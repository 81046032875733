import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LockIcon from '@material-ui/icons/Lock';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/bootstrap.css';
import { getMeMedico } from '@actions/me/actions';
import { getCatalogoNacionalidad } from '@utils/getCatalogos';
import { getToken, getEmailMe, perfilMedicoDatosIdentificacion } from '@utils/common';
import { validarFecha, validarFechaEsAnterior } from '@common/functions';
import { constDia, constMes, constAnio } from '@constants/fechas';
import {
  docIdLongitudMax,
  validacionDocIdOnChange,
  validacionEmail,
} from '@constants/validaciones';
import {
  IFechaField,
  ITextFieldEstandar,
  ICountryPhoneInput,
  ICatStrEstandar,
} from '@common/types';
import { RootState } from 'src/store';
import DialogPassword from '@components/ModalDialogs/DialogPassword/index';
import useStyles from './styles';
import { IPerfilAdministradorProps } from './types';
import { IDatosAdministrador } from '../types';

dayjs.locale('es');

const PerfilAdministrador = (props: IPerfilAdministradorProps) => {
  const { t } = useTranslation();
  const {
    objSuperAdministrador, setObjSuperAdministrador, setDisabledSave, setDisabledNext,
  } =
    props;
  const Me = useSelector((state: RootState) => state.Me);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anyoFur] = useState<number[]>(constAnio);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [catNacionalidad, setCatNacionalidad] = useState<Array<ICatStrEstandar>>([]);
  const [emailError, setEmailError] = useState<any>({ helperText: '', error: false });
  const [docIdField, setDocIdField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [disableDocId, setDisableDocId] = useState<boolean>(false);
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailError({ helperText: '', error: false });
    } else {
      setEmailError({ helperText: t('email_incorrecto'), error: true });
    }
  };
  const handleChangeSexo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjSuperAdministrador({ ...objSuperAdministrador, sexo: val });
  };
  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const dataUpd = { ...objSuperAdministrador };
    const valAdd = event.target.value as string;
    if (tipo === 'dia') {
      dataUpd.fechaNac.dia = valAdd;
    } else if (tipo === 'mes') {
      dataUpd.fechaNac.mes = valAdd;
    } else if (tipo === 'anio') {
      dataUpd.fechaNac.anio = valAdd;
    }
    setObjSuperAdministrador(dataUpd);
  };
  const handleChangeTxt = (event: React.ChangeEvent<HTMLInputElement>, tipo: string) => {
    const val = event.target.value as string;
    const dataUpd = { ...objSuperAdministrador };
    if (tipo === 'nombre') {
      dataUpd.nombre = val;
    } else if (tipo === 'apellidoP') {
      dataUpd.apellidoP = val;
    } else if (tipo === 'apellidoS') {
      dataUpd.apellidoS = val;
    } else if (tipo === 'docId') {
      if (validacionDocIdOnChange(val, Me.pais)) {
        setDocIdField({ helperText: '', error: false });
        dataUpd.docId = val;
      } else {
        setDocIdField({ helperText: t(`doc_id_incorrecto_${Me.pais}`), error: true });
      }
    } else if (tipo === 'email') {
      dataUpd.email = val.toLowerCase();
    }
    setObjSuperAdministrador(dataUpd);
  };
  const handleChangeNacionalidad = (_event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setObjSuperAdministrador({ ...objSuperAdministrador, nacionalidad: newValue });
    } else {
      setObjSuperAdministrador({ ...objSuperAdministrador, nacionalidad: { id: '', label: '' } });
    }
  };
  const invalidAdmin = (admin: IDatosAdministrador) =>
    fechaField.error ||
    admin.nombre.length === 0 ||
    admin.apellidoP.length === 0 ||
    (Me.pais === 'mx' && admin.docId.length < 18) ||
    (Me.pais === 'uy' && admin.docId.length < 6) ||
    admin.sexo.length === 0 ||
    admin.fechaNac.dia.length === 0 ||
    admin.fechaNac.mes.length === 0 ||
    admin.nacionalidad.id.length === 0 ||
    admin.fechaNac.anio.length === 0 ||
    admin.celular.numero.length === 0 ||
    admin.email.length === 0;

  useEffect(() => {
    if (catNacionalidad.length === 0) {
      getCatalogoNacionalidad().then((result: ICatStrEstandar[]) => {
        setCatNacionalidad(result);
      });
    }
  }, [catNacionalidad]);
  useEffect(() => {
    if (objSuperAdministrador.id === -1 && Me.email && Me.email.length > 0) {
      fetch(perfilMedicoDatosIdentificacion(Me.email), {
        method: 'GET',
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setObjSuperAdministrador(result.data);
            setDisabledNext(invalidAdmin(result.data));
            setDisableDocId(
              (Me.pais === 'mx' && result.data.docId.length === 18) ||
                (Me.pais === 'uy' &&
                  (result.data.docId.length === 6 || result.data.docId.length === 7)),
            );
          } else {
            setDisableDocId(false);
          }
        })
        .catch(() => {
          setDisableDocId(false);
        });
    }
  }, [Me]);
  useEffect(() => {
    if (objSuperAdministrador.id === -1) {
      dispatch(getMeMedico(getEmailMe()));
    } else {
      setDisabledNext(invalidAdmin(objSuperAdministrador));
      setDisableDocId(
        (Me.pais === 'mx' && objSuperAdministrador.docId.length === 18) ||
          (Me.pais === 'uy' &&
            (objSuperAdministrador.docId.length === 6 || objSuperAdministrador.docId.length === 7)),
      );
    }
  }, []);
  useEffect(() => {
    const rdia = objSuperAdministrador.fechaNac.dia;
    const rmes = objSuperAdministrador.fechaNac.mes;
    const ranio = objSuperAdministrador.fechaNac.anio;
    if (rdia !== '' && rmes !== '' && ranio !== '') {
      const objF = { ...fechaField };
      objF.error = false;
      objF.helperText = '';
      if (!validarFecha(ranio, rmes, rdia) || !validarFechaEsAnterior(ranio, rmes, rdia)) {
        objF.error = true;
        objF.helperText = t('fecha_invalida');
      }
      setFechaField(objF);
    }
  }, [
    objSuperAdministrador.fechaNac.dia,
    objSuperAdministrador.fechaNac.mes,
    objSuperAdministrador.fechaNac.anio,
  ]);
  useEffect(() => {
    setDisabledSave(invalidAdmin(objSuperAdministrador));
  }, [objSuperAdministrador]);

  return (
    <div className="p-4 bg-white">
      <DialogPassword
        open={showDialog}
        callBackClose={() => {
          setShowDialog(!showDialog);
        }}
      />
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <div className="flex flex-col justify-center">
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {t('administrador_mis_datos')}
          </h1>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nombre(s)')}*`}</h4>
            <TextField
              variant="outlined"
              type="nombre"
              value={objSuperAdministrador.nombre}
              onChange={(e: any) => handleChangeTxt(e, 'nombre')}
              fullWidth
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('primer_apellido')}*`}</h4>
            <TextField
              variant="outlined"
              type="apellidoP"
              value={objSuperAdministrador.apellidoP}
              onChange={(e: any) => handleChangeTxt(e, 'apellidoP')}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('segundo_apellido')}</h4>
            <TextField
              variant="outlined"
              type="apellidoS"
              value={objSuperAdministrador.apellidoS}
              onChange={(e: any) => handleChangeTxt(e, 'apellidoS')}
              fullWidth
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              placeholder={t('especifica')}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t(`doc_id_${Me.pais}`)}*`}</h4>
            <TextField
              variant="outlined"
              type="docId"
              value={objSuperAdministrador.docId}
              onChange={(e: any) => handleChangeTxt(e, 'docId')}
              fullWidth
              inputProps={{
                maxLength: docIdLongitudMax(Me.pais),
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                style: {
                  textTransform:
                    Me.pais === 'mx' && objSuperAdministrador.docId.length ? 'uppercase' : 'none',
                },
              }}
              placeholder={t(`especifica_debe_contener_n_caracteres_${Me.pais}`)}
              error={docIdField.error}
              helperText={docIdField.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              disabled={disableDocId}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div>
            <h4 className="m-0 mb-3 text-gray-500 font-normal">{`${t('sexo')}*`}</h4>
            <RadioGroup
              row
              name="sexo"
              value={objSuperAdministrador.sexo}
              onChange={handleChangeSexo}
            >
              <FormControlLabel
                value="H"
                control={<Radio color="primary" />}
                label={t('hombre')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="M"
                control={<Radio color="primary" />}
                label={t('mujer')}
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
          <div className="w-full grid grid-cols-3 gap-2">
            <h4 className="text-gray-500 font-normal m-0 col-span-3">
              {`${t('fecha_nacimiento')}*`}
            </h4>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-dia">{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={objSuperAdministrador.fechaNac.dia}
                onChange={(e) => handleChangeDate(e, 'dia')}
                label={t('dia')}
                error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle.toString()} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-mes">{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={objSuperAdministrador.fechaNac.mes}
                onChange={(e) => handleChangeDate(e, 'mes')}
                label={t('mes')}
                error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {mesSingle.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.absoluteBottom}>
                {fechaField.helperText}
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-año">{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={objSuperAdministrador.fechaNac.anio}
                onChange={(e) => handleChangeDate(e, 'anio')}
                label={t('anio')}
                error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {anyoFur.map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_celular')}*`}</h4>
            <PhoneInput
              preferredCountries={[Me.pais, 'us']}
              country={Me.pais}
              localization={es}
              enableSearch
              searchPlaceholder={t('buscar')}
              searchNotFound={t('sin_resultados')}
              inputStyle={{ width: '100%' }}
              value={`${objSuperAdministrador.celular.codigo}${objSuperAdministrador.celular.numero}`}
              onChange={(value, country: ICountryPhoneInput) => {
                const { dialCode } = country;
                const nuevoCel = value.slice(dialCode.length);
                const dataUpd = { ...objSuperAdministrador };
                dataUpd.celular.numero = nuevoCel;
                dataUpd.celular.codigo = country.dialCode;
                setObjSuperAdministrador(dataUpd);
              }}
            />
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0 mb-2">{t('telefono_fijo')}</h4>
            <PhoneInput
              preferredCountries={[Me.pais, 'us']}
              country={Me.pais}
              localization={es}
              enableSearch
              searchPlaceholder={t('buscar')}
              searchNotFound={t('sin_resultados')}
              inputStyle={{ width: '100%' }}
              value={`${objSuperAdministrador.telefono.codigo}${objSuperAdministrador.telefono.numero}`}
              onChange={(value, country: ICountryPhoneInput) => {
                const { dialCode } = country;
                const nuevoCel = value.slice(dialCode.length);
                const dataUpd = { ...objSuperAdministrador };
                dataUpd.telefono.numero = nuevoCel;
                dataUpd.telefono.codigo = country.dialCode;
                setObjSuperAdministrador(dataUpd);
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 pt-2">
          <div className="col-span-2">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('email')}*`}</h4>
            <TextField
              variant="outlined"
              type="email"
              value={objSuperAdministrador.email}
              placeholder={t('especifica')}
              onChange={(e: any) => handleChangeTxt(e, 'email')}
              fullWidth
              error={emailError.error}
              helperText={emailError.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              onBlur={handleBlurEmail}
              disabled
            />
          </div>
          <div className="flex items-end">
            <Button
              color="primary"
              onClick={() => {
                setShowDialog(!showDialog);
              }}
              className={classes.noTextTranform}
              startIcon={<LockIcon />}
            >
              {t('medico_perfil_identificacion_cambiar')}
            </Button>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nacionalidad')}*`}</h4>
            <Autocomplete
              options={catNacionalidad}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('sin_resultados')}
              value={objSuperAdministrador.nacionalidad}
              onChange={(e, nV) => handleChangeNacionalidad(e, nV)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
        </div>
        <div className="pt-4">
          <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
        </div>
      </div>
    </div>
  );
};

export default PerfilAdministrador;
