import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ListSubheader, MenuItem } from '@material-ui/core';
import { RootState } from 'src/store';
import {
  getDictamenMedLab,
  getCatalogoCategoriaExamen,
  getCatalogoTipoExamen,
  getCatalogoOcupacion,
  getCatalogoDictamen,
  getCatalogoRestricciones,
  getCatalogoCausasNoAptitud,
} from '@utils/getCatalogos';
import { ICatalogosEstandar } from '@common/types';
// import { setRequest } from '@actions/request/types';
import { setRequestFile } from '@actions/requestFile/types';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import Dictamen from './Dictamen';
import Catalogos from './Catalogos';
import {
  IDictamenMedLab,
  ICatalogosCombinados,
  IDictamenMedicoLaboralProps,
  dictamenMedLabInitial,
} from './types';
import useStyles from './styles';

function DictamenMedicoLaboral(props: IDictamenMedicoLaboralProps) {
  const { enfermedadesActivas } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idMedico, secciones } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idOrganizacion, urlLogotipo } = useSelector(
    (state: RootState) => state.Organizaciones.organizacion,
  );
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const [urlLogo, setUrlLogo] = useState<string>('');
  const [dictamenesMedLab, setDictamenesMedLab] = useState<IDictamenMedLab[]>([]);
  const [catCategorias, setCatCategorias] = useState<ICatalogosEstandar[]>([]);
  const [catTiposExamen, setCatTiposExamen] = useState<ICatalogosCombinados[]>([]);
  const [catPuestos, setCatPuestos] = useState<ICatalogosCombinados[]>([]);
  const [catDictamenes, setCatDictamenes] = useState<ICatalogosEstandar[]>([]);
  const [catRestricciones, setCatRestricciones] = useState<ICatalogosCombinados[]>([]);
  const [catCausasNoAptitud, setCatCausasNoAptitud] = useState<ICatalogosCombinados[]>([]);
  const [listaTipoExamen, setListaTipoExamen] = useState<any[]>([]);
  const [activeSubStep, setActiveSubStep] = useState<'dictamen-med-lab' | 'catalogos'>(
    'dictamen-med-lab',
  );
  const [tipoCatalogo, setTipoCatalogo] = useState<
  'tipo_examen' | 'puesto' | 'restricciones' | 'causas_no_aptitud' | 'enfermedades'
  >('tipo_examen');
  const [objDictamenMedLab, setObjDictamenMedLab] = useState<IDictamenMedLab>({
    ...dictamenMedLabInitial,
    idConsulta,
  });

  useEffect(() => {
    dispatch(setLoading(true));
    const dictamenMedLabP = getDictamenMedLab(
      idPaciente,
      idMedico,
      idOrganizacion,
      idConsultorio,
      idConsulta,
    );
    const catalogoCategoriaExamenP = getCatalogoCategoriaExamen();
    const catalogoTipoExamenP = getCatalogoTipoExamen(idOrganizacion);
    const catalogoOcupacionP = getCatalogoOcupacion(idOrganizacion);
    const catalogoDictamenP = getCatalogoDictamen();
    const catalogoRestriccionesP = getCatalogoRestricciones(idOrganizacion);
    const catalogoCausasNoAptitudP = getCatalogoCausasNoAptitud(idOrganizacion);
    // const fetchListaAntecedentes = getListaAntecedentesLaborales(idPaciente, idMedico);
    Promise.all([
      dictamenMedLabP,
      catalogoCategoriaExamenP,
      catalogoTipoExamenP,
      catalogoOcupacionP,
      catalogoDictamenP,
      catalogoRestriccionesP,
      catalogoCausasNoAptitudP,
    ])
      .then((responses) => {
        Promise.all([
          responses[0].json(),
          responses[1].json(),
          responses[2].json(),
          responses[3].json(),
          responses[4].json(),
          responses[5].json(),
          responses[6].json(),
        ]).then((results) => {
          setDictamenesMedLab(results[0].datos || []);
          setCatCategorias(results[1].datos || []);
          setCatTiposExamen(results[2].datos || []);
          setCatPuestos(results[3].datos || []);
          setCatDictamenes(results[4].datos || []);
          setCatRestricciones(results[5].datos || []);
          setCatCausasNoAptitud(results[6].datos || []);
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: `${t('message_get_success')}`,
            }),
          );
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-get-error')} ${err.toString()}`,
          }),
        );
      });
  }, []);
  useEffect(() => {
    if (catTiposExamen.length > 0) {
      const lTipoExamen: any[] = [];
      const categorias: number[] = [];
      catTiposExamen.forEach((tp: ICatalogosCombinados) => {
        if (!categorias.includes(tp.categoria.id)) {
          lTipoExamen.push(<ListSubheader>{tp.categoria.label}</ListSubheader>);
          categorias.push(tp.categoria.id);
        }
        lTipoExamen.push(
          <MenuItem value={`${tp.source}-${tp.id}`}>
            {tp.source === 'propio' ? `${tp.label} (opción creada)` : tp.label}
          </MenuItem>,
        );
      });
      setListaTipoExamen(lTipoExamen);
    }
  }, [catTiposExamen]);
  useEffect(() => {
    if (idMedico && idOrganizacion) {
      dispatch(
        setRequestFile({
          type: 'get',
          idMedico,
          idPaciente: null,
          idArchivo: idOrganizacion,
          nombreArchivo: null,
          pathArchivo: urlLogotipo,
          tipoArchivo: 'imagenOrganizacion',
          successFunction: (url: string) => setUrlLogo(url),
        }),
      );
    }
  }, [idOrganizacion]);

  return (
    <div>
      {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 77) && (
        <div>
          <Button
            className={
              activeSubStep === 'dictamen-med-lab' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => setActiveSubStep('dictamen-med-lab')}
          >
            {t('dictamen_med_lab')}
          </Button>
          <Button
            className={activeSubStep === 'catalogos' ? classes.buttonSelected : classes.buttonRoot}
            onClick={() => setActiveSubStep('catalogos')}
          >
            {t('catalogos')}
          </Button>
        </div>
      )}
      {activeSubStep === 'dictamen-med-lab' && (
        <Dictamen
          setTipoCatalogo={setTipoCatalogo}
          setActiveSubStep={setActiveSubStep}
          dictamenesMedLab={dictamenesMedLab}
          setDictamenesMedLab={setDictamenesMedLab}
          objDictamenMedLab={objDictamenMedLab}
          setObjDictamenMedLab={setObjDictamenMedLab}
          listaTipoExamen={listaTipoExamen}
          catTiposExamen={catTiposExamen}
          setCatTiposExamen={setCatTiposExamen}
          catPuestos={catPuestos}
          setCatPuestos={setCatPuestos}
          catDictamenes={catDictamenes}
          catRestricciones={catRestricciones}
          setCatRestricciones={setCatRestricciones}
          catCausasNoAptitud={catCausasNoAptitud}
          setCatCausasNoAptitud={setCatCausasNoAptitud}
          enfermedades={enfermedadesActivas}
          urlLogo={urlLogo}
        />
      )}
      {activeSubStep === 'catalogos' && (
        <Catalogos
          tipoCatalogo={tipoCatalogo}
          setTipoCatalogo={setTipoCatalogo}
          catCategorias={catCategorias}
          catTiposExamen={catTiposExamen}
          setCatTiposExamen={setCatTiposExamen}
          catPuestos={catPuestos}
          setCatPuestos={setCatPuestos}
          catRestricciones={catRestricciones}
          setCatRestricciones={setCatRestricciones}
          catCausasNoAptitud={catCausasNoAptitud}
          setCatCausasNoAptitud={setCatCausasNoAptitud}
        />
      )}
    </div>
  );
}

export default DictamenMedicoLaboral;
