/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import {
  RadioGroup, FormControlLabel, Radio, Button, TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RootState } from 'src/store';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { ICatalogosCie10 } from '@common/types';
import {
  edadCalculada, getFechaActual, getFechaActualISO, hasKey,
} from '@common/functions';
import { numeroEnfermedadGrupo } from '@common/enfermedades';
import ComentariosComponente from '@components/Comentarios';
import { getCatCie10Query } from '@utils/getCatalogos';
import {
  enfermedadesGuardarGrupoEnfermedadesNegadas,
  enfermedadesGuardarReferida,
} from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import { enfermedadesEliminarNegada } from '@utils/deleteInfo';
import { setEnfNegadas, setEnfReferidas, setGrupoEnfNegada } from '@actions/paciente/actions';
import AlertaEnfermedadGuardado from '../../../../EnfermedadesDialogs/AlertaEnfermedadGuardado';
import { IFormCieMedicoProps } from './types';
import {
  IFechaComentarios,
  enfermedadesFormInitial,
} from '../../../../Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';

function FormCieMedico({
  setSeleccionDiagnostico,
  setIdDiagnostico,
  diagnostico,
  setDiagnostico,
}: IFormCieMedicoProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    dia,
    mes,
    anio,
    edad: { periodo, edad },
    sexo,
    idPaciente,
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { enfReferidas, enfNegadas, grupoEnfNegadas } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const [keyComentarios, setKeyComentarios] = useState<number>(1);
  const [openAlertEnfermedad, setOpenAlertEnfermedad] = useState(false);
  const [indiceRepetido, setIndiceRepetido] = useState(-1);

  const [catCIE10, setCatCIE10] = useState<ICatalogosCie10[]>([]);
  const [openCIE10, setOpenCIE10] = useState<boolean>(false);
  const [loadingCIE10, setLoadingCIE10] = useState<boolean>(false);
  const [inputCIE10, setInputCIE10] = useState<string>('');

  const cambiarKeyComentarios = () => {
    setKeyComentarios(Math.floor(Math.random() * 1000) + 1);
  };

  const handleChangeCIE = (event: any, newValue: ICatalogosCie10 | null, reason: string) => {
    setDiagnostico({
      ...diagnostico,
      diagnosticoCie10:
        reason !== 'clear' && newValue ? newValue : { id: '', label: '', grupoEnfermedades: 0 },
    });
    setOpenCIE10(reason === 'clear');
  };

  const handleChangeDiagnosticoMedico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnostico({
      ...diagnostico,
      diagnosticoMedico: event.target.value as string,
    });
  };

  const handleChangeTipoDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnostico({
      ...diagnostico,
      tipoDiagnostico: event.target.value as 'cronico' | 'agudo' | '',
    });
  };

  const handleChangeCertezaDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnostico({
      ...diagnostico,
      certezaDelDiagnostico: event.target.value as 'presuntivo' | 'confirmado' | '',
    });
  };

  const setComentarios = (data: RawDraftContentState) => {
    setDiagnostico({
      ...diagnostico,
      comentario: data,
    });
  };

  const limpiarCampos = () => {
    setCatCIE10([]);
    setInputCIE10('');
    setSeleccionDiagnostico('cie10');
    setIdDiagnostico(-1);
    setDiagnostico(enfermedadesFormInitial);
  };

  const handleClickAgregarDiagnostico = () => {
    let newComs = [] as IFechaComentarios[];
    // funcion que verifica que existan comentarios y no este en blanco
    if (
      diagnostico.comentario.blocks.length &&
      !diagnostico.comentario.blocks.every((row: any) => row.text === '' || /^\s*$/.test(row.text))
    ) {
      newComs = [{ fecha: getFechaActualISO(), comentario: diagnostico.comentario }];
    }
    const objDiagnostico = {
      idBD: diagnostico.idBD,
      seleccionDiagnostico: diagnostico.seleccionDiagnostico,
      diagnosticoCie10: diagnostico.diagnosticoCie10,
      diagnosticoMedico: diagnostico.diagnosticoMedico,
      tipoDiagnostico: diagnostico.tipoDiagnostico,
      certezaDelDiagnostico: diagnostico.certezaDelDiagnostico,
      estatusDelDiagnostico: 'activo' as 'activo',
      edadAlDiagnostico: edadCalculada(anio, mes, dia),
      fechaRegistroDiagnostico: getFechaActual(),
      comentarios: newComs,
      idConsulta,
    };
    const objEnviar = {
      idMedico,
      idPaciente,
      idConsultorio,
      enfermedadReferida: objDiagnostico,
      consulta: idConsulta,
    };
    const LGEN: any = { ...grupoEnfNegadas };
    // si el diagnostico es cie 10 entonces tenemos que hacer varias verificaciones
    if (diagnostico.seleccionDiagnostico === 'cie10') {
      // tenemos que verificar que esa misma enfermedad no esté activa
      // solamente si esta es referida
      // si esta activa tenemos que mostrar el modal con las opciones de que hacer con ella
      if (objDiagnostico.estatusDelDiagnostico === 'activo') {
        const found = enfReferidas.findIndex(
          (enfermedad) =>
            enfermedad.diagnosticoCie10.id === objDiagnostico.diagnosticoCie10.id &&
            enfermedad.estatusDelDiagnostico === 'activo',
        );
        if (found >= 0) {
          setIndiceRepetido(found);
          setOpenAlertEnfermedad(true);
          return;
        }
      }
      // tenemos que obtener el catalog_key padre de la enfermedad introducida
      // para buscarla en la lista de enfermedades negadas, y si resulta que existe
      // en la lista de enfermedades negadas, procederemos a eliminarla
      const idPadre = diagnostico.diagnosticoCie10.id.substr(0, 3);
      const findIndxEN = enfNegadas.findIndex((len) => len.diagnosticoCie10.id === idPadre);
      if (findIndxEN >= 0) {
        const idABorrar = enfNegadas[findIndxEN].idBD;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: enfermedadesEliminarNegada({
              idMedico,
              idPaciente,
              idConsultorio,
              idBD: idABorrar,
              consulta: idConsulta,
            }),
            successFunction: () => {
              const LEN = enfNegadas.filter((enfN) => enfN.diagnosticoCie10.id !== idPadre);
              dispatch(setEnfNegadas(LEN));
            },
          }),
        );
      }
      // ahora buscamos el nombre del grupo, ya que si esta referida la enfermedad
      // eso significa que no podemos negarla del grupo, ya que existe
      // asi que buscamos su grupo y su selected y disabled
      const grupoEnf = numeroEnfermedadGrupo[diagnostico.diagnosticoCie10.grupoEnfermedades];
      const keySelected = `${grupoEnf}Selected`;
      const keyDisabled = `${grupoEnf}Disabled`;
      // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
      // y tenemos que deshabilitarlo
      if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
        LGEN[keySelected] = false;
        LGEN[keyDisabled] = true;
      }
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarReferida(objEnviar),
        successFunction: (result: any) => {
          dispatch(
            setEnfReferidas([{ ...objDiagnostico, idBD: result.data.idBD }, ...enfReferidas]),
          );
          setCatCIE10([]);
          limpiarCampos();
          // al darnos un resultado positivo mandamos a guardar el grupo de enfNegadas
          dispatch(setLoading(true));
          enfermedadesGuardarGrupoEnfermedadesNegadas({
            idMedico,
            idPaciente,
            idConsultorio,
            consulta: idConsulta,
            listaGrupoEnfermedadesNegadas: LGEN,
          })
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                // al resultado positivo procedemos a actualizar el grupo de enfermedades negadas
                dispatch(setGrupoEnfNegada(LGEN));
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'success',
                    mensaje: t('message-success'),
                  }),
                );
              } else {
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'error',
                    mensaje: `${t('message-error-warning')}: ${result2.msg}`,
                  }),
                );
              }
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-warning')}: ${err}`,
                }),
              );
              dispatch(setLoading(false));
            });
        },
      }),
    );
  };

  useEffect(() => {
    cambiarKeyComentarios();
  }, [diagnostico.seleccionDiagnostico]);

  const verificarDisableAgregarDiagnostico = () => {
    let diagnosticoBool;
    switch (diagnostico.seleccionDiagnostico) {
      case 'cie10':
        diagnosticoBool = !diagnostico.diagnosticoCie10.id;
        break;
      case 'medico':
        diagnosticoBool = !diagnostico.diagnosticoMedico;
        break;
      default:
        diagnosticoBool = true;
        break;
    }
    return diagnosticoBool;
  };

  const verificarDisableCancelarDiagnostico = () =>
    diagnostico.diagnosticoCie10.id.length === 0 &&
    diagnostico.diagnosticoMedico.length === 0 &&
    diagnostico.tipoDiagnostico.length === 0 &&
    diagnostico.certezaDelDiagnostico.length === 0 &&
    diagnostico.comentario.blocks.length === 0;

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertEnfermedad(false);
  };

  const handleCancelar = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    limpiarCampos();
  };

  useEffect(() => {
    if (!openCIE10) {
      setCatCIE10([]);
    }
  }, [openCIE10]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (inputCIE10.length > 2) {
      let sexoPaciente = '';
      if (sexo === 'H') {
        sexoPaciente = 'HOMBRE';
      } else if (sexo === 'M') {
        sexoPaciente = 'MUJER';
      }
      setLoadingCIE10(true);
      getCatCie10Query(inputCIE10, sexoPaciente, periodo, edad, signal)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatCIE10(result.datos);
          } else {
            setCatCIE10([]);
          }
          setLoadingCIE10(false);
        })
        .catch(() => {
          setCatCIE10([]);
          setLoadingCIE10(false);
        });
    } else {
      setCatCIE10([]);
      setLoadingCIE10(false);
    }
    return () => abortController.abort();
  }, [inputCIE10]);

  return (
    <div>
      <AlertaEnfermedadGuardado
        open={openAlertEnfermedad}
        callBackClose={handleAlertClose}
        indiceRepetido={indiceRepetido}
        objForm={diagnostico}
        estaEnDiagnostico
      />
      <div className="my-4">
        {diagnostico.seleccionDiagnostico === 'cie10' && (
          <Autocomplete
            open={openCIE10}
            onOpen={() => {
              setOpenCIE10(true);
            }}
            onClose={() => {
              setOpenCIE10(false);
            }}
            getOptionLabel={(option) => option.label}
            options={catCIE10}
            loading={loadingCIE10}
            loadingText="Buscando..."
            noOptionsText={
              inputCIE10.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
            }
            value={diagnostico.diagnosticoCie10}
            onChange={(e, nV, r) => handleChangeCIE(e, nV, r)}
            onInputChange={(e, newInputValue) => {
              if (newInputValue.length < 3) {
                setCatCIE10([]);
              }
              setInputCIE10(newInputValue);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('selecciona')}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingCIE10 ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
        {diagnostico.seleccionDiagnostico === 'medico' && (
          <TextField
            variant="outlined"
            fullWidth
            value={diagnostico.diagnosticoMedico}
            onChange={handleChangeDiagnosticoMedico}
            inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 255 }}
          />
        )}
      </div>
      <div className="flex my-4">
        <div className="mr-4">
          <h3 className="m-0 mr-4 text-gray-600 font-medium mb-2">
            {t('tipo_de_diagnostico')}
          </h3>
          <RadioGroup
            row
            name="tipo diagnostico"
            value={diagnostico.tipoDiagnostico}
            onChange={handleChangeTipoDiagnostico}
          >
            <FormControlLabel
              value="cronico"
              control={(
                <Radio
                  color="primary"
                  disabled={
                    (diagnostico.diagnosticoCie10.id === '0' &&
                      diagnostico.seleccionDiagnostico === 'cie10') ||
                    (diagnostico.diagnosticoMedico.length === 0 &&
                      diagnostico.seleccionDiagnostico === 'medico')
                  }
                />
              )}
              label={t('cronico')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="agudo"
              control={(
                <Radio
                  color="primary"
                  disabled={
                    (diagnostico.diagnosticoCie10.id === '0' &&
                      diagnostico.seleccionDiagnostico === 'cie10') ||
                    (diagnostico.diagnosticoMedico.length === 0 &&
                      diagnostico.seleccionDiagnostico === 'medico')
                  }
                />
              )}
              label={t('agudo')}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div className="mr-4">
          <h3 className="m-0 mr-4 text-gray-600 font-medium mb-2">
            {t('certeza_del_diagnostico')}
          </h3>
          <RadioGroup
            row
            name="diagnostico"
            value={diagnostico.certezaDelDiagnostico}
            onChange={handleChangeCertezaDiagnostico}
          >
            <FormControlLabel
              value="presuntivo"
              control={
                <Radio color="primary" disabled={diagnostico.tipoDiagnostico.length === 0} />
              }
              label={t('presuntivo')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="confirmado"
              control={
                <Radio color="primary" disabled={diagnostico.tipoDiagnostico.length === 0} />
              }
              label={t('confirmado')}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
      </div>
      <ComentariosComponente
        comentarios={diagnostico.comentario}
        setComentarios={setComentarios}
        rows={4}
        maxLength={400}
        placeholder={t('comentarios')}
        lang="es"
        disabled={false}
        key={`${diagnostico.diagnosticoCie10.id}-${diagnostico.certezaDelDiagnostico}-${keyComentarios}`}
      />
      <div className="flex justify-between items-center my-4">
        <span>
          *
          {t('campos_obligatorios')}
        </span>
        <div className="flex flex-row">
          <Button
            color="primary"
            variant="contained"
            disabled={verificarDisableAgregarDiagnostico()}
            onClick={handleClickAgregarDiagnostico}
            startIcon={<SaveIcon />}
          >
            {t('guardar_diagnostico')}
          </Button>
          <div className="ml-2">
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={verificarDisableCancelarDiagnostico()}
              onClick={(e) => {
                handleCancelar(e);
              }}
            >
              {t('cancelar')}
            </Button>
          </div>
        </div>
      </div>
      <div className="text-right">
        {`${t('para_guardar_el_diagnostico_debes_dar_clic_en_el_boton')} `}
        <span className="font-bold">
          {`“${!(diagnostico.idBD > 0) ? t('guardar_diagnostico') : t('actualizar')}”`}
        </span>
      </div>
    </div>
  );
}

export default FormCieMedico;
