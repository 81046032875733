import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { validacionSoloNumeros } from '@constants/validaciones';
import { IModalTiempoExposicionProps, IDbTiempoSeleccionComentarios } from './types';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function ModalRuido(props: IModalTiempoExposicionProps) {
  const {
    open, callBackClose, callBackSave, callBackUpdate, nombre, datoActualizar,
  } = props;
  const { t } = useTranslation();
  const [tiempoSeleccionComentarios, setTiempoSeleccionComentarios] =
    useState<IDbTiempoSeleccionComentarios>({
      id: -1,
      txtDb: '',
      txtTiempo: '',
      radioSeleccion: undefined,
      comentarios: '',
    });
  const [dB, setDB] = useState(0);
  const [tiempo, setTiempo] = useState(0);
  const [radioSel, setRadioSel] = useState('');
  const getAlerta = (decibeles: number, tiempoExp: number, minHoras: string) => {
    const textoDB =
      decibeles >= 105
        ? '≥ 105'
        : `${decibeles - (decibeles % 3)}-${decibeles - (decibeles % 3) + 3}`;
    let textoTiempo = null;
    if (decibeles >= 105) {
      if (tiempoExp >= 15 || (tiempoExp >= 1 && minHoras === 'H')) {
        textoTiempo = '15 M';
      }
    } else if (decibeles >= 102) {
      if (tiempoExp >= 30 || (tiempoExp >= 1 && minHoras === 'H')) {
        textoTiempo = '30 M';
      }
    } else if (decibeles >= 99) {
      if (tiempoExp >= 60 || (tiempoExp >= 1 && minHoras === 'H')) {
        textoTiempo = '1 H';
      }
    } else if (decibeles >= 96) {
      if (tiempoExp >= 2 && minHoras === 'H') {
        textoTiempo = '2 H';
      }
    } else if (decibeles >= 93) {
      if (tiempoExp >= 4 && minHoras === 'H') {
        textoTiempo = '4 H';
      }
    } else if (decibeles >= 90) {
      if (tiempoExp >= 8 && minHoras === 'H') {
        textoTiempo = '8 H';
      }
    }
    return textoTiempo ? (
      <span>
        <p className="text-red-600">
          <b>Nivel de exposición peligroso</b>
        </p>
        <p>
          De acuerdo a la
          {' '}
          <a
            href="https://www.dof.gob.mx/nota_detalle.php?codigo=734536&fecha=17/04/2002"
            target="_blank"
            rel="noreferrer"
          >
            NOM-011-STPS-2001
          </a>
          , el tiempo máximo de exposición permisible para
          {' '}
          {`${textoDB}`}
          {' '}
          dB(A) es de
          {' '}
          <b>{`${textoTiempo}`}</b>
          .
        </p>
      </span>
    ) : null;
  };
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    callBackClose(event);
    setTiempoSeleccionComentarios({
      id: -1,
      txtDb: '',
      txtTiempo: '',
      radioSeleccion: undefined,
      comentarios: '',
    });
    setDB(0);
    setTiempo(0);
    setRadioSel('');
  };
  useEffect(() => {
    if (datoActualizar.id !== -1) {
      setTiempoSeleccionComentarios({
        id: datoActualizar.id,
        txtDb: datoActualizar.txtDb || '',
        txtTiempo: datoActualizar.txtTiempo || '',
        radioSeleccion: datoActualizar.radioSeleccion,
        comentarios: datoActualizar.comentarios,
      });
      setDB(parseInt(datoActualizar.txtDb || '0', 10));
      setTiempo(parseInt(datoActualizar.txtTiempo || '0', 10));
      setRadioSel(datoActualizar.radioSeleccion || '');
    } else {
      setTiempoSeleccionComentarios({
        id: -1,
        txtDb: '',
        txtTiempo: '',
        radioSeleccion: undefined,
        comentarios: '',
      });
    }
  }, [datoActualizar]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{nombre}</h2>
        <IconButton aria-label="cerrar" color="inherit">
          <CloseIcon onClick={(e) => handleClose(e)} />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">
            {t('antecedentes_laborales_modal_ruido_nivel')}
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <div className="grid grid-cols-2 gap-2 mb-2 place-items-center">
              <TextField
                id="txtDb"
                value={tiempoSeleccionComentarios.txtDb}
                onChange={(e) => {
                  const valorNumerico = e.target.value;
                  if (valorNumerico === '' || validacionSoloNumeros(valorNumerico)) {
                    setTiempoSeleccionComentarios({
                      ...tiempoSeleccionComentarios,
                      txtDb: e.target.value,
                    });
                    setDB(parseInt(valorNumerico, 10));
                  }
                }}
                inputProps={{
                  maxLength: 3,
                  autoComplete: 'off',
                }}
                variant="outlined"
                fullWidth
              />
              <h3 className="w-full text-gray-500 font-normal m-0 text-left">
                {t('antecedentes_laborales_modal_ruido_db')}
              </h3>
            </div>
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">
                {`${t('antecedentes_laborales_modal_tiempo_exposicion')}*`}
              </h3>
            </div>
            <div className="grid grid-cols-2 gap-2 mb-2">
              <TextField
                id="txtTiempo"
                value={tiempoSeleccionComentarios.txtTiempo}
                onChange={(e) => {
                  const valorNumerico = e.target.value;
                  if (valorNumerico === '' || validacionSoloNumeros(valorNumerico)) {
                    setTiempoSeleccionComentarios({
                      ...tiempoSeleccionComentarios,
                      txtTiempo: e.target.value,
                    });
                    setTiempo(parseInt(valorNumerico, 10));
                  }
                }}
                inputProps={{
                  maxLength: 2,
                  autoComplete: 'off',
                }}
                variant="outlined"
                fullWidth
              />
              <RadioGroup
                row
                aria-label="empresa"
                name="empresa"
                value={tiempoSeleccionComentarios.radioSeleccion}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTiempoSeleccionComentarios({
                    ...tiempoSeleccionComentarios,
                    radioSeleccion: (event.target as HTMLInputElement).value,
                  });
                  setRadioSel((event.target as HTMLInputElement).value);
                }}
              >
                <FormControlLabel
                  value="H"
                  control={<Radio color="primary" />}
                  label={t('hora_s_')}
                />
                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label={t('minuto_s_')}
                />
              </RadioGroup>
            </div>
          </div>
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">
              {dB >= 90 && tiempo && radioSel ? getAlerta(dB, tiempo, radioSel) : null}
            </h3>
          </div>
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">{t('comentarios')}</h3>
        </div>
        <TextField
          id="comentarios"
          placeholder={t('comentarios')}
          value={tiempoSeleccionComentarios.comentarios}
          onChange={(e) => {
            setTiempoSeleccionComentarios({
              ...tiempoSeleccionComentarios,
              comentarios: e.target.value,
            });
          }}
          inputProps={{
            maxLength: 400,
            autoComplete: 'off',
          }}
          multiline
          rows="4"
          variant="outlined"
          fullWidth
        />
        <div className="w-full flex justify-between py-2">
          <p className="text-gray-600">
            * &nbsp;
            {t('campos_obligatorios')}
          </p>
          <div className="flex flex-row">
            <div className="pr-2">
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={(e) => {
                  handleClose(e);
                }}
              >
                {t('cancelar')}
              </Button>
            </div>
            <div>
              {tiempoSeleccionComentarios && tiempoSeleccionComentarios.id === -1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={(e) => {
                    callBackSave(tiempoSeleccionComentarios);
                    handleClose(e);
                  }}
                  disabled={
                    tiempoSeleccionComentarios.txtTiempo === '' ||
                    tiempoSeleccionComentarios.radioSeleccion === undefined
                  }
                >
                  {t('guardar')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={(e) => {
                    callBackUpdate(tiempoSeleccionComentarios);
                    handleClose(e);
                  }}
                  disabled={
                    tiempoSeleccionComentarios.txtTiempo === '' &&
                    tiempoSeleccionComentarios.radioSeleccion === undefined
                  }
                >
                  {t('actualizar')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalRuido;
