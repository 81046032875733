import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button, Accordion, AccordionDetails, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '@common/styles';
import ListaDatosTab from '../ListaDatosTabs';
import { ITabExposicionFisicoProps } from './types';
import {
  IDbTiempoSeleccionComentariosFecha,
  ITiempoSeleccionComentariosFecha,
} from '../../../types';

function TabExposicionFisico({
  setOpenModalTiempoExposicion,
  setOpenModalRuido,
  setNombreModal,
  setIdTabSeleccionado,
  objExposicionRiesgo,
  setDatoActualizar,
  setInxArraySeleccionado,
  setDeleteAlert,
}: ITabExposicionFisicoProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accTemperaturasAltas, setAccTemperaturasAltas] = useState<boolean | undefined>(undefined);
  const [accTemperaturasBajas, setAccTemperaturasBajas] = useState<boolean | undefined>(undefined);
  const [accRadiacionIonizante, setAccRadiacionIonizante] = useState<boolean | undefined>(
    undefined,
  );
  const [accRadiacionNoIonizante, setAccRadiacionNoIonizante] = useState<boolean | undefined>(
    undefined,
  );
  const [accVentilacion, setAccVentilacion] = useState<boolean | undefined>(undefined);
  const [accIluminacion, setAccIluminacion] = useState<boolean | undefined>(undefined);
  const [accRuido, setAccRuido] = useState<boolean | undefined>(undefined);
  const [accVibracion, setAccVibracion] = useState<boolean | undefined>(undefined);
  const [accFluidoElectrico, setAccFluidoElectrico] = useState<boolean | undefined>(undefined);
  const [accOtro, setAccOtro] = useState<boolean | undefined>(undefined);
  return (
    <div className="w-full">
      {/* es la parte que corresponde a temperaturas altas */}
      <Accordion
        expanded={accTemperaturasAltas}
        onChange={() => setAccTemperaturasAltas(!setAccTemperaturasAltas)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_tal')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_tal'));
                  setIdTabSeleccionado(1);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasAltas.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasAltas.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_tal')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(1);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_tal'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(1);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a temperaturas bajas */}
      <Accordion
        expanded={accTemperaturasBajas}
        onChange={() => setAccTemperaturasBajas(!accTemperaturasBajas)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_tba')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_tba'));
                  setIdTabSeleccionado(2);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasBajas.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayTemperaturasBajas.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_tba')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(2);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_tba'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(2);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion ionizante */}
      <Accordion
        expanded={accRadiacionIonizante}
        onChange={() => setAccRadiacionIonizante(!accRadiacionIonizante)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_rad')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_rad'));
                  setIdTabSeleccionado(3);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionIonizante.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionIonizante.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_rad')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(3);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_rad'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(3);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion no ionizante */}
      <Accordion
        expanded={accRadiacionNoIonizante}
        onChange={() => setAccRadiacionNoIonizante(!accRadiacionNoIonizante)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_rad_no')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_rad_no'));
                  setIdTabSeleccionado(4);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionNoIonizante.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRadiacionNoIonizante.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_rad_no')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(4);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_rad_no'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(4);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ventilacion */}
      <Accordion expanded={accVentilacion} onChange={() => setAccVentilacion(!accVentilacion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_ven')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_ven'));
                  setIdTabSeleccionado(5);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_ven')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(5);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_ven'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(5);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a iluminacion */}
      <Accordion expanded={accIluminacion} onChange={() => setAccIluminacion(!accIluminacion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_ilu')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_ilu'));
                  setIdTabSeleccionado(6);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_ilu')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(6);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_ilu'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(6);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ruido */}
      <Accordion expanded={accRuido} onChange={() => setAccRuido(!accRuido)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_ruido')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRuido(true);
                  setNombreModal(t('antecedentes_laborales_fisico_ruido'));
                  setIdTabSeleccionado(7);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido.map(
            (valPruebaLista: IDbTiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_ruido')}
                txtDb={valPruebaLista.txtDb}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(7);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_ruido'));
                }}
                openModalActualizar={() => setOpenModalRuido(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(7);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a vibracion */}
      <Accordion expanded={accVibracion} onChange={() => setAccVibracion(!accVibracion)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_vib')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_vib'));
                  setIdTabSeleccionado(8);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_vib')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(8);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_vib'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(8);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a fluido electrico */}
      <Accordion
        expanded={accFluidoElectrico}
        onChange={() => setAccFluidoElectrico(!accFluidoElectrico)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_fe')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_fe'));
                  setIdTabSeleccionado(9);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayFluidoElectrico.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayFluidoElectrico.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_fe')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(9);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_fe'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(9);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a otro */}
      <Accordion expanded={accOtro} onChange={() => setAccOtro(!accOtro)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_fisico_otro')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_fisico_fe'));
                  setIdTabSeleccionado(10);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_fisico_otro')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(10);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_fisico_fe'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(10);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TabExposicionFisico;
