export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const RESET_MODAL = 'RESET_MODAL';
export const RESET_ALL_MODALS = 'RESET_ALL_MODALS';

interface SetActiveModalAction {
  type: typeof SET_ACTIVE_MODAL;
  payload: string;
}

interface ResetModalAction {
  type: typeof RESET_MODAL;
  payload: string;
}

interface ResetAllModalsAction {
  type: typeof RESET_ALL_MODALS;
}

export type ModalActionTypes = SetActiveModalAction | ResetModalAction | ResetAllModalsAction;
