import React, { useState, useEffect } from 'react';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { validacionSoloNumeros } from '@constants/validaciones';
import { RootState } from 'src/store';
import Comentarios from '@components/Comentarios/index';
import { antecedentesNoPatologicosGuardarHistoriaSexual } from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import { setHistoriaSexual } from '@actions/paciente/actions';
import { IHistoriaSexual } from './types';

function HistoriaSexual() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { historiaSexual } = useSelector(
    (state: RootState) => state.Paciente.expediente.antNoPatologicos,
  );
  const [historiaSexualTemp, setHistoriaSexualTemp] = useState<IHistoriaSexual>({
    loaded: false,
    activo: '',
    edad: 0,
    parejaNumero: 0,
    parejaTipo: '',
    condon: '',
    violencia: '',
    comentariosViolencia: '',
    comentarios: {
      entityMap: {},
      blocks: [],
    },
  });
  /** Disabled */
  const [disableEdadInicio, setDisableEdadInicio] = useState<boolean>(false);
  const [disableParejasUltimoAño, setDisableParejasUltimoAño] = useState<boolean>(false);
  const [disableCondon, setDisableCondon] = useState<boolean>(false);
  /** Catalogos */
  /** states de la snackbar */
  /** Handlers */
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>, select: string) => {
    const val = (event.target as HTMLInputElement).value;
    const newHistoriaSexual = { ...historiaSexualTemp };
    if (select === 'activo') {
      newHistoriaSexual.activo = val;
      if (val === 'T') {
        setDisableCondon(false);
        setDisableEdadInicio(false);
        setDisableParejasUltimoAño(false);
      } else {
        setDisableCondon(true);
        setDisableEdadInicio(true);
        setDisableParejasUltimoAño(true);
        newHistoriaSexual.edad = null;
        newHistoriaSexual.parejaNumero = null;
        newHistoriaSexual.parejaTipo = '';
        newHistoriaSexual.condon = '';
      }
    } else if (select === 'pareja') {
      newHistoriaSexual.parejaTipo = val;
    } else if (select === 'condon') {
      newHistoriaSexual.condon = val;
    } else if (select === 'violencia') {
      newHistoriaSexual.violencia = val;
      if (val === 'F') {
        newHistoriaSexual.comentariosViolencia = '';
      }
    }
    setHistoriaSexualTemp(newHistoriaSexual);
  };
  const handleChangeNumeros = (event: React.ChangeEvent<{ value: unknown }>, select: string) => {
    const val = event.target.value as string;
    const newHistoriaSexual = { ...historiaSexualTemp };
    if (val.length === 0 && select === 'edad') {
      newHistoriaSexual.edad = null;
    } else if (validacionSoloNumeros(val) && select === 'edad') {
      newHistoriaSexual.edad = parseInt(val, 10);
    }
    if (val.length === 0 && select === 'pareja') {
      newHistoriaSexual.parejaNumero = null;
    } else if (validacionSoloNumeros(val) && select === 'pareja') {
      newHistoriaSexual.parejaNumero = parseInt(val, 10);
    }
    setHistoriaSexualTemp(newHistoriaSexual);
  };

  // Guarda los datos del expediente del paciente
  const guardarDatosExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarHistoriaSexual({
          idMedico,
          idPaciente,
          idConsultorio,
          idConsulta,
          historiaSexual: {
            activo: historiaSexualTemp.activo,
            edad: historiaSexualTemp.edad,
            parejaNumero: historiaSexualTemp.parejaNumero,
            parejaTipo: historiaSexualTemp.parejaTipo,
            condon: historiaSexualTemp.condon,
            violencia: historiaSexualTemp.violencia,
            comentariosViolencia: historiaSexualTemp.comentariosViolencia,
            comentarios: historiaSexualTemp.comentarios,
          },
        }),
        successFunction: () => dispatch(setHistoriaSexual({ ...historiaSexualTemp, loaded: true })),
      }),
    );
  };
  const handleChangeComentarios = (newComVals: RawDraftContentState) => {
    setHistoriaSexualTemp({
      ...historiaSexualTemp,
      comentarios: newComVals,
    });
  };

  useEffect(() => {
    if (historiaSexual.loaded && !historiaSexualTemp.loaded) {
      setHistoriaSexualTemp(historiaSexual);
    }
  }, [historiaSexual]);

  return (
    <div className="relative py-4">
      <h2 className="text-blue-800 font-normal m-0 mb-4">{t('antecedentes-pnp-historias')}</h2>
      <div className="grid grid-cols-12 gap-4 mb-4 border-solid border border-gray-400 rounded p-3">
        <div className="col-span-2">
          <h3 className="mt-0 mb-3 text-gray-600 font-medium">
            {t('antecedentes-pnp-historias-activo')}
          </h3>
          <FormControl>
            <RadioGroup
              aria-label="activo"
              name="activo"
              value={historiaSexualTemp.activo}
              onChange={(e) => handleChangeRadio(e, 'activo')}
              row
            >
              <FormControlLabel value="T" control={<Radio color="primary" />} label={t('si')} />
              <FormControlLabel value="F" control={<Radio color="primary" />} label={t('no')} />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="col-span-2">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {t('antecedentes-pnp-historias-ei')}
          </h3>
          <div className="grid grid-cols-2">
            <TextField
              variant="outlined"
              value={historiaSexualTemp.edad ? historiaSexualTemp.edad.toString() : ''}
              fullWidth
              InputProps={{
                // endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
                inputProps: {
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: '2',
                },
              }}
              onChange={(e) => handleChangeNumeros(e, 'edad')}
              disabled={disableEdadInicio}
            />
          </div>
        </div>
        <div className="col-span-5">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {t('antecedentes-pnp-historias-parejas')}
          </h3>
          <div className="grid grid-cols-5 gap-4">
            <div>
              <TextField
                variant="outlined"
                value={
                  historiaSexualTemp.parejaNumero ? historiaSexualTemp.parejaNumero.toString() : ''
                }
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                  maxLength: '3',
                }}
                onChange={(e) => handleChangeNumeros(e, 'pareja')}
                disabled={disableParejasUltimoAño}
              />
            </div>
            <div className="col-span-4 mt-1">
              <FormControl disabled={disableParejasUltimoAño}>
                <RadioGroup
                  aria-label="parejas"
                  name="parejas"
                  value={historiaSexualTemp.parejaTipo}
                  onChange={(e) => handleChangeRadio(e, 'pareja')}
                  row
                >
                  <FormControlLabel
                    value="H"
                    control={<Radio color="primary" />}
                    label={t('hombre')}
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio color="primary" />}
                    label={t('mujer')}
                  />
                  <FormControlLabel
                    value="A"
                    control={<Radio color="primary" />}
                    label={t('ambos')}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <h3 className="mt-0 mb-3 text-gray-600 font-medium">
            {t('antecedentes-pnp-historias-condon')}
          </h3>
          <FormControl disabled={disableCondon}>
            <RadioGroup
              aria-label="condon"
              name="condon"
              value={historiaSexualTemp.condon}
              onChange={(e) => handleChangeRadio(e, 'condon')}
              row
            >
              <FormControlLabel
                value="S"
                control={<Radio color="primary" />}
                label={t('antecedentes-pnp-historias-siempre')}
              />
              <FormControlLabel
                value="N"
                control={<Radio color="primary" />}
                label={t('antecedentes-pnp-historias-aveces')}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="mb-4" />
      <div className="mb-4 border-solid border border-gray-400 rounded p-3">
        <div className="col-span-4 mb-2">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {t('antecedentes-pnp-historias-violencia')}
          </h3>
          <FormControl>
            <RadioGroup
              aria-label="violencia"
              name="violencia"
              value={historiaSexualTemp.violencia}
              onChange={(e) => handleChangeRadio(e, 'violencia')}
              row
            >
              <FormControlLabel value="T" control={<Radio color="primary" />} label={t('si')} />
              <FormControlLabel value="F" control={<Radio color="primary" />} label={t('no')} />
            </RadioGroup>
          </FormControl>
        </div>
        {historiaSexualTemp.violencia === 'T' ? (
          <div className="mb-4">
            <TextField
              id="com-viol"
              placeholder={t('antecedentes-pnp-historias-comentarios')}
              variant="outlined"
              fullWidth
              value={historiaSexualTemp.comentariosViolencia}
              onChange={(event) =>
                setHistoriaSexualTemp({
                  ...historiaSexualTemp,
                  comentariosViolencia: event.target.value,
                })}
              inputProps={{ maxLength: 500, autoComplete: 'off' }}
              multiline
              rows="4"
            />
          </div>
        ) : null}
      </div>
      <div className="mb-4" />
      <Comentarios
        key={
          historiaSexualTemp.comentarios.blocks.length
            ? historiaSexualTemp.comentarios.blocks[0].key
            : 'coms'
        }
        comentarios={historiaSexualTemp.comentarios}
        setComentarios={handleChangeComentarios}
        rows={4}
        maxLength={400}
        placeholder={t('comentarios')}
        lang="es"
        disabled={false}
      />
      <div className="text-right">
        <Button
          variant="contained"
          color="primary"
          type="button"
          size="large"
          onClick={guardarDatosExpediente}
          startIcon={<SaveIcon />}
        >
          {t('guardar')}
        </Button>
      </div>
    </div>
  );
}

export default HistoriaSexual;
