import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core/';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReactToPrint from 'react-to-print';
import { RootState } from 'src/store';
import DeleteDialog from '@components/DeleteDialog';
import { deleteDictamenesDictamenMedLab } from '@utils/deleteInfo';
import { setRequest } from '@actions/request/types';
import { IListaDictamenesMedLabProps } from './types';
import Certificado from '../Certificado';

function ListaDictamenes({
  dictamenesMedLab,
  setDictamenesMedLab,
  setObjDictamenMedLab,
  catRestricciones,
  catCausasNoAptitud,
  urlLogo,
}: IListaDictamenesMedLabProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const [index, setIndex] = useState<number>(-1);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const certificadoRef = useRef<HTMLDivElement[]>([]);

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  const editDictamenMedLab = () => {
    setObjDictamenMedLab({ ...dictamenesMedLab[index] });
  };

  const deleteDictamenMedLab = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: deleteDictamenesDictamenMedLab({
          idMedico,
          idPaciente,
          idOrganizacion,
          idConsultorio,
          idDictamen: dictamenesMedLab[index].id,
        }),
        successFunction: () => {
          const nuevaLista = [...dictamenesMedLab];
          nuevaLista.splice(index, 1);
          setDictamenesMedLab(nuevaLista);
        },
      }),
    );
  };

  useEffect(() => {
    certificadoRef.current = certificadoRef.current.slice(0, dictamenesMedLab.length);
  }, [dictamenesMedLab]);

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('eliminar_dictamen')}
        callBackAceptar={deleteDictamenMedLab}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('editar_dictamen')}
        callBackAceptar={editDictamenMedLab}
        callBackClose={handleEditAlertClose}
      />
      <div className="mt-4 bg-white rounded p-4 shadow">
        <h2 className="text-blue-800 font-normal">{t('lista_dictamenes')}</h2>
        <div className="pt-4">
          {dictamenesMedLab.map((dictamenMedLab, inx) => (
            <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
              <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
                <p className="font-normal m-0 text-gray-500">
                  {`${t('tipo_examen')}: ${dictamenMedLab.tipoExamen.label}`}
                </p>
                <p className="font-normal m-0 text-gray-500">
                  {`${
                    dictamenMedLab.tipoExamen.source === 'propio' ||
                    [2, 3].includes(dictamenMedLab.tipoExamen.id)
                      ? t('puesto_actual')
                      : t('puesto_aspirado')
                  }: ${dictamenMedLab.puesto.label}`}
                </p>
                {dictamenMedLab.tipoExamen.id !== 2 && (
                  <p className="font-normal m-0 text-gray-500">
                    {`${t('dictamen')}: ${dictamenMedLab.dictamen.label}`}
                  </p>
                )}
                {((dictamenMedLab.tipoExamen.id === 2 && dictamenMedLab.sinRestricciones) ||
                  (dictamenMedLab.tipoExamen.id !== 2 &&
                    [1, 4, 7].includes(dictamenMedLab.dictamen.id))) && (
                    <p className="font-normal m-0 text-gray-500">{t('sin_restricciones')}</p>
                )}
                {((dictamenMedLab.tipoExamen.id === 2 && !dictamenMedLab.sinRestricciones) ||
                  (dictamenMedLab.tipoExamen.id !== 2 &&
                    [2, 6, 9].includes(dictamenMedLab.dictamen.id))) && (
                    <>
                      <p className="font-normal m-0 text-gray-500">{`${t('restricciones')}:`}</p>
                      <p className="font-normal m-0 text-gray-500 pl-4">
                        {`- ${t('restriccion_total')}: ${catRestricciones
                          .filter((r) =>
                            dictamenMedLab.arrayRestriccionTotal.includes(`${r.source}-${r.id}`))
                          .map((r) => r.label)
                          .join(', ')}`}
                      </p>
                      <p className="font-normal m-0 text-gray-500 pl-4">
                        {`- ${t('restriccion_parcial')}: ${catRestricciones
                          .filter((r) =>
                            dictamenMedLab.arrayRestriccionParcial.includes(`${r.source}-${r.id}`))
                          .map((r) => r.label)
                          .join(', ')}`}
                      </p>
                      <p className="font-normal m-0 text-gray-500 pl-4">
                        {`- ${t('comentarios_restricciones')}: ${
                          dictamenMedLab.comentariosRestricciones
                        }`}
                      </p>
                    </>
                )}
                {dictamenMedLab.dictamen.id === 3 && (
                  <>
                    <p className="font-normal m-0 text-gray-500">{`${t('causa_no_aptitud')}:`}</p>
                    <p className="font-normal m-0 text-gray-500 pl-4">
                      {`- ${t(
                        'enfermedad_preexistente',
                      )}: ${dictamenMedLab.arrayCausaNoAptitudEnf.join(', ')}`}
                    </p>
                    <p className="font-normal m-0 text-gray-500 pl-4">
                      {`- ${t('otra')}: ${catCausasNoAptitud
                        .filter((c) =>
                          dictamenMedLab.arrayCausaNoAptitudOtra.includes(`${c.source}-${c.id}`))
                        .map((c) => c.label)
                        .join(', ')}`}
                    </p>
                  </>
                )}
                <p className="font-normal m-0 text-gray-500">
                  {`${t('recomendaciones')}: ${dictamenMedLab.recomendaciones}`}
                </p>
              </div>
              <div className="min-w-max self-center">
                {dictamenMedLab.idConsulta === idConsulta && (
                  <div className="text-right">
                    <IconButton
                      aria-label="edit"
                      color="primary"
                      onClick={() => {
                        setIndex(inx);
                        setEditAlert(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        setIndex(inx);
                        setDeleteAlert(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        color="primary"
                        size="small"
                        startIcon={<PrintIcon />}
                        // disabled={}
                      >
                        {t('imprimir_certificado')}
                      </Button>
                    )}
                    content={() => certificadoRef.current[inx]}
                  />
                </div>
              </div>
              <div className="hidden">
                <Certificado
                  certificado={dictamenMedLab}
                  catRestricciones={catRestricciones}
                  urlLogo={urlLogo}
                  ref={(el: HTMLDivElement) => {
                    certificadoRef.current[inx] = el;
                    return el;
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ListaDictamenes;
