import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IconButton,
  TextField,
  Button,
  InputAdornment,
  Checkbox,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/material.css';
import './styles.css';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import { KeyboardCapslock, Visibility, VisibilityOff } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import PersonIcon from '@material-ui/icons/Person';
// import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
// import PhoneIcon from '@material-ui/icons/Phone';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as FlagMx } from '@assets/flags/mx.svg';
import { ReactComponent as FlagUy } from '@assets/flags/uy.svg';
import { getPublicKey } from '@utils/getCatalogos';
import { sendSignUp } from '@utils/sendInfo';
import { setLoading } from '@actions/loading/actions';
import useStyles from '@common/styles';
import { constDia, constMes, constAnio } from '@constants/fechas';
import {
  validacionEmail,
  docIdLongitudMax,
  validacionDocIdOnChange,
  validacionDocId,
  validacionLongTelefono,
  validacionNombres,
  validacionApellidos,
  validacionIp,
} from '@constants/validaciones';
import {
  removeUserSession, setKeyBack, setToken, setUser,
} from '@utils/common';
import { setSnackComplete } from '@actions/snackbar/types';
import { setAuth } from '@actions/auth/actions';
import { regexPassword, validarFecha, validarFechaEsAnterior } from '@common/functions';
import { setEmailMe } from '@utils/commonStore';
import { str2AB } from '@common/base64Convert';
import { getMeMedico } from '@actions/me/actions';
import {
  ICatStrEstandar, IFechaObj, ICountryPhoneInput, IPhone,
} from '@common/types';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import { ISignUpProps } from './types';
import NuevoUsuarioNoDocId from '../ModalDialogs/NuevoUsuarioNoDocId';
import IngresarPacientePIN from '../ModalDialogs/IngresarPacientePIN';
import AvisoTokenOrganizacion from '../ModalDialogs/AvisoTokenOrganizacion';

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function SignUp({ esPaciente = false }: ISignUpProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const paises: ICatStrEstandar[] = [
    { id: 'mx', label: 'México' },
    { id: 'uy', label: 'Uruguay' },
  ];
  const getURLParameter = (param: string) => new URLSearchParams(location.search).get(param);
  const [ip, setIp] = useState<string>('');
  const [pais, setPais] = useState<string>(
    paises.find((p) => p.id === getURLParameter('country'))?.id || 'mx',
  );
  const [tokenOrg, setTokenOrg] = useState<string>('');
  const [showAvisoTokenOrg, setShowAvisoTokenOrg] = useState<boolean>(false);
  const [nombre, setNombre] = useState<string>('');
  const [primerApellido, setPrimerApellido] = useState<string>('');
  const [celular, setCelular] = useState<IPhone>({ codigo: '', numero: '' });
  const [fijo, setFijo] = useState<IPhone>({ codigo: '', numero: '' });
  const [docId, setDocId] = useState<string>('');
  const [noPoseeDocId, setNoPoseeDocId] = useState<boolean>(false);
  const [noRecuerdaDocId, setNoRecuerdaDocId] = useState<boolean>(false);
  const [showNoDocIdModal, setShowNoDocIdModal] = useState<boolean>(false);
  const [numeroDocumento, setNumeroDocumento] = useState<string>('');
  const [sexo, setSexo] = useState<string>('');
  const [fechaNac, setFechaNac] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [fechaIngreso, setFechaIngreso] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [numeroSeguro, setNumeroSeguro] = useState<string>('');
  const [numeroEmpleado, setNumeroEmpleado] = useState<string>('');
  const [showModalPinMedipraxi, setShowModalPinMedipraxi] = useState<boolean>(false);
  const [pinPaciente, setPinPaciente] = useState<string>('');
  const [verPassword, setVerPassword] = useState(false);
  const [verPasswordRepetido, setVerPasswordRepetido] = useState(false);
  const [kBack, setKBack] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [email2, setEmail2] = useState<string>('');
  const [contrasenia, setContrasenia] = useState<string>('');
  const [contraseniaRepetida, setContraseniaRepetida] = useState<string>('');
  const [isPasswordCapsLockOn, setIsPasswordCapsLockOn] = useState(false);
  const [isPasswordRepetidoCapsLockOn, setIsPasswordRepetidoCapsLockOn] = useState(false);
  const [leidoAvisoPrivacidad, setLeidoAvisoPrivacidad] = useState<boolean>(false);
  const erroresInitial = {
    nombres: { error: false, helperText: '' },
    primerApellido: { error: false, helperText: '' },
    docId: { error: false, helperText: '' },
    documento: { error: false, helperText: '' },
    fechaNac: { error: false, helperText: '' },
    fechaIngreso: { error: false, helperText: '' },
    email: { error: false, helperText: '' },
    email2: { error: false, helperText: '' },
    password: { error: false, helperText: '' },
    passwordRepetido: { error: false, helperText: '' },
    celular: { error: false, helperText: '' },
    fijo: { error: false, helperText: '' },
  };
  const [errores, setErrores] = useState(erroresInitial);

  const fetchIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org');
      const data = await response.text();
      if (validacionIp(data)) {
        setIp(data);
      }
    } catch (e) {
      /* empty */
    }
  };

  useEffect(() => {
    if (pais !== getURLParameter('country')) {
      history.push(`/signup${esPaciente ? '-patient' : ''}?country=${pais}`);
    }
  }, [pais]);
  useEffect(() => {
    if (fechaNac.dia && fechaNac.mes && fechaNac.anio) {
      if (
        !validarFecha(fechaNac.anio, fechaNac.mes, fechaNac.dia) ||
        !validarFechaEsAnterior(fechaNac.anio, fechaNac.mes, fechaNac.dia)
      ) {
        setErrores((prev) => ({
          ...prev,
          fechaNac: { error: true, helperText: t('fecha_invalida') },
        }));
      } else {
        setErrores((prev) => ({ ...prev, fechaNac: { error: false, helperText: '' } }));
      }
    }
  }, [fechaNac.anio, fechaNac.mes, fechaNac.dia]);
  useEffect(() => {
    if (fechaIngreso.dia && fechaIngreso.mes && fechaIngreso.anio) {
      if (
        !validarFecha(fechaIngreso.anio, fechaIngreso.mes, fechaIngreso.dia) ||
        !validarFechaEsAnterior(fechaIngreso.anio, fechaIngreso.mes, fechaIngreso.dia)
      ) {
        setErrores((prev) => ({
          ...prev,
          fechaIngreso: { error: true, helperText: t('fecha_invalida') },
        }));
      } else {
        setErrores((prev) => ({ ...prev, fechaIngreso: { error: false, helperText: '' } }));
      }
    }
  }, [fechaIngreso.anio, fechaIngreso.mes, fechaIngreso.dia]);
  useEffect(() => {
    fetchIP();
    setTokenOrg(getURLParameter('token') || '');
    const abortController = new AbortController();
    const { signal } = abortController;
    getPublicKey(signal).then((result: string) => setKBack(result));
    return () => abortController.abort();
  }, []);
  useEffect(() => {
    if (tokenOrg && tokenOrg.length > 0) {
      setShowAvisoTokenOrg(true);
    }
  }, [tokenOrg]);

  const cerrarNoDocIdModal = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setShowNoDocIdModal(false);
  };

  const handleChangeNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setNombre(val);
    if (validacionNombres(val) || !val.length) {
      setErrores((prev) => ({ ...prev, nombres: { error: false, helperText: '' } }));
    } else {
      setErrores((prev) => ({
        ...prev,
        nombres: { error: true, helperText: t('nombre_incorrecto') },
      }));
    }
  };

  const handleChangePrimerApellido = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setPrimerApellido(val);
    if (validacionApellidos(val) || !val.length) {
      setErrores((prev) => ({ ...prev, primerApellido: { error: false, helperText: '' } }));
    } else {
      setErrores((prev) => ({
        ...prev,
        primerApellido: { error: true, helperText: t('primer_apellido_incorrecto') },
      }));
    }
  };

  const handleChangeCelular = (value: string, country: ICountryPhoneInput) => {
    const longTel = validacionLongTelefono(pais);
    const { dialCode } = country;
    const cel = value.slice(dialCode.length);
    const newCelular = { ...celular };
    newCelular.numero = cel;
    newCelular.codigo = country.dialCode;
    setCelular(newCelular);
    if (!value.length || value.length === longTel) {
      setErrores((prev) => ({ ...prev, celular: { error: false, helperText: '' } }));
    }
  };

  const handleBlurCelular = () => {
    const longTel = validacionLongTelefono(pais);
    if (celular.numero.length && celular.numero.length < longTel) {
      setErrores((prev) => ({
        ...prev,
        celular: { error: true, helperText: t(`telefono_a_${longTel}_digitos`) },
      }));
    } else {
      setErrores((prev) => ({ ...prev, celular: { error: false, helperText: '' } }));
    }
  };

  const handleChangeFijo = (value: string, country: ICountryPhoneInput) => {
    const longTel = validacionLongTelefono(pais);
    const { dialCode } = country;
    const tel = value.slice(dialCode.length);
    const newFijo = { ...celular };
    newFijo.numero = tel;
    newFijo.codigo = country.dialCode;
    setFijo(newFijo);
    if (!value.length || value.length === longTel) {
      setErrores((prev) => ({ ...prev, fijo: { error: false, helperText: '' } }));
    }
  };

  const handleBlurFijo = () => {
    const longTel = validacionLongTelefono(pais);
    if (fijo.numero.length && fijo.numero.length < longTel) {
      setErrores((prev) => ({
        ...prev,
        fijo: { error: true, helperText: t(`telefono_a_${longTel}_digitos`) },
      }));
    } else {
      setErrores((prev) => ({ ...prev, fijo: { error: false, helperText: '' } }));
    }
  };

  const handleChangeSexo = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSexo(event.target.value as string);
  };

  const getNewFecha = (fecha: IFechaObj, val: string, tipo: string) => {
    const newFecha = { ...fecha };
    if (tipo === 'dia') {
      newFecha.dia = val;
    } else if (tipo === 'mes') {
      newFecha.mes = val;
    } else if (tipo === 'anio') {
      newFecha.anio = val;
    }
    return newFecha;
  };

  const handleChangeFechaNac = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    setFechaNac(getNewFecha(fechaNac, event.target.value as string, tipo));
  };

  const handleChangeFechaIngreso = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    setFechaIngreso(getNewFecha(fechaIngreso, event.target.value as string, tipo));
  };

  const handleChangeDocId = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setDocId(val);
    if (validacionDocIdOnChange(val, pais)) {
      setErrores((prev) => ({ ...prev, docId: { error: false, helperText: '' } }));
    } else {
      setErrores((prev) => ({
        ...prev,
        docId: { error: true, helperText: t(`doc_id_incorrecto_${pais}`) },
      }));
    }
  };

  const handleBlurDocId = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.length === 0 || validacionDocId(val, pais)) {
      setErrores((prev) => ({ ...prev, docId: { error: false, helperText: '' } }));
    } else {
      setErrores((prev) => ({
        ...prev,
        docId: { error: true, helperText: t(`doc_id_incorrecto_${pais}`) },
      }));
    }
  };

  const handleChangeNoPoseeDocId = () => {
    const noPosee = !noPoseeDocId;
    setDocId('');
    setNoPoseeDocId(noPosee);
    if (noPosee) {
      setNoRecuerdaDocId(false);
    }
  };

  const handleChangeNoRecuerdaDocId = () => {
    const noRecuerda = !noRecuerdaDocId;
    setDocId('');
    setNoRecuerdaDocId(noRecuerda);
    if (noRecuerda) {
      setNoPoseeDocId(false);
    }
  };

  const handleChangeNumeroDocumento = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNumeroDocumento(event.target.value as string);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setEmail(val.toLowerCase().trim());
    if (!val.length || validacionEmail(val)) {
      setErrores((prev) => ({ ...prev, email: { error: false, helperText: '' } }));
    }
  };

  const handleChangeEmail2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setEmail2(val.toLowerCase().trim());
    if (!val.length || validacionEmail(val)) {
      setErrores((prev) => ({ ...prev, email2: { error: false, helperText: '' } }));
    }
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContrasenia(val);
    if (val.length) {
      setErrores((preV) => ({
        ...preV,
        password: { helperText: '', error: false },
        passwordRepetido: { helperText: '', error: false },
      }));
    } else {
      setErrores((preV) => ({
        ...preV,
        password: { helperText: t('error_campo_en_blanco'), error: true },
      }));
    }
  };

  const handleChangePasswordRepetido = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContraseniaRepetida(val);
    if (val.length) {
      setErrores((preV) => ({
        ...preV,
        passwordRepetido: { helperText: '', error: false },
        password: { error: false, helperText: '' },
      }));
    } else {
      setErrores((preV) => ({
        ...preV,
        password: { helperText: t('error_campo_en_blanco'), error: true },
      }));
    }
  };

  const handleBlurContrasenia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.length) {
      if (val.length < 8) {
        setErrores((preV) => ({
          ...preV,
          password: {
            helperText: t('la_contrasenia_debe_tener_al_menos_8_caracteres'),
            error: true,
          },
        }));
      } else if (contraseniaRepetida.length && contraseniaRepetida !== val) {
        setErrores((preV) => ({
          ...preV,
          password: { helperText: t('las_contrasenias_no_coinciden'), error: true },
          passwordRepetido: { helperText: t('las_contrasenias_no_coinciden'), error: true },
        }));
      } else {
        setErrores((preV) => ({
          ...preV,
          password: { helperText: '', error: false },
          passwordRepetido: { helperText: '', error: false },
        }));
      }
    } else {
      setErrores((preV) => ({
        ...preV,
        password: { helperText: t('error_campo_en_blanco'), error: true },
      }));
    }
  };

  const handleBlurContraseniaRepetida = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.length) {
      if (contrasenia.length && contrasenia !== val) {
        setErrores((preV) => ({
          ...preV,
          password: { helperText: t('las_contrasenias_no_coinciden'), error: true },
          passwordRepetido: { helperText: t('las_contrasenias_no_coinciden'), error: true },
        }));
      } else {
        setErrores((prev) => ({
          ...prev,
          passwordRepetido: { helperText: '', error: false },
          password: { helperText: '', error: false },
        }));
      }
    } else {
      setErrores((preV) => ({
        ...preV,
        passwordRepetido: { helperText: t('error_campo_en_blanco'), error: true },
      }));
    }
  };

  const checkPasswordCapsLock = (event: any) => {
    setIsPasswordCapsLockOn(event.getModifierState('CapsLock'));
  };

  const checkPasswordRepetidoCapsLock = (event: any) => {
    setIsPasswordRepetidoCapsLockOn(event.getModifierState('CapsLock'));
  };

  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (email2 && val.trim() !== email2) {
      setErrores((preV) => ({
        ...preV,
        email: { helperText: t('email_incorrecto_repetir'), error: true },
      }));
    } else if (!val.length || validacionEmail(val)) {
      setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        email: { helperText: t('email_incorrecto'), error: true },
      }));
    }
  };

  const handleBlurEmail2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.trim() !== email) {
      setErrores((preV) => ({
        ...preV,
        email2: { helperText: t('email_incorrecto_repetir'), error: true },
      }));
    } else {
      setErrores((preV) => ({ ...preV, email2: { helperText: '', error: false } }));
    }
  };

  const handleClickVerPasswordRepetido = () => {
    setVerPasswordRepetido((prev) => !prev);
  };

  const handleClickVerPassword = () => {
    setVerPassword((prev) => !prev);
  };

  const handleMouseDownPasswordRepetido = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeHeLeido = () => {
    setLeidoAvisoPrivacidad((prev) => !prev);
  };

  const disableSave = () =>
    errores.email.error ||
    errores.email2.error ||
    errores.password.error ||
    errores.passwordRepetido.error ||
    !leidoAvisoPrivacidad ||
    !nombre ||
    !primerApellido ||
    !celular ||
    !email ||
    !contrasenia ||
    !contraseniaRepetida ||
    (esPaciente &&
      (errores.fechaNac.error ||
        errores.docId.error ||
        !sexo ||
        !fechaNac.dia ||
        !fechaNac.mes ||
        !fechaNac.anio ||
        (!noPoseeDocId && !noRecuerdaDocId && !docId) ||
        (noPoseeDocId && !numeroDocumento) ||
        (noRecuerdaDocId && !numeroEmpleado)));

  const registrarUsuario = async () => {
    // creadas el par de llaves del front (usuario)
    if (
      email === '' ||
      contrasenia === '' ||
      contraseniaRepetida === '' ||
      contrasenia !== contraseniaRepetida
    ) {
      if (email === '') {
        setErrores((preV) => ({
          ...preV,
          email: { error: true, helperText: t('error_campo_en_blanco') },
        }));
      } else if (contrasenia === '' || contraseniaRepetida === '') {
        setErrores((preV) => ({
          ...preV,
          password: { error: true, helperText: t('error_campo_en_blanco') },
        }));
      } else {
        setErrores((preV) => ({
          ...preV,
          password: { helperText: t('las_contrasenias_no_coinciden'), error: true },
          passwordRepetido: { helperText: t('las_contrasenias_no_coinciden'), error: true },
        }));
      }
      return;
    }
    if (!regexPassword(contrasenia)) {
      setErrores((preV) => ({
        ...preV,
        password: {
          error: true,
          helperText: t(
            'la_contraseña_debe_tener_al_menos_una_mayuscula_una_minuscula_un_numero_y_un_caracter_especial',
          ),
        },
      }));
      return;
    }
    setErrores(erroresInitial);
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt'],
    );
    // exportadas a JWK (Json Web Key)
    let publicKeyJwk;
    if (keyPair.publicKey) {
      publicKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.publicKey);
    } else {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('error_ocurrio_un_error_intentar_mas_tarde'),
        }),
      );
      return;
    }
    // const privateKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.privateKey); // a proposito
    // Headers de llaves PEM
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    // Importada la key desde el back
    const pemContents = kBack.substring(pemHeader.length, kBack.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      { name: 'RSA-OAEP', hash: 'SHA-256' },
      true,
      ['encrypt'],
    );
    const encriptarDato = (dato: string) =>
      window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, pemPublicKey, str2AB(dato));
    const emailC = await encriptarDato(email);
    const contraseniaC = await encriptarDato(contrasenia);
    const nombreC = await encriptarDato(nombre);
    const primerApellidoC = await encriptarDato(primerApellido);
    const celularC = await encriptarDato(celular.numero);
    const fijoC = await encriptarDato(fijo.numero);
    const sexoC = await encriptarDato(sexo);
    const fechaNacDiaC = await encriptarDato(fechaNac.dia);
    const fechaNacMesC = await encriptarDato(fechaNac.mes);
    const fechaNacAnioC = await encriptarDato(fechaNac.anio);
    const docIdC = await encriptarDato(docId);
    const numeroDocumentoC = await encriptarDato(numeroDocumento);
    const tokenOrgC = await encriptarDato(tokenOrg);
    const fechaIngresoDiaC = await encriptarDato(fechaIngreso.dia);
    const fechaIngresoMesC = await encriptarDato(fechaIngreso.mes);
    const fechaIngresoAnioC = await encriptarDato(fechaIngreso.anio);
    const numeroSeguroC = await encriptarDato(numeroSeguro);
    const numeroEmpleadoC = await encriptarDato(numeroEmpleado);
    const pinPacienteC = await encriptarDato(pinPaciente);
    const dataToSend = {
      ip,
      pais,
      esPaciente,
      email: arrayBufferToBase64(emailC),
      password: arrayBufferToBase64(contraseniaC),
      nombre: arrayBufferToBase64(nombreC),
      primerApellido: arrayBufferToBase64(primerApellidoC),
      ladaCel: celular.codigo,
      celular: arrayBufferToBase64(celularC),
      ladaFijo: fijo.codigo,
      fijo: arrayBufferToBase64(fijoC),
      sexo: arrayBufferToBase64(sexoC),
      fechaNacDia: arrayBufferToBase64(fechaNacDiaC),
      fechaNacMes: arrayBufferToBase64(fechaNacMesC),
      fechaNacAnio: arrayBufferToBase64(fechaNacAnioC),
      docId: arrayBufferToBase64(docIdC),
      noPoseeDocId,
      noRecuerdaDocId,
      numeroDocumento: arrayBufferToBase64(numeroDocumentoC),
      tokenOrg: arrayBufferToBase64(tokenOrgC),
      fechaIngresoDia: arrayBufferToBase64(fechaIngresoDiaC),
      fechaIngresoMes: arrayBufferToBase64(fechaIngresoMesC),
      fechaIngresoAnio: arrayBufferToBase64(fechaIngresoAnioC),
      numeroSeguro: arrayBufferToBase64(numeroSeguroC),
      numeroEmpleado: arrayBufferToBase64(numeroEmpleadoC),
      pinPaciente: arrayBufferToBase64(pinPacienteC),
      llave: publicKeyJwk,
    };
    dispatch(setLoading(true));
    sendSignUp(dataToSend)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setToken(result.datos.Bearer);
          setUser(result.datos.userSend);
          setEmailMe(email);
          setKeyBack(kBack);
          dispatch(
            setAuth({
              id: result.datos.userSend,
              acreditado: true,
              suscrito: esPaciente,
            }),
          );
          history.push(esPaciente ? '/ece' : '/home');
          dispatch(getMeMedico(email));
        } else {
          const newErrores = { ...erroresInitial };
          let errorMsg = '';
          switch (result.code) {
            case 409:
              errorMsg = t('email_registrado_en_medipraxi');
              newErrores.email = { error: true, helperText: t('email_registrado_en_medipraxi') };
              break;
            case 410:
              errorMsg = t('error_no_existe_registro');
              newErrores.docId = { error: true, helperText: t('error_no_existe_registro') };
              break;
            case 411:
              errorMsg = t('error_usuario_registrado');
              newErrores.documento = { error: true, helperText: t('error_usuario_registrado') };
              break;
            case 412:
              errorMsg = t('error_token__org_invalido');
              break;
            case 500:
              errorMsg = t('error_ocurrio_un_error_en_el_servidor');
              break;
            default:
              errorMsg = t('error_ocurrio_un_error_intentar_mas_tarde');
              break;
          }
          setErrores(newErrores);
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: errorMsg,
            }),
          );
          removeUserSession();
          setToken('');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleClickSignUp = async () => {
    if (noPoseeDocId) {
      setShowNoDocIdModal(true);
      return;
    }
    await registrarUsuario();
  };

  return (
    <>
      <NuevoUsuarioNoDocId // Modal que se abre cuando se intenta registrar sin documento de identidad
        open={showNoDocIdModal}
        callBackAceptar={registrarUsuario}
        callBackClose={cerrarNoDocIdModal}
        pais={pais}
      />
      <IngresarPacientePIN
        open={showModalPinMedipraxi}
        pass={pinPaciente}
        setPass={setPinPaciente}
        callBackAceptar={() => setShowModalPinMedipraxi(false)}
        callBackClose={() => setShowModalPinMedipraxi(false)}
      />
      <AvisoTokenOrganizacion
        open={showAvisoTokenOrg}
        callBackClose={() => setShowAvisoTokenOrg(false)}
      />
      <div className="absolute float-right right-0 top-2 w-20">
        <FormControl>
          <Select
            displayEmpty
            labelId="pais"
            id="pais"
            value={pais}
            onChange={(e) => setPais(e.target.value as string)}
            inputProps={{
              name: 'Pais',
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 375,
                },
              },
            }}
            renderValue={() => (
              <Box sx={{ display: 'flex' }}>
                <SvgIcon className="mr-2">
                  {pais === 'mx' && <FlagMx />}
                  {pais === 'uy' && <FlagUy />}
                </SvgIcon>
              </Box>
            )}
          >
            {paises.map((p, inx) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem value={p.id} key={inx}>
                {p.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="absolute w-full inset-y-16">
        <div className="relative md:mx-auto md:w-8/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12">
          <div className="absolute shadow-lg bg-white rounded-xl mt-16 lg:mt-0 py-6 px-10 border border-solid border-gray-200">
            <div className="absolute top-0 right-0 mt-3 mr-3">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  history.push(esPaciente ? '/login-patient' : '/login');
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="w-full text-center">
              <h2 className="text-gray-900 font-normal mb-0">{t('registro_de_usuarios')}</h2>
            </div>
            <div className="pt-4 pb-2 my-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
                <TextField
                  fullWidth
                  variant="outlined"
                  label={`${t('nombre(s)')}*`}
                  value={nombre}
                  error={errores.nombres.error}
                  helperText={errores.nombres.helperText}
                  onChange={handleChangeNombre}
                  InputProps={{
                    endAdornment: (
                      <div className="text-gray-500">
                        <PersonIcon color="inherit" />
                      </div>
                    ),
                    inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 50 },
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label={`${t('primer_apellido')}*`}
                  value={primerApellido}
                  error={errores.primerApellido.error}
                  helperText={errores.primerApellido.helperText}
                  onChange={handleChangePrimerApellido}
                  InputProps={{
                    endAdornment: (
                      <div className="text-gray-500">
                        <PersonIcon color="inherit" />
                      </div>
                    ),
                    inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 50 },
                  }}
                />
                <PhoneInput
                  containerClass="phone-input-labelled"
                  placeholder={`${t('telefono_celular')}*`}
                  specialLabel={`${t('telefono_celular')}*`}
                  preferredCountries={[pais, 'us']}
                  country={pais}
                  localization={es}
                  enableSearch
                  searchPlaceholder={t('buscar')}
                  searchNotFound={t('sin_resultados')}
                  inputStyle={{ width: '100%' }}
                  value={`${celular.codigo}${celular.numero}`}
                  onChange={handleChangeCelular}
                  onBlur={handleBlurCelular}
                />
                <PhoneInput
                  containerClass="phone-input-labelled"
                  placeholder={t('telefono_fijo')}
                  specialLabel={t('telefono_fijo')}
                  preferredCountries={[pais, 'us']}
                  country={pais}
                  localization={es}
                  enableSearch
                  searchPlaceholder={t('buscar')}
                  searchNotFound={t('sin_resultados')}
                  inputStyle={{ width: '100%' }}
                  value={`${fijo.codigo}${fijo.numero}`}
                  onChange={handleChangeFijo}
                  onBlur={handleBlurFijo}
                />
              </div>
              {esPaciente && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 my-8">
                    <div>
                      <span className="text-gray-500">{`${t('sexo')}*`}</span>
                      <div className="mt-2">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-label="sexo"
                            name="sexo"
                            value={sexo}
                            onChange={handleChangeSexo}
                          >
                            <FormControlLabel
                              value="H"
                              control={<Radio color="primary" />}
                              label={t('hombre')}
                            />
                            <FormControlLabel
                              value="M"
                              control={<Radio color="primary" />}
                              label={t('mujer')}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <div className="mt-6 md:mt-0">
                      <span className="text-gray-500">{`${t('fecha_nacimiento')}*`}</span>
                      <div className="grid grid-cols-12 gap-2 mt-1">
                        <div className="col-span-3">
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label-dia">{t('dia')}</InputLabel>
                            <Select
                              labelId="label-dia"
                              id="select-dia"
                              value={fechaNac.dia}
                              onChange={(e) => handleChangeFechaNac(e, 'dia')}
                              label={t('dia')}
                              error={errores.fechaNac.error}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 375,
                                  },
                                },
                              }}
                              displayEmpty
                            >
                              <MenuItem value={0} disabled>
                                <span className="text-gray-400 font-light">{t('selecciona')}</span>
                              </MenuItem>
                              {constDia.map((diaSingle) => (
                                <MenuItem value={diaSingle} key={diaSingle}>
                                  {diaSingle}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-span-5">
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label-mes">{t('mes')}</InputLabel>
                            <Select
                              labelId="label-mes"
                              id="select-mes"
                              value={fechaNac.mes}
                              onChange={(e) => handleChangeFechaNac(e, 'mes')}
                              label={t('mes')}
                              error={errores.fechaNac.error}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 375,
                                  },
                                },
                              }}
                            >
                              <MenuItem value={0} disabled>
                                <span className="text-gray-400 font-light">{t('selecciona')}</span>
                              </MenuItem>
                              {constMes.map((mesSingle, indx) => (
                                <MenuItem value={indx + 1} key={mesSingle}>
                                  {mesSingle}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText className={classes.absoluteBottom}>
                              {errores.fechaNac.helperText}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <div className="col-span-4">
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="label-año">{t('anio')}</InputLabel>
                            <Select
                              labelId="label-año"
                              id="select-año"
                              value={fechaNac.anio}
                              onChange={(e) => handleChangeFechaNac(e, 'anio')}
                              label={t('anio')}
                              error={errores.fechaNac.error}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 375,
                                  },
                                },
                              }}
                            >
                              <MenuItem value={0} disabled>
                                <span className="text-gray-400 font-light">{t('selecciona')}</span>
                              </MenuItem>
                              {constAnio().map((anioSingle) => (
                                <MenuItem key={anioSingle} value={anioSingle}>
                                  {anioSingle}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                    <div className="col-span-2 ">
                      {noPoseeDocId ? (
                        <TextField
                          variant="outlined"
                          value={numeroDocumento}
                          label={t('numero_documento')}
                          onChange={handleChangeNumeroDocumento}
                          fullWidth
                          error={noPoseeDocId && errores.documento.error}
                          helperText={noPoseeDocId && errores.documento.helperText}
                          inputProps={{
                            maxLength: 20,
                            autoComplete: 'off',
                            'aria-autocomplete': 'none',
                          }}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          variant="outlined"
                          label={`${t(`doc_id_${pais}`)}*`}
                          value={docId}
                          onChange={handleChangeDocId}
                          onBlur={handleBlurDocId}
                          error={!noPoseeDocId && errores.docId.error}
                          helperText={!noPoseeDocId && errores.docId.helperText}
                          inputProps={{
                            maxLength: docIdLongitudMax(pais),
                            autoComplete: 'off',
                            'aria-autocomplete': 'none',
                            className: 'uppercase',
                          }}
                          disabled={noRecuerdaDocId}
                        />
                      )}
                    </div>
                    {tokenOrg && (
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={noRecuerdaDocId}
                              onChange={handleChangeNoRecuerdaDocId}
                              color="primary"
                            />
                          )}
                          label={t(`no_recuerdo_doc_id_${pais}`)}
                        />
                      </FormControl>
                    )}
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={noPoseeDocId}
                            onChange={handleChangeNoPoseeDocId}
                            color="primary"
                          />
                        )}
                        label={t(`no_poseo_doc_id_${pais}`)}
                      />
                    </FormControl>
                  </div>
                </>
              )}
              {tokenOrg.length > 0 && tokenOrg.slice(-1) === 'I' && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 mt-8">
                  <div>
                    <span className="text-gray-500">{`${t('fecha_de_ingreso')}`}</span>
                    <div className="grid grid-cols-12 gap-2 mt-1">
                      <div className="col-span-3">
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="label-dia">{t('dia')}</InputLabel>
                          <Select
                            labelId="label-dia"
                            id="select-dia"
                            value={fechaIngreso.dia}
                            onChange={(e) => handleChangeFechaIngreso(e, 'dia')}
                            label={t('dia')}
                            error={errores.fechaIngreso.error}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 375,
                                },
                              },
                            }}
                            displayEmpty
                          >
                            <MenuItem value={0} disabled>
                              <span className="text-gray-400 font-light">{t('selecciona')}</span>
                            </MenuItem>
                            {constDia.map((diaSingle) => (
                              <MenuItem value={diaSingle} key={diaSingle}>
                                {diaSingle}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-span-5">
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="label-mes">{t('mes')}</InputLabel>
                          <Select
                            labelId="label-mes"
                            id="select-mes"
                            value={fechaIngreso.mes}
                            onChange={(e) => handleChangeFechaIngreso(e, 'mes')}
                            label={t('mes')}
                            error={errores.fechaIngreso.error}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 375,
                                },
                              },
                            }}
                          >
                            <MenuItem value={0} disabled>
                              <span className="text-gray-400 font-light">{t('selecciona')}</span>
                            </MenuItem>
                            {constMes.map((mesSingle, indx) => (
                              <MenuItem value={indx + 1} key={mesSingle}>
                                {mesSingle}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText className={classes.absoluteBottom}>
                            {errores.fechaIngreso.helperText}
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div className="col-span-4">
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="label-año">{t('anio')}</InputLabel>
                          <Select
                            labelId="label-año"
                            id="select-año"
                            value={fechaIngreso.anio}
                            onChange={(e) => handleChangeFechaIngreso(e, 'anio')}
                            label={t('anio')}
                            error={errores.fechaIngreso.error}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 375,
                                },
                              },
                            }}
                          >
                            <MenuItem value={0} disabled>
                              <span className="text-gray-400 font-light">{t('selecciona')}</span>
                            </MenuItem>
                            {constAnio().map((anioSingle) => (
                              <MenuItem key={anioSingle} value={anioSingle}>
                                {anioSingle}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block" />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={`${t('numero_seguro_social')}`}
                    value={numeroSeguro}
                    onChange={(e) => setNumeroSeguro(e.target.value as string)}
                    InputProps={{
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: 50,
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={`${t('numero_empleado')}${noRecuerdaDocId ? '*' : ''}`}
                    value={numeroEmpleado}
                    onChange={(e) => setNumeroEmpleado(e.target.value as string)}
                    InputProps={{
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: 50,
                      },
                    }}
                  />
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 mt-8">
                <div className="col-span-2">
                  <TextField
                    error={errores.email.error}
                    fullWidth
                    variant="outlined"
                    label={`${t('correo_electronico')}*`}
                    value={email}
                    onChange={handleChangeEmail}
                    onBlur={handleBlurEmail}
                    onCopy={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onCut={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    helperText={errores.email.helperText}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    InputProps={{
                      endAdornment: (
                        <div className="text-gray-500">
                          <EmailIcon color="inherit" />
                        </div>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: 100,
                      },
                    }}
                    type="email"
                  />
                </div>
                <div className="col-span-2">
                  <TextField
                    error={errores.email2.error}
                    fullWidth
                    variant="outlined"
                    label={`${t('repetir_correo_electronico')}*`}
                    value={email2}
                    onChange={handleChangeEmail2}
                    onBlur={handleBlurEmail2}
                    onCopy={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onCut={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    helperText={errores.email2.helperText}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    InputProps={{
                      endAdornment: (
                        <div className="text-gray-500">
                          <EmailIcon color="inherit" />
                        </div>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: 100,
                      },
                    }}
                    type="email"
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    error={errores.password.error}
                    label={`${t('contrasenia')}*`}
                    variant="outlined"
                    type={verPassword ? 'text' : 'password'}
                    fullWidth
                    value={contrasenia}
                    onChange={handleChangePassword}
                    onBlur={handleBlurContrasenia}
                    onKeyUp={checkPasswordCapsLock}
                    onKeyDown={checkPasswordCapsLock}
                    onCopy={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onCut={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    helperText={
                      errores.password.error
                        ? errores.password.helperText
                        : t(
                          'la_contraseña_debe_tener_al_menos_una_mayuscula_una_minuscula_un_numero_y_un_caracter_especial',
                        )
                    }
                    InputProps={{
                      endAdornment: (
                        <div className="text-blue-500">
                          <InputAdornment position="end">
                            {isPasswordCapsLockOn ? (
                              <Tooltip title={t('mayusculas_activadas')}>
                                <KeyboardCapslock />
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            <IconButton
                              onClick={handleClickVerPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              color="inherit"
                            >
                              {verPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        </div>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '50',
                      },
                    }}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    error={errores.passwordRepetido.error}
                    label={`${t('repetir_contrasenia')}*`}
                    variant="outlined"
                    type={verPasswordRepetido ? 'text' : 'password'}
                    fullWidth
                    value={contraseniaRepetida}
                    onChange={handleChangePasswordRepetido}
                    onBlur={handleBlurContraseniaRepetida}
                    onKeyDown={checkPasswordRepetidoCapsLock}
                    onKeyUp={checkPasswordRepetidoCapsLock}
                    onCopy={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onCut={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    helperText={errores.passwordRepetido.helperText}
                    InputProps={{
                      endAdornment: (
                        <div className="text-blue-500">
                          <InputAdornment position="end">
                            {isPasswordRepetidoCapsLockOn ? (
                              <Tooltip title={t('mayusculas_activadas')}>
                                <KeyboardCapslock />
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            <IconButton
                              onClick={handleClickVerPasswordRepetido}
                              onMouseDown={handleMouseDownPasswordRepetido}
                              edge="end"
                              color="inherit"
                            >
                              {verPasswordRepetido ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        </div>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '50',
                      },
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
                <div className="mt-2">
                  <Checkbox
                    checked={leidoAvisoPrivacidad}
                    onChange={handleChangeHeLeido}
                    name="he-leido-el-aviso-de-privacidad"
                    color="primary"
                  />
                  <span>
                    <span>{`${t('he_leido_el')} `}</span>
                    <a
                      href={`${
                        esPaciente
                          ? 'https://medipraxi.com/aviso-de-privacidad/'
                          : 'https://medipraxi.com/privacidad-medicos/'
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('aviso_de_privacidad')}
                    </a>
                  </span>
                </div>
                {esPaciente && !tokenOrg && (
                  <div className="flex flex-row mt-2 justify-end">
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={() => setShowModalPinMedipraxi(true)}
                    >
                      {t('tengo_pin_medipraxi')}
                    </Button>
                    <HtmlTooltip title={<p>{t('tengo_pin_medipraxi_descripcion')}</p>}>
                      <IconButton size="small">
                        <InfoIcon color="primary" fontSize="small" />
                      </IconButton>
                    </HtmlTooltip>
                  </div>
                )}
              </div>
              <div className="text-right mt-4">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleClickSignUp}
                  disabled={disableSave()}
                >
                  {esPaciente ? t('guardar') : t('continuar')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
