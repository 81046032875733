import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select, TextField, FormControl, InputLabel, MenuItem,
} from '@material-ui/core';
import { capitalize } from '@common/functions';
import { validacionHoraDoce } from '@constants/validaciones';
import { IMcpa } from '../../../TabsPaciente/MCPA/types';
import { IBuilderHoraProps, IError } from './types';

function BuilderHora({ componenteHora, setMcpa, id }: IBuilderHoraProps) {
  const { t } = useTranslation();
  const [err, setErr] = useState<IError>({ helperText: '', error: false });
  /** States del functional component */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */
  const handleBlurHora = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionHoraDoce(val)) {
      setErr({ helperText: '', error: false });
    } else {
      setErr({ helperText: t('hora_incorrecta'), error: true });
    }
  };

  const handleChangeHora = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (/^\d+:?\d*$/.test(val) || val === '') {
      setMcpa((preMcpa: IMcpa) => ({
        ...preMcpa,
        componentes: preMcpa.componentes.map((preComp) => {
          if (preComp.id === id) {
            return {
              ...preComp,
              constructorComponente: {
                ...preComp.constructorComponente,
                constructor: { ...preComp.constructorComponente.constructor, valor: val },
                valores: { ...preComp.constructorComponente.valores, valor: val },
              },
            };
          }
          return preComp;
        }),
      }));
      setErr({ helperText: '', error: false });
    }
  };

  const handleChangeHorario = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setMcpa((preMcpa: IMcpa) => ({
      ...preMcpa,
      componentes: preMcpa.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, hora: { horario: val } },
              valores: { ...preComp.constructorComponente.valores, hora: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMcpa((preMcpa: IMcpa) => ({
      ...preMcpa,
      componentes: preMcpa.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, comentarios: val },
              valores: { ...preComp.constructorComponente.valores, textoLibre: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="flex mb-2 items-end">
            <h3 className="pr-4 m-0 text-gray-600 font-medium">
              {capitalize(componenteHora.datosComponente.titulo)}
            </h3>
            <div className="text-gray-500">
              {componenteHora.datosComponente.descripcion
                ? `* ${componenteHora.datosComponente.descripcion}`
                : ''}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2 mb-3">
            <TextField
              label={t('hora')}
              variant="outlined"
              fullWidth
              value={componenteHora.constructor.valor}
              onChange={handleChangeHora}
              onBlur={handleBlurHora}
              error={err.error}
              helperText={err.helperText}
              inputProps={{ autoComplete: 'off', maxLength: 5 }}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{t('horario')}</InputLabel>
              <Select
                label={t('horario')}
                value={componenteHora.constructor.hora!.horario}
                onChange={handleChangeHorario}
              >
                <MenuItem value="a.m.">a.m.</MenuItem>
                <MenuItem value="p.m.">p.m.</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <TextField
          variant="outlined"
          rows={4}
          multiline
          fullWidth
          label={t('comentarios')}
          inputProps={{ autoComplete: 'off', maxLength: 300 }}
          value={componenteHora.constructor.comentarios}
          onChange={handleChangeComentarios}
        />
      </div>
    </div>
  );
}

export default React.memo(BuilderHora);
