import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { INotasAdicionalesMCPAProps } from './types';
import { IMcpa } from '../../types';

function NotasAdicionalesMCPA({ incluido, comentarios, setMcpa }: INotasAdicionalesMCPAProps) {
  const { t } = useTranslation();
  const handleChangeTexto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMcpa((preMcpa: IMcpa) => ({
      ...preMcpa,
      opciones: {
        ...preMcpa.opciones,
        notasAdicionales: {
          ...preMcpa.opciones.notasAdicionales,
          comentarios: val,
        },
      },
    }));
  };

  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200">
      <div className="flex justify-between items-center">
        <h3 className="m-0 text-blue-800 font-medium">{t('notas_adicionales')}</h3>
      </div>
      {incluido && (
        <div className="mt-2">
          <TextField
            value={comentarios}
            fullWidth
            rows={3}
            variant="outlined"
            multiline
            onChange={handleChangeTexto}
            InputProps={{
              inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 500 },
            }}
          />
        </div>
      )}
    </div>
  );
}

const MemoNotasAdicionalesMCPA = React.memo(NotasAdicionalesMCPA);

export default MemoNotasAdicionalesMCPA;
