import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as GraficoCafeina } from '@assets/img/Grafico_estimacion.svg';
import SimpleAlert from '../SimpleAlerta';
import { INivelCafeinaProps } from './types';

function NivelCafeinaAlerta({ open, setOpen }: INivelCafeinaProps) {
  const { t } = useTranslation();

  return (
    <SimpleAlert open={open} setOpen={setOpen}>
      <div className="bg-white rounded w-10/12 max-w-screen-lg mx-auto p-4 max-h-screen overflow-y-scroll">
        <div className="flex justify-between">
          <h2 className="text-blue-600 font-normal mt-0">{t('estimacion_de_nivel_de_cafeina')}</h2>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="flex items-center">
          <GraficoCafeina />
          <div className="w-1/2 lg:w-full">
            <p>{t('las_ingestas_de_hasta_400_mg_no_tienen_Efectos_perjudiciales_para_adultos')}</p>
            <p>
              {t(
                'la_ingesta_de_Cafeina_hasta_200mg_por_dia_durante_el_embarazo_no_planteean_problemas_para_el_feto',
              )}
            </p>
          </div>
        </div>
        <p>
          <span className="font-bold">{`${t('adaptado_de')}:`}</span>
          &nbsp;
          {t('efsa_explica_la_evacuacion_de_riesgos')}
        </p>
        <p>{t('todas_las_cifran_son_aproximadaS_en_contenido_cafeina')}</p>
        <h4>{`${t('referencias')}:`}</h4>
        <p>{t('1_referencia_efsa_nda_panel_option_on_the_safety_caffeine')}</p>
        <p>
          {`${t('2_referencia_efsa_explica_la_evalucion_de_riesgos_seguridad_alimentaria')}:`}
          &nbsp;
          <a
            href="http://www.cibr.es/ka/apps/cibr/docs/folleto-cafeina-trad.-efsa.pdf"
            target="_blank"
            rel="noreferrer"
          >
            http://www.cibr.es/ka/apps/cibr/docs/folleto-cafeina-trad.-efsa.pdf
          </a>
        </p>
      </div>
    </SimpleAlert>
  );
}

export default NivelCafeinaAlerta;
