import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IListaAccidentesLaboralesProps } from './types';

function ListaAccidentesLaborales({
  fecha,
  lugar,
  turno,
  causa,
  naturaleza,
  segmento,
  ubicacion,
  gravedad,
  porcentajeIncapacidad,
  nom035,
  seguimiento,
  riesgo,
  comentarios,
  inx,
  actualizarValorArrayAccidentesLaborales,
  borrarValorArrayAccidentesLaborales,
  discapacidad,
  diasIncapacidad,
}: IListaAccidentesLaboralesProps) {
  const { t } = useTranslation();
  let txtRadioTitulo = '';
  if (discapacidad && discapacidad === 'sin') {
    txtRadioTitulo = t('incapacidades_tipo_sin');
  } else if (discapacidad && discapacidad === 'temporal') {
    txtRadioTitulo = t('incapacidades_tipo_temporal');
  } else if (discapacidad && discapacidad === 'parcial') {
    txtRadioTitulo = t('incapacidades_tipo_parcial');
  } else if (discapacidad && discapacidad === 'total') {
    txtRadioTitulo = t('incapacidades_tipo_total');
  }
  return (
    <div className="shadow-md border border-solid border-gray-200 rounded mb-4 flex justify-between items-center">
      <div className="w-full">
        <div className="flex flex-row w-full pt-2">
          <h3 className="m-0 font-normal text-blue-900 pl-4">
            {`${t('accidentes_accidente_laboral_misma_empresa')} ${inx + 1}`}
          </h3>
          <h3 className="m-0 font-normal text-gray-900 pl-4">
            {`${t('accidentes_fecha')}: ${fecha}`}
          </h3>
        </div>
        {lugar && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_lugar')}: `}</b>
            {lugar}
          </p>
        )}
        {turno && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_turno')}: `}</b>
            {turno}
          </p>
        )}
        <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
          <b className="text-gray-900">{`${t('accidentes_causa')}: `}</b>
          {causa}
        </p>
        <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
          <b className="text-gray-900">{`${t('accidentes_lesion_naturaleza')}: `}</b>
          {naturaleza}
        </p>
        {segmento && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_lesion_segmento')}: `}</b>
            {segmento}
          </p>
        )}
        {ubicacion && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_lesion_ubicacion')}: `}</b>
            {ubicacion}
          </p>
        )}
        {gravedad && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_lesion_gravedad')}: `}</b>
            {gravedad}
          </p>
        )}
        {porcentajeIncapacidad && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_porcentaje_incapacidad')}: `}</b>
            {porcentajeIncapacidad}
          </p>
        )}
        {nom035 && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_encuesta_nom_035')}: `}</b>
            {nom035}
          </p>
        )}
        {seguimiento && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_amerita_seguimiento')}: `}</b>
            {seguimiento}
          </p>
        )}
        {riesgo && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('incapacidades_tipo_riesgo')}: `}</b>
            {riesgo}
          </p>
        )}
        {discapacidad && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('accidentes_incapacidad')}: `}</b>
            {txtRadioTitulo}
          </p>
        )}
        {diasIncapacidad && (
          <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
            <b className="text-gray-900">{`${t('incapacidades_dias')}: `}</b>
            {diasIncapacidad || ''}
          </p>
        )}
        <p className="m-0 pl-4 py-2 font-normal text-gray-500">
          <b className="text-gray-900">{`${t('comentarios')}: `}</b>
          {comentarios}
        </p>
      </div>
      <div className="min-w-max self-center">
        <IconButton
          aria-label="edit"
          onClick={() => actualizarValorArrayAccidentesLaborales(inx)}
          color="primary"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => borrarValorArrayAccidentesLaborales(inx)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaAccidentesLaborales;
