import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton, FormControl, Select, MenuItem, TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from '@components/DeleteDialog';
import { validacionSoloNumeros } from '@constants/validaciones';
import ITipoDeAlcoholProps from './types';
import { ITipoAlcohol } from '../../types';

const arrTipoAlcohol: ITipoAlcohol[] = [
  { label: 'cerveza_al_5_lata', grUnidad: 13 },
  { label: 'cerveza_al_5_caguama', grUnidad: 36.92 },
  { label: 'pulque_al_6_3_jarrito', grUnidad: 13 },
  { label: 'ron_al_38_vaso', grUnidad: 13 },
  { label: 'ron_al_40_botela_750', grUnidad: 235.5 },
  { label: 'ron_al_40_botella_980', grUnidad: 307.7 },
  { label: 'vino_al_12_copa', grUnidad: 13 },
  { label: 'vino_al_12_botella', grUnidad: 70.6 },
  { label: 'coctel_destilado_al_45', grUnidad: 13 },
  { label: 'tequila_caballito', grUnidad: 13 },
  { label: 'tequila_al_38_botella', grUnidad: 283.4 },
  { label: 'whisky_al_40_vaso', grUnidad: 13 },
  { label: 'whisky_al_40_botella_750', grUnidad: 235.5 },
  { label: 'vodka_al_40_vaso', grUnidad: 13 },
  { label: 'vodka_al_40_botella_450', grUnidad: 141.31 },
  { label: 'vodka_al_40_botella_750', grUnidad: 235.56 },
  { label: 'otro_(trago_estandar)', grUnidad: 13 },
];

function TipoDeAlcohol(props: ITipoDeAlcoholProps) {
  const {
    hayCambios, toxicomania, setToxicomania, index, handleDeleteRow,
  } = props;
  const { t } = useTranslation();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const handleChangeTipoDeAlcohol = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.map(
      (item: ITipoAlcohol, j: number) => {
        if (j === index) {
          return arrTipoAlcohol.find((el) => el.label === val) || { label: '', grUnidad: 0 };
        }
        return item;
      },
    );
    setToxicomania({
      ...toxicomania,
      alcohol: {
        ...toxicomania.alcohol,
        arrTipoAlcohol: {
          ...toxicomania.alcohol.arrTipoAlcohol,
          tipoAlcohol: arr,
        },
      },
    });
  };

  const handleChangeCantidadAlcohol = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      const arr = toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol.map((item, j) => {
        if (j === index) {
          return val;
        }
        return item;
      });
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          arrTipoAlcohol: {
            ...toxicomania.alcohol.arrTipoAlcohol,
            cantidadAlcohol: arr,
          },
        },
      });
    }
  };

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={`${t('_esta_seguro_que_desea_eliminar_el_tipo_de_alcohol')}${
          toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol[index].label
            ? ` ${t(toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol[index].label)}`
            : ''
        }${t('_?')}`}
        callBackAceptar={() => handleDeleteRow(index)}
        callBackClose={handleAlertClose}
      />
      <div className="grid grid-cols-7 px-4 pb-4 mt-4 border border-solid border-gray-300 rounded gap-x-4">
        <h3 className="col-span-4 text-gray-600 font-medium">{t('tipo_de_alcohol')}</h3>
        <h3 className="col-span-2 text-gray-600 font-medium">{t('cantidad')}</h3>
        <div className="row-span-2 flex items-center justify-center">
          <IconButton color="primary" onClick={() => setDeleteAlert(true)} disabled={hayCambios}>
            <DeleteIcon />
          </IconButton>
        </div>
        <div className="col-span-4">
          <FormControl variant="outlined" fullWidth>
            <Select
              displayEmpty
              value={toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol[index].label}
              onChange={handleChangeTipoDeAlcohol}
              disabled={hayCambios}
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">{t('selecciona')}</span>
              </MenuItem>
              {arrTipoAlcohol.map((singleTipoAlcohol) => (
                <MenuItem key={singleTipoAlcohol.label} value={singleTipoAlcohol.label}>
                  {t(singleTipoAlcohol.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-span-2">
          <TextField
            value={toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol[index]}
            onChange={handleChangeCantidadAlcohol}
            variant="outlined"
            InputProps={{
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '2',
              },
            }}
            fullWidth
            disabled={hayCambios}
          />
        </div>
      </div>
    </>
  );
}

export default TipoDeAlcohol;
