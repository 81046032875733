import { ILoadingState, LoadingActionTypes, SET_LOADING } from '@actions/loading/types';

const initialState: ILoadingState = {
  loading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const LoadingReducer = (state = initialState, action: LoadingActionTypes) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default LoadingReducer;
