import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Stepper, Step, StepLabel, StepIconProps, Button, StepConnector,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactComponent as MedicoPerfil } from '@assets/icons/medico_perfil.svg';
import { ReactComponent as MedicoConsultorio } from '@assets/icons/medico_consultorio.svg';
import { ReactComponent as MedicoReceta } from '@assets/icons/medico_receta.svg';
import { ReactComponent as MedicoAsistentes } from '@assets/icons/medico_asistentes.svg';
import { RootState } from 'src/store';
import Perfil from './Perfil/index';
import MisConsultorios from './MisConsultorios';
import ConfigurarReceta from './ConfigurarReceta';

dayjs.locale('es');

const MedicoInformacionComponente = () => {
  const { t } = useTranslation();
  const {
    consultorios,
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    idMedico, nombre, primerApellido, carrera, rol, secciones,
  } = useSelector(
    (state: RootState) => state.Me,
  );
  const [steps] = useState(
    secciones.find((valor) => valor.idSeccion === 48)
      ? [t('perfil'), t('medico_consultorios'), t('medico_receta')]
      : [t('perfil'), t('medico_consultorios')],
  );
  const [activeStep, setActiveStep] = useState(0);
  const [perfilMostrar, setPerfilMostrar] = useState<boolean>(false);
  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundColor: '#1E40AF',
      },
    },
    completed: {
      '& $line': {
        backgroundColor: '#1E40AF',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#1E40AF',
      borderRadius: 1,
    },
  })(StepConnector);
  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      backgroundColor: '#1E40AF',
    },
  });
  const ColorlibStepIcon = (colorProps: StepIconProps) => {
    const classes = useColorlibStepIconStyles();
    const { active } = colorProps;
    const icons: { [index: string]: React.ReactElement } = {
      1: <MedicoPerfil />,
      2: <MedicoConsultorio />,
      3: <MedicoReceta />,
      4: <MedicoAsistentes />,
    };
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {/* eslint-disable react/destructuring-assignment */}
        {icons[String(colorProps.icon)]}
      </div>
    );
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className="p-4 bg-white">
      {!perfilMostrar ? (
        <>
          <h1 className="text-blue-800 font-normal m-0 text-center">
            {`${t('bienvenido')}, ${nombre || ''} ${primerApellido || ''}`}
          </h1>
          <div className="flex content-center justify-center">
            <Button disabled={activeStep === 0} onClick={handleBack}>
              <ArrowBackIosIcon />
            </Button>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Button
              onClick={handleNext}
              disabled={
                (activeStep === 1 && (idMedico === 0 || idConsultorio === -1)) ||
                activeStep === steps.length - 1
              }
            >
              <ArrowForwardIosIcon />
            </Button>
          </div>
          {rol.grupo === 'salud' &&
            (idMedico <= 0 ||
              !consultorios.length ||
              idConsultorio <= 0 ||
              !carrera.cedula ||
              !carrera.escuela ||
              !carrera.titulo) && (
              <div className="text-center p-4 bg-yellow-300 text-gray-800">
                {t(
                  'si_no_llenas_al_menos_tus_datos_de_identificacion_no_podras_acceder_a_las_otras_partes_del_expediente',
                )}
              </div>
          )}
          {activeStep === 0 ? (
            <Perfil
              setActiveStepMedico={setActiveStep}
              activeStepMedico={activeStep}
              perfilMostrar={perfilMostrar}
              setPerfilMostrar={setPerfilMostrar}
            />
          ) : null}
          {activeStep === 1 && (
            <MisConsultorios setActiveStepMedico={setActiveStep} activeStepMedico={activeStep} />
          )}
          {activeStep === 2 && <ConfigurarReceta />}
        </>
      ) : (
        <Perfil
          setActiveStepMedico={setActiveStep}
          activeStepMedico={activeStep}
          perfilMostrar={perfilMostrar}
          setPerfilMostrar={setPerfilMostrar}
        />
      )}
    </div>
  );
};

export default MedicoInformacionComponente;
