import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, Button } from '@material-ui/core';
import { ReactComponent as StarDiscount } from '@assets/misc/star_discount.svg';
import { ICardAnualProps } from './types';

function CardAnual({
  tipoSuscripcion,
  tiempoSuscripcion,
  handleClickButtonSuscripcion,
}: ICardAnualProps) {
  const { t } = useTranslation();
  const costoTipoSuscripcion = () => {
    switch (tipoSuscripcion) {
      case 'premium':
        return t('costo_anual_premium');
      case 'estandar':
        return t('costo_anual_estandar');
      case 'basica':
        return t('costo_anual_basica');
      default:
        return '';
    }
  };
  return (
    <div
      className={`px-8 py-2 ${
        tiempoSuscripcion === 'anual' ? 'bg-blue-100' : 'bg-gray-100'
      } rounded-lg border border-solid border-blue-500 relative`}
    >
      <div className="absolute top-2 right-2">
        <StarDiscount />
      </div>
      <p className="font-medium text-gray-800">{t('paga_solo_11_meses')}</p>
      <p className="font-bold text-gray-800">{t('_recibe_1_mes_gratis_')}</p>
      <div className="text-center">
        <FormControlLabel
          value="anual"
          control={<Radio color="primary" />}
          label={(
            <h4 className="font-normal m-0">
              {t('pagaras')}
              &nbsp;
              <span className="font-bold">{costoTipoSuscripcion()}</span>
            </h4>
          )}
        />
        <div className="text-gray-800">
          <Button
            variant="outlined"
            color="inherit"
            style={{
              backgroundColor: '#6EE7B7',
              fontWeight: 500,
              borderColor: '#047857',
              borderRadius: '0.5rem',
            }}
            fullWidth
            onClick={() => handleClickButtonSuscripcion('A')}
          >
            {t('subscripcion_anual')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CardAnual;
