// import { ICatalogoAGEE, ICatalogoAGEM } from "../TabsPaciente/PerfilPaciente/DatosDeUbicacion/types";
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { ICatalogosCie10, ICatalogosEstandar } from '@common/types';

export interface IBusinessDoctor {
  start: any;
  end: any;
}

interface IPaciente {
  nombre: string;
  edad: string;
  sexo: string;
  abreviado: string;
  foto: string;
  id: number;
}

interface IAcciones {
  id: number;
}

interface IDatos {
  celular: string;
  telefono: string;
  email: string;
}

export interface IPacienteTabla {
  paciente: IPaciente;
  acciones: IAcciones;
  datos: IDatos;
  consulta: string;
  proxima: string;
}

export interface IObjPacientesDia {
  fecha: string;
  total: number;
  nuevos: number;
  subsecuentes: number;
  registrados: number;
  diferencia: number;
}

export interface IObjPacientesEdadSexo {
  grupo: string;
  hombre: number;
  mujer: number;
  total: number;
}

export interface IObjPacientesDiagnosticoSexo {
  diagnostico: string;
  porcentaje: number;
  mujeres: number;
  hombres: number;
  total: number;
}

export interface IObjPacientesAccidentes {
  fecha: string;
  nombreEmpleado: string;
  numeroEmpleado: string;
  causaAccidente: string;
  naturalezaLesion: string;
  incapacidad: string;
  diasIncapacidad: number;
  tipoRiesgo: string;
}

export interface IObjPacientesIncapacidades {
  nombreEmpleado: string;
  numeroEmpleado: string;
  fecha: string;
  tipoIncidencia: string;
  folioImss: string;
  folioInterno: string;
  diasIncapacidad: number;
  fechaInicio: string;
  ramoSeguro: string;
  tipoRiesgo: string;
  secuela: string;
  controlIncapacidad: string;
}

export interface IObjPacientesDistribucionGeografica {
  nombre: string;
  porcentaje: number;
  mujeres: number;
  hombres: number;
  total: number;
}

export interface IObjPacientesCumple {
  nombre: string;
  iniciales: string;
  telefono: string;
  edad: number;
}

export interface IObjAreasOportunidadProps {
  tipo: string;
  titulo: string;
  diagnosticoHabito: number;
  perdidaPresentismo: number;
  perdidaAusentismo: number;
  perdidaTotal: number;
}

export interface IObjReporteEpidemiologia {
  nombre: string;
  edad: number;
  sexo: string;
  diagnosticos: string[];
  ocupacion: string;
}

export interface IObjCampania {
  campaniaId: number;
  campaniaNombre: string;
  correoAsunto: string;
  correoCuerpo: RawDraftContentState;
  documento: string;
}

export const campaniaInitial = {
  campaniaId: -1,
  campaniaNombre: '',
  correoAsunto: '',
  correoCuerpo: { entityMap: {}, blocks: [] },
  documento: '',
};

export interface IObjTableProps {
  titulo: string;
  colA: number;
  colB: number;
  totalAlarma: number;
  totalDatos: number;
  sin_datos: number;
}

export interface IObjTableHabitosProps {
  tipo: string;
  titulo: string;
  colA: number;
  colB: number;
  totalAlarma: number;
  totalDatos: number;
  sin_datos: number;
  exConsumidor: number;
  comentarios: string;
  perdida: number;
  verDetalle: string;
}

export interface IObjGraphCardiovascularProps {
  riesgo_muy_alto: number;
  riesgo_alto: number;
  riesgo_intermedio: number;
  riesgo_bajo: number;
  riesgo_nulo: number;
  sin_datos: number;
  totalAlarma: number;
  totalDatos: number;
}

export interface IObjGloboriskProps {
  rangoClasificacion: string;
  valorPa: number;
  valor: string;
  consumoTabaco: number;
  edad: number;
  esDiabetico: number;
  imc: number;
  valorColesterol: number;
  rangoColesterol: number;
  rangoEdad: number;
  rangoPa: number;
  sexo: number;
  nombre: string;
  contacto: IObjContacto;
  idPaciente: number;
  fecha: string;
}

export interface IObjAAAProps {
  toxicomania: string;
  idPaciente: number;
}

export interface IObjCancerPulmonProps {
  contacto: IObjContacto;
  idPaciente: number;
  edad: number;
}

export interface IObjCancerCervicoProps {
  contacto: IObjContacto;
  rangoClasificacion: string;
  sugerencia: string;
  edad: number;
  fecha: string;
}

interface IObjEstudio {
  fechaEstudio: string;
  fechaSiguiente: string;
  diasRestantes: number;
}

export interface IObjAudiometriasProps {
  contacto: IObjContacto;
  idPaciente: number;
  nivel: number;
  estudio: IObjEstudio;
  sugerencia: string;
  edad: number;
  fecha: string;
}

export interface IObjEMPProps {
  contacto: IObjContacto;
  idPaciente: number;
  estudio: IObjEstudio;
  sugerencia: string;
  fecha: string;
}

export interface IObjHabitoProps {
  nombre: IObjEstudio;
  rangoClasificacion: string;
  frecuencia: string;
  consumo: string;
}

export interface IButtonBackProps {
  accionClick: any;
}

interface IObjContacto {
  email: string;
  celular: number;
  telefono: number;
}

export interface ITableViewProps {
  columns: any;
  data: any[];
  findNext: any;
  findMore: boolean;
  showDataDiv: any;
}

export interface IFiltrosReporteEpidemiologia {
  fecha: IBusinessDoctor;
  consultorio: number;
  // agees: ICatalogoAGEE[];
  // agems: ICatalogoAGEM[];
  edad: number[];
  sexo: string;
  diagnosticos: ICatalogosCie10[];
  ocupaciones: ICatalogosEstandar[];
}

export interface IObjPacientesDictamenes {
  tipoExamen: ICatalogosEstandar;
  puestoActual: ICatalogosEstandar;
  dictamen: ICatalogosEstandar;
  restricciones: ICatalogosEstandar[];
  recomendaciones: ICatalogosEstandar[];
}
