import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxInputProps } from './type';

export const CheckboxInput: FC<CheckboxInputProps> = ({
  name,
  containerClass = '',
  label: externalLabel,
  isRequired = false,
  helperText,
  setValue,
  disabled,
  value,
}) => {
  const { t } = useTranslation();
  const label = externalLabel === undefined ? t(name) : externalLabel;

  return (
    <div className={`w-full h-auto ${containerClass}`}>
      <input
        type="checkbox"
        name={name}
        checked={value}
        onClick={() => setValue({
          name,
          value: !value,
        })}
        required={isRequired}
        disabled={disabled}
        className="w-4 h-4 text-blue-500 border-gray-300 focus:ring-blue-500"
      />

      <span className="ml-2 font-normal text-base text-gray-700">
        {label}
      </span>
      {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
    </div>
  );
};

export default CheckboxInput;
