import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import DeleteDialog from '@components/DeleteDialog';
import FormularioOrganizaciones from './FormularioOrganizaciones';
import Organizaciones from './Organizaciones';
import { IOtrasOrganizacionesProps } from './types';

function OtrasOrganizaciones(props: IOtrasOrganizacionesProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const locState = location.state || {
    idOrg: -1,
    objType: '',
    objOrgId: '',
    objOrgLabel: '',
    objAdminId: '',
  };
  const {
    objOrganizacion,
    setObjOrganizacion,
    organizaciones,
    setOrganizaciones,
    setDisabledSave,
  } = props;
  const [idActualizar, setIdActualizar] = useState<number>(-1);
  const [indexToDelete, setIndexToDelete] = useState<number>(-1);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [licenciasOrganizacion, setLicenciasOrganizacion] = useState({
    totales: 0,
    disponibles: 0,
  });

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  const handleEditOrganizacion = (indice: number) => {
    const idOrg = organizaciones.findIndex(
      (organizacion) =>
        JSON.stringify(organizacion) ===
        JSON.stringify(
          organizaciones.filter((org) => !org.borrado)[indice === -1 ? indexToDelete : indice],
        ),
    );
    setIdActualizar(idOrg);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  const handleDeleteOrganizacion = () => {
    const nuevaLista = [...organizaciones];
    const idOrg = organizaciones.findIndex(
      (organizacion) =>
        JSON.stringify(organizacion) ===
        JSON.stringify(organizaciones.filter((org) => !org.borrado)[indexToDelete]),
    );
    nuevaLista[idOrg].borrado = true;
    setOrganizaciones(nuevaLista);
    setLicenciasOrganizacion({
      ...licenciasOrganizacion,
      disponibles: licenciasOrganizacion.disponibles + nuevaLista[idOrg].licencias,
    });
  };

  useEffect(() => {
    if (locState.objType === 'organizacion' && locState.objOrgId !== -1) {
      const index = organizaciones.findIndex((org) => org.id === locState.objOrgId);
      setIdActualizar(index);
    }
    setDisabledSave(
      organizaciones.length === 0 ||
        organizaciones.filter((org) => org.id === -1 || org.actualizado || org.borrado).length ===
          0,
    );
  }, [organizaciones, locState]);

  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_registro_')}
        callBackAceptar={handleDeleteOrganizacion}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_el_registro_se_borrara_el_que_estas_creando')}
        callBackAceptar={() => handleEditOrganizacion(indexToDelete)}
        callBackClose={handleEditAlertClose}
      />
      <FormularioOrganizaciones
        setOrganizaciones={setOrganizaciones}
        idActualizar={idActualizar}
        organizaciones={organizaciones}
        setIdActualizar={setIdActualizar}
        objOrganizacion={objOrganizacion}
        setObjOrganizacion={setObjOrganizacion}
        licenciasOrganizacion={licenciasOrganizacion}
        setLicenciasOrganizacion={setLicenciasOrganizacion}
      />
      {organizaciones.filter((org) => !org.borrado).length > 0 && (
        <>
          <h2 className="text-blue-800 font-normal">{t('lista_de_organizaciones')}</h2>
          {organizaciones.map((org, indx) => (
            <>
              {org && !org.borrado && (
                <Organizaciones
                  organizacion={org}
                  editOrganizacion={handleEditOrganizacion}
                  objOrganizacion={objOrganizacion}
                  index={indx}
                  setDeleteAlert={setDeleteAlert}
                  setEditAlert={setEditAlert}
                  setIndexToDelete={setIndexToDelete}
                />
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
}

export default OtrasOrganizaciones;
