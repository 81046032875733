import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { IDoughnutProps } from './types';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props: IDoughnutProps) => {
  const {
    tipos,
    etiquetas,
    colores,
    datos,
    totales,
    valorCentro,
    etiquetaCentro,
    callbackDetalle,
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-row justify-center items-center mb-4">
        {etiquetas.map((label: string, index: number) => (
          <>
            <div
              className={`w-9 h-3 mr-1 ${index === 0 ? 'ml-0' : 'ml-6'}`}
              style={{ backgroundColor: colores[index] }}
            />
            <div className="text-gray-500 font-normal">{label}</div>
          </>
        ))}
      </div>
      <div className={`grid grid-cols-${datos.length > 4 ? '4' : '3'}`}>
        {datos.length === 1 && <div />}
        {datos.map((data: number[], index: number) => (
          <div className="border-solid border border-gray-200 shadow-md p-4 pt-2 mb-4 ml-2 mr-2">
            {tipos && (
              <div className="text-center mb-0 h-14">
                <h3 className="mb-0 pb-4">{t(tipos[index])}</h3>
              </div>
            )}
            <div>
              {data.some((value) => value > 0) ? (
                <Doughnut
                  data={{
                    labels: etiquetas,
                    datasets: [
                      {
                        data,
                        backgroundColor: colores,
                        borderColor: colores,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    cutout: 65,
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: (d: any) => {
                            if ([t('sin_datos'), t('riesgo_nulo')].includes(d.label)) {
                              return d.raw;
                            }
                            const p = (d.raw * 100) / totales[index];
                            return `${d.raw} (${p % 1 === 0 ? p : p.toFixed(2)}%)`;
                          },
                        },
                      },
                    },
                  }}
                  plugins={[
                    {
                      id: 'doughnutLabel',
                      beforeDatasetsDraw(chart: any) {
                        const { ctx } = chart;
                        ctx.save();
                        const xCoor = chart.getDatasetMeta(0).data[0].x;
                        const yCoor = chart.getDatasetMeta(0).data[0].y;
                        ctx.font = 'bold 15px sans-serif';
                        ctx.fillStyle = 'black';
                        ctx.textAlign = 'center';
                        ctx.baseLine = 'middle';
                        ctx.fillText(
                          valorCentro
                            ? `${t(etiquetaCentro || '')}: ${valorCentro}`
                            : `${t('total')}: ${totales[index]}`,
                          xCoor,
                          yCoor,
                        );
                      },
                    },
                  ]}
                  width={200}
                  height={200}
                />
              ) : (
                <div className="pt-4 text-center">
                  <span>{t('sin_datos_alarmas')}</span>
                </div>
              )}
              {tipos && callbackDetalle && data.some((value) => value > 0) && (
                <div className="text-right mt-0">
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={() => callbackDetalle(tipos[index])}
                  >
                    {t('ver_detalle')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoughnutChart;
