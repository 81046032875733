import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd';
import { Button, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import { IMedicamentoActualProps } from './types';

function MedicamentoActual({
  medicamento,
  tipoMedicamento,
  index,
  onDropMedicamento,
  handleEliminarMedicamento,
  handleEditarMedicamento,
  handleVerDetalleMedicamento,
}: IMedicamentoActualProps) {
  const { t } = useTranslation();
  const {
    id,
    tipo,
    nombreDistintivo,
    nombreGenerico,
    medTextoLibre,
    via,
    cantidad,
    unidad,
    frecuencia,
    otraFrecuencia,
    duracion,
    periodo,
    alertas,
  } = medicamento;

  const [{ isDraggin }, dragRef] = useDrag({
    item: {
      type: tipoMedicamento,
      index,
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        onDropMedicamento(item);
      }
    },
    collect: (monitor) => ({
      isDraggin: monitor.isDragging(),
    }),
  });

  const titulo = () => {
    let jsx;
    switch (tipo) {
      case 'nombre_generico':
        jsx = <h3 className="font-normal m-0 text-gray-900">{nombreGenerico.label}</h3>;
        break;
      case 'denominacion_distintiva':
        jsx = (
          <>
            <h3 className="font-normal m-0 text-gray-900">{nombreDistintivo.label}</h3>
            <h4 className="font-normal m-0 text-gray-500">{nombreGenerico.label}</h4>
          </>
        );
        break;
      case 'texto_libre':
        jsx = <h3 className="font-normal m-0 text-gray-900">{medTextoLibre}</h3>;
        break;
      default:
        jsx = <h3 className="font-normal m-0 text-gray-900">Sin información</h3>;
        break;
    }
    return jsx;
  };

  const descripcion = () => (
    <p className="text-gray-500">
      <span className="text-gray-900">{via.impresion}</span>
      {` ${cantidad} ${unidad.label} ${frecuencia.label || otraFrecuencia} ${t(
        'farma_por',
      )} ${duracion} ${periodo.label}`}
    </p>
  );

  return (
    <div
      className={`shadow-lg bg-white rounded border border-solid border-gray-200 my-4 w-full${
        isDraggin ? ' opacity-50' : ''
      }`}
      ref={dragRef}
    >
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 pb-6 relative">
        <div className="pr-9">{titulo()}</div>
        {descripcion()}
        <div className="absolute bottom-0 right-0">
          {!!alertas.alergias.length && (
            <Button
              disabled
              style={{ alignItems: 'end' }}
              startIcon={
                <WarningIcon fontSize="small" color="inherit" className="text-yellow-500" />
              }
            >
              <span className="text-red-500">
                (
                {alertas.alergias.length}
                )
              </span>
            </Button>
          )}
          <Button color="primary" onClick={() => handleVerDetalleMedicamento(medicamento)}>
            {t('ver_detalle')}
          </Button>
          <IconButton color="primary" onClick={() => handleEditarMedicamento(medicamento)}>
            <CreateIcon fontSize="small" />
          </IconButton>
          <IconButton color="primary" onClick={() => handleEliminarMedicamento(id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default MedicamentoActual;
