/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ICatalogosCie10 } from '@common/types';
import { getCatCie10QueryCategoriaSexo } from '@utils/getCatalogos';
import { IFormAFNegarProps } from './types';
import { IFamiliaresGrupoEnfermedadesNegadas } from '../types';

function FormAntecedentesFamiliaresNegar({
  grupoEnfNegadas,
  setGrupoEnfNegadas,
  enfNegada,
  setEnfNegada,
  sexoFamiliar,
  setCambioGrupoEnfermedades,
}: IFormAFNegarProps) {
  const { t } = useTranslation();
  const {
    infecciosasYParasitariasSelected,
    infecciosasYParasitariasDisabled,
    neoplasiasSelected,
    neoplasiasDisabled,
    hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected,
    hematologicasOQueAfectanElMecanismoDeLaInmunidadDisabled,
    endocrinasNutricionalesYMetabolicasSelected,
    endocrinasNutricionalesYMetabolicasDisabled,
    trastornosMentalesYDelComportamientoSelected,
    trastornosMentalesYDelComportamientoDisabled,
    sistemaNerviosoSelected,
    sistemaNerviosoDisabled,
    delOjoYSusAnexosSelected,
    delOjoYSusAnexosDisabled,
    delOidoYApofisisMastoideSelected,
    delOidoYApofisisMastoideDisabled,
    sistemaCirculatorioSelected,
    sistemaCirculatorioDisabled,
    sistemaRespiratorioSelected,
    sistemaRespiratorioDisabled,
    sistemaDigestivoSelected,
    sistemaDigestivoDisabled,
    pielYDelTejidoSubcutaneoSelected,
    pielYDelTejidoSubcutaneoDisabled,
    sistemaOsteomuscularYDelTejidoConjuntivoSelected,
    sistemaOsteomuscularYDelTejidoConjuntivoDisabled,
    sistemaGenitourinarioSelected,
    sistemaGenitourinarioDisabled,
    embarazoPartoPuerperioSelected,
    embarazoPartoPuerperioDisabled,
    delPeriodoPerinatalSelected,
    delPeriodoPerinatalDisabled,
    malformaconesCongenitasYAnomaliasCongenitasSelected,
    malformaconesCongenitasYAnomaliasCongenitasDisabled,
    traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected,
    traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasDisabled,
  } = grupoEnfNegadas;
  const { diagnosticoCie10 } = enfNegada;

  const [catCIE10, setCatCIE10] = useState<ICatalogosCie10[]>([]);
  const [openCIE10, setOpenCIE10] = useState<boolean>(false);
  const [loadingCIE10, setLoadingCIE10] = useState<boolean>(false);
  const [inputCIE10, setInputCIE10] = useState<string>('');

  const handleChangeCIE = (event: any, newValue: ICatalogosCie10 | null, reason: string) => {
    setEnfNegada({
      ...enfNegada,
      diagnosticoCie10:
        reason !== 'clear' && newValue ? newValue : { id: '', label: '', grupoEnfermedades: 0 },
    });
    setOpenCIE10(reason === 'clear');
  };

  const handleChangeNegarEnfermedades = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGrupoEnfNegadas((genP: IFamiliaresGrupoEnfermedadesNegadas) => ({
      ...genP,
      [`${event.target.name}Selected`]: event.target.checked,
    }));
    setCambioGrupoEnfermedades(true);
  };

  useEffect(() => {
    if (!openCIE10) {
      setCatCIE10([]);
    }
  }, [openCIE10]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (inputCIE10.length > 2) {
      let sexo = '';
      if (sexoFamiliar === 'H') {
        sexo = 'HOMBRE';
      } else if (sexoFamiliar === 'M') {
        sexo = 'MUJER';
      }
      setLoadingCIE10(true);
      getCatCie10QueryCategoriaSexo(inputCIE10, sexo, signal)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatCIE10(result.datos);
          } else {
            setCatCIE10([]);
          }
          setLoadingCIE10(false);
        })
        .catch(() => {
          setCatCIE10([]);
          setLoadingCIE10(false);
        });
    } else {
      setCatCIE10([]);
      setLoadingCIE10(false);
    }
    return () => abortController.abort();
  }, [inputCIE10]);

  return (
    <div className="border border-solid border-gray-300 rounded p-4">
      <div className="border border-solid rounded border-gray-300 p-4 mb-4">
        <h3 className="mt-0 text-gray-500 font-normal">{t('negar_enfermedad_especifica')}</h3>
        <Autocomplete
          open={openCIE10}
          onOpen={() => {
            setOpenCIE10(true);
          }}
          onClose={() => {
            setOpenCIE10(false);
          }}
          getOptionLabel={(option) => option.label}
          options={catCIE10}
          loading={loadingCIE10}
          loadingText="Buscando..."
          noOptionsText={
            inputCIE10.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
          }
          value={diagnosticoCie10}
          onChange={(e, nV, r) => handleChangeCIE(e, nV, r)}
          onInputChange={(e, newInputValue) => {
            if (newInputValue.length < 3) {
              setCatCIE10([]);
            }
            setInputCIE10(newInputValue);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('selecciona')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingCIE10 ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="border border-solid rounded border-gray-300 p-4 mb-4">
        <h3 className="m-0 text-gray-500 font-normal">{t('negar_grupo_enfermedades')}</h3>
        <div>
          <FormGroup>
            <div className="grid grid-cols-2">
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={infecciosasYParasitariasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="infecciosasYParasitarias"
                    disabled={infecciosasYParasitariasDisabled}
                  />
                )}
                label={t('infecciosas_y_parasitarias_(A00-B99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={neoplasiasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="neoplasias"
                    disabled={neoplasiasDisabled}
                  />
                )}
                label={t('neoplasias_(C00-D48)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="hematologicasOQueAfectanElMecanismoDeLaInmunidad"
                    disabled={hematologicasOQueAfectanElMecanismoDeLaInmunidadDisabled}
                  />
                )}
                label={t('hematologicas_o_que_afectan_el_mecanismo_de_la_inmunidad_(D50-D89)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={endocrinasNutricionalesYMetabolicasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="endocrinasNutricionalesYMetabolicas"
                    disabled={endocrinasNutricionalesYMetabolicasDisabled}
                  />
                )}
                label={t('endocrinas_nutricionales_y_metabolicas_(E00-E90)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={trastornosMentalesYDelComportamientoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="trastornosMentalesYDelComportamiento"
                    disabled={trastornosMentalesYDelComportamientoDisabled}
                  />
                )}
                label={t('trastornos_mentales_y_del_comportamiento_(F00-F99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={sistemaNerviosoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaNervioso"
                    disabled={sistemaNerviosoDisabled}
                  />
                )}
                label={t('sistema_nervioso_(G00-G99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={delOjoYSusAnexosSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="delOjoYSusAnexos"
                    disabled={delOjoYSusAnexosDisabled}
                  />
                )}
                label={t('del_ojo_y_sus_anexos_(H00-H59)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={delOidoYApofisisMastoideSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="delOidoYApofisisMastoide"
                    disabled={delOidoYApofisisMastoideDisabled}
                  />
                )}
                label={t('del_oido_y_apofisis_mastoide_(H60-H95)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={sistemaCirculatorioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaCirculatorio"
                    disabled={sistemaCirculatorioDisabled}
                  />
                )}
                label={t('sistema_circulatorio_(I00-I99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={sistemaRespiratorioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaRespiratorio"
                    disabled={sistemaRespiratorioDisabled}
                  />
                )}
                label={t('sistema_respiratorio_(J00-J99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={sistemaDigestivoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaDigestivo"
                    disabled={sistemaDigestivoDisabled}
                  />
                )}
                label={t('sistema_digestivo_(K00-K93)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={pielYDelTejidoSubcutaneoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="pielYDelTejidoSubcutaneo"
                    disabled={pielYDelTejidoSubcutaneoDisabled}
                  />
                )}
                label={t('piel_y_del_tejido_subcutaneo_(L00-L99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={sistemaOsteomuscularYDelTejidoConjuntivoSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaOsteomuscularYDelTejidoConjuntivo"
                    disabled={sistemaOsteomuscularYDelTejidoConjuntivoDisabled}
                  />
                )}
                label={t('sistema_osteomuscular_y_del_tejido_conjuntivo_(M00-M99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={sistemaGenitourinarioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="sistemaGenitourinario"
                    disabled={sistemaGenitourinarioDisabled}
                  />
                )}
                label={t('sistema_genitourinario_(N00-N99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={embarazoPartoPuerperioSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="embarazoPartoPuerperio"
                    disabled={embarazoPartoPuerperioDisabled}
                  />
                )}
                label={t('embarazo_parto_puerperio_(O00-O99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={delPeriodoPerinatalSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="delPeriodoPerinatal"
                    disabled={delPeriodoPerinatalDisabled}
                  />
                )}
                label={t('del_periodo_perinatal_(P00-P96)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={malformaconesCongenitasYAnomaliasCongenitasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="malformaconesCongenitasYAnomaliasCongenitas"
                    disabled={malformaconesCongenitasYAnomaliasCongenitasDisabled}
                  />
                )}
                label={t('malformacones_congenitas_y_anomalias_congenitas_(Q00-Q99)')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected}
                    onChange={handleChangeNegarEnfermedades}
                    name="traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternas"
                    disabled={traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasDisabled}
                  />
                )}
                label={t(
                  'traumatismo_y_envenenamiento_y_otras_consecuencias_de_causas_externas_(S00-T98)',
                )}
              />
            </div>
          </FormGroup>
        </div>
      </div>
      <span className="text-gray-500">
        {t('_grupos_de_enfermedades_considerando_la_clasificacion_del_CIE-10')}
      </span>
    </div>
  );
}

export default FormAntecedentesFamiliaresNegar;
