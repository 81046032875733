import { useTranslation } from 'react-i18next';
import { ITablaDocumentosCargados } from './types';
import RowTablaDocumentosCargados from './RowTablaDocumentosCargados';

function TablaDocumentosCargados({
  idMedico,
  archivos,
  eliminarArchivo,
  verArchivo,
}: ITablaDocumentosCargados) {
  const { t } = useTranslation();
  return (
    <div className="border border-solid border-gray-600">
      <div className="grid grid-cols-5 gap-4 bg-blue-600 text-white text-center py-2">
        <span className="col-span-2 text-white">{t('nombre_documento')}</span>
        <span className="text-white">{t('tamanio_archivo')}</span>
        <span className="text-white">{t('fecha_de_carga')}</span>
        <span className="text-white">{t('opciones')}</span>
      </div>
      {archivos.map((archivo, indx) => (
        <RowTablaDocumentosCargados
          key={archivo.idArchivo}
          idMedico={idMedico}
          archivo={archivo}
          index={indx}
          eliminarArchivo={eliminarArchivo}
          verArchivo={verArchivo}
        />
      ))}
    </div>
  );
}

export default TablaDocumentosCargados;
