import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@material-ui/core';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
// import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { ReactComponent as RXIcon } from '@assets/icons/Pharmacy_Rx.svg';
import { RootState } from 'src/store';
import { getFechaActualISO } from '@common/functions';
import { setRequestFile } from '@actions/requestFile/types';
import { IHeaderRecetaProps } from './types';

function HeaderReceta({
  nombrePaciente = null,
  edadPaciente = null,
  folio = '######',
}: IHeaderRecetaProps) {
  const { t } = useTranslation();
  const [urlImage, setUrlImage] = useState<string>('');
  const dispatch = useDispatch();
  const nombre = nombrePaciente;
  const edad = edadPaciente;
  const {
    idMedico,
    nombre: nombreMedico,
    primerApellido,
    segundoApellido,
    carrera,
    especialidades,
    sexo,
    rol,
    enOrganizacion,
  } = useSelector((state: RootState) => state.Me);
  const { consultorio } = useSelector((state: RootState) => state.Consultorios);
  const { organizacion } = useSelector((state: RootState) => state.Organizaciones);
  const getTitulo = () => {
    let titulo = '';
    if (rol.tipo === 'medico') {
      titulo = sexo === 'M' ? t('dra_') : t('dr_');
    } else if (rol.tipo === 'enfermero') {
      titulo = t('enf_');
    }
    return titulo;
  };
  const verImagen = (idArchivo: number, pathArchivo: string, registro: string) => {
    dispatch(
      setRequestFile({
        type: 'get',
        idMedico,
        idPaciente: null,
        idArchivo,
        nombreArchivo: null,
        pathArchivo,
        tipoArchivo: registro,
        successFunction: (url: string) => setUrlImage(url),
      }),
    );
  };
  useEffect(() => {
    if (enOrganizacion) {
      if (organizacion.urlLogotipo && organizacion.urlLogotipo.length) {
        verImagen(organizacion.idOrganizacion, organizacion.urlLogotipo, 'imagenOrganizacion');
      }
    } else if (consultorio.logotipo && consultorio.logotipo.length > 0) {
      verImagen(consultorio.idConsultorio, consultorio.logotipo, 'imagenConsultorio');
    }
  }, []);
  return (
    <div>
      <div
        style={{
          background:
            'linear-gradient(180deg, rgba(61, 221, 242, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
        className="border-0 border-solid border-gray-400 border-b p-4"
      >
        <div className="flex items-center m-4">
          <div>
            <div className="rounded h-20 w-20 items-center justify-center inline-block">
              <Avatar style={{ width: '90px', height: '90px' }} src={urlImage}>
                {urlImage.length === 0 && <AddToPhotosIcon fontSize="large" />}
              </Avatar>
            </div>
            <p className="m-0 text-gray-500 mt-4">
              <span>{`${t('folio')}:\u00A0${folio}`}</span>
            </p>
          </div>
          <div className="text-right w-full">
            <h2 className="text-blue-800 font-normal m-0 capitalize">
              {`${getTitulo()} ${nombreMedico} ${primerApellido} ${segundoApellido}`}
            </h2>
            <h3 className="uppercase font-normal text-gray-500 m-0">
              {`${
                especialidades.length
                  ? especialidades.map((esp) => esp.titulo).join(',')
                  : carrera.titulo
              }`}
            </h3>
            <h4 className="uppercase font-normal text-gray-500 m-0">{carrera.escuela}</h4>
            <p className="m-0 text-gray-500">
              {carrera.cedula && t('cedula_profesional_no_')}
              <span>{carrera.cedula}</span>
            </p>
            {especialidades.length > 0 && (
              <p className="m-0 text-gray-500">
                {`${
                  especialidades.length > 1
                    ? t('cedula_de_las_especialidades_no_')
                    : t('cedula_de_la_especialidad_no_')
                }`}
                <span>{especialidades.map((esp) => esp.cedula).join(',')}</span>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center m-6">
        <RXIcon className="mt-2" />
        <div className="flex justify-between ml-4 w-full pr-32">
          <div className="m-0 text-gray-900">
            <span className="font-bold">{`${t('paciente')}:`}</span>
            <span className="capitalize">{` ${nombre || t('nombre_del_paciente')}`}</span>
          </div>
          <div className="m-0 text-gray-900">
            <span className="font-bold">{`${t('edad')}:`}</span>
            <span>
              {` ${edad ? edad.edad : t('edad_del_paciente')} ${edad ? t(edad.periodo) : ''}`}
            </span>
          </div>
          <div className="m-0 text-gray-900">
            <span className="font-bold">{`${t('fecha')}:`}</span>
            <span>{` ${getFechaActualISO().substr(0, 10)}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderReceta;
