import { useTranslation } from 'react-i18next';
import CervicoUterino from './CervicoUterino';
import Mama from './Mama';
import { IDespistajeDeCancerProps } from './types';

function DespistajeDeCancer(props: IDespistajeDeCancerProps) {
  const { ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();
  return (
    <div className="border-solid border-0 border-b border-gray-600">
      <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('despistaje_de_cancer')}</h2>
      <CervicoUterino
        ginecoObstetrico={ginecoObstetrico}
        setGinecoObstetrico={setGinecoObstetrico}
      />
      <Mama ginecoObstetrico={ginecoObstetrico} setGinecoObstetrico={setGinecoObstetrico} />
    </div>
  );
}

export default DespistajeDeCancer;
