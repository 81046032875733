import {
  SET_RUTA_PRINCIPAL,
  SET_RUTA_PERFIL,
  SET_RUTA_ANTECEDENTES,
  SET_RUTA_ANT_PATOLOGICOS,
  SET_RUTA_ANT_NO_PATOLOGICOS,
  SET_RUTA_PLAN,
  RutasExpedienteActionTypes,
} from './types';

export function setRutaPrincipal(ruta: string): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_PRINCIPAL,
    payload: ruta,
  };
}

export function setRutaPerfil(ruta: string): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_PERFIL,
    payload: ruta,
  };
}

export function setRutaAntecedentes(ruta: string): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_ANTECEDENTES,
    payload: ruta,
  };
}

export function setRutaAntPatologicos(ruta: string): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_ANT_PATOLOGICOS,
    payload: ruta,
  };
}

export function setRutaAntNoPatologicos(ruta: string): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_ANT_NO_PATOLOGICOS,
    payload: ruta,
  };
}

export function setRutaPlan(ruta: string): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_PLAN,
    payload: ruta,
  };
}
