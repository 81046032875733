import { useTranslation } from 'react-i18next';
import ConstructorTabla from '../ConstructorTabla';
import { ITractoGenitalProps } from './types';

function TractoGenital(props: ITractoGenitalProps) {
  const { ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();

  const setRadiosValues = (newRadioVals: string[]) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      sintomasClimaterio: {
        ...ginecoObstetrico.sintomasClimaterio,
        tractoGenital: {
          ...ginecoObstetrico.sintomasClimaterio.tractoGenital,
          radiosValues: newRadioVals,
        },
      },
    });
  };

  const setComentariosValues = (newComVals: string[]) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      sintomasClimaterio: {
        ...ginecoObstetrico.sintomasClimaterio,
        tractoGenital: {
          ...ginecoObstetrico.sintomasClimaterio.tractoGenital,
          comentariosValues: newComVals,
        },
      },
    });
  };

  return (
    <div>
      <h2 className="mt-4 mb-4 font-normal text-blue-300">{t('tracto_genital')}</h2>
      <ConstructorTabla
        items={ginecoObstetrico.sintomasClimaterio.tractoGenital.items}
        radiosValues={ginecoObstetrico.sintomasClimaterio.tractoGenital.radiosValues}
        setRadiosValues={setRadiosValues}
        comentariosValues={ginecoObstetrico.sintomasClimaterio.tractoGenital.comentariosValues}
        setComentariosValues={setComentariosValues}
      />
    </div>
  );
}

export default TractoGenital;
