import React, {
  FC, FormEvent, useEffect, useRef,
} from 'react';
import { resetModal } from '@actions/modal/actions';
import { BackButton } from '@components/BackButton';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';
import { FormProgressSteps } from '@components/FormProgressSteps';
// import { MediHero } from '@components/MediHero';
import { StepHeader } from '@components/StepHeader';
import { GLOBALS } from '@constants/prueba/globals';
import useBreakPoint from '@hooks/useBreakpoint';
import ResumenCambios from '@components/ResumenCambios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ModalFormProps } from './type';

// eslint-disable-next-line import/prefer-default-export
export const ModalForm: FC<ModalFormProps> = ({
  currentStep,
  title,
  children,
  progressSteps,
  id,
  submitButtonLabel,
  acceptCallback,
  rejectCallback,
  saveCallback,
  saveDisable,
  hayCambios = false,
  cambios = [],
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const modals = useSelector((state: RootState) => state.Modal);

  const isWideScreen = useBreakPoint(GLOBALS.breakPointwidth);
  const form = useRef<HTMLFormElement | null>(null);

  const dispatch = useDispatch();

  const closeModal = () => {
    if (form.current) {
      form.current.classList.add('animate-popRight');
      document.body.style.removeProperty('overflow');
      setTimeout(() => dispatch(resetModal(id)), 300);
    }
  };

  const handleDynamicHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  };

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await saveCallback();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    // Animación de entrada
    setTimeout(() => form.current?.classList.remove('animate-popLeft'), 300);

    // Cierre con deslizar en móviles
    const handleTouchMove = (e: TouchEvent) => {
      if (e.touches[0].clientX < 40) closeModal();
    };
    document.addEventListener('touchmove', handleTouchMove);

    // Manejar cierre al recargar página
    window.onbeforeunload = () => closeModal();

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      window.onbeforeunload = null;
    };
  }, []);

  // useEffect(() => {
  //   // Bloquear scroll en pantallas pequeñas
  //   if (!isWideScreen) {
  //     document.body.style.overflow = 'hidden';
  //     return () => {
  //       document.body.style.removeProperty('overflow');
  //     };
  //   }
  // }, [isWideScreen]);

  useEffect(() => {
    // Ajustar altura del viewport en móviles
    if (!isWideScreen) {
      handleDynamicHeight();
      window.addEventListener('resize', handleDynamicHeight);
    }
    return () => window.removeEventListener('resize', handleDynamicHeight);
  }, [isWideScreen]);

  // Estilos dinámicos del formulario
  const dynamicStyles: React.CSSProperties = !isWideScreen
    ? {
      height: 'calc(var(--vh, 1vh) * 100)', // Altura dinámica
      overflowY: 'auto', // Permitir scroll para Safari
      WebkitOverflowScrolling: 'touch', // Scroll suave en Safari
    }
    : {};

  const minHeight = isWideScreen
    ? `calc(100vh - ${
      modals.some((modal) => modal.isActive && modal.name === 'SubMenu') ? '300px' : '252px'
    })`
    : undefined;

  return (
    <form
      onSubmit={handleSubmitForm}
      ref={form}
      className={`${
        isWideScreen ? '' : 'animate-popLeft'
      } p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100 border-t-0`}
      style={dynamicStyles}
    >
      <div
        className="flex-1 md:w-full md:normal md:h-fit"
        style={{ WebkitOverflowScrolling: 'touch' }}
      >
        <div
          className="w-full h-[100%] md:h-auto flex flex-col justify-between"
          style={{ minHeight }}
        >
          <div className="w-full min-h-fit relative">
            {/* {!isWideScreen && <MediHero />} */}
            {!isWideScreen && <BackButton onClick={closeModal} />}
            {hayCambios && <ResumenCambios cambios={cambios} />}
            <h1
              className={`w-full font-normal md:text-center px-4 ${
                currentStep || progressSteps ? '' : 'mb-4'
              } md:text-blue-800 md:mb-3`}
            >
              {title}
            </h1>
            {esPaciente && currentStep && <StepHeader currentStep={currentStep} />}
            {progressSteps && !isWideScreen && <FormProgressSteps steps={progressSteps} />}
            {children}
          </div>

          <SubmitButton
            label={submitButtonLabel}
            acceptCallback={acceptCallback}
            rejectCallback={rejectCallback}
            saveCallback={saveCallback}
            saveDisable={saveDisable}
            hayCambios={hayCambios}          
          />
        </div>
      </div>
    </form>
  );
};
