import { RawDraftContentState } from 'draft-js';
import { ICatStrEstandar } from '@common/types';
import { IProcedimiento } from '../../../Antecedentes/PersonalesPatologicos/Procedimiento/types';

export interface IProcedimientoSolicitado {
  idBd: number;
  selector: string;
  cie9: ICatStrEstandar;
  txtLibre: string;
  actualizado: boolean;
}

export const procedimientoSolicitadoInitial: IProcedimientoSolicitado = {
  idBd: -1,
  selector: '',
  cie9: { id: '', label: '' },
  txtLibre: '',
  actualizado: false,
};

interface ICheckAll {
  check1: boolean;
  check2: boolean;
  check3: boolean;
}

export interface ISolicitudProcedimientos {
  idSolicitud: number;
  diagnosticoArray: string[];
  procedimientosArray: IProcedimientoSolicitado[];
  datosClinicos: RawDraftContentState;
  checkAll: ICheckAll;
  txtResumen: string;
}

export const solicitudProcedimientoInitial: ISolicitudProcedimientos = {
  idSolicitud: -1,
  diagnosticoArray: [],
  procedimientosArray: [procedimientoSolicitadoInitial],
  datosClinicos: { entityMap: {}, blocks: [] },
  checkAll: { check1: false, check2: false, check3: false },
  txtResumen: '',
};

export interface IProcedimientosProps {
  tipo: 'quirurgico' | 'diagnostico' | 'terapeutico';
  solProcedimientos: ISolicitudProcedimientos[];
  procedimientos: IProcedimiento[];
}
