import { useTranslation } from 'react-i18next';
import Tabla from '../Tabla';
import { IDictamenesProps } from './types';

const Dictamenes = (props: IDictamenesProps) => {
  const { datosPacienteDictamenes } = props;
  const { t } = useTranslation();

  const columnasDictamenes = [
    { Header: t('trabajador_a'), accessor: 'nombreEmpleado' },
    { Header: t('numero_trabajador_a'), accessor: 'numeroEmpleado' },
    { Header: t('tipo_examen'), accessor: 'tipoExamen' },
    { Header: t('puesto_actual'), accessor: 'puestoActual' },
    { Header: t('dictamen'), accessor: 'dictamen' },
    // { Header: t('restricciones'), accessor: 'restricciones' },
    // { Header: t('recomendaciones'), accessor: 'recomendaciones' },
  ];

  return (
    <>
      <h2 className="text-blue-800 font-normal m-0 pb-4 text-center">{t('dictamenes')}</h2>
      <Tabla columns={columnasDictamenes} firstColumnIsTitle data={datosPacienteDictamenes} />
    </>
  );
};

export default Dictamenes;
