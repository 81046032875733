import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  Button,
  StepConnector,
  AppBar,
  Toolbar,
  CssBaseline,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Check from '@material-ui/icons/Check';
import { RootState } from 'src/store';
import { setSnackComplete } from '@actions/snackbar/types';
import { getMeMedico } from '@actions/me/actions';
import { getEmailMe, getKeyBack } from '@utils/common';
import { setLoading } from '@actions/loading/actions';
import { setRequest } from '@actions/request/types';
import {
  sendPerfilAdministrador,
  sendMiOrganizacionOrganizacion,
  sendMiOrganizacionAdministrador,
  sendMiOrganizacionConsultorios,
  // sendMiOrganizacionRoles,
  sendMiOrganizacionEquipoMedicoCsv,
  sendMiOrganizacionPacientesCsv,
} from '@utils/sendInfo';
import { deleteMiOrganizacionAdministrador, deleteMiOrganizacionRoles } from '@utils/deleteInfo';
import { obtenerURLArchivo } from '@utils/getFiles';
import {
  getCatalogoNacionalidad,
  getCatalogoCfdi,
  getCatalogoAgee,
  getCatalogoAgem,
  getCatalogoAgel,
  getCatalogoAgea,
  getCatalogoTipoEstablecimiento,
  getCatalogoTablaTipoRoles,
  getCatalogoTablaSecciones,
  getOrganizacionConsultorios,
  getOrganizacionRoles,
  getOrganizacionEquipoMedicoLicencias,
  getMiOrganizacionAdministrador,
  getPerfilMedicoDatosId,
} from '@utils/getCatalogos';
import { ICatStrEstandar, ICatalogosEstandar } from '@common/types';
import { validarFecha, validarFechaEsAnterior } from '@common/functions';
import { arrayBufferToBase64, str2ab, str2AB } from '@common/base64Convert';
import DeleteDialog from '@components/DeleteDialog';
import { getOrganizacion } from '@actions/organizaciones/actions';
import DocIdCorrectoAdmin from '@components/ModalDialogs/DocIdCorrectoAdmin';
import useStyles from './styles';
import {
  IDatosAdministrador,
  IDatosOrganizacion,
  IDatosConsultorio,
  IRolesData,
  IObjCsv,
  IObjError,
  IObjCsvPacientes,
  ITipoRol,
  IRolSeleccionado,
  IDatosEnvioPacientes,
} from './types';
import { ICsTablaSecciones } from './MiOrganizacion/RolesPermisos/types';
import { ICatalogoCfdi } from './MiOrganizacion/Organizacion/types';
import {
  ICatalogoAGEE,
  ICatalogoAGEM,
  ICatalogoAGEL,
  ICatalogoAGEA,
} from '../TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import Perfil from './PerfilAdministrador/index';
import MiOrganizacion from './MiOrganizacion';
import {
  datosAdministradorInitial,
  datosConsultorioInitial as datosConsInit,
  rolesDataInitial,
} from './mockDatosInitial';

dayjs.locale('es');

const AdmonInformacionComponente = () => {
  const classesGen = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organizacion } = useSelector((state: RootState) => state.Organizaciones);
  const { idOrganizacion } = organizacion;
  const {
    idMedico,
    nombre,
    primerApellido,
    rol,
    email: emailSend,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const datosConsultorioInitial = {
    ...datosConsInit,
    estaEnHospital: pais === 'mx' ? 'si' : 'no',
  };
  const [showModalDocId, setShowModalDocId] = useState<boolean>(false);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [deleteAlertRol, setDeleteAlertRol] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<number>(-1);
  const [objAdministrador, setObjAdministrador] =
    useState<IDatosAdministrador>(datosAdministradorInitial);
  const [objAdminInvitado, setObjAdminInvitado] =
    useState<IDatosAdministrador>(datosAdministradorInitial);
  const [objAdminTmp, setObjAdminTmp] = useState<IDatosAdministrador>(datosAdministradorInitial);
  const [disableDocId, setDisableDocId] = useState<boolean>(false);
  const [catNacionalidad, setCatNacionalidad] = useState<Array<ICatStrEstandar>>([]);
  const [catCfdi, setCatCfdi] = useState<ICatalogoCfdi[]>([]);
  const [catAGEE, setCatAGEE] = useState<ICatalogoAGEE[]>([]);
  const [catAGEM, setCatAGEM] = useState<ICatalogoAGEM[]>([]);
  const [catAGEL, setCatAGEL] = useState<ICatalogoAGEL[]>([]);
  const [catAGEA, setCatAGEA] = useState<ICatalogoAGEA[]>([]);
  const [objOrganizacion, setObjOrganizacion] = useState<IDatosOrganizacion>(organizacion);
  const [urlLogo, setUrlLogo] = useState<string>('');
  const [objConsultorio, setObjConsultorio] = useState<IDatosConsultorio>(datosConsultorioInitial);
  const [objRolPermisos, setObjRolPermisos] = useState<IRolesData>(rolesDataInitial);
  const [steps] = useState([t('administrador_mis_datos'), t('administrador_mi_organizacion')]);
  const [consultorios, setConsultorios] = useState<IDatosConsultorio[]>([]);
  const [catTipoEstablecimiento, setCatTipoEstablecimiento] = useState<ICatalogosEstandar[]>([]);
  const [rolesPermisos, setRolesPermisos] = useState<IRolesData[]>([]);
  const [catTablaCsSecciones, setCatTablaCsSecciones] = useState<ICsTablaSecciones[]>([]);
  const [tipoRoles, setTipoRoles] = useState<ITipoRol[]>([]);
  const [arrayRoles, setArrayRoles] = useState<IRolSeleccionado[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [licenciasDisponibles, setLicenciasDisponibles] = useState<number>(0);
  const [csvArray, setCsvArray] = useState<IObjCsv[]>([]);
  const [csvPacientesArray, setCsvPacientesArray] = useState<IObjCsvPacientes[]>([]);
  const [datosCsvCryptos, setDatosCsvCryptos] = useState<IObjCsvPacientes[]>([]);
  const datosEnvioPacientesInitial: IDatosEnvioPacientes = {
    incremento: 50,
    total: 0,
    completados: 0,
    errores: [],
    deleteItems: 0,
    insertItems: 0,
    existsItems: 0,
    emailsSended: 0,
  };
  const [datosEnvioPacientes, setDatosEnvioPacientes] = useState<IDatosEnvioPacientes>(
    datosEnvioPacientesInitial,
  );
  const [errorArray, setErrorArray] = useState<IObjError[]>([]);
  const [stringInformacionCambios, setStringInformacionCambios] = useState<string>('');
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [disabledNext, setDisabledNext] = useState<boolean>(false);
  const dataInfoPacientes: IObjError[] = [];
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#1E40AF',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#1E40AF',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#1E40AF',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#1E40AF',
      zIndex: 1,
      fontSize: 18,
    },
  });
  const QontoStepIcon = (props: StepIconProps) => {
    const classes2 = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
      <div
        className={clsx(classes2.root, {
          [classes2.active]: active,
        })}
      >
        {completed ? <Check className={classes2.completed} /> : <div className={classes2.circle} />}
      </div>
    );
  };
  const handleSteps = (newStep: number) => {
    setCsvArray([]);
    setCsvPacientesArray([]);
    setErrorArray([]);
    setStringInformacionCambios('');
    setActiveStep(newStep);
  };
  const openAndDeleteAdmon = (idBorrado: number) => {
    setIdDelete(idBorrado);
    setDeleteAlert(true);
  };
  const openAndDeleteRol = (idBorrado: number) => {
    setIdDelete(idBorrado);
    setDeleteAlertRol(true);
  };
  const peticionPerfilAdministrador = () => {
    getPerfilMedicoDatosId(emailSend)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setObjAdministrador(result.data);
          setObjAdminTmp(result.data);
          if (
            (pais === 'mx' && result.data.docId.length === 18) ||
            (pais === 'uy' && (result.data.docId.length === 6 || result.data.docId.length === 7))
          ) {
            setDisableDocId(true);
          } else {
            setDisableDocId(false);
          }
        } else {
          setDisableDocId(false);
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: t('message-get-error'),
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        setDisableDocId(false);
        setObjAdministrador(datosAdministradorInitial);
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };
  const peticionOrganizacionAdministrador = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getMiOrganizacionAdministrador(idMedico, idOrganizacion),
        successFunction: (result: any) => setObjAdminInvitado(result.data),
        showMsgs: false,
      }),
    );
  };
  const deleteSelectionId = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: deleteMiOrganizacionAdministrador({
          idMedico,
          idOrganizacion,
          idDelete,
        }),
        successFunction: () => {
          setIdDelete(-1);
          setDeleteAlert(false);
          setObjAdminInvitado(datosAdministradorInitial);
        },
      }),
    );
  };
  const encriptionData = async () => {
    const binaryDerString = window.atob(getKeyBack());
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // email encriptado
    const emailC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(objAdminTmp.email),
    );
    // contraseña encriptada
    const contraseniaC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(objAdminTmp.password),
    );
    const updateData = {
      ...objAdminTmp,
      email: arrayBufferToBase64(emailC),
      password: arrayBufferToBase64(contraseniaC),
      emailSend,
    };
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendMiOrganizacionAdministrador({
          idMedico,
          idOrganizacion: organizacion.idOrganizacion,
          datos: updateData,
        }),
        successFunction: () => {
          setObjAdminTmp(datosAdministradorInitial);
          peticionOrganizacionAdministrador();
        },
      }),
    );
  };
  const encriptionMedicosData = async () => {
    const binaryDerString = window.atob(getKeyBack());
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    const datosCsvCrypto: IObjCsv[] = await Promise.all(
      csvArray.map(async (valorCsv) => ({
        ...valorCsv,
        CONTRASENIA: arrayBufferToBase64(
          await window.crypto.subtle.encrypt(
            {
              name: 'RSA-OAEP',
            },
            pemPublicKey,
            str2AB(valorCsv.CONTRASENIA),
          ),
        ),
        CORREO_ELECTRONICO: arrayBufferToBase64(
          await window.crypto.subtle.encrypt(
            {
              name: 'RSA-OAEP',
            },
            pemPublicKey,
            str2AB(valorCsv.CORREO_ELECTRONICO.trim().toLowerCase()),
          ),
        ),
      })),
    );
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendMiOrganizacionEquipoMedicoCsv({
          idMedico,
          idOrganizacion: organizacion.idOrganizacion,
          emailSend,
          datos: datosCsvCrypto,
        }),
        successFunction: (result: any) => {
          setDisabledSave(true);
          setStringInformacionCambios(
            `Registros borrados: ${result.data.deleteItems || 0}; Registros insertados: ${
              result.data.insertItems || 0
            }; Registros existentes: ${result.data.existsItems || 0}`,
          );
          if (result.data.licencias) {
            setLicenciasDisponibles(result.data.licencias);
          }
          if (result.data.erroresEmail) {
            const dataInfo: IObjError[] = [];
            result.data.erroresEmail.forEach((valorError: string) => {
              const datoBusqueda = csvArray.find(
                (valorCsv) => valorCsv.CORREO_ELECTRONICO === valorError,
              );
              dataInfo.push({
                posicion: 'N/A',
                error: `${datoBusqueda?.NOMBRE} ${datoBusqueda?.PRIMER_APELLIDO}` || '',
              });
            });
            setErrorArray(dataInfo || []);
          }
        },
        loading: false,
      }),
    );
  };
  const encriptionPacientesData = async () => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    const binaryDerString = window.atob(getKeyBack());
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    setDatosEnvioPacientes({ ...datosEnvioPacientes, total: csvPacientesArray.length });
    setDatosCsvCryptos(
      await Promise.all(
        csvPacientesArray.map(async (valorCsv) => ({
          ...valorCsv,
          NO_EMPLEADO: arrayBufferToBase64(
            await window.crypto.subtle.encrypt(
              {
                name: 'RSA-OAEP',
              },
              pemPublicKey,
              str2AB(valorCsv.NO_EMPLEADO),
            ),
          ),
          CORREO_ELECTRONICO: arrayBufferToBase64(
            await window.crypto.subtle.encrypt(
              {
                name: 'RSA-OAEP',
              },
              pemPublicKey,
              str2AB(valorCsv.CORREO_ELECTRONICO.trim().toLowerCase()),
            ),
          ),
          IMSS: arrayBufferToBase64(
            await window.crypto.subtle.encrypt(
              {
                name: 'RSA-OAEP',
              },
              pemPublicKey,
              str2AB(valorCsv.IMSS),
            ),
          ),
        })),
      ),
    );
  };
  const peticionConsultorios = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getOrganizacionConsultorios(idMedico, organizacion.idOrganizacion),
        successFunction: (result: any) => {
          const dataCheck = result.data.map((valueData: any) => {
            const newData = { ...valueData };
            newData.estaEnHospital = newData.estaEnHospital ? 'si' : 'no';
            return newData;
          });
          setConsultorios([...dataCheck]);
        },
        errorFunction: () => setConsultorios([]),
        showMsgs: false,
      }),
    );
  };
  const peticionRoles = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getOrganizacionRoles(idMedico, organizacion.idOrganizacion),
        successFunction: (result: any) => {
          const roles: any = [];
          result.data.forEach((row: any) => {
            roles.push({
              ...row,
              tipoRol: {
                id: row.tipoRol.id,
                tipo: row.tipoRol.tipo,
                grupo: row.tipoRol.grupo,
                secciones: JSON.parse(row.tipoRol.secciones),
              },
            });
          });
          setRolesPermisos(roles);
        },
        errorFunction: () => setRolesPermisos([]),
        showMsgs: false,
      }),
    );
  };
  const saveData = () => {
    if (activeStep === 0) {
      const updData = {
        ...objAdminTmp,
        emailSend,
        emailLog: emailSend,
      };
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: sendPerfilAdministrador({ idMedico, datos: updData }),
          successFunction: () => {
            dispatch(getMeMedico(getEmailMe()));
            setDisabledNext(false);
          },
        }),
      );
    } else if (activeStep === 1) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: sendMiOrganizacionOrganizacion({
            idMedico,
            idRol: rol.id,
            datos: { ...objOrganizacion, emailSend },
          }),
          successFunction: (result: any) => {
            if (result.id !== 0) {
              if (objOrganizacion.idOrganizacion === -1) {
                setObjOrganizacion({ ...objOrganizacion, idOrganizacion: result.id });
              }
              dispatch(getOrganizacion(idMedico, result.id));
            }
          },
        }),
      );
    } else if (activeStep === 2) {
      if (objAdminTmp.id === -1) {
        setShowModalDocId(!showModalDocId);
      } else {
        encriptionData();
      }
    } else if (activeStep === 3) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: sendMiOrganizacionConsultorios({
            idMedico,
            idOrganizacion: organizacion.idOrganizacion,
            datos: consultorios,
          }),
          successFunction: () => {
            setConsultorios([]);
            setObjConsultorio(datosConsultorioInitial);
            peticionConsultorios();
          },
        }),
      );
      // } else if (activeStep === 4) {
      //   dispatch(
      //     setRequest({
      //       type: 'send',
      //       requestFunction: sendMiOrganizacionRoles({
      //         idMedico,
      //         idOrganizacion: organizacion.idOrganizacion,
      //         datos: objRolPermisos,
      //       }),
      //       successFunction: () => {
      //         setObjRolPermisos(rolesDataInitial);
      //         peticionRoles();
      //       },
      //     }),
      //   );
    } else if (activeStep === 4) {
      encriptionMedicosData();
    } else if (activeStep === 5) {
      setDatosEnvioPacientes(datosEnvioPacientesInitial);
      encriptionPacientesData();
    }
  };
  const evalStep = () => {
    if (activeStep < 2) {
      return activeStep;
    }
    if (activeStep > 6) {
      return 2;
    }
    return 1;
  };
  const eliminarRol = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: deleteMiOrganizacionRoles({
          idMedico,
          idDelete,
          idOrganizacion: organizacion.idOrganizacion,
        }),
        successFunction: () => {
          setIdDelete(-1);
          setDeleteAlertRol(false);
          peticionRoles();
        },
      }),
    );
  };

  useEffect(() => {
    if (activeStep === 0 && objAdministrador.id > 0) {
      setObjAdminTmp(objAdministrador);
    } else if (activeStep === 1) {
      setObjOrganizacion(organizacion);
    } else if (activeStep === 2) {
      setObjAdminTmp(datosAdministradorInitial);
    } else if (activeStep === 3) {
      setObjConsultorio(datosConsultorioInitial);
    } else if (activeStep === 100) {
      setObjRolPermisos(rolesDataInitial);
    } else if (activeStep === 4 || activeStep === 5) {
      setDatosEnvioPacientes(datosEnvioPacientesInitial);
      setCsvArray([]);
      setCsvPacientesArray([]);
      setErrorArray([]);
      setStringInformacionCambios('');
    }
  }, [activeStep]);
  useEffect(() => {
    if (!csvPacientesArray.length) {
      setDatosEnvioPacientes(datosEnvioPacientesInitial);
    }
  }, [csvPacientesArray]);
  useEffect(() => {
    const len = datosCsvCryptos.length;
    if (len) {
      let datos: IObjCsvPacientes[];
      for (let i = 0; i < len; i += datosEnvioPacientes.incremento) {
        datos = datosCsvCryptos.slice(i, i + datosEnvioPacientes.incremento);
        sendMiOrganizacionPacientesCsv({
          idMedico,
          idOrganizacion: organizacion.idOrganizacion,
          emailSend,
          datos,
        })
          .then((response) => response.json())
          .then((result) => {
            const newDatos: IDatosEnvioPacientes = { ...datosEnvioPacientes };
            newDatos.completados = datosEnvioPacientes.completados + 1;
            if (result.code === 200) {
              newDatos.deleteItems =
                datosEnvioPacientes.deleteItems + (result.data.deleteItems || 0);
              newDatos.insertItems =
                datosEnvioPacientes.insertItems + (result.data.insertItems || 0);
              newDatos.existsItems =
                datosEnvioPacientes.existsItems + (result.data.existsItems || 0);
              newDatos.emailsSended =
                datosEnvioPacientes.emailsSended + (result.data.emailsSended || 0);
              if (result.data && result.data.erroresEmail) {
                result.data.erroresEmail.forEach((valorError: string) => {
                  const datoBusqueda = csvArray.find(
                    (valorCsv) => valorCsv.CORREO_ELECTRONICO === valorError,
                  );
                  dataInfoPacientes.push({
                    posicion: `${i + 1}-${i + datosEnvioPacientes.incremento + 1}`,
                    error: `${datoBusqueda?.NOMBRE} ${datoBusqueda?.PRIMER_APELLIDO}` || '',
                  });
                  newDatos.errores = [
                    ...datosEnvioPacientes.errores,
                    `${i + 1}-${i + datosEnvioPacientes.incremento + 1}`,
                  ];
                });
              }
            } else {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error')} (${i + 1}-${
                    i + datosEnvioPacientes.incremento + 1
                  }): ${result.msg}`,
                }),
              );
              newDatos.errores = [
                ...datosEnvioPacientes.errores,
                `${i + 1}-${
                  datosEnvioPacientes.incremento > datosEnvioPacientes.total
                    ? datosEnvioPacientes.total
                    : i + datosEnvioPacientes.incremento
                }`,
              ];
            }
            setDatosEnvioPacientes(newDatos);
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error')} (${i + 1}-${
                  i + datosEnvioPacientes.incremento + 1
                }): ${err.toString()}`,
              }),
            );
            setDatosEnvioPacientes({
              ...datosEnvioPacientes,
              completados: datosEnvioPacientes.completados + 1,
              errores: [
                ...datosEnvioPacientes.errores,
                `${i + 1}-${
                  datosEnvioPacientes.incremento > datosEnvioPacientes.total
                    ? datosEnvioPacientes.total
                    : i + datosEnvioPacientes.incremento
                }`,
              ],
            });
          });
      }
    }
  }, [datosCsvCryptos]);
  useEffect(() => {
    if (datosEnvioPacientes.total > 0) {
      if (
        Math.floor(datosEnvioPacientes.total / (datosEnvioPacientes.incremento + 1)) ===
        datosEnvioPacientes.completados - 1
      ) {
        dispatch(
          setSnackComplete({
            open: true,
            severity: datosEnvioPacientes.errores.length ? 'info' : 'success',
            mensaje: t(datosEnvioPacientes.errores.length ? 'message-info' : 'message-success'),
          }),
        );
        setErrorArray(dataInfoPacientes || []);
        setStringInformacionCambios(
          `Registros borrados: ${datosEnvioPacientes.deleteItems} | Registros insertados: ${
            datosEnvioPacientes.insertItems
          } | Registros existentes: ${
            datosEnvioPacientes.existsItems
          } | Correos de autorización enviados: ${datosEnvioPacientes.emailsSended}${
            datosEnvioPacientes.errores.length
              ? ` | Error en los registros: ${datosEnvioPacientes.errores.join(', ')}`
              : ''
          }`,
        );
      }
    }
  }, [datosEnvioPacientes]);
  useEffect(() => {
    setDisabledNext(
      objAdministrador.nombre.length === 0 ||
        objAdministrador.apellidoP.length === 0 ||
        (pais === 'mx' && objAdministrador.docId.length < 18) ||
        (pais === 'uy' && objAdministrador.docId.length < 6) ||
        objAdministrador.sexo.length === 0 ||
        objAdministrador.fechaNac.dia.length === 0 ||
        objAdministrador.fechaNac.mes.length === 0 ||
        objAdministrador.fechaNac.anio.length === 0 ||
        !validarFecha(
          objAdministrador.fechaNac.anio,
          objAdministrador.fechaNac.mes,
          objAdministrador.fechaNac.dia,
        ) ||
        !validarFechaEsAnterior(
          objAdministrador.fechaNac.anio,
          objAdministrador.fechaNac.mes,
          objAdministrador.fechaNac.dia,
        ) ||
        objAdministrador.nacionalidad.id.length === 0 ||
        objAdministrador.celular.numero.length === 0 ||
        objAdministrador.email.length === 0,
    );
  }, [objAdministrador]);
  useEffect(() => {
    dispatch(setLoading(true));
    if (organizacion.idOrganizacion > 0) {
      setObjOrganizacion(organizacion);
      let toJson = 4;
      const requestFunctions = [
        getMiOrganizacionAdministrador(idMedico, organizacion.idOrganizacion),
        getOrganizacionConsultorios(idMedico, organizacion.idOrganizacion),
        getOrganizacionRoles(idMedico, organizacion.idOrganizacion),
        getOrganizacionEquipoMedicoLicencias(idMedico, idOrganizacion, emailSend),
      ];
      if (organizacion.urlLogotipo) {
        toJson += 1;
        requestFunctions.push(
          obtenerURLArchivo(
            idMedico,
            null,
            organizacion.idOrganizacion,
            null,
            organizacion.urlLogotipo,
            'imagenOrganizacion',
          ),
        );
      }
      if (organizacion.agee?.cve_agee) {
        requestFunctions.push(getCatalogoAgem(organizacion.agee?.cve_agee || ''));
      }
      if (organizacion.agem?.cve_agee && organizacion.agem?.cve_agem) {
        requestFunctions.push(
          getCatalogoAgel(organizacion.agem?.cve_agee, organizacion.agem?.cve_agem),
        );
      }
      if (
        organizacion.agel?.cve_agee &&
        organizacion.agel?.cve_agem &&
        organizacion.agel?.cve_agel
      ) {
        requestFunctions.push(
          getCatalogoAgea(
            organizacion.agel?.cve_agee,
            organizacion.agel?.cve_agem,
            organizacion.agel?.cve_agel,
          ),
        );
      }
      Promise.all(requestFunctions)
        .then((responses) => {
          Promise.all(
            responses.map((response, index) => (index < toJson ? response.json() : response)),
          )
            .then((results) => {
              if (results[0].code === 200) {
                setObjAdminInvitado(results[0].data);
              } else {
                setObjAdminInvitado(datosAdministradorInitial);
              }
              if (results[1].code === 200) {
                const dataCheck = results[1].data.map((valueData: any) => {
                  const newData = { ...valueData };
                  newData.estaEnHospital = newData.estaEnHospital ? 'si' : 'no';
                  return newData;
                });
                setConsultorios([...dataCheck]);
              } else {
                setConsultorios([]);
              }
              if (results[2].code === 200) {
                const roles: any = [];
                results[2].data.forEach((row: any) => {
                  roles.push({
                    ...row,
                    tipoRol: {
                      id: row.tipoRol.id,
                      tipo: row.tipoRol.tipo,
                      grupo: row.tipoRol.grupo,
                      secciones: JSON.parse(row.tipoRol.secciones),
                    },
                  });
                });
                setRolesPermisos(roles);
              } else {
                setRolesPermisos([]);
              }
              if (results[3].code === 200) {
                setLicenciasDisponibles(results[3].data);
              } else {
                setLicenciasDisponibles(0);
              }
              if (results.length > 4 && results[4].code === 200) {
                setUrlLogo(results[4].data.url);
              } else {
                setUrlLogo('');
              }
              if (results.length > 5) {
                setCatAGEM(results[5]);
              }
              if (results.length > 6) {
                setCatAGEL(results[6]);
              }
              if (results.length > 7) {
                setCatAGEA(results[7]);
              }
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'success',
                  mensaje: t('message_get_success'),
                }),
              );
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-warning')} ${err.toString()}`,
                }),
              );
              dispatch(setLoading(false));
            });
        })
        .catch((err) => {
          if (err.toString() !== 'AbortError: The user aborted a request.') {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          }
          dispatch(setLoading(false));
        });
    }
  }, [organizacion.idOrganizacion]);
  useEffect(() => {
    if (objAdministrador.id === -1 && emailSend && emailSend.length > 0) {
      peticionPerfilAdministrador();
    }
  }, [emailSend]);
  useEffect(() => {
    dispatch(
      setRequest({
        type: 'get',
        multiple: true,
        requestFunctions: [
          getCatalogoNacionalidad(),
          getCatalogoCfdi(),
          getCatalogoAgee(idMedico),
          getCatalogoTipoEstablecimiento(),
          getCatalogoTablaTipoRoles(),
          getCatalogoTablaSecciones(),
        ],
        toJson: false,
        successFunctions: [
          (result: any) => setCatNacionalidad(result),
          (result: any) => setCatCfdi(result || []),
          (result: any) => setCatAGEE(result),
          (result: any) => setCatTipoEstablecimiento(result),
          (result: any) =>
            setTipoRoles(
              result.map((tipoRol: any) => ({
                id: tipoRol.id,
                tipo: tipoRol.tipo,
                grupo: tipoRol.grupo,
                secciones: JSON.parse(tipoRol.secciones),
              })),
            ),
          (result: any) => {
            setCatTablaCsSecciones(result);
            const rolesSel: IRolSeleccionado[] = [];
            result.forEach((valDatos: ICsTablaSecciones) => {
              rolesSel.push({ id: valDatos.id, checkLectura: false, checkEscritura: false });
            });
            setArrayRoles(rolesSel);
          },
        ],
      }),
    );
  }, []);

  return (
    <>
      <DocIdCorrectoAdmin
        open={showModalDocId}
        callBackClose={() => {
          setShowModalDocId(!showModalDocId);
        }}
        callBackAceptar={async () => {
          await encriptionData();
          setShowModalDocId(!showModalDocId);
        }}
        docId={objAdminTmp.docId}
        email={objAdminTmp.email}
        password={objAdminTmp.password}
      />
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('administrador_invitado_borrar')}
        callBackAceptar={deleteSelectionId}
        callBackClose={() => {
          setDeleteAlert(false);
          setIdDelete(-1);
        }}
      />
      <DeleteDialog
        open={deleteAlertRol}
        titulo={t('_atencion_')}
        descripcion={t('administrador_roles_eliminar')}
        callBackAceptar={eliminarRol}
        callBackClose={() => {
          setDeleteAlertRol(false);
          setIdDelete(-1);
        }}
      />
      <CssBaseline />
      <div className="p-4 bg-white">
        <h1 className="text-blue-800 font-normal m-0 text-center">
          {`${t('bienvenido')}, ${nombre || ''} ${primerApellido || ''}`}
        </h1>
        <div className="flex content-center justify-center">
          <Button disabled={activeStep === 0} onClick={() => handleSteps(0)}>
            <ArrowBackIosIcon />
          </Button>
          <Stepper alternativeLabel activeStep={evalStep()} connector={<QontoConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Button onClick={() => handleSteps(1)} disabled={activeStep > 0 || disabledNext}>
            <ArrowForwardIosIcon />
          </Button>
        </div>
        {!(idMedico > 0 && consultorios.length > 0) && (
          <div className="text-center p-4 bg-yellow-300 text-gray-800">
            {t('si_no_llenas_los_datos_no_podras_administrar_la_cuenta')}
          </div>
        )}
        {activeStep === 0 && (
          <Perfil
            activeStep={activeStep}
            objAdministrador={objAdministrador}
            objAdminTmp={objAdminTmp}
            setObjAdminTmp={setObjAdminTmp}
            disableDocId={disableDocId}
            catNacionalidad={catNacionalidad}
            setDisabledSave={setDisabledSave}
            openAndDeleteAdmon={openAndDeleteAdmon}
          />
        )}
        {activeStep >= 1 && activeStep <= 6 && (
          <MiOrganizacion
            objAdministrador={objAdminInvitado}
            objAdminTmp={objAdminTmp}
            setObjAdminTmp={setObjAdminTmp}
            disableDocId={disableDocId}
            disabledNext={disabledNext}
            objOrganizacion={objOrganizacion}
            setObjOrganizacion={setObjOrganizacion}
            urlLogo={urlLogo}
            setUrlLogo={setUrlLogo}
            catCfdi={catCfdi}
            catAGEE={catAGEE}
            catAGEM={catAGEM}
            catAGEL={catAGEL}
            catAGEA={catAGEA}
            catNacionalidad={catNacionalidad}
            disabledSave={disabledSave}
            setDisabledSave={setDisabledSave}
            setDisabledNext={setDisabledNext}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            openAndDeleteAdmon={openAndDeleteAdmon}
            objConsultorio={objConsultorio}
            setObjConsultorio={setObjConsultorio}
            consultorios={consultorios}
            setConsultorios={setConsultorios}
            catTipoEstablecimiento={catTipoEstablecimiento}
            objRolPermisos={objRolPermisos}
            setObjRolPermisos={setObjRolPermisos}
            rolesPermisos={rolesPermisos}
            openAndDeleteRol={openAndDeleteRol}
            tipoRoles={tipoRoles}
            arrayRoles={arrayRoles}
            catTablaCsSecciones={catTablaCsSecciones}
            licenciasDisponibles={licenciasDisponibles}
            csvArray={csvArray}
            setCsvArray={setCsvArray}
            csvPacientesArray={csvPacientesArray}
            setCsvPacientesArray={setCsvPacientesArray}
            errorArray={errorArray}
            setErrorArray={setErrorArray}
            stringInformacionCambios={stringInformacionCambios}
            setStringInformacionCambios={setStringInformacionCambios}
          />
        )}
      </div>
      <AppBar position="sticky" color="inherit" className={classesGen.bottomSticky}>
        <Toolbar className={classesGen.divButton}>
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={() => handleSteps(activeStep - 1)}
            >
              {t('atras')}
            </Button>
          </div>
          <div className="flex flex-row">
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveData()}
              disabled={disabledSave}
            >
              {t('guardar')}
            </Button>
            <div className="px-1" />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSteps(activeStep + 1)}
              disabled={activeStep === 5 || disabledNext}
            >
              {t('siguiente')}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AdmonInformacionComponente;
