import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Dialog, Slide, IconButton, FormControl, Select, MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateDayjsUtils from '@date-io/dayjs';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  getTablaRecetasPDFIni,
  getTablaConsultasPDF,
  getTablaConsultasPDFIni,
} from '@utils/getCatalogos';
import { RootState } from 'src/store';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { IModalHistoricoProps } from './types';
import { IDatosImport } from './HistorialPaciente/types';
import useStyles from './styles';
import HistorialPaciente from './HistorialPaciente';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function ModalHistorial({ open, callBackClose }: IModalHistoricoProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [menuRecetas] = useState<string[]>([
    t('recetas'),
    // t('sol-laboratorio'),
    // t('sol-diagnostico'),
    // t('sol-terapeutico'),
    t('consulta_historico'),
    t('consulta_realizada'),
  ]);
  const [selector, setSelector] = useState<number>(-1);
  const [dateInit, setDateInit] = useState<any>('');
  const [dateEnd, setDateEnd] = useState<any>('');
  const [datosTabla, setDatosTabla] = useState<IDatosImport[]>([]);
  const handleDateChange = (date: any, txt: string) => {
    if (date) {
      if (txt === 'inicio') {
        setDateInit(new Date(date.toDate()));
      } else if (txt === 'fin') {
        setDateEnd(new Date(date.toDate()));
      }
    }
  };
  const handleCallBackClose = () => {
    setSelector(-1);
    callBackClose();
    setDateInit('');
    setDateEnd('');
  };
  useEffect(() => {
    if (dateInit && dateEnd) {
      dispatch(setLoading(true));
      getTablaConsultasPDF(
        idMedico,
        idPaciente,
        idConsultorio,
        idOrganizacion,
        dayjs(dateInit).format('YYYY-MM-DD'),
        dayjs(dateEnd).format('YYYY-MM-DD'),
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setDatosTabla(result.data);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-get-error'),
              }),
            );
            setDatosTabla([]);
          }
          dispatch(setLoading(false));
        });
    }
  }, [dateInit, dateEnd]);
  useEffect(() => {
    if (selector === 0) {
      dispatch(setLoading(true));
      getTablaRecetasPDFIni(idMedico, idPaciente, idConsultorio, idOrganizacion)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setDatosTabla(result.data);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-get-error'),
              }),
            );
            setDatosTabla([]);
          }
          dispatch(setLoading(false));
        });
    } else if (selector === 1 || selector === 2) {
      dispatch(setLoading(true));
      getTablaConsultasPDFIni(idMedico, idPaciente, idConsultorio, idOrganizacion)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setDatosTabla(result.data);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'info',
                mensaje: t('message-get-error'),
              }),
            );
            setDatosTabla([]);
          }
          dispatch(setLoading(false));
        });
    }
  }, [selector]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleCallBackClose}
      aria-labelledby="delete-dialog-titulo"
      aria-describedby="delete-dialog-descripcion"
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{t('historial')}</h2>
        <IconButton aria-label="cerrar" color="inherit" onClick={handleCallBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="p-4">
        <div className="py-2 px-0">
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">{`${t('deseo_ver')}:`}</h3>
          <div className={`grid ${selector >= 0 ? 'grid-cols-3' : 'grid-cols-1'} gap-4`}>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={selector >= 0 ? selector : ''}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  setSelector(e.target.value as number);
                }}
                className={classes.textLeft}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <span className="text-gray-400 text-left">{t('selecciona')}</span>
                </MenuItem>
                {menuRecetas.map((label, indx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem value={indx} key={String(indx)}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selector >= 0 && (
              <>
                <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="dateInit"
                    value={dateInit}
                    onChange={(e) => handleDateChange(e, 'inicio')}
                    maxDate={dayjs()}
                    KeyboardButtonProps={{
                      'aria-label': 'fecha de inicio',
                    }}
                    invalidDateMessage={t('fecha_invalida')}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="dateEnd"
                    value={dateEnd}
                    onChange={(e) => handleDateChange(e, 'fin')}
                    minDate={dateInit}
                    maxDate={dayjs()}
                    KeyboardButtonProps={{
                      'aria-label': 'fecha fin',
                    }}
                    disabled={!dateInit}
                    invalidDateMessage={t('fecha_invalida')}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
          </div>
        </div>
        {datosTabla && datosTabla.length > 0 && (selector === 0 || selector === 1) && (
          <HistorialPaciente datosTabla={datosTabla} selector={selector} />
        )}
      </div>
    </Dialog>
  );
}

export default ModalHistorial;
