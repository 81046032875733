import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { ReactComponent as AlergiasIcon } from '@assets/icons/Alergias.svg';
import { ReactComponent as EnfermedadesIcon } from '@assets/icons/Enfermedades.svg';
import { ReactComponent as HospitalizacionIcon } from '@assets/icons/Hospitalizacion.svg';
import { ReactComponent as ProcedimientosQuirurgicosIcon } from '@assets/icons/ProcedimientosQuirurgicos.svg';
import { ReactComponent as ProcedimientosTerapeuticosIcon } from '@assets/icons/ProcedimientosTerapeuticos.svg';
import { ReactComponent as ProcedimientosDiagnosticosIcon } from '@assets/icons/ProcedimientosDiagnosticos.svg';
import { ReactComponent as TransfusionesIcon } from '@assets/icons/Transfusiones.svg';
import useStyles from './styles';
import Transfusiones from './Transfusiones';
import Hospitalizacion from './Hospitalizacion/index';
import Procedimiento from './Procedimiento/index';
import EnfermedadesTratamientos from './EnfermedadesTratamientos';
import Alergias from './Alergias';
import DiagnosticosGenerales from './DiagnosticosGenerales';
import { IDiagnosticoGeneral } from './DiagnosticosGenerales/types';

function PersonalesPatologicos() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    expediente: {
      antPatologicos: {
        procTerapeuticos, procDiagnosticos, procQuirurgicos, sinProcQuirurgicos,
      },
    },
    usuario: {
      antPatologicos: { diagnosticosGenerales },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const [activeStep, setActiveStep] = useState<
  | 'diagnosticos-generales'
  | 'enfermedades-tratamientos'
  | 'hospitalizaciones'
  | 'procedimientos-quirurgicos'
  | 'procedimientos-terapeuticos'
  | 'procedimientos-diagnosticos'
  | 'alergias'
  | 'transfusiones'
  >(esPaciente ? 'diagnosticos-generales' : 'enfermedades-tratamientos');

  return (
    <div>
      <div className="p-4 bg-white container mx-auto shadow rounded-b">
        <div className="relative pb-4">
          <div className="text-left">
            <h1 className="text-blue-800 font-normal m-2">{t('antecedentes-pnp-selecciona')}</h1>
          </div>
        </div>
        <div className="grid grid-cols-3 xl:grid-cols-4 gap-3 pb-6">
          {esPaciente &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 14) && (
              <Button
                className={
                  activeStep === 'diagnosticos-generales' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                startIcon={<EnfermedadesIcon />}
                fullWidth
                onClick={() => setActiveStep('diagnosticos-generales')}
                size="large"
              >
                {t('antecedentes-pp-diagnosticos-g')}
              </Button>
          )}
          {!esPaciente &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 15) && (
              <Button
                className={
                  activeStep === 'enfermedades-tratamientos' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setActiveStep('enfermedades-tratamientos')}
                size="large"
                startIcon={
                  idUsuario > 0 &&
                  diagnosticosGenerales.contenidoTabla.some(
                    (diagnostico: IDiagnosticoGeneral) =>
                      diagnostico.posee === 'si' && !diagnostico.validado,
                  ) ? (
                    <Warning color="secondary" fontSize="small" />
                    ) : (
                      <EnfermedadesIcon />
                    )
                }
              >
                {t('antecedentes-pp-enfermedades')}
              </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 17) && (
            <Button
              className={activeStep === 'hospitalizaciones' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<HospitalizacionIcon />}
              fullWidth
              onClick={() => setActiveStep('hospitalizaciones')}
            >
              {t('antecedentes-pp-hospitalizaciones')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 18) && (
            <Button
              className={
                activeStep === 'procedimientos-quirurgicos' ? classes.selected : classes.button
              }
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ProcedimientosQuirurgicosIcon />}
              fullWidth
              onClick={() => setActiveStep('procedimientos-quirurgicos')}
            >
              {t('antecedentes-pp-pquirurgico')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 19) && (
            <Button
              className={
                activeStep === 'procedimientos-terapeuticos' ? classes.selected : classes.button
              }
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ProcedimientosTerapeuticosIcon />}
              fullWidth
              onClick={() => setActiveStep('procedimientos-terapeuticos')}
            >
              {t('antecedentes-pp-pterapeutico')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 20) && (
            <Button
              className={
                activeStep === 'procedimientos-diagnosticos' ? classes.selected : classes.button
              }
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ProcedimientosDiagnosticosIcon />}
              fullWidth
              onClick={() => setActiveStep('procedimientos-diagnosticos')}
            >
              {t('antecedentes-pp-pdiagnosticos')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 21) && (
            <Button
              className={activeStep === 'alergias' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AlergiasIcon />}
              fullWidth
              onClick={() => setActiveStep('alergias')}
            >
              {t('antecedentes-pp-alergias')}
            </Button>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 22) && (
            <Button
              className={activeStep === 'transfusiones' ? classes.selected : classes.button}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<TransfusionesIcon />}
              fullWidth
              onClick={() => setActiveStep('transfusiones')}
            >
              {t('antecedentes-pp-transfusiones')}
            </Button>
          )}
        </div>
        <div>
          {activeStep === 'diagnosticos-generales' &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 14) && <DiagnosticosGenerales />}
          {activeStep === 'enfermedades-tratamientos' &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 15) && (
              <EnfermedadesTratamientos />
          )}
          {activeStep === 'hospitalizaciones' &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 17) && <Hospitalizacion />}
          {activeStep === 'procedimientos-quirurgicos' &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 18) && (
              <Procedimiento
                modulo="antecedentes"
                tipo="quirurgico"
                sinProcedimientos={sinProcQuirurgicos}
                procedimientos={procQuirurgicos}
              />
          )}
          {activeStep === 'procedimientos-terapeuticos' &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 19) && (
              <Procedimiento
                modulo="antecedentes"
                tipo="terapeutico"
                sinProcedimientos={false}
                procedimientos={procTerapeuticos}
              />
          )}
          {activeStep === 'procedimientos-diagnosticos' &&
            secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 20) && (
              <Procedimiento
                modulo="antecedentes"
                tipo="diagnostico"
                sinProcedimientos={false}
                procedimientos={procDiagnosticos}
              />
          )}
          {activeStep === 'alergias' && <Alergias />}
          {activeStep === 'transfusiones' && <Transfusiones />}
        </div>
      </div>
    </div>
  );
}

export default PersonalesPatologicos;
