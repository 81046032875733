import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '@common/styles';
import { IFechaField } from '@common/types';
import TablaMama from './TablaMama';
import { IMamaProps } from './types';

function Mama(props: IMamaProps) {
  const { ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const estudioItems = ['mastografia', 'rmn', 'ultrasonido', 'otro'];
  const resultadoItems = ['normal', 'anormal'];

  const [fechaField, setFechaField] = useState<IFechaField[]>([
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
  ]);

  const handleClickAgregarNuevoEstudio = () => {
    if (ginecoObstetrico.despistajeCancer.mama.arrayTabla.length < 10) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          mama: {
            arrayTabla: ginecoObstetrico.despistajeCancer.mama.arrayTabla.concat(
              `${
                ginecoObstetrico.despistajeCancer.mama.arrayTabla[
                  ginecoObstetrico.despistajeCancer.mama.arrayTabla.length - 1
                ]
              }I`,
            ),
            estudio: ginecoObstetrico.despistajeCancer.mama.estudio.concat(''),
            especifica: ginecoObstetrico.despistajeCancer.mama.especifica.concat(''),
            resultado: ginecoObstetrico.despistajeCancer.mama.resultado.concat(''),
            dia: ginecoObstetrico.despistajeCancer.mama.dia.concat(''),
            mes: ginecoObstetrico.despistajeCancer.mama.mes.concat(''),
            anio: ginecoObstetrico.despistajeCancer.mama.anio.concat(''),
            comentarios: ginecoObstetrico.despistajeCancer.mama.comentarios.concat(''),
          },
        },
      });
    }
  };

  const handleDeleteRow = (indx: number) => {
    if (ginecoObstetrico.despistajeCancer.mama.arrayTabla.length > 1) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          mama: {
            arrayTabla: ginecoObstetrico.despistajeCancer.mama.arrayTabla.filter(
              (_item, j) => indx !== j,
            ),
            estudio: ginecoObstetrico.despistajeCancer.mama.estudio.filter(
              (_item, j) => indx !== j,
            ),
            especifica: ginecoObstetrico.despistajeCancer.mama.especifica.filter(
              (_item, j) => indx !== j,
            ),
            resultado: ginecoObstetrico.despistajeCancer.mama.resultado.filter(
              (_item, j) => indx !== j,
            ),
            dia: ginecoObstetrico.despistajeCancer.mama.dia.filter((_item, j) => indx !== j),
            mes: ginecoObstetrico.despistajeCancer.mama.mes.filter((_item, j) => indx !== j),
            anio: ginecoObstetrico.despistajeCancer.mama.anio.filter((_item, j) => indx !== j),
            comentarios: ginecoObstetrico.despistajeCancer.mama.comentarios.filter(
              (_item, j) => indx !== j,
            ),
          },
        },
      });
      setFechaField((prevArr) => {
        const arr = [...prevArr];
        arr[indx].error = false;
        arr[indx].helperText = '';
        return arr;
      });
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          mama: {
            arrayTabla: ['I'],
            estudio: [''],
            especifica: [''],
            resultado: [''],
            dia: [''],
            mes: [''],
            anio: [''],
            comentarios: [''],
          },
        },
      });
      setFechaField([
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
      ]);
    }
  };
  const reviewCatchData = () => {
    if (
      ginecoObstetrico.despistajeCancer.mama.estudio.find((estudioVal) => estudioVal === '') !==
        undefined ||
      ginecoObstetrico.despistajeCancer.mama.resultado.find(
        (resultadoVal) => resultadoVal === '',
      ) !== undefined ||
      ginecoObstetrico.despistajeCancer.mama.dia.find((diaVal) => diaVal === '') !== undefined ||
      ginecoObstetrico.despistajeCancer.mama.mes.find((mesVal) => mesVal === '') !== undefined ||
      ginecoObstetrico.despistajeCancer.mama.anio.find((anioVal) => anioVal === '') !== undefined
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <h3 className="font-medium text-gray-900">{t('mama')}</h3>
      <div className="grid grid-cols-12 bg-blue-600 py-2 text-center rounded-t">
        <span className="text-white col-span-3">{t('estudio')}</span>
        <span className="text-white col-span-3">{t('resultado')}</span>
        <span className="text-white col-span-5">{t('fecha/comentarios')}</span>
      </div>
      {ginecoObstetrico.despistajeCancer.mama.arrayTabla.map((tabla, indx) => (
        <TablaMama
          ginecoObstetrico={ginecoObstetrico}
          setGinecoObstetrico={setGinecoObstetrico}
          key={tabla}
          index={indx}
          lastIndex={ginecoObstetrico.despistajeCancer.mama.arrayTabla.length - 1}
          estudioItems={estudioItems}
          resultadoItems={resultadoItems}
          handleDeleteRow={handleDeleteRow}
          fechaField={fechaField}
          setFechaField={setFechaField}
        />
      ))}
      <div className="flex justify-end">
        <Button
          startIcon={<AddCircleOutlineIcon />}
          className={classes.noTextTransform}
          color="primary"
          onClick={handleClickAgregarNuevoEstudio}
          disabled={reviewCatchData()}
        >
          {t('agregar_nuevo_estudio')}
        </Button>
      </div>
    </div>
  );
}

export default Mama;
