import { Menu, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { IMenuDoctor } from './types';

/** Componente que regresa el menú del doctor */
function MenuDoctor({
  anchorEl, handleClose, open, handleLogout,
}: IMenuDoctor) {
  const history = useHistory();
  const { t } = useTranslation();
  const { secciones, rol, enOrganizacion } = useSelector((state: RootState) => state.Me);
  return (
    <Menu
      id="menu-doctor"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 1) && (
        <MenuItem
          onClick={() => {
            history.push('/perfil-medico');
            handleClose();
          }}
        >
          {t('perfil')}
        </MenuItem>
      )}
      {((enOrganizacion && rol.grupo === 'administrativo') || !enOrganizacion) && (
        <MenuItem
          onClick={() => {
            history.push('/mi-cuenta');
            handleClose();
          }}
        >
          {t('mi_cuenta')}
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>{t('cerrar_sesion')}</MenuItem>
    </Menu>
  );
}

export default MenuDoctor;
