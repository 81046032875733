import { useTranslation } from 'react-i18next';
import {
  Select, TextField, FormControl, InputLabel, MenuItem,
} from '@material-ui/core';
import { capitalize } from '@common/functions';
import { IViewHoraProps } from './types';

function ViewHora({ componenteHora }: IViewHoraProps) {
  const { t } = useTranslation();

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="flex mb-2 items-end">
            <h3 className="pr-4 m-0 text-gray-600 font-medium">
              {capitalize(componenteHora.datosComponente.titulo)}
            </h3>
            <div className="text-gray-500">
              {componenteHora.datosComponente.descripcion
                ? `* ${componenteHora.datosComponente.descripcion}`
                : ''}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2 mb-3">
            <TextField
              label={t('hora')}
              variant="outlined"
              fullWidth
              disabled
              value={componenteHora.constructor.valor}
            />
            <FormControl variant="outlined" fullWidth disabled>
              <InputLabel>{t('horario')}</InputLabel>
              <Select label={t('horario')} value={componenteHora.constructor.hora!.horario}>
                <MenuItem value="a.m.">a.m.</MenuItem>
                <MenuItem value="p.m.">p.m.</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <TextField
          variant="outlined"
          rows={4}
          multiline
          fullWidth
          disabled
          label={t('comentarios')}
          inputProps={{ autoComplete: 'off', maxLength: 300 }}
          value={componenteHora.constructor.comentarios}
        />
      </div>
    </div>
  );
}

export default ViewHora;
