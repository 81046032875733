import {
  ModalActionTypes, RESET_ALL_MODALS, RESET_MODAL, SET_ACTIVE_MODAL,
} from './types';

export function setActiveModal(modalName: string): ModalActionTypes {
  return {
    type: SET_ACTIVE_MODAL,
    payload: modalName,
  };
}

export function resetModal(modalName: string): ModalActionTypes {
  return {
    type: RESET_MODAL,
    payload: modalName,
  };
}

export function resetAllModals(): ModalActionTypes {
  return {
    type: RESET_ALL_MODALS,
  };
}
