import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import FormCieMedico from './FormCIEMedico';
import FormPreexistente from './FormPreexistente';
import { IDiagnosticoProps } from './types';

function Diagnostico({
  idDiagnostico,
  setIdDiagnostico,
  diagnostico,
  setDiagnostico,
}: IDiagnosticoProps) {
  const { t } = useTranslation();
  const { enfReferidas } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const [seleccionDiagnostico, setSeleccionDiagnostico] = useState<
  'cie10' | 'medico' | 'preexistente'
  >('cie10');
  const [preexistente, setPreexistente] = useState<boolean>(false);
  const handleChangeSeleccionDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'cie10' | 'medico' | 'preexistente';
    setSeleccionDiagnostico(val);
    setPreexistente(val === 'preexistente');
    if (val === 'medico') {
      setDiagnostico({
        ...diagnostico,
        seleccionDiagnostico: 'medico',
        diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
      });
    } else {
      setDiagnostico({
        ...diagnostico,
        seleccionDiagnostico: 'cie10',
        diagnosticoMedico: '',
      });
    }
  };

  useEffect(() => {
    if (idDiagnostico !== -1) {
      const diagnosticoSel = enfReferidas.find((e) => e.idBD === idDiagnostico);
      if (diagnosticoSel) {
        setPreexistente(true);
        setSeleccionDiagnostico(diagnosticoSel.seleccionDiagnostico);
      }
    } else {
      setPreexistente(false);
    }
  }, [idDiagnostico]);

  return (
    <div className="p-4">
      <h1 className="text-blue-800 font-normal m-2 text-center">{t('d-p-diagnostico')}</h1>
      <div className="flex items-center my-4">
        <h3 className="m-0 mr-4 text-gray-600 font-medium">{`${t('diagnostico')}*`}</h3>
        <RadioGroup
          row
          name="diagnostico"
          value={seleccionDiagnostico}
          onChange={handleChangeSeleccionDiagnostico}
        >
          <FormControlLabel
            value="cie10"
            control={<Radio color="primary" />}
            label={t('CIE-10')}
            labelPlacement="end"
          />
          <FormControlLabel
            value="medico"
            control={<Radio color="primary" />}
            label={t('medico')}
            labelPlacement="end"
          />
          <FormControlLabel
            value="preexistente"
            control={<Radio color="primary" />}
            label={t('preexistente')}
            labelPlacement="end"
            disabled={idDiagnostico !== -1}
          />
        </RadioGroup>
      </div>
      {preexistente ? (
        <FormPreexistente
          idDiagnostico={idDiagnostico}
          setIdDiagnostico={setIdDiagnostico}
          setSeleccionDiagnostico={setSeleccionDiagnostico}
          diagnostico={diagnostico}
          setDiagnostico={setDiagnostico}
        />
      ) : (
        <FormCieMedico
          setIdDiagnostico={setIdDiagnostico}
          setSeleccionDiagnostico={setSeleccionDiagnostico}
          diagnostico={diagnostico}
          setDiagnostico={setDiagnostico}
        />
      )}
    </div>
  );
}

export default Diagnostico;
