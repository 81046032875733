import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button, Accordion, AccordionDetails, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '@common/styles';
import ListaDatosTab from '../ListaDatosTabs';
import { ITabExposicionBiologicoProps } from './types';
import { ITiempoSeleccionComentariosFecha } from '../../../types';

function TabExposicionBiologico({
  setOpenModalTiempoExposicion,
  setNombreModal,
  setIdTabSeleccionado,
  objExposicionRiesgo,
  setDatoActualizar,
  setInxArraySeleccionado,
  setDeleteAlert,
}: ITabExposicionBiologicoProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accAnimales, setAccAnimales] = useState<boolean | undefined>(undefined);
  const [accBacterias, setAccBacterias] = useState<boolean | undefined>(undefined);
  const [accHongos, setAccHongos] = useState<boolean | undefined>(undefined);
  const [accParasitos, setAccParasitos] = useState<boolean | undefined>(undefined);
  const [accVectores, setAccVectores] = useState<boolean | undefined>(undefined);
  const [accVirus, setAccVirus] = useState<boolean | undefined>(undefined);
  const [accOtro, setAccOtro] = useState<boolean | undefined>(undefined);
  return (
    <div className="w-full">
      {/* es la parte que corresponde a temperaturas altas */}
      <Accordion expanded={accAnimales} onChange={() => setAccAnimales(!accAnimales)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_biologico_animales')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_biologico_animales'));
                  setIdTabSeleccionado(33);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayAnimales.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayAnimales.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_biologico_animales')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(33);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_biologico_animales'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(33);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a temperaturas bajas */}
      <Accordion expanded={accBacterias} onChange={() => setAccBacterias(!accBacterias)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_biologico_bacterias')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_biologico_bacterias'));
                  setIdTabSeleccionado(34);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayBacterias.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayBacterias.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_biologico_bacterias')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(34);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_biologico_bacterias'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(34);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion ionizante */}
      <Accordion expanded={accHongos} onChange={() => setAccHongos(!accHongos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_biologico_hongos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_biologico_hongos'));
                  setIdTabSeleccionado(35);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_biologico_hongos')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(35);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_biologico_hongos'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(35);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion no ionizante */}
      <Accordion expanded={accParasitos} onChange={() => setAccParasitos(!accParasitos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_biologico_parasitos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_biologico_parasitos'));
                  setIdTabSeleccionado(36);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayParasitos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayParasitos.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_biologico_parasitos')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(36);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_biologico_parasitos'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(36);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ventilacion */}
      <Accordion expanded={accVectores} onChange={() => setAccVectores(!accVectores)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_biologico_vectores')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_biologico_vectores'));
                  setIdTabSeleccionado(37);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVectores.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVectores.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_biologico_vectores')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(37);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_biologico_vectores'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(37);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ventilacion */}
      <Accordion expanded={accVirus} onChange={() => setAccVirus(!accVirus)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_biologico_virus')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_biologico_virus'));
                  setIdTabSeleccionado(38);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_biologico_virus')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(38);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_biologico_virus'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(38);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a otro */}
      <Accordion expanded={accOtro} onChange={() => setAccOtro(!accOtro)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_biologico_otros')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalTiempoExposicion(true);
                  setNombreModal(t('antecedentes_laborales_biologico_otros'));
                  setIdTabSeleccionado(39);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro.map(
            (valPruebaLista: ITiempoSeleccionComentariosFecha, inx: number) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_biologico_otros')}
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(39);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_biologico_otros'));
                }}
                openModalActualizar={() => setOpenModalTiempoExposicion(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(39);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TabExposicionBiologico;
