import { useTranslation } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useTable } from 'react-table';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { capitalize } from '@common/functions';
import { IViewTabla2, ITableViewProps } from './types';
import useStyles from './styles';

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackCheckbox = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

const TableView = ({ columns, data, varOpciones }: ITableViewProps) => {
  const {
    getTableProps, headerGroups, rows, prepareRow,
  } = useTable({
    columns,
    data,
  });
  const classes = useStyles();
  const { t } = useTranslation();

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()} className={classes.header}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell, inx) => {
                const rowIndex = parseInt(cell.row.id, 10) || 0;
                let field = '';
                if (cell.column && cell.column.id === 'titulo') {
                  field = 'titulo';
                } else if (cell.column && cell.column.id === 'comentarios') {
                  field = 'comentarios';
                }
                if (field === 'titulo') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                }
                if (field === 'comentarios') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                    >
                      <BlackTextField
                        id="field-otra-religion"
                        placeholder={t('comentarios')}
                        disabled
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={1}
                        InputProps={{
                          className: classes.backgroundWhite,
                          inputProps: { maxLength: 150, autoComplete: 'off' },
                        }}
                        style={{ textAlign: 'center', width: '100%' }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    {...cell.getCellProps()}
                    className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                  >
                    {varOpciones ? (
                      <BlackCheckbox disabled style={{ textAlign: 'center', width: '100%' }} />
                    ) : (
                      <BlackRadio
                        style={{ textAlign: 'center', width: '100%' }}
                        disabled
                        value={cell.column.id}
                        name={`radio-${cell.column.id}-${inx}`}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
};

const ViewTabla = ({ componenteTabla }: IViewTabla2) => {
  const { titulo } = componenteTabla.datosComponente;
  const { descripcion } = componenteTabla.datosComponente;
  const { columnas } = componenteTabla.constructor.tabla!;
  const { renglones } = componenteTabla.constructor.tabla!;
  const varOpciones = componenteTabla.constructor.tabla!.unaVarOpc;

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex flex-wrap mb-2">
        <h3 className="mr-4 mt-0 mb-0 text-gray-600 font-medium">{capitalize(titulo)}</h3>
        <div className="text-gray-500">{descripcion ? `*${descripcion}` : ''}</div>
      </div>
      <CssBaseline />
      <TableView columns={columnas} data={renglones} varOpciones={varOpciones} />
    </div>
  );
};

export default ViewTabla;
