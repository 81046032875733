import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton } from '@material-ui/core';
import { IVariableSeleccionadaProps } from './types';

function VariableSeleccionada({ idBD, nombre, eliminarVariable }: IVariableSeleccionadaProps) {
  return (
    <div className="rounded-xl bg-gray-200 inline-block pl-3 mr-4 mb-2">
      <span className="text-gray-600">{nombre}</span>
      <IconButton onClick={() => eliminarVariable(idBD)} size="small">
        <CancelIcon />
      </IconButton>
    </div>
  );
}

export default VariableSeleccionada;
