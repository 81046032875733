import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IListaAntecedentesLaboralesProps } from '../types';
import {
  ITiempoSeleccionComentariosFecha,
  IDbTiempoSeleccionComentariosFecha,
  ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
  IObjComentariosFecha,
} from '../../types';

dayjs.locale('es');

function ListaAntecedentesLaborales({
  objTabEmpresa,
  objExposicionRiesgo,
  objLaboral,
  idEmpresa,
  inx,
  actualizaListaValores,
  eliminaListaValores,
}: IListaAntecedentesLaboralesProps) {
  const { t } = useTranslation();
  const valNombre = (radio: string | undefined) => {
    if (radio === 'consultorio') {
      return objTabEmpresa.opcionConsultorio.label;
    }
    if (radio === 'otro') {
      return objTabEmpresa.txtEmpresa;
    }
    return `${t('antecedentes_laborales_trabajador_independiente')}. ${objTabEmpresa.txtEmpresa}}`;
  };
  const validacionFecha = (dia: string, mes: string, anio: string) => {
    const formatString = dia.length === 0 ? 'MMM-YYYY' : 'DD-MMM-YYYY';
    return dayjs(`${anio}-${mes}-${dia.length === 0 ? '1' : dia}`).format(formatString);
  };
  const validacionCheck = () => {
    let valorRetorno = false;
    if (objTabEmpresa.checkTrabajoActual !== undefined && !objTabEmpresa.checkTrabajoActual) {
      valorRetorno = true;
    }
    return valorRetorno;
  };
  const changeTextIncapacidad = (txtToChange: string) => {
    let txtRadioTitulo = '';
    if (txtToChange === 'sin') {
      txtRadioTitulo = t('incapacidades_tipo_sin');
    } else if (txtToChange === 'temporal') {
      txtRadioTitulo = t('incapacidades_tipo_temporal');
    } else if (txtToChange === 'parcial') {
      txtRadioTitulo = t('incapacidades_tipo_parcial');
    } else if (txtToChange === 'total') {
      txtRadioTitulo = t('incapacidades_tipo_total');
    }
    return txtRadioTitulo;
  };
  const getTxtExposicionTiempo = (titulo: string, array: ITiempoSeleccionComentariosFecha[]) => (
    <>
      <h4 className="m-0 font-normal text-gray-500 pl-12 pt-2">{`- ${titulo}`}</h4>
      {array.map((valArray: ITiempoSeleccionComentariosFecha) => (
        <p className="m-0 pl-16 pt-2 font-normal text-gray-500">
          {`${t('antecedentes_laborales_modal_tiempo_exposicion')}: ${valArray.txtTiempo} ${
            valArray.radioSeleccion === 'M' ? 'minutos' : 'horas'
          }. ${valArray.comentarios}`}
        </p>
      ))}
    </>
  );
  const getTxtExposicionDbTiempo = (
    titulo: string,
    tituloNivel: string,
    array: IDbTiempoSeleccionComentariosFecha[],
  ) => (
    <>
      <h4 className="m-0 font-normal text-gray-500 pl-12 pt-2">{`- ${titulo}`}</h4>
      {array.map((valArray: IDbTiempoSeleccionComentariosFecha) => (
        <p className="m-0 pl-16 pt-2 font-normal text-gray-500">
          {valArray.txtDb ? `${tituloNivel}: ${valArray.txtDb} dB.` : ''}
          {`${t('antecedentes_laborales_modal_tiempo_exposicion')}: ${valArray.txtTiempo} ${
            valArray.radioSeleccion === 'M' ? 'minutos' : 'horas'
          }. ${valArray.comentarios}`}
        </p>
      ))}
    </>
  );
  const getTxtExposicionQuimico = (
    titulo: string,
    array: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[],
    tipo: boolean = true,
  ) => (
    <>
      <h4 className="m-0 font-normal text-gray-500 pl-12 pt-2">{`- ${titulo}`}</h4>
      {array.map((valArray: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha) => (
        <p className="m-0 pl-16 pt-2 font-normal text-gray-500">
          {tipo
            ? `${t('antecedentes_laborales_modal_quimico_tipo')}: ${
              valArray.tipoQuimico.id === 52
                ? valArray.txtQuimicoOtro
                : valArray.tipoQuimico.label
            }. ${t('antecedentes_laborales_modal_tiempo_exposicion')}: ${valArray.txtTiempo} ${
              valArray.radioSeleccion === 'M' ? 'minutos' : 'horas'
            }. ${valArray.comentarios}`
            : `${t('antecedentes_laborales_modal_tiempo_exposicion')}: ${valArray.txtTiempo} ${
              valArray.radioSeleccion === 'M' ? 'minutos' : 'horas'
            }. ${valArray.comentarios}`}
        </p>
      ))}
    </>
  );
  const getTxtExposicionComentario = (titulo: string, array: IObjComentariosFecha[]) => (
    <>
      <h4 className="m-0 font-normal text-gray-500 pl-12 pt-2">{`- ${titulo}`}</h4>
      {array.map((valArray: IObjComentariosFecha) => (
        <p className="m-0 pl-16 pt-2 font-normal text-gray-500">{valArray.comentarios}</p>
      ))}
    </>
  );
  return (
    <div className="border-0 border-solid border-red-500 rounded border-l-8 w-full shadow-md mb-4 flex justify-between items-center">
      <div className="w-full p-4">
        <h2 className="m-0 font-normal text-gray-900 pl-4">
          {valNombre(objTabEmpresa.radioEmpresa)}
        </h2>
        <div className="w-full">
          <div className="flex w-full pt-2">
            <p className="m-0 pl-4 font-normal text-gray-500">
              <b>{`${t('ocupacion')}: `}</b>
              {`${
                objTabEmpresa.opcionOcupacion.id !== 6862
                  ? objTabEmpresa.opcionOcupacion.label
                  : objTabEmpresa.txtOcupacion
              }      |`}
            </p>
            {objTabEmpresa.radioSindicalizado !== undefined && (
              <p className="m-0 pl-4 font-normal text-gray-500">
                <b>{`${t('antecedentes_laborales_sindicalizado')}: `}</b>
                {`${objTabEmpresa.radioSindicalizado ? t('si') : t('no')}`}
              </p>
            )}
          </div>
          <div className="flex w-full pt-2">
            <p className="m-0 pl-4 font-normal text-gray-500">
              <b>{`${t('antecedentes_laborales_fecha_ingreso')}: `}</b>
              {`${validacionFecha(
                '',
                objTabEmpresa.fechaIngresoMes || '',
                objTabEmpresa.fechaIngresoAnio || '',
              )}      |`}
            </p>
            {validacionCheck() ? (
              <p className="m-0 pl-4 font-normal text-gray-500">
                <b>{`${t('antecedentes_laborales_fecha_egreso')}: `}</b>
                {validacionFecha(
                  '',
                  objTabEmpresa.fechaEgresoMes || '',
                  objTabEmpresa.fechaEgresoAnio || '',
                )}
              </p>
            ) : (
              <p className="m-0 pl-4 font-normal text-gray-500">
                <b>{`${t('antecedentes_laborales_fecha_egreso')}: `}</b>
                {`${t('antecedentes_laborales_trabajo_actual')}`}
              </p>
            )}
          </div>
          {objExposicionRiesgo &&
            objExposicionRiesgo.grupoSelectores &&
            (objExposicionRiesgo.grupoSelectores.fisico.seleccionado === 1 ||
              objExposicionRiesgo.grupoSelectores.mecanico.seleccionado === 1 ||
              objExposicionRiesgo.grupoSelectores.quimico.seleccionado === 1 ||
              objExposicionRiesgo.grupoSelectores.biologico.seleccionado === 1 ||
              objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado === 1 ||
              objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado === 1) && (
              <>
                <h3 className="m-0 font-normal text-gray-900 pl-4 pt-2">
                  {t('antecedentes_laborales_exposicion_riesgo')}
                </h3>
                {objExposicionRiesgo.grupoSelectores.fisico.seleccionado === 1 && (
                  <div>
                    <h3 className="m-0 font-normal text-gray-500 pl-8 pt-2">
                      {`- ${t('antecedentes_laborales_fisico')}`}
                    </h3>
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                      .arrayTemperaturasAltas.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_tal'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                          .arrayTemperaturasAltas,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                      .arrayTemperaturasBajas.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_tba'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                          .arrayTemperaturasBajas,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                      .arrayRadiacionIonizante.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_rad'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                          .arrayRadiacionIonizante,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                      .arrayRadiacionNoIonizante.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_rad_no'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                          .arrayRadiacionNoIonizante,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_ven'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVentilacion,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_ilu'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayIluminacion,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido.length >
                      0 &&
                      getTxtExposicionDbTiempo(
                        t('antecedentes_laborales_fisico_ruido'),
                        t('antecedentes_laborales_modal_ruido_nivel'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayRuido,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_vib'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayVibracion,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayFluidoElectrico
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_fe'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico
                          .arrayFluidoElectrico,
                      )}
                    {objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro.length >
                      0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_fisico_otro'),
                        objExposicionRiesgo.grupoSelectores.fisico.opcionesFisico.arrayOtro,
                      )}
                  </div>
                )}
                {objExposicionRiesgo.grupoSelectores.mecanico.seleccionado === 1 && (
                  <div>
                    <h3 className="m-0 font-normal text-gray-500 pl-8 pt-2">
                      {`- ${t('antecedentes_laborales_mecanico')}`}
                    </h3>
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayAtrapamiento
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_atrapamiento'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayAtrapamiento,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                      .arrayAtropellamientosVehiculos.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_atropellamientos'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayAtropellamientosVehiculos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCaidaObjetos
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_caida_objetos'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayCaidaObjetos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                      .arrayCaidasMismoNivel.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_caidas_mismo'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayCaidasMismoNivel,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                      .arrayCaidasDiferenteNivel.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_caidas_diferente'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayCaidasDiferenteNivel,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                      .arrayContactoSuperficiesTrabajo.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_contacto_superficies'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayContactoSuperficiesTrabajo,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_choques'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayChoques,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                      .arrayContactoElectrico.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_contacto_electrico'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayContactoElectrico,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_cortes'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayCortes,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                      .arrayProyeccionFluidos.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_proyeccion_fluidos'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayProyeccionFluidos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                      .arrayProyeccionFragmentos.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_proyeccion_fragmentos'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayProyeccionFragmentos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayPinchazos
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_pinchazos'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico
                          .arrayPinchazos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_mecanico_otros'),
                        objExposicionRiesgo.grupoSelectores.mecanico.opcionesMecanico.arrayOtro,
                      )}
                  </div>
                )}
                {objExposicionRiesgo.grupoSelectores.quimico.seleccionado === 1 && (
                  <div>
                    <h3 className="m-0 font-normal text-gray-500 pl-8 pt-2">
                      {`- ${t('antecedentes_laborales_quimico')}`}
                    </h3>
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles
                      .length > 0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_aerosoles'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos.length >
                      0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_humos'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos
                      .length > 0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_gaseosos'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos
                      .length > 0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_liquidos'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos
                      .length > 0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_solidos'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas
                      .length > 0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_neblinas'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos
                      .length > 0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_polvos'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores
                      .length > 0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_vapores'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores,
                      )}
                    {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro.length >
                      0 &&
                      getTxtExposicionQuimico(
                        t('antecedentes_laborales_quimico_otros'),
                        objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro,
                        false,
                      )}
                  </div>
                )}
                {objExposicionRiesgo.grupoSelectores.biologico.seleccionado === 1 && (
                  <div>
                    <h3 className="m-0 font-normal text-gray-500 pl-8 pt-2">
                      {`- ${t('antecedentes_laborales_biologico')}`}
                    </h3>
                    {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayAnimales
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_biologico_animales'),
                        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico
                          .arrayAnimales,
                      )}
                    {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayBacterias
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_biologico_bacterias'),
                        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico
                          .arrayBacterias,
                      )}
                    {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_biologico_hongos'),
                        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayHongos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayParasitos
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_biologico_parasitos'),
                        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico
                          .arrayParasitos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVectores
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_biologico_vectores'),
                        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico
                          .arrayVectores,
                      )}
                    {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_biologico_virus'),
                        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayVirus,
                      )}
                    {objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_biologico_otros'),
                        objExposicionRiesgo.grupoSelectores.biologico.opcionesBiologico.arrayOtro,
                      )}
                  </div>
                )}
                {objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado === 1 && (
                  <div>
                    <h3 className="m-0 font-normal text-gray-500 pl-8 pt-2">
                      {`- ${t('antecedentes_laborales_ergonomico')}`}
                    </h3>
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayRepeticion.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_repeticion'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayRepeticion,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayPosturaIncomoda.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_postura'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayPosturaIncomoda,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayFuerzaExcesiva.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_fuerza'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayFuerzaExcesiva,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayPosicionesEstacionarias.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_posiciones'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayPosicionesEstacionarias,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayPresionDirecta.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_presion'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayPresionDirecta,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayTemperaturasExtremas.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_temperaturas'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayTemperaturasExtremas,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayVibraciones.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_vibraciones'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayVibraciones,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                      .arrayExtresContacto.length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_estres'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico
                          .arrayExtresContacto,
                      )}
                    {objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro
                      .length > 0 &&
                      getTxtExposicionTiempo(
                        t('antecedentes_laborales_ergonomico_otro'),
                        objExposicionRiesgo.grupoSelectores.ergonomico.opcionesErgonomico.arrayOtro,
                      )}
                  </div>
                )}
                {objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado === 1 && (
                  <div>
                    <h3 className="m-0 font-normal text-gray-500 pl-8 pt-2">
                      {`- ${t('antecedentes_laborales_psicosocial')}`}
                    </h3>
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayCargasTrabajoExcesivas.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_cargas'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayCargasTrabajoExcesivas,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayFaltaControlTrabajo.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_falta'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayFaltaControlTrabajo,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayJornadasTrabajoSuperiores.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_jornadas'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayJornadasTrabajoSuperiores,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayRotacionTurnos.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_rotacion'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayRotacionTurnos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayPeriodosCortosRecuperacion.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_periodos'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayPeriodosCortosRecuperacion,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayInterferenciaRelacion.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_interferencia'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayInterferenciaRelacion,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayLiderazgoNegativo.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_liderazgo'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayLiderazgoNegativo,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayHostigamiento.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_hostigamiento'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayHostigamiento,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayAcoso
                      .length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_acoso'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayAcoso,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                      .arrayMalosTratos.length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_malos'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayMalosTratos,
                      )}
                    {objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial.arrayOtro
                      .length > 0 &&
                      getTxtExposicionComentario(
                        t('antecedentes_laborales_psicosocial_otros'),
                        objExposicionRiesgo.grupoSelectores.psicosocial.opcionesPsicosocial
                          .arrayOtro,
                      )}
                  </div>
                )}
              </>
          )}
          {(objExposicionRiesgo.grupoSelectores.fisico.seleccionado === 0 ||
            objExposicionRiesgo.grupoSelectores.mecanico.seleccionado === 0 ||
            objExposicionRiesgo.grupoSelectores.quimico.seleccionado === 0 ||
            objExposicionRiesgo.grupoSelectores.biologico.seleccionado === 0 ||
            objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado === 0 ||
            objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado === 0) && (
            <div>
              <p className="m-0 pl-8 pt-2 font-normal text-gray-500">
                {`${t('antecedentes_laborales_riesgos_negados')}: 
                  ${
                    objExposicionRiesgo.grupoSelectores.fisico.seleccionado === 0
                      ? `${t('antecedentes_laborales_fisico')}, `
                      : ''
                  }
                  ${
                    objExposicionRiesgo.grupoSelectores.mecanico.seleccionado === 0
                      ? `${t('antecedentes_laborales_mecanico')}, `
                      : ''
                  }
                  ${
                    objExposicionRiesgo.grupoSelectores.quimico.seleccionado === 0
                      ? `${t('antecedentes_laborales_quimico')}, `
                      : ''
                  }
                  ${
                    objExposicionRiesgo.grupoSelectores.biologico.seleccionado === 0
                      ? `${t('antecedentes_laborales_biologico')}, `
                      : ''
                  }
                  ${
                    objExposicionRiesgo.grupoSelectores.ergonomico.seleccionado === 0
                      ? `${t('antecedentes_laborales_ergonomico')}, `
                      : ''
                  }
                  ${
                    objExposicionRiesgo.grupoSelectores.psicosocial.seleccionado === 0
                      ? `${t('antecedentes_laborales_psicosocial')}, `
                      : ''
                  }
                  `}
              </p>
            </div>
          )}
        </div>
        {objLaboral?.radioLaboral === 'no' && (
          <h3 className="m-0 font-normal text-gray-900 pl-4 pt-2">
            {`${t('accidentes_accidente_laboral_misma_empresa')}: ${t('no')}`}
          </h3>
        )}
        {objLaboral?.radioLaboral === 'si' &&
          objLaboral.arrayAccidentesLaborales.map((valArray, inxsub) => (
            <>
              <h3 className="m-0 font-normal text-gray-900 pl-4 pt-2">
                {`${t('accidentes_accidente_laboral_misma_empresa')} (${inxsub + 1})`}
              </h3>
              <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                {`${t('accidentes_fecha')}: ${validacionFecha(
                  valArray.fechaDia,
                  valArray.fechaMes,
                  valArray.fechaAnio,
                )}`}
              </p>
              {valArray.lugarAccidente.id > 0 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_lugar')}: ${t(valArray.lugarAccidente.label)}`}
                </p>
              )}
              {valArray.turnoAccidente.id > 0 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_turno')}: ${t(valArray.turnoAccidente.label)}`}
                </p>
              )}
              <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                {`${t('accidentes_causa')}: ${
                  valArray.tipoCausaAccidente.label || valArray.txtCausaAccidente
                }`}
              </p>
              <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                {`${t('accidentes_lesion_naturaleza')}: ${
                  valArray.tipoNaturalezaLesion.label || valArray.txtNaturalezaLesion
                }`}
              </p>
              {valArray.segmentoLesionado.id > 0 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_lesion_segmento')}: ${t(valArray.segmentoLesionado.label)}`}
                </p>
              )}
              {valArray.ubicacionLesion.id > 0 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_lesion_ubicacion')}: ${t(valArray.ubicacionLesion.label)}`}
                </p>
              )}
              {valArray.gravedad.id > 0 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_lesion_gravedad')}: ${t(valArray.gravedad.label)}`}
                </p>
              )}
              {valArray.porcentajeIncapacidad.id > 0 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_porcentaje_incapacidad')}: ${t(
                    valArray.porcentajeIncapacidad.label,
                  )}`}
                </p>
              )}
              {valArray.radioEncuestaNOM035 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_encuesta_nom_035')}: ${t(valArray.radioEncuestaNOM035)}`}
                </p>
              )}
              {valArray.radioAmeritaSeguimiento && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_amerita_seguimiento')}: ${t(valArray.radioAmeritaSeguimiento)}`}
                </p>
              )}
              {valArray.tipoRiesgo.id > 0 && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('incapacidades_tipo_riesgo')}: ${t(valArray.tipoRiesgo.label)}`}
                </p>
              )}
              {valArray.radioDiscapacidad && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('accidentes_incapacidad')}: ${changeTextIncapacidad(
                    valArray.radioDiscapacidad,
                  )}`}
                </p>
              )}
              {valArray.diasIncapacidad && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('incapacidades_dias')}: ${valArray.diasIncapacidad}`}
                </p>
              )}
              {valArray.comentarios && (
                <p className="m-0 pl-4 pt-2 font-normal text-gray-500">
                  {`${t('comentarios')}: ${valArray.comentarios}`}
                </p>
              )}
            </>
          ))}
      </div>
      {inx > -1 && (
        <div className="min-w-max self-center">
          <IconButton aria-label="edit" onClick={() => actualizaListaValores(inx)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => eliminaListaValores(inx)}
            disabled={idEmpresa === objTabEmpresa.id}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default ListaAntecedentesLaborales;
