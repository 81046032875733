import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { setInfoEmergencia, setInfoEmergenciaUsuario } from '@actions/paciente/actions';
import { setRutaPerfil } from '@actions/rutasExpediente/actions';
import { validacionNombres, validacionEmail, validacionApellidos } from '@constants/validaciones';
import AlertDialog from '@components/AlertDialogs/AlertDialog';
import { ICatalogosEstandar } from '@common/types';
import { getCatalogoParentesco } from '@utils/getCatalogos';
import {
  updateInfoEmergencia,
  updateInfoEmergenciaPaciente,
  updateInfoEmergenciaUsuario,
} from '@utils/sendInfo';
import { RootState } from 'src/store';
import { setRequest } from '@actions/request/types';
import { ModalForm } from '@components/modals/ModalForm';
import { IInfoEmergencia, infoEmergenciaInitial } from './types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import CheckboxInput from '@components/FormElemntsLibrary/CheckboxInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import EmailInput from '@components/FormElemntsLibrary/EmailInput';
import PhoneInput from '@components/FormElemntsLibrary/PhoneInput';

/** Formulario de la Info de emergencia del paciente */
function InfoEmergencia() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { infoEmergencia } = useSelector((state: RootState) => state.Paciente.expediente.perfil);
  const { infoEmergencia: infoEmergenciaUsuario } = useSelector(
    (state: RootState) => state.Paciente.usuario.perfil,
  );
  const { infoEmergencia: cambios } = useSelector(
    (state: RootState) => state.Paciente.cambios.perfil,
  );

  const [infoEmergenciaTemp, setInfoEmergenciaTemp] =
    useState<IInfoEmergencia>(infoEmergenciaInitial);

  /** states para mostrar o no diferentes campos */
  /** states del alert */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  /** catalogos */
  const [catParentesco, setCatParentesco] = useState<Array<ICatalogosEstandar>>([]);
  /** logica */
  const verificarErrorValidacionGeneral = false;
  // const verificarErrorValidacionGeneral =
  //   nombresField.error || apellidosField.error || emailField.error;
  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  /** Handlers del componente */
  const handleInputChange = (e: { name: string; value: any }) =>
    setInfoEmergenciaTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const getData = (datos: IInfoEmergencia, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    desconoceGSanguineo: datos.desconoceGSanguineo,
    tipoSanguineo: datos.tipoSanguineo,
    factorSanguineo: datos.factorSanguineo,
    nombreContacto: datos.nombreContacto,
    apellidosContacto: datos.apellidosContacto,
    parentescoContacto: datos.parentescoContacto,
    especifiqueParentescoContacto: datos.especifiqueParentescoContacto,
    emailContacto: datos.emailContacto,
    ladaTel: datos.ladaTel,
    telefonoContacto: datos.telefonoContacto,
    tipoTelefonoContacto: datos.tipoTelefonoContacto,
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosInfoEmergencia = getData(infoEmergenciaTemp, idUsuario);
    const sendFunctions = [updateInfoEmergencia(datosInfoEmergencia)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateInfoEmergenciaPaciente(datosInfoEmergencia));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(setInfoEmergencia({ ...infoEmergenciaTemp, loaded: true })),
          () => dispatch(setInfoEmergenciaUsuario({ ...infoEmergenciaTemp, loaded: true })),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergenciaUsuario(getData(infoEmergenciaTemp, idMiUsuario)),
        successFunction: () => {
          dispatch(setInfoEmergencia({ ...infoEmergenciaTemp, loaded: true }));
          dispatch(setRutaPerfil('seguros-planes'));
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergencia(getData(infoEmergenciaUsuario)),
        successFunction: () => {
          dispatch(setInfoEmergencia(infoEmergenciaUsuario));
          setInfoEmergenciaTemp(infoEmergenciaUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateInfoEmergenciaPaciente(getData(infoEmergencia, idUsuario)),
        successFunction: () => dispatch(setInfoEmergenciaUsuario(infoEmergencia)),
      }),
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoParentesco().then((result: ICatalogosEstandar[]) => {
      setCatParentesco(result);
    });
  }, []);

  useEffect(() => {
    if (infoEmergencia.loaded && !infoEmergenciaTemp.loaded) {
      setInfoEmergenciaTemp(infoEmergencia);
    }
  }, [infoEmergencia]);

  useEffect(() => {
    if (infoEmergenciaTemp.desconoceGSanguineo) {
      setInfoEmergenciaTemp({
        ...infoEmergenciaTemp,
        tipoSanguineo: '',
        factorSanguineo: '',
      });
    }
  }, [infoEmergenciaTemp.desconoceGSanguineo]);
  
  return (
    <div id="info-emergencia-paciente" aria-labelledby="info-emergencia-paciente">
      <ModalForm
        currentStep={4}
        title={t('info_de_emergencia')}
        id="info-emergencia-form"
        saveCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        acceptCallback={guardarDatosDeUsuarioAExpediente}
        rejectCallback={guardarDatosDeExpedienteAUsuario}
        saveDisable={verificarErrorValidacionGeneral}
        hayCambios={hayCambios}
        cambios={cambios}
      >
        <div className="flex flex-col">
          <h2 className="text-gray-600 font-medium my-4">{t('grupo_sanguineo')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 md:gap-2">
            <CheckboxInput
              containerClass='md:col-span-4 ml-4'
              name="desconoceGSanguineo"
              label={t('desconoce_grupo_sanguineo')}
              value={infoEmergenciaTemp.desconoceGSanguineo}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
            <CheckboxGroup
              name="tipoSanguineo"
              label={t('tipo')}
              options={['A', 'B', 'AB', 'O'].map((tipo) => ({
                value: tipo,
                label: tipo,
              }))}
              value={infoEmergenciaTemp.tipoSanguineo}
              setValue={handleInputChange}
              distribution="vertical-flex"
              disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
            />
            <CheckboxGroup
              name="factorSanguineo"
              label={t('factor_rh')}
              options={[
                { value: 'POSITIVO', label: <AddIcon /> },
                { value: 'NEGATIVO', label: <RemoveIcon /> },
              ]}
              value={infoEmergenciaTemp.factorSanguineo}
              setValue={handleInputChange}
              distribution="vertical-flex"
              disabled={hayCambios || infoEmergenciaTemp.desconoceGSanguineo}
            />
          </div>
          <h2 className="text-gray-600 font-medium my-4">{t('contacto_emergencia')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 md:gap-2 mt-4">
            <TextInput
              label={t('nombre(s)')}
              name="nombre"
              value={infoEmergenciaTemp.nombreContacto}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionNombres(val) ? t('nombre_incorrecto') : ''
              }
              maxLength={50}
              disabled={hayCambios}
            />
            <TextInput
              label={t('apellidos(s)')}
              name="nombre"
              value={infoEmergenciaTemp.apellidosContacto}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionApellidos(val) ? t('apellido_incorrecto') : ''
              }
              maxLength={50}
              disabled={hayCambios}
            />
            <SelectInput
              name="parentescoContacto"
              label={t('parentesco')}
              options={catParentesco}
              value={String(infoEmergenciaTemp.parentescoContacto)}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
            {String(infoEmergenciaTemp.parentescoContacto) === '11' &&
              <TextInput
                label={t('especifica')}
                name="especifiqueParentescoContacto"
                value={infoEmergenciaTemp.especifiqueParentescoContacto}
                setValue={handleInputChange}
                maxLength={50}
                disabled={hayCambios}
              />          
            }
            <EmailInput
              containerClass='md:col-span-2'
              label={t('email')}
              name="emailContacto"
              value={infoEmergenciaTemp.emailContacto}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionEmail(val) ? t('email_incorrecto') : ''
              }
            />
            <PhoneInput
              name="telefono"
              value={`${infoEmergenciaTemp.ladaTel}${infoEmergenciaTemp.telefonoContacto}`}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
            <CheckboxGroup
              containerClass='md:mt-8'
              label=''
              name="tipoTelefonoContacto"
              options={[
                { value: '1', label: t('celular') },
                { value: '2', label: t('fijo') },
              ]}
              value={infoEmergenciaTemp.tipoTelefonoContacto}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
          </div>
        </div>
      </ModalForm>
      <AlertDialog
        open={alertOpen}
        titulo="¡Atención!"
        descripcion=""
        nombreCancelar="Regresar"
        nombreAceptar="Guardar"
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
    </div>
  );
}

export default InfoEmergencia;
