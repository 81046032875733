import { RawDraftContentState } from 'draft-js';

export interface IHistoriaSexual {
  loaded: boolean;
  activo: string;
  edad: number | null;
  parejaNumero: number | null;
  parejaTipo: string;
  condon: string;
  violencia: string;
  comentariosViolencia: string;
  comentarios: RawDraftContentState;
}

export const historiaSexualInitial: IHistoriaSexual = {
  loaded: false,
  activo: '',
  edad: 0,
  parejaNumero: 0,
  parejaTipo: '',
  condon: '',
  violencia: '',
  comentariosViolencia: '',
  comentarios: {
    entityMap: {},
    blocks: [],
  },
};
