import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { setRutaAntNoPatologicos } from '@actions/rutasExpediente/actions';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { getEjercicios } from '@utils/getCatalogos';
import { RootState } from 'src/store';
import ResumenCambios from '@components/ResumenCambios';
import BotonesGuardar from '@components/BotonesGuardar';
import {
  antecedentesNoPatologicosGuardarActividadFisica,
  antecedentesNoPatologicosUsuarioPacienteGuardarActividadFisica,
  antecedentesNoPatologicosUsuarioGuardarActividadFisica,
} from '@utils/sendInfo';
import { ICatalogosEstandar } from '@common/types';
import { setActividadFisica, setActividadFisicaUsuario } from '@actions/paciente/actions';
import { setRequest } from '@actions/request/types';
import useStyles from './styles';
import EjercicioComponente from './Ejercicio/index';
import { IActividadFisica, ITipoActividad, actividadFisicaInitial } from './types';

function ActividadFisica() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    expediente: {
      antNoPatologicos: { actividadFisica },
    },
    usuario: {
      antNoPatologicos: { actividadFisica: actividadFisicaUsuario },
    },
    cambios: {
      antNoPatologicos: { actividadFisica: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const [actividadFisicaTemp, setActividadFisicaTemp] =
    useState<IActividadFisica>(actividadFisicaInitial);
  /** Catalogos */
  const [catEjercicios, setCatEjercicios] = useState<Array<ICatalogosEstandar>>([]);

  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  const tipoActividadInitial: ITipoActividad = {
    idTipo: { id: 0, label: '' },
    especifica: '',
    frecuencia: '',
    duracion: '',
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const realiza = (event.target as HTMLInputElement).value;
    if (realiza === 'F') {
      setActividadFisicaTemp({
        ...actividadFisicaTemp,
        realiza,
        tipo: [tipoActividadInitial],
      });
    } else {
      setActividadFisicaTemp({
        ...actividadFisicaTemp,
        realiza,
      });
    }
  };
  const addTipo = () => {
    setActividadFisicaTemp({
      ...actividadFisicaTemp,
      tipo: [...actividadFisicaTemp.tipo, tipoActividadInitial],
    });
  };
  const handleChangeTipo = (event: any, index: number, newValue: ICatalogosEstandar | null) => {
    const valUpdate = [...actividadFisicaTemp.tipo];
    if (!newValue || newValue.id !== 9999) {
      valUpdate[index].especifica = '';
    }
    valUpdate[index].idTipo = newValue || { id: 0, label: '' };
    setActividadFisicaTemp({
      ...actividadFisicaTemp,
      tipo: valUpdate,
    });
  };
  const handleChangeTipoEspecifica = (event: any, index: number) => {
    const valUpdate = [...actividadFisicaTemp.tipo];
    if (event.target.value.length === 0) {
      valUpdate[index].especifica = '';
    } else if (event.target.value.length < 41) {
      valUpdate[index].especifica = event.target.value;
    }
    setActividadFisicaTemp({
      ...actividadFisicaTemp,
      tipo: valUpdate,
    });
  };
  const deleteTipo = (index: number) => {
    if (actividadFisicaTemp.tipo.length === 1) {
      setActividadFisicaTemp({
        ...actividadFisicaTemp,
        tipo: [tipoActividadInitial],
      });
    } else {
      const newTipo = [...actividadFisicaTemp.tipo];
      newTipo.splice(index, 1);
      setActividadFisicaTemp({
        ...actividadFisicaTemp,
        tipo: newTipo,
      });
    }
  };
  const handleNumbers = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number,
    sel: string,
  ) => {
    const val = (event.target.value as string).match(/\D/g) ? '0' : (event.target.value as string);
    const valUpdate = [...actividadFisicaTemp.tipo];
    if (sel === 'frecuencia') {
      valUpdate[index].frecuencia = val;
    } else if (sel === 'duracion') {
      valUpdate[index].duracion = val;
    }
    setActividadFisicaTemp({
      ...actividadFisicaTemp,
      tipo: valUpdate,
    });
  };

  useEffect(() => {
    if (actividadFisica.loaded && !actividadFisicaTemp.loaded) {
      setActividadFisicaTemp(actividadFisica);
    }
  }, [actividadFisica]);
  useEffect(() => {
    getEjercicios().then((result: ICatalogosEstandar[]) => {
      setCatEjercicios(result);
    });
  }, []);

  const getDatosActividadFisica = (datos: IActividadFisica) => ({
    realiza: datos.realiza,
    tipo: datos.tipo,
    comentarios: datos.comentarios,
  });
  const getDatosExpediente = (datos: IActividadFisica) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    actividadFisica: getDatosActividadFisica(datos),
  });
  const getDatosUsuario = (datos: IActividadFisica) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    actividadFisica: getDatosActividadFisica(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(actividadFisicaTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarActividadFisica(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(
        antecedentesNoPatologicosUsuarioPacienteGuardarActividadFisica(datosToSend),
      );
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(setActividadFisica({ ...actividadFisicaTemp, loaded: true })),
          () => dispatch(setActividadFisicaUsuario({ ...actividadFisicaTemp, loaded: true })),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarActividadFisica(
          getDatosUsuario(actividadFisicaTemp),
        ),
        successFunction: () => {
          dispatch(setActividadFisica({ ...actividadFisicaTemp, loaded: true }));
          dispatch(setRutaAntNoPatologicos('toxicomania'));
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarActividadFisica(
          getDatosExpediente(actividadFisicaUsuario),
        ),
        successFunction: () => {
          dispatch(setActividadFisica(actividadFisicaUsuario));
          setActividadFisicaTemp(actividadFisicaUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarActividadFisica(
          getDatosExpediente(actividadFisica),
        ),
        successFunction: () => dispatch(setActividadFisicaUsuario(actividadFisica)),
      }),
    );
  };

  return (
    <div className="relative py-4">
      {hayCambios && <ResumenCambios cambios={cambios} />}
      <div className="grid grid-cols-6 pb-4">
        <div className="justify-self-start place-self-center">
          <h2 className="text-blue-800 font-normal m-0">{t('antecedentes-pnp-actividad')}</h2>
        </div>
        <div className="border-solid border-0 border-l border-gray-600 pl-12">
          <FormControl>
            <FormLabel component="legend" className={classes.boldText}>
              {t('antecedentes-pnp-actividad-realiza')}
            </FormLabel>
            <RadioGroup
              aria-label="realiza"
              name="realiza"
              value={actividadFisicaTemp.realiza}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel
                value="T"
                control={<Radio color="primary" />}
                label={t('si')}
                disabled={hayCambios}
              />
              <FormControlLabel
                value="F"
                control={<Radio color="primary" />}
                label={t('no')}
                disabled={hayCambios}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div>
        {actividadFisicaTemp.realiza === 'T' ? (
          <>
            {actividadFisicaTemp.tipo.map((val, index) => (
              <EjercicioComponente
                {...val}
                hayCambios={hayCambios}
                index={index}
                catEjercicios={catEjercicios}
                eliminarEjercicio={deleteTipo}
                handleChangeEjercicio={handleChangeTipo}
                handleChangeEjercicioEspecifica={handleChangeTipoEspecifica}
                handleNumbers={handleNumbers}
              />
            ))}
            <div className="flex justify-end mb-4">
              <Button
                color="primary"
                size="small"
                onClick={addTipo}
                className={classes.noTextTranform}
                startIcon={<AddCircleOutlineIcon />}
                disabled={
                  hayCambios ||
                  actividadFisicaTemp.tipo.some(
                    (actividad: ITipoActividad) =>
                      actividad.idTipo.id === 0 && !actividad.especifica.length,
                  )
                }
              >
                {t('antecedentes-pnp-actividad-aactividad')}
              </Button>
            </div>
          </>
        ) : null}
      </div>
      <div>
        <h3 className="text-gray-600 font-normal m-0 mb-2">{t('comentarios')}</h3>
        <TextField
          variant="outlined"
          fullWidth
          value={actividadFisicaTemp.comentarios}
          onChange={(event) =>
            setActividadFisicaTemp({
              ...actividadFisicaTemp,
              comentarios: event.target.value,
            })}
          inputProps={{ maxLength: 500, autoComplete: 'off' }}
          multiline
          disabled={hayCambios}
          rows="4"
        />
      </div>
      <BotonesGuardar
        hayCambios={hayCambios}
        aceptarCallback={guardarDatosDeUsuarioAExpediente}
        cancelarCallback={guardarDatosDeExpedienteAUsuario}
        guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        continuar={esPaciente}
      />
    </div>
  );
}

export default ActividadFisica;
