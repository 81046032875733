import { Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setRutaPerfil } from '@actions/rutasExpediente/actions';
/** Importacion de estilos */
import { RootState } from 'src/store';
import useStyles from './styles';
/** Importacion de los componentes de las tabs */
import DatosDeUbicacion from './DatosDeUbicacion';
import DatosPersonales from './DatosPersonales';
import Discapacidades from './Discapacidades';
import InfoEmergencia from './InfoEmergencia';
import SegurosPlanes from './SegurosPlanes';

function PerfilPaciente() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { idUsuario, idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { rutaPerfil } = useSelector((state: RootState) => state.RutasExpediente);
  const { cambios } = useSelector((state: RootState) => state.Paciente);

  return (
    <>
      <div className="pb-0 bg-white container mx-auto rounded-t-md border-solid border border-gray-100 shadow hidden md:block">
        <div>
          <Button
            className={
              rutaPerfil === 'datos-personales' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => dispatch(setRutaPerfil('datos-personales'))}
            startIcon={
              !esPaciente &&
              idUsuario > 0 &&
              cambios.perfil.datosPersonales.some((c: string) => c.length > 0) ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('datos_personales')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              rutaPerfil === 'datos-ubicacion' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => dispatch(setRutaPerfil('datos-ubicacion'))}
            startIcon={
              !esPaciente &&
              idUsuario > 0 &&
              cambios.perfil.datosUbicacion.some((c: string) => c.length > 0) ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('datos_de_ubicacion')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              rutaPerfil === 'discapacidades' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => dispatch(setRutaPerfil('discapacidades'))}
            startIcon={
              !esPaciente &&
              idUsuario > 0 &&
              cambios.perfil.discapacidades.some((c: string) => c.length > 0) ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('discapacidades')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              rutaPerfil === 'info-emergencia' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => dispatch(setRutaPerfil('info-emergencia'))}
            startIcon={
              !esPaciente &&
              idUsuario > 0 &&
              cambios.perfil.infoEmergencia.some((c: string) => c.length > 0) ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('info_de_emergencia')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              rutaPerfil === 'seguros-planes' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => dispatch(setRutaPerfil('seguros-planes'))}
            startIcon={
              !esPaciente &&
              idUsuario > 0 &&
              cambios.perfil.segurosPlanes.some((c: string) => c.length > 0) ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('seguros_y_planes')}
          </Button>
        </div>
      </div>
      {rutaPerfil === 'datos-personales' && <DatosPersonales />}
      {idPaciente > 0 && rutaPerfil === 'datos-ubicacion' && <DatosDeUbicacion />}
      {idPaciente > 0 && rutaPerfil === 'discapacidades' && <Discapacidades />}
      {idPaciente > 0 && rutaPerfil === 'info-emergencia' && <InfoEmergencia />}
      {idPaciente > 0 && rutaPerfil === 'seguros-planes' && <SegurosPlanes />}
    </>
  );
}

export default PerfilPaciente;
