import { FC, useEffect, useRef, useState } from 'react';
import CountryInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { PhoneInputProps } from './type';

export const PhoneInput: FC<PhoneInputProps> = ({
  name,
  containerClass = '',
  label: externalLabel,
  isRequired,
  placeholder,
  disableOption,
  setValue,
  disabled = false,
  value: externalValue,
}) => {
  const { t } = useTranslation();
  const { pais } = useSelector((state: RootState) => state.Me);
  const [phone, setPhone] = useState(externalValue || '');
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [wasFocused, setWasFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const label = externalLabel === undefined ? t(name) : externalLabel;
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  // Sincronizar con valor externo
  useEffect(() => {
    if (externalValue !== undefined) {
      setPhone(externalValue);
    }
  }, [externalValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      setWasFocused(!!inputRef.current?.contains(event.target as Node));
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wasFocused]);

  const validatePhone = (phoneNumber: string) => {
    if (isRequired && (!phoneNumber || phoneNumber.trim() === '')) {
      setErrorMessage('Este campo es requerido');
      return false;
    }

    if (phoneNumber && phoneNumber.length < 10) {
      setErrorMessage('El número debe tener al menos 10 dígitos');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const handlePhoneChange = (value: string, data: any) => {
    setPhone(value);
    setErrorMessage('');

    // Crear evento sintético
    const syntheticEvent = {
      name,
      value: { numero: value, codigo: data.dialCode },
      type: 'tel',
    };

    setValue(syntheticEvent);
  };

  const handleBlur = () => {
    validatePhone(phone);
  };

  const handleDisableChange = () => {
    const newState = !isDisabled;
    setIsDisabled(newState);
    setErrorMessage('');

    if (disableOption?.name) {
      setValue?.({ name: disableOption.name, value: newState });
      setPhone('');
    }
    if (!newState) {
      setValue?.({ name, value: '' });
    }
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={!!disableOption?.value}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  const customStyles = {
    containerStyle: {
      width: '100%',
    },
    inputStyle: {
      width: '100%',
      height: '48px',
      fontSize: '0.875rem',
      borderRadius: '0.300rem',
      border: errorMessage ? '1px solid rgb(239 68 68)' : '1px solid rgb(209 213 219)',
      backgroundColor: isDisabled ? '#F9FAFB' : 'white',
      color: isDisabled ? '#9CA3AF' : 'black',
      padding: '8px 8px 8px 58px',
      boxShadow: errorMessage ? '0 0 0 2px rgb(254 202 202)' : 'none',
    },
    buttonStyle: {
      border: errorMessage ? '1px solid rgb(239 68 68)' : '1px solid rgb(209 213 219)',
      borderRight: 'none',
      borderRadius: '0.300rem 0 0 0.300rem',
      backgroundColor: isDisabled ? '#F9FAFB' : 'white',
    },
  };

  return (
    <div className={`w-full h-fit mb-4 ${containerClass}`}>
      <div className="px-4">
        <div className="">
          <div className="flex items-center justify-between mb-2">
            {label && (
              <label
                htmlFor={name}
                className={`block font-medium text-base ${
                  errorMessage ? 'text-red-500' : 'text-gray-700'
                } ${
                  isDisabled ? 'text-gray-500' : ''
                }`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            )}
            {disableOption?.position === 'right' && renderDisableCheckbox()}
          </div>

          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div ref={inputRef}>
                <CountryInput
                  country={pais}
                  preferredCountries={[pais, 'us']}
                  localization={es}
                  enableSearch
                  searchPlaceholder={t('buscar')}
                  searchNotFound={t('sin_resultados')}
                  value={phone}
                  onChange={(e, newPhone) => handlePhoneChange(e, newPhone)}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                  inputProps={{
                    name,
                    placeholder: placeholder || 'Ingresa el número telefónico',
                  }}
                  containerStyle={customStyles.containerStyle}
                  inputStyle={customStyles.inputStyle}
                  buttonStyle={customStyles.buttonStyle}
                  containerClass={`phone-input-container rounded-md ${
                    wasFocused ? 'ring-2 ring-blue-500' : ''}
                  `}
                  disableDropdown={isDisabled}
                />
              </div>
            </div>
            {disableOption?.position === 'after' && renderDisableCheckbox()}
          </div>

          {errorMessage && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default PhoneInput;
