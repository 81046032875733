import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { stringMes } from '@common/functions';
import ViewComentarios from '@components/Comentarios/view';
import { IListaHospitalizacionesProps } from './types';

function ListaHospitalizaciones({
  hospitalizacion,
  index,
  setEditAlert,
  setDeleteAlert,
  idBD,
  setIndexToUpdate,
  setIndexToDelete,
  editHospitalizacion,
  // variables para checar si activamos ala alerta de edicion
  diagnosticoRelacionadoALaHospitalizacionVerificar,
  motivoHospitalizacionVerificar,
  duracionVerificar,
  fechaVerificar,
  comentariosVerificar,
}: IListaHospitalizacionesProps) {
  const { t } = useTranslation();
  const {
    idBD: indiceBD,
    diagnosticoRelacionadoALaHospitalizacion,
    motivoHospitalizacion,
    duracion,
    fecha,
    comentarios,
  } = hospitalizacion;

  const handleEditHospitalizacion = () => {
    if (
      diagnosticoRelacionadoALaHospitalizacionVerificar.id ||
      motivoHospitalizacionVerificar ||
      fechaVerificar.anio ||
      duracionVerificar.id ||
      comentariosVerificar.blocks.length
    ) {
      setIndexToUpdate(index);
      setEditAlert(true);
    } else {
      setIndexToUpdate(index);
      editHospitalizacion(index);
    }
  };

  const handleDeleteHospitalizacion = () => {
    setIndexToDelete(index);
    setDeleteAlert(true);
  };
  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 pr-5 w-full">
        <h3 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-800">
          <span className="font-medium">{`${t('antecedentes-pp-hos-motivo')}: `}</span>
          {motivoHospitalizacion}
        </h3>
        {!!diagnosticoRelacionadoALaHospitalizacion.id && (
          <h3 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-800">
            <span className="font-medium">{`${t('antecedentes-pp-hos-diag-res')}: `}</span>
            {diagnosticoRelacionadoALaHospitalizacion.label}
          </h3>
        )}
        <div className="flex">
          {duracion.id !== 0 && (
            <h4 className="font-normal text-gray-500 m-0 mr-4">
              {`${t('antecedentes-pp-hos-duracion')}: ${duracion.label}`}
            </h4>
          )}
          {fecha.anio && (
            <h4 className="font-normal m-0 text-gray-600">
              {`${t('fecha')}: ${fecha.dia ? `${fecha.dia.padStart(2, '0')}/` : ''}${
                fecha.mes ? `${stringMes(fecha.mes, t)}/` : ''
              }${fecha.anio}`}
            </h4>
          )}
        </div>
        {!!comentarios.blocks.length && (
          <div className="max-h-24 overflow-y-auto w-full">
            <ViewComentarios comentarios={comentarios} />
          </div>
        )}
      </div>
      <div className="min-w-max self-center">
        <IconButton aria-label="edit" color="primary" onClick={handleEditHospitalizacion}>
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={handleDeleteHospitalizacion}
          disabled={idBD === indiceBD}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaHospitalizaciones;
