import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from '@common/styles';
import { ReactComponent as LogoIcon } from '@assets/logo/Logotipo_Color.svg';
import { regexPassword } from '@common/functions';
import { enviarPasswordReestablecer, enviarTokenReestablecer } from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import { setSnackComplete } from '@actions/snackbar/types';
import { arrayBufferToBase64, str2ab, str2AB } from '@common/base64Convert';
import { getPublicKey } from '@utils/getCatalogos';

function RestablecePassword() {
  const { token } = useParams() as { token: string };
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [keyBack, setKeyBack] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [contrasenia, setContrasenia] = useState<string>('');
  const [contraseniaRepetida, setContraseniaRepetida] = useState<string>('');
  const [verPassword, setVerPassword] = useState<boolean>(false);
  const [verPasswordRepetido, setVerPasswordRepetido] = useState<boolean>(false);
  const [cargando, setCargando] = useState<boolean>(true);
  const [exitoso, setExitoso] = useState<boolean>(false);
  const [errores, setErrores] = useState({
    password: { error: false, helperText: '' },
    passwordRepetido: { error: false, helperText: '' },
  });

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContrasenia(val);
  };

  const handleChangePasswordRepetido = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContraseniaRepetida(val);
  };

  const handleClickVerPasswordRepetido = () => {
    setVerPasswordRepetido((prev) => !prev);
  };

  const handleClickVerPassword = () => {
    setVerPassword((prev) => !prev);
  };

  const handleMouseDownPasswordRepetido = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleFocusPassword = () => {
    setErrores({
      password: { error: false, helperText: '' },
      passwordRepetido: { error: false, helperText: '' },
    });
  };

  const handleClickActualizar = async () => {
    if (contraseniaRepetida !== contrasenia) {
      setErrores({
        password: { error: true, helperText: t('las_contrasenias_no_coinciden') },
        passwordRepetido: { error: true, helperText: t('las_contrasenias_no_coinciden') },
      });
      return;
    }
    if (contrasenia.length < 8) {
      setErrores({
        password: { error: true, helperText: t('la_contrasenia_debe_tener_al_menos_8_caracteres') },
        passwordRepetido: {
          error: true,
          helperText: t('la_contrasenia_debe_tener_al_menos_8_caracteres'),
        },
      });
      return;
    }
    if (!regexPassword(contrasenia)) {
      setErrores({
        password: {
          helperText: t(
            'la_contraseña_debe_tener_al_menos_una_mayuscula_una_minuscula_un_numero_y_un_caracter_especial',
          ),
          error: true,
        },
        passwordRepetido: {
          error: true,
          helperText: t(
            'la_contraseña_debe_tener_al_menos_una_mayuscula_una_minuscula_un_numero_y_un_caracter_especial',
          ),
        },
      });
      return;
    }
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    // Importada la key desde el back
    const pemContents = keyBack.substring(pemHeader.length, keyBack.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // email encriptado
    const emailC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(email),
    );
    // contraseña encriptada
    const contraseniaC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(contrasenia),
    );
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enviarPasswordReestablecer({
          email: arrayBufferToBase64(emailC),
          token,
          password: arrayBufferToBase64(contraseniaC),
        }),
        successFunction: () => setExitoso(true),
        showMsgLoading: false,
        showMsgSuccess: false,
        errorMessage: t('error_ocurrio_un_error_intentar_mas_tarde'),
      }),
    );
  };

  useLayoutEffect(() => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enviarTokenReestablecer({ token }),
        successFunction: (result) => {
          setEmail(result.email);
          setCargando(false);
        },
        errorFunction: (result: any) => {
          if (result.code === 300) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('tu_token_ha_expirado'),
              }),
            );
            history.push('/');
          } else if (result.code === 301) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('tu_token_ya_ha_sido_usado'),
              }),
            );
            history.push('/');
          } else if (result.code === 302) {
            history.push('/');
          }
        },
        showMsgError: false,
      }),
    );
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    getPublicKey(signal).then((result: string) => setKeyBack(result));
    return () => abortController.abort();
  }, []);

  return (
    <>
      <CssBaseline />
      <div className="text-white bg-white">
        <AppBar color="inherit">
          <Toolbar>
            <div className="text-gray-900 w-100 flex-grow">
              <LogoIcon height="55" />
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <div className="container mx-auto mt-4">
        {exitoso ? (
          <div className="relative md:mx-auto w-full md:w-8/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12 shadow-lg bg-white rounded-xl py-6 px-10 border border-solid border-gray-200 text-center">
            <h2 className="mt-0">
              {t('tu_contrasenia_ha_sido_modificada_satisfactoriamente')}
              .
            </h2>
            <div className="text-green-500 text-7xl mb-2">
              <CheckCircleIcon color="inherit" fontSize="inherit" />
            </div>
            <Button onClick={() => history.push('/login')} color="primary" variant="contained">
              {t('ir_a_medipraxi')}
            </Button>
          </div>
        ) : (
          <div className="relative md:mx-auto w-full md:w-10/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12 shadow-lg bg-white rounded-xl py-6 px-10 border border-solid border-gray-200">
            <h2 className="text-blue-800 font-medium m-0">
              {cargando ? <Skeleton /> : t('restablecer_contrasenia')}
            </h2>
            <h3 className="text-gray-600 font-medium mb-0">
              {cargando ? <Skeleton /> : t('tu_correo_electronico')}
            </h3>
            <h3 className="text-gray-600 font-normal mt-0">{cargando ? <Skeleton /> : email}</h3>
            <div className="relative mb-8">
              {cargando ? (
                <Skeleton variant="rect" height={56} />
              ) : (
                <TextField
                  error={errores.password.error}
                  label={`${t('contrasenia_nueva')}*`}
                  variant="outlined"
                  type={verPassword ? 'text' : 'password'}
                  fullWidth
                  value={contrasenia}
                  onChange={handleChangePassword}
                  onFocus={handleFocusPassword}
                  onCopy={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onCut={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  helperText={errores.password.helperText}
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                  InputProps={{
                    endAdornment: (
                      <div className="text-blue-500">
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickVerPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            color="inherit"
                          >
                            {verPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      </div>
                    ),
                    inputProps: {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: '50',
                    },
                  }}
                />
              )}
            </div>
            <div className="relative mb-8">
              {cargando ? (
                <Skeleton variant="rect" height={56} />
              ) : (
                <TextField
                  error={errores.passwordRepetido.error}
                  label={`${t('repetir_contrasenia')}*`}
                  variant="outlined"
                  type={verPasswordRepetido ? 'text' : 'password'}
                  onFocus={handleFocusPassword}
                  fullWidth
                  value={contraseniaRepetida}
                  onChange={handleChangePasswordRepetido}
                  onCopy={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onCut={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  helperText={errores.passwordRepetido.helperText}
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                  InputProps={{
                    endAdornment: (
                      <div className="text-blue-500">
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickVerPasswordRepetido}
                            onMouseDown={handleMouseDownPasswordRepetido}
                            edge="end"
                            color="inherit"
                          >
                            {verPasswordRepetido ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      </div>
                    ),
                    inputProps: {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: '50',
                    },
                  }}
                />
              )}
            </div>
            <div className="flex justify-end w-full">
              <div className="mr-4">
                {cargando ? (
                  <Skeleton variant="rect" height={37} width={91} />
                ) : (
                  <Button onClick={() => history.push('/login')} color="primary" variant="outlined">
                    {t('cancelar')}
                  </Button>
                )}
              </div>
              {cargando ? (
                <Skeleton variant="rect" height={37} width={91} />
              ) : (
                <Button
                  onClick={handleClickActualizar}
                  color="primary"
                  variant="contained"
                  disabled={!contrasenia || !contraseniaRepetida}
                >
                  {t('actualizar')}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RestablecePassword;
