import { ICatalogosEstandar } from '@common/types';
import { ICompUnico } from '../../PersonalizacionFC/SeleccionVariablesMC/TodasLasVariables/types';
import { IOpcionesMC } from '../../FormatosMC/types';

export interface ICatalogoMcpa {
  id: number;
  nombre: string;
  tipo: 'medipraxi' | 'propio';
}

export interface IMcpa {
  id: number;
  tipoConsulta: number;
  categoria: ICatalogosEstandar;
  formato: ICatalogoMcpa;
  rangoEdad: ICatalogosEstandar;
  sexo: ICatalogosEstandar;
  componentes: ICompUnico[];
  opciones: IOpcionesMC;
}

export const mcpaInitial: IMcpa = {
  id: 0,
  tipoConsulta: 0,
  categoria: { id: 0, label: '' },
  formato: { id: 0, nombre: '', tipo: 'medipraxi' },
  rangoEdad: { id: 0, label: '' },
  sexo: { id: 0, label: '' },
  componentes: [],
  opciones: {
    notasAdicionales: {
      incluido: false,
      comentarios: '',
    },
  },
};

export interface ICatalogoCompleto {
  ID: number;
  label: string;
  MOTIVO_CATEGORIA: string;
  MOTIVO_CONSULTA: string;
  MOTIVO_RANGO: string;
  MOTIVO_SEXO: string;
}

export interface IElementsConstructor {
  todasVariables: any[];
  notasAdicionales: any[];
  patologias: any[];
  variablesCreadas: any[];
}
