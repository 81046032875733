import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { generarPDFResumen } from '@utils/documents';

function HistorialPaciente(datos: any) {
  const { t } = useTranslation();
  const { datosTabla, selector } = datos;

  return (
    <div className="border border-solid border-gray-600">
      <div className="grid grid-cols-4 gap-4 bg-blue-600 text-white text-center py-2">
        <span className="col-span-2">{t('nombre_documento')}</span>
        <span className="col-span-1">{t('fecha')}</span>
        <span className="col-span-1">{t('descargar')}</span>
      </div>
      {datosTabla.map((objDocumento: any, index: any) => (
        <div
          className={`grid grid-cols-4 gap-4 ${
            index % 2 === 0 ? 'bg-blue-300' : ''
          } text-center items-center py-4`}
          key={objDocumento.descargar}
        >
          <div className="col-span-2">{objDocumento.nombreDocumento}</div>
          <div className="col-span-1">{dayjs(objDocumento.fecha).format('DD-MM-YYYY')}</div>
          <div className="col-span-1">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                if (selector !== 0) {
                  generarPDFResumen({
                    idConsulta: objDocumento.id,
                    tipoSolicitud: parseInt(selector, 10) + 1,
                  });
                }
              }}
            >
              <CloudDownload />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
}

export default HistorialPaciente;
