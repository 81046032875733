import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import { IDetalleArchivoProps } from './types';

function DetalleArchivo({
  idMedico,
  idLista,
  archivo,
  eliminarArchivo,
  verArchivo,
}: IDetalleArchivoProps) {
  const { t } = useTranslation();
  const {
    idArchivo, nombreArchivo, pathArchivo, idMedico: idMedicoSubio,
  } = archivo;
  return (
    <div
      className="underline transform hover:scale-105 inline-block mr-4"
      style={{ maxWidth: '8rem' }}
    >
      <div className="flex justify-between items-center">
        <HtmlTooltip title={nombreArchivo}>
          <Button
            fullWidth
            color="primary"
            onClick={() => verArchivo(idLista, idArchivo, nombreArchivo, pathArchivo)}
          >
            <p className="truncate m-0">{nombreArchivo}</p>
          </Button>
        </HtmlTooltip>
        <span className="text-red-500">
          {idMedico !== idMedicoSubio ? (
            <HtmlTooltip title={`${t('no_se_puede_eliminar_archivos_adjuntados_por_otro_medico')}`}>
              <span>
                <IconButton disabled aria-label="borrar" color="inherit" size="small">
                  <CloseIcon />
                </IconButton>
              </span>
            </HtmlTooltip>
          ) : (
            <IconButton
              aria-label="borrar"
              color="inherit"
              size="small"
              onClick={() => eliminarArchivo(idLista, idArchivo, nombreArchivo, pathArchivo)}
            >
              <CloseIcon />
            </IconButton>
          )}
        </span>
      </div>
    </div>
  );
}

export default DetalleArchivo;
