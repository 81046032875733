import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from '@components/DeleteDialog';
import { validacionSoloNumeros } from '@constants/validaciones';
import useStyles from '@common/styles';
import { edadCalculada, iEdadMenorOIgual } from '@common/functions';
import { RootState } from 'src/store';
import { ITipoDrogasProps } from './types';

function TipoDeDrogas(props: ITipoDrogasProps) {
  const {
    hayCambios, index, toxicomania, setToxicomania, handleDeleteRow,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { dia, mes, anio } = useSelector((state: RootState) => state.BasicosPaciente);
  const edadPaciente = edadCalculada(anio, mes, dia);
  const [errores, setErrores] = useState({
    edadInicio: { error: false, helperText: '' },
    edadTermino: { error: false, helperText: '' },
    general: { error: false, helperText: '' },
  });

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);

  const estatus = ['activo', 'abandonado'];
  const tipo = [
    'otra',
    'ayahuasca',
    'depresores_nerviosos',
    'cocaina',
    'dmt',
    'ghb',
    'alucinogenos',
    'heroina',
    'inhalantes',
    'ketamina',
    'khat',
    'kratom',
    'lsd',
    'marihuana',
    'mdma',
    'mescalina',
    'metanfetamienta',
    'venta_libre_dextrometorfano',
    'venta_libre_loperamida',
    'pcp',
    'opioides_con_receta',
    'estimulantes_con_receta',
    'psilocibina',
    'rohypnol',
    'salvia',
    'esteroides',
    'cannabinoides_sinteticos',
    'catinonas_sinteticas',
  ];
  const frecuencia = [
    'diario',
    '1_vez_por_semanas',
    '2_veces_por_semana',
    '3_veces_por_semana',
    '4_veces_por_semana',
    '5_veces_por_semana',
    '6_veces_por_semana',
    'mensual_o_menos',
  ];

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const handleChangeTipoDroga = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const newArrTipoDrogas = toxicomania.otrasDrogas.arrTipoDrogas;
    const arr = toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    newArrTipoDrogas.tipoDroga = arr;
    if (val !== 'otra') {
      const arr1 = [...toxicomania.otrasDrogas.arrTipoDrogas.especifica];
      arr1[index] = '';
      newArrTipoDrogas.especifica = arr1;
    }
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        arrTipoDrogas: newArrTipoDrogas,
      },
    });
  };

  const handleChangeEspecifica = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = toxicomania.otrasDrogas.arrTipoDrogas.especifica.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        arrTipoDrogas: {
          ...toxicomania.otrasDrogas.arrTipoDrogas,
          especifica: arr,
        },
      },
    });
  };

  const handleChangeCantidadDroga = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        arrTipoDrogas: {
          ...toxicomania.otrasDrogas.arrTipoDrogas,
          cantidadDroga: arr,
        },
      },
    });
  };

  const handleChangeEdadInicio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      const arr = [...toxicomania.otrasDrogas.arrTipoDrogas.edadInicio];
      arr[index] = val;
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            ...toxicomania.otrasDrogas.arrTipoDrogas,
            edadInicio: arr,
          },
        },
      });
      if (val !== '') {
        const age = parseInt(val, 10);
        if (!iEdadMenorOIgual({ edad: age, claveEdad: 5, periodo: 'anios' }, edadPaciente)) {
          setErrores((prev) => ({
            ...prev,
            edadInicio: {
              error: true,
              helperText:
                'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
            },
          }));
        } else {
          setErrores((prev) => ({
            ...prev,
            edadInicio: {
              error: false,
              helperText: '',
            },
          }));
        }
      } else {
        setErrores((prev) => ({
          ...prev,
          edadInicio: {
            error: false,
            helperText: '',
          },
        }));
      }
    } else {
      setErrores((prev) => ({
        ...prev,
        edadInicio: {
          error: false,
          helperText: '',
        },
      }));
    }
  };

  const handleChangeEdadTermino = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      const arr = [...toxicomania.otrasDrogas.arrTipoDrogas.edadTermino];
      arr[index] = val;
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            ...toxicomania.otrasDrogas.arrTipoDrogas,
            edadTermino: arr,
          },
        },
      });
      if (val !== '') {
        const age = parseInt(val, 10);
        if (!iEdadMenorOIgual({ edad: age, claveEdad: 5, periodo: 'anios' }, edadPaciente)) {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: true,
              helperText:
                'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
            },
          }));
        } else if (
          iEdadMenorOIgual(
            { edad: age, claveEdad: 5, periodo: 'anios' },
            {
              edad: parseInt(toxicomania.otrasDrogas.arrTipoDrogas.edadInicio[index], 10),
              claveEdad: 5,
              periodo: 'anios',
            },
          )
        ) {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: true,
              helperText: 'la_edad_de_termino_es_menor_a_la_edad_actual_de_inicio',
            },
          }));
        } else {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: false,
              helperText: '',
            },
          }));
        }
      } else {
        setErrores((prev) => ({
          ...prev,
          edadTermino: {
            error: false,
            helperText: '',
          },
        }));
      }
    } else {
      setErrores((prev) => ({
        ...prev,
        edadTermino: {
          error: false,
          helperText: '',
        },
      }));
    }
  };

  const handleChangeFrecuencia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = toxicomania.otrasDrogas.arrTipoDrogas.frecuencia.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        arrTipoDrogas: {
          ...toxicomania.otrasDrogas.arrTipoDrogas,
          frecuencia: arr,
        },
      },
    });
  };

  const handleChangeEstatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const newArrTipoDrogas = toxicomania.otrasDrogas.arrTipoDrogas;
    const arr = toxicomania.otrasDrogas.arrTipoDrogas.estatus.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    newArrTipoDrogas.estatus = arr;
    if (val === 'activo' && toxicomania.otrasDrogas.arrTipoDrogas.estatus[index] !== val) {
      const arrE = toxicomania.otrasDrogas.arrTipoDrogas.edadTermino.map((item, j) => {
        if (j === index) {
          return '';
        }
        return item;
      });
      setErrores((prev) => ({ ...prev, edadTermino: { error: false, helperText: '' } }));
      newArrTipoDrogas.edadTermino = arrE;
    }
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        arrTipoDrogas: newArrTipoDrogas,
      },
    });
  };

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={`${t('_esta_seguro_que_desea_eliminar_el_tipo_de_droga')}${
          toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index]
            ? ` ${t(toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index])}`
            : ''
        }${t('_?')}`}
        callBackAceptar={() => handleDeleteRow(index)}
        callBackClose={handleAlertClose}
      />

      <div
        className={`grid grid-cols-${
          toxicomania.otrasDrogas.arrTipoDrogas.estatus[index] === 'abandonado' ? '11' : '9'
        } px-4 pb-4 mt-4 border border-solid border-gray-300 rounded gap-x-4 gap-y-2`}
      >
        <div
          className={`col-span-${
            toxicomania.otrasDrogas.arrTipoDrogas.estatus[index] === 'abandonado' ? '11' : '9'
          }`}
        >
          <RadioGroup
            row
            name="consumo-drogas"
            value={toxicomania.otrasDrogas.arrTipoDrogas.estatus[index]}
            onChange={handleChangeEstatus}
          >
            {estatus.map((valor) => (
              <FormControlLabel
                value={valor}
                control={<Radio color="primary" />}
                label={t(`${valor}`)}
                labelPlacement="end"
                disabled={hayCambios}
              />
            ))}
          </RadioGroup>
        </div>
        <h3 className="col-span-2 mb-0 text-gray-600 font-medium">{t('tipo')}</h3>
        <h3 className="col-span-2 mb-0 text-gray-600 font-medium">{t('cantidad/presentacion')}</h3>
        <h3 className="col-span-2 mb-0 text-gray-600 font-medium">{t('edad_de_inicio')}</h3>
        {toxicomania.otrasDrogas.arrTipoDrogas.estatus[index] === 'abandonado' && (
          <h3 className="col-span-2 mb-0 text-gray-600 font-medium">{t('edad_de_termino')}</h3>
        )}
        <h3 className="col-span-2 mb-0 text-gray-600 font-medium">{t('frecuencia')}</h3>
        <div className="row-span-3 flex items-center justify-center">
          <IconButton color="primary" onClick={() => setDeleteAlert(true)} disabled={hayCambios}>
            <DeleteIcon />
          </IconButton>
        </div>
        <div className="col-span-2">
          <FormControl variant="outlined" fullWidth>
            <Select
              displayEmpty
              value={toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index]}
              onChange={handleChangeTipoDroga}
              disabled={hayCambios}
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">{t('selecciona')}</span>
              </MenuItem>
              {tipo.map((valor) => (
                <MenuItem value={valor}>{t(`${valor}`)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-span-2">
          <TextField
            variant="outlined"
            value={toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga[index]}
            onChange={handleChangeCantidadDroga}
            inputProps={{
              maxLength: 30,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
            fullWidth
            disabled={hayCambios}
          />
        </div>
        <div className="col-span-2">
          <TextField
            variant="outlined"
            value={toxicomania.otrasDrogas.arrTipoDrogas.edadInicio[index]}
            onChange={handleChangeEdadInicio}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '2',
              },
            }}
            error={errores.edadInicio.error}
            helperText={errores.edadInicio.error ? t(errores.edadInicio.helperText) : ''}
            FormHelperTextProps={{ className: classes.absoluteBottom2 }}
            fullWidth
            disabled={hayCambios}
          />
        </div>
        {toxicomania.otrasDrogas.arrTipoDrogas.estatus[index] === 'abandonado' && (
          <div className="col-span-2">
            <TextField
              variant="outlined"
              value={toxicomania.otrasDrogas.arrTipoDrogas.edadTermino[index]}
              onChange={handleChangeEdadTermino}
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
                inputProps: {
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: '2',
                },
              }}
              error={errores.edadTermino.error}
              helperText={errores.edadTermino.error ? t(errores.edadTermino.helperText) : ''}
              FormHelperTextProps={{ className: classes.absoluteBottom2 }}
              fullWidth
              disabled={hayCambios}
            />
          </div>
        )}
        <div className="col-span-2">
          <FormControl variant="outlined" fullWidth>
            <Select
              displayEmpty
              value={toxicomania.otrasDrogas.arrTipoDrogas.frecuencia[index]}
              onChange={handleChangeFrecuencia}
              disabled={hayCambios}
            >
              <MenuItem value="" disabled>
                <span className="text-gray-400">{t('selecciona')}</span>
              </MenuItem>
              {frecuencia.map((valor) => (
                <MenuItem value={valor}>{t(`${valor}`)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index] === 'otra' && (
          <div className="col-span-2">
            <TextField
              variant="outlined"
              value={toxicomania.otrasDrogas.arrTipoDrogas.especifica[index]}
              onChange={handleChangeEspecifica}
              inputProps={{
                maxLength: 30,
                autoComplete: 'off',
                'aria-autocomplete': 'none',
              }}
              fullWidth
              label={t('especifica')}
              disabled={hayCambios}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default TipoDeDrogas;
