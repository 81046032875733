import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setRutaAntecedentes } from '@actions/rutasExpediente/actions';
import { setDiagnosticosGeneralesUsuario } from '@actions/paciente/actions';
import { RootState } from 'src/store';
import { enfermedadesGuardarDiagnosticosUsuario } from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import BotonesGuardar from '@components/BotonesGuardar';
import { IDiagnosticoGeneral, IDiagnosticosGenerales, diagnosticosGeneralesInitial } from './types';
import TablaDiagnosticos from './TablaDiagnosticos';

function DiagnosticosGenerales() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idUsuario, sexo } = useSelector((state: RootState) => state.Me);
  const { diagnosticosGenerales } = useSelector(
    (state: RootState) => state.Paciente.usuario.antPatologicos,
  );

  const [diagnosticosGeneralesTemp, setDiagnosticosGeneralesTemp] =
    useState<IDiagnosticosGenerales>(diagnosticosGeneralesInitial);

  const handleSubmitForm = () => {
    const contenidoTabla = diagnosticosGeneralesTemp.contenidoTabla.map(
      (diagnostico: IDiagnosticoGeneral, index: number) => {
        if (
          diagnostico.posee !== diagnosticosGenerales.contenidoTabla[index].posee ||
          diagnostico.medicamento !== diagnosticosGenerales.contenidoTabla[index].medicamento ||
          diagnostico.noMedicamento !== diagnosticosGenerales.contenidoTabla[index].noMedicamento
        ) {
          return {
            ...diagnostico,
            validado: false,
          };
        }
        return diagnostico;
      },
    );
    const newDiagnosticos = { ...diagnosticosGeneralesTemp, contenidoTabla };
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarDiagnosticosUsuario({
          idUsuario,
          contenidoTabla,
        }),
        successFunction: () => {
          setDiagnosticosGeneralesTemp(newDiagnosticos);
          dispatch(setDiagnosticosGeneralesUsuario(newDiagnosticos));
          dispatch(setRutaAntecedentes('personales-no-patologicos'));
        },
      }),
    );
  };

  useEffect(() => {
    if (diagnosticosGenerales.loaded && !diagnosticosGeneralesTemp.loaded) {
      setDiagnosticosGeneralesTemp(diagnosticosGenerales);
    }
  }, [diagnosticosGenerales]);

  return (
    <>
      <div className="p-4 bg-white container mx-auto shadow border-solid border-0 border-t border-gray-600">
        <div className="relative border-solid border-0 border-b border-gray-500 pb-4">
          <div className="text-center">
            <h1 className="text-blue-800 font-normal m-2">{t('antecedentes-pp-diagnosticos-g')}</h1>
          </div>
          <div className="text-gray-500 mt-4">{t('antecedentes-pp-diagnosticos-g-responde-1')}</div>
          <div className="text-gray-500 mb-2">{t('antecedentes-pp-diagnosticos-g-responde-2')}</div>
          <div className="text-gray-500 my-2 font-bold">
            {t('antecedentes-pp-diagnosticos-g-presenta')}
          </div>
          <TablaDiagnosticos
            diagnosticos={diagnosticosGeneralesTemp}
            setDiagnosticos={setDiagnosticosGeneralesTemp}
            sexo={sexo}
          />
        </div>
        <BotonesGuardar
          hayCambios={false}
          aceptarCallback={() => {}}
          cancelarCallback={() => {}}
          guardarCallback={handleSubmitForm}
          continuar
        />
      </div>
    </>
  );
}

export default DiagnosticosGenerales;
