import {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputProps } from './type';

export const TextInput: FC<TextInputProps> = ({
  name,
  containerClass = '',
  placeholder: externalPlaceholder,
  isRequired = false,
  label: externalLabel,
  labelAligmet = 'top',
  adornment,
  fixedValue,
  value,
  textCase,
  validacion,
  validacionBlur,
  helperText,
  disableOption,
  setValue,
  onClick,
  action,
  readOnly,
  maxLength,
  disabled = false,
  modal,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const label = externalLabel === undefined ? t(name) : externalLabel;
  const placeholder = externalPlaceholder === undefined ? label : '';

  const isFloating = isFocused || value?.length;

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const handleInputClick = () => {
    if (modal && onClick) onClick(value || '');
    action?.();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;
    let { name: newName, value: newValue } = e.target;
    if (textCase) {
      switch (textCase) {
        case 'upper':
          newValue = newValue.toUpperCase();
          break;
        case 'lower':
          newValue = newValue.toLowerCase();
          break;
        case 'title':
          newValue = newValue[0].toUpperCase() + newValue.slice(1).toLowerCase();
          break;
        default:
          break;
      }
    }
    setValue?.({
      name: newName, value: newValue,
    });
    if (validacion) {
      setErrorMessage(validacion(newValue));
    }
    onClick?.(newValue);
  };

  const handleBlur = () => {
    setIsFocused(false);

    // Validación básica cuando pierde el focus
    if (isRequired && value?.trim() === '') {
      setErrorMessage(t('error_campo_en_blanco'));
    } else if (validacionBlur) {
      setErrorMessage(validacionBlur(value?.trim() || ''));
    }
  };

  const toggleDisabled = () => {
    const newState = !disableOption?.value;
    setIsDisabled(newState);
    setErrorMessage('');
    if (disableOption?.name) {
      setValue?.({ name: disableOption.name, value: newState });
    }
    if (!newState) {
      setValue?.({ name, value: '' });
    }
  };

  const renderCheckbox = () => (
    <label className={`flex items-center text-sm ${
      disabled ? 'text-gray-300' : 'text-gray-600'
    } ml-4`}>
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={!!disableOption?.value}
        onChange={toggleDisabled}
        disabled={disabled}
      />
      {disableOption?.label}
    </label>
  );

  const getStyle = () => {
    if (isDisabled) {
      return 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed';
    }
    if (readOnly) {
      return 'bg-gray-50 cursor-pointer hover:bg-gray-100 border-gray-300';
    }
    if (errorMessage) {
      return 'border-red-500 ring-2 ring-red-200';
    }
    return 'border-gray-300 focus:ring-2 focus:ring-blue-500';
  };

  const getAdornment = (txt: string) => (
    <div className="content-center">
      <label
        htmlFor={name}
        className="text-base bg-white text-gray-500"
      >
        {txt}
      </label>
    </div>
  );

  return (
    <div className={`w-full h-auto mb-4 ${containerClass}`}>
      <div className="px-4">
        {labelAligmet === 'top' && label && (
          <div className="flex items-center justify-between mb-2">
            <label
              htmlFor={name}
              className={`block font-medium text-base ${errorMessage ? 'text-red-500' : 'text-gray-600'} ${
                isDisabled && 'text-gray-500'
              }`}
            >
              {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            {disableOption?.position === 'right' && renderCheckbox()}
          </div>
        )}

        <div className="flex items-center gap-4">
          <div className="flex-1 relative">
            <div className="flex flex-row relative">
              {adornment && adornment.position === 'start' && getAdornment(adornment.text)}
              <input
                type="text"
                id={name}
                name={name}
                placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
                value={value}
                onChange={handleChange}
                onClick={handleInputClick}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                disabled={isDisabled}
                readOnly={readOnly}
                maxLength={maxLength}
                className={`w-full h-[48px] text-base px-3 ${
                  fixedValue ? 'pr-16' : 'pr-3'
                } rounded-md border transition focus:outline-none ${getStyle()}`}
                autoComplete='off'
              />
              {adornment && adornment.position === 'end' && getAdornment(adornment.text)}
            </div>
            {labelAligmet === 'border' && label && (
              <label
                htmlFor={name}
                className={`absolute text-sm transition-all duration-200 ${
                  isFloating
                    ? `-top-2.5 left-2 bg-white px-1 ${
                      errorMessage ? 'text-red-500' : 'text-blue-500'
                    }`
                    : 'top-[50%] -translate-y-[50%] left-3 text-gray-500'
                } ${isDisabled && 'text-gray-400'}`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            )}
            {fixedValue && (
              <span
                className={`absolute right-3 top-1/2 -translate-y-1/2 text-sm ${
                  isDisabled ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                {fixedValue}
              </span>
            )}
          </div>
          {disableOption?.position === 'after' && renderCheckbox()}
        </div>

        {(errorMessage || helperText) && (
          <p className={`mt-1 text-xs ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
            {errorMessage || helperText}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextInput;
