import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ReactComponent as Barra } from '@assets/img/barra.svg';

function FooterCertificado() {
  const {
    nombre: nombreMedico,
    primerApellido,
    segundoApellido,
    carrera,
    sexo,
    rol,
  } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation();
  const getTitulo = () => {
    let titulo = '';
    if (rol.tipo === 'medico') {
      titulo = sexo === 'M' ? t('dra_') : t('dr_');
    } else if (rol.tipo === 'enfermero') {
      titulo = t('enf_');
    }
    return titulo;
  };

  return (
    <div className="absolute bottom-0 w-full">
      <div className="text-center mb-5">
        <div className="border-0 border-solid border-gray-800 border-b w-80 inline-block" />
        <h3 className="text-gray-600 m-0 capitalize font-bold">
          <p className="m-0">{`${getTitulo()} ${nombreMedico} ${primerApellido} ${segundoApellido}`}</p>
          <p className="m-0">{`${t('cedula_profesional_no_')}: ${carrera.cedula}`}</p>
        </h3>
      </div>
      <div
        style={{
          backgroundColor: '#1E3A8A',
          height: '3px',
        }}
      />
      <Barra className="mt-5" />
    </div>
  );
}

export default FooterCertificado;
