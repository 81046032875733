/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RadioGroup, FormControlLabel, Radio, TextField, Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RootState } from 'src/store';
import { ICatalogosCie10, IFechaField } from '@common/types';
import { getCatCie10SexoQuery } from '@utils/getCatalogos';
import { validacionSoloNumeros } from '@constants/validaciones';
import { verificarIEdadValida } from '@common/functions';
import { IFormAFReferirProps } from './types';
import { IFamiliarEnfermedades } from '../types';

function FormAntecedentesFamiliaresReferir({
  listaEnfermedad,
  setListaEnfermedad,
  vive,
  sexoFamiliar,
}: IFormAFReferirProps) {
  const { t } = useTranslation();

  const {
    diagnostico, diagnosticoCie10, diagnosticoMedico, edadAlDiagnostico, esCausaDeMuerte,
  } =
    listaEnfermedad;

  const [catCIE10, setCatCIE10] = useState<ICatalogosCie10[]>([]);
  const [openCIE10, setOpenCIE10] = useState<boolean>(false);
  const [loadingCIE10, setLoadingCIE10] = useState<boolean>(false);
  const [inputCIE10, setInputCIE10] = useState<string>('');

  const [edadField, setEdadField] = useState<IFechaField>({ error: false, helperText: '' });

  const handleChangeDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    if (val === 'cie10') {
      setListaEnfermedad((lep: IFamiliarEnfermedades) => ({
        ...lep,
        diagnostico: val,
        diagnosticoMedico: '',
      }));
    } else {
      setListaEnfermedad((lep: IFamiliarEnfermedades) => ({
        ...lep,
        diagnostico: val,
        diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
      }));
    }
  };

  const handleChangeEdad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    const num = parseInt(val, 10) || 0;
    if (val === '' || (validacionSoloNumeros(val) && num < 121)) {
      setListaEnfermedad((leP: IFamiliarEnfermedades) => ({
        ...leP,
        edadAlDiagnostico: { ...leP.edadAlDiagnostico, edad: num },
      }));
      if (edadAlDiagnostico.claveEdad && num > 0) {
        if (!verificarIEdadValida({ ...listaEnfermedad.edadAlDiagnostico, edad: num })) {
          setEdadField({
            error: true,
            helperText: 'formato_i_edad_invalido',
          });
          return;
        }
      }
    }
    if (num === 0) {
      setEdadField({
        error: true,
        helperText: 'debes_introducir_un_valor_y_seleccionar_una_opcion',
      });
    } else {
      setEdadField({
        error: false,
        helperText: '',
      });
    }
  };

  const handleChangePeriodoEdad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as '' | 'anios' | 'meses' | 'dias';
    let claveEdad = 0;
    switch (val) {
      case 'anios':
        claveEdad = 5;
        break;
      case 'meses':
        claveEdad = 4;
        break;
      case 'dias':
        claveEdad = 3;
        break;
      default:
        claveEdad = 0;
        break;
    }
    setListaEnfermedad((leP: IFamiliarEnfermedades) => ({
      ...leP,
      edadAlDiagnostico: { ...leP.edadAlDiagnostico, periodo: val, claveEdad },
    }));
    if (edadAlDiagnostico.edad) {
      if (!verificarIEdadValida({ ...edadAlDiagnostico, periodo: val, claveEdad })) {
        setListaEnfermedad((leP: IFamiliarEnfermedades) => ({
          ...leP,
          edadAlDiagnostico: { ...leP.edadAlDiagnostico, periodo: '', claveEdad: 0 },
        }));
        setEdadField({
          error: true,
          helperText: 'formato_i_edad_invalido',
        });
        return;
      }
    }
    if (edadAlDiagnostico.edad && val) {
      setEdadField({
        error: false,
        helperText: '',
      });
      return;
    }
    setEdadField({
      error: true,
      helperText: 'debes_introducir_un_valor_y_seleccionar_una_opcion',
    });
  };

  const handleBlurEdad = () => {
    if (edadAlDiagnostico.edad && edadAlDiagnostico.periodo === '') {
      setEdadField({
        error: true,
        helperText: 'debes_introducir_un_valor_y_seleccionar_una_opcion',
      });
    } else {
      setEdadField({
        error: false,
        helperText: '',
      });
    }
  };

  const handleChangeEsCausaDeMuerte = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).checked;
    setListaEnfermedad((leP: IFamiliarEnfermedades) => ({ ...leP, esCausaDeMuerte: val }));
  };

  const handleChangeDiagnosticoMedico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setListaEnfermedad((leP: IFamiliarEnfermedades) => ({ ...leP, diagnosticoMedico: val }));
  };

  const handleChangeCIE = (event: any, newValue: ICatalogosCie10 | null, reason: string) => {
    setListaEnfermedad({
      ...listaEnfermedad,
      diagnosticoCie10:
        reason !== 'clear' && newValue ? newValue : { id: '', label: '', grupoEnfermedades: 0 },
    });
    setOpenCIE10(reason === 'clear');
  };

  useEffect(() => {
    if (!openCIE10) {
      setCatCIE10([]);
    }
  }, [openCIE10]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (inputCIE10.length > 2) {
      let sexo = '';
      if (sexoFamiliar === 'H') {
        sexo = 'HOMBRE';
      } else if (sexoFamiliar === 'M') {
        sexo = 'MUJER';
      }
      setLoadingCIE10(true);
      getCatCie10SexoQuery(inputCIE10, sexo, signal)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatCIE10(result.datos);
          } else {
            setCatCIE10([]);
          }
          setLoadingCIE10(false);
        })
        .catch(() => {
          setCatCIE10([]);
          setLoadingCIE10(false);
        });
    } else {
      setCatCIE10([]);
      setLoadingCIE10(false);
    }
    return () => abortController.abort();
  }, [inputCIE10]);

  useEffect(() => {
    if (edadAlDiagnostico.edad === 0 && edadAlDiagnostico.periodo === '') {
      setEdadField({
        error: false,
        helperText: '',
      });
    }
  }, [edadAlDiagnostico]);
  return (
    <div className="border border-solid border-gray-300 rounded p-4">
      <div className="grid grid-cols-4 gap-4 mb-4">
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-4">{`${t('diagnostico')}*`}</h3>
          <RadioGroup
            aria-label="diagnostico"
            name="diagnostico"
            row
            value={diagnostico}
            onChange={handleChangeDiagnostico}
          >
            <FormControlLabel
              value="cie10"
              control={<Radio color="primary" />}
              label={t('CIE-10')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="medico"
              control={<Radio color="primary" />}
              label={t('medico')}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div className="col-span-2">
          <h3 className="m-0 mr-4 text-gray-500 font-normal mb-2">
            {t('edad_al_momento_del_diagnostico')}
          </h3>
          <div className="flex">
            <div className="mr-4 my-2 w-16">
              <TextField
                variant="outlined"
                InputProps={{
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '3',
                  },
                }}
                value={edadAlDiagnostico.edad ? edadAlDiagnostico.edad.toString() : ''}
                onChange={handleChangeEdad}
                onBlur={handleBlurEdad}
                fullWidth
              />
            </div>
            <RadioGroup
              row
              name="periodo_edad"
              value={edadAlDiagnostico.periodo}
              onChange={handleChangePeriodoEdad}
            >
              <FormControlLabel
                value="anios"
                control={<Radio color="primary" />}
                label={t('anio(s)')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="meses"
                control={<Radio color="primary" />}
                label={t('mes(es)')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="dias"
                control={<Radio color="primary" />}
                label={t('dias')}
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
          {edadField.error && <p className="m-0 text-red-500">{t(edadField.helperText)}</p>}
        </div>
        {!vive && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={esCausaDeMuerte}
                onChange={handleChangeEsCausaDeMuerte}
                name="checkedB"
                color="primary"
              />
            )}
            label={t('causa_de_muerte')}
          />
        )}
      </div>
      {diagnostico === 'cie10' ? (
        <Autocomplete
          open={openCIE10}
          onOpen={() => {
            setOpenCIE10(true);
          }}
          onClose={() => {
            setOpenCIE10(false);
          }}
          getOptionLabel={(option) => option.label}
          options={catCIE10}
          loading={loadingCIE10}
          loadingText="Buscando..."
          noOptionsText={
            inputCIE10.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
          }
          value={diagnosticoCie10}
          onChange={(e, nV, r) => handleChangeCIE(e, nV, r)}
          onInputChange={(e, newInputValue) => {
            if (newInputValue.length < 3) {
              setCatCIE10([]);
            }
            setInputCIE10(newInputValue);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('selecciona')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingCIE10 ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      ) : (
        <TextField
          variant="outlined"
          fullWidth
          value={diagnosticoMedico}
          onChange={handleChangeDiagnosticoMedico}
        />
      )}
      <p className="text-gray-400">
        * &nbsp;
        {t('campos_obligatorios')}
      </p>
    </div>
  );
}

export default FormAntecedentesFamiliaresReferir;
