import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl, FormControlLabel, Radio, TextField, RadioGroup,
} from '@material-ui/core';
import { setRutaAntNoPatologicos } from '@actions/rutasExpediente/actions';
import { setRequest } from '@actions/request/types';
import { RootState } from 'src/store';
import ResumenCambios from '@components/ResumenCambios';
import BotonesGuardar from '@components/BotonesGuardar';
import { setHigiene, setHigieneUsuario } from '@actions/paciente/actions';
import {
  antecedentesNoPatologicosGuardarHigiene,
  antecedentesNoPatologicosUsuarioPacienteGuardarHigiene,
  antecedentesNoPatologicosUsuarioGuardarHigiene,
} from '@utils/sendInfo';
import { IHigiene, higieneInitial } from './types';

function Higiene() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    expediente: {
      antNoPatologicos: { higiene },
    },
    usuario: {
      antNoPatologicos: { higiene: higieneUsuario },
    },
    cambios: {
      antNoPatologicos: { higiene: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const [higieneTemp, setHigieneTemp] = useState<IHigiene>(higieneInitial);

  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  const handleChangeBanio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    if (val === 'OTRO') {
      setHigieneTemp({
        ...higieneTemp,
        banio: val,
      });
    } else {
      setHigieneTemp({
        ...higieneTemp,
        banio: val,
        banioComentario: '',
      });
    }
  };
  const handleChangeBanioComentario = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHigieneTemp({
      ...higieneTemp,
      banioComentario: (event.target as HTMLTextAreaElement).value,
    });
  };
  const handleChangeBucal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHigieneTemp({
      ...higieneTemp,
      bucal: (event.target as HTMLInputElement).value,
    });
  };
  const handleChangeRopa = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    if (val === 'OTRO') {
      setHigieneTemp({
        ...higieneTemp,
        ropa: val,
      });
    } else {
      setHigieneTemp({
        ...higieneTemp,
        ropa: val,
        ropaComentario: '',
      });
    }
  };
  const handleChangeRopaComentario = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHigieneTemp({
      ...higieneTemp,
      ropaComentario: (event.target as HTMLTextAreaElement).value,
    });
  };

  const getDatosHigiene = (datos: IHigiene) => ({
    banio: datos.banio,
    banioComentario: datos.banioComentario,
    bucal: datos.bucal,
    ropa: datos.ropa,
    ropaComentario: datos.ropaComentario,
    comentarios: datos.comentarios,
  });
  const getDatosExpediente = (datos: IHigiene) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    higiene: getDatosHigiene(datos),
  });
  const getDatosUsuario = (datos: IHigiene) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    higiene: getDatosHigiene(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(higieneTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarHigiene(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarHigiene(datosToSend));
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions: sendFunctions,
        successFunctions: [
          () => dispatch(setHigiene({ ...higieneTemp, loaded: true })),
          () => dispatch(setHigieneUsuario({ ...higieneTemp, loaded: true })),
        ],
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioGuardarHigiene(
          getDatosUsuario(higieneTemp),
        ),
        successFunction: () => {
          dispatch(setHigiene({ ...higieneTemp, loaded: true }));
          dispatch(setRutaAntNoPatologicos('alimentacion'));
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosGuardarHigiene(
          getDatosExpediente(higieneUsuario),
        ),
        successFunction: () => {
          dispatch(setHigiene(higieneUsuario));
          setHigieneTemp(higieneUsuario);
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarHigiene(
          getDatosExpediente(higiene),
        ),
        successFunction: () => dispatch(setHigieneUsuario(higiene)),
      }),
    );
  };

  useEffect(() => {
    if (higiene.loaded && !higieneTemp.loaded) {
      setHigieneTemp(higiene);
    }
  }, [higiene]);

  const banio = [
    ['D', t('antecedentes-pnp-higiene-diario')],
    ['2D', t('antecedentes-pnp-higiene-dos-dias')],
    ['3D', t('antecedentes-pnp-higiene-tres-dias')],
    ['OTRO', t('antecedentes-pnp-higiene-otro')],
  ];
  const bucal = [
    ['1', t('antecedentes-pnp-higiene-una')],
    ['2', t('antecedentes-pnp-higiene-dos')],
    ['3', t('antecedentes-pnp-higiene-tres')],
    ['MENOS', t('antecedentes-pnp-higiene-menos')],
  ];
  const ropa = [
    ['D', t('antecedentes-pnp-higiene-diario')],
    ['2D', t('antecedentes-pnp-higiene-dos-dias')],
    ['3D', t('antecedentes-pnp-higiene-tres-dias')],
    ['OTRO', t('antecedentes-pnp-higiene-otro')],
  ];

  return (
    <div className="relative py-4">
      <div className="text-left">
        <h2 className="text-blue-800 font-normal m-0">{t('antecedentes-pnp-higiene')}</h2>
      </div>
      {hayCambios && <ResumenCambios cambios={cambios} />}
      <div className="grid grid-cols-3 gap-8 pt-6 pb-4">
        <div className="border-solid border-0 border-r border-gray-600 pr-8">
          <h3 className="text-gray-800 font-medium m-0">{t('antecedentes-pnp-higiene-banio')}</h3>
          <FormControl>
            <RadioGroup
              aria-label="banio"
              name="banio"
              value={higieneTemp.banio}
              onChange={handleChangeBanio}
            >
              {banio.map((item: string[]) => (
                <FormControlLabel
                  value={item[0]}
                  control={<Radio color="primary" />}
                  label={item[1]}
                  disabled={hayCambios}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {higieneTemp.banio === 'OTRO' ? (
            <TextField
              id="banioComentario"
              label={t('comentarios')}
              variant="outlined"
              fullWidth
              value={higieneTemp.banioComentario}
              onChange={handleChangeBanioComentario}
              inputProps={{ maxLength: 30, autoComplete: 'off' }}
              multiline
              disabled={hayCambios}
            />
          ) : null}
        </div>
        <div className="border-solid border-0 border-r border-gray-600 pr-8">
          <h3 className="text-gray-800 font-medium m-0">{t('antecedentes-pnp-higiene-bucal')}</h3>
          <FormControl>
            <RadioGroup
              aria-label="bucal"
              name="bucal"
              value={higieneTemp.bucal}
              onChange={handleChangeBucal}
            >
              {bucal.map((item: string[]) => (
                <FormControlLabel
                  value={item[0]}
                  control={<Radio color="primary" />}
                  label={item[1]}
                  disabled={hayCambios}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="pr-8">
          <h3 className="text-gray-800 font-medium m-0">{t('antecedentes-pnp-higiene-ropa')}</h3>
          <FormControl>
            <RadioGroup
              aria-label="ropa"
              name="ropa"
              value={higieneTemp.ropa}
              onChange={handleChangeRopa}
            >
              {ropa.map((item: string[]) => (
                <FormControlLabel
                  value={item[0]}
                  control={<Radio color="primary" />}
                  label={item[1]}
                  disabled={hayCambios}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {higieneTemp.ropa === 'OTRO' ? (
            <TextField
              id="ropaComentario"
              label={t('comentarios')}
              variant="outlined"
              fullWidth
              value={higieneTemp.ropaComentario}
              onChange={handleChangeRopaComentario}
              inputProps={{ maxLength: 30, autoComplete: 'off' }}
              multiline
              disabled={hayCambios}
            />
          ) : null}
        </div>
      </div>
      <h3 className="text-gray-800 font-medium m-0 mb-2">{t('comentarios')}</h3>
      <TextField
        variant="outlined"
        fullWidth
        value={higieneTemp.comentarios}
        onChange={(event) =>
          setHigieneTemp({
            ...higieneTemp,
            comentarios: event.target.value,
          })}
        inputProps={{ maxLength: 500, autoComplete: 'off' }}
        multiline
        disabled={hayCambios}
        rows="4"
      />
      <BotonesGuardar
        hayCambios={hayCambios}
        aceptarCallback={guardarDatosDeUsuarioAExpediente}
        cancelarCallback={guardarDatosDeExpedienteAUsuario}
        guardarCallback={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        continuar={esPaciente}
      />
    </div>
  );
}

export default Higiene;
