import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select, TextField, FormControl, InputLabel, MenuItem,
} from '@material-ui/core';
import { capitalize } from '@common/functions';
import { constAnioBuilder, constDia, constMes } from '@constants/fechas';
import { IMcpa } from '../../../TabsPaciente/MCPA/types';
import { IBuilderFechaProps } from './types';

function BuilderFecha({ componenteFecha, setMcpa, id }: IBuilderFechaProps) {
  const { t } = useTranslation();

  const handleChangeDia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setMcpa((preMcpa: IMcpa) => ({
      ...preMcpa,
      componentes: preMcpa.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                fecha: { ...preComp.constructorComponente.constructor.fecha!, dia: val },
              },
              valores: {
                ...preComp.constructorComponente.valores,
                valor: `${componenteFecha.constructor.fecha!.anio}-${
                  componenteFecha.constructor.fecha!.mes.length < 2
                    ? `0${componenteFecha.constructor.fecha!.mes}-`
                    : componenteFecha.constructor.fecha!.mes
                }${val.length < 2 ? `0${val}` : val}`,
              },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setMcpa((preMcpa: IMcpa) => ({
      ...preMcpa,
      componentes: preMcpa.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                fecha: { ...preComp.constructorComponente.constructor.fecha!, mes: val },
              },
              valores: {
                ...preComp.constructorComponente.valores,
                valor: `${componenteFecha.constructor.fecha!.anio}-${
                  val.length < 2 ? `0${val}` : val
                }-${
                  componenteFecha.constructor.fecha!.dia.length < 2
                    ? `0${componenteFecha.constructor.fecha!.dia}`
                    : componenteFecha.constructor.fecha!.dia
                }`,
              },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeAnio = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setMcpa((preMcpa: IMcpa) => ({
      ...preMcpa,
      componentes: preMcpa.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: {
                ...preComp.constructorComponente.constructor,
                fecha: { ...preComp.constructorComponente.constructor.fecha!, anio: val },
              },
              valores: {
                ...preComp.constructorComponente.valores,
                valor: `${val}-${
                  componenteFecha.constructor.fecha!.mes.length < 2
                    ? `0${componenteFecha.constructor.fecha!.mes}`
                    : componenteFecha.constructor.fecha!.mes
                }-${
                  componenteFecha.constructor.fecha!.dia.length < 2
                    ? `0${componenteFecha.constructor.fecha!.dia}`
                    : componenteFecha.constructor.fecha!.dia
                }`,
              },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMcpa((preMcpa: IMcpa) => ({
      ...preMcpa,
      componentes: preMcpa.componentes.map((preComp) => {
        if (preComp.id === id) {
          return {
            ...preComp,
            constructorComponente: {
              ...preComp.constructorComponente,
              constructor: { ...preComp.constructorComponente.constructor, comentarios: val },
              valores: { ...preComp.constructorComponente.valores, textoLibre: val },
            },
          };
        }
        return preComp;
      }),
    }));
  };

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="flex mb-2 items-end">
            <h3 className="pr-4 m-0 text-gray-600 font-medium">
              {capitalize(componenteFecha.datosComponente.titulo)}
            </h3>
            <div className="text-gray-500">
              {componenteFecha.datosComponente.descripcion
                ? `* ${componenteFecha.datosComponente.descripcion}`
                : ''}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2 mb-3">
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={componenteFecha.constructor.fecha!.dia}
                label={t('dia')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
                onChange={handleChangeDia}
              >
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle.toString()} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth className="col-span-2">
              <InputLabel>{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={componenteFecha.constructor.fecha!.mes}
                label={t('mes')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
                onChange={handleChangeMes}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {capitalize(mesSingle)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={componenteFecha.constructor.fecha!.anio}
                label={t('anio')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
                onChange={handleChangeAnio}
              >
                {constAnioBuilder().map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <TextField
          variant="outlined"
          rows={4}
          multiline
          fullWidth
          label={t('comentarios')}
          inputProps={{ autoComplete: 'off', maxLength: 300 }}
          value={componenteFecha.constructor.comentarios}
          onChange={handleChangeComentarios}
        />
      </div>
    </div>
  );
}

export default React.memo(BuilderFecha);
