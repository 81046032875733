/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Button,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ICatalogosEstandar } from '@common/types';
import {
  getCatFarmacologicoFrecuencia,
  getCatFarmacologicoGenerico,
  getCatFarmacologicoPatente,
  getCatFarmacologicoPeriodo,
  getCatFarmacologicoUnidad,
  getCatFarmacologicoVia,
  getFarmacologicoGenerico,
  getFarmacologicoUnidadFormaGenerico,
  getFarmacologicoViasGenerico,
  getFarmacologicoViasPatente,
} from '@utils/getCatalogos';
import {
  validacionDiezNumerosCuatroDecimales,
  validacionIngresoSoloUnPunto,
  validacionSoloNumeros,
} from '@constants/validaciones';
import { setLoading } from '@actions/loading/actions';
import {
  currentSmallDatatimeFormat,
  currentSmallDateTimePlusDurationAndPeriod,
} from '@common/functions';
import { farmacologicoGuardarMedicamento } from '@utils/sendInfo';
import AlertDialog from '@components/AlertDialogs/AlertDialog';
import { RootState } from 'src/store';
import { setSnackComplete } from '@actions/snackbar/types';
import ConfirmDialog from '@components/ConfirmDialog';
import { editTerapiaFarmacologicaMedicamento } from '@utils/editInfo';
import { setRequest } from '@actions/request/types';
import { setRecetas, setTratamientos } from '@actions/paciente/actions';
import { ICatalogosPatente, ICatalogosVia, IFormularioRecetaProps } from './types';

function FormularioReceta({
  tratamientosActualesConsulta,
  indiceReceta,
  listaEnfermedadesActivas,
  modoEditar,
  medicamentoAEditar,
  toggleEditar,
  formularioRef,
  setModoEditar,
}: IFormularioRecetaProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { tratamientos, recetas } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const abortController = new AbortController();
  const { signal } = abortController;
  const [idMedicamento, setIdMedicamento] = useState<number>(-1);
  const [radioMedicamento, setRadioMedicamento] = useState<
  'nombre_generico' | 'denominacion_distintiva' | 'texto_libre'
  >('nombre_generico');
  const [cantidad, setCantidad] = useState<string>('');
  const [unidad, setUnidad] = useState<number>(0);
  const [frecuencia, setFrecuencia] = useState<number>(0);
  const [otraFrecuencia, setOtraFrecuencia] = useState<string>('');
  const [via, setVia] = useState<number>(0);
  const [duracion, setDuracion] = useState<string>('');
  const [periodo, setPeriodo] = useState<number>(0);
  const [presentacion, setPresentacion] = useState<string>('');
  const [instruccionesAdicionales, setInstruccionesAdicionales] = useState<string>('');

  const [diagnosticoAsociado, setDiagnosticoAsociado] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const [catUnidad, setCatUnidad] = useState<ICatalogosEstandar[]>([]);
  const [catFrecuencia, setCatFrecuencia] = useState<ICatalogosEstandar[]>([]);
  const [catPeriodo, setCatPeriodo] = useState<ICatalogosEstandar[]>([]);
  const [catVia, setCatVia] = useState<ICatalogosVia[]>([]);
  const [catGenerico, setCatGenerico] = useState<ICatalogosEstandar[]>([]);
  const [catPatente, setCatPatente] = useState<ICatalogosPatente[]>([]);

  const [inputGenerico, setInputGenerico] = useState<string>('');
  const [inputPatente, setInputPatente] = useState<string>('');
  const [openGenerico, setOpenGenerico] = useState<boolean>(false);
  const [openPatente, setOpenPatente] = useState<boolean>(false);

  const [loadingPatente, setLoadingPatente] = useState<boolean>(false);
  const [loadingGenerico, setLoadingGenerico] = useState<boolean>(false);

  const [medGenerico, setMedGenerico] = useState<ICatalogosEstandar>({
    id: 0,
    label: '',
  });
  const [medPatente, setMedPatente] = useState<ICatalogosPatente>({
    id: 0,
    generico: 0,
    label: '',
  });
  const [medTextoLibre, setMedTextoLibre] = useState<string>('');

  const clearSelects = () => {
    if (radioMedicamento !== 'texto_libre') {
      setCatVia([]);
      setCatUnidad([]);
    }
    setCantidad('');
    setUnidad(0);
    setFrecuencia(0);
    setVia(0);
    setDuracion('');
    setPeriodo(0);
    setInstruccionesAdicionales('');
  };

  const borrarMedicamentos = () => {
    clearSelects();
    setMedGenerico({ id: 0, label: '' });
    setMedPatente({ id: 0, generico: 0, label: '' });
    setMedTextoLibre('');
    setPresentacion('');
  };

  const handleChangeGenerico = (
    event: any,
    newValue: ICatalogosEstandar | null,
    reason: string,
  ) => {
    if (reason === 'clear') {
      setOpenGenerico(true);
      clearSelects();
      setMedGenerico({ id: 0, label: '' });
    }
    if (newValue) {
      setVia(0);
      setMedGenerico(newValue);
      dispatch(setLoading(true));
      const viasFetch = getFarmacologicoViasGenerico(idMedico, newValue.id, signal);
      const unidadFormaFetch = getFarmacologicoUnidadFormaGenerico(idMedico, newValue.id, signal);
      // const indicacionesFetch = getFarmacologicoIndicationGenerico(newValue.id, signal);
      Promise.all([viasFetch, unidadFormaFetch])
        .then((responses) => {
          Promise.all([responses[0].json(), responses[1].json()])
            .then((results) => {
              if (results[0].code === 200) {
                setCatVia(results[0].datos);
              }
              if (results[1].code === 200) {
                setCatUnidad(results[1].datos);
              }
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-get-error')} ${err.toString()}`,
                }),
              );
            });
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${err.toString()}`,
            }),
          );
        });
      return () => abortController.abort();
    }
    setMedGenerico({ id: 0, label: '' });
    return undefined;
  };

  const handleChangePatente = async (
    event: any,
    newValue: ICatalogosPatente | null,
    reason: string,
  ) => {
    if (reason === 'clear') {
      setOpenPatente(true);
      clearSelects();
      setMedPatente({ id: 0, generico: 0, label: '' });
      setMedGenerico({ id: 0, label: '' });
    }
    if (newValue) {
      setVia(0);
      dispatch(setLoading(true));
      setMedPatente(newValue);
      if (newValue.generico > 0) {
        const response = await getFarmacologicoGenerico(idMedico, newValue.generico);
        const result = await response.json();
        setMedGenerico(result.datos[0]);
      } else {
        setMedGenerico({ id: -1, label: 'No considerado en el análisis' });
      }
      const viasFetch = getFarmacologicoViasPatente(idMedico, newValue.id, signal);
      const unidadFormaFetch = getFarmacologicoUnidadFormaGenerico(
        idMedico,
        newValue.generico,
        signal,
      );
      // const indicacionesFetch = getFarmacologicoIndicationGenerico(newValue.generico);
      Promise.all([viasFetch, unidadFormaFetch])
        .then((responses) => {
          Promise.all([responses[0].json(), responses[1].json()])
            .then((results) => {
              if (results[0].code === 200) {
                setCatVia(results[0].datos);
              }
              if (results[1].code === 200) {
                setCatUnidad(results[1].datos);
              }
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-get-error')} ${err.toString()}`,
                }),
              );
            });
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${err.toString()}`,
            }),
          );
        });
      return () => abortController.abort();
    }
    setMedPatente({ id: 0, generico: 0, label: '' });
    return undefined;
  };

  const getAllCatalogs = () => {
    dispatch(setLoading(true));
    const fetchUnidad = getCatFarmacologicoUnidad();
    const fetchVia = getCatFarmacologicoVia();
    Promise.all([fetchUnidad, fetchVia])
      .then((responses) => {
        Promise.all([responses[0].json(), responses[1].json()])
          .then((results) => {
            setCatUnidad(results[0].datos);
            setCatVia(results[1].datos);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleChangeTextoLibre = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMedTextoLibre(val);
  };

  const handleChangeRadioMedicamento = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'nombre_generico' | 'denominacion_distintiva' | 'texto_libre';
    switch (val) {
      case 'nombre_generico':
      case 'denominacion_distintiva':
        setCatVia([]);
        setCatUnidad([]);
        setOpenGenerico(false);
        setOpenPatente(false);
        borrarMedicamentos();
        setCatGenerico([]);
        setCatPatente([]);
        break;
      case 'texto_libre':
        setAlertOpen(true);
        return;
      default:
        break;
    }
    setRadioMedicamento(val);
  };

  const handleChangeUnidad = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as number;
    setUnidad(val);
  };

  const handleChangeCantidad = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as string;
    if (
      validacionIngresoSoloUnPunto(val) ||
      validacionDiezNumerosCuatroDecimales(val) ||
      val === ''
    ) {
      setCantidad(val);
    }
  };

  const handleChangeFrecuencia = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as number;
    setFrecuencia(val);
  };

  const handleChangeOtraFrecuencia = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setOtraFrecuencia(val);
  };

  const handleChangeVia = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as number;
    setVia(val);
  };

  const handleChangeDuración = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setDuracion(val);
    }
  };

  const handleChangePeriodo = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as number;
    setPeriodo(val);
  };

  const handleChangePresentacion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as string;
    setPresentacion(val);
  };

  const handleChangeInstruccionesAdicionales = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as string;
    setInstruccionesAdicionales(val);
  };

  const handleClickGuardarMedicamentoALaReceta = () => {
    // buscamos el indice del array del id diagnostico asociado
    const indxLEA = listaEnfermedadesActivas.findIndex((enf) => enf.idBD === diagnosticoAsociado);
    // creamos el objDiagnosticoAsociado que contiene un catalogo estandar de la enfermedad
    const objDiagnosticoAsociado = { id: 0, label: '' };
    // si existe entonces actualizamos el objeto con la nueva informacion
    if (indxLEA >= 0) {
      objDiagnosticoAsociado.id = diagnosticoAsociado;
      objDiagnosticoAsociado.label =
        listaEnfermedadesActivas[indxLEA].seleccionDiagnostico === 'cie10'
          ? listaEnfermedadesActivas[indxLEA].diagnosticoCie10.label
          : listaEnfermedadesActivas[indxLEA].diagnosticoMedico;
    }
    // creamos el objeto medicamento
    const medicamento = {
      idConsulta,
      idReceta: 0,
      idRecetaConsulta: idConsulta,
      enfermedad: objDiagnosticoAsociado,
      tipo: radioMedicamento,
      nombreDistintivo: medPatente,
      nombreGenerico: medGenerico,
      medTextoLibre,
      cantidad: parseFloat(cantidad),
      unidad: catUnidad.find((und) => und.id === unidad) || { id: 0, label: '' },
      frecuencia: catFrecuencia.find((frec) => frec.id === frecuencia) || { id: 0, label: '' },
      otraFrecuencia,
      via: catVia.find((catv) => catv.id === via) || { id: 0, label: '', impresion: '' },
      duracion: Number.parseInt(duracion, 10),
      periodo: catPeriodo.find((catP) => catP.id === periodo) || { id: 0, label: '' },
      instruccionesAdicionales,
      fechaReceta: currentSmallDatatimeFormat(),
      fechaFinTratamiento: currentSmallDateTimePlusDurationAndPeriod(
        Number.parseInt(duracion, 10),
        periodo,
      ),
      presentacion,
      id: idMedicamento,
      idPaciente,
      actual: true,
      alertas: { alergias: [] },
    };
    // cambios el id negativo que tenemos para insertar a los medicamentos
    // en la logica actual es presindible, pero antes en las listas, y guardar en listas
    // era impresindible para evitar errores
    setIdMedicamento((prevVal) => prevVal - 1);
    // creamos una variable para saber si el medicamento existe en la lista de medicamentos
    // actuales, es decir buscamos duplicidad de medicamentos mas no redundancia
    let existeElMedicamento = false;
    if (radioMedicamento === 'nombre_generico') {
      // si nombre generico buscar en la lista el id de los genericos
      existeElMedicamento = tratamientosActualesConsulta.some(
        (medi) =>
          medi.tipo === 'nombre_generico' &&
          medi.nombreGenerico.id === medicamento.nombreGenerico.id,
      );
    } else if (radioMedicamento === 'denominacion_distintiva') {
      // si es nombre comercial buscar en la lista el id de los comerciales
      existeElMedicamento = tratamientosActualesConsulta.some(
        (medi) =>
          medi.tipo === 'denominacion_distintiva' &&
          medi.nombreDistintivo.id === medicamento.nombreDistintivo.id,
      );
    }
    // si el medicamento no existe procedemos a insertardo
    if (!existeElMedicamento) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: farmacologicoGuardarMedicamento({
            ...medicamento,
            idMedico,
            idConsulta,
          }),
          successFunction: (result: any) => {
            // cambiamos el id por el nuevo id insertado que nos da el back
            medicamento.id = result.datos.idInsertado;
            // cambiamos las alertas por las alertas que nos trae el back
            medicamento.alertas = result.datos.alertas;
            // agregamos automáticamente el medicamento a la receta seleccionada
            medicamento.idReceta = recetas[indiceReceta].idBD;
            // insertamos el medicamento en el lista de medicamentos actuales
            dispatch(setTratamientos([...tratamientos, medicamento]));
            // marcamos la receta como actualizada
            const newRecetas = [...recetas];
            newRecetas[indiceReceta].actualizado = true;
            dispatch(setRecetas(newRecetas));
            // promesa fullfilled reseteamos
            setOpenGenerico(false);
            setOpenPatente(false);
            borrarMedicamentos();
            setRadioMedicamento('nombre_generico');
          },
        }),
      );
    } else {
      // alertar que el medicamento ya se tiene alli
      setConfirmOpen(true);
    }
  };

  const handleClickEditarMedicamento = () => {
    // buscamos el indice del array del id diagnostico asociado
    const indxLEA = listaEnfermedadesActivas.findIndex((enf) => enf.idBD === diagnosticoAsociado);
    // creamos el objDiagnosticoAsociado que contiene un catalogo estandar de la enfermedad
    const objDiagnosticoAsociado = { id: 0, label: '' };
    // si existe entonces actualizamos el objeto con la nueva informacion
    if (indxLEA >= 0) {
      objDiagnosticoAsociado.id = diagnosticoAsociado;
      objDiagnosticoAsociado.label =
        listaEnfermedadesActivas[indxLEA].seleccionDiagnostico === 'cie10'
          ? listaEnfermedadesActivas[indxLEA].diagnosticoCie10.label
          : listaEnfermedadesActivas[indxLEA].diagnosticoMedico;
    }
    // creamos el objeto medicamento
    const medicamento = {
      idConsulta: 0,
      idReceta: 0,
      idRecetaConsulta: 0,
      enfermedad: objDiagnosticoAsociado,
      tipo: radioMedicamento,
      nombreDistintivo: medPatente,
      nombreGenerico: medGenerico,
      medTextoLibre,
      cantidad: parseFloat(cantidad),
      unidad: catUnidad.find((und) => und.id === unidad) || { id: 0, label: '' },
      frecuencia: catFrecuencia.find((frec) => frec.id === frecuencia) || { id: 0, label: '' },
      otraFrecuencia,
      via: catVia.find((catv) => catv.id === via) || { id: 0, label: '', impresion: '' },
      duracion: Number.parseInt(duracion, 10),
      periodo: catPeriodo.find((catP) => catP.id === periodo) || { id: 0, label: '' },
      instruccionesAdicionales,
      fechaReceta: currentSmallDatatimeFormat(),
      fechaFinTratamiento: currentSmallDateTimePlusDurationAndPeriod(
        Number.parseInt(duracion, 10),
        periodo,
      ),
      presentacion,
      id: idMedicamento,
      idPaciente,
      actual: true,
      alertas: { alergias: [] },
    };
    let existeElMedicamento = false;
    if (radioMedicamento === 'nombre_generico') {
      // si nombre generico buscar en la lista el id de los genericos
      existeElMedicamento = tratamientosActualesConsulta.some(
        (medi) =>
          medi.tipo === 'nombre_generico' &&
          medi.nombreGenerico.id === medicamento.nombreGenerico.id &&
          medi.id !== idMedicamento,
      );
    } else if (radioMedicamento === 'denominacion_distintiva') {
      // si es nombre comercial buscar en la lista el id de los comerciales
      existeElMedicamento = tratamientosActualesConsulta.some(
        (medi) =>
          medi.tipo === 'denominacion_distintiva' &&
          medi.nombreDistintivo.id === medicamento.nombreDistintivo.id &&
          medi.id !== idMedicamento,
      );
    }
    // si el medicamento no existe procedemos a insertarlo
    if (!existeElMedicamento) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: editTerapiaFarmacologicaMedicamento({ ...medicamento, idMedico }),
          successFunction: (result: any) => {
            // cambiamos las alertas por las alertas que nos trae el back
            medicamento.alertas = result.datos.alertas;
            // actualizamos el medicamento en la lista de medicamentos actuales
            dispatch(
              setTratamientos(
                tratamientos.map((medi) =>
                  (medi.id === medicamento.id
                    ? {
                      ...medicamento,
                      idConsulta: medi.idConsulta,
                      idReceta: medi.idReceta,
                      idRecetaConsulta: medi.idRecetaConsulta,
                    }
                    : medi)),
              ),
            );
            // promesa fullfilled reseteamos
            setOpenGenerico(false);
            setOpenPatente(false);
            borrarMedicamentos();
            setRadioMedicamento('nombre_generico');
            setModoEditar(false);
          },
        }),
      );
    } else {
      setConfirmOpen(true);
    }
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleConfirmClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmOpen(false);
  };

  const handleChangeDiagnosticoAsociado = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setDiagnosticoAsociado(val);
  };

  const verificarDisableGuardarMedicamento = () => {
    let frec = !!frecuencia;
    if (frecuencia === 99) {
      frec = !!otraFrecuencia;
    }
    if (radioMedicamento === 'nombre_generico') {
      return !medGenerico.id || !cantidad || !frec || !via || !duracion || !periodo;
    }
    if (radioMedicamento === 'denominacion_distintiva') {
      return !medPatente.id || !cantidad || !frec || !via || !duracion || !periodo;
    }
    if (radioMedicamento === 'texto_libre') {
      return !medTextoLibre || !cantidad || !frec || !via || !duracion || !periodo;
    }
    return true;
  };

  useEffect(() => {
    if (inputGenerico.length > 2) {
      setLoadingGenerico(true);
      getCatFarmacologicoGenerico(idMedico, inputGenerico, signal)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatGenerico(result.datos);
          } else {
            setCatGenerico([]);
          }
          setLoadingGenerico(false);
        })
        .catch((err) => {
          setCatGenerico([]);
          setLoadingGenerico(false);
        });
    } else {
      setCatGenerico([]);
      setLoadingGenerico(false);
    }
    return () => abortController.abort();
  }, [inputGenerico]);

  useEffect(() => {
    if (inputPatente.length > 2) {
      setLoadingPatente(true);
      getCatFarmacologicoPatente(idMedico, inputPatente, signal)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatPatente(result.datos);
          } else {
            setCatPatente([]);
          }
          setLoadingPatente(false);
        })
        .catch(() => {
          setCatPatente([]);
          setLoadingPatente(false);
        });
    } else {
      setCatPatente([]);
      setLoadingPatente(false);
    }
    return () => abortController.abort();
  }, [inputPatente]);

  useEffect(() => {
    if (!openGenerico) {
      setCatGenerico([]);
    }
  }, [openGenerico]);

  useEffect(() => {
    if (!openPatente) {
      setCatPatente([]);
    }
  }, [openPatente]);

  useEffect(() => {
    dispatch(
      setRequest({
        type: 'get',
        multiple: true,
        requestFunctions: [
          getCatFarmacologicoFrecuencia(signal),
          getCatFarmacologicoPeriodo(signal),
        ],
        successFunctions: [
          (result: any) => setCatFrecuencia(result.datos),
          (result: any) => setCatPeriodo(result.datos),
        ],
        loading: false,
        showMsgs: false,
      }),
    );
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (modoEditar) {
      formularioRef.current?.scrollIntoView({ behavior: 'smooth' });
      // debemos saber si es texto libre, generico o distintivo
      // cuando sepamos que onditas debemos ir a llenar primero los catalogos
      // y despues que los catalogos esten llenos procedemos a hacer lo siguiente
      setRadioMedicamento(medicamentoAEditar.tipo);
      if (['nombre_generico', 'denominacion_distintiva'].includes(medicamentoAEditar.tipo)) {
        dispatch(
          setRequest({
            type: 'get',
            multiple: true,
            requestFunctions: [
              medicamentoAEditar.tipo === 'nombre_generico'
                ? getFarmacologicoViasGenerico(
                  idMedico,
                  medicamentoAEditar.nombreGenerico.id,
                  signal,
                )
                : getFarmacologicoViasPatente(
                  idMedico,
                  medicamentoAEditar.nombreDistintivo.id,
                  signal,
                ),
              getFarmacologicoUnidadFormaGenerico(
                idMedico,
                medicamentoAEditar.nombreGenerico.id,
                signal,
              ),
            ],
            successFunctions: [
              (result: any) => {
                setCatVia(result.datos);
                setVia(medicamentoAEditar.via.id);
              },
              (result: any) => {
                setCatUnidad(result.datos);
                setUnidad(medicamentoAEditar.unidad.id);
              },
            ],
            loading: false,
            showMsgs: false,
          }),
        );
        setMedGenerico(medicamentoAEditar.nombreGenerico);
        setMedTextoLibre(medicamentoAEditar.medTextoLibre);
        setMedPatente(medicamentoAEditar.nombreDistintivo);
      }
      if (medicamentoAEditar.tipo === 'texto_libre') {
        getAllCatalogs();
        setMedGenerico(medicamentoAEditar.nombreGenerico);
        setMedTextoLibre(medicamentoAEditar.medTextoLibre);
        setMedPatente(medicamentoAEditar.nombreDistintivo);
        setUnidad(medicamentoAEditar.unidad.id);
        setVia(medicamentoAEditar.via.id);
      }
      if (medicamentoAEditar.frecuencia.id === 0) {
        setFrecuencia(99);
        setOtraFrecuencia(medicamentoAEditar.otraFrecuencia);
      } else {
        setFrecuencia(medicamentoAEditar.frecuencia.id);
      }
      setIdMedicamento(medicamentoAEditar.id);
      setCantidad(medicamentoAEditar.cantidad ? medicamentoAEditar.cantidad.toString() : '');
      setDuracion(medicamentoAEditar.duracion ? medicamentoAEditar.duracion.toString() : '');
      setPeriodo(medicamentoAEditar.periodo.id);
      setPresentacion(medicamentoAEditar.presentacion);
      setInstruccionesAdicionales(medicamentoAEditar.instruccionesAdicionales);
      // si no existe que hacemos? que mostramos? que edita??
      setDiagnosticoAsociado(medicamentoAEditar.enfermedad.id);
    }
    return () => abortController.abort();
  }, [modoEditar, toggleEditar]);

  useEffect(() => {
    if (catVia.length === 1) {
      setVia(catVia[0].id);
    }
  }, [catVia]);

  return (
    <div ref={formularioRef}>
      <AlertDialog
        open={alertOpen}
        titulo="¡Atención!"
        descripcion={t('farmacologico_texto_libre_warning')}
        nombreCancelar={t('cancelar')}
        nombreAceptar={t('aceptar')}
        callBackAceptar={() => {
          setRadioMedicamento('texto_libre');
          borrarMedicamentos();
          getAllCatalogs();
        }}
        callBackClose={handleAlertClose}
      />
      <ConfirmDialog
        open={confirmOpen}
        titulo="¡Atención!"
        descripcion={t('farmacologico_medicamento_duplicado_actual')}
        nombreCancelar={t('aceptar')}
        callBackClose={handleConfirmClose}
      />
      <h3>{t('diagnostico_asociado_a_la_medicacion')}</h3>
      <Select
        fullWidth
        variant="outlined"
        value={diagnosticoAsociado}
        onChange={handleChangeDiagnosticoAsociado}
        displayEmpty
      >
        <MenuItem value={0}>{t('selecciona')}</MenuItem>
        {listaEnfermedadesActivas.map((lea) => (
          <MenuItem key={lea.idBD} value={lea.idBD}>
            {lea.seleccionDiagnostico === 'cie10'
              ? lea.diagnosticoCie10.label
              : lea.diagnosticoMedico}
          </MenuItem>
        ))}
      </Select>
      <h3>{`${t('nombre_del_medicamento')}*`}</h3>
      <RadioGroup row value={radioMedicamento} onChange={handleChangeRadioMedicamento}>
        <FormControlLabel
          value="nombre_generico"
          control={<Radio color="primary" />}
          label={t('nombre_generico')}
        />
        <FormControlLabel
          value="denominacion_distintiva"
          control={<Radio color="primary" />}
          label={t('nombre_comercial')}
        />
        <FormControlLabel
          value="texto_libre"
          control={<Radio color="primary" />}
          label={t('texto_libre')}
        />
      </RadioGroup>
      {radioMedicamento === 'nombre_generico' && (
        <Autocomplete
          open={openGenerico}
          onOpen={() => {
            setOpenGenerico(true);
          }}
          onClose={() => {
            setOpenGenerico(false);
          }}
          getOptionLabel={(option) => option.label}
          options={catGenerico}
          loading={loadingGenerico}
          loadingText="Buscando..."
          noOptionsText={
            inputGenerico.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
          }
          value={medGenerico}
          onChange={(e, nV, r) => handleChangeGenerico(e, nV, r)}
          onInputChange={(e, newInputValue) => {
            if (newInputValue.length < 3) {
              setCatGenerico([]);
            }
            setInputGenerico(newInputValue);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('selecciona')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingGenerico ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
      {radioMedicamento === 'denominacion_distintiva' && (
        <Autocomplete
          open={openPatente}
          onOpen={() => {
            setOpenPatente(true);
          }}
          onClose={() => {
            setOpenPatente(false);
          }}
          getOptionLabel={(option) => option.label}
          options={catPatente}
          loading={loadingPatente}
          loadingText="Buscando..."
          noOptionsText={
            inputPatente.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
          }
          value={medPatente}
          onChange={(e, nV, r) => handleChangePatente(e, nV, r)}
          onInputChange={(e, newInputValue) => {
            if (newInputValue.length < 3) {
              setCatPatente([]);
            }
            setInputPatente(newInputValue);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('selecciona')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingPatente ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
      {radioMedicamento === 'texto_libre' && (
        <TextField
          variant="outlined"
          fullWidth
          placeholder={t('especifica_el_nombre_del_medicamento')}
          value={medTextoLibre}
          onChange={handleChangeTextoLibre}
        />
      )}
      <div className="grid grid-cols-3 gap-x-16">
        <div className="w-full">
          <h3>{`${t('via')}*`}</h3>
          <Select value={via} onChange={handleChangeVia} displayEmpty fullWidth variant="outlined">
            <MenuItem value={0} disabled>
              <span className="text-gray-400">{t('selecciona')}</span>
            </MenuItem>
            {catVia.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <h3>{`${t('cantidad')}*`}</h3>
          <TextField
            variant="outlined"
            fullWidth
            onChange={handleChangeCantidad}
            value={cantidad}
          />
        </div>
        <div className="w-full">
          <h3>{`${t('unidad')}*`}</h3>
          <Select
            displayEmpty
            fullWidth
            variant="outlined"
            value={unidad}
            onChange={handleChangeUnidad}
          >
            <MenuItem value={0} disabled>
              <span className="text-gray-400">{t('selecciona')}</span>
            </MenuItem>
            {catUnidad.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <h3>{`${t('frecuencia')}*`}</h3>
          <Select
            value={frecuencia}
            onChange={handleChangeFrecuencia}
            displayEmpty
            fullWidth
            variant="outlined"
          >
            <MenuItem value={0} disabled>
              <span className="text-gray-400">{t('selecciona')}</span>
            </MenuItem>
            <MenuItem value={99}>{t('otra')}</MenuItem>
            {catFrecuencia.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        {frecuencia === 99 && (
          <div className="w-full">
            <h3>{t('otra_frecuencia')}</h3>
            <TextField
              variant="outlined"
              fullWidth
              value={otraFrecuencia}
              onChange={handleChangeOtraFrecuencia}
              InputProps={{
                inputProps: {
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: 100,
                },
              }}
            />
          </div>
        )}
        <div className="w-full">
          <h3>{`${t('duracion_del_tratamiento')}*`}</h3>
          <div className="flex w-full">
            <div className="w-1/3 mr-4">
              <TextField
                variant="outlined"
                value={duracion}
                onChange={handleChangeDuración}
                InputProps={{
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: 2,
                  },
                }}
              />
            </div>
            <div className="w-2/3">
              <Select
                value={periodo}
                onChange={handleChangePeriodo}
                displayEmpty
                fullWidth
                variant="outlined"
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400">{t('selecciona')}</span>
                </MenuItem>
                {catPeriodo.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3>{t('presentacion')}</h3>
        <TextField
          fullWidth
          variant="outlined"
          value={presentacion}
          onChange={handleChangePresentacion}
          placeholder={t('ejemplo_1_caja_con_30_tabletas')}
          InputProps={{
            inputProps: {
              autoComplete: 'off',
              'aria-autocomplete': 'none',
              maxLength: 50,
            },
          }}
        />
      </div>
      <div>
        <h3>{t('instrucciones_adicionales')}</h3>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={instruccionesAdicionales}
          onChange={handleChangeInstruccionesAdicionales}
          inputProps={{
            maxLength: 500,
            autoComplete: 'off',
            'aria-autocomplete': 'none',
          }}
        />
      </div>
      {/* {(radioMedicamento === 'nombre_generico'
        || radioMedicamento === 'denominacion_distintiva') && (
        <div className="">
          <h3>{t('lista_de_indicaciones_para_este_producto')}</h3>
          <Select
            value={indicaciones}
            onChange={handleChangeListaIndicaciones}
            displayEmpty
            fullWidth
            variant="outlined"
          >
            <MenuItem value="" disabled>
              <span className="text-gray-400">{t('selecciona')}</span>
            </MenuItem>
            {catIndicacion.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )} */}
      <div className="text-right my-4">
        {!modoEditar ? (
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickGuardarMedicamentoALaReceta}
            disabled={verificarDisableGuardarMedicamento()}
            startIcon={<SaveIcon />}
          >
            {t('guardar_medicamento')}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickEditarMedicamento}
            disabled={verificarDisableGuardarMedicamento()}
            startIcon={<SyncIcon />}
          >
            {t('editar_medicamento')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default FormularioReceta;
