/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
// import TableChartIcon from '@material-ui/icons/TableChart';
import { edadCalculada } from '@common/functions';
import { setExploracionFisica } from '@actions/paciente/actions';
import { setRutaPrincipal } from '@actions/rutasExpediente/actions';
import { sendExploracionFisica, sendExploracionFisicaPaciente } from '@utils/sendInfo';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { RootState } from 'src/store';
import {
  validacionIngresoSoloUnPunto,
  validacionSoloNumeros,
  validacionSoloNumerosDosDecimal,
  validacionSoloNumerosTresDecimales,
} from '@constants/validaciones';
import { getSitioMedicion } from '@utils/getCatalogos';
import { edadInitial, ICatalogosEstandar, IEdad } from '@common/types';
import BotonesGuardar from '@components/BotonesGuardar';
import { IControlErrores, IExploracionFisica, exploracionFisicaInitial } from './types';
import useStyles, { InputDisabled } from './styles';
import TablaExploracion from './TablaExploracion';

function ExploracionFisica() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idMedico, idUsuario, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    perfil: { datosPersonales },
    exploracionFisica,
  } = useSelector((state: RootState) => state.Paciente.expediente);
  const { idPaciente, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const [catSitioMedicion, setCatSitioMedicion] = useState<ICatalogosEstandar[]>([]);
  const [controlError, setControlError] = useState<IControlErrores>({
    peso: false,
    altura: false,
    cintura: false,
    sistolica: false,
    diastolica: false,
    fcardiaca: false,
    frespiratoria: false,
    temperatura: false,
    sp: false,
  });
  const [exploracionFisicaTemp, setExploracionFisicaTemp] =
    useState<IExploracionFisica>(exploracionFisicaInitial);
  const arrAreas = [
    'antecedentes-ef-ag',
    'antecedentes-ef-pa',
    'antecedentes-ef-craneo',
    'antecedentes-ef-cara',
    'antecedentes-ef-ojos',
    'antecedentes-ef-oidos',
    'antecedentes-ef-nariz',
    'antecedentes-ef-bg',
    'antecedentes-ef-cuello',
    'antecedentes-ef-torax',
    'antecedentes-ef-rp',
    'antecedentes-ef-gm',
    'antecedentes-ef-axila',
    'antecedentes-ef-abdomen',
    'antecedentes-ef-ric',
    'antecedentes-ef-gen',
    'antecedentes-ef-recto',
    'antecedentes-ef-extremidades',
    'antecedentes-ef-col',
    'antecedentes-ef-neurologico',
    'antecedentes-ef-otro',
  ];

  const edad =
    datosPersonales.fechaNac.dia && datosPersonales.fechaNac.mes && datosPersonales.fechaNac.anio
      ? edadCalculada(
        datosPersonales.fechaNac.anio,
        datosPersonales.fechaNac.mes,
        datosPersonales.fechaNac.dia,
      )
      : edadInitial;

  useEffect(() => {
    if (catSitioMedicion.length === 0) {
      getSitioMedicion().then((result: ICatalogosEstandar[]) => {
        setCatSitioMedicion(result);
      });
    }
    if (!exploracionFisica.loaded) {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        contenidoTabla: arrAreas.map((aA) => ({
          comentarioSintoma: '',
          nombreSintoma: aA,
          normal: '',
        })),
      });
    } else if (!exploracionFisicaTemp.loaded) {
      setExploracionFisicaTemp(exploracionFisica);
    }
  }, [exploracionFisica]);
  // calculo de presiones
  useEffect(() => {
    if (
      exploracionFisicaTemp.sistolica.length !== 0 &&
      exploracionFisicaTemp.diastolica.length !== 0
    ) {
      const pMedia =
        (parseInt(exploracionFisicaTemp.sistolica, 10) +
          parseInt(exploracionFisicaTemp.diastolica, 10) * 2) /
        3;
      const pPulso =
        parseInt(exploracionFisicaTemp.sistolica, 10) -
        parseInt(exploracionFisicaTemp.diastolica, 10);
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        pMedia: pMedia.toFixed(1),
        pPulso: pPulso > 0 ? pPulso.toFixed(1) : '',
      });
    } else if (exploracionFisicaTemp.loaded) {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        pMedia: '',
        pPulso: '',
      });
    }
  }, [exploracionFisicaTemp.sistolica, exploracionFisicaTemp.diastolica]);
  // calculo de IMC
  useEffect(() => {
    if (
      exploracionFisicaTemp.peso.length !== 0 &&
      exploracionFisicaTemp.altura.length !== 0 &&
      edad.edad >= 5 &&
      edad.periodo === 'anios'
    ) {
      const imc =
        parseFloat(exploracionFisicaTemp.peso) /
        ((parseFloat(exploracionFisicaTemp.altura) / 100) *
          (parseFloat(exploracionFisicaTemp.altura) / 100));
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        imc: imc.toFixed(1),
      });
    } else if (exploracionFisicaTemp.loaded) {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        imc: '',
      });
    }
  }, [exploracionFisicaTemp.peso, exploracionFisicaTemp.altura]);

  const handleChangeSitio = (event: any, newValue: ICatalogosEstandar | null) => {
    setExploracionFisicaTemp({
      ...exploracionFisicaTemp,
      sitioMedicion: newValue === null ? { id: 0, label: '' } : newValue,
    });
  };
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>, radio: string) => {
    const { value } = event.target as HTMLInputElement;
    if (radio === 'frecuencia') {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        fCardiaca: '',
        fCardiacaRadio: value,
      });
    } else if (radio === 'arterial') {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        sistolica: '',
        diastolica: '',
        pArterialRadio: value,
      });
    }
  };
  const valSoloNumeros = (event: React.ChangeEvent<{ value: unknown }>, input: string) => {
    const value = event.target.value as string;
    if (validacionSoloNumeros(value) || value === '') {
      if (input === 'altura') {
        setExploracionFisicaTemp({
          ...exploracionFisicaTemp,
          altura: value,
        });
        if (parseInt(value, 10) < 30 || parseInt(value, 10) > 220) {
          setControlError({ ...controlError, altura: true });
        } else {
          setControlError({ ...controlError, altura: false });
        }
      } else if (input === 'cintura') {
        setExploracionFisicaTemp({
          ...exploracionFisicaTemp,
          perCintura: value,
        });
        if (parseInt(value, 10) < 20 || parseInt(value, 10) > 300) {
          setControlError({ ...controlError, cintura: true });
        } else {
          setControlError({ ...controlError, cintura: false });
        }
      } else if (input === 'sistolica') {
        setExploracionFisicaTemp({
          ...exploracionFisicaTemp,
          sistolica: value,
        });
        if (parseInt(value, 10) < 50 || parseInt(value, 10) > 300) {
          setControlError({ ...controlError, sistolica: true });
        } else {
          setControlError({ ...controlError, sistolica: false });
        }
      } else if (input === 'diastolica') {
        setExploracionFisicaTemp({
          ...exploracionFisicaTemp,
          diastolica: value,
        });
        if (parseInt(value, 10) < 20 || parseInt(value, 10) > 200) {
          setControlError({ ...controlError, diastolica: true });
        } else {
          setControlError({ ...controlError, diastolica: false });
        }
      } else if (input === 'fcardiaca') {
        setExploracionFisicaTemp({
          ...exploracionFisicaTemp,
          fCardiaca: value,
        });
        if (parseInt(value, 10) < 40 || parseInt(value, 10) > 220) {
          setControlError({ ...controlError, fcardiaca: true });
        } else {
          setControlError({ ...controlError, fcardiaca: false });
        }
      } else if (input === 'frespiratoria') {
        setExploracionFisicaTemp({
          ...exploracionFisicaTemp,
          fRespiratoria: value,
        });
        if (parseInt(value, 10) < 10 || parseInt(value, 10) > 99) {
          setControlError({ ...controlError, frespiratoria: true });
        } else {
          setControlError({ ...controlError, frespiratoria: false });
        }
      } else if (input === 'satOxigeno') {
        setExploracionFisicaTemp({
          ...exploracionFisicaTemp,
          satOxigeno: value,
        });
        if (parseInt(value, 10) < 1 || parseInt(value, 10) > 100) {
          setControlError({ ...controlError, sp: true });
        } else {
          setControlError({ ...controlError, sp: false });
        }
      }
    }
  };
  const valConTresDecimales = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    if (validacionSoloNumerosTresDecimales(value) || validacionIngresoSoloUnPunto(value)) {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        peso: value,
      });
      if (parseFloat(value) < 1 || parseFloat(value) > 400) {
        setControlError({ ...controlError, peso: true });
      } else {
        setControlError({ ...controlError, peso: false });
      }
    }
  };
  const handleSubmitForm = () => {
    const datosAEnviar = {
      PESO:
        exploracionFisicaTemp.peso && exploracionFisicaTemp.peso.length === 0
          ? 999
          : exploracionFisicaTemp.peso,
      ALTURA:
        exploracionFisicaTemp.altura && exploracionFisicaTemp.altura.length === 0
          ? 999
          : exploracionFisicaTemp.altura,
      IMC:
        exploracionFisicaTemp.imc && exploracionFisicaTemp.imc.length === 0
          ? '-1'
          : exploracionFisicaTemp.imc,
      PARTERIAL_SISTOLICA:
        exploracionFisicaTemp.sistolica && exploracionFisicaTemp.sistolica.length === 0
          ? 0
          : exploracionFisicaTemp.sistolica,
      PARTERIAL_MEDIA: exploracionFisicaTemp.pMedia,
      PARTERIAL_PULSO: exploracionFisicaTemp.pPulso,
      PARTERIAL_SEL: exploracionFisicaTemp.pArterialRadio,
      PARTERIAL_DIASTOLICA:
        exploracionFisicaTemp.diastolica && exploracionFisicaTemp.diastolica.length === 0
          ? 0
          : exploracionFisicaTemp.diastolica,
      PER_CINTURA:
        exploracionFisicaTemp.perCintura && exploracionFisicaTemp.perCintura.length === 0
          ? 999
          : exploracionFisicaTemp.perCintura,
    };
    dispatch(setLoading(true));
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    const funcSendExploracionFisica = esPaciente
      ? sendExploracionFisicaPaciente({ ...datosAEnviar, ID_USUARIO: idUsuario })
      : sendExploracionFisica({
        ...datosAEnviar,
        ID_PACIENTE: idPaciente,
        ID_MEDICO: idMedico,
        ID_CONSULTA: idConsulta,
        F_CARDIACA:
            exploracionFisicaTemp.fCardiaca && exploracionFisicaTemp.fCardiaca.length === 0
              ? 0
              : exploracionFisicaTemp.fCardiaca,
        F_CARDIACASEL: exploracionFisicaTemp.fCardiacaRadio,
        F_RESPIRATORIA:
            exploracionFisicaTemp.fRespiratoria && exploracionFisicaTemp.fRespiratoria.length === 0
              ? 0
              : exploracionFisicaTemp.fRespiratoria,
        SAT_OXIGENO:
            exploracionFisicaTemp.satOxigeno && exploracionFisicaTemp.satOxigeno.length === 0
              ? 0
              : exploracionFisicaTemp.satOxigeno,
        TEMPERATURA:
            exploracionFisicaTemp.temperatura && exploracionFisicaTemp.temperatura.length === 0
              ? 0
              : exploracionFisicaTemp.temperatura,
        TEMPERATURASEL_ID: exploracionFisicaTemp.sitioMedicion.id,
        CONTENIDO_TABLA: exploracionFisicaTemp.contenidoTabla,
        idMedico,
        idConsultorio,
      });
    funcSendExploracionFisica
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setExploracionFisica({
              ...exploracionFisicaTemp,
              loaded: true,
            }),
          );
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          if (esPaciente) {
            dispatch(setRutaPrincipal('diagnostico-y-plan'));
          }
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = 'Campo Repetido';
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };
  /** Handlers de los textboxs */
  const handleChangeTemperatura = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    if (
      value === '' ||
      validacionSoloNumerosDosDecimal(value) ||
      validacionIngresoSoloUnPunto(value)
    ) {
      setExploracionFisicaTemp({
        ...exploracionFisicaTemp,
        temperatura: value,
      });
      if (parseFloat(value) < 30 || parseFloat(value) > 44) {
        setControlError((prev) => ({ ...prev, temperatura: true }));
      } else {
        setControlError((prev) => ({ ...prev, temperatura: false }));
      }
    }
  };

  return (
    <>
      <div>
        <div className="p-4 bg-white container mx-auto shadow">
          <div className="relative border-solid border-0 border-b border-gray-500 pb-4">
            <div className="text-center">
              <h1 className="text-blue-800 font-normal m-2">
                {t(esPaciente ? 'mediciones' : 'exploracion_fisica')}
              </h1>
            </div>
            {/*
            // dejado a proposito creo que solo se debe mostrar si se llega a esta pantalla por otro medio
            // que no sea una tab
            <div className="absolute top-0">
              <Button variant="contained" startIcon={<ArrowBackIcon />}>
                {t('atras')}
              </Button>
            </div> */}
            {/*
            // dejado a propositio son los botones comentados
            <div className="absolute top-0 right-0 flex flex-row content-center items-center">
              <div>
                <h3 className="text-blue-600 font-medium m-2">{t('antecedentes-ef-grafica')}</h3>
              </div>
              <div className="border-solid border-0 border-r border-gray-600 text-blue-600">
                <IconButton color="inherit" aria-label="grafica">
                  <ShowChartIcon />
                </IconButton>
              </div>
              <div className="text-blue-600">
                <IconButton color="inherit" aria-label="tabla">
                  <TableChartIcon />
                </IconButton>
              </div>
            </div> */}
          </div>
          <div className="relative pt-4">
            <div className="text-left">
              <h2 className="text-blue-800 font-normal m-0">{t('antecedentes-ef-signos')}</h2>
            </div>
            {!esPaciente && (
              <>
                <div>
                  <h3 className="text-gray-600 font-medium">{t('antecedentes-ef-temperatura')}</h3>
                </div>
                <div className="grid grid-cols-12 gap-8">
                  <div className="col-span-4">
                    <Autocomplete
                      id="combo-box-sitio-medicion"
                      options={catSitioMedicion}
                      getOptionLabel={(option) => option.label}
                      noOptionsText={t('sin_resultados')}
                      value={exploracionFisicaTemp.sitioMedicion}
                      onChange={(e, nV) => handleChangeSitio(e, nV)}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('antecedentes-ef-sitio')}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-2">
                    <TextField
                      id="grados-centigrados"
                      label="T"
                      value={exploracionFisicaTemp.temperatura}
                      error={controlError.temperatura}
                      FormHelperTextProps={{ className: classes.absoluteBottom }}
                      helperText={
                        controlError.temperatura ? t('antecedentes-ef-temperatura-err') : ''
                      }
                      onChange={handleChangeTemperatura}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{t('antecedentes-ef-gc')}</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </div>
                <div className="pt-6">
                  <h3 className="text-gray-600 font-medium m-0 pb-2">
                    {t('antecedentes-ef-frecuenciac')}
                  </h3>
                  <div className="grid grid-cols-12 gap-8">
                    <div className="col-span-4 justify-self-start place-self-center">
                      <RadioGroup
                        aria-label="fCardiaca"
                        name="fCardiaca"
                        value={exploracionFisicaTemp.fCardiacaRadio}
                        onChange={(event) => handleChangeRadio(event, 'frecuencia')}
                        row
                      >
                        <FormControlLabel
                          value="rRegular"
                          control={<Radio color="primary" />}
                          label={t('antecedentes-ef-ritmor')}
                        />
                        <FormControlLabel
                          value="rIrregular"
                          control={<Radio color="primary" />}
                          label={t('antecedentes-ef-ritmoi')}
                        />
                      </RadioGroup>
                    </div>
                    <div className="col-span-2">
                      <TextField
                        id="latidos-minuto"
                        value={exploracionFisicaTemp.fCardiaca}
                        error={controlError.fcardiaca}
                        FormHelperTextProps={{ className: classes.absoluteBottom }}
                        helperText={
                          controlError.fcardiaca ? t('antecedentes-ef-frecuenciac-err') : ''
                        }
                        onChange={(event) => valSoloNumeros(event, 'fcardiaca')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t('antecedentes-ef-l_m')}
                            </InputAdornment>
                          ),
                          inputProps: {
                            autoComplete: 'off',
                            'aria-autocomplete': 'none',
                            maxLength: '3',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="pt-6">
              <h3 className="text-gray-600 font-medium m-0 pb-2">{t('antecedentes-ef-presion')}</h3>
              <div className="grid lg:grid-cols-6 xl:grid-cols-12 gap-8">
                <div className="col-span-4">
                  <div className="pt-2">
                    <RadioGroup
                      aria-label="pArterialRadio"
                      name="pArterialRadio"
                      value={exploracionFisicaTemp.pArterialRadio}
                      onChange={(event) => handleChangeRadio(event, 'arterial')}
                      row
                    >
                      <FormControlLabel
                        value="sentado"
                        control={<Radio color="primary" />}
                        label={t('antecedentes-ef-sentado')}
                      />
                      <FormControlLabel
                        value="decubito"
                        control={<Radio color="primary" />}
                        label={t(
                          esPaciente ? 'antecedentes-ef-acostado' : 'antecedentes-ef-decubito',
                        )}
                      />
                      <FormControlLabel
                        value="pie"
                        control={<Radio color="primary" />}
                        label={t('antecedentes-ef-pie')}
                      />
                    </RadioGroup>
                  </div>
                </div>
                <div className="col-span-2">
                  <TextField
                    id="sistolica"
                    label={t(esPaciente ? 'antecedentes-ef-superior' : 'antecedentes-ef-sistolica')}
                    value={exploracionFisicaTemp.sistolica}
                    error={controlError.sistolica}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    helperText={controlError.sistolica ? t('antecedentes-ef-sistolica-err') : ''}
                    onChange={(event) => valSoloNumeros(event, 'sistolica')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-mmhg')}</InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '3',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="col-span-2">
                  <TextField
                    id="diastolica"
                    label={t(
                      esPaciente ? 'antecedentes-ef-inferior' : 'antecedentes-ef-diastolica',
                    )}
                    value={exploracionFisicaTemp.diastolica}
                    error={controlError.diastolica}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    helperText={controlError.diastolica ? t('antecedentes-ef-diastolica-err') : ''}
                    onChange={(event) => valSoloNumeros(event, 'diastolica')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-mmhg')}</InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '3',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="col-span-2">
                  <InputDisabled
                    label={t('antecedentes-ef-presiona')}
                    disabled
                    id="pMedia"
                    value={exploracionFisicaTemp.pMedia}
                    type="string"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-mmhg')}</InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                  />
                </div>
                <div className="col-span-2">
                  <InputDisabled
                    label={t('antecedentes-ef-presionp')}
                    disabled
                    id="pPulso"
                    value={exploracionFisicaTemp.pPulso}
                    type="string"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-mmhg')}</InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </div>
            </div>
            {!esPaciente && (
              <>
                <div className="pt-6">
                  <div className="grid lg:grid-cols-6 xl:grid-cols-12 gap-8">
                    <div className="col-span-3">
                      <h3 className="text-gray-600 font-medium m-0 pb-4">
                        {t('antecedentes-ef-frecuenciar')}
                      </h3>
                      <div>
                        <TextField
                          id="respiraciones"
                          value={exploracionFisicaTemp.fRespiratoria}
                          error={controlError.frespiratoria}
                          FormHelperTextProps={{ className: classes.absoluteBottom }}
                          helperText={
                            controlError.frespiratoria ? t('antecedentes-ef-frecuenciar-err') : ''
                          }
                          onChange={(event) => valSoloNumeros(event, 'frespiratoria')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {t('antecedentes-ef-respiraciones')}
                              </InputAdornment>
                            ),
                            inputProps: {
                              autoComplete: 'off',
                              'aria-autocomplete': 'none',
                              maxLength: '2',
                            },
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div />
                    <div className="col-span-2">
                      <h3 className="text-gray-600 font-medium m-0 pb-4">
                        {t('antecedentes-ef-sp02-label')}
                      </h3>
                      <TextField
                        id="sp"
                        placeholder={t('antecedentes-ef-sp02')}
                        value={exploracionFisicaTemp.satOxigeno}
                        error={controlError.sp}
                        FormHelperTextProps={{ className: classes.absoluteBottom }}
                        helperText={controlError.sp ? t('antecedentes-ef-sp02-err') : ''}
                        onChange={(event) => valSoloNumeros(event, 'satOxigeno')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{t('antecedentes-ef-%')}</InputAdornment>
                          ),
                          inputProps: {
                            autoComplete: 'off',
                            'aria-autocomplete': 'none',
                            maxLength: '3',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="pt-6">
              <div className="text-left">
                <h2 className="text-blue-800 font-normal m-0 pb-4">
                  {t(esPaciente ? 'antecedentes-ef-medidas' : 'antecedentes-ef-soma')}
                </h2>
              </div>
              <div className="grid lg:grid-cols-6 xl:grid-cols-12 gap-8">
                <div className="col-span-2">
                  <TextField
                    id="peso"
                    label={t('antecedentes-ef-peso')}
                    value={exploracionFisicaTemp.peso}
                    error={controlError.peso}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    helperText={controlError.peso ? t('antecedentes-ef-peso-err') : ''}
                    onChange={(event) => {
                      if (event.target.value.length !== 0) {
                        valConTresDecimales(event);
                      } else {
                        setExploracionFisicaTemp({
                          ...exploracionFisicaTemp,
                          peso: '',
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-kg')}</InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '3',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="col-span-2">
                  <TextField
                    id="altura"
                    label={t('antecedentes-ef-altura')}
                    value={exploracionFisicaTemp.altura}
                    error={controlError.altura}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    helperText={controlError.altura ? t('antecedentes-ef-altura-err') : ''}
                    onChange={(event) => valSoloNumeros(event, 'altura')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-cm')}</InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '3',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="col-span-2">
                  <InputDisabled
                    label={t('antecedentes-ef-imc')}
                    disabled
                    id="pMedia"
                    value={exploracionFisicaTemp.imc}
                    type="string"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-km')}</InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                  />
                </div>
                <div className="col-span-3">
                  <TextField
                    id="perimetro"
                    label={t('antecedentes-ef-perimetro')}
                    value={exploracionFisicaTemp.perCintura}
                    error={controlError.cintura}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    helperText={controlError.cintura ? t('antecedentes-ef-perimetro-err') : ''}
                    onChange={(event) => valSoloNumeros(event, 'cintura')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('antecedentes-ef-cm')}</InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        maxLength: '3',
                      },
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
            </div>
          </div>
          {!esPaciente && (
            <div>
              {!!arrAreas.length && (
                <>
                  <div className="text-left">
                    <h2 className="text-blue-800 font-normal m-0 py-4">
                      {t('antecedentes-ef-detalle')}
                    </h2>
                  </div>
                  <TablaExploracion
                    exploracionFisica={exploracionFisicaTemp}
                    setExploracionFisica={setExploracionFisicaTemp}
                    sexo={sexo}
                  />
                </>
              )}
            </div>
          )}
          {(esPaciente || !!arrAreas.length) && (
            <BotonesGuardar
              hayCambios={false}
              aceptarCallback={() => {}}
              cancelarCallback={() => {}}
              guardarCallback={handleSubmitForm}
              guardarDisable={Object.values(controlError).find((element) => element === true)}
              continuar={esPaciente}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ExploracionFisica;
