import { useTranslation } from 'react-i18next';
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
  Chip,
  Button,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { capitalize } from '@common/functions';
import { IViewLDProps } from './types';

function ListaDesplegable({ componenteListaDesplegable }: IViewLDProps) {
  const { t } = useTranslation();
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex items-end mb-2">
        <h3 className="m-0 pr-4 text-gray-600 font-medium">
          {capitalize(componenteListaDesplegable.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500">
          {componenteListaDesplegable.datosComponente.descripcion
            ? `* ${componenteListaDesplegable.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <div className="flex items-end mb-4">
        <div className="w-96">
          {!componenteListaDesplegable.constructor.listaDesplegable!.seleccionMultiple ? (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label">{t('selecciona')}</InputLabel>
              <Select
                labelId="label-lista"
                id="select-lista"
                label={t('selecciona')}
                value={
                  componenteListaDesplegable.constructor.listaDesplegable!
                    .seleccionListaDesplegable[0]
                }
              >
                {componenteListaDesplegable.constructor.listaDesplegable!.arrValores.map(
                  (valor, inx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={valor} key={String(inx)}>
                      {valor}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          ) : (
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">{t('selecciona')}</InputLabel>
                <Select
                  labelId="label-lista"
                  label={t('selecciona')}
                  id="select-multiple-lista"
                  multiple
                  value={
                    componenteListaDesplegable.constructor.listaDesplegable!
                      .seleccionListaDesplegable
                  }
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div>
                      {(selected as string[]).map((value) => (
                        <Chip
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          key={value}
                          label={value}
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </div>
                  )}
                >
                  {componenteListaDesplegable.constructor.listaDesplegable!.arrValores.map(
                    (valor, iny) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={String(iny)} value={valor}>
                        {valor}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: '#F59E0B', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        disabled
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 500 }}
        value={componenteListaDesplegable.constructor.comentarios}
      />
    </div>
  );
}

export default ListaDesplegable;
