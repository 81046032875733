import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { ReactComponent as LogoIcon } from '@assets/logo/Logotipo_Color.svg';
import errorAni from '@assets/misc/error-page.gif';

function NotFound() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="relative overflow-hidden">
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <div className="text-gray-900 w-100 flex-grow">
            <LogoIcon height="55" />
          </div>
        </Toolbar>
      </AppBar>
      <div className="absolute top-0">
        <img
          src={errorAni}
          style={{
            height: '100%',
            width: '100%',
          }}
          alt="error 404"
        />
      </div>
      <div className="absolute w-full text-center md:text-xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold">
        <h1>404</h1>
        <p>{t('_vaya_lo_sentimos_no_podemos_encontrar_esa_pagina_')}</p>
      </div>
      <div
        className="flex items-end pb-8"
        style={{ height: 'calc(100vh - 96px)', backgroundColor: '#a5d3e6' }}
      >
        <div className="w-full text-center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push('/')}
          >
            {t('volver_a_pagina_de_inicio')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
