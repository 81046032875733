import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import {
  RadioGroup, FormControlLabel, Radio, TextField, Button,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '@common/styles';
import TipoDeDrogas from './TipoDrogas';
import { IOtrasDrogasProps } from './types';

function OtrasDrogas(props: IOtrasDrogasProps) {
  const { hayCambios, toxicomania, setToxicomania } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { esPaciente } = useSelector((state: RootState) => state.Me);

  const estatus = ['si', 'no'];
  const handleChangeConsumoDrogas = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'si' | 'no';
    const newOtrasDrogas = { ...toxicomania.otrasDrogas };
    newOtrasDrogas.consumoOtrasDrogas = val;
    if (val === 'no') {
      newOtrasDrogas.arrTipoDrogas = {
        arrTabla: ['I'],
        tipoDroga: [''],
        especifica: [''],
        cantidadDroga: [''],
        edadInicio: [''],
        edadTermino: [''],
        frecuencia: [''],
        estatus: ['activo'],
      };
      newOtrasDrogas.comentarios = '';
    }
    setToxicomania({
      ...toxicomania,
      otrasDrogas: newOtrasDrogas,
    });
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        comentarios: event.target.value as string,
      },
    });
  };

  const handleDeleteRow = (indx: number) => {
    if (toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length > 1) {
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            arrTabla: toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.filter(
              (_item, j) => indx !== j,
            ),
            tipoDroga: toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga.filter(
              (_item, j) => indx !== j,
            ),
            especifica: toxicomania.otrasDrogas.arrTipoDrogas.especifica.filter(
              (_item, j) => indx !== j,
            ),
            cantidadDroga: toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga.filter(
              (_item, j) => indx !== j,
            ),
            edadInicio: toxicomania.otrasDrogas.arrTipoDrogas.edadInicio.filter(
              (_item, j) => indx !== j,
            ),
            edadTermino: toxicomania.otrasDrogas.arrTipoDrogas.edadTermino.filter(
              (_item, j) => indx !== j,
            ),
            frecuencia: toxicomania.otrasDrogas.arrTipoDrogas.frecuencia.filter(
              (_item, j) => indx !== j,
            ),
            estatus: toxicomania.otrasDrogas.arrTipoDrogas.estatus.filter((_item, j) => indx !== j),
          },
        },
      });
    } else {
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            arrTabla: ['I'],
            tipoDroga: [''],
            especifica: [''],
            cantidadDroga: [''],
            edadInicio: [''],
            edadTermino: [''],
            frecuencia: [''],
            estatus: ['activo'],
          },
        },
      });
    }
  };

  const handleClickAgregarOtroTipo = () => {
    if (toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length < 10) {
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            arrTabla: toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.concat(
              `${
                toxicomania.otrasDrogas.arrTipoDrogas.arrTabla[
                  toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length - 1
                ]
              }I`,
            ),
            tipoDroga: toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga.concat(''),
            especifica: toxicomania.otrasDrogas.arrTipoDrogas.especifica.concat(''),
            cantidadDroga: toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga.concat(''),
            edadInicio: toxicomania.otrasDrogas.arrTipoDrogas.edadInicio.concat(''),
            edadTermino: toxicomania.otrasDrogas.arrTipoDrogas.edadTermino.concat(''),
            frecuencia: toxicomania.otrasDrogas.arrTipoDrogas.frecuencia.concat(''),
            estatus: toxicomania.otrasDrogas.arrTipoDrogas.estatus.concat('activo'),
          },
        },
      });
    }
  };

  return (
    <div className="border-solid border-0 border-b border-gray-600">
      <h2 className="mt-4 mb-0 font-normal text-blue-800">
        {t(esPaciente ? 'otras_sustancias' : 'otras_drogas')}
      </h2>
      <RadioGroup
        row
        name="consumo-drogas"
        value={toxicomania.otrasDrogas.consumoOtrasDrogas}
        onChange={handleChangeConsumoDrogas}
      >
        {estatus.map((valor) => (
          <FormControlLabel
            value={valor}
            control={<Radio color="primary" />}
            label={t(`${valor}`)}
            labelPlacement="end"
            disabled={hayCambios}
          />
        ))}
      </RadioGroup>
      {toxicomania.otrasDrogas.consumoOtrasDrogas === 'si' && (
        <>
          {toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.map((el, indx) => (
            <TipoDeDrogas
              hayCambios={hayCambios}
              key={el}
              index={indx}
              toxicomania={toxicomania}
              setToxicomania={setToxicomania}
              handleDeleteRow={handleDeleteRow}
            />
          ))}
          <div className="flex justify-end">
            <Button
              color="primary"
              size="small"
              className={classes.noTextTransform}
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleClickAgregarOtroTipo}
              disabled={
                hayCambios ||
                toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length >= 10 ||
                toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga.some((droga: string) => !droga)
              }
            >
              {t('agregar_droga')}
            </Button>
          </div>
        </>
      )}
      {toxicomania.otrasDrogas.consumoOtrasDrogas === 'si' && (
        <div className="my-4">
          <TextField
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            value={toxicomania.otrasDrogas.comentarios}
            onChange={handleChangeComentarios}
            inputProps={{
              maxLength: 300,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
              className: 'uppercase',
            }}
            disabled={hayCambios}
            label={t('comentarios')}
          />
        </div>
      )}
    </div>
  );
}

export default OtrasDrogas;
