import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, Button } from '@material-ui/core';
import { ICardMensualProps } from './types';

function CardMensual({
  tiempoSuscripcion,
  tipoSuscripcion,
  handleClickButtonSuscripcion,
}: ICardMensualProps) {
  const { t } = useTranslation();
  const costoTipoSuscripcion = () => {
    switch (tipoSuscripcion) {
      case 'premium':
        return t('costo_mensual_premium');
      case 'estandar':
        return t('costo_mensual_estandar');
      case 'basica':
        return t('costo_mensual_basica');
      default:
        return '';
    }
  };
  return (
    <div
      className={`px-8 py-2 ${
        tiempoSuscripcion === 'mensual' ? 'bg-blue-100' : 'bg-gray-100'
      } rounded-lg border border-solid border-blue-500 flex`}
    >
      <div className="self-end w-full">
        <p className="font-medium text-gray-800">{t('paga_mensualmente')}</p>
        <div className="text-center">
          <FormControlLabel
            value="mensual"
            control={<Radio color="primary" />}
            label={(
              <h4 className="font-normal m-0">
                {t('pagaras')}
                &nbsp;
                <span>{costoTipoSuscripcion()}</span>
              </h4>
            )}
            style={{ color: '#047857' }}
          />
          <div className="text-gray-800">
            <Button
              variant="outlined"
              color="inherit"
              fullWidth
              style={{
                backgroundColor: '#6EE7B7',
                fontWeight: 500,
                borderColor: '#047857',
                borderRadius: '0.5rem',
              }}
              onClick={() => handleClickButtonSuscripcion('M')}
            >
              {t('subscripcion_mensual')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardMensual;
