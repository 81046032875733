import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import useStyles from '@common/styles';
import { RootState } from 'src/store';
import TragosEstandar from '@components/AlertasInformacion/TragosEstandar';
import { validacionSoloNumeros } from '@constants/validaciones';
import { edadCalculada, iEdadMenorOIgual } from '@common/functions';
import TipoDeAlcohol from './TipoDeAlcohol';
import { IAlcoholProps } from './types';
import { ITipoAlcohol, toxicomaniaInitial } from '../types';

function Alcohol(props: IAlcoholProps) {
  const {
    hayCambios, toxicomania, setToxicomania, getAlcohol,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const {
    sexo, dia, mes, anio,
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const edadPaciente = edadCalculada(anio, mes, dia);
  const [openTragos, setOpenTragos] = useState<boolean>(false);
  const [errores, setErrores] = useState({
    edadInicio: { error: false, helperText: '' },
    edadTermino: { error: false, helperText: '' },
    general: { error: false, helperText: '' },
  });
  const estatus = ['niega', 'activo', 'ex-bebedor'];
  const frecuencia = [
    'diario',
    '1_vez_por_semanas',
    '2_veces_por_semana',
    '3_veces_por_semana',
    '4_veces_por_semana',
    '5_veces_por_semana',
    '6_veces_por_semana',
    'mensual_o_menos',
  ];

  const handleChangeConsumoAlcohol = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'niega' | 'activo' | 'ex-bebedor';
    const newAlcohol =
      val === 'niega' ? { ...toxicomaniaInitial.alcohol } : { ...toxicomania.alcohol };
    newAlcohol.consumoAlcohol = val;
    if (val === 'ex-bebedor') {
      newAlcohol.consumeEnMenosDeDosHoras = '';
    }
    setToxicomania({
      ...toxicomania,
      alcohol: newAlcohol,
    });
  };

  const handleChangeEdadInicio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          edadInicio: val,
        },
      });
      if (val !== '') {
        const age = parseInt(val, 10);
        if (!iEdadMenorOIgual({ edad: age, claveEdad: 5, periodo: 'anios' }, edadPaciente)) {
          setErrores((prev) => ({
            ...prev,
            edadInicio: {
              error: true,
              helperText:
                'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
            },
          }));
        } else {
          setErrores((prev) => ({
            ...prev,
            edadInicio: {
              error: false,
              helperText: '',
            },
          }));
        }
      } else {
        setErrores((prev) => ({
          ...prev,
          edadInicio: {
            error: false,
            helperText: '',
          },
        }));
      }
    } else {
      setErrores((prev) => ({
        ...prev,
        edadInicio: {
          error: false,
          helperText: '',
        },
      }));
    }
  };

  const handleChangeEdadTermino = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          edadTermino: val,
        },
      });
      if (val !== '') {
        const age = parseInt(val, 10);
        if (!iEdadMenorOIgual({ edad: age, claveEdad: 5, periodo: 'anios' }, edadPaciente)) {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: true,
              helperText:
                'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
            },
          }));
        } else if (
          iEdadMenorOIgual(
            { edad: age, claveEdad: 5, periodo: 'anios' },
            { edad: parseInt(toxicomania.alcohol.edadInicio, 10), claveEdad: 5, periodo: 'anios' },
          )
        ) {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: true,
              helperText: 'la_edad_de_termino_es_menor_a_la_edad_actual_de_inicio',
            },
          }));
        } else {
          setErrores((prev) => ({
            ...prev,
            edadTermino: {
              error: false,
              helperText: '',
            },
          }));
        }
      } else {
        setErrores((prev) => ({
          ...prev,
          edadTermino: {
            error: false,
            helperText: '',
          },
        }));
      }
    } else {
      setErrores((prev) => ({
        ...prev,
        edadTermino: {
          error: false,
          helperText: '',
        },
      }));
    }
  };

  const handleChangeFrecuencia = (event: React.ChangeEvent<{ value: unknown }>) => {
    setToxicomania({
      ...toxicomania,
      alcohol: {
        ...toxicomania.alcohol,
        frecuencia: event.target.value as string,
      },
    });
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: unknown }>) => {
    setToxicomania({
      ...toxicomania,
      alcohol: {
        ...toxicomania.alcohol,
        comentarios: event.target.value as string,
      },
    });
  };

  const handleDeleteRow = (indx: number) => {
    if (toxicomania.alcohol.arrTipoAlcohol.arrTabla.length > 1) {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          arrTipoAlcohol: {
            arrTabla: toxicomania.alcohol.arrTipoAlcohol.arrTabla.filter((_item, j) => indx !== j),
            tipoAlcohol: toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.filter(
              (_item, j) => indx !== j,
            ),
            cantidadAlcohol: toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol.filter(
              (_item, j) => indx !== j,
            ),
          },
        },
      });
    } else {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          arrTipoAlcohol: {
            arrTabla: ['I'],
            tipoAlcohol: [{ label: '', grUnidad: 0 }],
            cantidadAlcohol: [''],
          },
        },
      });
    }
  };

  const handleClickAgregarOtroTipo = () => {
    if (toxicomania.alcohol.arrTipoAlcohol.arrTabla.length < 10) {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          arrTipoAlcohol: {
            arrTabla: toxicomania.alcohol.arrTipoAlcohol.arrTabla.concat(
              `${
                toxicomania.alcohol.arrTipoAlcohol.arrTabla[
                  toxicomania.alcohol.arrTipoAlcohol.arrTabla.length - 1
                ]
              }I`,
            ),
            tipoAlcohol: toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.concat({
              label: '',
              grUnidad: 0,
            }),
            cantidadAlcohol: toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol.concat(''),
          },
        },
      });
    }
  };

  const handleChangeConsumeEnMEnosDeDosHoras = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToxicomania({
      ...toxicomania,
      alcohol: {
        ...toxicomania.alcohol,
        consumeEnMenosDeDosHoras: event.target.value as 'si' | 'no',
      },
    });
  };

  const preguntarDosHoras = () =>
    toxicomania.alcohol.consumoAlcohol === 'activo' &&
    toxicomania.alcohol.totalAproxAlcohol &&
    ((sexo === 'M' && parseInt(toxicomania.alcohol.totalAproxAlcohol, 10) >= 52) ||
      (sexo === 'H' && parseInt(toxicomania.alcohol.totalAproxAlcohol, 10) >= 78));

  useEffect(() => {
    setToxicomania({
      ...toxicomania,
      alcohol: getAlcohol(toxicomania.alcohol),
    });
  }, [
    toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol,
    toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol,
    toxicomania.alcohol.frecuencia,
    toxicomania.alcohol.consumeEnMenosDeDosHoras,
  ]);

  return (
    <>
      <TragosEstandar open={openTragos} setOpen={setOpenTragos} />
      <div className="border-solid border-0 border-b border-gray-600">
        <h2 className="mt-4 mb-0 font-normal text-blue-800">{t('alcohol')}</h2>
        <RadioGroup
          row
          name="consumo-alcohol"
          value={toxicomania.alcohol.consumoAlcohol}
          onChange={handleChangeConsumoAlcohol}
        >
          {estatus.map((valor) => (
            <FormControlLabel
              value={valor}
              control={<Radio color="primary" />}
              label={t(`${valor}`)}
              labelPlacement="end"
              disabled={hayCambios}
            />
          ))}
        </RadioGroup>
        {(toxicomania.alcohol.consumoAlcohol === 'activo' ||
          toxicomania.alcohol.consumoAlcohol === 'ex-bebedor') && (
          <div>
            <div
              className={`grid grid-cols-${
                toxicomania.alcohol.consumoAlcohol === 'ex-bebedor' ? '3' : '2'
              } gap-x-4 w-full`}
            >
              <h3 className="mt-0 mb-2 text-gray-600 font-medium">{t('edad_de_inicio')}</h3>
              {toxicomania.alcohol.consumoAlcohol === 'ex-bebedor' && (
                <h3 className="mt-0 mb-2 text-gray-600 font-medium">{t('edad_de_termino')}</h3>
              )}
              <h3 className="mt-0 mb-2 text-gray-600 font-medium">{t('frecuencia')}</h3>
            </div>
            <div
              className={`grid grid-cols-${
                toxicomania.alcohol.consumoAlcohol === 'ex-bebedor' ? '3' : '2'
              } gap-x-4 w-full`}
            >
              <TextField
                variant="outlined"
                value={toxicomania.alcohol.edadInicio}
                onChange={handleChangeEdadInicio}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '2',
                  },
                }}
                error={errores.edadInicio.error}
                helperText={errores.edadInicio.error ? t(errores.edadInicio.helperText) : ''}
                FormHelperTextProps={{ className: classes.absoluteBottom2 }}
                fullWidth
                disabled={hayCambios}
              />
              {toxicomania.alcohol.consumoAlcohol === 'ex-bebedor' && (
                <TextField
                  variant="outlined"
                  value={toxicomania.alcohol.edadTermino}
                  onChange={handleChangeEdadTermino}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t('anios')}</InputAdornment>,
                    inputProps: {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: '2',
                    },
                  }}
                  error={errores.edadTermino.error}
                  helperText={errores.edadTermino.error ? t(errores.edadTermino.helperText) : ''}
                  FormHelperTextProps={{ className: classes.absoluteBottom2 }}
                  fullWidth
                  disabled={hayCambios}
                />
              )}
              <FormControl variant="outlined">
                <Select
                  displayEmpty
                  value={toxicomania.alcohol.frecuencia}
                  onChange={handleChangeFrecuencia}
                >
                  <MenuItem value="" disabled>
                    <span className="text-gray-400">{t('selecciona')}</span>
                  </MenuItem>
                  {frecuencia.map((valor) => (
                    <MenuItem value={valor}>{t(`${valor}`)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4">
              {toxicomania.alcohol.arrTipoAlcohol.arrTabla.map((tabla, indx) => (
                <TipoDeAlcohol
                  hayCambios={hayCambios}
                  toxicomania={toxicomania}
                  setToxicomania={setToxicomania}
                  index={indx}
                  handleDeleteRow={handleDeleteRow}
                  key={tabla}
                />
              ))}
            </div>
            <div className="flex justify-end">
              <Button
                color="primary"
                size="small"
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleClickAgregarOtroTipo}
                disabled={
                  hayCambios ||
                  toxicomania.alcohol.arrTipoAlcohol.arrTabla.length >= 10 ||
                  toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.some(
                    (alcohol: ITipoAlcohol) => !alcohol.label,
                  )
                }
              >
                {t('agregar_tipo_de_alcohol')}
              </Button>
            </div>
            <div className="grid grid-cols-3 gap-x-4">
              <h3 className="mt-4 mb-2 text-gray-600 font-medium">
                {t('total_aproximado_de_alcohol')}
              </h3>
              {preguntarDosHoras() ? (
                <h3 className="col-span-2 mt-4 mb-2 text-gray-600 font-medium">
                  {sexo === 'M'
                    ? t('consume_5_tragos_de_estas_bebidas_en_menos_de_2_horas')
                    : t('consume_6_tragos_de_estas_bebidas_en_menos_de_2_horas')}
                </h3>
              ) : (
                <div className="col-span-2" />
              )}
              <div>
                <TextField
                  disabled
                  value={toxicomania.alcohol.totalAproxAlcohol}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t('gr_de_alcohol')}</InputAdornment>
                    ),
                  }}
                />
                {toxicomania.alcohol.consumoAlcohol === 'activo' && (
                  <div>
                    {toxicomania.alcohol.alarmaConsumoIntensivo && (
                      <p className="text-red-500 m-1">{t('consumo_intensivo_de_alcohol')}</p>
                    )}
                    {!esPaciente && toxicomania.alcohol.alarmaPosibleTrastorno && (
                      <p className="text-red-500 m-1">
                        {t('posible_trastorno_por_el_uso_de_alcohol')}
                      </p>
                    )}
                  </div>
                )}
              </div>
              {preguntarDosHoras() && (
                <RadioGroup
                  row
                  name="consume en menos de dos horas"
                  value={toxicomania.alcohol.consumeEnMenosDeDosHoras}
                  onChange={handleChangeConsumeEnMEnosDeDosHoras}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio color="primary" />}
                    label={t('si')}
                    labelPlacement="end"
                    disabled={hayCambios}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label={t('no')}
                    labelPlacement="end"
                    disabled={hayCambios}
                  />
                </RadioGroup>
              )}
            </div>
            <Button
              color="primary"
              size="small"
              className={classes.noTextTransform}
              startIcon={<InfoIcon />}
              onClick={() => setOpenTragos(true)}
              disabled={hayCambios}
            >
              {t('equivalencias_de_tragos_estandar')}
            </Button>
            <div className="my-4">
              <TextField
                variant="outlined"
                value={toxicomania.alcohol.comentarios}
                onChange={handleChangeComentarios}
                multiline
                rows={3}
                fullWidth
                inputProps={{
                  maxLength: 300,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  className: 'uppercase',
                }}
                label={t('comentarios')}
                disabled={hayCambios}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Alcohol;
