import ViewRespuestaBreve from '@containers/Builder/Scaffold/RespuestaBreve/view';
import ViewParrafo from '@containers/Builder/Scaffold/Parrafo/view';
import ViewOpcionMultiple from '@containers/Builder/Scaffold/OpcionMultiple/view';
import ViewCasillasVerificacion from '@containers/Builder/Scaffold/CasillasVerificacion/view';
import ViewListaDesplegable from '@containers/Builder/Scaffold/ListaDesplegable/view';
import ViewEscalaLineal from '@containers/Builder/Scaffold/EscalaLineal/view';
import ViewFecha from '@containers/Builder/Scaffold/Fecha/view';
import ViewHora from '@containers/Builder/Scaffold/Hora/view';
import ViewTabla from '@containers/Builder/Scaffold/Tabla/view';
import { IRenderMotivoConsultaProps, IRenderProps } from './types';

function Render({ formato, componente }: IRenderProps) {
  switch (formato) {
    case 1:
      return (
        <div>
          <ViewRespuestaBreve componenteRespuesta={componente.constructorComponente} />
        </div>
      );
    case 2:
      return (
        <div>
          <ViewParrafo componenteParrafo={componente.constructorComponente} />
        </div>
      );
    case 3:
      return (
        <div>
          <ViewOpcionMultiple componenteCasillasOpciones={componente.constructorComponente} />
        </div>
      );
    case 4:
      return (
        <div>
          <ViewCasillasVerificacion componenteCasillasOpciones={componente.constructorComponente} />
        </div>
      );
    case 5:
      return (
        <div>
          <ViewListaDesplegable componenteListaDesplegable={componente.constructorComponente} />
        </div>
      );
    case 6:
      return (
        <div>
          <ViewEscalaLineal componenteEscalaLineal={componente.constructorComponente} />
        </div>
      );
    case 7:
      return (
        <div>
          <ViewTabla componenteTabla={componente.constructorComponente} />
        </div>
      );
    case 8:
      return (
        <div>
          <ViewFecha componenteFecha={componente.constructorComponente} />
        </div>
      );
    case 9:
      return (
        <div>
          <ViewHora componenteHora={componente.constructorComponente} />
        </div>
      );
    default:
      return null;
  }
}

function RenderMotivoConsulta({ componentes }: IRenderMotivoConsultaProps) {
  return (
    <div>
      {componentes.map((comp, indx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Render key={String(indx)} componente={comp} formato={comp.formato} />
      ))}
    </div>
  );
}

export default RenderMotivoConsulta;
