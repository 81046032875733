import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import DialogoPassword from '@components/ModalDialogs/DialogPassword';
import { IInformacionGeneralProps } from './types';

function InformacionGeneral({ nombreCompleto, email }: IInformacionGeneralProps) {
  const { t } = useTranslation();
  const [openCC, setOpenCC] = useState<boolean>(false);

  const cBCCCerar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setOpenCC(false);
  };

  return (
    <>
      <DialogoPassword open={openCC} callBackClose={cBCCCerar} />
      <div className="py-4">
        <h2 className="text-blue-800 font-normal m-0">{t('informacion_general')}</h2>
        <div className="border-0 border-solid border-gray-400 border-b flex items-center py-2">
          <h3 className="text-gray-800 font-medium m-0 w-1/3">{t('nombre')}</h3>
          <p className="text-gray-600 m-0 w-1/3 ml-4">{nombreCompleto}</p>
        </div>
        <div className="border-0 border-solid border-gray-400 border-b flex items-center py-2">
          <h3 className="text-gray-800 font-medium m-0 w-1/3">{t('correo_electronico')}</h3>
          <p className="text-gray-600 m-0 w-1/3 ml-4">{email}</p>
        </div>
        <div className="border-0 border-solid border-gray-400 border-b flex items-center py-1">
          <h3 className="text-gray-800 font-medium m-0 w-1/3">{t('contrasenia')}</h3>
          <p className="text-gray-600 m-0 w-1/3 ml-5">
            {'*'.repeat((nombreCompleto.length || 6) + 2)}
          </p>
          <div className="w-1/3 flex justify-end">
            <Button color="primary" onClick={() => setOpenCC(true)}>
              {t('modificar_contrasenia')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default InformacionGeneral;
