import React, { useState, useEffect } from 'react';
import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import { TransitionProps } from '@material-ui/core/transitions';
import { changePassword } from '@utils/sendInfo';
import { RootState } from 'src/store';
import { setRequest } from '@actions/request/types';
import { setSnackComplete } from '@actions/snackbar/types';
import { regexPassword } from '@common/functions';
import { str2AB } from '@common/base64Convert';
import { getKeyBack } from '@utils/common';
import useStyles from './styles';
import { IDialogoPasswordProps } from './types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#111827',
    },
  });

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined) => void;
}

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function DialogoPassword({ open, callBackClose }: IDialogoPasswordProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const me = useSelector((state: RootState) => state.Me);
  const [oldPass, setOldPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [secondNewPass, setSecondNewPass] = useState<string>('');
  const [verPass, setVerPass] = useState<boolean>(false);
  const [error, setError] = useState({
    error: false,
    helperText: '',
  });
  const [errorPR, setErrorPR] = useState({ error: false, helperText: '' });
  const [progress, setProgress] = useState<boolean>(false);

  useEffect(() => {
    setNewPass('');
    setOldPass('');
    setSecondNewPass('');
    setErrorPR({
      error: false,
      helperText: '',
    });
    setError({
      error: false,
      helperText: '',
    });
    setVerPass(false);
  }, [open]);

  const handleChangeData = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const val = event.target.value as string;
    if (tipo === 'old') {
      setOldPass(val);
      setError({
        error: false,
        helperText: '',
      });
    } else if (tipo === 'new') {
      setNewPass(val);
      setError({
        error: false,
        helperText: '',
      });
      setErrorPR({
        error: false,
        helperText: '',
      });
    } else if (tipo === 'repeat') {
      setSecondNewPass(val);
      setError({
        error: false,
        helperText: '',
      });
    }
  };
  const handleCambiarPassword = async () => {
    if (newPass !== secondNewPass) {
      setError({ error: true, helperText: t('medico_perfil_errornoigual') });
      return;
    }
    if (newPass === oldPass && secondNewPass === oldPass) {
      setError({ error: true, helperText: t('medico_perfil_same_all_pass') });
      return;
    }
    if (!regexPassword(newPass)) {
      setErrorPR({
        error: true,
        helperText: t(
          'la_contraseña_debe_tener_al_menos_una_mayuscula_una_minuscula_un_numero_y_un_caracter_especial',
        ),
      });
      return;
    }
    setError({ error: false, helperText: '' });
    setErrorPR({ error: false, helperText: '' });

    const binaryDerString = window.atob(getKeyBack());
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // email encriptada
    const emailC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(me.email),
    );
    // contraseña vieja encriptada
    const oldPassC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(oldPass),
    );
    // contraseña nueva encriptada
    const newPassC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(newPass),
    );
    setProgress(true);
    dispatch(
      setRequest({
        type: 'send',
        loading: false,
        requestFunction: changePassword({
          email: arrayBufferToBase64(emailC),
          oldPass: arrayBufferToBase64(oldPassC),
          newPass: arrayBufferToBase64(newPassC),
        }),
        successFunction: () => callBackClose(),
        successMessage: t('medico_perfil_sucess'),
        errorFunction: (result: any) => {
          switch (result.code) {
            case 400:
              setError({ error: true, helperText: t('medico_perfil_error_400') });
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: t('medico_perfil_error_400'),
                }),
              );
              break;
            case 401:
              setError({ error: true, helperText: t('medico_perfil_error_401') });
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: t('medico_perfil_error_401'),
                }),
              );
              break;
            case 500:
              setError({ error: true, helperText: t('medico_perfil_error_500') });
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: t('medico_perfil_error_500'),
                }),
              );
              break;
            default:
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: t('error_ocurrio_un_error_intentar_mas_tarde'),
                }),
              );
              break;
          }
        },
        alwaysFunction: () => setProgress(false),
        showMsgLoading: false,
        showMsgError: false,
      }),
    );
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
      fullWidth
      maxWidth="md"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{t('medico_perfil_cambiopass')}</h2>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText id="alert-dialog-descripcion">
          <div className="pb-2 pt-2">
            <div className="flex justify-between items-center">
              <h4 className="text-gray-500 font-normal m-0">{`${t('medico_perfil_oldpass')}*`}</h4>
              <Button
                color="primary"
                startIcon={verPass ? <Visibility /> : <VisibilityOff />}
                onClick={() => setVerPass(!verPass)}
              >
                {verPass ? t('ocultar_contrasenia') : t('mostrar_contrasenia')}
              </Button>
            </div>
            <TextField
              onCopy={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onCut={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onPaste={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(event) => handleChangeData(event, 'old')}
              variant="outlined"
              value={oldPass}
              fullWidth
              type={verPass ? 'text' : 'password'}
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '50' },
                endAdornment: (
                  <div className="text-gray-500">
                    <LockIcon color="inherit" />
                  </div>
                ),
              }}
            />
            <h4 className="text-gray-500 font-normal m-0 pt-4 pb-2">
              {`${t('medico_perfil_newpass')}*`}
            </h4>
            <TextField
              onCopy={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onCut={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onPaste={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(event) => handleChangeData(event, 'new')}
              value={newPass}
              variant="outlined"
              fullWidth
              type={verPass ? 'text' : 'password'}
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '50' },
                endAdornment: (
                  <div className="text-gray-500">
                    <LockIcon color="inherit" />
                  </div>
                ),
              }}
              helperText={errorPR.helperText}
              error={errorPR.error}
            />
            <h4 className="text-gray-500 font-normal m-0 pt-4 pb-2">
              {`${t('medico_perfil_repeatpass')}*`}
            </h4>
            <TextField
              onCopy={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onCut={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onPaste={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(event) => handleChangeData(event, 'repeat')}
              value={secondNewPass}
              variant="outlined"
              fullWidth
              type={verPass ? 'text' : 'password'}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '50' },
                endAdornment: (
                  <div className="text-gray-500">
                    <LockIcon color="inherit" />
                  </div>
                ),
              }}
            />
          </div>
          {error.error ? (
            <h4 className="text-red-500 font-normal m-0 py-1">{error.helperText}</h4>
          ) : null}
          <div className="flex justify-between pt-2">
            <h4 className="text-gray-500 font-normal m-0 pt-4 pb-2">{t('campo-obligatorio')}</h4>
            <Button
              variant="contained"
              color="primary"
              type="button"
              size="large"
              onClick={() => handleCambiarPassword()}
              disabled={
                !oldPass ||
                !newPass ||
                !secondNewPass ||
                newPass.length < 8 ||
                errorPR.error ||
                error.error
              }
            >
              {progress ? (
                <CircularProgress color="secondary" size="1.5rem" />
              ) : (
                t('medico_perfil_identificacion_cambiar')
              )}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default DialogoPassword;
