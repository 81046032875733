import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  TextField, RadioGroup, Radio, FormControlLabel,
} from '@material-ui/core';
import { RootState } from 'src/store';
import { validacionSoloNumeros } from '@constants/validaciones';
import { IEmbarazosProps } from './types';

function Embarazos(props: IEmbarazosProps) {
  const { hayCambios, ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const [error, setError] = useState<boolean>(false);

  const verificarCalculoEmbarazos = () => {
    const numeroGestas = parseInt(ginecoObstetrico.embarazos.gestas, 10) || 0;
    if (
      (numeroGestas &&
        (ginecoObstetrico.embarazos.para ||
          ginecoObstetrico.embarazos.cesareas ||
          ginecoObstetrico.embarazos.abortos ||
          ginecoObstetrico.embarazos.ectopicos)) ||
      (numeroGestas === 0 &&
        (ginecoObstetrico.embarazos.para ||
          ginecoObstetrico.embarazos.cesareas ||
          ginecoObstetrico.embarazos.abortos ||
          ginecoObstetrico.embarazos.ectopicos))
    ) {
      const numeroPara = parseInt(ginecoObstetrico.embarazos.para, 10) || 0;
      const numeroCesareas = parseInt(ginecoObstetrico.embarazos.cesareas, 10) || 0;
      const numeroAbortos = parseInt(ginecoObstetrico.embarazos.abortos, 10) || 0;
      const numeroEctopicos = parseInt(ginecoObstetrico.embarazos.ectopicos, 10) || 0;
      if (numeroGestas !== numeroPara + numeroCesareas + numeroAbortos + numeroEctopicos) {
        setError(true);
        return;
      }
    }
    setError(false);
  };

  const handleChangeEmbarazos = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (val === 'no') {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          embarazos: val,
          gestas: '',
          para: '',
          cesareas: '',
          abortos: '',
          ectopicos: '',
          nacidosVivos: '',
        },
      });
      setError(false);
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          embarazos: val,
        },
      });
    }
  };

  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      embarazos: {
        ...ginecoObstetrico.embarazos,
        comentarios: event.target.value as string,
      },
    });
  };

  const handleChangeGestas = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          gestas: val.toString(),
        },
      });
    }
    verificarCalculoEmbarazos();
  };

  const handleChangePara = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          para: val.toString(),
        },
      });
    }
  };

  const handleChangeCesareas = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          cesareas: val.toString(),
        },
      });
    }
  };

  const handleChangeAbortos = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          abortos: val.toString(),
        },
      });
    }
  };

  const handleChangeEctopicos = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          ectopicos: val.toString(),
        },
      });
    }
  };

  const handleChangeNacidosVivos = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        embarazos: {
          ...ginecoObstetrico.embarazos,
          nacidosVivos: val.toString(),
        },
      });
    }
  };

  return (
    <div className="border-solid border-0 border-b border-gray-600">
      <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('embarazos')}</h2>
      <RadioGroup
        row
        name="embarazos"
        value={ginecoObstetrico.embarazos.embarazos}
        onChange={handleChangeEmbarazos}
      >
        <FormControlLabel value="si" control={<Radio color="primary" />} label="Si" />
        <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
      </RadioGroup>
      {ginecoObstetrico.embarazos.embarazos === 'si' && (
        <div className="mb-4">
          <h2 className="mt-4 mb-0 font-normal text-blue-300">{`${t('cantidad_de')}:`}</h2>
          <div className={`grid grid-cols-${esPaciente ? '5' : '6'} gap-x-4`}>
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">
              {t(esPaciente ? 'embarazos' : 'gestas')}
            </h3>
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">
              {t(esPaciente ? 'partos' : 'para')}
            </h3>
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('cesareas')}</h3>
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('abortos')}</h3>
            {!esPaciente && (
              <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('ectopicos')}</h3>
            )}
            <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('nacidos_vivos')}</h3>
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: {
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: '2',
                },
              }}
              error={error}
              value={ginecoObstetrico.embarazos.gestas}
              onChange={handleChangeGestas}
              disabled={hayCambios}
            />
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '2' },
              }}
              value={ginecoObstetrico.embarazos.para}
              onChange={handleChangePara}
              disabled={hayCambios}
            />
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '2' },
              }}
              value={ginecoObstetrico.embarazos.cesareas}
              onChange={handleChangeCesareas}
              disabled={hayCambios}
            />
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '2' },
              }}
              value={ginecoObstetrico.embarazos.abortos}
              onChange={handleChangeAbortos}
              disabled={hayCambios}
            />
            {!esPaciente && (
              <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                  inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '2' },
                }}
                value={ginecoObstetrico.embarazos.ectopicos}
                onChange={handleChangeEctopicos}
                disabled={hayCambios}
              />
            )}
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '2' },
              }}
              value={ginecoObstetrico.embarazos.nacidosVivos}
              onChange={handleChangeNacidosVivos}
              disabled={hayCambios}
            />
          </div>
          {error && (
            <p className="text-red-500">{t('las_gestas_no_concuerda_con_los_otros_datos')}</p>
          )}
        </div>
      )}
      {!esPaciente && (
        <div className="mb-4">
          <TextField
            variant="outlined"
            rows={4}
            multiline
            fullWidth
            label={t('comentarios')}
            inputProps={{
              maxLength: 500,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
            value={ginecoObstetrico.embarazos.comentarios}
            onChange={handleChangeComentario}
            disabled={hayCambios}
          />
        </div>
      )}
    </div>
  );
}

export default Embarazos;
