import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { verificarSubscripcionConekta } from '@utils/getCatalogos';
import { setSnackComplete } from '@actions/snackbar/types';
import StepBienvenido from './StepBienvenido';
import { IAnalizandoPagoProps } from './types';

function AnalizandoPago({
  email, customer, setModalError, setModalLlame,
}: IAnalizandoPagoProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triggerSubscripcion, setTriggerSubscripcion] = useState<boolean>(false);
  const [subscrito, setSuscrito] = useState<boolean>(false);
  const [countConekta, setCountConekta] = useState<number>(0);

  useEffect(() => {
    if (countConekta > 10) {
      // mostrar modal de llamar a medipraxi
      // ya que el pago se realizó correctamente pero el webhook no lo detectó
      setModalLlame(true);
    }
  }, [countConekta]);

  useEffect(() => {
    if (!subscrito) {
      verificarSubscripcionConekta(email, customer)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setSuscrito(true);
          } else if (result.code === 201) {
            setModalError(true);
          } else if (result.code === 405) {
            setCountConekta((preC) => preC + 1);
            if (countConekta < 12) {
              setTimeout(() => {
                setTriggerSubscripcion((pre) => !pre);
              }, 15000);
            }
          } else if (result.code === 402 || result.code === 500) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error')} ${result.msg} ${result.detail}`,
              }),
            );
          } else {
            setTimeout(() => {
              setTriggerSubscripcion((pre) => !pre);
            }, 15000);
          }
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: `${t('message-get-error')} ${err.toString()}`,
            }),
          );
        });
    } else {
      setTimeout(() => {
        setTriggerSubscripcion((pre) => !pre);
      }, 15000);
    }
  }, [triggerSubscripcion]);
  return (
    <div className="p-4 shadow-lg bg-white rounded-lg border border-solid border-gray-200">
      {subscrito ? (
        <StepBienvenido />
      ) : (
        <div className="text-center w-full">
          <h2 className="font-normal text-green-500">{t('tu_pago_esta_siendo_procesado')}</h2>
          <p>
            <Trans i18nKey="confirmacion_de_pago_conekta_tiempo">
              <span className="font-medium">index0</span>
              <span className="font-medium">index1</span>
            </Trans>
          </p>
          <CircularProgress color="inherit" size={50} />
          <h3 className="font-normal text-green-500">
            {t('te_pedimos_estar_al_pendiente_de_tu_correo_electronico_')}
          </h3>
        </div>
      )}
    </div>
  );
}

export default AnalizandoPago;
