import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IPaperInstruccionesProps } from './types';

function PaperInstrucciones({ setModoEditar, modoEditar }: IPaperInstruccionesProps) {
  const history = useHistory();
  const { t } = useTranslation();
  /** States del functional component */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */

  return (
    <div className="p-4 bg-white shadow relative">
      <div className="absolute top-0 mt-4">
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => (setModoEditar && modoEditar ? setModoEditar(-1) : history.go(-1))}
        >
          {t('atras')}
        </Button>
      </div>
      <div>
        <div className="text-center">
          <h1 className="text-blue-800 font-normal text-center m-0">
            {t('personalizacion_fc_titulo')}
          </h1>
        </div>
        <p>{t('personalizacion_fc_instruccion_1')}</p>
        <p>{t('personalizacion_fc_instruccion_2')}</p>
      </div>
    </div>
  );
}

export default PaperInstrucciones;
