import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ITablaFMCMedipraxiProps, ITablaFMCMedipraxiRowsProps } from './types';

function TablaFMCMedipraxiRows({
  nombreFormato,
  rangoEdad,
  sexo,
  tipoConsulta,
  idFormato,
  index,
  handleHabilitarUso,
  habilitarUso,
  handleVerFormato,
}: ITablaFMCMedipraxiRowsProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`grid grid-cols-12${
        index % 2 === 0 ? ' bg-blue-200' : ''
      } text-center items-center`}
    >
      <div className="col-span-2 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center">
        <span className={!habilitarUso ? 'text-gray-400' : ''}>{nombreFormato}</span>
      </div>
      <div className="col-span-3 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center text-left">
        <span className={!habilitarUso ? 'text-gray-400' : ''}>{rangoEdad}</span>
      </div>
      <div className="col-span-1 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center">
        <span className={!habilitarUso ? 'text-gray-400' : ''}>{sexo}</span>
      </div>
      <div className="col-span-1 p-2 border-l border-solid border-0 border-gray-400 h-full flex items-center">
        <span className={!habilitarUso ? 'text-gray-400' : ''}>{tipoConsulta}</span>
      </div>
      <div className="col-span-2 p-2 border-l border-solid border-0 border-gray-400 h-full flex flex-wrap items-center justify-center">
        <IconButton
          aria-label="habilitar"
          onClick={() => handleHabilitarUso(idFormato)}
          color="primary"
        >
          {habilitarUso ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </div>
      <div className="col-span-3 p-2 border-l border-solid border-0 border-gray-400 h-full">
        <Button
          color="primary"
          onClick={() => handleVerFormato(idFormato)}
          disabled={!habilitarUso}
        >
          {t('ver_formato')}
        </Button>
        {/* a proposito seria una mejora este botón */}
        {/* <Button color="inherit" style={{ color: '#EF4444' }}>
          {t('usar_formato_como_plantilla')}
        </Button> */}
      </div>
    </div>
  );
}

function TablaFMCMedipraxi({
  motivosConsultaMedipraxi,
  handleHabilitarUso,
  handleVerFormato,
}: ITablaFMCMedipraxiProps) {
  const { t } = useTranslation();
  return (
    <div className="border border-solid border-gray-400 rounded-md">
      <div className="grid grid-cols-12 bg-blue-600 text-white text-center py-2 items-center">
        <span className="col-span-2">{t('nombre_del_formato')}</span>
        <span className="col-span-3">{t('rango_de_edad')}</span>
        <span className="col-span-1">{t('sexo')}</span>
        <span className="col-span-1">{t('tipo_de_consulta')}</span>
        <span className="col-span-2">{t('habilitar_uso_en_consulta')}</span>
      </div>
      {motivosConsultaMedipraxi.map((objResultado, index) => (
        <TablaFMCMedipraxiRows
          nombreFormato={objResultado.nombreFormato}
          rangoEdad={objResultado.rangoEdad}
          sexo={objResultado.sexo}
          tipoConsulta={objResultado.tipoConsulta}
          idFormato={objResultado.idFormato}
          index={index}
          habilitarUso={objResultado.habilitarUso}
          handleHabilitarUso={handleHabilitarUso}
          key={`${objResultado.idFormato}`}
          handleVerFormato={handleVerFormato}
        />
      ))}
    </div>
  );
}

export default TablaFMCMedipraxi;
