import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTable, usePagination } from 'react-table';
import { RootState } from 'src/store';
import dayjs from 'dayjs';
import {
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Chip,
  Button,
  IconButton,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
// import TableChartIcon from '@material-ui/icons/TableChart';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@components/PaginationButtons';
import { ReactComponent as Excel2021Icon } from '@assets/icons/Excel2021.svg';
import { verificarLaboratoriosPaciente } from '@utils/laboratory';
import { generarXlsPaciente } from '@utils/documents';
import { IPropsResultados, IArrayTablas, IColumns } from './types';
import useStyles from './styles';

const Table = (val: IArrayTablas) => {
  const classes = useStyles();
  // Use the state and functions returned from useTable to build your UI
  const {
    columns,
    data,
    pageCount: controlledPageCount,
    firstStepEditar,
    firstStepDelete,
    val2,
    idPaciente,
  } = val;
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination,
  );
  // Render the UI for your table
  return (
    <>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} className={classes.header}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, inx) => {
            const { id } = data[inx];
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const rowIndex = parseInt(cell.row.id, 10) || 0;
                  if (cell.column && cell.column.id && cell.column.id === 'anexo') {
                    return (
                      <TableCell {...cell.getCellProps()} className={classes.rowIcons}>
                        <div className="min-w-max self-center">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              firstStepEditar(id);
                            }}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => firstStepDelete(id)}
                            color="primary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : `${classes.row2}`}
                    >
                      {cell.column && cell.column.id && cell.column.id === 'fecha'
                        ? dayjs(cell.value.substring(0, 11)).locale('es').format('DD-MMM-YYYY')
                        : cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <div className="flex justify-between">
        <div>
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          />
        </div>
        <IconButton
          aria-label="edit"
          onClick={() => {
            const sendData = {
              idPaciente,
              idLaboratorio: val2?.idLaboratorio ? val2.idLaboratorio : 0,
              txt: `${val2?.txtLibre}`,
            };
            generarXlsPaciente(sendData);
          }}
          color="primary"
        >
          <Excel2021Icon />
        </IconButton>
      </div>
    </>
  );
};

function RegistrarLaboratorioComponente(props: IPropsResultados) {
  const {
    arrConjunto, firstStepEditar, firstStepDelete, totalResultados, actualizar,
  } = props;
  const [valMultiple, setValMultiple] = useState<string[]>([]);
  const [dataTablas, setDataTablas] = useState<any[]>([]);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { t } = useTranslation();
  useEffect(() => {
    if (valMultiple.length === 0) {
      setDataTablas([]);
    } else {
      const xvalor: any[] = [];
      let valorDummy;
      valMultiple.forEach((element, posx) => {
        valorDummy = arrConjunto.find((x) => x.label === element);
        xvalor.push({
          id_order: posx,
          id_laboratorio: valorDummy?.idLaboratorio,
          palabra: valorDummy?.txtLibre,
        });
      });
      const peticionLaboratorios = {
        ID_USUARIO: idPaciente,
        busqueda: xvalor,
        idPaciente,
        idMedico,
        idConsultorio,
      };
      verificarLaboratoriosPaciente(peticionLaboratorios)
        .then((result: any) => {
          const arrayData: any[] = [];
          let initialArray: any[] = [];
          let idOrder = 0;
          result.forEach((element: any) => {
            if (element.id_order === idOrder) {
              initialArray.push(element);
            } else {
              arrayData.push(initialArray);
              initialArray = [];
              idOrder = element.id_order;
              initialArray.push(element);
            }
          });
          arrayData.push(initialArray);
          setDataTablas(arrayData);
        })
        .catch(() => {
          setDataTablas([]);
        });
    }
  }, [valMultiple, actualizar]);
  useEffect(() => {
    setDataTablas([]);
    setValMultiple([]);
  }, [totalResultados]);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (valMultiple.length < 5) {
      setValMultiple(event.target.value as string[]);
    }
  };
  const deleteChip = (id: number) => {
    const chips = [...valMultiple];
    if (id !== -1) {
      chips.splice(id, 1);
      setValMultiple(chips);
    }
  };
  return (
    <>
      <h4 className="mb-2 mt-2 font-medium text-gray-500">
        <Trans i18nKey="d-p-plan-lab-escoge-laboratorio">
          <strong title="index0">index0</strong>
          index1
        </Trans>
      </h4>
      <div className="flex flex-wrap items-end justify-end">
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="label">{t('selecciona')}</InputLabel>
          <Select
            labelId="label-lista"
            label={t('selecciona')}
            id="select-multiple-lista"
            multiple
            value={valMultiple}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div>
                {(selected as string[]).map((value, inx) => (
                  <Chip
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => deleteChip(inx)}
                    key={value}
                    label={value}
                    variant="outlined"
                    color="primary"
                  />
                ))}
              </div>
            )}
          >
            {arrConjunto.map((valor, iny) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={iny} value={valor.label}>
                {valor.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="mt-2">
          <Button
            color="primary"
            size="small"
            style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
            startIcon={<FormatListBulletedIcon />}
            disabled
          >
            {t('podras_seleccionar_varias_opciones')}
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {dataTablas.map((elementTable, inx) => {
          let columns: IColumns[] = [];
          if (!elementTable[0].unidad) {
            columns = [
              {
                Header: 'FECHA',
                accessor: 'fecha',
              },
              {
                Header: 'VALOR',
                accessor: 'label',
              },
              {
                Header: '',
                accessor: 'anexo',
              },
            ];
          } else {
            columns = [
              {
                Header: 'FECHA',
                accessor: 'fecha',
              },
              {
                Header: `${elementTable[0].unidad}`,
                accessor: 'valor',
              },
              {
                Header: '',
                accessor: 'anexo',
              },
            ];
          }
          const valorEncontrado = arrConjunto.find((val) => val.label === valMultiple[inx]);
          let labelCategoria: string = '';
          let labelNombre: string = '';
          if (valorEncontrado && valorEncontrado?.idLaboratorio && valorEncontrado?.categoria) {
            labelCategoria = valorEncontrado?.categoria;
            labelNombre = valorEncontrado?.nombre || '';
          } else if (
            valorEncontrado &&
            !valorEncontrado?.idLaboratorio &&
            valorEncontrado?.txtLibre
          ) {
            labelCategoria = 'Otro';
            labelNombre = valorEncontrado?.txtLibre;
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={inx} className="border-solid border border-gray-400 rounded p-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h2 className="text-blue-500 font-normal my-2">{labelCategoria}</h2>
                  <h3 className="text-gray-500 font-normal my-2">{labelNombre}</h3>
                </div>
                {/* estos se deben de habilitar cuando esten las funciones */}
                {/* <div className="flex place-items-end place-content-end">
                  <div className="flex flex-row place-items-center place-content-end">
                    <h4 className="text-blue-500 font-normal m-2">
                      {t('antecedentes-ef-grafica')}
                    </h4>
                    <div className="border-solid border-0 border-r border-gray-500 text-blue-500">
                      <IconButton color="inherit" aria-label="grafica">
                        <ShowChartIcon />
                      </IconButton>
                    </div>
                    <div className="text-blue-500">
                      <IconButton color="inherit" aria-label="tabla">
                        <TableChartIcon />
                      </IconButton>
                    </div>
                  </div>
                </div> */}
              </div>
              <Table
                columns={columns}
                data={elementTable}
                pageCount={elementTable.length / 5}
                firstStepEditar={firstStepEditar}
                firstStepDelete={firstStepDelete}
                val2={arrConjunto.find((x) => x.label === valMultiple[inx])}
                idPaciente={idPaciente}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default RegistrarLaboratorioComponente;
