import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import { TransitionProps } from '@material-ui/core/transitions';
import { validacionSoloNumeros } from '@constants/validaciones';
import IAlertDialogProps from './types';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function IngresarPacientePIN(props: IAlertDialogProps) {
  const {
    open, pass, setPass, callBackAceptar, callBackClose,
  } = props;
  const [viewPass, setViewPass] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChangePIN = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val === '' || validacionSoloNumeros(val)) {
      setPass(val);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="ingresar-paciente-pin-titulo"
      aria-describedby="ingresar-paciente-pin-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('ingresar_pin_medipraxi')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <WarningIcon className="text-yellow-500" color="inherit" />
        <DialogContentText>
          <div className="p-4 grid grid-cols-3 place-items-center">
            <div />
            <TextField
              placeholder="XXXX"
              variant="outlined"
              type={viewPass ? 'text' : 'password'}
              fullWidth
              value={pass}
              onChange={handleChangePIN}
              InputProps={{
                endAdornment: (
                  <div className="text-gray-500">
                    <LockIcon color="inherit" />
                  </div>
                ),
                inputProps: {
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  maxLength: 4,
                },
              }}
            />
            <div className="text-right">
              <Button
                color="primary"
                startIcon={viewPass ? <Visibility /> : <VisibilityOff />}
                onClick={() => setViewPass((prevVal) => !prevVal)}
              >
                {viewPass ? t('ocultar_contrasenia') : t('mostrar_contrasenia')}
              </Button>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center mb-4">
          <div>
            <Button
              disabled={pass.length < 4}
              onClick={callBackAceptar}
              color="primary"
              variant="contained"
            >
              {t('aceptar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default IngresarPacientePIN;
