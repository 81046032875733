/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Radio,
  Checkbox,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { capitalize } from '@common/functions';
import { IViewOpcionMultiple } from './types';

function OpcionMultiple({ componenteCasillasOpciones }: IViewOpcionMultiple) {
  const { t } = useTranslation();

  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex items-end">
        <h3 className="mr-4 mt-0 mb-0 text-gray-600 font-medium">
          {capitalize(componenteCasillasOpciones.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500">
          {componenteCasillasOpciones.datosComponente.descripcion
            ? `* ${componenteCasillasOpciones.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <div className="mb-4">
        <FormControl component="fieldset">
          <RadioGroup value={componenteCasillasOpciones.constructor.valor}>
            {componenteCasillasOpciones.constructor.casillasOpciones!.arrOpciones.map(
              // eslint-disable-next-line react/no-array-index-key
              (singleArr, indx) => (
                <div key={String(indx)}>
                  <FormControlLabel
                    value={singleArr.nombreOpcion}
                    control={<Radio disabled color="primary" />}
                    label={singleArr.nombreOpcion}
                  />
                  <div className="ml-4">
                    <FormGroup row>
                      {singleArr.arraySubOpciones.map((singleCasilla, indy) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`secondOption-${indx}-${indy}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                name={singleCasilla.nombreOpcion}
                                color="primary"
                                disabled
                                checked={singleCasilla.checked}
                              />
                            )}
                            label={singleCasilla.nombreOpcion}
                          />
                        </div>
                      ))}
                    </FormGroup>
                  </div>
                </div>
              ),
            )}
          </RadioGroup>
        </FormControl>
      </div>
      <TextField
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        disabled
        label={t('comentarios')}
        inputProps={{ autoComplete: 'off', maxLength: 500 }}
        value={componenteCasillasOpciones.constructor.comentarios}
      />
    </div>
  );
}

export default OpcionMultiple;
