import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputProps } from './type';

export const TextField: FC<TextInputProps> = ({
  name,
  containerClass = '',
  placeholder,
  label: externalLabel,
  helperText,
  adornment,
  labelAligmet = 'top',
  value,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const label = externalLabel === undefined ? t(name) : externalLabel;
  const isFloating = value && value.length > 0;

  const getAdornment = (txt: string) => (
    <div className="content-center">
      <label
        htmlFor={name}
        className="text-base bg-white text-gray-500"
      >
        {txt}
      </label>
    </div>
  );

  return (
    <div className={`w-full h-fit mb-4 ${containerClass}`}>
      <div className="px-4">
        {labelAligmet === 'top' && label && (
          <div className="flex items-center justify-between mb-2">
            <label
              htmlFor={name}
              className="block font-medium text-base text-gray-600"
            >
              {label}
            </label>
          </div>
        )}

        <div className="flex items-center gap-4">
          <div className="flex flex-row relative border-b border-gray-400 border-dotted">
            {adornment && adornment.position === 'start' && getAdornment(adornment.text)}
            <input
              type="text"
              id={name}
              name={name}
              placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
              value={value}
              readOnly
              className="w-full h-[48px] text-base px-3 pr-3 focus:outline-none bg-white"
              disabled={disabled}
            />
            {adornment && adornment.position === 'end' && getAdornment(adornment.text)}
            {labelAligmet === 'border' && label && (
              <label
                htmlFor={name}
                className={`absolute text-sm transition-all duration-200 ${
                  isFloating
                    ? '-top-2.5 left-2 bg-white px-1 text-gray-500'
                    : 'top-[50%] -translate-y-[50%] left-3 text-gray-500'
                }`}
              >
                {label}
              </label>
            )}
          </div>
        </div>
        {helperText && (
          <h5 className="mt-1 text-gray-500">
            {helperText}
          </h5>
        )}
      </div>
    </div>
  );
};

export default TextField;
