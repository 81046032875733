import {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { EmailInputProps } from './type';

export const EmailInput: FC<EmailInputProps> = ({
  name,
  containerClass = '',
  label: externalLabel,
  value: externalValue,
  validacion,
  isRequired,
  placeholder: externalPlaceholder,
  labelAligmet = 'top',
  setValue,
  helperText,
  disableOption,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState(externalValue || '');
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const label = externalLabel === undefined ? t(name) : externalLabel;
  const placeholder = externalPlaceholder === undefined ? label : '';
  const isFloating = isFocused || text.length > 0;

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if (externalValue !== undefined) {
      setText(externalValue);
    }
  }, [externalValue]);

  const validateEmail = (email: string) => {
    if (isRequired && email.trim() === '') {
      setErrorMessage('Este campo es requerido');
      return false;
    }

    if (email.trim() !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setErrorMessage('Por favor ingrese un correo electrónico válido');
        return false;
      }
    }

    setErrorMessage('');
    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    setErrorMessage('');

    // Crear evento sintético
    const syntheticEvent = {
      name,
      value: newValue,
      type: 'email',
    };
    if (validacion) {
      setErrorMessage(validacion(newValue));
    }
    setValue(syntheticEvent);
  };

  const handleBlur = () => {
    setIsFocused(false);
    validateEmail(text);
  };

  const handleDisableChange = () => {
    const newState = !disableOption?.value;
    setIsDisabled(newState);
    setText('');
    setErrorMessage('');
    if (disableOption?.name) {
      setValue?.({ name: disableOption.name, value: newState });
    }
    if (!newState) {
      // Crear evento sintético para el reset
      const syntheticEvent = {
        name,
        value: '',
        type: 'email',
      };
      setValue(syntheticEvent);
    }
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={isDisabled}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  const getStyle = () => {
    if (isDisabled) {
      return 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed';
    }
    if (errorMessage) {
      return 'border-red-500 ring-2 ring-red-200';
    }
    return 'border-gray-300 focus:ring-2 focus:ring-blue-500';
  };

  return (
    <div className={`w-full h-fit mb-4 ${containerClass}`}>
      <div className="px-4">
        <div className="">
          <div className="flex items-center justify-between mb-2">
            {label && (
              <label
                htmlFor={name}
                className={`${
                  labelAligmet === 'top'
                    ? `block font-medium text-base ${errorMessage
                      ? 'text-red-500' : 'text-gray-700'}`
                    : `absolute font-medium text-sm transition-all duration-200 ${
                      errorMessage ? 'text-red-500' : 'text-gray-700'
                    } bg-white ${
                      isFloating ? 'left-0 top-0' : 'absolute top-[13px] left-2'}`
                } ${isDisabled ? 'text-gray-500' : ''}`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            )}
            {disableOption?.position === 'right' && renderDisableCheckbox()}
          </div>

          <div className="flex items-center gap-4">
            <div className="flex-1 relative">
              <input
                type="email"
                id={name}
                name={name}
                placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
                value={text}
                onChange={handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                disabled={isDisabled}
                className={`w-full h-[48px] px-3 rounded-md border focus:outline-none transition ${
                  getStyle()
                }`}
              />
            </div>
            {disableOption?.position === 'after' && renderDisableCheckbox()}
          </div>

          {(errorMessage || helperText) && (
            <p className={`text-xs mt-1 ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
              {errorMessage || helperText}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailInput;
