import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import useStyles from './styles';
import FormularioCatalogo from './FormularioCatalogo';
import { ICatalogosCombinados } from '../types';
import { ICatalogosProps } from './types';
import ListaCatalogos from './ListaCatalogos';

function Catalogos({
  tipoCatalogo,
  setTipoCatalogo,
  catCategorias,
  catTiposExamen,
  setCatTiposExamen,
  catPuestos,
  setCatPuestos,
  catRestricciones,
  setCatRestricciones,
  catCausasNoAptitud,
  setCatCausasNoAptitud,
}: ICatalogosProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [listaCatalogo, setListaCatalogo] = useState<ICatalogosCombinados[]>(catTiposExamen);
  const [objCatalogo, setObjCatalogo] = useState<ICatalogosCombinados>({
    id: -1,
    label: '',
    categoria: { id: 0, label: '' },
    source: 'propio',
    inUse: false,
  });

  useEffect(() => {
    setObjCatalogo({
      id: -1,
      label: '',
      categoria: { id: 0, label: '' },
      source: 'propio',
      inUse: false,
    });
    if (tipoCatalogo === 'tipo_examen') {
      setListaCatalogo(catTiposExamen);
    } else if (tipoCatalogo === 'puesto') {
      setListaCatalogo(catPuestos);
    } else if (tipoCatalogo === 'restricciones') {
      setListaCatalogo(catRestricciones);
    } else if (tipoCatalogo === 'causas_no_aptitud') {
      setListaCatalogo(catCausasNoAptitud);
    }
  }, [tipoCatalogo, catTiposExamen, catPuestos, catRestricciones, catCausasNoAptitud]);

  return (
    <div>
      <div className="mt-4 grid grid-cols-3 xl:grid-cols-4 gap-3 pb-6">
        <Button
          className={tipoCatalogo === 'tipo_examen' ? classes.selected : classes.button}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setTipoCatalogo('tipo_examen')}
          size="large"
        >
          {t('tipo_examen')}
        </Button>
        <Button
          className={tipoCatalogo === 'puesto' ? classes.selected : classes.button}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => setTipoCatalogo('puesto')}
        >
          {t('puesto_actual_o_aspirado')}
        </Button>
        <Button
          className={tipoCatalogo === 'restricciones' ? classes.selected : classes.button}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => setTipoCatalogo('restricciones')}
        >
          {t('restricciones')}
        </Button>
        <Button
          className={tipoCatalogo === 'causas_no_aptitud' ? classes.selected : classes.button}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => setTipoCatalogo('causas_no_aptitud')}
        >
          {t('causa_no_aptitud')}
        </Button>
      </div>
      <FormularioCatalogo
        tipoCatalogo={tipoCatalogo}
        catCategorias={catCategorias}
        setCatTiposExamen={setCatTiposExamen}
        setCatPuestos={setCatPuestos}
        setCatRestricciones={setCatRestricciones}
        setCatCausasNoAptitud={setCatCausasNoAptitud}
        objCatalogo={objCatalogo}
        setObjCatalogo={setObjCatalogo}
      />
      {listaCatalogo.filter((cat) => cat.source === 'propio').length > 0 && (
        <ListaCatalogos
          tipoCatalogo={tipoCatalogo}
          listaCatalogo={listaCatalogo}
          catTiposExamen={catTiposExamen}
          setCatTiposExamen={setCatTiposExamen}
          catPuestos={catPuestos}
          setCatPuestos={setCatPuestos}
          catRestricciones={catRestricciones}
          setCatRestricciones={setCatRestricciones}
          catCausasNoAptitud={catCausasNoAptitud}
          setCatCausasNoAptitud={setCatCausasNoAptitud}
          setObjCatalogo={setObjCatalogo}
        />
      )}
    </div>
  );
}

export default Catalogos;
