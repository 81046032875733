import { FC } from 'react';
import { isOxxo } from '@constants/oxxo';
import { subMenu } from '@constants/prueba/menu';
import { StepHeaderProps } from './type';

// eslint-disable-next-line import/prefer-default-export
export const StepHeader: FC<StepHeaderProps> = ({ currentStep }) => {
  const totalSteps = subMenu[0].buttons.filter(
    (button) => button.isOxxo !== false || !isOxxo,
  ).length;
  const step = Math.min(Math.max(1, currentStep), totalSteps);

  return (
    <div className="w-full bg-gray-100 my-4 px-4 py-3 shadow-[0_2px_4px_-1px_rgba(0,0,0,0.5)]">
      <h2 className="text-gray-800 text-lg font-medium">
        Paso {step} de {totalSteps}
      </h2>
    </div>
  );
};
