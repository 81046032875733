export interface IDiagnosticoGeneral {
  nombre: string;
  posee: '' | 'si' | 'no';
  medicamento: string;
  noMedicamento: boolean;
  sexo: 'H' | 'M' | 'A';
  validado: boolean;
}

export interface IDiagnosticosGenerales {
  loaded: boolean;
  contenidoTabla: IDiagnosticoGeneral[];
}

export const diagnosticosGeneralesInitial: IDiagnosticosGenerales = {
  loaded: false,
  contenidoTabla: [],
};
