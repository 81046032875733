import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  StepConnector,
  StepIconProps,
  IconButton,
  Badge,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Input,
  Chip,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/bootstrap.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import { getMeMedico } from '@actions/me/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { setRequest } from '@actions/request/types';
import {
  getCatalogoEscuelaMedicina,
  getCatalogoEspecialiadadMedicina,
  getCatalogoAreaMedicina,
  getCatalogoIdiomasMedicina,
  getCatalogoNacionalidad,
} from '@utils/getCatalogos';
import {
  sendPerfilMedico,
  sendCarreraMedico,
  sendEspecialidadMedico,
  sendMaestriaMedico,
  sendDoctoradoMedico,
  sendExperienciaMedico,
} from '@utils/sendInfo';
import {
  getToken,
  getEmailMe,
  perfilMedicoDatosIdentificacion,
  perfilMedicoDatosCarrera,
  perfilMedicoDatosEspecialidad,
  perfilMedicoDatosMaestria,
  perfilMedicoDatosDoctorado,
  perfilMedicoDatosExperiencia,
} from '@utils/common';
import DeleteDialog from '@components/DeleteDialog';
import TxtEnriquecido from '@components/Comentarios/index';
import { validarFecha, validarFechaEsAnterior } from '@common/functions';
import { constDia, constMes, constAnio } from '@constants/fechas';
import {
  docIdLongitudMax,
  validacionDocIdOnChange,
  validacionEmail,
  validacionTelefono,
} from '@constants/validaciones';
import { ReactComponent as ChangePhotoIcon } from '@assets/icons/changePhoto.svg';
import {
  IFechaField,
  ICatalogosEstandar,
  ITextFieldEstandar,
  ICountryPhoneInput,
  ICatStrEstandar,
} from '@common/types';
import { RootState } from 'src/store';
import DialogPassword from '@components/ModalDialogs/DialogPassword/index';
import Previsualizar from './Previsualizar/index';
import useStyles from './styles';
import {
  IDatosId, IPosgradoObj, IExperiencia, IPerfilProps,
} from './types';

dayjs.locale('es');

const PerfilPaciente = (propsPapa: IPerfilProps) => {
  const { t } = useTranslation();
  const {
    setActiveStepMedico, activeStepMedico, perfilMostrar, setPerfilMostrar,
  } = propsPapa;
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico, nombre, email, pais, rol,
  } = useSelector((state: RootState) => state.Me);
  const me = useSelector((state: RootState) => state.Me);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anyoFur] = useState<number[]>(constAnio);
  const [steps] = useState([
    t('medico_perfil_identificacion'),
    t('medico_perfil_carrera'),
    t('medico_perfil_especialidad'),
    t('medico_perfil_maestria'),
    t('medico_perfil_doctorado'),
    t('medico_perfil_experiencia'),
  ]);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [activeStep, setActiveStep] = useState(0);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [iconLoad, setIconLoad] = useState<boolean>(false);
  const [objDatId, setObjDatId] = useState<IDatosId>({
    nombre: '',
    apellidoP: '',
    apellidoS: '',
    docId: '',
    sexo: '',
    fechaNac: {
      dia: '',
      mes: '',
      anio: '',
    },
    celular: {
      numero: '',
      codigo: '',
    },
    telefono: {
      numero: '',
      codigo: '',
    },
    email: '',
    emailLog: '',
    nacionalidad: {
      id: '',
      label: '',
    },
    id: 0,
  });
  const [objCarrera, setObjCarrera] = useState<IPosgradoObj>({
    id: -1,
    selector: { id: 0, label: '' },
    institucion: '',
    titulo: '',
    titulor: '',
    anio: '',
    cedula: '',
    edit: false,
  });
  const [objEspecialidad, setObjEspecialidad] = useState<IPosgradoObj>({
    id: -1,
    selector: { id: 0, label: '' },
    titulo: '',
    anio: '',
    cedula: '',
    institucion: '',
    edit: false,
  });
  const [objMaestria, setObjMaestria] = useState<IPosgradoObj>({
    id: -1,
    titulo: '',
    anio: '',
    cedula: '',
    institucion: '',
    edit: false,
  });
  const [objDoctorado, setObjDoctorado] = useState<IPosgradoObj>({
    id: -1,
    titulo: '',
    anio: '',
    cedula: '',
    institucion: '',
    edit: false,
  });
  const [objExperiencia, setObjExperiencia] = useState<IExperiencia>({
    id: -1,
    sobre: { entityMap: {}, blocks: [] },
    area: [],
    idiomas: [],
  });
  const [catNacionalidad, setCatNacionalidad] = useState<Array<ICatStrEstandar>>([]);
  const [catEscuelas, setCatEscuelas] = useState<ICatalogosEstandar[]>([]);
  const [catEspecialidades, setCatEspecialidades] = useState<ICatalogosEstandar[]>([]);
  const [arrIdioma, setArrIdioma] = useState<ICatStrEstandar[]>([]);
  const [arrArea, setArrArea] = useState<ICatStrEstandar[]>([]);
  const [arrayMaestria, setArrayMaestria] = useState<IPosgradoObj[]>([]);
  const [arrayDoctorado, setArrayDoctorado] = useState<IPosgradoObj[]>([]);
  const [arrayEspecialidad, setArrayEspecialidad] = useState<IPosgradoObj[]>([]);
  const [emailError, setEmailError] = useState<any>({ helperText: '', error: false });
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [idSel, setIdSel] = useState<number>(-1);
  const [idConteo, setIdConteo] = useState<number>(-1);
  const [docIdField, setDocIdField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [disableDocId, setDisableDocId] = useState<boolean>(false);
  // variable para bloquear el avance de la pantalla especialidad, maestria, doctorado
  const [blockNext, setBlockNext] = useState<boolean>(false);
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailError({ helperText: '', error: false });
    } else {
      setEmailError({ helperText: t('email_incorrecto'), error: true });
    }
  };
  const handleChangeSexo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjDatId({ ...objDatId, sexo: val });
  };
  const handleChangeCarrera = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjCarrera({ ...objCarrera, titulor: val, titulo: '' });
  };
  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const dataUpd = { ...objDatId };
    const valAdd = event.target.value as string;
    if (tipo === 'dia') {
      dataUpd.fechaNac.dia = valAdd;
    } else if (tipo === 'mes') {
      dataUpd.fechaNac.mes = valAdd;
    } else if (tipo === 'anio') {
      dataUpd.fechaNac.anio = valAdd;
    }
    setObjDatId(dataUpd);
  };
  const disabledPosgrado = () => {
    let valBol = true;
    if (activeStep === 3 && objMaestria.titulo.length > 0) {
      valBol = false;
    } else if (activeStep === 4 && objDoctorado.titulo.length > 0) {
      valBol = false;
    }
    return valBol;
  };
  const editElement = (inx: number) => {
    if (activeStep === 2) {
      setObjEspecialidad({ ...arrayEspecialidad[inx], edit: true });
    } else if (activeStep === 3) {
      setObjMaestria({ ...arrayMaestria[inx], edit: true });
    } else if (activeStep === 4) {
      setObjDoctorado({ ...arrayDoctorado[inx], edit: true });
    }
    setEditAlert(false);
    setIdSel(inx);
  };
  const deleteElelement = (inx: number) => {
    if (activeStep === 2) {
      const arrUpdate = [...arrayEspecialidad];
      arrUpdate.splice(inx, 1);
      setArrayEspecialidad(arrUpdate);
    } else if (activeStep === 3) {
      const arrUpdate = [...arrayMaestria];
      arrUpdate.splice(inx, 1);
      setArrayMaestria(arrUpdate);
    } else if (activeStep === 4) {
      const arrUpdate = [...arrayDoctorado];
      arrUpdate.splice(inx, 1);
      setArrayDoctorado(arrUpdate);
    }
    setDeleteAlert(false);
  };
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#1E40AF',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#1E40AF',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#1E40AF',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#1E40AF',
      zIndex: 1,
      fontSize: 18,
    },
  });
  const QontoStepIcon = (props: StepIconProps) => {
    const classes2 = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
      <div
        className={clsx(classes2.root, {
          [classes2.active]: active,
        })}
      >
        {completed ? <Check className={classes2.completed} /> : <div className={classes2.circle} />}
      </div>
    );
  };
  const handleSaveProfile = (moveForward: boolean = true) => {
    const updData = {
      ...objDatId,
      emailLog: email,
      celular: {
        ...objDatId.celular,
        codigo: objDatId.celular.codigo === 'NULL' ? '' : objDatId.celular.codigo,
      },
      telefono: {
        ...objDatId.telefono,
        codigo: objDatId.telefono.codigo === 'NULL' ? '' : objDatId.telefono.codigo,
      },
    };
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendPerfilMedico({ idMedico, datos: updData }),
        successFunction: () => {
          dispatch(getMeMedico(getEmailMe()));
          if (moveForward) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        },
      }),
    );
  };
  const handleNext = () => {
    setIdSel(-1);
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    if (activeStep === 0) {
      handleSaveProfile();
    } else if (activeStep === 1) {
      sendCarreraMedico({ idMedico, datos: objCarrera })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(getMeMedico(getEmailMe()));
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else if (activeStep === 2 && arrayEspecialidad.length >= 0) {
      sendEspecialidadMedico({ idMedico, datos: arrayEspecialidad })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            setArrayEspecialidad(result.data);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(getMeMedico(getEmailMe()));
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else if (activeStep === 3 && arrayMaestria.length >= 0) {
      sendMaestriaMedico({ idMedico, datos: arrayMaestria })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            setArrayMaestria(result.data);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(getMeMedico(getEmailMe()));
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else if (activeStep === 4 && arrayDoctorado.length >= 0) {
      sendDoctoradoMedico({ idMedico, datos: arrayDoctorado })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            setArrayDoctorado(result.data);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(getMeMedico(getEmailMe()));
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else if (activeStep === 5 && objExperiencia) {
      sendExperienciaMedico({ idMedico, datos: objExperiencia })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            setObjExperiencia(result.data);
            setActiveStepMedico(activeStepMedico + 1);
            dispatch(getMeMedico(getEmailMe()));
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setIdSel(-1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const disNextButton = () => {
    let isDisabled = false;
    if (
      activeStep === 0 &&
      (objDatId.nombre.length === 0 ||
        objDatId.apellidoP.length === 0 ||
        (pais === 'mx' && objDatId.docId.length < 18) ||
        (pais === 'uy' && objDatId.docId.length < 6) ||
        docIdField.error ||
        objDatId.sexo.length === 0 ||
        objDatId.fechaNac.dia.length === 0 ||
        objDatId.fechaNac.mes.length === 0 ||
        objDatId.nacionalidad.id.length === 0 ||
        objDatId.fechaNac.anio.length === 0 ||
        objDatId.celular.numero.length === 0 ||
        !validacionTelefono(objDatId.celular.numero, pais) ||
        objDatId.email.length === 0)
    ) {
      isDisabled = true;
    } else if (
      activeStep === 1 &&
      ((objCarrera.anio && objCarrera.anio.length === 0) ||
        (objCarrera.cedula && objCarrera.cedula.length === 0) ||
        objCarrera.selector?.id === 0 ||
        (objCarrera.selector?.id === 57 && objCarrera.institucion?.length === 0) ||
        objCarrera.titulor?.length === 0 ||
        (objCarrera.titulor === 'O' && objCarrera.titulo.length === 0))
    ) {
      isDisabled = true;
    } else if (blockNext && activeStep >= 2 && activeStep <= 4) {
      isDisabled = true;
    }
    return isDisabled;
  };
  const handleTxtButton = () => {
    let txt = '';
    if (activeStep === 2 && !objMaestria.edit) {
      txt = t('medico_perfil_es_agregar');
    } else if (activeStep === 2 && objMaestria.edit) {
      txt = t('medico_perfil_es_actualizar');
    } else if (activeStep === 3 && !objMaestria.edit) {
      txt = t('medico_perfil_mas_agregar');
    } else if (activeStep === 3 && objMaestria.edit) {
      txt = t('medico_perfil_mas_actualizar');
    } else if (activeStep === 4 && !objDoctorado.edit) {
      txt = t('medico_perfil_doc_agregar');
    } else if (activeStep === 4 && objDoctorado.edit) {
      txt = t('medico_perfil_doc_actualizar');
    }
    return txt;
  };
  const handleChangeTxt = (event: React.ChangeEvent<HTMLInputElement>, tipo: string) => {
    const val = event.target.value as string;
    const dataUpd = { ...objDatId };
    if (tipo === 'nombre') {
      dataUpd.nombre = val;
    } else if (tipo === 'apellidoP') {
      dataUpd.apellidoP = val;
    } else if (tipo === 'apellidoS') {
      dataUpd.apellidoS = val;
    } else if (tipo === 'docId') {
      if (validacionDocIdOnChange(val, pais)) {
        setDocIdField({ helperText: '', error: false });
        dataUpd.docId = val;
      } else {
        setDocIdField({ helperText: t(`doc_id_incorrecto_${pais}`), error: true });
      }
    } else if (tipo === 'email') {
      dataUpd.email = val;
    }
    setObjDatId(dataUpd);
  };
  const handleChangeNacionalidad = (_event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setObjDatId({ ...objDatId, nacionalidad: newValue });
    } else {
      setObjDatId({ ...objDatId, nacionalidad: { id: '', label: '' } });
    }
  };
  useEffect(() => {
    if (activeStep === 0 && catNacionalidad.length === 0) {
      getCatalogoNacionalidad().then((result: ICatStrEstandar[]) => {
        setCatNacionalidad(result);
      });
    } else if (activeStep === 1 && catEscuelas.length === 0) {
      getCatalogoEscuelaMedicina(idMedico).then((result: ICatalogosEstandar[]) => {
        setCatEscuelas(result);
      });
    } else if (activeStep === 2 && catEspecialidades.length === 0) {
      getCatalogoEspecialiadadMedicina().then((result: ICatalogosEstandar[]) => {
        setCatEspecialidades(result);
      });
    } else if (activeStep === 5 && (arrArea.length === 0 || arrIdioma.length === 0)) {
      getCatalogoAreaMedicina().then((result: ICatStrEstandar[]) => {
        setArrArea(result);
      });
      getCatalogoIdiomasMedicina().then((result: ICatStrEstandar[]) => {
        setArrIdioma(result);
      });
    }
  }, [activeStep]);
  useEffect(() => {
    if (nombre === '') {
      dispatch(getMeMedico(getEmailMe()));
    }
    if (objDatId.nombre === '' && email && email.length > 0) {
      fetch(perfilMedicoDatosIdentificacion(email), {
        method: 'GET',
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setObjDatId(result.data);
            if (result.data.docId.length === 18) {
              setDisableDocId(true);
            } else {
              setDisableDocId(false);
            }
          } else {
            setDisableDocId(false);
          }
        })
        .catch(() => {
          setDisableDocId(false);
          setObjDatId({ ...objDatId });
        });
    }
    if (idMedico > 0) {
      fetch(perfilMedicoDatosCarrera(idMedico.toString()), {
        method: 'GET',
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setObjCarrera(result.data);
          }
        })
        .catch(() => {
          setObjCarrera({ ...objCarrera });
        });
      fetch(perfilMedicoDatosEspecialidad(idMedico.toString()), {
        method: 'GET',
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setArrayEspecialidad(result.data);
          }
        })
        .catch(() => {
          setArrayEspecialidad([...arrayEspecialidad]);
        });
      fetch(perfilMedicoDatosMaestria(idMedico.toString()), {
        method: 'GET',
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setArrayMaestria(result.data);
          }
        })
        .catch(() => {
          setArrayMaestria([...arrayMaestria]);
        });
      fetch(perfilMedicoDatosDoctorado(idMedico.toString()), {
        method: 'GET',
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setArrayDoctorado(result.data);
          }
        })
        .catch(() => {
          setArrayDoctorado([...arrayDoctorado]);
        });
      fetch(perfilMedicoDatosExperiencia(idMedico.toString()), {
        method: 'GET',
        headers: { Authorization: `Bearer ${getToken()}` },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setObjExperiencia(result.data);
          }
        })
        .catch(() => {
          setObjExperiencia({ ...objExperiencia });
        });
    }
  }, [me]);
  useEffect(() => {
    const rdia = objDatId.fechaNac.dia;
    const rmes = objDatId.fechaNac.mes;
    const ranio = objDatId.fechaNac.anio;
    if (rdia !== '' && rmes !== '' && ranio !== '') {
      const objF = { ...fechaField };
      objF.error = false;
      objF.helperText = '';
      if (!validarFecha(ranio, rmes, rdia) || !validarFechaEsAnterior(ranio, rmes, rdia)) {
        objF.error = true;
        objF.helperText = t('fecha_invalida');
      }
      setFechaField(objF);
    }
  }, [objDatId.fechaNac.dia, objDatId.fechaNac.mes, objDatId.fechaNac.anio]);
  if (perfilMostrar) {
    return (
      <Previsualizar
        objDatId={objDatId}
        objCarrera={objCarrera}
        arrayEspecialidad={arrayEspecialidad}
        arrayMaestria={arrayMaestria}
        arrayDoctorado={arrayDoctorado}
        objExperiencia={objExperiencia}
        perfilMostrar={perfilMostrar}
        setPerfilMostrar={setPerfilMostrar}
      />
    );
  }
  return (
    <div className="p-4 bg-white">
      {rol.grupo === 'salud' && (
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('medico_perfil_eliminar')}
        callBackAceptar={() => deleteElelement(idSel)}
        callBackClose={() => {
          setIdSel(-1);
          setDeleteAlert(!deleteAlert);
        }}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('medico_perfil_editar')}
        callBackAceptar={() => editElement(idSel)}
        callBackClose={() => {
          setIdSel(-1);
          setEditAlert(!editAlert);
        }}
      />
      <DialogPassword
        open={showDialog}
        callBackClose={() => {
          setShowDialog(!showDialog);
        }}
      />
      {activeStep === 0 ? (
        <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
          {rol.grupo === 'salud' && (
            <div className="absolute top right-10">
              <Button
                color="primary"
                onClick={() => setPerfilMostrar(!perfilMostrar)}
                className={classes.noTextTranform}
                startIcon={<VisibilityIcon />}
              >
                {t('medico_perfil_identificacion_prev')}
              </Button>
            </div>
          )}
          <div className="flex flex-col justify-center">
            <h1 className="text-blue-500 font-normal m-2 text-center">
              {t('medico_perfil_identificacion')}
            </h1>
            <div className="p-0 pl-2">
              <div
                className={classes.root}
                onMouseOver={() => setIconLoad(true)}
                onFocus={() => undefined}
                onMouseOut={() => setIconLoad(false)}
                onBlur={() => undefined}
              >
                <label htmlFor="contained-button-file" className="m-0 font-normal">
                  {!idPaciente ? (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      classes={{ label: classes.iconButton }}
                    >
                      <Avatar className={classes.large}>
                        <div className="m-0 text-xs">{t('agregar-foto')}</div>
                      </Avatar>
                    </IconButton>
                  ) : (
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      {iconLoad ? (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          badgeContent={<ChangePhotoIcon />}
                        >
                          <Avatar
                            alt="Usuario"
                            style={{ backgroundColor: '#1D4ED8' }}
                            className={classes.large}
                          />
                        </Badge>
                      ) : (
                        <Avatar
                          alt="Usuario"
                          style={{ backgroundColor: '#1D4ED8' }}
                          className={classes.large}
                        />
                      )}
                    </IconButton>
                  )}
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nombre(s)')}*`}</h4>
              <TextField
                variant="outlined"
                type="nombre"
                value={objDatId.nombre}
                onChange={(e: any) => handleChangeTxt(e, 'nombre')}
                fullWidth
                inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                placeholder={t('especifica')}
              />
            </div>
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('primer_apellido')}*`}</h4>
              <TextField
                variant="outlined"
                type="apellidoP"
                value={objDatId.apellidoP}
                onChange={(e: any) => handleChangeTxt(e, 'apellidoP')}
                fullWidth
                inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                placeholder={t('especifica')}
              />
            </div>
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('segundo_apellido')}</h4>
              <TextField
                variant="outlined"
                type="apellidoS"
                value={objDatId.apellidoS}
                onChange={(e: any) => handleChangeTxt(e, 'apellidoS')}
                fullWidth
                inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                placeholder={t('especifica')}
              />
            </div>
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t(`doc_id_${pais}`)}*`}</h4>
              <TextField
                variant="outlined"
                type="docId"
                value={objDatId.docId}
                onChange={(e: any) => handleChangeTxt(e, 'docId')}
                fullWidth
                inputProps={{
                  maxLength: docIdLongitudMax(pais),
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                  style: { textTransform: objDatId.docId.length ? 'uppercase' : 'none' },
                }}
                placeholder={t(`especifica_debe_contener_n_caracteres_${pais}`)}
                error={docIdField.error}
                helperText={docIdField.helperText}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                disabled={disableDocId}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 mt-4">
            <div>
              <h4 className="m-0 mb-3 text-gray-500 font-normal">{`${t('sexo')}*`}</h4>
              <RadioGroup row name="sexo" value={objDatId.sexo} onChange={handleChangeSexo}>
                <FormControlLabel
                  value="H"
                  control={<Radio color="primary" />}
                  label={t('hombre')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="M"
                  control={<Radio color="primary" />}
                  label={t('mujer')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            <div className="w-full grid grid-cols-3 gap-2">
              <h4 className="text-gray-500 font-normal m-0 col-span-3">
                {`${t('fecha_nacimiento')}*`}
              </h4>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-dia">{t('dia')}</InputLabel>
                <Select
                  labelId="label-dia"
                  id="select-dia"
                  value={objDatId.fechaNac.dia}
                  onChange={(e) => handleChangeDate(e, 'dia')}
                  label={t('dia')}
                  error={fechaField.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {constDia.map((diaSingle) => (
                    <MenuItem value={diaSingle.toString()} key={diaSingle}>
                      {diaSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-mes">{t('mes')}</InputLabel>
                <Select
                  labelId="label-mes"
                  id="select-mes"
                  value={objDatId.fechaNac.mes}
                  onChange={(e) => handleChangeDate(e, 'mes')}
                  label={t('mes')}
                  error={fechaField.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {constMes.map((mesSingle, indx) => (
                    <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                      {mesSingle.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className={classes.absoluteBottom}>
                  {fechaField.helperText}
                </FormHelperText>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={objDatId.fechaNac.anio}
                  onChange={(e) => handleChangeDate(e, 'anio')}
                  label={t('anio')}
                  error={fechaField.error}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {anyoFur.map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle.toString()}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <h4 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_celular')}*`}</h4>
              <PhoneInput
                preferredCountries={[pais, 'us']}
                country={pais}
                localization={es}
                enableSearch
                searchPlaceholder={t('buscar')}
                searchNotFound={t('sin_resultados')}
                inputStyle={{ width: '100%' }}
                value={`${objDatId.celular.codigo}${objDatId.celular.numero}`}
                onChange={(value, country: ICountryPhoneInput) => {
                  const { dialCode } = country;
                  const nuevoCel = value.slice(dialCode.length);
                  const dataUpd = { ...objDatId };
                  dataUpd.celular.numero = nuevoCel;
                  dataUpd.celular.codigo = country.dialCode;
                  setObjDatId(dataUpd);
                }}
              />
            </div>
            <div>
              <h4 className="text-gray-500 font-normal m-0 mb-2">{t('telefono_fijo')}</h4>
              <PhoneInput
                preferredCountries={[pais, 'us']}
                country={pais}
                localization={es}
                enableSearch
                searchPlaceholder={t('buscar')}
                searchNotFound={t('sin_resultados')}
                inputStyle={{ width: '100%' }}
                value={`${objDatId.telefono.codigo}${objDatId.telefono.numero}`}
                onChange={(value, country: ICountryPhoneInput) => {
                  const { dialCode } = country;
                  const nuevoCel = value.slice(dialCode.length);
                  const dataUpd = { ...objDatId };
                  dataUpd.telefono.numero = nuevoCel;
                  dataUpd.telefono.codigo = country.dialCode;
                  setObjDatId(dataUpd);
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 pt-2">
            <div className="col-span-2">
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('email')}*`}</h4>
              <TextField
                variant="outlined"
                type="email"
                value={objDatId.email}
                placeholder={t('especifica')}
                onChange={(e: any) => handleChangeTxt(e, 'email')}
                fullWidth
                error={emailError.error}
                helperText={emailError.helperText}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                inputProps={{ maxLength: 100, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                onBlur={handleBlurEmail}
                disabled
              />
            </div>
            <div className="flex items-end">
              <Button
                color="primary"
                onClick={() => {
                  setShowDialog(!showDialog);
                }}
                className={classes.noTextTranform}
                startIcon={<LockIcon />}
              >
                {t('medico_perfil_identificacion_cambiar')}
              </Button>
            </div>
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nacionalidad')}*`}</h4>
              <Autocomplete
                options={catNacionalidad}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={objDatId.nacionalidad}
                onChange={(e, nV) => handleChangeNacionalidad(e, nV)}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
          </div>
          <div className="pt-4">
            <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
          </div>
        </div>
      ) : null}
      {activeStep === 1 && rol.tipo === 'medico' ? (
        <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
          <div className="absolute top right-10">
            <Button
              color="primary"
              onClick={() => setPerfilMostrar(!perfilMostrar)}
              className={classes.noTextTranform}
              startIcon={<VisibilityIcon />}
            >
              {t('medico_perfil_identificacion_prev')}
            </Button>
          </div>
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {t('medico_perfil_carrera')}
          </h1>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">
              {`${t('medico_perfil_carrera_egresado')}*`}
            </h4>
            <div className="grid grid-cols-2 gap-4">
              <Autocomplete
                options={catEscuelas}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={objCarrera.selector}
                onChange={(e, nV) => {
                  const upObj = { ...objCarrera };
                  if (nV) {
                    upObj.selector = nV;
                  } else {
                    upObj.selector = { id: 0, label: '' };
                  }
                  upObj.institucion = '';
                  setObjCarrera(upObj);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
              {objCarrera.selector?.label === 'Otro' ? (
                <TextField
                  variant="outlined"
                  type="institucion"
                  value={objCarrera.institucion}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objCarrera };
                    updValores.institucion = val;
                    setObjCarrera(updValores);
                  }}
                  placeholder={`${t('especifica')}*`}
                  fullWidth
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                  inputProps={{ maxLength: 80, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 pb-4">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('anio')}*`}</h4>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={objCarrera.anio}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objCarrera };
                    updValores.anio = val;
                    setObjCarrera(updValores);
                  }}
                  label={t('anio')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {anyoFur.map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle.toString()}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-3">
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {`${t('medico_perfil_cedulapp')}*`}
              </h4>
              <TextField
                variant="outlined"
                type="cedula"
                value={objCarrera.cedula}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const val = event.target.value as string;
                  const updValores = { ...objCarrera };
                  updValores.cedula = val;
                  setObjCarrera(updValores);
                }}
                fullWidth
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 20 }}
                placeholder={t('especifica')}
              />
            </div>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0">{`${t('medico_perfil_titulo')}*`}</h4>
            <div className="pb-2">
              <RadioGroup
                row
                name="titulos"
                value={objCarrera.titulor}
                onChange={handleChangeCarrera}
              >
                <FormControlLabel
                  value="LM"
                  control={<Radio color="primary" />}
                  label={t('medico_perfil_licenciadom')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="MCP"
                  control={<Radio color="primary" />}
                  label={t('medico_perfil_medicocp')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="MC"
                  control={<Radio color="primary" />}
                  label={t('medico_perfil_medicoc')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="MG"
                  control={<Radio color="primary" />}
                  label={t('medico_perfil_medicog')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="O"
                  control={<Radio color="primary" />}
                  label={t('medico_perfil_otro')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            {objCarrera.titulor === 'O' ? (
              <TextField
                variant="outlined"
                type="titulo"
                value={objCarrera.titulo}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const val = event.target.value as string;
                  const updValores = { ...objCarrera };
                  updValores.titulo = val;
                  setObjCarrera(updValores);
                }}
                fullWidth
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
                placeholder={t('especifica')}
              />
            ) : null}
          </div>
          <div className="pt-4">
            <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
          </div>
        </div>
      ) : null}
      {activeStep === 1 && rol.tipo === 'enfermero' ? (
        <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
          <div className="absolute top right-10">
            <Button
              color="primary"
              onClick={() => setPerfilMostrar(!perfilMostrar)}
              className={classes.noTextTranform}
              startIcon={<VisibilityIcon />}
            >
              {t('medico_perfil_identificacion_prev')}
            </Button>
          </div>
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {t('medico_perfil_carrera')}
          </h1>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">
              {`${t('enfermera_perfil_carrera_egresado')}:*`}
            </h4>
            <div className="grid grid-cols-2 gap-4">
              <Autocomplete
                options={catEscuelas}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={objCarrera.selector}
                onChange={(e, nV) => {
                  const upObj = { ...objCarrera };
                  if (nV) {
                    upObj.selector = nV;
                  } else {
                    upObj.selector = { id: 0, label: '' };
                  }
                  upObj.institucion = '';
                  setObjCarrera(upObj);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
              {objCarrera.selector?.id === 57 ? (
                <TextField
                  variant="outlined"
                  type="institucion"
                  value={objCarrera.institucion}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objCarrera };
                    updValores.institucion = val;
                    setObjCarrera(updValores);
                  }}
                  placeholder={`${t('especifica')}*`}
                  fullWidth
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                  inputProps={{ maxLength: 80, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 pb-4">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('anio')}*`}</h4>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={objCarrera.anio}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objCarrera };
                    updValores.anio = val;
                    setObjCarrera(updValores);
                  }}
                  label={t('anio')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {anyoFur.map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle.toString()}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-3">
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {`${t('enfermera_perfil_cedulapp')}*`}
              </h4>
              <TextField
                variant="outlined"
                type="cedula"
                value={objCarrera.cedula}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const val = event.target.value as string;
                  const updValores = { ...objCarrera };
                  updValores.cedula = val;
                  setObjCarrera(updValores);
                }}
                fullWidth
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 20 }}
                placeholder={t('especifica')}
              />
            </div>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal m-0">{`${t('enfermera_perfil_titulo')}*`}</h4>
            <div className="pb-2">
              <RadioGroup
                row
                name="titulos"
                value={objCarrera.titulor}
                onChange={handleChangeCarrera}
              >
                <FormControlLabel
                  value="EG"
                  control={<Radio color="primary" />}
                  label={t('enfermera_perfil_eg')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="TE"
                  control={<Radio color="primary" />}
                  label={t('enfermera_perfil_te')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="AE"
                  control={<Radio color="primary" />}
                  label={t('enfermera_perfil_ae')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="O"
                  control={<Radio color="primary" />}
                  label={t('enfermera_perfil_o')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            {objCarrera.titulor === 'O' ? (
              <TextField
                variant="outlined"
                type="titulo"
                value={objCarrera.titulo}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const val = event.target.value as string;
                  const updValores = { ...objCarrera };
                  updValores.titulo = val;
                  setObjCarrera(updValores);
                }}
                fullWidth
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
                placeholder={t('especifica')}
              />
            ) : null}
          </div>
          <div className="pt-4">
            <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
          </div>
        </div>
      ) : null}
      {activeStep === 2 && rol.tipo === 'medico' ? (
        <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
          <div className="absolute top right-10">
            <Button
              color="primary"
              onClick={() => setPerfilMostrar(!perfilMostrar)}
              className={classes.noTextTranform}
              startIcon={<VisibilityIcon />}
            >
              {t('medico_perfil_identificacion_prev')}
            </Button>
          </div>
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {t('medico_perfil_especialidad')}
          </h1>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('medico_perfil_tituloe')}</h4>
            <div className="grid grid-cols-2 gap-4">
              <Autocomplete
                id="combo-box-duracion"
                options={catEspecialidades}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={objEspecialidad.selector}
                onChange={(e, nV) => {
                  const upObj = { ...objEspecialidad };
                  if (nV) {
                    upObj.selector = nV;
                  } else {
                    upObj.selector = { id: 0, label: '' };
                  }
                  upObj.titulo = '';
                  setObjEspecialidad(upObj);
                  setBlockNext(true);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
              {objEspecialidad.selector && objEspecialidad.selector.id === 124 ? (
                <TextField
                  variant="outlined"
                  type="titulo"
                  value={objEspecialidad.titulo}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objEspecialidad };
                    updValores.titulo = val;
                    setObjEspecialidad(updValores);
                    setBlockNext(true);
                  }}
                  placeholder={t('especifica')}
                  fullWidth
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                  inputProps={{ maxLength: 80, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 pb-4">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('anio')}*`}</h4>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={objEspecialidad.anio}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objEspecialidad };
                    updValores.anio = val;
                    setObjEspecialidad(updValores);
                    setBlockNext(true);
                  }}
                  label={t('anio')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {anyoFur.map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle.toString()}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-3">
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {`${t('medico_perfil_cedulae')}*`}
              </h4>
              <TextField
                variant="outlined"
                type="cedula"
                value={objEspecialidad.cedula}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const val = event.target.value as string;
                  const updValores = { ...objEspecialidad };
                  updValores.cedula = val;
                  setObjEspecialidad(updValores);
                  setBlockNext(true);
                }}
                fullWidth
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 20 }}
                placeholder={t('especifica')}
              />
            </div>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">
              {`${t('medico_perfil_institucion')}*`}
            </h4>
            <TextField
              variant="outlined"
              type="institucion"
              value={objEspecialidad.institucion}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const val = event.target.value as string;
                const updValores = { ...objEspecialidad };
                updValores.institucion = val;
                setObjEspecialidad(updValores);
                setBlockNext(true);
              }}
              fullWidth
              inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
              placeholder={t('especifica')}
            />
          </div>
          <div className="flex justify-between items-center pt-2">
            <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
            <div>
              <Button
                color="primary"
                onClick={() => {
                  setObjEspecialidad({
                    id: -1,
                    selector: { id: 0, label: '' },
                    titulo: '',
                    anio: '',
                    cedula: '',
                    institucion: '',
                    edit: false,
                  });
                  setBlockNext(false);
                }}
                className={classes.noTextTranform}
                startIcon={<DeleteIcon />}
                disabled={!blockNext}
              >
                {t('medico_perfil_limpiar_campos')}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  const val = [...arrayEspecialidad];
                  if (objEspecialidad.edit) {
                    val[val.findIndex((x) => x.id === objEspecialidad.id)] = {
                      ...objEspecialidad,
                    };
                  } else {
                    val.push(objEspecialidad);
                  }
                  setArrayEspecialidad(val);
                  setObjEspecialidad({
                    id: idConteo - 1,
                    selector: { id: 0, label: '' },
                    titulo: '',
                    anio: '',
                    cedula: '',
                    institucion: '',
                    edit: false,
                  });
                  setIdConteo(idConteo - 1);
                  setBlockNext(false);
                }}
                className={classes.noTextTranform}
                startIcon={<AddCircleOutlineIcon />}
                disabled={
                  !blockNext ||
                  objEspecialidad.selector?.id === 0 ||
                  (objEspecialidad.selector?.id === 124 && objEspecialidad.titulo.length === 0) ||
                  objEspecialidad.anio.length === 0 ||
                  objEspecialidad.cedula.length === 0
                }
              >
                {handleTxtButton()}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {activeStep === 2 && rol.tipo === 'enfermero' ? (
        <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
          <div className="absolute top right-10">
            <Button
              color="primary"
              onClick={() => setPerfilMostrar(!perfilMostrar)}
              className={classes.noTextTranform}
              startIcon={<VisibilityIcon />}
            >
              {t('medico_perfil_identificacion_prev')}
            </Button>
          </div>
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {t('medico_perfil_especialidad')}
          </h1>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('medico_perfil_tituloe')}</h4>
            <div className="grid grid-cols-2 gap-4">
              <Autocomplete
                id="combo-box-duracion"
                options={catEspecialidades}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={objEspecialidad.selector}
                onChange={(e, nV) => {
                  const upObj = { ...objEspecialidad };
                  if (nV) {
                    upObj.selector = nV;
                  } else {
                    upObj.selector = { id: 0, label: '' };
                  }
                  upObj.titulo = '';
                  setObjEspecialidad(upObj);
                  setBlockNext(true);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
              {objEspecialidad.selector && objEspecialidad.selector.id === 124 ? (
                <TextField
                  variant="outlined"
                  type="titulo"
                  value={objEspecialidad.titulo}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objEspecialidad };
                    updValores.titulo = val;
                    setObjEspecialidad(updValores);
                    setBlockNext(true);
                  }}
                  placeholder={t('especifica')}
                  fullWidth
                  FormHelperTextProps={{ className: classes.absoluteBottom }}
                  inputProps={{ maxLength: 80, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                />
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 pb-4">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('anio')}*`}</h4>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={objEspecialidad.anio}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const val = event.target.value as string;
                    const updValores = { ...objEspecialidad };
                    updValores.anio = val;
                    setObjEspecialidad(updValores);
                    setBlockNext(true);
                  }}
                  label={t('anio')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {anyoFur.map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle.toString()}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-3">
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {`${t('medico_perfil_cedulae')}*`}
              </h4>
              <TextField
                variant="outlined"
                type="cedula"
                value={objEspecialidad.cedula}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const val = event.target.value as string;
                  const updValores = { ...objEspecialidad };
                  updValores.cedula = val;
                  setObjEspecialidad(updValores);
                  setBlockNext(true);
                }}
                fullWidth
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 20 }}
                placeholder={t('especifica')}
              />
            </div>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">
              {`${t('enfermera_perfil_institucion')}*`}
            </h4>
            <TextField
              variant="outlined"
              type="institucion"
              value={objEspecialidad.institucion}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const val = event.target.value as string;
                const updValores = { ...objEspecialidad };
                updValores.institucion = val;
                setObjEspecialidad(updValores);
                setBlockNext(true);
              }}
              fullWidth
              inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
              placeholder={t('especifica')}
            />
          </div>
          <div className="flex justify-between items-center pt-2">
            <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
            <div>
              <Button
                color="primary"
                onClick={() => {
                  setObjEspecialidad({
                    id: -1,
                    selector: { id: 0, label: '' },
                    titulo: '',
                    anio: '',
                    cedula: '',
                    institucion: '',
                    edit: false,
                  });
                  setBlockNext(false);
                }}
                className={classes.noTextTranform}
                startIcon={<DeleteIcon />}
                disabled={!blockNext}
              >
                {t('medico_perfil_limpiar_campos')}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  const val = [...arrayEspecialidad];
                  if (objEspecialidad.edit) {
                    val[val.findIndex((x) => x.id === objEspecialidad.id)] = {
                      ...objEspecialidad,
                    };
                  } else {
                    val.push(objEspecialidad);
                  }
                  setArrayEspecialidad(val);
                  setObjEspecialidad({
                    id: idConteo - 1,
                    selector: { id: 0, label: '' },
                    titulo: '',
                    anio: '',
                    cedula: '',
                    institucion: '',
                    edit: false,
                  });
                  setIdConteo(idConteo - 1);
                  setBlockNext(false);
                }}
                className={classes.noTextTranform}
                startIcon={<AddCircleOutlineIcon />}
                disabled={
                  !blockNext ||
                  objEspecialidad.selector?.id === 0 ||
                  (objEspecialidad.selector?.id === 124 && objEspecialidad.titulo.length === 0) ||
                  objEspecialidad.anio.length === 0 ||
                  objEspecialidad.cedula.length === 0
                }
              >
                {handleTxtButton()}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {activeStep === 3 || activeStep === 4 ? (
        <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
          <div className="absolute top right-10">
            <Button
              color="primary"
              onClick={() => setPerfilMostrar(!perfilMostrar)}
              className={classes.noTextTranform}
              startIcon={<VisibilityIcon />}
            >
              {t('medico_perfil_identificacion_prev')}
            </Button>
          </div>
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {activeStep === 3 ? t('medico_perfil_maestria') : t('medico_perfil_doctorado')}
          </h1>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">
              {activeStep === 3 ? t('medico_perfil_titulom') : t('medico_perfil_titulod')}
            </h4>
            <TextField
              variant="outlined"
              type="titulo"
              value={activeStep === 3 ? objMaestria.titulo : objDoctorado.titulo}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const val = event.target.value as string;
                const updValores = activeStep === 3 ? { ...objMaestria } : { ...objDoctorado };
                updValores.titulo = val;
                if (activeStep === 3) {
                  setObjMaestria(updValores);
                } else if (activeStep === 4) {
                  setObjDoctorado(updValores);
                }
                setBlockNext(true);
              }}
              placeholder={t('especifica')}
              fullWidth
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              inputProps={{ maxLength: 80, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            />
          </div>
          <div className="grid grid-cols-12 gap-4 pb-4">
            <div>
              <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('anio')}</h4>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label-año">{t('anio')}</InputLabel>
                <Select
                  labelId="label-año"
                  id="select-año"
                  value={activeStep === 3 ? objMaestria.anio : objDoctorado.anio}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const val = event.target.value as string;
                    const updValores = activeStep === 3 ? { ...objMaestria } : { ...objDoctorado };
                    updValores.anio = val;
                    if (activeStep === 3) {
                      setObjMaestria(updValores);
                    } else if (activeStep === 4) {
                      setObjDoctorado(updValores);
                    }
                    setBlockNext(true);
                  }}
                  label={t('anio')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {anyoFur.map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle.toString()}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-span-3">
              <h4 className="text-gray-500 font-normal mt-0 mb-2">
                {activeStep === 3 ? t('medico_perfil_cedulam') : t('medico_perfil_cedulad')}
              </h4>
              <TextField
                variant="outlined"
                type="cedula"
                value={activeStep === 3 ? objMaestria.cedula : objDoctorado.cedula}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const val = event.target.value as string;
                  const updValores = activeStep === 3 ? { ...objMaestria } : { ...objDoctorado };
                  updValores.cedula = val;
                  if (activeStep === 3) {
                    setObjMaestria(updValores);
                  } else if (activeStep === 4) {
                    setObjDoctorado(updValores);
                  }
                  setBlockNext(true);
                }}
                fullWidth
                inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 20 }}
                placeholder={t('especifica')}
              />
            </div>
          </div>
          <div>
            <h4 className="text-gray-500 font-normal mt-0 mb-2">
              {t('medico_perfil_institucion')}
            </h4>
            <TextField
              variant="outlined"
              type="institucion"
              value={activeStep === 3 ? objMaestria.institucion : objDoctorado.institucion}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const val = event.target.value as string;
                const updValores = activeStep === 3 ? { ...objMaestria } : { ...objDoctorado };
                updValores.institucion = val;
                if (activeStep === 3) {
                  setObjMaestria(updValores);
                } else if (activeStep === 4) {
                  setObjDoctorado(updValores);
                }
                setBlockNext(true);
              }}
              fullWidth
              inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
              placeholder={t('especifica')}
            />
          </div>
          <div className="flex justify-between items-center pt-2">
            <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
            <div>
              <Button
                color="primary"
                onClick={() => {
                  if (activeStep === 3) {
                    setObjMaestria({
                      id: idConteo - 1,
                      titulo: '',
                      anio: '',
                      cedula: '',
                      institucion: '',
                      edit: false,
                    });
                  } else if (activeStep === 4) {
                    setObjDoctorado({
                      id: idConteo - 1,
                      titulo: '',
                      anio: '',
                      cedula: '',
                      institucion: '',
                      edit: false,
                    });
                  }
                  setBlockNext(false);
                }}
                className={classes.noTextTranform}
                startIcon={<DeleteIcon />}
                disabled={!blockNext}
              >
                {t('medico_perfil_limpiar_campos')}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (activeStep === 3) {
                    const val = [...arrayMaestria];
                    if (objMaestria.edit) {
                      val[val.findIndex((x) => x.id === objMaestria.id)] = {
                        ...objMaestria,
                      };
                    } else {
                      val.push(objMaestria);
                    }
                    setArrayMaestria(val);
                    setObjMaestria({
                      id: idConteo - 1,
                      titulo: '',
                      anio: '',
                      cedula: '',
                      institucion: '',
                      edit: false,
                    });
                  } else if (activeStep === 4) {
                    const val = [...arrayDoctorado];
                    if (objDoctorado.edit) {
                      val[val.findIndex((x) => x.id === objDoctorado.id)] = {
                        ...objDoctorado,
                      };
                    } else {
                      val.push(objDoctorado);
                    }
                    setArrayDoctorado(val);
                    setObjDoctorado({
                      id: idConteo - 1,
                      titulo: '',
                      anio: '',
                      cedula: '',
                      institucion: '',
                      edit: false,
                    });
                  }
                  setIdConteo(idConteo - 1);
                  setBlockNext(false);
                }}
                className={classes.noTextTranform}
                startIcon={<AddCircleOutlineIcon />}
                disabled={disabledPosgrado()}
              >
                {handleTxtButton()}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {activeStep === 5 ? (
        <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
          <div className="absolute top right-10">
            <Button
              color="primary"
              onClick={() => setPerfilMostrar(!perfilMostrar)}
              className={classes.noTextTranform}
              startIcon={<VisibilityIcon />}
            >
              {t('medico_perfil_identificacion_prev')}
            </Button>
          </div>
          <h1 className="text-blue-500 font-normal m-2 text-center">
            {t('medico_perfil_experiencia')}
          </h1>
          <h4 className="text-gray-500 font-normal mt-0 mb-2 text-center">
            {`(${t('medico_perfil_experiencia_txt')})`}
          </h4>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('medico_sobre_mi')}</h4>
            <TxtEnriquecido
              comentarios={objExperiencia.sobre}
              setComentarios={(contentState: RawDraftContentState) => {
                const oldVal = { ...objExperiencia };
                oldVal.sobre = contentState;
                setObjExperiencia(oldVal);
              }}
              rows={4}
              maxLength={400}
              placeholder=""
              lang="es"
              disabled={false}
            />
          </div>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('medico_area')}</h4>
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{t('selecciona')}</InputLabel>
                <Select
                  label={t('selecciona')}
                  multiple
                  value={objExperiencia.area}
                  onChange={(event) => {
                    const valXp = { ...objExperiencia };
                    valXp.area = event.target.value as string[];
                    setObjExperiencia(valXp);
                  }}
                  input={<Input />}
                  renderValue={(selected) => (
                    <div className="flex flex-wrap">
                      {(selected as string[]).map((value, inx) => (
                        <Chip
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => {
                            const valChip = [...objExperiencia.area];
                            if (inx !== 1) {
                              valChip.splice(inx, 1);
                              setObjExperiencia({ ...objExperiencia, area: valChip });
                            }
                          }}
                          key={value}
                          label={value}
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </div>
                  )}
                >
                  {arrArea.map((valor, iny) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={String(iny)} value={valor.label}>
                      {valor.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
          </div>
          <div className="pb-4">
            <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('medico_idiomas')}</h4>
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">{t('selecciona')}</InputLabel>
                <Select
                  fullWidth
                  labelId="label-lista"
                  label={t('selecciona')}
                  id="select-multiple-lista"
                  multiple
                  value={objExperiencia.idiomas}
                  onChange={(event) => {
                    const valXp = { ...objExperiencia };
                    valXp.idiomas = event.target.value as string[];
                    setObjExperiencia(valXp);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className="flex flex-wrap">
                      {(selected as string[]).map((value, inx) => (
                        <Chip
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => {
                            const valChip = [...objExperiencia.idiomas];
                            if (inx !== 1) {
                              valChip.splice(inx, 1);
                              setObjExperiencia({ ...objExperiencia, idiomas: valChip });
                            }
                          }}
                          key={value}
                          label={value}
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </div>
                  )}
                >
                  {arrIdioma.map((valor, iny) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={String(iny)} value={valor.label}>
                      {valor.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {activeStep === 2 && arrayEspecialidad.length > 0 ? (
        <>
          <div className="pt-4 text-right border-solid border-0 border-b-2 border-gray-300" />
          <h1 className="text-blue-500 font-normal m-2 text-left">{t('medico_perfil_le')}</h1>
        </>
      ) : null}
      {activeStep === 2 &&
        arrayEspecialidad.map((val, inx) => (
          <div
            key={`t-${String(inx)}`}
            className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative"
          >
            <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
              <h2 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-900 pb-1">
                {val.selector?.id === 124 ? val.titulo : val.selector?.label}
              </h2>
              <h4 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('anio')}: ${val.anio}`}
              </h4>
              <h3 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('medico_perfil_institucion')}: ${val.institucion}`}
              </h3>
              <h3 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('medico_perfil_lcedulae')}: ${val.cedula}`}
              </h3>
            </div>
            <div className="min-w-max self-center">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setIdSel(inx);
                  if (
                    objEspecialidad.titulo.length > 0 ||
                    objEspecialidad.anio.length > 0 ||
                    objEspecialidad.cedula.length > 0 ||
                    (objEspecialidad.institucion && objEspecialidad.institucion.length > 0)
                  ) {
                    setEditAlert(true);
                  } else {
                    editElement(inx);
                  }
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  setIdSel(inx);
                  setDeleteAlert(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      {(activeStep === 3 && arrayMaestria.length > 0) ||
      (activeStep === 4 && arrayDoctorado.length > 0) ? (
        <>
          <div className="pt-4 text-right border-solid border-0 border-b-2 border-gray-300" />
          <h1 className="text-blue-600 font-normal m-2 text-left">
            {activeStep === 3 ? t('medico_perfil_lm') : t('medico_perfil_ld')}
          </h1>
        </>
        ) : null}
      {activeStep === 3 &&
        arrayMaestria.map((val, inx) => (
          <div
            key={`t-${String(inx)}`}
            className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative"
          >
            <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
              <h2 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-900 pb-1">
                {val.titulo}
              </h2>
              <h4 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('anio')}: ${val.anio}`}
              </h4>
              <h3 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('medico_perfil_institucion')}: ${val.institucion}`}
              </h3>
              <h3 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('medico_perfil_lcedulam')}: ${val.cedula}`}
              </h3>
            </div>
            <div className="min-w-max self-center">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setIdSel(inx);
                  if (
                    objMaestria.titulo.length > 0 ||
                    objMaestria.anio.length > 0 ||
                    objMaestria.cedula.length > 0 ||
                    (objMaestria.institucion && objMaestria.institucion.length > 0)
                  ) {
                    setEditAlert(true);
                  } else {
                    editElement(inx);
                  }
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  setIdSel(inx);
                  setDeleteAlert(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      {activeStep === 4 &&
        arrayDoctorado.map((val, inx) => (
          <div
            key={`t-${String(inx)}`}
            className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative"
          >
            <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
              <h2 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-900 pb-1">
                {val.titulo}
              </h2>
              <h4 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('anio')}: ${val.anio}`}
              </h4>
              <h3 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('medico_perfil_institucion')}: ${val.institucion}`}
              </h3>
              <h3 className="font-normal text-gray-500 m-0 mr-4 pb-1">
                {`${t('medico_perfil_lcedulam')}: ${val.cedula}`}
              </h3>
            </div>
            <div className="min-w-max self-center">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setIdSel(inx);
                  if (
                    objDoctorado.titulo.length > 0 ||
                    objDoctorado.anio.length > 0 ||
                    objDoctorado.cedula.length > 0 ||
                    (objDoctorado.institucion && objDoctorado.institucion.length > 0)
                  ) {
                    setEditAlert(true);
                  } else {
                    editElement(inx);
                  }
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  setIdSel(inx);
                  setDeleteAlert(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      <div className="py-4 text-right">
        {rol.grupo === 'salud' ? (
          <>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              {t('atras')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={disNextButton()}
            >
              {t('guardar_continuar')}
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={() => handleSaveProfile(false)}>
            {t('guardar')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PerfilPaciente;
