import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { capitalize, stringMes } from '@common/functions';
import ViewComentarios from '@components/Comentarios/view';
import { RootState } from 'src/store';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import DocumentosCargadosListado from '@components/Archivos/DocumentosCargadosListado';
import { IListaTransfusionesProps } from './types';

function ListaTransfusiones({
  transfusion,
  index,
  setEditAlert,
  setDeleteAlert,
  idBD,
  setIndexToUpdate,
  setIndexToDelete,
  editTransfusion,
  // variables para prender o no la alerta de edición
  diagnosticoRelacionadoVerificar,
  motivoTransfusionVerificar,
  fechaVerificar,
  componenteVerificar,
  comentariosVerificar,
  setSelArchivo,
  verArchivo,
  eliminarArchivo,
}: IListaTransfusionesProps) {
  const { t } = useTranslation();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    idBD: indxDB,
    diagnosticoRelacionadoALaTransfusion,
    // otroDiagnosticoRelacionadoALaTransfusion,
    motivoTransfusion,
    fecha,
    componente,
    otroComponente,
    comentarios,
    archivos,
  } = transfusion;

  const handleEditTransfusion = () => {
    if (
      diagnosticoRelacionadoVerificar.label ||
      motivoTransfusionVerificar ||
      fechaVerificar.anio ||
      componenteVerificar ||
      comentariosVerificar.blocks.length
    ) {
      setIndexToUpdate(index);
      setEditAlert(true);
    } else {
      setIndexToUpdate(index);
      editTransfusion(index);
    }
  };

  const handleDeleteTransfusion = () => {
    setIndexToDelete(index);
    setDeleteAlert(true);
  };

  const seleccionarArchivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setSelArchivo({ archivo: event.target.files[0], idLista: indxDB });
    } else {
      setSelArchivo(null);
    }
  };

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
        <h2 className="m-0 overflow-ellipsis overflow-hidden text-gray-600">
          {componente === 'otro' && otroComponente ? capitalize(otroComponente) : t(componente)}
        </h2>
        <h4 className="font-normal my-2 text-gray-600">
          {`${t('motivo_de_la_transfusion')}: ${motivoTransfusion}`}
        </h4>
        {diagnosticoRelacionadoALaTransfusion.label && (
          <h4 className="font-normal my-2 text-gray-600">
            {`${t('diagnostico_relacionado_a_la_transfusion')}: ${
              // diagnosticoRelacionadoALaTransfusion.label === 'OTRO'
              //   ? otroDiagnosticoRelacionadoALaTransfusion
              diagnosticoRelacionadoALaTransfusion.label
            }`}
          </h4>
        )}
        {fecha.anio && (
          <h4 className="font-normal my-2 text-gray-600">
            {`${t('fecha')}: ${fecha.dia ? `${fecha.dia.padStart(2, '0')}/` : ''}${
              fecha.mes ? `${stringMes(fecha.mes, t)}/` : ''
            }${fecha.anio}`}
          </h4>
        )}
        {!!comentarios.blocks.length && (
          <div className="max-h-24 overflow-y-auto w-full">
            <ViewComentarios comentarios={comentarios} />
          </div>
        )}
        {archivos.length > 0 && (
          <DocumentosCargadosListado
            idMedico={idMedico}
            idLista={indxDB}
            archivos={archivos}
            eliminarArchivo={eliminarArchivo}
            verArchivo={verArchivo}
          />
        )}
      </div>
      <div className="min-w-max self-center">
        <label htmlFor={`btn-upload-${indxDB}`}>
          <input
            id={`btn-upload-${indxDB}`}
            name={`btn-upload-${indxDB}`}
            style={{ display: 'none' }}
            type="file"
            accept=".csv, .odt, .ods, odp, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
            onChange={seleccionarArchivo}
          />
          <Button component="span" color="primary" startIcon={<CloudUploadIcon />}>
            {t('cargar_archivo')}
          </Button>
        </label>
        <div className="text-center">
          <IconButton aria-label="edit" color="primary" onClick={handleEditTransfusion}>
            <EditIcon />
          </IconButton>
          {archivos.length ? (
            <HtmlTooltip title={`${t('para_eliminar_se_deben_eliminar_los_archivos_primero')}`}>
              <span>
                <IconButton aria-label="delete" color="primary" disabled>
                  <DeleteIcon />
                </IconButton>
              </span>
            </HtmlTooltip>
          ) : (
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={handleDeleteTransfusion}
              disabled={idBD === indxDB}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default ListaTransfusiones;
