import { ICatalogosEstandar } from '@common/types';

export interface ICatLaboratorio {
  idCat: number;
  categoria: string;
  label: string;
  unidad: string;
  id?: number;
}

export interface ILaboratorioResultado {
  id: number;
  radio: string;
  catSelect: ICatLaboratorio | undefined;
  txtLibre: string;
  valor: string;
  unidadSelect: ICatalogosEstandar | undefined;
  dia: string;
  mes: string;
  anio: string;
}

export const laboratorioResultadoInitial: ILaboratorioResultado = {
  id: -1,
  radio: 'catalogo',
  catSelect: {
    idCat: 0,
    categoria: '',
    label: '',
    unidad: '',
  },
  txtLibre: '',
  valor: '',
  unidadSelect: { id: 0, label: '' },
  dia: '',
  mes: '',
  anio: '',
};

export interface IArrayLaboratorioTotal {
  total: number;
  label: string;
  idLaboratorio: number | null;
  txtLibre: string | null;
  categoria: string | null;
  nombre: string | null;
}
