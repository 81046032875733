import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Administradores from './Administradores';
import PerfilAdministrador from './PerfilAdministrador';
import { IAdministradoresOrgProps } from './types';
import { IDatosAdministrador } from '../types';

function AdministradoresOrg(props: IAdministradoresOrgProps) {
  const { t } = useTranslation();
  const {
    administradores,
    objAdministrador,
    setObjAdministrador,
    contrasenia,
    setContrasenia,
    contraseniaRepetida,
    setContraseniaRepetida,
    otrasOrganizaciones,
    setUsuarioExistente,
    saveData,
    deleteAdministrador,
  } = props;
  const [docId, setDocId] = useState<string>('');

  return (
    <div>
      <PerfilAdministrador
        objAdministrador={objAdministrador}
        setObjAdministrador={setObjAdministrador}
        docId={docId}
        contrasenia={contrasenia}
        setContrasenia={setContrasenia}
        contraseniaRepetida={contraseniaRepetida}
        setContraseniaRepetida={setContraseniaRepetida}
        otrasOrganizaciones={otrasOrganizaciones}
        setUsuarioExistente={setUsuarioExistente}
        saveData={saveData}
      />
      {administradores.length > 0 && (
        <>
          <h2 className="text-blue-800 font-normal">{t('lista_de_administradores')}</h2>
          {otrasOrganizaciones.map((org) => {
            const admins = administradores
              .filter((admin: IDatosAdministrador) => admin.organizacion.id === `${org.id}`)
              .map((admin) => (
                <Administradores
                  administrador={admin}
                  objAdministrador={objAdministrador}
                  setObjAdministrador={setObjAdministrador}
                  setDocId={setDocId}
                  deleteAdministrador={deleteAdministrador}
                />
              ));
            if (admins.length > 0) {
              return (
                <>
                  <div className="shadow-md border border-solid border-gray-200 rounded mt-4 p-4">
                    <h2 className="font-bold text-gray-500 m-0">{org.nombreOrganizacion}</h2>
                    {admins}
                  </div>
                </>
              );
            }
            return '';
          })}
        </>
      )}
    </div>
  );
}

export default AdministradoresOrg;
