/** @format */

export const menuData = [
  {
    title: 'Datos Personales',
    buttons: [
      {
        id: 'SubMenu',
        title: 'Perfil',
        action: () => {},
      },
      {
        id: 'MiSalud',
        title: 'Mi Salud',
        action: () => {},
      },
    ],
  },
  {
    title: 'Estilo de vida y hábitos',
    buttons: [
      {
        id: 'EstiloDeVida',
        title: 'Vivienda',
        action: () => {},
      },

      {
        id: 'Alimentacion',
        title: 'Alimentación',
        action: () => {},
      },

      {
        id: 'Habitos',
        title: 'Hábitos',
        action: () => {},
      },
      {
        id: 'Higiene',
        title: 'Higiene',
        action: () => {},
        isOxxo: false,
      },
      {
        id: 'ActividadFisica',
        title: 'Actividad física',
        action: () => {},
        isOxxo: false,
      },
    ],
  },
  {
    title: 'Historia ginecológica',
    buttons: [
      {
        id: 'Ginecologia',
        title: 'Ginecología',
        action: () => {},
      },
    ],
  },
  {
    title: 'Medidas actuales',
    buttons: [
      {
        id: 'MisMedidas',
        title: 'Mis Medidas',
        action: () => {},
      },
    ],
  },
  {
    title: 'Mi tablero',
    buttons: [
      {
        id: 'ResumenDeSalud',
        title: 'Resumen de Salud',
        action: () => {},
      },
    ],
  },
];

export const subMenu = [
  {
    buttons: [
      {
        id: 'Perfil',
        title: 'Perfil',
        action: () => {},
      },

      {
        id: 'Ubication',
        title: 'Ubicacion',
        action: () => {},
      },

      {
        id: 'Discapacidades',
        title: 'Discapacidades',
        action: () => {},
      },
      {
        id: 'InformacionEmergencia',
        title: 'Info. de emergencia',
        action: () => {},
      },
      {
        id: 'SegurosPlanes',
        title: 'Seguros Y Planes',
        action: () => {},
        isOxxo: false,
      },
    ],
  },
];
