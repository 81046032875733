import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { constMes, constAnio } from '@constants/fechas';
import DeleteDialog from '@components/DeleteDialog';
import useStyles from '@common/styles';
import { ICatalogosEstandar } from '@common/types';
import { ITabEmpresaProps } from './types';

function TabEmpresa({
  // nombreEmpresa,
  datosSelector,
  datosTipoOcupacion,
  objTabEmpresa,
  setObjTabEmpresa,
  guardarEmpresa,
  actualizarEmpresa,
  peticionLimpiarPantalla,
}: ITabEmpresaProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deleteAlertLaboral, setDeleteAlertLaboral] = useState<boolean>(false);
  const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>, selector: number) => {
    if (selector === 1) {
      setObjTabEmpresa({ ...objTabEmpresa, fechaIngresoMes: event.target.value as string });
    } else if (selector === 2) {
      setObjTabEmpresa({ ...objTabEmpresa, fechaEgresoMes: event.target.value as string });
    }
  };
  const handleChangeAnio = (event: React.ChangeEvent<{ value: unknown }>, selector: number) => {
    if (selector === 1) {
      setObjTabEmpresa({ ...objTabEmpresa, fechaIngresoAnio: event.target.value as string });
    } else if (selector === 2) {
      setObjTabEmpresa({ ...objTabEmpresa, fechaEgresoAnio: event.target.value as string });
    }
  };
  const handleChangeOpcion = (
    event: any,
    newValue: ICatalogosEstandar | null,
    selector: number,
  ) => {
    if (selector === 1) {
      setObjTabEmpresa({
        ...objTabEmpresa,
        opcionConsultorio: newValue || { id: 0, label: '' },
        txtEmpresa: '',
      });
    } else if (selector === 2) {
      setObjTabEmpresa({
        ...objTabEmpresa,
        opcionOcupacion: newValue || { id: 0, label: '' },
        txtOcupacion: '',
      });
    }
  };
  const handleChangeEspecifica = (event: React.ChangeEvent<{ value: unknown }>, tipo: number) => {
    if (tipo === 1) {
      setObjTabEmpresa({ ...objTabEmpresa, txtEmpresa: event.target.value as string });
    } else if (tipo === 2) {
      setObjTabEmpresa({ ...objTabEmpresa, txtOcupacion: event.target.value as string });
    }
  };
  const revisionBotonGuardado = () => {
    let valDatos = false;
    if (
      objTabEmpresa.radioEmpresa === undefined ||
      objTabEmpresa.radioSindicalizado === undefined ||
      (objTabEmpresa.radioEmpresa === 'consultorio' && objTabEmpresa.opcionConsultorio.id === 0) ||
      (objTabEmpresa.radioEmpresa === 'otro' && objTabEmpresa.txtEmpresa.length === 0) ||
      (objTabEmpresa.opcionOcupacion.id === 6862 && objTabEmpresa.txtOcupacion.length === 0) ||
      objTabEmpresa.opcionOcupacion.id === 0 ||
      !objTabEmpresa.fechaIngresoMes ||
      !objTabEmpresa.fechaIngresoAnio ||
      (!objTabEmpresa.checkTrabajoActual &&
        (!objTabEmpresa.fechaEgresoAnio || !objTabEmpresa.fechaEgresoMes))
    ) {
      valDatos = true;
    }
    return valDatos;
  };
  return (
    <div className="border border-solid border-gray-300 rounded p-4">
      <DeleteDialog
        open={deleteAlertLaboral}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes_laborales_cancelar')}
        callBackAceptar={() => {
          peticionLimpiarPantalla();
          setDeleteAlertLaboral(false);
        }}
        callBackClose={() => setDeleteAlertLaboral(false)}
      />
      <div className="mb-4">
        <div className="flex justify-start items-center m-0">
          <h3 className="m-0 text-gray-600 font-medium pr-4">
            {`${t('antecedentes_laborales_empresa')}:*`}
          </h3>
          <RadioGroup
            row
            aria-label="empresa"
            name="empresa"
            value={objTabEmpresa.radioEmpresa}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setObjTabEmpresa({
                ...objTabEmpresa,
                radioEmpresa: (event.target as HTMLInputElement).value,
                opcionConsultorio: { id: 0, label: '' },
                txtEmpresa: '',
              });
            }}
          >
            <FormControlLabel
              value="consultorio"
              control={
                <Radio color="primary" checked={objTabEmpresa.radioEmpresa === 'consultorio'} />
              }
              // label={nombreEmpresa}
              label={t('antecedentes_laborales_mi_empresa')}
            />
            <FormControlLabel
              value="otro"
              control={<Radio color="primary" checked={objTabEmpresa.radioEmpresa === 'otro'} />}
              label={t('antecedentes_laborales_otra_empresa')}
            />
            <FormControlLabel
              value="independiente"
              control={
                <Radio color="primary" checked={objTabEmpresa.radioEmpresa === 'independiente'} />
              }
              label={t('antecedentes_laborales_trabajador_independiente')}
            />
          </RadioGroup>
        </div>
        {objTabEmpresa.radioEmpresa === 'consultorio' ? (
          <Autocomplete
            options={datosSelector}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={objTabEmpresa.opcionConsultorio}
            onChange={(e, nV) => handleChangeOpcion(e, nV, 1)}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
            disabled={objTabEmpresa.radioEmpresa === undefined}
          />
        ) : (
          <TextField
            variant="outlined"
            fullWidth
            label={t('especifica')}
            InputProps={{
              inputProps: {
                maxLength: 50,
                autoComplete: 'off',
                'aria-autocomplete': 'none',
              },
            }}
            value={objTabEmpresa.txtEmpresa}
            onChange={(e) => handleChangeEspecifica(e, 1)}
            disabled={objTabEmpresa.radioEmpresa === undefined}
          />
        )}
      </div>
      <h3 className="mt-0 mb-3 text-gray-600 font-medium">{`${t('ocupacion')}*`}</h3>
      <div className="grid grid-cols-2 m-0 gap-4 mb-3">
        <Autocomplete
          options={datosTipoOcupacion}
          getOptionLabel={(option) => option.label}
          noOptionsText={t('sin_resultados')}
          value={objTabEmpresa.opcionOcupacion}
          onChange={(e, nV) => handleChangeOpcion(e, nV, 2)}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
        {objTabEmpresa && objTabEmpresa.opcionOcupacion.id === 6862 && (
          <TextField
            variant="outlined"
            placeholder={t('especifica')}
            fullWidth
            InputProps={{
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: 100,
              },
            }}
            value={objTabEmpresa.txtOcupacion}
            onChange={(e) => handleChangeEspecifica(e, 2)}
          />
        )}
      </div>
      <div className="grid grid-cols-4 mb-4 gap-4">
        <div>
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {`${t('antecedentes_laborales_sindicalizado')}*`}
          </h3>
          <div className="flex content-center pt-2">
            <RadioGroup
              row
              aria-label="ocupacion"
              name="ocupacion"
              value={objTabEmpresa.radioSindicalizado}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setObjTabEmpresa({
                  ...objTabEmpresa,
                  radioSindicalizado: (event.target as HTMLInputElement).value,
                });
              }}
            >
              <FormControlLabel
                value="no"
                control={
                  <Radio color="primary" checked={objTabEmpresa.radioSindicalizado === 'no'} />
                }
                label={t('no')}
              />
              <FormControlLabel
                value="si"
                control={
                  <Radio color="primary" checked={objTabEmpresa.radioSindicalizado === 'si'} />
                }
                label={t('si')}
              />
            </RadioGroup>
          </div>
        </div>
        <div className="flex flex-col content-center">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {`${t('antecedentes_laborales_fecha_ingreso')}*`}
          </h3>
          <div className="w-full grid grid-cols-2 gap-2">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="fechaIngresoMes">{t('mes')}</InputLabel>
              <Select
                labelId="fechaIngresoMes"
                id="fechaIngresoMes"
                value={objTabEmpresa.fechaIngresoMes || ''}
                onChange={(e) => handleChangeMes(e, 1)}
                label={t('mes')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {mesSingle.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                className={classes.absoluteBottom}
                style={{ textAlign: 'center', margin: '0', width: '100%' }}
              >
                {/* {fechaField.helperText} */}
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="fechaIngresoAnio">{t('anio')}</InputLabel>
              <Select
                labelId="fechaIngresoAnio"
                id="fechaIngresoAnio"
                value={objTabEmpresa.fechaIngresoAnio || ''}
                onChange={(e) => handleChangeAnio(e, 1)}
                label={t('anio')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constAnio().map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {!objTabEmpresa.checkTrabajoActual && (
          <div className="flex flex-col content-center">
            <h3 className="mt-0 mb-2 text-gray-600 font-medium">
              {`${t('antecedentes_laborales_fecha_egreso')}*`}
            </h3>
            <div className="w-full grid grid-cols-2 gap-2">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="fechaEgresoMes">{t('mes')}</InputLabel>
                <Select
                  labelId="fechaEgresoMes"
                  id="fechaEgresoMes"
                  value={objTabEmpresa.fechaEgresoMes || ''}
                  disabled={objTabEmpresa.checkTrabajoActual}
                  onChange={(e) => handleChangeMes(e, 2)}
                  label={t('mes')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {constMes.map((mesSingle, indx) => (
                    <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                      {mesSingle.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  className={classes.absoluteBottom}
                  style={{ textAlign: 'center', margin: '0', width: '100%' }}
                >
                  {/* {fechaField.helperText} */}
                </FormHelperText>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="fechaEgresoAnio">{t('anio')}</InputLabel>
                <Select
                  labelId="fechaEgresoAnio"
                  id="fechaEgresoAnio"
                  value={objTabEmpresa.fechaEgresoAnio || ''}
                  disabled={objTabEmpresa.checkTrabajoActual}
                  onChange={(e) => handleChangeAnio(e, 2)}
                  label={t('anio')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 375,
                      },
                    },
                  }}
                >
                  {constAnio().map((anioSingle) => (
                    <MenuItem key={anioSingle} value={anioSingle.toString()}>
                      {anioSingle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        <div className="pt-10">
          <FormControlLabel
            control={(
              <Checkbox
                checked={objTabEmpresa.checkTrabajoActual}
                onChange={() =>
                  setObjTabEmpresa({
                    ...objTabEmpresa,
                    checkTrabajoActual: !objTabEmpresa.checkTrabajoActual,
                    fechaEgresoAnio: undefined,
                    fechaEgresoMes: undefined,
                  })}
                name="trabajoActual"
                color="primary"
              />
            )}
            label={t('antecedentes_laborales_trabajo_actual')}
          />
        </div>
      </div>
      <div className="pt-4 flex justify-between">
        <p className="text-gray-600">
          * &nbsp;
          {t('campos_obligatorios')}
        </p>
        <div className="flex flex-row">
          <div className="pr-2">
            <Button
              variant="contained"
              color="primary"
              type="button"
              size="large"
              onClick={() => setDeleteAlertLaboral(true)}
            >
              {t('cancelar')}
            </Button>
          </div>
          <div>
            {objTabEmpresa.id === -1 ? (
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={() => guardarEmpresa()}
                disabled={revisionBotonGuardado()}
              >
                {t('guardar')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={() => actualizarEmpresa()}
                disabled={revisionBotonGuardado()}
              >
                {t('actualizar')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabEmpresa;
