import { FC } from 'react';
import { BackButtonProps } from './type';

// eslint-disable-next-line import/prefer-default-export
export const BackButton: FC<BackButtonProps> = ({
  label = 'Regresar a Menú',
  onClick,
  showIcon = true,
  customIcon,
}) => {
  const renderIcon = () => {
    if (!showIcon) return null;
    if (customIcon) return customIcon;

    return (
      <svg className="w-5 h-5 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    );
  };

  return (
    <div className="w-full px-3 my-4">
      <button
        type="button"
        onClick={onClick}
        className="inline-flex items-center text-[#3B82F6] hover:text-blue-700 font-medium text-base transition-colors"
      >
        {renderIcon()}
        <span>{label}</span>
      </button>
    </div>
  );
};
