import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import { Group } from '@material-ui/icons';
import { RootState } from 'src/store';
import useStyles from './styles';

/** Prop recibidas del componente Navbar */
interface ISideBarProps {
  sidebarOpen: boolean;
  handleSidebarClose: () => void;
}

/** Regresa una sidebar (un componente drawer de Material UI) */
function AdmonSideBar(props: ISideBarProps) {
  const history = useHistory();
  const location = useLocation();
  const { sidebarOpen, handleSidebarClose } = props;
  const { secciones, orgsAdministradas } = useSelector((state: RootState) => state.Me);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleSidebarClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List>
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 154) &&
            orgsAdministradas > 0 && (
              <ListItem
                button
                classes={{ button: classes.button }}
                className={
                  location.pathname === '/home'
                    ? classes.listItemHomeSelected
                    : classes.listItemHome
                }
                onClick={() => history.push('/home')}
              >
                <ListItemIcon className={classes.iconWidth}>
                  <HomeIcon
                    className={location.pathname === '/home' ? 'text-white' : 'text-blue-800'}
                  />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
          )}
          <ListItem
            button
            classes={{ button: classes.button }}
            className={
              location.pathname === '/administracion'
                ? classes.listItemHomeSelected
                : classes.listItemHome
            }
            onClick={() => history.push('/administracion')}
          >
            <ListItemIcon className={classes.iconWidth}>
              <Group
                className={location.pathname === '/administracion' ? 'text-white' : 'text-blue-800'}
              />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default AdmonSideBar;
