import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel, RadioGroup, Radio, Button, Collapse,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteDialog from '@components/DeleteDialog';
import dayjs from 'dayjs';
import { ITabLaboralProps } from './types';
import ListaAccidentesLaborales from './ListaAccidentesLaborales';
import ModalAccidenteLaboralDialog from '../Modales/ModalAccidenteLaboral';
import useStyles from './styles';
import { IObjArrayAccidentesLaborales } from '../../types';

dayjs.locale('es');

function FormTabLaboral({
  datosLugarAccidente,
  datosTurnoAccidente,
  datosCausaAccidente,
  datosNaturalezaLesion,
  datosSegmentoLesionado,
  datosUbicacionLesion,
  datosGravedad,
  datosPorcentajeIncapacidad,
  datosTipoRiesgo,
  objLaboral,
  objArrayAccidentesLaborales,
  setObjArrayAccidentesLaborales,
  actualizarArrayAccidentesLaborales,
  updateAccidenteLaboralRadio,
}: ITabLaboralProps) {
  const { t } = useTranslation();
  const [expandAccidentes, setExpandAccidentes] = useState<boolean>(true);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [modalAccidenteLaboral, setModalAccidenteLaboral] = useState<boolean>(false);
  const [inxArraySeleccionado, setInxArraySeleccionado] = useState<number>(-1);
  const validacionFecha = (dia: string, mes: string, anio: string) => {
    const formatString = dia.length === 0 ? 'MMM-YYYY' : 'DD-MMM-YYYY';
    return dayjs(`${anio}-${mes}-${dia.length === 0 ? '1' : dia}`).format(formatString);
  };
  const mostrarModalBorrado = (inx: number) => {
    setInxArraySeleccionado(inx);
    setDeleteAlert(true);
  };
  const mostrarModalInformacionLaboral = (inx: number) => {
    setInxArraySeleccionado(inx);
    setObjArrayAccidentesLaborales(objLaboral.arrayAccidentesLaborales[inx]);
    setModalAccidenteLaboral(true);
  };
  const actualizarDatosGuardadoArray = () => {
    setModalAccidenteLaboral(false);
    actualizarArrayAccidentesLaborales('actualizar', inxArraySeleccionado);
  };
  const borrarValorArrayAccidentesLaborales = (inx: number) =>
    actualizarArrayAccidentesLaborales('borrar', inx);
  const agregarValorArrayAccidentesLaborales = () =>
    actualizarArrayAccidentesLaborales('agregar', -1);
  const classes = useStyles();
  return (
    <div className="border border-solid border-gray-300 rounded">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={() => actualizarArrayAccidentesLaborales('borrar', inxArraySeleccionado)}
        callBackClose={() => setDeleteAlert(false)}
      />
      <ModalAccidenteLaboralDialog
        open={modalAccidenteLaboral}
        callBackClose={() => {
          setInxArraySeleccionado(-1);
          setModalAccidenteLaboral(false);
        }}
        datosLugarAccidente={datosLugarAccidente}
        datosTurnoAccidente={datosTurnoAccidente}
        datosCausaAccidente={datosCausaAccidente}
        datosNaturalezaLesion={datosNaturalezaLesion}
        datosSegmentoLesionado={datosSegmentoLesionado}
        datosUbicacionLesion={datosUbicacionLesion}
        datosGravedad={datosGravedad}
        datosPorcentajeIncapacidad={datosPorcentajeIncapacidad}
        datosTipoRiesgo={datosTipoRiesgo}
        objArrayAccidentesLaborales={objArrayAccidentesLaborales}
        setObjArrayAccidentesLaborales={setObjArrayAccidentesLaborales}
        agregarValorArrayAccidentesLaborales={agregarValorArrayAccidentesLaborales}
        actualizarDatosGuardadoArray={actualizarDatosGuardadoArray}
      />
      <div className="flex flex-col justify-start m-0 w-full mt-2 ml-4">
        <h3 className="m-0 text-gray-600 font-medium">
          {t('accidentes_accidente_laboral_misma_empresa')}
        </h3>
        <div className="flex flex-row">
          <RadioGroup
            row
            aria-label="accidenteLaboral"
            name="accidenteLaboral"
            value={objLaboral.radioLaboral}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateAccidenteLaboralRadio((event.target as HTMLInputElement).value);
            }}
          >
            <FormControlLabel
              value="no"
              control={
                <Radio color="primary" disabled={objLaboral.arrayAccidentesLaborales.length > 0} />
              }
              label={t('no')}
            />
            <FormControlLabel
              value="si"
              control={
                <Radio color="primary" disabled={objLaboral.arrayAccidentesLaborales.length > 0} />
              }
              label={t('si')}
            />
          </RadioGroup>
          {objLaboral && objLaboral.radioLaboral && objLaboral.radioLaboral === 'si' && (
            <Button
              color="primary"
              size="small"
              onClick={() => setModalAccidenteLaboral(true)}
              className={classes.noTextTransform}
              startIcon={<AddCircleOutlineIcon />}
            >
              {t('accidentes_registrar')}
            </Button>
          )}
        </div>
      </div>
      {objLaboral.arrayAccidentesLaborales.length > 0 && (
        <div className="p-4">
          <h2 className="text-gray-500 font-normal m-0 pb-2">
            {`${t('accidentes_lista')} (${objLaboral.arrayAccidentesLaborales.length})`}
          </h2>
          <ListaAccidentesLaborales
            fecha={validacionFecha(
              objLaboral.arrayAccidentesLaborales[0].fechaDia,
              objLaboral.arrayAccidentesLaborales[0].fechaMes,
              objLaboral.arrayAccidentesLaborales[0].fechaAnio,
            )}
            lugar={objLaboral.arrayAccidentesLaborales[0].lugarAccidente.label}
            turno={objLaboral.arrayAccidentesLaborales[0].turnoAccidente.label}
            causa={
              objLaboral.arrayAccidentesLaborales[0].tipoCausaAccidente.label ||
              objLaboral.arrayAccidentesLaborales[0].txtCausaAccidente
            }
            naturaleza={
              objLaboral.arrayAccidentesLaborales[0].tipoNaturalezaLesion.label ||
              objLaboral.arrayAccidentesLaborales[0].txtNaturalezaLesion
            }
            segmento={objLaboral.arrayAccidentesLaborales[0].segmentoLesionado.label}
            ubicacion={objLaboral.arrayAccidentesLaborales[0].ubicacionLesion.label}
            gravedad={objLaboral.arrayAccidentesLaborales[0].gravedad.label}
            porcentajeIncapacidad={
              objLaboral.arrayAccidentesLaborales[0].porcentajeIncapacidad.label
            }
            nom035={
              objLaboral.arrayAccidentesLaborales[0].radioEncuestaNOM035
                ? t(objLaboral.arrayAccidentesLaborales[0].radioEncuestaNOM035)
                : ''
            }
            seguimiento={
              objLaboral.arrayAccidentesLaborales[0].radioAmeritaSeguimiento
                ? t(objLaboral.arrayAccidentesLaborales[0].radioAmeritaSeguimiento)
                : ''
            }
            riesgo={objLaboral.arrayAccidentesLaborales[0].tipoRiesgo.label}
            comentarios={objLaboral.arrayAccidentesLaborales[0].comentarios}
            inx={0}
            actualizarValorArrayAccidentesLaborales={mostrarModalInformacionLaboral}
            borrarValorArrayAccidentesLaborales={mostrarModalBorrado}
            discapacidad={objLaboral.arrayAccidentesLaborales[0].radioDiscapacidad}
            diasIncapacidad={objLaboral.arrayAccidentesLaborales[0].diasIncapacidad}
          />
          {objLaboral.arrayAccidentesLaborales.length > 1 && (
            <>
              <Collapse in={expandAccidentes}>
                {objLaboral.arrayAccidentesLaborales
                  .slice(1)
                  .map((valObjAccidente: IObjArrayAccidentesLaborales, inx: number) => (
                    <ListaAccidentesLaborales
                      fecha={validacionFecha(
                        valObjAccidente.fechaDia,
                        valObjAccidente.fechaMes,
                        valObjAccidente.fechaAnio,
                      )}
                      lugar={objLaboral.arrayAccidentesLaborales[0].lugarAccidente.label}
                      turno={objLaboral.arrayAccidentesLaborales[0].turnoAccidente.label}
                      causa={
                        valObjAccidente.tipoCausaAccidente.label ||
                        valObjAccidente.txtCausaAccidente
                      }
                      naturaleza={
                        valObjAccidente.tipoNaturalezaLesion.label ||
                        valObjAccidente.txtNaturalezaLesion
                      }
                      segmento={valObjAccidente.segmentoLesionado.label}
                      ubicacion={valObjAccidente.ubicacionLesion.label}
                      gravedad={valObjAccidente.gravedad.label}
                      porcentajeIncapacidad={valObjAccidente.porcentajeIncapacidad.label}
                      nom035={
                        objLaboral.arrayAccidentesLaborales[0].radioEncuestaNOM035
                          ? t(objLaboral.arrayAccidentesLaborales[0].radioEncuestaNOM035)
                          : ''
                      }
                      seguimiento={
                        objLaboral.arrayAccidentesLaborales[0].radioAmeritaSeguimiento
                          ? t(objLaboral.arrayAccidentesLaborales[0].radioAmeritaSeguimiento)
                          : ''
                      }
                      riesgo={valObjAccidente.tipoRiesgo.label}
                      comentarios={valObjAccidente.comentarios}
                      inx={inx + 1}
                      actualizarValorArrayAccidentesLaborales={mostrarModalInformacionLaboral}
                      borrarValorArrayAccidentesLaborales={borrarValorArrayAccidentesLaborales}
                      discapacidad={valObjAccidente.radioDiscapacidad}
                      diasIncapacidad={valObjAccidente.diasIncapacidad}
                    />
                  ))}
              </Collapse>
              <div className="text-center">
                <Button
                  onClick={() => setExpandAccidentes((prevVal: boolean) => !prevVal)}
                  color="primary"
                >
                  {expandAccidentes ? <span>{t('ver_menos')}</span> : <span>{t('ver_mas')}</span>}
                  {expandAccidentes ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default FormTabLaboral;
