import { ThunkDispatch } from 'redux-thunk';
import { getToken } from '@utils/common';
import { getPerfilMedicoMe } from '@utils/getCatalogos';
import { setLoading } from '../loading/actions';
import { LoadingActionTypes } from '../loading/types';
import {
  IRol,
  CHANGE_ROL,
  CHANGE_TOKEN,
  CHANGE_EMAIL,
  CHANGE_ID_MEDICO,
  MeActionTypes,
  CHANGE_NOMBRE,
  CHANGE_PRIMER_APELLIDO,
  CHANGE_SEGUNDO_APELLIDO,
  IEducacion,
  CHANGE_CARRERA,
  CHANGE_ESPECIALIDAD,
  MeState,
  SET_ME,
  RESET_ME,
  CHANGE_SEXO,
  CHANGE_ACTIVO,
} from './types';
import { getUsuarioPaciente } from '@actions/basicosPaciente/actions';

export function changeToken(token: string): MeActionTypes {
  return {
    type: CHANGE_TOKEN,
    payload: token,
  };
}

export function changeRol(rol: IRol): MeActionTypes {
  return {
    type: CHANGE_ROL,
    payload: rol,
  };
}

export function changeEmailMe(email: string): MeActionTypes {
  return {
    type: CHANGE_EMAIL,
    payload: email,
  };
}

export function changeIDMedicoMe(idMedico: number): MeActionTypes {
  return {
    type: CHANGE_ID_MEDICO,
    payload: idMedico,
  };
}

export function changeNombreMe(nombre: string): MeActionTypes {
  return {
    type: CHANGE_NOMBRE,
    payload: nombre,
  };
}

export function changePrimerApellidoMe(primerApellido: string): MeActionTypes {
  return {
    type: CHANGE_PRIMER_APELLIDO,
    payload: primerApellido,
  };
}

export function changeSegundoApellidoMe(segundoApellido: string): MeActionTypes {
  return {
    type: CHANGE_SEGUNDO_APELLIDO,
    payload: segundoApellido,
  };
}

export function changeCarreraMe(carrera: IEducacion): MeActionTypes {
  return {
    type: CHANGE_CARRERA,
    payload: carrera,
  };
}

export function changeEspecialidadMe(especialidades: IEducacion[]): MeActionTypes {
  return {
    type: CHANGE_ESPECIALIDAD,
    payload: especialidades,
  };
}

export function changeSexoMe(sexo: string): MeActionTypes {
  return {
    type: CHANGE_SEXO,
    payload: sexo,
  };
}

export function setMedicoMe(medicoMe: MeState): MeActionTypes {
  return {
    type: SET_ME,
    payload: medicoMe,
  };
}

export function resetMe(): MeActionTypes {
  return {
    type: RESET_ME,
  };
}

export function setActivoMedico(activo: boolean): MeActionTypes {
  return {
    type: CHANGE_ACTIVO,
    payload: activo,
  };
}

export const getMeMedico =
  (email: string) => (dispatch: ThunkDispatch<{}, {}, MeActionTypes | LoadingActionTypes>) => {
    dispatch(setLoading(true));
    getPerfilMedicoMe(email)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          const id = result.data.idMedico;
          const esPaciente = result.data.esPaciente;
          dispatch(
            setMedicoMe({
              nombre: result.data.nombre,
              email: result.data.email,
              idMedico: id,
              idUsuario: result.data.idUsuario,
              primerApellido: result.data.primerApellido,
              segundoApellido: result.data.segundoApellido,
              token: getToken(),
              carrera: result.data.carrera,
              especialidades: result.data.especialidades,
              rol: result.data.rol,
              sexo: result.data.sexo,
              activo: result.data.activo,
              tiempoRestante: result.data.tiempoRestante,
              customer: result.data.customer,
              secciones: result.data.secciones,
              pais: result.data.pais,
              esPaciente,
              validar: result.data.validar,
              enOrganizacion: result.data.enOrganizacion,
              orgsAdministradas: result.data.orgsAdministradas,
            }),
          );
          if (esPaciente) {
            dispatch(getUsuarioPaciente(id));
          }
        } else {
          dispatch(resetMe());
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(resetMe());
        dispatch(setLoading(false));
      });
  };
