import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SimpleAlert from '../SimpleAlerta';
import { ITragosEstandarProps } from './types';

function TragosEstandar({ open, setOpen }: ITragosEstandarProps) {
  const { t } = useTranslation();

  return (
    <SimpleAlert open={open} setOpen={setOpen}>
      <div className="bg-white rounded w-10/12 max-w-screen-lg mx-auto p-4 max-h-screen overflow-y-scroll">
        <div className="flex justify-between">
          <h2 className="text-blue-600 font-normal mt-0">
            {t('equivalencias_de_tragos_estándar')}
            &nbsp;
            <sup>*,1</sup>
          </h2>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="grid grid-cols-9 bg-blue-700 text-white text-center">
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('tarro_o_lata_con_cerveza')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('jarrito_con_pulque')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('vaso_con_ron')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('copa_con_vino')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('coctel_con_destilado')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('caballito_con_tequila_o_mezcal')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('vaso_con_licor')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('vaso_con_whisky')}
          </div>
          <div className="p-2 border border-solid border-gray-400 border-b-0 border-t-0 flex items-center">
            {t('vaso_con_vodka')}
          </div>
        </div>
        <div className="grid grid-cols-9 text-center border border-solid border-gray-400">
          <div className="p-2 border border-solid border-gray-400 border-t-0">331 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">263 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">47 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">138 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">37 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">43.5 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">97 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">41 ml</div>
          <div className="p-2 border border-solid border-gray-400 border-t-0">41 ml</div>
          <div className="p-2 border border-solid border-gray-400">5% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">6.3% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">35% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">12% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">45% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">38% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">17% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">40% Alc. Vol.</div>
          <div className="p-2 border border-solid border-gray-400">40% Alc. Vol.</div>
          <div className="col-span-9 p-1 border border-solid border-gray-400">
            {t('13_gramos_de_etanol_puro_en_c_u')}
          </div>
        </div>
        <p>
          {`${t('adaptado_de_alcohol_es_alcohol_trago_estandar_fundacion_investigaciones')}:`}
          &nbsp;
          <a
            href="http://www.tragoestandar.org.mx/como-sirvo-un-trago-estandar/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.tragoestandar.org.mx/como-sirvo-un-trago-estandar/
          </a>
        </p>
        <p className="text-gray-400 text-xs">
          {t('de_acuerdo_a_las_normal_oficiales_mexicana_nom_142_y_nom_047')}
        </p>
        <h3 className="text-center text-blue-600 font-medium">
          {t('transtorno_por_el_uso_de_alcohol')}
          &nbsp;
          <sup>2</sup>
        </h3>
        <p>{t('consumo_de_mas_de_3_tragos_al_dia_por_hombres_y_2_tragos_al_dia_en_mujeres')}</p>
        <h4>{t('referencias')}</h4>
        <p>
          {`${t('1_referencia_alcohol_es_un_trago_estandar')}:`}
          &nbsp;
          <a
            href="http://www.tragoestandar.org.mx/como-sirvo-un-trago-estandar/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.tragoestandar.org.mx/como-sirvo-un-trago-estandar/
          </a>
        </p>
        <p>{t('2_referencia_alcohol_alcoholic_liver_disease')}</p>
      </div>
    </SimpleAlert>
  );
}

export default TragosEstandar;
