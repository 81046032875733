import React from 'react';
import { Snackbar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { SetSnackClose } from '@actions/snackbar/types';
import ISnackBarProps from './types';

function SnackBarComponente(props: ISnackBarProps) {
  const { snackOpen, snackSeverity, snackMensaje } = props;
  const dispatch = useDispatch();
  /** Handlers */
  const handleSnackClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(SetSnackClose());
  };
  return (
    <Snackbar
      open={snackOpen}
      autoHideDuration={6000}
      onClose={handleSnackClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MuiAlert severity={snackSeverity} onClose={handleSnackClose}>
        {snackMensaje}
      </MuiAlert>
    </Snackbar>
  );
}

export default SnackBarComponente;
