import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from 'src/store';
import BasicNav from '@containers/BasicNav';
import { ILoggedInRouteProps } from './types';

function LoggedInRoute({ children, ...otrasProps }: ILoggedInRouteProps) {
  const { acreditado, suscrito } = useSelector((state: RootState) => state.Auth);
  const { rol } = useSelector((state: RootState) => state.Me);
  if (suscrito === true && rol) {
    return (
      <Route
        {...otrasProps}
        render={({ location }) =>
          acreditado &&
          rol.id !== -1 && (
            <Redirect
              to={{
                pathname: `${rol.tipo === 'admin' ? '/administracion' : '/home'}`,
                state: { from: location },
              }}
            />
          )}
      />
    );
  }
  return (
    <Route
      {...otrasProps}
      render={({ location }) =>
        (acreditado === false ? (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        ) : (
          <>
            <BasicNav />
            <div className="container mx-auto mt-4">{children}</div>
          </>
        ))}
    />
  );
}

export default LoggedInRoute;
