import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { capitalize } from '@common/functions';
import { IConstructorParrafoProps } from './types';

function BuilderParrafo({ variable, setVariable }: IConstructorParrafoProps) {
  const { t } = useTranslation();
  /** States del functional component */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        descripcion: val,
      },
      constructor: {
        ...preCons.constructor,
        descripcion: val,
      },
    }));
  };
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-1 gap-x-4">
      <div className="flex mb-3.5 items-end">
        <h3 className="w-max mr-4 mb-2 mt-0 text-gray-600 font-medium">
          {capitalize(variable.datosComponente.titulo)}
        </h3>
        <div className="w-1/3 flex-wrap">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {t('incluye_una_descripcion_opcional')}
          </h3>
          <TextField
            fullWidth
            value={variable.datosComponente.descripcion}
            onChange={handleChangeDescripcion}
            inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
            multiline
            rows={1}
          />
        </div>
      </div>
      <TextField label={t('parrafo')} variant="outlined" rows={6} multiline fullWidth disabled />
    </div>
  );
}

export default BuilderParrafo;
