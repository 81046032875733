import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button, Accordion, AccordionDetails, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '@common/styles';
import ListaDatosTab from '../ListaDatosTabs';
import { ITabExposicionQuimicoProps } from './types';
import { ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha } from '../../../types';

function TabExposicionQuimico({
  setOpenModalRiesgoQuimico,
  setNombreModal,
  setIdTabSeleccionado,
  objExposicionRiesgo,
  setDatoActualizar,
  setInxArraySeleccionado,
  setDeleteAlert,
}: ITabExposicionQuimicoProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accAerosoles, setAccAerosoles] = useState<boolean | undefined>(undefined);
  const [accHumos, setAccHumos] = useState<boolean | undefined>(undefined);
  const [accGaseosos, setAccGaseosos] = useState<boolean | undefined>(undefined);
  const [accLiquidos, setAccLiquidos] = useState<boolean | undefined>(undefined);
  const [accSolidos, setAccSolidos] = useState<boolean | undefined>(undefined);
  const [accNeblinas, setAccNeblinas] = useState<boolean | undefined>(undefined);
  const [accPolvos, setAccPolvos] = useState<boolean | undefined>(undefined);
  const [accVapores, setAccVapores] = useState<boolean | undefined>(undefined);
  const [accOtro, setAccOtro] = useState<boolean | undefined>(undefined);
  return (
    <div className="w-full">
      {/* es la parte que corresponde a temperaturas altas */}
      <Accordion expanded={accAerosoles} onChange={() => setAccAerosoles(!accAerosoles)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_aerosoles')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_aerosoles'));
                  setIdTabSeleccionado(24);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayAerosoles.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_aerosoles')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(24);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_aerosoles'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(24);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a temperaturas bajas */}
      <Accordion expanded={accHumos} onChange={() => setAccHumos(!accHumos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_humos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_humos'));
                  setIdTabSeleccionado(25);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayHumos.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_humos')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(25);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_humos'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(25);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion ionizante */}
      <Accordion expanded={accGaseosos} onChange={() => setAccGaseosos(!accGaseosos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_gaseosos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_gaseosos'));
                  setIdTabSeleccionado(26);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayGaseosos.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_gaseosos')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(26);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_gaseosos'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(26);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a radiacion no ionizante */}
      <Accordion expanded={accLiquidos} onChange={() => setAccLiquidos(!accLiquidos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_liquidos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_liquidos'));
                  setIdTabSeleccionado(27);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayLiquidos.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_liquidos')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(27);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_liquidos'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(27);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ventilacion */}
      <Accordion expanded={accSolidos} onChange={() => setAccSolidos(!accSolidos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_solidos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_solidos'));
                  setIdTabSeleccionado(28);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arraySolidos.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_solidos')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(28);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_solidos'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(28);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a iluminacion */}
      <Accordion expanded={accNeblinas} onChange={() => setAccNeblinas(!accNeblinas)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_neblinas')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_neblinas'));
                  setIdTabSeleccionado(29);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayNeblinas.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_neblinas')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(29);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_neblinas'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(29);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a ruido */}
      <Accordion expanded={accPolvos} onChange={() => setAccPolvos(!accPolvos)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_polvos')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_polvos'));
                  setIdTabSeleccionado(30);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayPolvos.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_polvos')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(30);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_polvos'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(30);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a vibracion */}
      <Accordion expanded={accVapores} onChange={() => setAccVapores(!accVapores)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_vapores')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_vapores'));
                  setIdTabSeleccionado(31);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayVapores.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_vapores')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(31);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_vapores'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(31);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
      {/* es la parte que corresponde a otro */}
      <Accordion expanded={accOtro} onChange={() => setAccOtro(!accOtro)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} IconButtonProps={{ color: 'primary' }}>
          <div className="w-full grid grid-cols-3 gap-4">
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {t('antecedentes_laborales_quimico_otros')}
            </h3>
            <div className="w-full flex">
              <Button
                color="primary"
                size="small"
                onClick={(e) => {
                  setOpenModalRiesgoQuimico(true);
                  setNombreModal(t('antecedentes_laborales_quimico_otros'));
                  setIdTabSeleccionado(32);
                  e.stopPropagation();
                }}
                className={classes.noTextTransform}
                startIcon={<AddCircleOutlineIcon />}
              >
                {t('antecedentes_laborales_riesgo_agregar')}
              </Button>
            </div>
            <h3 className="w-full place-self-center m-0 text-gray-600 font-normal">
              {`(${objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro.length})`}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col">
          {objExposicionRiesgo.grupoSelectores.quimico.opcionesQuimico.arrayOtro.map(
            (
              valPruebaLista: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha,
              inx: number,
            ) => (
              <ListaDatosTab
                titulo={t('antecedentes_laborales_quimico_otros')}
                tipoQuimico={
                  valPruebaLista.tipoQuimico.id === 52
                    ? valPruebaLista.txtQuimicoOtro
                    : valPruebaLista.tipoQuimico.label
                }
                txtTiempo={valPruebaLista.txtTiempo}
                radioSeleccion={valPruebaLista.radioSeleccion || ''}
                fechaCreacion={
                  valPruebaLista.fechaCreacion
                    ? dayjs(valPruebaLista.fechaCreacion).format('DD-MM-YYYY')
                    : ''
                }
                comentarios={valPruebaLista.comentarios}
                inx={inx}
                valDatoArray={valPruebaLista}
                setDatoActualizar={(valor: any) => {
                  setIdTabSeleccionado(32);
                  setDatoActualizar(valor);
                  setNombreModal(t('antecedentes_laborales_quimico_otros'));
                }}
                openModalActualizar={() => setOpenModalRiesgoQuimico(true)}
                setInxArraySeleccionado={setInxArraySeleccionado}
                setDeleteAlert={(activa: boolean) => {
                  setIdTabSeleccionado(32);
                  setDeleteAlert(activa);
                }}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TabExposicionQuimico;
