import Modal from '@material-ui/core/Modal';
import { ISimpleAlertaProps } from './types';

function SimpleAlert({ open, setOpen, children }: ISimpleAlertaProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="h-full flex items-center">{children}</div>
    </Modal>
  );
}

export default SimpleAlert;
