import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  withStyles,
  RadioGroup,
  FormControlLabel,
  Button,
  IconButton,
} from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
/** importancion de las fechas para la fecha */
import { constDia, constMes, constAnio } from '@constants/fechas';
import { validarFecha, validarFechaEsAnterior } from '@common/functions';
import useStyles from '@common/styles';
import DeleteDialog from '@components/DeleteDialog';
import { ITablaMama } from './types';

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

function TablaMama(props: ITablaMama) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    ginecoObstetrico,
    setGinecoObstetrico,
    index,
    lastIndex,
    estudioItems,
    resultadoItems,
    handleDeleteRow,
    fechaField,
    setFechaField,
  } = props;

  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleChangeDia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const arr = [...ginecoObstetrico.despistajeCancer.mama.dia];
    arr[index] = event.target.value as string;
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        mama: {
          ...ginecoObstetrico.despistajeCancer.mama,
          dia: arr,
        },
      },
    });
  };

  const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>) => {
    const arr = [...ginecoObstetrico.despistajeCancer.mama.mes];
    arr[index] = event.target.value as string;
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        mama: {
          ...ginecoObstetrico.despistajeCancer.mama,
          mes: arr,
        },
      },
    });
  };

  const handleChangeAnio = (event: React.ChangeEvent<{ value: unknown }>) => {
    const arr = [...ginecoObstetrico.despistajeCancer.mama.anio];
    arr[index] = event.target.value as string;
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        mama: {
          ...ginecoObstetrico.despistajeCancer.mama,
          anio: arr,
        },
      },
    });
  };

  useEffect(() => {
    if (
      ginecoObstetrico.despistajeCancer.mama.dia[index] !== '' &&
      ginecoObstetrico.despistajeCancer.mama.mes[index] !== '' &&
      ginecoObstetrico.despistajeCancer.mama.anio[index] !== ''
    ) {
      if (
        !validarFecha(
          ginecoObstetrico.despistajeCancer.mama.anio[index],
          ginecoObstetrico.despistajeCancer.mama.mes[index],
          ginecoObstetrico.despistajeCancer.mama.dia[index],
        )
      ) {
        setFechaField((prevArr) => {
          const arr = [...prevArr];
          arr[index].error = true;
          arr[index].helperText = t('fecha_invalida');
          return arr;
        });
      } else if (
        !validarFechaEsAnterior(
          ginecoObstetrico.despistajeCancer.mama.anio[index],
          ginecoObstetrico.despistajeCancer.mama.mes[index],
          ginecoObstetrico.despistajeCancer.mama.dia[index],
        )
      ) {
        setFechaField((prevArr) => {
          const arr = [...prevArr];
          arr[index].error = true;
          arr[index].helperText = t('fecha_invalida');
          return arr;
        });
      } else {
        setFechaField((prevArr) => {
          const arr = [...prevArr];
          arr[index].error = false;
          arr[index].helperText = '';
          return arr;
        });
      }
    }
  }, [
    ginecoObstetrico.despistajeCancer.mama.dia[index],
    ginecoObstetrico.despistajeCancer.mama.mes[index],
    ginecoObstetrico.despistajeCancer.mama.anio[index],
  ]);

  const handleChangeRadioEstudio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    const newMama = ginecoObstetrico.despistajeCancer.mama;
    const arrEs = ginecoObstetrico.despistajeCancer.mama.estudio.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    newMama.estudio = arrEs;
    if (val !== 'otro') {
      const arrEsp = ginecoObstetrico.despistajeCancer.mama.especifica.map((item, j) => {
        if (j === index) {
          return '';
        }
        return item;
      });
      newMama.especifica = arrEsp;
    }
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        mama: newMama,
      },
    });
  };

  const handleChangeEspecifica = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arrEsp = ginecoObstetrico.despistajeCancer.mama.especifica.map((item, j) => {
      if (j === index) {
        return val.toUpperCase();
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        mama: {
          ...ginecoObstetrico.despistajeCancer.mama,
          especifica: arrEsp,
        },
      },
    });
  };

  const handleChangeRadioResultado = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    const arr = ginecoObstetrico.despistajeCancer.mama.resultado.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        mama: {
          ...ginecoObstetrico.despistajeCancer.mama,
          resultado: arr,
        },
      },
    });
  };

  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    const arr = ginecoObstetrico.despistajeCancer.mama.comentarios.map((item, j) => {
      if (j === index) {
        return val;
      }
      return item;
    });
    setGinecoObstetrico({
      ...ginecoObstetrico,
      despistajeCancer: {
        ...ginecoObstetrico.despistajeCancer,
        mama: {
          ...ginecoObstetrico.despistajeCancer.mama,
          comentarios: arr,
        },
      },
    });
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <DeleteDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={`${t('_esta_seguro_que_desea_eliminar_el_estudio')}${
          ginecoObstetrico.despistajeCancer.mama.estudio[index]
            ? ` ${t(ginecoObstetrico.despistajeCancer.mama.estudio[index])}`
            : ''
        }${t('_?')}`}
        callBackAceptar={() => handleDeleteRow(index)}
        callBackClose={handleAlertClose}
      />
      <div
        className={`grid grid-cols-12 bg-gray-100 border border-solid border-gray-300 border-t-0${
          lastIndex === index ? ' rounded-b' : ''
        }`}
      >
        <div className="p-4 col-span-3 bg-white">
          <RadioGroup
            aria-label="estudio-cancer-mama"
            value={ginecoObstetrico.despistajeCancer.mama.estudio[index]}
            onChange={handleChangeRadioEstudio}
          >
            {estudioItems.map((est) => (
              <FormControlLabel
                key={est}
                value={est}
                control={<Radio color="primary" name={`radio-button-${est}-estudio`} />}
                label={t(est)}
              />
            ))}
          </RadioGroup>
          {ginecoObstetrico.despistajeCancer.mama.estudio[index] === 'otro' && (
            <TextField
              variant="outlined"
              fullWidth
              label={t('especifica')}
              InputProps={{
                inputProps: {
                  maxLength: 50,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                },
              }}
              value={ginecoObstetrico.despistajeCancer.mama.especifica[index]}
              onChange={handleChangeEspecifica}
            />
          )}
        </div>
        <div className="bg-blue-300 p-4 col-span-3">
          <RadioGroup
            aria-label="resultado-cancer-mama"
            value={ginecoObstetrico.despistajeCancer.mama.resultado[index]}
            onChange={handleChangeRadioResultado}
          >
            {resultadoItems.map((res) => (
              <FormControlLabel
                key={res}
                value={res}
                control={<BlackRadio name={`radio-button-${res}-resultado`} />}
                label={t(res)}
              />
            ))}
          </RadioGroup>
          <div className="mt-4">
            <Button
              color="primary"
              className={classes.noTextTransform}
              startIcon={<CloudUploadIcon />}
            >
              {t('cargar_resultado')}
            </Button>
          </div>
        </div>
        <div className="bg-white w-full grid grid-cols-3 gap-2 p-4 col-span-5">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-dia">{t('dia')}</InputLabel>
            <Select
              labelId="label-dia"
              id="select-dia"
              value={ginecoObstetrico.despistajeCancer.mama.dia[index]}
              onChange={handleChangeDia}
              label={t('dia')}
              error={fechaField[index].error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
              style={{ backgroundColor: 'white' }}
            >
              {constDia.map((diaSingle) => (
                <MenuItem value={diaSingle.toString()} key={diaSingle}>
                  {diaSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-mes">{t('mes')}</InputLabel>
            <Select
              labelId="label-mes"
              id="select-mes"
              value={ginecoObstetrico.despistajeCancer.mama.mes[index]}
              onChange={handleChangeMes}
              label={t('mes')}
              error={fechaField[index].error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
              style={{ backgroundColor: 'white' }}
            >
              {constMes.map((mesSingle, indx) => (
                <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                  {mesSingle.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.absoluteBottom}>
              {fechaField[index].helperText}
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-año">{t('anio')}</InputLabel>
            <Select
              labelId="label-año"
              id="select-año"
              value={ginecoObstetrico.despistajeCancer.mama.anio[index]}
              onChange={handleChangeAnio}
              label={t('anio')}
              error={fechaField[index].error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
              style={{ backgroundColor: 'white' }}
            >
              {constAnio().map((anioSingle) => (
                <MenuItem key={anioSingle} value={anioSingle.toString()}>
                  {anioSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="col-span-3 mt-4">
            <BlackTextField
              variant="outlined"
              rows={8}
              multiline
              fullWidth
              InputProps={{
                className: 'bg-white',
                inputProps: {
                  maxLength: 300,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                },
              }}
              value={ginecoObstetrico.despistajeCancer.mama.comentarios[index]}
              onChange={handleChangeComentario}
              label={t('comentarios')}
            />
          </div>
        </div>
        <div className="p-4 flex justify-center items-center">
          <IconButton color="primary" onClick={() => setAlertOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default TablaMama;
