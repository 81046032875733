import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DeleteDialog from '@components/DeleteDialog';
import {
  deleteAntecedentesFamiliarEnfermedad,
  deleteAntecedentesFamiliarEnfermedadNegada,
} from '@utils/deleteInfo';
import { RootState } from 'src/store';
import { setRequest } from '@actions/request/types';
import { setFamiliares } from '@actions/paciente/actions';
import Familiar from './Familiar';
import { IListaAntecedentesFamiliaresProps } from './types';

function ListaAntecedentesFamiliares({
  familiares,
  setParentesco,
  setVive,
  setNombreApellidos,
  setIdFamiliarActualizar,
  setReferirNegar,
  setListaEnfermedad,
  setEnfNegada,
  setGrupoEnfNegadas,
  verificarExitenDatosEnElFormulario,
  disableFamiliar,
  enableFamiliar,
  resetListasFamiliar,
  enableRF,
  disableRF,
  setAgregarDiagnostico,
  disableParentesco,
}: IListaAntecedentesFamiliaresProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [addAlert, setAddAlert] = useState<boolean>(false);
  const [indiceFamiliar, setIndiceFamiliar] = useState<number>(-1);
  const [tipoEnfermedad, setTipoEnfermedad] = useState<string>('');
  const [indiceEnfermedad, setIndiceEnfermedad] = useState<number>(-1);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const resetIndices = () => {
    setIndiceFamiliar(-1);
    setTipoEnfermedad('');
    setIndiceEnfermedad(-1);
  };

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };

  const handleAddAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddAlert(false);
  };

  const handleDeleteEnfermedad = () => {
    const objEnviar = {
      idFamiliar: familiares[indiceFamiliar].idFamiliar,
      idPaciente: familiares[indiceFamiliar].idPaciente,
      idMedico,
      idConsultorio,
    };
    if (tipoEnfermedad === 'referir') {
      dispatch(
        setRequest({
          type: 'delete',
          requestFunction: deleteAntecedentesFamiliarEnfermedad({
            ...objEnviar,
            idEnfermedad:
              familiares[indiceFamiliar].listaEnfermedades[indiceEnfermedad].idEnfermedad,
            consulta: idConsulta,
          }),
          successFunction: (result: any) => {
            resetIndices();
            dispatch(setFamiliares(result.datos));
          },
        }),
      );
    } else if (tipoEnfermedad === 'negar') {
      dispatch(
        setRequest({
          type: 'delete',
          requestFunction: deleteAntecedentesFamiliarEnfermedadNegada({
            ...objEnviar,
            idEnfermedadNegada:
              familiares[indiceFamiliar].enfermedadesNegadas[indiceEnfermedad].idEnfermedadNegada,
          }),
          successFunction: (result: any) => {
            resetIndices();
            dispatch(setFamiliares(result.datos));
          },
        }),
      );
    }
  };

  const handleEditEnfermedad = (
    indxFam = indiceFamiliar,
    typeEnf = tipoEnfermedad,
    indxEnf = indiceEnfermedad,
  ) => {
    enableFamiliar();
    disableRF();
    disableParentesco();
    setAgregarDiagnostico(false);
    setParentesco(familiares[indxFam].parentesco);
    setNombreApellidos(familiares[indxFam].nombreApellidos);
    setVive(familiares[indxFam].vive ? 'T' : 'F');
    setReferirNegar(typeEnf);
    setIdFamiliarActualizar(familiares[indxFam].idFamiliar);
    if (typeEnf === 'referir') {
      setListaEnfermedad(familiares[indxFam].listaEnfermedades[indxEnf]);
      setEnfNegada({
        idEnfermedadNegada: 0,
        diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
        idFamiliar: 0,
      });
    } else {
      setEnfNegada(familiares[indxFam].enfermedadesNegadas[indxEnf]);
      setListaEnfermedad({
        idEnfermedad: 0,
        diagnostico: 'cie10',
        diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
        diagnosticoMedico: '',
        edadAlDiagnostico: { edad: 0, claveEdad: 0, periodo: '' },
        esCausaDeMuerte: false,
        idFamiliar: 0,
      });
    }
    setGrupoEnfNegadas(familiares[indxFam].grupoEnfermedadesNegadas);
    window.scrollTo({ top: 260, left: 0, behavior: 'smooth' });
  };

  const handleAddDiagnostico = (indxFam = indiceFamiliar) => {
    resetListasFamiliar();
    disableFamiliar();
    enableRF();
    setAgregarDiagnostico(true);
    setIdFamiliarActualizar(familiares[indxFam].idFamiliar);
    setParentesco(familiares[indxFam].parentesco);
    setNombreApellidos(familiares[indxFam].nombreApellidos);
    setVive(familiares[indxFam].vive ? 'T' : 'F');
    setGrupoEnfNegadas(familiares[indxFam].grupoEnfermedadesNegadas);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  return (
    <div className="container mx-auto shadow-lg border border-solid border-gray-200 mt-4 p-4 rounded">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={handleDeleteEnfermedad}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_este_diagnostico_se_borrara_el_que_estas_creando')}
        callBackAceptar={handleEditEnfermedad}
        callBackClose={handleEditAlertClose}
      />
      <DeleteDialog
        open={addAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_vas_a_agegar_un_nuevo_diagnostico_se_borrara_el_que_estas_creando')}
        callBackAceptar={handleAddDiagnostico}
        callBackClose={handleAddAlertClose}
      />
      <h3 className="text-blue-800 font-normal mt-0">{t('lista_de_antecedentes_familiares')}</h3>
      {familiares.map((familiar, indx) => (
        <Familiar
          index={indx}
          familiar={familiar}
          key={familiar.idFamiliar}
          setGrupoEnfNegadas={setGrupoEnfNegadas}
          setIndiceFamiliar={setIndiceFamiliar}
          setTipoEnfermedad={setTipoEnfermedad}
          setIndiceEnfermedad={setIndiceEnfermedad}
          setDeleteAlert={setDeleteAlert}
          setEditAlert={setEditAlert}
          handleEditEnfermedad={handleEditEnfermedad}
          verificarExitenDatosEnElFormulario={verificarExitenDatosEnElFormulario}
          handleAddDiagnostico={handleAddDiagnostico}
          setAddAlert={setAddAlert}
        />
      ))}
    </div>
  );
}

export default ListaAntecedentesFamiliares;
