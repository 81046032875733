import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VisaLogo from '@assets/logo/visa.svg';
import MastercardLogo from '@assets/logo/mastercard.svg';
import AmericanLogo from '@assets/logo/american_express.svg';
import MedipraxiLogo from '@assets/logo/medipraxi.svg';
import { epochTimeToDiaMesAnioStr } from '@common/functions';
import { ITarjetaInfoProps } from './types';

function obtenerLogo(brand: string) {
  switch (brand) {
    case 'visa':
      return VisaLogo;
    case 'mastercard':
      return MastercardLogo;
    case 'american_express':
      return AmericanLogo;
    default:
      return MedipraxiLogo;
  }
}

function TarjetaInfo({
  brand,
  last4,
  predeterminada,
  createdAt,
  expMonth,
  expYear,
  handleClickEliminar,
  id,
}: ITarjetaInfoProps) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center py-1 border-0 border-solid border-gray-400 border-b py-1">
      <img className="w-10 mr-4" src={obtenerLogo(brand)} alt="logo_tarjeta" />
      <h3 className="text-gray-800 font-medium m-0">
        {last4.padStart(brand === 'american_express' ? 15 : 16, '*')}
      </h3>
      <h3 className="text-gray-800 font-normal m-0 ml-2">
        {t('agregada_el')}
        : &nbsp;
        <span className="font-medium">{epochTimeToDiaMesAnioStr(createdAt)}</span>
      </h3>
      <h3 className="text-gray-800 font-normal m-0 ml-2">
        {t('vence_el')}
        : &nbsp;
        <span className="font-medium">{`${expMonth}/${expYear}`}</span>
      </h3>
      <IconButton
        aria-label="delete"
        color="primary"
        onClick={() => handleClickEliminar(id, last4)}
      >
        <DeleteIcon />
      </IconButton>
      {predeterminada && (
        <div className="bg-blue-500 py-1 px-4 rounded text-white">{t('predeterminada')}</div>
      )}
    </div>
  );
}

export default TarjetaInfo;
