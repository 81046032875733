import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '@common/styles';
import { IFechaField } from '@common/types';
import TablaCervicoUterino from './TablaCervicoUterino';
import { ICervicoUterinoProps } from './types';

function CervicoUterino(props: ICervicoUterinoProps) {
  const { ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const estudioItems = ['Citología cervical', 'Prueba de VPH-AR', 'otro'];

  const [fechaField, setFechaField] = useState<IFechaField[]>([
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
    { error: false, helperText: '' },
  ]);

  const handleClickAgregarNuevoEstudio = () => {
    if (ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.length < 10) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          cervicoUterino: {
            arrayTabla: ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.concat(
              `${
                ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla[
                  ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.length - 1
                ]
              }I`,
            ),
            estudio: ginecoObstetrico.despistajeCancer.cervicoUterino.estudio.concat(''),
            especifica: ginecoObstetrico.despistajeCancer.cervicoUterino.especifica.concat(''),
            resultado: ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.concat({
              check1: null,
              check2: null,
              check3: null,
              selector2: null,
              selector3: null,
              resultadoRadio: null,
              resultadoTexto: '',
            }),
            dia: ginecoObstetrico.despistajeCancer.cervicoUterino.dia.concat(''),
            mes: ginecoObstetrico.despistajeCancer.cervicoUterino.mes.concat(''),
            anio: ginecoObstetrico.despistajeCancer.cervicoUterino.anio.concat(''),
            comentarios: ginecoObstetrico.despistajeCancer.cervicoUterino.comentarios.concat(''),
          },
        },
      });
    }
  };

  const handleDeleteRow = (indx: number) => {
    if (ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.length > 1) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          cervicoUterino: {
            arrayTabla: ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.filter(
              (_item, j) => indx !== j,
            ),
            estudio: ginecoObstetrico.despistajeCancer.cervicoUterino.estudio.filter(
              (_item, j) => indx !== j,
            ),
            especifica: ginecoObstetrico.despistajeCancer.cervicoUterino.especifica.filter(
              (_item, j) => indx !== j,
            ),
            resultado: ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.filter(
              (_item, j) => indx !== j,
            ),
            dia: ginecoObstetrico.despistajeCancer.cervicoUterino.dia.filter(
              (_item, j) => indx !== j,
            ),
            mes: ginecoObstetrico.despistajeCancer.cervicoUterino.mes.filter(
              (_item, j) => indx !== j,
            ),
            anio: ginecoObstetrico.despistajeCancer.cervicoUterino.anio.filter(
              (_item, j) => indx !== j,
            ),
            comentarios: ginecoObstetrico.despistajeCancer.cervicoUterino.comentarios.filter(
              (_item, j) => indx !== j,
            ),
          },
        },
      });
      setFechaField((prevArr) => {
        const arr = [...prevArr];
        arr[indx].error = false;
        arr[indx].helperText = '';
        return arr;
      });
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        despistajeCancer: {
          ...ginecoObstetrico.despistajeCancer,
          cervicoUterino: {
            arrayTabla: ['I'],
            estudio: [''],
            especifica: [''],
            resultado: [
              {
                check1: null,
                check2: null,
                check3: null,
                selector2: null,
                selector3: null,
                resultadoRadio: null,
                resultadoTexto: '',
              },
            ],
            dia: [''],
            mes: [''],
            anio: [''],
            comentarios: [''],
          },
        },
      });
      setFechaField([
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
        { error: false, helperText: '' },
      ]);
    }
  };

  const reviewCatchData = () => {
    if (
      ginecoObstetrico.despistajeCancer.cervicoUterino.estudio.find(
        (estudioVal) => estudioVal === '',
      ) !== undefined ||
      ginecoObstetrico.despistajeCancer.cervicoUterino.resultado.find(
        (resultadoVal) => resultadoVal.resultadoTexto === '',
      ) !== undefined ||
      ginecoObstetrico.despistajeCancer.cervicoUterino.dia.find((diaVal) => diaVal === '') !==
        undefined ||
      ginecoObstetrico.despistajeCancer.cervicoUterino.mes.find((mesVal) => mesVal === '') !==
        undefined ||
      ginecoObstetrico.despistajeCancer.cervicoUterino.anio.find((anioVal) => anioVal === '') !==
        undefined
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <h3 className="text-gray-900 font-medium">{t('cervico_uterino')}</h3>
      <div className="grid grid-cols-12 bg-blue-600 py-2 text-center rounded-t">
        <span className="text-white col-span-3">{`${t('estudio')}*`}</span>
        <span className="text-white col-span-3">{`${t('resultado')}*`}</span>
        <span className="text-white col-span-5">{t('fecha/comentarios')}</span>
      </div>
      {ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.map((tabla, indx) => (
        <TablaCervicoUterino
          ginecoObstetrico={ginecoObstetrico}
          setGinecoObstetrico={setGinecoObstetrico}
          key={tabla}
          index={indx}
          lastIndex={ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.length - 1}
          estudioItems={estudioItems}
          handleDeleteRow={handleDeleteRow}
          fechaField={fechaField}
          setFechaField={setFechaField}
        />
      ))}
      <div className="flex justify-between">
        <p className="text-gray-600">
          * &nbsp;
          {t('campos_obligatorios')}
        </p>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          className={classes.noTextTransform}
          color="primary"
          onClick={handleClickAgregarNuevoEstudio}
          disabled={
            ginecoObstetrico.despistajeCancer.cervicoUterino.arrayTabla.length >= 20 ||
            reviewCatchData()
          }
        >
          {t('agregar_nuevo_estudio')}
        </Button>
      </div>
    </div>
  );
}

export default CervicoUterino;
