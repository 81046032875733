import { ICatalogosEstandar } from '@common/types';
import { IFamiliar } from '@containers/TabsPaciente/Antecedentes/Familiares/types';
import {
  IAntecedenteLaboral,
  IObjLaboral,
} from '@containers/TabsPaciente/Antecedentes/Laborales/types';
import { IActividadFisica } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/ActividadFisica/types';
import { IAlimentacion } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Alimentacion/types';
import {
  IEnGestacion,
  IGinecoObstetrico,
} from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/GinecoObstetricos/types';
import { IHigiene } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Higiene/types';
import { IHistoriaSexual } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/HistoriaSexual/types';
import { IInmunizacion } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Inmunizaciones/types';
import { IToxicomania } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Toxicomania/types';
import { IVivienda } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import { IAlergia } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Alergias/types';
import { IDiagnosticosGenerales } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import {
  IEnfermedad,
  IEnfermedadNegada,
  IGrupoEnfermedadesNegadas,
} from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';
import { IHospitalizacion } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Hospitalizacion/types';
import { IProcedimiento } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Procedimiento/types';
import { ITransfusion } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/Transfusiones/types';
import { IAparatosYSistemas } from '@containers/TabsPaciente/AparatosYSistemas/types';
import { ILaboratorioSolicitud } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Laboratorio/types';
import { ISolicitudProcedimientos } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Procedimientos/types';
import { IPronostico } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Pronostico/types';
import {
  IListaRecetas,
  IMedicamento,
} from '@containers/TabsPaciente/DiagnosticoPlan/Plan/TerapiaFarmacologica/types';
import { IExploracionFisica } from '@containers/TabsPaciente/ExploracionFisica/types';
import { IDatosIncapacidad } from '@containers/TabsPaciente/Incapacidades/types';
import { IMcpa } from '@containers/TabsPaciente/MCPA/types';
import {
  IDatosUbicacion,
  IDatosUbicacionNombres,
} from '@containers/TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { IDatosPersonales } from '@containers/TabsPaciente/PerfilPaciente/DatosPersonales/types';
import { IDiscapacidades } from '@containers/TabsPaciente/PerfilPaciente/Discapacidades/types';
import { IInfoEmergencia } from '@containers/TabsPaciente/PerfilPaciente/InfoEmergencia/types';
import { ISegurosPlanes } from '@containers/TabsPaciente/PerfilPaciente/SegurosPlanes/types';

export const SET_DATOS_EXPEDIENTE = 'SET_DATOS_EXPEDIENTE';
export const SET_DATOS_USUARIO = 'SET_DATOS_USUARIO';
export const SET_DATOS_PERSONALES = 'SET_DATOS_PERSONALES';
export const SET_DATOS_UBICACION = 'SET_DATOS_UBICACION';
export const SET_DISCAPACIDADES = 'SET_DISCAPACIDADES';
export const SET_INFO_EMERGENCIA = 'SET_INFO_EMERGENCIA';
export const SET_SEGUROS_PLANES = 'SET_SEGUROS_PLANES';
export const SET_MCPA = 'SET_MCPA';
export const SET_ENF_REFERIDAS = 'SET_ENF_REFERIDAS';
export const SET_ENF_NEGADAS = 'SET_ENF_NEGADAS';
export const SET_GRUPO_ENF_NEGADAS = 'SET_GRUPO_ENF_NEGADAS';
export const SET_CAT_DIAGNOSTICO = 'SET_CAT_DIAGNOSTICO';
export const SET_TRATAMIENTOS = 'SET_TRATAMIENTOS';
export const SET_RECETAS = 'SET_RECETAS';
export const SET_SIN_HOSPITALIZACIONES = 'SET_SIN_HOSPITALIZACIONES';
export const SET_HOSPITALIZACIONES = 'SET_HOSPITALIZACIONES';
export const SET_SIN_ALERGIAS = 'SET_SIN_ALERGIAS';
export const SET_ALERGIAS = 'SET_ALERGIAS';
export const SET_SIN_TRANSFUSIONES = 'SET_SIN_TRANSFUSIONES';
export const SET_TRANSFUSIONES = 'SET_TRANSFUSIONES';
export const SET_EN_GESTACION = 'SET_EN_GESTACION';
export const SET_VIVIENDA = 'SET_VIVIENDA';
export const SET_HIGIENE = 'SET_HIGIENE';
export const SET_ALIMENTACION = 'SET_ALIMENTACION';
export const SET_ACTIVIDAD_FISICA = 'SET_ACTIVIDAD_FISICA';
export const SET_INMUNIZACIONES = 'SET_INMUNIZACIONES';
export const SET_TOXICOMANIA = 'SET_TOXICOMANIA';
export const SET_GINECO_OBSTETRICO = 'SET_GINECO_OBSTETRICO';
export const SET_HISTORIA_SEXUAL = 'SET_HISTORIA_SEXUAL';
export const SET_SIN_PROC_QUIRURGICOS = 'SET_SIN_PROC_QUIRURGICOS';
export const SET_PROC_DIAGNOSTICOS = 'SET_PROC_DIAGNOSTICOS';
export const SET_PROC_TERAPEUTICOS = 'SET_PROC_TERAPEUTICOS';
export const SET_PROC_QUIRURGICOS = 'SET_PROC_QUIRURGICOS';
export const SET_SOL_PROC_DIAGNOSTICOS = 'SET_SOL_PROC_DIAGNOSTICOS';
export const SET_SOL_PROC_TERAPEUTICOS = 'SET_SOL_PROC_TERAPEUTICOS';
export const SET_SOL_PROC_QUIRURGICOS = 'SET_SOL_PROC_QUIRURGICOS';
export const SET_FAMILIARES = 'SET_FAMILIARES';
export const SET_OBJ_LABORAL = 'SET_OBJ_LABORAL';
export const SET_ANTECEDENTES_LABORALES = 'SET_ANTECEDENTES_LABORALES';
export const SET_ACCIDENTES_LABORALES = 'SET_ACCIDENTES_LABORALES';
export const SET_APARATOS_Y_SISTEMAS = 'SET_APARATOS_Y_SISTEMAS';
export const SET_EXPLORACION_FISICA = 'SET_EXPLORACION_FISICA';
export const SET_LABORATORIO_SOLICITUD = 'SET_LABORATORIO_SOLICITUD';
export const SET_PRONOSTICOS = 'SET_PRONOSTICOS';
export const SET_INCAPACIDADES = 'SET_INCAPACIDADES';
export const SET_DATOS_EXPEDIENTE_USUARIO = 'SET_DATOS_EXPEDIENTE_USUARIO';
export const SET_DATOS_PERSONALES_USUARIO = 'SET_DATOS_PERSONALES_USUARIO';
export const SET_DATOS_UBICACION_USUARIO = 'SET_DATOS_UBICACION_USUARIO';
export const SET_DISCAPACIDADES_USUARIO = 'SET_DISCAPACIDADES_USUARIO';
export const SET_INFO_EMERGENCIA_USUARIO = 'SET_INFO_EMERGENCIA_USUARIO';
export const SET_SEGUROS_PLANES_USUARIO = 'SET_SEGUROS_PLANES_USUARIO';
export const SET_DIAGNOSTICOS_GENERALES_USUARIO = 'SET_DIAGNOSTICOS_GENERALES_USUARIO';
export const SET_EN_GESTACION_USUARIO = 'SET_EN_GESTACION_USUARIO';
export const SET_VIVIENDA_USUARIO = 'SET_VIVIENDA_USUARIO';
export const SET_HIGIENE_USUARIO = 'SET_HIGIENE_USUARIO';
export const SET_ALIMENTACION_USUARIO = 'SET_ALIMENTACION_USUARIO';
export const SET_ACTIVIDAD_FISICA_USUARIO = 'SET_ACTIVIDAD_FISICA_USUARIO';
export const SET_TOXICOMANIA_USUARIO = 'SET_TOXICOMANIA_USUARIO';
export const SET_GINECO_OBSTETRICO_USUARIO = 'SET_GINECO_OBSTETRICO_USUARIO';
export const SET_CAMBIOS_DATOS_PACIENTE = 'SET_CAMBIOS_DATOS_PACIENTE';
export const RESET_DATOS_PACIENTE = 'RESET_DATOS_PACIENTE';

export interface IExpediente {
  perfil: {
    datosPersonales: IDatosPersonales;
    datosUbicacion: IDatosUbicacion;
    discapacidades: IDiscapacidades;
    infoEmergencia: IInfoEmergencia;
    segurosPlanes: ISegurosPlanes;
  };
  mcpa: IMcpa;
  antPatologicos: {
    enfReferidas: IEnfermedad[];
    catDiagnostico: ICatalogosEstandar[];
    enfNegadas: IEnfermedadNegada[];
    grupoEnfNegadas: IGrupoEnfermedadesNegadas;
    tratamientos: IMedicamento[];
    recetas: IListaRecetas[];
    sinHospitalizaciones: boolean;
    hospitalizaciones: IHospitalizacion[];
    sinProcQuirurgicos: boolean;
    procDiagnosticos: IProcedimiento[];
    procTerapeuticos: IProcedimiento[];
    procQuirurgicos: IProcedimiento[];
    sinAlergias: boolean;
    alergias: IAlergia[];
    sinTransfusiones: boolean;
    transfusiones: ITransfusion[];
    diagnosticosGenerales: IDiagnosticosGenerales;
  };
  antNoPatologicos: {
    enGestacion: 'si' | 'no' | '';
    vivienda: IVivienda;
    higiene: IHigiene;
    alimentacion: IAlimentacion;
    actividadFisica: IActividadFisica;
    inmunizaciones: IInmunizacion[];
    toxicomania: IToxicomania;
    ginecoObstetrico: IGinecoObstetrico;
    historiaSexual: IHistoriaSexual;
  };
  familiares: IFamiliar[];
  objLaboral: IObjLaboral;
  antecedentesLaborales: IAntecedenteLaboral[];
  accidentesLaborales: ICatalogosEstandar[];
  aparatosYSistemas: IAparatosYSistemas;
  exploracionFisica: IExploracionFisica;
  plan: {
    solProcDiagnosticos: ISolicitudProcedimientos[];
    solProcTerapeuticos: ISolicitudProcedimientos[];
    solProcQuirurgicos: ISolicitudProcedimientos[];
    laboratorioSolicitud: ILaboratorioSolicitud[];
    pronosticos: IPronostico[];
  };
  incapacidades: IDatosIncapacidad[];
}

export interface IUsuarioPaciente {
  perfil: {
    datosPersonales: IDatosPersonales;
    datosUbicacion: IDatosUbicacion;
    datosUbicacionNombres: IDatosUbicacionNombres;
    discapacidades: IDiscapacidades;
    infoEmergencia: IInfoEmergencia;
    segurosPlanes: ISegurosPlanes;
  };
  antPatologicos: {
    diagnosticosGenerales: IDiagnosticosGenerales;
  };
  antNoPatologicos: {
    enGestacion: IEnGestacion;
    vivienda: IVivienda;
    higiene: IHigiene;
    alimentacion: IAlimentacion;
    actividadFisica: IActividadFisica;
    toxicomania: IToxicomania;
    ginecoObstetrico: IGinecoObstetrico;
  };
}

export interface ICambios {
  perfil: {
    datosPersonales: string[];
    datosUbicacion: string[];
    discapacidades: string[];
    infoEmergencia: string[];
    segurosPlanes: string[];
  };
  antNoPatologicos: {
    vivienda: string[];
    higiene: string[];
    alimentacion: string[];
    actividadFisica: string[];
    toxicomania: string[];
    ginecoObstetrico: string[];
  };
}

export interface IPaciente {
  expediente: IExpediente;
  usuario: IUsuarioPaciente;
  cambios: ICambios;
}

interface SetDatosExpedienteAction {
  type: typeof SET_DATOS_EXPEDIENTE;
  payload: IExpediente;
}

interface SetDatosUsuarioAction {
  type: typeof SET_DATOS_USUARIO;
  payload: IExpediente;
}

interface SetDatosPersonalesAction {
  type: typeof SET_DATOS_PERSONALES;
  payload: IDatosPersonales;
}

interface SetDatosUbicacionAction {
  type: typeof SET_DATOS_UBICACION;
  payload: IDatosUbicacion;
}

interface SetDiscapacidadesAction {
  type: typeof SET_DISCAPACIDADES;
  payload: IDiscapacidades;
}

interface SetInfoEmergenciaAction {
  type: typeof SET_INFO_EMERGENCIA;
  payload: IInfoEmergencia;
}

interface SetSegurosPlanesAction {
  type: typeof SET_SEGUROS_PLANES;
  payload: ISegurosPlanes;
}

interface SetMcpaAction {
  type: typeof SET_MCPA;
  payload: IMcpa;
}

interface SetEnfReferidasAction {
  type: typeof SET_ENF_REFERIDAS;
  payload: IEnfermedad[];
}

interface SetEnfNegadasAction {
  type: typeof SET_ENF_NEGADAS;
  payload: IEnfermedadNegada[];
}

interface SetGrupoEnfNegadaAction {
  type: typeof SET_GRUPO_ENF_NEGADAS;
  payload: IGrupoEnfermedadesNegadas;
}

interface SetCatDiagnosticoAction {
  type: typeof SET_CAT_DIAGNOSTICO;
  payload: ICatalogosEstandar[];
}

interface SetTratamientosAction {
  type: typeof SET_TRATAMIENTOS;
  payload: IMedicamento[];
}

interface SetRecetasAction {
  type: typeof SET_RECETAS;
  payload: IListaRecetas[];
}

interface SetSinHospitalizacionesAction {
  type: typeof SET_SIN_HOSPITALIZACIONES;
  payload: boolean;
}

interface SetHospitalizacionesAction {
  type: typeof SET_HOSPITALIZACIONES;
  payload: IHospitalizacion[];
}

interface SetSinAlergiasAction {
  type: typeof SET_SIN_ALERGIAS;
  payload: boolean;
}

interface SetAlergiasAction {
  type: typeof SET_ALERGIAS;
  payload: IAlergia[];
}

interface SetSinTransfusionesAction {
  type: typeof SET_SIN_TRANSFUSIONES;
  payload: boolean;
}

interface SetTransfusionesAction {
  type: typeof SET_TRANSFUSIONES;
  payload: ITransfusion[];
}

interface SetEnGestacionAction {
  type: typeof SET_EN_GESTACION;
  payload: '' | 'si' | 'no';
}

interface SetViviendaAction {
  type: typeof SET_VIVIENDA;
  payload: IVivienda;
}

interface SetHigieneAction {
  type: typeof SET_HIGIENE;
  payload: IHigiene;
}

interface SetAlimentacionAction {
  type: typeof SET_ALIMENTACION;
  payload: IAlimentacion;
}

interface SetActividadFisicaAction {
  type: typeof SET_ACTIVIDAD_FISICA;
  payload: IActividadFisica;
}

interface SetInmunizacionesAction {
  type: typeof SET_INMUNIZACIONES;
  payload: IInmunizacion[];
}

interface SetToxicomaniaAction {
  type: typeof SET_TOXICOMANIA;
  payload: IToxicomania;
}

interface SetGinecoObstetricoAction {
  type: typeof SET_GINECO_OBSTETRICO;
  payload: IGinecoObstetrico;
}

interface SetHistoriaSexualAction {
  type: typeof SET_HISTORIA_SEXUAL;
  payload: IHistoriaSexual;
}

interface SetSinProcQuirurgicosAction {
  type: typeof SET_SIN_PROC_QUIRURGICOS;
  payload: boolean;
}

interface SetProcDiagnosticosAction {
  type: typeof SET_PROC_DIAGNOSTICOS;
  payload: IProcedimiento[];
}

interface SetProcTerapeuticosAction {
  type: typeof SET_PROC_TERAPEUTICOS;
  payload: IProcedimiento[];
}

interface SetProcQuirurgicosAction {
  type: typeof SET_PROC_QUIRURGICOS;
  payload: IProcedimiento[];
}

interface SetSolProcDiagnosticosAction {
  type: typeof SET_SOL_PROC_DIAGNOSTICOS;
  payload: ISolicitudProcedimientos[];
}

interface SetSolProcTerapeuticosAction {
  type: typeof SET_SOL_PROC_TERAPEUTICOS;
  payload: ISolicitudProcedimientos[];
}

interface SetSolProcQuirurgicosAction {
  type: typeof SET_SOL_PROC_QUIRURGICOS;
  payload: ISolicitudProcedimientos[];
}

interface SetFamiliaresAction {
  type: typeof SET_FAMILIARES;
  payload: IFamiliar[];
}

interface SetObjLaboralAction {
  type: typeof SET_OBJ_LABORAL;
  payload: IObjLaboral;
}

interface SetAntecedentesLaboralesAction {
  type: typeof SET_ANTECEDENTES_LABORALES;
  payload: IAntecedenteLaboral[];
}

interface SetAccidentesLaboralesAction {
  type: typeof SET_ACCIDENTES_LABORALES;
  payload: ICatalogosEstandar[];
}

interface SetAparatosYSistemasAction {
  type: typeof SET_APARATOS_Y_SISTEMAS;
  payload: IAparatosYSistemas;
}

interface SetExploracionFisicaAction {
  type: typeof SET_EXPLORACION_FISICA;
  payload: IExploracionFisica;
}

interface SetLaboratorioSolicitudAction {
  type: typeof SET_LABORATORIO_SOLICITUD;
  payload: ILaboratorioSolicitud[];
}

interface SetPronosticosAction {
  type: typeof SET_PRONOSTICOS;
  payload: IPronostico[];
}

interface SetIncapacidadesAction {
  type: typeof SET_INCAPACIDADES;
  payload: IDatosIncapacidad[];
}

interface SetDatosExpedienteUsuarioAction {
  type: typeof SET_DATOS_EXPEDIENTE_USUARIO;
  payload: IUsuarioPaciente;
}

interface SetDatosPersonalesUsuarioAction {
  type: typeof SET_DATOS_PERSONALES_USUARIO;
  payload: IDatosPersonales;
}

interface SetDatosUbicacionUsuarioAction {
  type: typeof SET_DATOS_UBICACION_USUARIO;
  payload: IDatosUbicacion;
}

interface SetDiscapacidadesUsuarioAction {
  type: typeof SET_DISCAPACIDADES_USUARIO;
  payload: IDiscapacidades;
}

interface SetInfoEmergenciaUsuarioAction {
  type: typeof SET_INFO_EMERGENCIA_USUARIO;
  payload: IInfoEmergencia;
}

interface SetSegurosPlanesUsuarioAction {
  type: typeof SET_SEGUROS_PLANES_USUARIO;
  payload: ISegurosPlanes;
}

interface SetDiagnosticosGeneralesUsuarioAction {
  type: typeof SET_DIAGNOSTICOS_GENERALES_USUARIO;
  payload: IDiagnosticosGenerales;
}

interface SetEnGestacionUsuarioAction {
  type: typeof SET_EN_GESTACION_USUARIO;
  payload: IEnGestacion;
}

interface SetViviendaUsuarioAction {
  type: typeof SET_VIVIENDA_USUARIO;
  payload: IVivienda;
}

interface SetHigieneUsuarioAction {
  type: typeof SET_HIGIENE_USUARIO;
  payload: IHigiene;
}

interface SetAlimentacionUsuarioAction {
  type: typeof SET_ALIMENTACION_USUARIO;
  payload: IAlimentacion;
}

interface SetActividadFisicaUsuarioAction {
  type: typeof SET_ACTIVIDAD_FISICA_USUARIO;
  payload: IActividadFisica;
}

interface SetToxicomaniaUsuarioAction {
  type: typeof SET_TOXICOMANIA_USUARIO;
  payload: IToxicomania;
}

interface SetGinecoObstetricoUsuarioAction {
  type: typeof SET_GINECO_OBSTETRICO_USUARIO;
  payload: IGinecoObstetrico;
}

interface SetCambiosDatosPacienteAction {
  type: typeof SET_CAMBIOS_DATOS_PACIENTE;
  payload: ICambios;
}

interface ResetDatosPacienteAction {
  type: typeof RESET_DATOS_PACIENTE;
}

export type PacienteActionTypes =
  | SetDatosExpedienteAction
  | SetDatosUsuarioAction
  | SetDatosPersonalesAction
  | SetDatosUbicacionAction
  | SetDiscapacidadesAction
  | SetInfoEmergenciaAction
  | SetSegurosPlanesAction
  | SetMcpaAction
  | SetEnfReferidasAction
  | SetEnfNegadasAction
  | SetGrupoEnfNegadaAction
  | SetCatDiagnosticoAction
  | SetTratamientosAction
  | SetRecetasAction
  | SetSinHospitalizacionesAction
  | SetHospitalizacionesAction
  | SetSinAlergiasAction
  | SetAlergiasAction
  | SetSinTransfusionesAction
  | SetTransfusionesAction
  | SetEnGestacionAction
  | SetViviendaAction
  | SetHigieneAction
  | SetAlimentacionAction
  | SetActividadFisicaAction
  | SetInmunizacionesAction
  | SetToxicomaniaAction
  | SetGinecoObstetricoAction
  | SetHistoriaSexualAction
  | SetSinProcQuirurgicosAction
  | SetProcDiagnosticosAction
  | SetProcTerapeuticosAction
  | SetProcQuirurgicosAction
  | SetSolProcDiagnosticosAction
  | SetSolProcTerapeuticosAction
  | SetSolProcQuirurgicosAction
  | SetFamiliaresAction
  | SetObjLaboralAction
  | SetAntecedentesLaboralesAction
  | SetAccidentesLaboralesAction
  | SetAparatosYSistemasAction
  | SetExploracionFisicaAction
  | SetLaboratorioSolicitudAction
  | SetPronosticosAction
  | SetIncapacidadesAction
  | SetDatosExpedienteUsuarioAction
  | SetDatosPersonalesUsuarioAction
  | SetDatosUbicacionUsuarioAction
  | SetDiscapacidadesUsuarioAction
  | SetInfoEmergenciaUsuarioAction
  | SetSegurosPlanesUsuarioAction
  | SetDiagnosticosGeneralesUsuarioAction
  | SetEnGestacionUsuarioAction
  | SetViviendaUsuarioAction
  | SetHigieneUsuarioAction
  | SetAlimentacionUsuarioAction
  | SetActividadFisicaUsuarioAction
  | SetToxicomaniaUsuarioAction
  | SetGinecoObstetricoUsuarioAction
  | SetCambiosDatosPacienteAction
  | ResetDatosPacienteAction;
