import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DndProvider, useDrag, useDrop, DropTargetMonitor,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { XYCoord } from 'dnd-core';
import { IPadecimientoActualMC, IRenderProps, IDragItem } from './types';

const style = {
  cursor: 'move',
};

function Render(props: IRenderProps) {
  const {
    index, id, mueveComponente, titulo,
  } = props;
  const ref = useRef<HTMLLIElement>(null);
  const [, drop] = useDrop({
    accept: 'Componente',
    hover(item: IDragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      mueveComponente(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'Componente', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging() as boolean,
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <li ref={ref} style={{ ...style, opacity }}>
      {titulo}
    </li>
  );
}

function PadecimientoActualMC({
  nombreDelMC,
  arrComponentes,
  setArrComponentes,
}: IPadecimientoActualMC) {
  const { t } = useTranslation();

  const mueveComponente = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setArrComponentes((prevA) => {
        const arr = [...prevA];
        const [ordenado] = arr.splice(dragIndex, 1);
        arr.splice(hoverIndex, 0, ordenado);
        return arr;
      });
    },
    [arrComponentes],
  );
  return (
    <div className="p-4 shadow-lg bg-white rounded border border-solid border-gray-200">
      <h2 className="text-center font-medium text-blue-800 mt-0">{t('padecimiento_actual')}</h2>
      <p className="font-bold m-0">{`${t('motivo_consulta')}: ${nombreDelMC}`}</p>
      <ul className="list-outside list-none pl-0 mt-2 mb-0">
        <li>{t('sexo')}</li>
        <li>{t('edad')}</li>
        <DndProvider backend={HTML5Backend}>
          {arrComponentes.map((singleComp, indx) => (
            <Render
              key={singleComp.id}
              index={indx}
              id={singleComp.id}
              mueveComponente={mueveComponente}
              titulo={singleComp.titulo}
            />
          ))}
        </DndProvider>
      </ul>
    </div>
  );
}

export default PadecimientoActualMC;
