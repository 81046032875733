import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import IBotonesGuardarProps from './types';

function BotonesGuardar(props: IBotonesGuardarProps) {
  const {
    hayCambios,
    aceptarCallback,
    cancelarCallback,
    guardarCallback,
    guardarDisable = false,
    continuar = false,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {hayCambios ? (
        <div className="flex flex-row justify-end p-4">
          <div>
            <Button
              variant="contained"
              color="primary"
              type="button"
              size="large"
              onClick={aceptarCallback}
            >
              {t('aceptar')}
            </Button>
          </div>
          <div className="ml-2">
            <Button
              variant="contained"
              color="primary"
              type="button"
              size="large"
              onClick={cancelarCallback}
            >
              {t('rechazar')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-right p-4">
          <Button
            variant="contained"
            color="primary"
            type="button"
            size="large"
            disabled={guardarDisable}
            onClick={guardarCallback}
          >
            {t(continuar ? 'guardar_y_continuar' : 'guardar')}
          </Button>
        </div>
      )}
    </>
  );
}

export default BotonesGuardar;
