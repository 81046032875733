import {
  Badge, Button, FormControl, IconButton, MenuItem, Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPaciente } from '@actions/basicosPaciente/actions';
import { ReactComponent as ChangePhotoIcon } from '@assets/icons/changePhoto.svg';
import { ReactComponent as NoteBlockIcon } from '@assets/icons/noteBlock.svg';
import { ReactComponent as PersonSearchIcon } from '@assets/icons/personSearch.svg';
import { calculateEdad, capitalizeLetter } from '@common/functions';
import { RootState } from 'src/store';
import ModalHistorial from './ModalHistorial/index';
import ModalNotas from './ModalNotas/index';
import { IObjectArray, ISelectorAlergia } from './types';
/** Import de estilos */
import useStyles from './styles';

/** Componente que regresa el boton de share, de imprimir y el de la nube */
function BarraHerramientas() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    idPaciente,
    docId,
    dia,
    mes,
    anio,
    sexo: sexoPaciente,
    alergias,
    nombre,
    telefonos,
    numeroEmpleado,
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico, secciones, enOrganizacion,
  } = useSelector(
    (state: RootState) => state.Me,
  );
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const [sexo, setSexo] = useState<string>('');
  const [edad, setEdad] = useState<string>('');
  const [iconLoad, setIconLoad] = useState<boolean>(false);
  const [grupoAlergias, setGrupoAlergias] = useState<IObjectArray[]>([]);
  const [selectorAlergia, setSelectorAlergia] = useState<ISelectorAlergia>({
    grupo1: 0,
    grupo2: 0,
    grupo3: 0,
    telefono: 0,
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openHistorial, setOpenHistorial] = useState<boolean>(false);
  const [findList, setFindList] = useState<boolean>(true);
  useEffect(() => {
    if (idPaciente !== 0) {
      if (dia && mes && anio) {
        setEdad(calculateEdad(anio, mes, dia));
      }
      if (sexoPaciente === 'H') {
        setSexo(t('hombre'));
      } else if (sexoPaciente === 'M') {
        setSexo(t('mujer'));
      } else {
        setSexo(t('desconoce'));
      }
    }
    let arrDatos: IObjectArray[] = [];
    if (alergias && alergias.length > 0) {
      arrDatos = [
        { grupo: 1, labels: [] },
        { grupo: 2, labels: [] },
        { grupo: 3, labels: [] },
      ];
      alergias.forEach((alergia) => {
        if (alergia.grupo === 1) {
          arrDatos[0].labels.push(alergia.nomVisual);
        } else if (alergia.grupo === 2) {
          arrDatos[1].labels.push(alergia.nomVisual);
        } else if (alergia.grupo === 3) {
          arrDatos[2].labels.push(alergia.nomVisual);
        }
      });
    }
    setGrupoAlergias(arrDatos);
  }, [dia, mes, anio, alergias, sexo]);
  // Si cambio de organizacion debo redireccionar a la lista de pacientes
  // Si cambio de consultorio, si es medico de organización se queda allí, si no debo redireccionar a la lista de pacientes
  useEffect(() => {
    // Se evalúa si docId está vacío para no realizar la consulta dos veces
    if (idMedico > 0 && docId.length === 0 && idPaciente > 0 && idConsultorio > 0 && (!enOrganizacion || idOrganizacion > 0)) {
      dispatch(getPaciente(idPaciente, idMedico, idOrganizacion, idConsultorio));
    }
  }, [idMedico, idPaciente]);
  const handleChangeAlergias = (event: React.ChangeEvent<{ value: unknown }>, grupo: string) => {
    const val = event.target.value as number;
    const updateData = { ...selectorAlergia };
    if (grupo === 'grupo1') {
      updateData.grupo1 = val;
    } else if (grupo === 'grupo2') {
      updateData.grupo2 = val;
    } else if (grupo === 'grupo3') {
      updateData.grupo3 = val;
    } else if (grupo === 'telefono') {
      updateData.telefono = val;
    }
    setSelectorAlergia(updateData);
  };

  return (
    <div className="fixed w-full bg-white rounded flex z-40 shadow-lg">
      {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 60) && (
        <ModalNotas
          open={openModal}
          callBackClose={() => {
            setOpenModal(false);
            setFindList(true);
          }}
          findList={findList}
          setFindList={setFindList}
        />
      )}
      {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 59) && (
        <ModalHistorial
          open={openHistorial}
          callBackClose={() => {
            setOpenHistorial(false);
          }}
        />
      )}
      <div className="p-0 pl-2">
        <div
          className={classes.root}
          onMouseOver={() => setIconLoad(true)}
          onFocus={() => undefined}
          onMouseOut={() => setIconLoad(false)}
          onBlur={() => undefined}
        >
          <label htmlFor="contained-button-file" className="m-0 font-normal">
            {!idPaciente ? (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                classes={{ label: classes.iconButton }}
              >
                <Avatar className={classes.large}>
                  <div className="m-0 text-xs">{t('agregar-foto')}</div>
                </Avatar>
              </IconButton>
            ) : (
              <IconButton color="primary" aria-label="upload picture" component="span">
                {iconLoad ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={<ChangePhotoIcon />}
                  >
                    <Avatar alt="Usuario" variant="rounded" className={classes.large}>
                      {nombre
                        .split(' ')
                        .map((w) => w[0])
                        .join('')}
                    </Avatar>
                  </Badge>
                ) : (
                  <Avatar alt="Usuario" variant="rounded" className={classes.large}>
                    {nombre
                      .split(' ')
                      .map((w) => w[0])
                      .join('')}
                  </Avatar>
                )}
              </IconButton>
            )}
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
            />
          </label>
        </div>
      </div>
      <div className="w-full pr-14 m-0">
        <div className="flex w-full justify-between">
          <div className="flex items-center">
            {nombre && nombre.length > 0 && (
              <>
                <h4 className="m-0 font-normal pr-2">{capitalizeLetter(nombre)}</h4>
                <div className={classes.lineaIzq} />
                <h4 className="m-0 font-normal px-2">{`${sexo}`}</h4>
              </>
            )}
            {edad !== '' && edad !== 'NaN' && (
              <>
                <div className={classes.lineaIzq} />
                <h4 className="m-0 font-normal px-2">{`${edad} ${t('anios')}`}</h4>
              </>
            )}
            {numeroEmpleado !== '' && (
              <>
                <div className={classes.lineaIzq} />
                <h4 className="m-0 font-normal px-2">{numeroEmpleado}</h4>
              </>
            )}
          </div>
          <div className="flex content-center m-0">
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 60) && (
              <Button
                variant="text"
                disableElevation
                color="primary"
                onClick={() => setOpenHistorial(true)}
              >
                <PersonSearchIcon />
                <h4 className="m-0 font-normal">{t('ver-historial')}</h4>
              </Button>
            )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 59) && (
              <Button
                variant="text"
                disableElevation
                color="primary"
                onClick={() => setOpenModal(true)}
              >
                <NoteBlockIcon />
                <h4 className="m-0 font-normal">{t('notas_privadas')}</h4>
              </Button>
            )}
            {/* los botones de abajo se deben de activar cuando esten las funciones */}
            {/* <Button variant="text" disableElevation color="primary">
              <ShareIcon />
              <ArrowDropDownIcon className={classes.flechaColor} />
            </Button>
            <Button variant="text" disableElevation color="primary">
              <PrintIcon />
              <ArrowDropDownIcon className={classes.flechaColor} />
            </Button>
            <IconButton color="primary">
              <BackupIcon />
            </IconButton> */}
          </div>
        </div>
        <div className="flex w-full content-center m-0 justify-between">
          <div className="flex items-center">
            <h4 className="m-0 font-normal pr-2">{`${t('alergias')}:`}</h4>
            {grupoAlergias && grupoAlergias.length > 0 ? (
              <>
                {grupoAlergias[0].labels.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <FiberManualRecordIcon className={classes.iconRed} />
                    <div className="ml-1 w-40">
                      <Select
                        labelId="alergias-fuertes"
                        id="alergias-fuertes"
                        fullWidth
                        value={selectorAlergia.grupo1}
                        onChange={(e) => handleChangeAlergias(e, 'grupo1')}
                      >
                        {grupoAlergias[0].labels.map((name, inx) => (
                          <MenuItem key={name} value={inx}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <h4 className="px-2 m-0 font-normal">
                      {grupoAlergias[0].labels.length > 1 &&
                        `(+${grupoAlergias[0].labels.length - 1})`}
                    </h4>
                  </FormControl>
                )}
                {grupoAlergias[1].labels.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <FiberManualRecordIcon className={classes.iconOrange} />
                    <div className="ml-1 w-40">
                      <Select
                        labelId="alergias-medias"
                        id="alergias-medias"
                        value={selectorAlergia.grupo2}
                        fullWidth
                        onChange={(e) => handleChangeAlergias(e, 'grupo2')}
                      >
                        {grupoAlergias[1].labels.map((name, inx) => (
                          <MenuItem key={name} value={inx}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <h4 className="px-2 m-0 font-normal">
                      {grupoAlergias[1].labels.length > 1 &&
                        `(+${grupoAlergias[1].labels.length - 1})`}
                    </h4>
                  </FormControl>
                )}
                {grupoAlergias[2].labels.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <FiberManualRecordIcon className={classes.iconYellow} />
                    <div className="ml-1 w-40">
                      <Select
                        labelId="alergias-bajas"
                        id="alergias-bajas"
                        value={selectorAlergia.grupo3}
                        fullWidth
                        onChange={(e) => handleChangeAlergias(e, 'grupo3')}
                      >
                        {grupoAlergias[2].labels.map((name, inx) => (
                          <MenuItem key={name} value={inx}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <h4 className="px-2 m-0 font-normal">
                      {grupoAlergias[2].labels.length > 1 &&
                        `(+${grupoAlergias[2].labels.length - 1})`}
                    </h4>
                  </FormControl>
                )}
              </>
            ) : (
              <h4 className="m-0 font-normal pr-2">{`${t('alergias-no-referidas')}`}</h4>
            )}
          </div>
          <div className="flex content-center">
            <Button variant="text" disableElevation color="primary">
              <PhoneIphoneIcon />
              <FormControl className={classes.formControl}>
                <div className="w-36">
                  <Select
                    labelId="telefonos"
                    id="telefonos"
                    value={selectorAlergia.telefono}
                    fullWidth
                    onChange={(e) => handleChangeAlergias(e, 'telefono')}
                  >
                    {telefonos.map((name: string, inx: number) => (
                      <MenuItem key={name} value={inx}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </FormControl>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarraHerramientas;
