import ListaDictamenes from './ListaDictamenes';
import FormularioDictamen from './FormularioDictamen';
import { IDictamenProps } from './types';

function Dictamen({
  setTipoCatalogo,
  setActiveSubStep,
  dictamenesMedLab,
  setDictamenesMedLab,
  objDictamenMedLab,
  setObjDictamenMedLab,
  listaTipoExamen,
  catTiposExamen,
  setCatTiposExamen,
  catPuestos,
  setCatPuestos,
  catDictamenes,
  catRestricciones,
  setCatRestricciones,
  catCausasNoAptitud,
  setCatCausasNoAptitud,
  enfermedades,
  urlLogo,
}: IDictamenProps) {
  return (
    <div>
      <FormularioDictamen
        setTipoCatalogo={setTipoCatalogo}
        setActiveSubStep={setActiveSubStep}
        dictamenesMedLab={dictamenesMedLab}
        setDictamenesMedLab={setDictamenesMedLab}
        objDictamenMedLab={objDictamenMedLab}
        setObjDictamenMedLab={setObjDictamenMedLab}
        listaTipoExamen={listaTipoExamen}
        catTiposExamen={catTiposExamen}
        setCatTiposExamen={setCatTiposExamen}
        catPuestos={catPuestos}
        setCatPuestos={setCatPuestos}
        catDictamenes={catDictamenes}
        catRestricciones={catRestricciones}
        setCatRestricciones={setCatRestricciones}
        catCausasNoAptitud={catCausasNoAptitud}
        setCatCausasNoAptitud={setCatCausasNoAptitud}
        enfermedades={enfermedades}
      />
      {dictamenesMedLab.length > 0 && (
        <ListaDictamenes
          dictamenesMedLab={dictamenesMedLab}
          setDictamenesMedLab={setDictamenesMedLab}
          setObjDictamenMedLab={setObjDictamenMedLab}
          catRestricciones={catRestricciones}
          catCausasNoAptitud={catCausasNoAptitud}
          urlLogo={urlLogo}
        />
      )}
    </div>
  );
}

export default Dictamen;
