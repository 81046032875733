import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, TextField, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import { getPublicKey } from '@utils/getCatalogos';
import { enviarEmailReestablecer } from '@utils/sendInfo';
import useStyles from '@common/styles';
import { validacionEmail } from '@constants/validaciones';
import { setRequest } from '@actions/request/types';
import { arrayBufferToBase64, str2ab, str2AB } from '@common/base64Convert';

function OlvidePassword() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [keyBack, setKeyBack] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState({ error: false, helperText: '' });

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    getPublicKey(signal).then((result: string) => setKeyBack(result));
    return () => abortController.abort();
  }, []);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setEmail(val);
  };

  const handleFocusEmail = () => {
    setError({ error: false, helperText: '' });
  };

  const handleClickEnviarEmail = async () => {
    // creadas el par de llaves del front (usuario)
    if (email === '') {
      setError({ error: true, helperText: t('error_campo_en_blanco') });
      return;
    }
    if (!validacionEmail(email)) {
      setError({ error: true, helperText: t('correo_invalido_verificalo') });
      return;
    }
    // Headers de llaves PEM
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    // Importada la key desde el back
    const pemContents = keyBack.substring(pemHeader.length, keyBack.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // email encriptado
    const emailC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(email),
    );
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enviarEmailReestablecer({ email: arrayBufferToBase64(emailC) }),
        successFunction: () => history.push('/login'),
        successMessage: t('correo_enviado'),
        errorMessage: t('error_ocurrio_un_error_intentar_mas_tarde'),
      }),
    );
  };

  return (
    <div className="relative md:mx-auto w-full md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12">
      <div className="absolute top-0 right-0 mt-3 mr-3">
        <IconButton
          aria-label="delete"
          onClick={() => {
            history.push('/login');
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="shadow-lg bg-white rounded-xl py-6 px-10 border border-solid border-gray-200">
        <div className="w-full text-center">
          <h2 className="text-gray-800 font-medium mb-0">{t('_olvidaste_tu_contrasenia_')}</h2>
        </div>
        <p className="text-center text-gray-600">{t('copy_ayuda_restablecer_contrasenia_1')}</p>
        <form className="pt-4 pb-2 my-2">
          <div className="col-span-2">
            <TextField
              error={error.error}
              fullWidth
              variant="outlined"
              label={t('correo_electronico')}
              value={email}
              onChange={handleChangeEmail}
              onFocus={handleFocusEmail}
              helperText={error.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              InputProps={{
                endAdornment: (
                  <div className="text-gray-500">
                    <EmailIcon color="inherit" />
                  </div>
                ),
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 },
              }}
              type="email"
            />
          </div>
          <div className="text-right mt-6">
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleClickEnviarEmail}
              disabled={error.error || !email.length}
            >
              {t('enviar_correo_para_restablecer_contrasenia')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OlvidePassword;
