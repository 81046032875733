export interface ICatalogoAGEE {
  cve_agee: string;
  nom_agee: string;
  nom_abrev: string;
  pob: string;
  pob_fem: string;
  pob_mas: string;
  viv: string;
}

export interface ICatalogoAGEM {
  cve_agee: string;
  cve_agem: string;
  nom_agem: string;
  cve_cab: string;
  nom_abrev: string;
  pob: string;
  pob_fem: string;
  pob_mas: string;
  viv: string;
}

export interface ICatalogoAGEL {
  altitud: string;
  ambito: string;
  cve_agee: string;
  cve_agem: string;
  cve_carta: string;
  cve_agel: string;
  nom_agel: string;
  estatus: number;
  latitud: string;
  longitud: string;
  periodo: string;
  pob: string;
  viv: string;
}

export interface ICatalogoAGEA {
  cve_agee: string;
  cve_agem: string;
  cve_agel: string;
  cve_agea: string;
  nom_agea: string;
  periodo: string;
  tipo_asen: string;
}

export interface IDatosUbicacionNombres {
  ageeOriginario: string;
  agemOriginario: string;
  agelOriginario: string;
  ageeResidente: string;
  agemResidente: string;
  agelResidente: string;
  ageaResidente: string;
}

export const datosUbicacionNombresInitial: IDatosUbicacionNombres = {
  ageeOriginario: '',
  agemOriginario: '',
  agelOriginario: '',
  ageeResidente: '',
  agemResidente: '',
  agelResidente: '',
  ageaResidente: '',
};

export interface IDatosUbicacion {
  loaded: boolean;
  territorioOriginario: string;
  ageeOriginario: string;
  agemOriginario: string;
  agelOriginario: string;
  territorioResidente: string;
  ageeResidente: string;
  agemResidente: string;
  agelResidente: string;
  ageaResidente: string;
  calle: string;
  num1: string;
  num2: string;
  cp: string;
  referencias: string;
  comentario: string;
  error: boolean;
}

export const datosUbicacionInitial: IDatosUbicacion = {
  loaded: false,
  territorioOriginario: '',
  ageeOriginario: '',
  agemOriginario: '',
  agelOriginario: '',
  territorioResidente: '',
  ageeResidente: '',
  agemResidente: '',
  agelResidente: '',
  ageaResidente: '',
  calle: '',
  num1: '',
  num2: '',
  cp: '',
  referencias: '',
  comentario: '',
  error: false,
};
