import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  getCatalogoAgem,
  getCatalogoAgel,
  getCatalogoAgea,
  getCatEstablecimientoSalud,
} from '@utils/getCatalogos';
import obtenerDatosGeograficos from '@utils/geografia';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { RootState } from 'src/store';
import { ICatStrEstandar, ICatalogosEstandar } from '@common/types';
import { validacionSoloNumeros, validacionEmail } from '@constants/validaciones';
import useStyles from './styles';
import {
  ICatalogoAGEE,
  ICatalogoAGEM,
  ICatalogoAGEL,
  ICatalogoAGEA,
} from '../../../../TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { datosConsultorioInitial } from '../../../mockDatosInitial';
import { IFormularioConsultoriosProps } from './types';
import { IDatosConsultorio } from '../../../types';

function FormularioConsultorios({
  consultorios,
  setConsultorios,
  idActualizar,
  setIdActualizar,
  catAGEE,
  catTipoEstablecimiento,
  objConsultorio,
  setObjConsultorio,
}: IFormularioConsultoriosProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pais } = useSelector((state: RootState) => state.Me);
  const recargarAgeMRef = useRef(false);
  const recargarAgeLRef = useRef(false);
  const recargarAgeARef = useRef(false);
  const recargarClinicaHospitalRef = useRef(false);
  const [refAgeM, setRefAgeM] = useState<boolean>(true);
  const [refAgeL, setRefAgeL] = useState<boolean>(true);
  const [refAgeA, setRefAgeA] = useState<boolean>(true);
  const [catAgeM, setCatAgeM] = useState<Array<ICatalogoAGEM>>([]);
  const [catAgeL, setCatAgeL] = useState<Array<ICatalogoAGEL>>([]);
  const [catAgeA, setCatAgeA] = useState<ICatalogoAGEA[]>([]);
  const [catClinicaHospital, setCatClinicaHospital] = useState<ICatStrEstandar[]>([]);
  const [errores, setErrores] = useState({
    email: { error: false, helperText: '' },
  });

  const geo = obtenerDatosGeograficos(pais);
  const clearFormulario = () => {
    setObjConsultorio(datosConsultorioInitial);
    setCatAgeM([]);
    setCatAgeL([]);
    setCatAgeA([]);
    setIdActualizar(-1);
    setRefAgeM(true);
    setRefAgeL(true);
    setRefAgeA(true);
  };
  const handleChangeClinicaHospital = (event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setObjConsultorio({ ...objConsultorio, clinicaHospital: newValue });
    } else {
      setObjConsultorio({ ...objConsultorio, clinicaHospital: { id: '', label: '' } });
    }
  };
  const handleChangeTipoEstablecimiento = (event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue) {
      setObjConsultorio({ ...objConsultorio, tipoEstablecimiento: newValue });
    } else {
      setObjConsultorio({ ...objConsultorio, tipoEstablecimiento: { id: 0, label: '' } });
    }
  };
  const handleChangeAgea = (event: any, newValue: ICatalogoAGEA | null) => {
    if (newValue) {
      setObjConsultorio({ ...objConsultorio, agea: newValue });
    } else {
      setObjConsultorio({ ...objConsultorio, agea: null });
    }
  };
  const handleAgemShow = () => {
    setRefAgeM(false);
  };
  const handleAgemHide = () => {
    setRefAgeM(true);
  };
  const handleAgelShow = () => {
    setRefAgeL(false);
  };
  const handleAgelHide = () => {
    setRefAgeL(true);
  };
  const handleAgeaHide = () => {
    setRefAgeA(true);
  };
  const handleAgeaShow = () => {
    setRefAgeA(false);
  };
  const handleChangeAgee = (event: any, newValue: ICatalogoAGEE | null) => {
    if (newValue) {
      setObjConsultorio({ ...objConsultorio, agee: newValue });
    } else {
      setObjConsultorio({ ...objConsultorio, agee: null });
    }
  };
  const handleChangeAgem = (event: any, newValue: ICatalogoAGEM | null) => {
    if (newValue) {
      setObjConsultorio({ ...objConsultorio, agem: newValue });
    } else {
      setObjConsultorio({ ...objConsultorio, agem: null });
    }
  };
  const handleChangeAgel = (event: any, newValue: ICatalogoAGEL | null) => {
    if (newValue) {
      setObjConsultorio({ ...objConsultorio, agel: newValue });
    } else {
      setObjConsultorio({ ...objConsultorio, agel: null });
    }
  };
  const handleChangeEstaEnHospital = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target as HTMLInputElement;
    setObjConsultorio({
      ...objConsultorio,
      estaEnHospital: val.value,
      tienePacientes: 0,
      label: '',
      clinicaHospital: { id: '', label: '' },
      tipoEstablecimiento: { id: 0, label: '' },
      otroEstablecimiento: '',
      nombreEstablecimiento: '',
    });
  };
  const handleChangeCalle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, calle: val });
  };
  const handleChangeNombreEstablecimiento = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, nombreEstablecimiento: val });
  };
  const handleChangeOtroEstablecimiento = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, otroEstablecimiento: val });
  };
  const handleChangeNum1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, num1: val });
  };
  const handleChangeNum2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, num2: val });
  };
  const handleChangeCP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjConsultorio({ ...objConsultorio, cp: val });
    }
  };
  const handleChangeTelefono1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjConsultorio({ ...objConsultorio, telefono1: val });
    }
  };
  const handleChangeExtension1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjConsultorio({ ...objConsultorio, extension1: val });
    }
  };
  const handleChangeTelefono2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjConsultorio({ ...objConsultorio, telefono2: val });
    }
  };
  const handleChangeExtension2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjConsultorio({ ...objConsultorio, extension2: val });
    }
  };
  const handleChangeTelefono3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjConsultorio({ ...objConsultorio, telefono3: val });
    }
  };
  const handleChangeExtension3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setObjConsultorio({ ...objConsultorio, extension3: val });
    }
  };
  const handleChangeNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, nombre: val });
  };
  const handleChangeCorreoElectronico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, correoElectronico: val.toLowerCase() });
    setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
  };
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        email: { helperText: t('email_incorrecto'), error: true },
      }));
    }
  };
  const handleChangeSitioWeb = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjConsultorio({ ...objConsultorio, sitioWeb: val });
  };
  const handleDeleteTel2 = () => {
    if (objConsultorio.showTel3) {
      setObjConsultorio({
        ...objConsultorio,
        telefono2: objConsultorio.telefono3,
        extension2: objConsultorio.extension3,
        telefono3: '',
        extension3: '',
        showTel3: false,
        showDelete3: false,
      });
    } else {
      setObjConsultorio({
        ...objConsultorio,
        showTel2: false,
        showDelete2: false,
        telefono2: '',
        extension2: '',
      });
    }
  };
  const handleDeleteTel3 = () => {
    setObjConsultorio({
      ...objConsultorio,
      telefono3: '',
      extension3: '',
      showTel3: false,
      showDelete3: false,
    });
  };
  const handleAddTelefono = () => {
    if (objConsultorio.showTel2) {
      setObjConsultorio({
        ...objConsultorio,
        showTel3: true,
      });
    } else {
      setObjConsultorio({
        ...objConsultorio,
        showTel2: true,
      });
    }
  };
  const handleAddConsultorio = () => {
    const numeroID =
      idActualizar >= 0 ? consultorios[idActualizar].idConsultorio : objConsultorio.idConsultorio;
    const obj: IDatosConsultorio = {
      idConsultorio: numeroID < 0 ? -1 : numeroID,
      nombre: objConsultorio.nombre || '',
      /* eslint-disable no-nested-ternary */
      label:
        objConsultorio.estaEnHospital === 'si'
          ? objConsultorio.clinicaHospital.label
          : objConsultorio.tipoEstablecimiento.id === 7
            ? `${objConsultorio.otroEstablecimiento.toUpperCase()} ${objConsultorio.nombreEstablecimiento.toUpperCase()}`
            : `${objConsultorio.tipoEstablecimiento.label.toUpperCase()} ${objConsultorio.nombreEstablecimiento.toUpperCase()}`,
      clinicaHospital: objConsultorio.clinicaHospital || { id: '', label: '' },
      estaEnHospital: objConsultorio.estaEnHospital,
      agee: objConsultorio.agee || null,
      agem: objConsultorio.agem || null,
      agel: objConsultorio.agel || null,
      agea: objConsultorio.agea || null,
      calle: objConsultorio.calle || '',
      num1: objConsultorio.num1 || '',
      num2: objConsultorio.num2 || '',
      cp: objConsultorio.cp || '',
      telefono1: objConsultorio.telefono1 || '',
      extension1: objConsultorio.extension1 || '',
      telefono2: objConsultorio.telefono2 || '',
      extension2: objConsultorio.extension2 || '',
      telefono3: objConsultorio.telefono3 || '',
      extension3: objConsultorio.extension3 || '',
      correoElectronico: objConsultorio.correoElectronico || '',
      sitioWeb: objConsultorio.sitioWeb || '',
      tienePacientes: 0,
      showDelete2: false,
      showDelete3: false,
      showTel2: false,
      showTel3: false,
      tipoEstablecimiento: objConsultorio.tipoEstablecimiento || { id: 0, label: '' },
      otroEstablecimiento: objConsultorio.otroEstablecimiento || '',
      nombreEstablecimiento: objConsultorio.nombreEstablecimiento || '',
      borrado: objConsultorio.borrado || false,
      actualizado: numeroID > 0,
    };
    if (idActualizar >= 0) {
      setConsultorios((prevCons) => {
        const nuevoArr = [...prevCons];
        nuevoArr[idActualizar] = obj;
        return nuevoArr;
      });
    } else {
      const newConsultorios = [...consultorios];
      newConsultorios.push(obj);
      setConsultorios([...newConsultorios]);
    }
    clearFormulario();
  };
  const checkDisableAddConsultorio = () =>
    (objConsultorio.estaEnHospital === 'si' &&
      ((geo.agee && geo.agee.required && !objConsultorio.agee) ||
        (geo.agem && geo.agem.required && !objConsultorio.agem) ||
        (geo.agel && geo.agel.required && !objConsultorio.agel) ||
        (geo.calle && geo.calle.required && !objConsultorio.calle) ||
        !objConsultorio.clinicaHospital.id ||
        !objConsultorio.nombre ||
        !objConsultorio.telefono1 ||
        errores.email.error)) ||
    (objConsultorio.estaEnHospital === 'no' &&
      ((geo.agee && geo.agee.required && !objConsultorio.agee) ||
        (geo.agem && geo.agem.required && !objConsultorio.agem) ||
        (geo.agel && geo.agel.required && !objConsultorio.agel) ||
        (geo.calle && geo.calle.required && !objConsultorio.calle) ||
        objConsultorio.tipoEstablecimiento.id === 0 ||
        (objConsultorio.tipoEstablecimiento.id === 7 &&
          objConsultorio.otroEstablecimiento.length === 0) ||
        !objConsultorio.nombreEstablecimiento ||
        !objConsultorio.nombre ||
        !objConsultorio.telefono1 ||
        errores.email.error));

  useEffect(() => {
    if (objConsultorio.agee) {
      handleAgemShow();
      setObjConsultorio({
        ...objConsultorio,
        agem: null,
      });
      getCatalogoAgem(objConsultorio.agee.cve_agee).then((result: ICatalogoAGEM[]) => {
        setCatAgeM(result.map((mun) => ({ ...mun, nom_agem: mun.nom_agem })));
        dispatch(setLoading(false));
      });
    } else {
      handleAgemHide();
      setObjConsultorio({
        ...objConsultorio,
        agem: null,
        clinicaHospital: { id: '', label: '' },
      });
      dispatch(setLoading(false));
    }
  }, [objConsultorio.agee]);
  useEffect(() => {
    if (objConsultorio.agem && objConsultorio.agem.cve_agee) {
      dispatch(setLoading(true));
      getCatEstablecimientoSalud(objConsultorio.agem.cve_agee, objConsultorio.agem.cve_agem)
        .then((response) => response.json())
        .then((result) => {
          setCatClinicaHospital(result.datos);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
      handleAgelShow();
      getCatalogoAgel(objConsultorio.agem.cve_agee, objConsultorio.agem.cve_agem).then(
        (result: ICatalogoAGEL[]) => {
          setCatAgeL(result.map((loc) => ({ ...loc, nom_agel: loc.nom_agel })));
          dispatch(setLoading(false));
        },
      );
      setObjConsultorio({
        ...objConsultorio,
        agel: null,
        clinicaHospital: { id: '', label: '' },
      });
    } else {
      handleAgelHide();
      setObjConsultorio({
        ...objConsultorio,
        agel: null,
        clinicaHospital: { id: '', label: '' },
      });
    }
  }, [objConsultorio.agem]);
  useEffect(() => {
    if (recargarAgeMRef.current) {
      recargarAgeMRef.current = false;
      setObjConsultorio({
        ...objConsultorio,
        agem: consultorios[idActualizar].agem,
      });
    }
  }, [catAgeM]);
  useEffect(() => {
    if (recargarAgeLRef.current) {
      recargarAgeLRef.current = false;
      setObjConsultorio({
        ...objConsultorio,
        agel: consultorios[idActualizar].agel,
      });
    }
  }, [catAgeL]);
  useEffect(() => {
    if (recargarAgeARef.current) {
      recargarAgeARef.current = false;
      setObjConsultorio({
        ...objConsultorio,
        agea: consultorios[idActualizar].agea,
      });
    }
  }, [catAgeA]);
  useEffect(() => {
    if (recargarClinicaHospitalRef.current) {
      recargarClinicaHospitalRef.current = false;
      setObjConsultorio({
        ...objConsultorio,
        clinicaHospital: consultorios[idActualizar].clinicaHospital,
      });
    }
  }, [catClinicaHospital]);
  useEffect(() => {
    if (idActualizar >= 0) {
      dispatch(setLoading(true));
      recargarAgeMRef.current = true;
      recargarAgeLRef.current = true;
      recargarAgeARef.current = true;
      recargarClinicaHospitalRef.current = true;
      setRefAgeM(false);
      setRefAgeL(false);
      setRefAgeA(false);
      setObjConsultorio({
        ...consultorios[idActualizar],
        showTel2: !!consultorios[idActualizar].telefono2,
        showTel3: !!consultorios[idActualizar].telefono3,
      });
      dispatch(setLoading(false));
    }
  }, [idActualizar]);
  useEffect(() => {
    if (objConsultorio.agel && objConsultorio.agel.cve_agel) {
      dispatch(setLoading(true));
      handleAgeaShow();
      setObjConsultorio({
        ...objConsultorio,
        agea: null,
      });
      getCatalogoAgea(
        objConsultorio.agel.cve_agee,
        objConsultorio.agel.cve_agem,
        objConsultorio.agel.cve_agel,
      )
        .then((result: ICatalogoAGEA[]) => {
          setCatAgeA(result.map((col) => ({ ...col, nom_agea: col.nom_agea })));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
    } else {
      handleAgeaHide();
      setObjConsultorio({
        ...objConsultorio,
        agea: null,
      });
    }
  }, [objConsultorio.agel]);

  return (
    <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
      <div className="flex flex-col justify-center">
        <h1 className="text-blue-500 font-normal m-2 text-center">{t('medico_consultorios')}</h1>
      </div>
      {pais === 'mx' && (
        <div className="py-4">
          <FormControl component="fieldset">
            <FormLabel>{`${t('administrador_dentro_clinica_hospital')}*`}</FormLabel>
            <RadioGroup
              value={objConsultorio.estaEnHospital}
              onChange={handleChangeEstaEnHospital}
              aria-label="esta-dentro-clinica-hospitla"
              name="esta-clinica-hospital"
              row
            >
              <FormControlLabel
                value="si"
                control={<Radio color="primary" />}
                label={t('si')}
                disabled={!!objConsultorio.tienePacientes}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={t('no')}
                disabled={!!objConsultorio.tienePacientes}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <hr />
      {geo.agee && (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="font-normal m-0 text-gray-500 mb-2">
              {`${t(`${geo.agee.label}`)}${geo.agee.required ? '*' : ''}`}
            </h3>
            <Autocomplete
              options={catAGEE}
              getOptionLabel={(option) => option.nom_agee}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={objConsultorio.agee}
              onChange={(e, nV) => handleChangeAgee(e, nV)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
              disabled={!!objConsultorio.tienePacientes}
            />
          </div>
          {geo.agem && (
            <div>
              <h3 className="font-normal m-0 text-gray-500 mb-2">
                {`${t(`${geo.agem.label}`)}${geo.agem.required ? '*' : ''}`}
              </h3>
              <Autocomplete
                disabled={refAgeM || !!objConsultorio.tienePacientes}
                options={catAgeM}
                getOptionLabel={(option) => option.nom_agem}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={objConsultorio.agem}
                onChange={(e, nV) => handleChangeAgem(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
          )}
        </div>
      )}
      {geo.agel && (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-2">
              {`${t(`${geo.agel.label}`)}${geo.agel.required ? '*' : ''}`}
            </h3>
            <Autocomplete
              disabled={refAgeL || !!objConsultorio.tienePacientes}
              options={catAgeL}
              getOptionLabel={(option) => option.nom_agel}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={objConsultorio.agel}
              onChange={(e, nV) => handleChangeAgel(e, nV)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
          {geo.agea && (
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-2">
                {`${t(`${geo.agea.label}`)}${geo.agea.required ? '*' : ''}`}
              </h3>
              <Autocomplete
                disabled={refAgeA || !!objConsultorio.tienePacientes}
                id="combo-box-agea-residente"
                options={catAgeA}
                getOptionLabel={(option) => option.nom_agea}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={objConsultorio.agea}
                onChange={(e, nV) => handleChangeAgea(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
          )}
        </div>
      )}
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-9">
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {`${t(`${geo.calle?.label}`)}${geo.calle?.required ? '*' : ''}`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 150 }}
            onChange={handleChangeCalle}
            value={objConsultorio.calle}
            disabled={!!objConsultorio.tienePacientes}
          />
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {`${t(`${geo.num1?.label}`)}${geo.num1?.required ? '*' : ''}`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
            onChange={handleChangeNum1}
            value={objConsultorio.num1}
            disabled={!!objConsultorio.tienePacientes}
          />
        </div>
        {geo.num2 && (
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-2">
              {`${t(`${geo.num2.label}`)}${geo.num2.required ? '*' : ''}`}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              onChange={handleChangeNum2}
              value={objConsultorio.num2}
              disabled={!!objConsultorio.tienePacientes}
            />
          </div>
        )}
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {`${t(`${geo.cp?.label}_av`)}${geo.cp?.required ? '*' : ''}`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 5 }}
            onChange={handleChangeCP}
            value={objConsultorio.cp}
            disabled={!!objConsultorio.tienePacientes}
          />
        </div>
      </div>
      {objConsultorio.estaEnHospital === 'si' && (
        <div className="mt-4">
          <h3 className="font-normal m-0 text-gray-500 mb-2">
            {`${t('nombre_de_la_clinica_hospital')}*`}
          </h3>
          <Autocomplete
            options={catClinicaHospital}
            getOptionLabel={(option) => option.label}
            fullWidth
            noOptionsText={t('sin_resultados')}
            value={objConsultorio.clinicaHospital}
            onChange={(e, nV) => handleChangeClinicaHospital(e, nV)}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
            disabled={!!objConsultorio.tienePacientes}
          />
        </div>
      )}
      {objConsultorio.estaEnHospital === 'no' && (
        <>
          <div className="flex items-center mt-4">
            <div className="w-4/12">
              <h3 className="font-normal m-0 text-gray-500 mb-2">
                {`${t('administrador_tipo_establecimiento')}*`}
              </h3>
              <Autocomplete
                options={catTipoEstablecimiento}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={objConsultorio.tipoEstablecimiento}
                onChange={(e, nV) => handleChangeTipoEstablecimiento(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
                disabled={!!objConsultorio.tienePacientes}
              />
            </div>
            {objConsultorio.tipoEstablecimiento && objConsultorio.tipoEstablecimiento.id === 7 && (
              <div className="w-4/12 ml-4">
                <h3 className="text-gray-500 font-normal m-0 mb-2">
                  {`${t('administrador_otro_establecimiento')}*`}
                </h3>
                <TextField
                  variant="outlined"
                  fullWidth
                  inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
                  onChange={handleChangeOtroEstablecimiento}
                  value={objConsultorio.otroEstablecimiento}
                  disabled={!!objConsultorio.tienePacientes}
                />
              </div>
            )}
            <div className="w-4/12 ml-4">
              <h3 className="text-gray-500 font-normal m-0 mb-2">
                {`${t('administrador_nombre_establecimiento')}*`}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 150 }}
                onChange={handleChangeNombreEstablecimiento}
                value={objConsultorio.nombreEstablecimiento}
                disabled={!!objConsultorio.tienePacientes}
              />
            </div>
          </div>
        </>
      )}
      <div className="w-full my-4">
        <h3 className="text-gray-500 font-normal m-0 mb-2">
          {`${t('nombre_o_numero_de_consultorio')}*`}
        </h3>
        <TextField
          variant="outlined"
          fullWidth
          inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 80 }}
          onChange={handleChangeNombre}
          value={objConsultorio.nombre}
          disabled={!!objConsultorio.tienePacientes}
        />
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="grid grid-cols-6 gap-4 items-end">
          <div className="col-span-3">
            <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_con_lada')}*`}</h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              onChange={handleChangeTelefono1}
              value={objConsultorio.telefono1}
            />
          </div>
          <div className="col-span-2">
            <h3 className="text-gray-500 font-normal m-0 mb-2">{t('extension')}</h3>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={t('numero')}
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              value={objConsultorio.extension1}
              onChange={handleChangeExtension1}
            />
          </div>
        </div>
        {objConsultorio.showTel2 && (
          <div
            onMouseEnter={() => setObjConsultorio({ ...objConsultorio, showDelete2: true })}
            onMouseLeave={() => setObjConsultorio({ ...objConsultorio, showDelete2: false })}
            className="grid grid-cols-6 gap-4 mt-4 items-end"
          >
            <div className="col-span-3">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_con_lada')}*`}</h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeTelefono2}
                value={objConsultorio.telefono2}
              />
            </div>
            <div className="col-span-2">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{t('extension')}</h3>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={t('numero')}
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeExtension2}
                value={objConsultorio.extension2}
              />
            </div>
            {objConsultorio.showDelete2 && (
              <div className="self-center">
                <IconButton aria-label="delete" onClick={handleDeleteTel2} color="primary">
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}
        {objConsultorio.showTel3 && (
          <div
            onMouseEnter={() => setObjConsultorio({ ...objConsultorio, showDelete3: true })}
            onMouseLeave={() => setObjConsultorio({ ...objConsultorio, showDelete3: false })}
            className="grid grid-cols-6 gap-4 mt-4 items-end"
          >
            <div className="col-span-3">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_con_lada')}*`}</h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeTelefono3}
                value={objConsultorio.telefono3}
              />
            </div>
            <div className="col-span-2">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{t('extension')}</h3>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={t('numero')}
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeExtension3}
                value={objConsultorio.extension3}
              />
            </div>
            {objConsultorio.showDelete3 && (
              <div className="self-center">
                <IconButton aria-label="delete" onClick={handleDeleteTel3} color="primary">
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}
        {(!objConsultorio.showTel2 || !objConsultorio.showTel3) && (
          <div className="flex items-end">
            <Button
              color="primary"
              size="small"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddTelefono}
              disabled={objConsultorio.showTel2 && objConsultorio.showTel3}
            >
              {t('agregar_telefono')}
            </Button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {t('correo_electronico_del_consultorio')}
          </h3>
          <TextField
            fullWidth
            variant="outlined"
            type="email"
            InputProps={{
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 },
            }}
            onChange={handleChangeCorreoElectronico}
            value={objConsultorio.correoElectronico}
            onBlur={handleBlurEmail}
            helperText={errores.email.helperText}
            error={errores.email.error}
            FormHelperTextProps={{ className: classes.absoluteBottom }}
          />
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">{t('sitio_web')}</h3>
          <TextField
            fullWidth
            variant="outlined"
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
            onChange={handleChangeSitioWeb}
            value={objConsultorio.sitioWeb}
          />
        </div>
      </div>
      <div className="flex justify-between my-4 items-center">
        <span className="text-gray-500">
          *
          {t('campos_obligatorios')}
        </span>
        {idActualizar >= 0 ? (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleAddConsultorio}
            disabled={checkDisableAddConsultorio()}
          >
            {t('actualizar')}
          </Button>
        ) : (
          <Button
            color="primary"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddConsultorio}
            disabled={checkDisableAddConsultorio()}
          >
            {t('agregar_otro_consultorio')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default FormularioConsultorios;
