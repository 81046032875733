import { RawDraftContentState } from 'draft-js';
import {
  ICatalogosCie10, IEdad, IFechaObj, edadInitial,
} from '@common/types';

export interface IFechaComentarios {
  fecha: string;
  comentario: RawDraftContentState;
}

export interface IEnfermedad {
  idBD: number;
  seleccionDiagnostico: 'cie10' | 'medico';
  diagnosticoCie10: ICatalogosCie10;
  diagnosticoMedico: string;
  tipoDiagnostico: 'cronico' | 'agudo' | '';
  certezaDelDiagnostico: 'presuntivo' | 'confirmado' | '';
  estatusDelDiagnostico: 'activo' | 'resuelto' | '';
  edadAlDiagnostico: IEdad;
  fechaRegistroDiagnostico: IFechaObj;
  comentarios: IFechaComentarios[];
  idConsulta: number;
}

export const enfermedadInitial: IEnfermedad = {
  idBD: -1,
  seleccionDiagnostico: 'cie10',
  diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
  diagnosticoMedico: '',
  tipoDiagnostico: '',
  certezaDelDiagnostico: '',
  estatusDelDiagnostico: '',
  edadAlDiagnostico: edadInitial,
  fechaRegistroDiagnostico: { dia: '', mes: '', anio: '' },
  comentarios: [],
  idConsulta: -1,
};

export interface IEnfermedadesForm {
  idBD: number;
  seleccionDiagnostico: 'cie10' | 'medico';
  diagnosticoCie10: ICatalogosCie10;
  diagnosticoMedico: string;
  tipoDiagnostico: 'cronico' | 'agudo' | '';
  certezaDelDiagnostico: 'presuntivo' | 'confirmado' | '';
  estatusDelDiagnostico: 'activo' | 'resuelto' | '';
  edadAlDiagnostico: IEdad;
  fechaRegistroDiagnostico: IFechaObj;
  comentario: RawDraftContentState;
  idConsulta: number;
}

export const enfermedadesFormInitial: IEnfermedadesForm = {
  idBD: -1,
  seleccionDiagnostico: 'cie10',
  diagnosticoCie10: { id: '', label: '', grupoEnfermedades: 0 },
  diagnosticoMedico: '',
  tipoDiagnostico: '',
  certezaDelDiagnostico: '',
  estatusDelDiagnostico: '',
  edadAlDiagnostico: edadInitial,
  fechaRegistroDiagnostico: { dia: '', mes: '', anio: '' },
  comentario: { entityMap: {}, blocks: [] },
  idConsulta: -1,
};

export interface IEnfermedadNegada {
  idBD: number;
  diagnosticoCie10: ICatalogosCie10;
}

export interface IGrupoEnfermedadesNegadas {
  infecciosasYParasitariasSelected: boolean;
  infecciosasYParasitariasDisabled: boolean;
  neoplasiasSelected: boolean;
  neoplasiasDisabled: boolean;
  hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected: boolean;
  hematologicasOQueAfectanElMecanismoDeLaInmunidadDisabled: boolean;
  endocrinasNutricionalesYMetabolicasSelected: boolean;
  endocrinasNutricionalesYMetabolicasDisabled: boolean;
  trastornosMentalesYDelComportamientoSelected: boolean;
  trastornosMentalesYDelComportamientoDisabled: boolean;
  sistemaNerviosoSelected: boolean;
  sistemaNerviosoDisabled: boolean;
  delOjoYSusAnexosSelected: boolean;
  delOjoYSusAnexosDisabled: boolean;
  delOidoYApofisisMastoideSelected: boolean;
  delOidoYApofisisMastoideDisabled: boolean;
  sistemaCirculatorioSelected: boolean;
  sistemaCirculatorioDisabled: boolean;
  sistemaRespiratorioSelected: boolean;
  sistemaRespiratorioDisabled: boolean;
  sistemaDigestivoSelected: boolean;
  sistemaDigestivoDisabled: boolean;
  pielYDelTejidoSubcutaneoSelected: boolean;
  pielYDelTejidoSubcutaneoDisabled: boolean;
  sistemaOsteomuscularYDelTejidoConjuntivoSelected: boolean;
  sistemaOsteomuscularYDelTejidoConjuntivoDisabled: boolean;
  sistemaGenitourinarioSelected: boolean;
  sistemaGenitourinarioDisabled: boolean;
  embarazoPartoPuerperioSelected: boolean;
  embarazoPartoPuerperioDisabled: boolean;
  delPeriodoPerinatalSelected: boolean;
  delPeriodoPerinatalDisabled: boolean;
  malformaconesCongenitasYAnomaliasCongenitasSelected: boolean;
  malformaconesCongenitasYAnomaliasCongenitasDisabled: boolean;
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected: boolean;
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasDisabled: boolean;
}

export const grupoEnfermedadesNegadasInitial: IGrupoEnfermedadesNegadas = {
  infecciosasYParasitariasSelected: false,
  infecciosasYParasitariasDisabled: false,
  neoplasiasSelected: false,
  neoplasiasDisabled: false,
  hematologicasOQueAfectanElMecanismoDeLaInmunidadSelected: false,
  hematologicasOQueAfectanElMecanismoDeLaInmunidadDisabled: false,
  endocrinasNutricionalesYMetabolicasSelected: false,
  endocrinasNutricionalesYMetabolicasDisabled: false,
  trastornosMentalesYDelComportamientoSelected: false,
  trastornosMentalesYDelComportamientoDisabled: false,
  sistemaNerviosoSelected: false,
  sistemaNerviosoDisabled: false,
  delOjoYSusAnexosSelected: false,
  delOjoYSusAnexosDisabled: false,
  delOidoYApofisisMastoideSelected: false,
  delOidoYApofisisMastoideDisabled: false,
  sistemaCirculatorioSelected: false,
  sistemaCirculatorioDisabled: false,
  sistemaRespiratorioSelected: false,
  sistemaRespiratorioDisabled: false,
  sistemaDigestivoSelected: false,
  sistemaDigestivoDisabled: false,
  pielYDelTejidoSubcutaneoSelected: false,
  pielYDelTejidoSubcutaneoDisabled: false,
  sistemaOsteomuscularYDelTejidoConjuntivoSelected: false,
  sistemaOsteomuscularYDelTejidoConjuntivoDisabled: false,
  sistemaGenitourinarioSelected: false,
  sistemaGenitourinarioDisabled: false,
  embarazoPartoPuerperioSelected: false,
  embarazoPartoPuerperioDisabled: false,
  delPeriodoPerinatalSelected: false,
  delPeriodoPerinatalDisabled: false,
  malformaconesCongenitasYAnomaliasCongenitasSelected: false,
  malformaconesCongenitasYAnomaliasCongenitasDisabled: false,
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasSelected: false,
  traumatismoYEnvenenamientoYOtrasConsecuenciasDeCausasExternasDisabled: false,
};

export interface IEnfermedadesProps {
  eActivas: IEnfermedad[];
  eResueltas: IEnfermedad[];
}
