import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroupProps } from './type';

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  name,
  containerClass = '',
  label: externalLabel,
  options,
  isRequired = false,
  layout = 'column',
  allowMultiple = false,
  labelPosition = 'top',
  distribution = 'default',
  helperText,
  numberPosition = -1,
  uniqueAction,
  setValue,
  onClick,
  // disableOption,
  disabled,
  value: externalValue,
}) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isUniqueAction, setIsUniqueAction] = useState<boolean>(false);
  const label = externalLabel === undefined ? t(name) : externalLabel;

  useEffect(() => {
    setCheckedItems(externalValue
      ? Array.isArray(externalValue) ? externalValue : [externalValue]
      : [],
    );
  }, [externalValue]);

  const handleChange = (value: string, option: any) => {
    let newCheckedItems: string[];

    if (allowMultiple) {
      if (value === 'todos_servicios') {
        newCheckedItems = ['todos_servicios'];
      } else {
        const withoutTodos = checkedItems.filter((item) => item !== 'todos_servicios');
        newCheckedItems = withoutTodos.includes(value)
          ? withoutTodos.filter((item) => item !== value)
          : [...withoutTodos, value];
      }
    } else {
      newCheckedItems = [value];
    }

    setCheckedItems(newCheckedItems);

    const syntheticEvent = {
      name,
      value: allowMultiple ? newCheckedItems : value,
      type: allowMultiple ? 'checkbox' : 'radio',
    };

    setValue(syntheticEvent);

    if (onClick) {
      onClick(value, option);
    }

    if (option.action && (!uniqueAction || !isUniqueAction)) {
      option.action();
      if (uniqueAction) {
        setIsUniqueAction(true);
      }
    }
  };

  const handleEdit = (e: React.MouseEvent, option: any) => {
    e.preventDefault();
    if (onClick) {
      onClick(option.value, option);
    }
  };

  const renderLabel = () => {
    if (!label) return null;

    return (
      <div className="flex items-center gap-2">
        <span className={`block font-medium text-base text-gray-700 ${disabled ? 'text-gray-500' : ''}`}>
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </span>
      </div>
    );
  };

  const renderCheckboxInput = (option: any) => {
    const isCheckedWithModal = checkedItems.includes(option.value) && option.modal;

    return (
      <div key={option.value} className="gap-2">
        <label className="flex items-center">
          {isCheckedWithModal && (
            <button
              onClick={(e) => handleEdit(e, option)}
              className="text-sm text-blue-600 hover:text-blue-800 underline pr-6"
            >
              Editar
            </button>
          )}

          <input
            type={allowMultiple ? 'checkbox' : 'radio'}
            name={name}
            value={option.value}
            checked={checkedItems.includes(option.value)}
            onChange={() => handleChange(option.value, option)}
            required={isRequired && checkedItems.length === 0}
            disabled={disabled}
            className="w-4 h-4 text-blue-500 border-gray-300 focus:ring-blue-500"
          />

          <span className="ml-2 font-normal text-base text-gray-700">
            {option.label}
            {disabled}
          </span>
        </label>
      </div>
    );
  };

  const renderOptions = () => {
    switch (distribution) {
      case 'horizontal-inline':
        return (
          <div
            className={`flex justify-between w-full gap-4 p-4 ${
              numberPosition === -1 ? '' : 'border-[#D1D5DB] border-b-[1px]'
            } ${numberPosition % 2 === 0 ? 'bg-[#F3F4F6]' : ''}`}
          >
            {label && (
              <label htmlFor={name} className="font-medium text-base text-gray-700 w-[70%]">
                {label}
                {isRequired && <span className="text-red-500 ml-1">*</span>}
              </label>
            )}
            <div className="w-fit flex justify-between align-middle gap-6">
              {options.map((option) => renderCheckboxInput(option))}
            </div>
          </div>
        );

      case 'grid':
        return (
          <div className="w-full px-4 pb-2">
            <div className="space-y-2">
              {labelPosition === 'top' && renderLabel()}
              <div className={labelPosition === 'inline' ? 'flex gap-4 items-start' : ''}>
                {labelPosition === 'inline' && renderLabel()}
                <div className="flex flex-wrap w-fit">
                  {options.map((option) => (
                    <div key={option.value} className="w-1/2 mb-2">
                      {renderCheckboxInput(option)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 'vertical':
        return (
          <div
            className={`w-full h-auto px-4 mb-4 ${labelPosition === 'inline' ? 'flex gap-4' : ''}`}
          >
            {labelPosition === 'top' && renderLabel()}
            <div className="mt-3">
              {labelPosition === 'inline' && renderLabel()}
              <div className="flex flex-col gap-2">
                {options.map((option) => renderCheckboxInput(option))}
              </div>
            </div>
          </div>
        );

      case 'vertical-flex':
        return (
          <div
            className={`flex flex-col w-full mb-4 px-4 ${
              numberPosition === -1 ? '' : 'border-[#D1D5DB] border-b-[1px]'
            } ${numberPosition % 2 === 0 ? 'bg-[#F3F4F6]' : ''}`}
          >
            <div className="font-medium text-base text-gray-700 mb-2">
              {label}
              {isRequired && <span className="text-red-500 ml-1">*</span>}
            </div>
            <div className="flex gap-6">{options.map((option) => renderCheckboxInput(option))}</div>
          </div>
        );

      default:
        return (
          <div className="w-full h-auto px-4">
            <div className={labelPosition === 'inline' ? 'flex items-center gap-4' : ''}>
              {labelPosition === 'top' && renderLabel()}
              <div className={labelPosition === 'inline' ? 'flex gap-4' : ''}>
                {labelPosition === 'inline' && renderLabel()}
                <div className={`flex ${layout === 'column' ? 'flex-col' : 'flex-row space-x-4'}`}>
                  {options.map((option) => renderCheckboxInput(option))}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className={`w-full h-auto ${containerClass}`}>
      {renderOptions()}
      {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
    </div>
  );
};

export default CheckboxGroup;
