import { combineReducers } from 'redux';
import Auth from './Auth/auth';
import BasicosPacienteReducer from './DatosBasicosPaciente';
import RutasExpediente from './rutasExpediente';
import Paciente from './paciente';
import LoadingReducer from './LoadingReducer';
import LoginReducer from './LoginReducer';
import MeReducer from './MeReducer';
import RequestReducer from './Request';
import RequestFileReducer from './RequestFile';
import SnackReducer from './SnackBar';
import CheckSave from './checkSaves';
import Consulta from './consulta';
import Modal from './Modal';
import Consultorios from './consultorios';
import Organizaciones from './organizaciones';

export const rootReducer = combineReducers({
  Me: MeReducer,
  Login: LoginReducer,
  BasicosPaciente: BasicosPacienteReducer,
  Loading: LoadingReducer,
  CheckSave,
  SnackBar: SnackReducer,
  Modal,
  Request: RequestReducer,
  RequestFile: RequestFileReducer,
  Consultorios,
  Organizaciones,
  Consulta,
  Auth,
  RutasExpediente,
  Paciente,
});

export type RootState = ReturnType<typeof rootReducer>;
