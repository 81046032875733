import { Button, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { getSAOrganizacionLicencias } from '@utils/getCatalogos';
import { setLoading } from '@actions/loading/actions';
import { IDatosOtraOrganizacion } from '../../types';
import { IFormularioOrganizacionesProps } from './types';

function FormularioOrganizaciones({
  organizaciones,
  setOrganizaciones,
  idActualizar,
  setIdActualizar,
  objOrganizacion,
  setObjOrganizacion,
  licenciasOrganizacion,
  setLicenciasOrganizacion,
}: IFormularioOrganizacionesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico, email: emailSend } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [errorLicencias, setErrorLicencias] = useState({ error: false, helperText: '' });
  const clearFormulario = () => {
    setObjOrganizacion({
      ...objOrganizacion,
      id: -1,
      nombreOrganizacion: '',
      administradores: [],
      licencias: 0,
      licenciasUsadas: 0,
      licenciasCompartidas: 0,
      borrado: false,
      actualizado: false,
    });
    setIdActualizar(-1);
  };
  const handleChangeNombreOrganizacion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjOrganizacion({ ...objOrganizacion, nombreOrganizacion: val });
  };
  const handleChangeSuscripcionesAsignadas = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.target.value as string, 10);
    const licencias = Number.isNaN(val) || val < 0 ? 0 : val;
    let error = '';
    if (licencias > licenciasOrganizacion.disponibles) {
      error = t('administrador_error_suscripciones_mayor');
    } else if (objOrganizacion.id !== -1 && licencias < objOrganizacion.licenciasUsadas) {
      error = t('administrador_error_suscripciones_menor');
    }
    setErrorLicencias({
      error: error !== '',
      helperText: error,
    });
    setObjOrganizacion({
      ...objOrganizacion,
      licencias,
    });
  };
  const handleAddOrganizacion = () => {
    const numeroID = idActualizar >= 0 ? organizaciones[idActualizar].id : objOrganizacion.id;
    const obj: IDatosOtraOrganizacion = {
      id: numeroID < 0 ? -1 : numeroID,
      nombreOrganizacion: objOrganizacion.nombreOrganizacion || '',
      administradores: objOrganizacion.administradores || [],
      licencias: objOrganizacion.licencias || 0,
      licenciasUsadas: objOrganizacion.licenciasUsadas || 0,
      licenciasCompartidas: objOrganizacion.licenciasCompartidas || 0,
      borrado: objOrganizacion.borrado || false,
      actualizado: numeroID > 0,
    };
    if (idActualizar >= 0) {
      setOrganizaciones((prevOrg) => {
        const nuevoArr = [...prevOrg];
        nuevoArr[idActualizar] = obj;
        return nuevoArr;
      });
    } else {
      const newOrganizaciones = [...organizaciones];
      newOrganizaciones.push(obj);
      setOrganizaciones([...newOrganizaciones]);
    }
    setLicenciasOrganizacion({
      ...licenciasOrganizacion,
      disponibles: licenciasOrganizacion.disponibles - objOrganizacion.licencias,
    });
    clearFormulario();
  };

  const reviewLicenses = () => {
    dispatch(setLoading(true));
    getSAOrganizacionLicencias(idMedico, emailSend, idOrganizacion)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setLicenciasOrganizacion(result.data);
        } else if (result.code === 400) {
          setLicenciasOrganizacion({ totales: 0, disponibles: 0 });
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        setLicenciasOrganizacion({ totales: 0, disponibles: 0 });
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    // llamar función de conteo de licencias
    reviewLicenses();
  }, []);
  useEffect(() => {
    if (idActualizar >= 0) {
      setObjOrganizacion(organizaciones[idActualizar]);
      setLicenciasOrganizacion({
        ...licenciasOrganizacion,
        disponibles: licenciasOrganizacion.disponibles + organizaciones[idActualizar].licencias,
      });
    }
  }, [idActualizar]);

  return (
    <div className="p-4 bg-white">
      <div className="grid grid-cols-2 justify-center">
        <div className="col-span-1">
          <h3 className="text-green-500 font-bold m-2 text-center">
            {`${t('administrador_total_suscripciones')}: ${licenciasOrganizacion.totales}`}
          </h3>
        </div>
        <div className="col-span-1">
          <h3 className="text-green-500 font-bold m-2 text-center">
            {`${t('administrador_total_suscripciones_disponibles')}: ${
              licenciasOrganizacion.disponibles
            }`}
          </h3>
        </div>
      </div>
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <h1 className="text-blue-500 font-normal m-2 text-center">
          {t('administrador_otras_organizaciones')}
        </h1>
        <div className="w-full my-4">
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {`${t('administrador_mi_organizacion_nombre')}* (${t(
              'administrador_mi_organizacion_comercial',
            )})`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
            onChange={handleChangeNombreOrganizacion}
            value={objOrganizacion.nombreOrganizacion}
          />
        </div>
        <div className="grid grid-cols-12 justify-items-stretch gap-4">
          <div className="col-span-6">
            <div className="flex items-center">
              <h3 className="text-gray-500 font-normal m-0">
                {`${t('administrador_asignar_suscripciones')}*: `}
              </h3>
              <div className="ml-2 w-24">
                <TextField
                  variant="outlined"
                  inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                  onChange={handleChangeSuscripcionesAsignadas}
                  value={objOrganizacion.licencias}
                />
              </div>
            </div>
            <div>
              {errorLicencias.error && (
                <span className="text-red-500 font-normal m-0">{errorLicencias.helperText}</span>
              )}
            </div>
          </div>
          <div className="col-span-1">
            <span> </span>
          </div>
          <div className="col-span-5 mt-4">
            <div className="flex flex-row">
              <h3 className="text-gray-500 font-normal m-0">
                {`${t('administrador_suscripciones_usadas_organizacion')}: `}
              </h3>
              <span className="ml-2">{objOrganizacion.licenciasUsadas}</span>
            </div>
            <div className="flex flex-row mt-2">
              {objOrganizacion.licenciasCompartidas > 0 && (
                <>
                  <h3 className="text-gray-500 font-normal m-0">
                    {`${t('administrador_suscripciones_compartidas_organizacion')}: `}
                  </h3>
                  <span className="ml-2">{objOrganizacion.licenciasCompartidas}</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between my-4 items-center">
          <span className="text-gray-500">
            *
            {t('campos_obligatorios')}
          </span>
          {objOrganizacion.id !== -1 ? (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleAddOrganizacion}
              disabled={!objOrganizacion.nombreOrganizacion.length}
            >
              {t('actualizar')}
            </Button>
          ) : (
            <Button
              color="primary"
              size="small"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddOrganizacion}
              disabled={!objOrganizacion.nombreOrganizacion.length}
            >
              {t('agregar_otra_organizacion')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormularioOrganizaciones;
