import { ChevronDown, XIcon, Search } from 'lucide-react';
import {
  FC, useEffect, useRef, useState,
} from 'react';
import { SelectInputProps } from './type';
import { useTranslation } from 'react-i18next';

export const SelectInput: FC<SelectInputProps> = ({
  name,
  containerClass = '',
  label: externalLabel,
  isRequired,
  options: externalOptions,
  placeholder: externalPlaceholder,
  helperText,
  variant = 'normal',
  setValue,
  sendObject = false,
  disableOption,
  value,
  isNumeric = true,
  disabled = false,
  position,
}) => {
  const { t } = useTranslation();
  const label = externalLabel === undefined ? t(name) : externalLabel;
  const placeholder = externalPlaceholder === undefined ? t('selecciona') : '';
  const [options, setOptions] = useState<{ id: number | string; label: string }[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [isDisabled, setIsDisabled] = useState(!!disabled);
  const [errorMessage, setErrorMessage] = useState('');
  const [wasFocused, setWasFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const valueIsNull = !value || ['0', ''].includes(String(value));

  const validateSelect = () => {
    setErrorMessage(
      isRequired && valueIsNull
        ? t('error_campo_en_blanco')
        : '',
    );
  };

  useEffect(() => {
    setOptions(externalOptions);
  }, [externalOptions]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    setSelectedOption(
      valueIsNull
        ? placeholder
        : options.find((opt) => opt.id.toString() === value)?.label || placeholder,
    );
  }, [value, options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        if (isOpen) {
          validateSelect();
        }
        setIsOpen(false);
        setSearchTerm('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, wasFocused]);

  useEffect(() => {
    const handleHover = (event: MouseEvent) => {
      setIsHover(!!dropdownRef.current?.contains(event.target as Node));
    };
    document.addEventListener('mouseover', handleHover);
    return () => document.removeEventListener('mouseover', handleHover);
  }, [isHover]);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleDisableChange = () => {
    const newState = !disableOption?.value;
    setIsDisabled(newState);
    setErrorMessage('');
    if (disableOption?.name) {
      if (disableOption.setValue) {
        disableOption.setValue({ name: disableOption.name, value: newState });
      } else {
        setValue?.({ name: disableOption.name, value: newState });
      }
    }
    if (!newState) {
      setSelectedOption(placeholder);
      setValue?.({ name, value: '' });
      setWasFocused(false);
    }
  };

  const handleSelect = (option: { id: string | number; label: string }) => {
    if (!isDisabled) {
      setSelectedOption(option.label);
      setSearchTerm('');
      setIsOpen(false);
      setErrorMessage('');
      setValue({
        name,
        value: sendObject ? option : option.id,
        position,
      });
    }
  };

  const handleToggleDropdown = () => {
    if (!isDisabled) {
      if (!wasFocused) {
        setWasFocused(true);
      }
      setIsOpen(!isOpen);
      if (isOpen) {
        validateSelect();
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      setSearchTerm(e.target.value as string);
    }
  };

  const getStyle = () => {
    if (isDisabled) {
      return 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed';
    }
    if (errorMessage) {
      return 'border-red-500 ring-2 ring-red-200';
    }
    return `border-gray-300 ${isOpen ? 'ring-2 ring-blue-500' : ''}`;
  };

  return (
    <div className={`w-full h-auto px-4 ${containerClass}`}>
      <div className="w-full h-[66px] mb-8" ref={dropdownRef}>
        <div className="flex items-center justify-between mb-2">
          {label && (
            <label
              htmlFor={name}
              className={`block font-medium text-base ${
                errorMessage
                  ? 'text-red-500'
                  : `text-gray-${isDisabled ? '500' : '700'}`
              }`}
            >
              {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
          )}
          {disableOption?.position === 'right' && (
            <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
              <input
                type="checkbox"
                className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
                checked={!!disableOption?.value}
                onChange={handleDisableChange}
              />
              {disableOption.label}
            </label>
          )}
        </div>

        <div className="relative">
          {variant === 'normal' ? (
            <div
              className={`w-full h-[48px] border rounded-md flex justify-between items-center ${
                getStyle()
              }`}
            >
              <div
                className="w-full h-full content-center"
                onClick={handleToggleDropdown}
              >
                <span
                  className={`ml-2 ${
                    valueIsNull ? 'text-gray-400' : ''
                  }`}
                >
                  {selectedOption}
                </span>
              </div>
              <div className="flex flex-row mr-2">
                {!valueIsNull && isHover && !disabled
                  ? <XIcon
                    size={18}
                    onClick={() => {
                      if (!isDisabled) {
                        setSelectedOption(placeholder);
                        setValue({
                          name,
                          value: sendObject
                            ? { id: isNumeric ? 0 : '', label: '' }
                            : isNumeric ? 0 : '',
                          position,
                        });
                        if (isRequired) {
                          setErrorMessage(t('error_campo_en_blanco'));                    
                        }
                      }
                    }}
                    className="hover:bg-gray-100 rounded-full"
                  /> : null
                }
                <ChevronDown
                  size={20}
                  className={`transition-transform duration-200 ${
                    isOpen ? 'rotate-180' : ''}
                  hover:bg-gray-100 rounded-full`}
                  onClick={handleToggleDropdown}
                />
              </div>
            </div>
          ) : (
            <div
              className={`relative h-[48px] flex items-center border rounded-md
               ${isDisabled
                ? 'bg-gray-50 border-gray-300'
                : `bg-white ${errorMessage
                  ? 'border-red-500 ring-2 ring-red-200'
                  : 'border-gray-300'}`
               }`}
            >
              <div
                className="w-full flex items-center"
                onClick={handleToggleDropdown}
              >
                <Search className="absolute self-center left-2 text-gray-400" size={18} />
                <input
                  type="text"
                  disabled={isDisabled}
                  className={`w-full pl-9 pr-8 py-2 focus:outline-none
                  ${
                    valueIsNull ? 'text-gray-400' : ''
                  } ${
                    isDisabled ? 'text-gray-400 cursor-not-allowed' : ''
                  }`}
                  placeholder={placeholder}
                  value={isOpen ? searchTerm : selectedOption}
                  onChange={handleInputChange}
                  onClick={() => !isDisabled && setIsOpen(true)}
                />
              </div>
              <div className="flex flex-row">
                {!valueIsNull && isHover && !disabled
                  ? <XIcon
                    size={18}
                    onClick={() => {
                      if (!isDisabled) {
                        setSelectedOption(placeholder);
                        setValue({
                          name,
                          value: sendObject
                            ? { id: isNumeric ? 0 : '', label: '' }
                            : isNumeric ? 0 : '',
                          position,
                        });
                        if (isRequired) {
                          setErrorMessage(t('error_campo_en_blanco'));                    
                        }
                      }
                    }}
                    className="hover:bg-gray-100 bg rounded-full"
                  /> : null
                }
                <ChevronDown
                  size={20}
                  className={`mr-2 transition-transform duration-200 ${
                    isOpen ? 'rotate-180' : ''
                  } hover:bg-gray-100 rounded-full`}
                  onClick={handleToggleDropdown}
                />
              </div>
            </div>
          )}

          {isOpen && !isDisabled && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <div
                    key={option.id}
                    className={`p-2 cursor-pointer hover:bg-gray-100 
                     ${
                       String(option.id) === String(value)
                         ? 'bg-blue-50 text-blue-600'
                         : ''
                     }`}
                    onClick={() => handleSelect(option)}
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-500 text-center">{t('sin_resultados')}</div>
              )}
            </div>
          )}
        </div>

        {(errorMessage || helperText) && (
          <p className={`mt-1 text-xs ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
            {errorMessage || helperText}
          </p>
        )}
      </div>
      {disableOption?.position === 'after' && (
        <label className="flex items-center text-sm text-gray-600 whitespace-nowrap">
          <input
            type="checkbox"
            className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
            checked={isDisabled}
            onChange={handleDisableChange}
          />
          {disableOption.label}
        </label>
      )}
    </div>
  );
};

export default SelectInput;
