import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDatosPersonales,
  setEnGestacion,
  setInmunizaciones,
  setDatosPersonalesUsuario,
} from '@actions/paciente/actions';
import { setRutaPerfil } from '@actions/rutasExpediente/actions';
/** importacion de funciones */
import { validarFecha, validarFechaEsAnterior, edadCalculada } from '@common/functions';
import {
  getCatalogoEscolaridad,
  getCatalogoEstadoCivil,
  getCatalogoTipoIdentificacion,
  getCatalogoGrupoEtnico,
  getCatalogoNacionalidad,
  getCatalogoReligion,
  getCatalogoTiempoOcupacion,
  getCatalogoTipoOcupacion,
} from '@utils/getCatalogos';
/** importacion de validaciones */
import {
  docIdLongitudMax,
  validacionDocId,
  validacionDocIdOnChange,
  validacionApellidos,
  validacionNombres,
  validacionEmail,
} from '@constants/validaciones';
import AlertDialog from '@components/AlertDialogs/AlertDialog';
import AccesoPIN from '@components/ModalDialogs/AccesoPIN';
import {
  updateDatosPersonales,
  updateDatosPersonalesPaciente,
  updateDatosPersonalesUsuario,
} from '@utils/sendInfo';
import { setRequest } from '@actions/request/types';
import {
  getPaciente,
  getUsuarioPaciente,
  setPacienteEdad,
  setPacienteNombre,
  setPacienteSexo,
  setPacienteTelefonos,
} from '@actions/basicosPaciente/actions';
import { RootState } from 'src/store';
import {
  ICatalogosEstandar,
  ICatStrEstandar,
  IEdad,
  edadInitial,
} from '@common/types';
import DialogoPassword from '@components/ModalDialogs/DialogPassword';
import { getMeMedico } from '@actions/me/actions';
import { setIdPacienteConsulta } from '@actions/consulta/actions';
import { ModalForm } from '@components/modals/ModalForm';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import DateInput from '@components/FormElemntsLibrary/DateInput';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import EmailInput from '@components/FormElemntsLibrary/EmailInput';
import PhoneInput from '@components/FormElemntsLibrary/PhoneInput';
import CheckboxInput from '@components/FormElemntsLibrary/CheckboxInput';
import { IDatosPersonales, datosPersonalesInitial } from './types';
import TextField from '@components/FormElemntsLibrary/TextField';

/** Formulario datos personales de informacion de paciente */
function DatosPersonales() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    idPaciente, idUsuario, docId, sinDocId,
  } = useSelector(
    (state: RootState) => state.BasicosPaciente,
  );
  const {
    idMedico,
    pais,
    idUsuario: idMiUsuario,
    esPaciente,
    email,
  } = useSelector((state: RootState) => state.Me);
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    organizacion: { idOrganizacion, isOxxo },
  } = useSelector((state: RootState) => state.Organizaciones);
  const { datosPersonales } = useSelector((state: RootState) => state.Paciente.expediente.perfil);
  const { datosPersonales: datosPersonalesUsuario } = useSelector(
    (state: RootState) => state.Paciente.usuario.perfil,
  );
  const { datosPersonales: cambios } = useSelector(
    (state: RootState) => state.Paciente.cambios.perfil,
  );
  /** States del functional component */
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [modalPin, setModalPin] = useState<boolean>(false);
  const [pinStr, setPinStr] = useState<string>('');
  const [edad, setEdad] = useState<IEdad>(edadInitial);
  const [avisoPaciente, setAvisoPaciente] = useState<boolean>(false);
  /** states de la snackbar */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMensaje, setAlertMensaje] = useState<string>('');

  /** Catalogos */
  const [catNacionalidad, setCatNacionalidad] = useState<ICatStrEstandar[]>([]);
  const [catGrupoEtnico, setCatGrupoEtnico] = useState<ICatalogosEstandar[]>([]);
  const [catTipoIdentificacion, setCatTipoIdentificacion] = useState<ICatalogosEstandar[]>([]);
  const [catEscolaridad, setCatEscolaridad] = useState<ICatalogosEstandar[]>([]);
  const [catTipoOcupacion, setCatTipoOcupacion] = useState<ICatalogosEstandar[]>([]);
  const [catTiempoOcupacion, setCatTiempoOcupacion] = useState<ICatalogosEstandar[]>([]);
  const [catReligion, setCatReligion] = useState<ICatalogosEstandar[]>([]);
  const [catEstadoCivil, setCatEstadoCivil] = useState<ICatalogosEstandar[]>([]);
  /** states para mostrar o no diferentes campos */
  const [tipoOcupacionShow, setTipoOcupacionShow] = useState<boolean>(false);
  const [otraReligionShow, setOtraReligionShow] = useState(false);
  const [datosPersonalesTemp, setDatosPersonalesTemp] =
    useState<IDatosPersonales>(datosPersonalesInitial);

  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);
  const ocupaciones = ['empleado', 'pensionado', 'desempleado', 'jubilado'];
  const comoEntero = ['referencia-amigo', 'publicidad', 'referencia-colega', 'otro'];

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setAlertOpen(false);
  };

  /** Handlers del componente */
  const handleInputChange = (e: { name: string; value: any }) =>
    setDatosPersonalesTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const handleChangeAviso = (e: { name: string; value: any }) =>
    setAvisoPaciente(e.value);

  const getData = (datos: IDatosPersonales, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsulta,
    idConsultorio,
    idOrganizacion,
    nombre: datos.nombre.trim().split(/\s+/).join(' '),
    primerApellido: datos.primerApellido.trim().split(/\s+/).join(' '),
    segundoApellido: datos.segundoApellido.trim().split(/\s+/).join(' '),
    sexo: datos.sexo,
    fechaDia: datos.fechaNac.dia,
    fechaMes: datos.fechaNac.mes,
    fechaAnio: datos.fechaNac.anio,
    nacionalidad: datos.nacionalidad.id,
    grupoEtnico: datos.grupoEtnico ? datos.grupoEtnico.id : 0,
    estadoCivil: datos.estadoCivil ? datos.estadoCivil.id : 0,
    escolaridad: datos.escolaridad ? datos.escolaridad.id : 0,
    religion: datos.religion ? datos.religion.id : 0,
    otraReligion: datos.otraReligion,
    docId: datos.docId,
    noPoseeDocId: datos.noPoseeDocId,
    tipoIdentificacion: datos.tipoIdentificacion ? datos.tipoIdentificacion.id : 0,
    numeroDocumento: datos.numeroDocumento,
    ocupacion: datos.ocupacion,
    tipoOcupacion: datos.tipoOcupacion ? datos.tipoOcupacion.id : 0,
    tiempoOcupacion: datos.tiempoOcupacion ? datos.tiempoOcupacion.id : 0,
    ladaCel: datos.celular.codigo,
    celular: datos.celular.numero,
    noTieneCelular: datos.noTieneCelular,
    ladaTel: datos.fijo.codigo,
    fijo: datos.fijo.numero,
    email: datos.email,
    noTieneEmail: datos.noTieneEmail,
    comoEntero: datos.comoEntero,
    otroComoEntero: datos.otroComoEntero,
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const dataPersonal = getData(datosPersonalesTemp, idUsuario);
    const requestFunctions = [updateDatosPersonales(dataPersonal)];
    const successFunctions = [
      (result: any) => {
        if (idPaciente === -1) {
          dispatch(setInmunizaciones(result.inmunizaciones));
        }
        if (datosPersonalesTemp.sexo === 'H') {
          dispatch(setEnGestacion(''));
        }
        dispatch(getPaciente(parseInt(result.datos, 10), idMedico, idOrganizacion, idConsultorio));
        if (idPacienteConsulta <= 0) {
          dispatch(setIdPacienteConsulta(parseInt(result.datos, 10)));
        }
        dispatch(setDatosPersonales({ ...datosPersonalesTemp, loaded: true }));
        if (result.pin) {
          setModalPin(true);
          setPinStr(result.pin);
        }
      },
    ];
    if (!esPaciente && idUsuario && idUsuario > 0) {
      requestFunctions.push(updateDatosPersonalesPaciente(dataPersonal));
      successFunctions.push(() => {
        dispatch(
          setDatosPersonalesUsuario({
            ...datosPersonalesTemp,
            loaded: true,
          }),
        );
      });
    }
    dispatch(
      setRequest({
        type: 'send',
        multiple: true,
        requestFunctions,
        successFunctions,
        showMsgSuccess: true,
      }),
    );
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonalesUsuario(getData(datosPersonalesTemp, idMiUsuario)),
        successFunction: () => {
          dispatch(setDatosPersonales({ ...datosPersonalesTemp, loaded: true }));
          if (datosPersonalesTemp.sexo === 'H') {
            dispatch(setEnGestacion(''));
          }
          dispatch(getMeMedico(email));
          dispatch(getUsuarioPaciente(idMedico));
          dispatch(
            setPacienteNombre(
              `${datosPersonalesTemp.nombre} ${datosPersonalesTemp.primerApellido} ${datosPersonalesTemp.segundoApellido}`,
            ),
          );
          dispatch(setPacienteSexo(datosPersonalesTemp.sexo));
          dispatch(setPacienteEdad(edad));
          dispatch(
            setPacienteTelefonos([
              datosPersonales.celular.numero
                ? `${datosPersonales.celular.codigo}${datosPersonales.celular.numero}`
                : '',
              datosPersonales.fijo.numero
                ? `${datosPersonales.fijo.codigo}${datosPersonales.fijo.numero}`
                : '',
            ]),
          );
          dispatch(setRutaPerfil('datos-ubicacion'));
        },
      }),
    );
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonales(getData(datosPersonalesUsuario)),
        successFunction: () => {
          dispatch(setDatosPersonales(datosPersonalesUsuario));
          setDatosPersonalesTemp(datosPersonalesUsuario);
          if (datosPersonalesUsuario.sexo === 'H') {
            dispatch(setEnGestacion(''));
          }
        },
      }),
    );
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: updateDatosPersonalesPaciente(getData(datosPersonales, idUsuario)),
        successFunction: () => dispatch(setDatosPersonalesUsuario(datosPersonales)),
      }),
    );
  };

  const verificarErrorValidacionGeneral =
    !datosPersonalesTemp.nombre.trim().length ||
    !datosPersonalesTemp.primerApellido.trim().length ||
    !datosPersonalesTemp.sexo.length ||
    !datosPersonalesTemp.fechaNac.dia.length ||
    !datosPersonalesTemp.fechaNac.mes.length ||
    !datosPersonalesTemp.fechaNac.anio.length ||
    !datosPersonalesTemp.nacionalidad.id.length ||
    (!datosPersonalesTemp.celular.numero.trim().length
      ? !datosPersonalesTemp.noTieneCelular
      : false) ||
    (!datosPersonalesTemp.email.trim().length ? !datosPersonalesTemp.noTieneEmail : false) ||
    // emailField.error ||
    (!avisoPaciente && !(idPaciente > 0));
  const verificarErrorValidacionObligatorios = !(
    datosPersonalesTemp.nombre.length > 0 &&
    datosPersonalesTemp.primerApellido.length > 0 &&
    datosPersonalesTemp.sexo.length > 0 &&
    datosPersonalesTemp.fechaNac.dia.length > 0 &&
    datosPersonalesTemp.fechaNac.mes.length > 0 &&
    datosPersonalesTemp.fechaNac.anio.length > 0 &&
    datosPersonalesTemp.nacionalidad.id.length > 0 &&
    (datosPersonalesTemp.docId.length > 0 || datosPersonalesTemp.noPoseeDocId) &&
    (datosPersonalesTemp.celular.numero.length > 0 || datosPersonalesTemp.noTieneCelular) &&
    (datosPersonalesTemp.email.length > 0 || datosPersonalesTemp.noTieneEmail) &&
    !verificarErrorValidacionGeneral &&
    (avisoPaciente || idPaciente > 0)
  );
  const handleSubmitForm = () => {
    if (esPaciente) {
      guardarDatosUsuario();
    } else {
      if (verificarErrorValidacionObligatorios) {
        setAlertOpen(true);
        setAlertMensaje(t('texto_llenado_norma_oficial'));
        return;
      }
      guardarDatosExpediente();
    }
  };

  useEffect(() => {
    if (datosPersonales.loaded && !datosPersonalesTemp.loaded) {
      setDatosPersonalesTemp(datosPersonales);
    }
  }, [datosPersonales]);
  /** Efecto para llenar la edad */
  useEffect(() => {
    if (
      datosPersonalesTemp.fechaNac.dia &&
      datosPersonalesTemp.fechaNac.mes &&
      datosPersonalesTemp.fechaNac.anio
      && validarFecha(
        datosPersonalesTemp.fechaNac.anio,
        datosPersonalesTemp.fechaNac.mes,
        datosPersonalesTemp.fechaNac.dia,
      ) &&
      validarFechaEsAnterior(
        datosPersonalesTemp.fechaNac.anio,
        datosPersonalesTemp.fechaNac.mes,
        datosPersonalesTemp.fechaNac.dia,
      )
    ) {
      const edadObj = edadCalculada(
        datosPersonalesTemp.fechaNac.anio,
        datosPersonalesTemp.fechaNac.mes,
        datosPersonalesTemp.fechaNac.dia,
      );
      setEdad(edadObj);
    }
  }, [
    datosPersonalesTemp.fechaNac.anio,
    datosPersonalesTemp.fechaNac.mes,
    datosPersonalesTemp.fechaNac.dia,
  ]);
  useEffect(() => {
    if (!datosPersonalesTemp.ocupacion || datosPersonalesTemp.ocupacion === 'DESEMPLEADO') {
      setTipoOcupacionShow(false);
      setDatosPersonalesTemp((prevData) => ({
        ...prevData,
        tipoOcupacion: { id: 0, label: '' },
        tiempoOcupacion: { id: 0, label: '' },
      }));
    } else {
      setTipoOcupacionShow(true);
    }
  }, [datosPersonalesTemp.ocupacion]);
  useEffect(() => {
    if (datosPersonalesTemp.religion.label === 'OTRA') {
      setOtraReligionShow(true);
    } else {
      setOtraReligionShow(false);
      setDatosPersonalesTemp((prevData) => ({
        ...prevData,
        otraReligion: '',
      }));
    }
  }, [datosPersonalesTemp.religion]);
  useEffect(() => {
    if (datosPersonalesTemp.tipoOcupacion.id === 0) {
      setDatosPersonalesTemp((prevData) => ({
        ...prevData,
        tiempoOcupacion: { id: 0, label: '' },
      }));
    }
  }, [datosPersonalesTemp.tipoOcupacion]);
  useEffect(() => {
    if (idPaciente === -1) {
      setDatosPersonalesTemp({
        ...datosPersonalesTemp,
        noPoseeDocId: !!sinDocId,
        docId,
      });
    }
  }, [idPaciente]);
  /** Hooks */
  /** Efecto al cargar la pagina */
  // Hacer una petición múltiple
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    Promise.all([
      getCatalogoNacionalidad(),
      getCatalogoGrupoEtnico(),
      getCatalogoTipoIdentificacion(),
      getCatalogoEscolaridad(),
      getCatalogoTipoOcupacion(),
      getCatalogoTiempoOcupacion(),
      getCatalogoReligion(),
      getCatalogoEstadoCivil(),
    ])
      .then((responses) => {
        Promise.all(responses)
          .then((results: any[]) => {
            setCatNacionalidad(results[0]);
            setCatGrupoEtnico(results[1]);
            setCatTipoIdentificacion(results[2]);
            setCatEscolaridad(results[3]);
            setCatTipoOcupacion(results[4]);
            setCatTiempoOcupacion(results[5]);
            setCatReligion(results[6]);
            setCatEstadoCivil(results[7]);
          });
      });
  }, []);

  return (
    <div id="datos-personales" aria-labelledby="datos-personales">
      <ModalForm
        currentStep={1}
        title={t('datos_personales')}
        id="datos-personales-form"
        saveCallback={handleSubmitForm}
        acceptCallback={guardarDatosDeUsuarioAExpediente}
        rejectCallback={guardarDatosDeExpedienteAUsuario}
        hayCambios={hayCambios}
        cambios={cambios}
      >
        <div className="flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-4 md:gap-2">
            <TextInput
              label={t('nombre(s)')}
              name="nombre"
              value={datosPersonalesTemp.nombre}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionNombres(val) ? t('nombre_incorrecto') : ''
              }
              isRequired
              maxLength={50}
              disabled={hayCambios}
            />
            <TextInput
              label={t('primer_apellido')}
              name="primerApellido"
              value={datosPersonalesTemp.primerApellido}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionApellidos(val) ? t('primer_apellido_incorrecto') : ''
              }
              isRequired
              maxLength={50}
              disabled={hayCambios}
            />
            <TextInput
              label={t('segundo_apellido')}
              name="segundoApellido"
              value={datosPersonalesTemp.segundoApellido}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionApellidos(val) ? t('segundo_apellido_incorrecto') : ''
              }
              maxLength={50}
              disabled={hayCambios}
            />
            <CheckboxGroup
              name="sexo"
              options={[
                { value: 'H', label: t('hombre') },
                { value: 'M', label: t('mujer') },
              ]}
              value={datosPersonalesTemp.sexo}
              setValue={handleInputChange}
              distribution="vertical-flex"
              isRequired
              disabled={hayCambios}
            />
            <div className="flex flex-row md:col-span-2">
              <div className="grid grid-cols-3">
                <DateInput
                  containerClass="col-span-2"
                  label={t('fecha_nacimiento')}
                  name="fechaNac"
                  value={datosPersonalesTemp.fechaNac}
                  setValue={handleInputChange}
                  validacion='esAnterior'
                  isRequired
                  disabled={hayCambios}
                />
                <TextField
                  name="edad"
                  value={edad.edad ? edad.edad.toString() : ''}
                  adornment={{
                    text: edad.periodo ? t(edad.periodo) : '',
                    position: 'end',
                  }}
                />
              </div>
            </div>
            {!isOxxo && (
              <>
                <SelectInput
                  name="nacionalidad"
                  options={catNacionalidad}
                  value={String(datosPersonalesTemp.nacionalidad.id)}
                  setValue={handleInputChange}
                  variant="search"
                  isNumeric={false}
                  isRequired
                  sendObject
                  disabled={hayCambios}
                />
                <SelectInput
                  label={t('grupo_etnolinguistico')}
                  name="grupoEtnico"
                  options={catGrupoEtnico}
                  value={String(datosPersonalesTemp.grupoEtnico.id)}
                  setValue={handleInputChange}
                  isRequired
                  sendObject
                  disabled={hayCambios}
                />
              </>
            )}

            <SelectInput
              label={t('estado_civil')}
              name="estadoCivil"
              options={catEstadoCivil}
              value={String(datosPersonalesTemp.estadoCivil.id)}
              setValue={handleInputChange}
              sendObject
              disabled={hayCambios}
            />

            {!isOxxo && (
              <>
                <SelectInput
                  name="escolaridad"
                  options={catEscolaridad}
                  value={String(datosPersonalesTemp.escolaridad.id)}
                  setValue={handleInputChange}
                  sendObject
                  disabled={hayCambios}
                />
                <SelectInput
                  name="religion"
                  options={catReligion}
                  value={String(datosPersonalesTemp.religion.id)}
                  setValue={handleInputChange}
                  variant="search"
                  sendObject
                  disabled={hayCambios}
                />
                {otraReligionShow
                  ? <TextInput
                    name="otra_especifica"
                    value={datosPersonalesTemp.otraReligion}
                    setValue={handleInputChange}
                    disabled={hayCambios}
                  /> : <div className="hidden md:block"/>
                }
              </>
            )}

            <TextInput
              name="curp"
              label={docId.startsWith('PACI-') ? t('usuario') : t(`doc_id_${pais}`)}
              disableOption={{
                name: 'noPoseeDocId',
                value: datosPersonalesTemp.noPoseeDocId,
                label: t('no_posee_doc_id_mx'),
                position: 'right',
              }}
              value={datosPersonalesTemp.docId}
              textCase="upper"
              maxLength={docIdLongitudMax(pais)}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionDocIdOnChange(val, pais)
                  ? t(`doc_id_incorrecto_${pais}`)
                  : ''
              }
              validacionBlur={(val) =>
                val.length && !validacionDocId(val, pais)
                  ? t(`doc_id_incorrecto_${pais}`)
                  : ''
              }
              disabled
              isRequired
            />

            <SelectInput
              label={t('tipo_identificacion')}
              name="tipoIdentificacion"
              options={catTipoIdentificacion}
              value={String(datosPersonalesTemp.tipoIdentificacion.id)}
              setValue={handleInputChange}
              disabled={hayCambios}
            />

            <TextInput
              label={t('numero_documento')}
              name="numeroDocumento"
              value={datosPersonalesTemp.numeroDocumento}
              setValue={handleInputChange}
              maxLength={20}
              disabled={hayCambios}
            />

            {!isOxxo && (
              <>
                <CheckboxGroup
                  containerClass="md:col-span-2"
                  name="ocupacion"
                  options={ocupaciones.map((ocupacion) => ({
                    value: ocupacion.toUpperCase(),
                    label: t(ocupacion),
                  }))}
                  value={datosPersonalesTemp.ocupacion}
                  setValue={handleInputChange}
                  distribution="grid"
                  disabled={hayCambios}
                />
                {tipoOcupacionShow ?
                  <>
                    <SelectInput
                      label={t('tipo_ocupacion')}
                      variant="search"
                      name="tipoOcupacion"
                      options={catTipoOcupacion}
                      value={String(datosPersonalesTemp.tipoOcupacion.id)}
                      setValue={handleInputChange}
                      disabled={hayCambios}
                    />
                    <SelectInput
                      label={t('tiempo_ocupacion')}
                      name="tiempoOcupacion"
                      options={catTiempoOcupacion}
                      value={String(datosPersonalesTemp.tiempoOcupacion.id)}
                      setValue={handleInputChange}
                      disabled={hayCambios}
                    />
                  </> : <><div className="hidden md:block"/><div className="hidden md:block"/></>
                }
              </>
            )}

            <PhoneInput
              label={t('telefono_celular')}
              name="celular"
              disableOption={{
                name: 'noTieneCelular',
                value: datosPersonalesTemp.noTieneCelular,
                label: t('no_tiene'),
                position: 'right',
              }}
              value={`${datosPersonales.celular.codigo}${datosPersonales.celular.numero}`}
              setValue={handleInputChange}
              isRequired
              disabled={hayCambios}
            />
            <PhoneInput
              label={t('telefono_fijo')}
              name="fijo"
              value={`${datosPersonales.fijo.codigo}${datosPersonales.fijo.numero}`}
              setValue={handleInputChange}
              disabled={hayCambios}
            />

            {esPaciente &&
              <>
                <div className="hidden md:block"/>
                <EmailInput
                  containerClass="md:col-span-2"
                  label={t('email')}
                  name="mainEmail"
                  value={email}
                  setValue={handleInputChange}
                  disabled
                />
              </>
            }
            <EmailInput
              containerClass="md:col-span-2"
              label={t(esPaciente ? 'email_recuperacion' : 'email')}
              name="email"
              disableOption={{
                name: 'noTieneEmail',
                value: datosPersonalesTemp.noTieneEmail,
                label: t('no_tiene'),
                position: 'right',
              }}
              value={datosPersonalesTemp.email}
              setValue={handleInputChange}
              validacion={(val) =>
                val.length && !validacionEmail(val) ? t('email_incorrecto') : ''
              }
              isRequired
              disabled={hayCambios}
            />
            {idPaciente <= 0 && !esPaciente && !datosPersonales.comoEntero.length &&
              <>
                <CheckboxGroup
                  containerClass='md:col-span-2'
                  name="comoEntero"
                  label={t('_como_se_entero_el_paciente_de_mi_consultorio_')}
                  options={comoEntero.map((opcion) => ({
                    value: opcion.toUpperCase(),
                    label: t(opcion),
                  }))}
                  value={datosPersonalesTemp.comoEntero}
                  setValue={handleInputChange}
                  distribution="grid"
                  disabled={hayCambios}
                />
                {datosPersonalesTemp.comoEntero === 'OTRO' &&
                  <TextInput
                    containerClass='md:col-span-2'
                    label={t('especifica')}
                    name="otroEspecifica"
                    value={datosPersonalesTemp.otroComoEntero}
                    setValue={handleInputChange}
                  />    
                }
              </>
            }
          </div>
          {idPaciente <= 0 &&
            <CheckboxInput
              containerClass='text-right'
              name="avisoPrivacidad"
              label={
                <span>
                  <span>
                    {`${t(
                      esPaciente ? 'he_leido_y_acepto_el' : 'el_paciente_leyo_y_acepto_el',
                    )} `}
                  </span>
                  <a
                    href="https://medipraxi.com/aviso-de-privacidad/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700"
                  >
                    {t('aviso_de_privacidad')}
                    <span className="text-red-500" > *</span>
                  </a>
                </span>
              }
              value={avisoPaciente}
              setValue={handleChangeAviso}
              disabled={hayCambios}
            />
          }
          <span className="text-red-500 text-right mt-2">
            *&nbsp;
            {t('campos_obligatorios')}
          </span>
        </div>
      </ModalForm>
      <AlertDialog
        open={alertOpen}
        titulo={t('_atencion_')}
        descripcion={alertMensaje}
        nombreCancelar={t('regresar')}
        nombreAceptar={t('guardar')}
        callBackAceptar={guardarDatosExpediente}
        callBackClose={handleAlertClose}
      />
      <AccesoPIN
        open={modalPin}
        callBackAceptar={() => setModalPin(false)}
        callBackClose={() => setModalPin(false)}
        usuario={docId}
        pin={pinStr}
      />
      <DialogoPassword
        open={showDialog}
        callBackClose={() => {
          setShowDialog(!showDialog);
        }}
      />
    </div>
  );
}

export default DatosPersonales;
