import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IOrganizacionesProps } from './types';

function Organizaciones({
  organizacion,
  editOrganizacion,
  objOrganizacion,
  index,
  setDeleteAlert,
  setEditAlert,
  setIndexToDelete,
}: IOrganizacionesProps) {
  const { t } = useTranslation();
  const handleEditOrganizacion = () => {
    if (objOrganizacion.nombreOrganizacion.length > 0 && objOrganizacion.licencias > 0) {
      setIndexToDelete(index);
      setEditAlert(true);
    } else {
      editOrganizacion(index);
    }
  };
  const handleDeleteOrganizacion = () => {
    setIndexToDelete(index);
    setDeleteAlert(true);
  };
  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
        <h3 className="font-normal m-0 text-gray-500">{`${organizacion.nombreOrganizacion}`}</h3>
        <p className="font-normal m-0 text-gray-500">
          {`${t('administrador_suscripciones_asignadas')}: ${organizacion.licencias}`}
        </p>
        <p className="font-normal m-0 text-gray-500">
          {`${t('administrador_suscripciones_usadas')}: ${organizacion.licenciasUsadas}`}
        </p>
        {organizacion.licenciasCompartidas > 0 && (
          <p className="font-normal m-0 text-gray-500">
            {`${t('administrador_suscripciones_compartidas')}: ${
              organizacion.licenciasCompartidas
            }`}
          </p>
        )}
      </div>
      <div className="min-w-max self-center">
        <IconButton aria-label="edit" color="primary" onClick={handleEditOrganizacion}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" color="primary" onClick={handleDeleteOrganizacion}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default Organizaciones;
