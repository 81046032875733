export const SET_RUTA_PRINCIPAL = 'SET_RUTA_PRINCIPAL';
export const SET_RUTA_PERFIL = 'SET_RUTA_PERFIL';
export const SET_RUTA_ANTECEDENTES = 'SET_RUTA_ANTECEDENTES';
export const SET_RUTA_ANT_PATOLOGICOS = 'SET_RUTA_ANT_PATOLOGICOS';
export const SET_RUTA_ANT_NO_PATOLOGICOS = 'SET_RUTA_ANT_NO_PATOLOGICOS';
export const SET_RUTA_PLAN = 'SET_RUTA_PLAN';

export interface IRutasExpediente {
  rutaPrincipal: string;
  rutaPerfil: string;
  rutaAntecedentes: string;
  rutaAntPatologicos: string;
  rutaAntNoPatologicos: string;
  rutaPlan: string;
}

interface SetRutaPrincipalAction {
  type: typeof SET_RUTA_PRINCIPAL;
  payload: string;
}

interface SetRutaPerfilAction {
  type: typeof SET_RUTA_PERFIL;
  payload: string;
}

interface SetRutaAntecedentesAction {
  type: typeof SET_RUTA_ANTECEDENTES;
  payload: string;
}

interface SetRutaAntPatologicosAction {
  type: typeof SET_RUTA_ANT_PATOLOGICOS;
  payload: string;
}

interface SetRutaAntoNoPatologicosAction {
  type: typeof SET_RUTA_ANT_NO_PATOLOGICOS;
  payload: string;
}

interface SetRutaPlanAction {
  type: typeof SET_RUTA_PLAN;
  payload: string;
}

export type RutasExpedienteActionTypes =
  | SetRutaPrincipalAction
  | SetRutaPerfilAction
  | SetRutaAntecedentesAction
  | SetRutaAntPatologicosAction
  | SetRutaAntoNoPatologicosAction
  | SetRutaPlanAction;
