import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Warning } from '@material-ui/icons';
import dayjs from 'dayjs';
import { setRutaPrincipal } from '@actions/rutasExpediente/actions';
import {
  setDatosExpediente,
  setDatosPersonales,
  setInmunizaciones,
  setCambiosDatosPaciente,
  setDatosExpedienteUsuario,
  setDatosUsuario,
} from '@actions/paciente/actions';
import { RootState } from 'src/store';
import {
  getPacienteDatosUsuario,
  getDatosUsuarioPaciente,
  getDatosPaciente,
  getPacienteDatosPersonales,
} from '@utils/getCatalogos';
import { setSnackComplete } from '@actions/snackbar/types';
import { setLoading } from '@actions/loading/actions';
import {
  getPaciente,
  setPacienteID,
  setPacienteIdUsuario,
  setPacienteTipoEmpleado,
} from '@actions/basicosPaciente/actions';
import { setRequest } from '@actions/request/types';
import ValidarUsuarioCorreo from '@components/ModalDialogs/ValidarUsuarioCorreo';
import { validarEmailUsuario, guardarTipoEmpleado } from '@utils/sendInfo';
import SeleccionarTipoPaciente from '@components/ModalDialogs/SelecionarTipoPaciente';
/** Importacion de estilos */
import useStyles from './styles';
/** Importaion de componentes de las tabs */
import PerfilPaciente from './PerfilPaciente';
import MCPA from './MCPA/index';
import Antecedentes from './Antecedentes/index';
import AparatosYSistemas from './AparatosYSistemas/index';
import ExploracionFisica from './ExploracionFisica/index';
import DiagnosticoPlan from './DiagnosticoPlan/index';
import Incapacidades from './Incapacidades';
import { IDiagnosticoGeneral } from './Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import { getCambiosDatosPaciente, getCambiosDatosPersonales } from './functionsCambios';
import {
  StyledButton,
  StyledButtonGroup,
  StyledFirstButton,
  StyledLastButton,
} from './buttonsStyles';

dayjs.locale('es');

function TabsPaciente() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { rutaPrincipal } = useSelector((state: RootState) => state.RutasExpediente);
  const {
    idPaciente, idUsuario, sexo, tipoEmpleado,
  } = useSelector(
    (state: RootState) => state.BasicosPaciente,
  );
  const {
    secciones,
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    enOrganizacion,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { expediente, usuario, cambios } = useSelector((state: RootState) => state.Paciente);
  const [validacionPendiente, setValidacionPendiente] = useState<boolean>(true);
  const [showModalValidacionCorreo, setShowModalValidacionCorreo] = useState<boolean>(false);
  const [errorCorreoInvalido, setErrorCorreoInvalido] = useState<boolean>(false);
  const [showModalTipoPaciente, setShowModalTipoPaciente] = useState<boolean>(false);
  const [tipoPaciente, setTipoPaciente] = useState<
  null | 'directo' | 'candidato' | 'contratista' | 'otro'
  >(null);
  const [emailPaciente, setEmailPaciente] = useState<string>('');

  const handleValidarUsuarioCorreo = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: validarEmailUsuario({
          idMedico,
          idPaciente,
          idConsultorio,
          emailPaciente,
        }),
        successFunction: (result: any) => {
          dispatch(setPacienteIdUsuario(result.data));
          setShowModalValidacionCorreo(false);
          getPacienteDatosUsuario(idMedico, idPaciente, result.data, idConsultorio)
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                dispatch(setDatosExpedienteUsuario(result2.data));
              }
            });
          setValidacionPendiente(false);
        },
        errorFunction: (result: any) => {
          if (result.code === 201) {
            setErrorCorreoInvalido(true);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('error')} ${result.msg}`,
              }),
            );
          }
        },
        showMsgError: false,
      }),
    );
  };

  const guardarTipo = () => {
    if (tipoPaciente) {
      dispatch(
        setRequest({
          type: 'send',
          requestFunction: guardarTipoEmpleado({
            idMedico,
            idPaciente,
            idConsultorio,
            idOrganizacion,
            tipoEmpleado: tipoPaciente,
          }),
          successFunction: () => {
            setShowModalTipoPaciente(false);
            dispatch(setPacienteTipoEmpleado(tipoPaciente));
          },
        }),
      );
    }
  };

  const handleSeleccionarTipoPaciente = () => {
    if (idPaciente > 0) {
      guardarTipo();
    } else {
      setShowModalTipoPaciente(false);
    }
  };

  const obtenerDatosUsuarioPaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosUsuarioPaciente(idMiUsuario),
        successFunction: (result: any) => {
          dispatch(setDatosUsuario(result.data));
        },
      }),
    );
  };

  const obtenerDatosExpedientePaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosPaciente(idMedico, idPaciente, idConsultorio, idConsulta),
        successFunction: (result: any) => {
          const { datosExpediente, datosUsuario, usuariosPorValidar } = result.data;
          dispatch(setDatosExpediente(datosExpediente));
          if (datosUsuario) {
            dispatch(setDatosExpedienteUsuario(datosUsuario));
            dispatch(
              setCambiosDatosPaciente(
                getCambiosDatosPaciente(
                  datosExpediente.perfil.datosPersonales,
                  datosUsuario.perfil.datosPersonales,
                  pais,
                  t,
                ),
              ),
            );
          }
          if (usuariosPorValidar) {
            setShowModalValidacionCorreo(true);
          } else {
            setValidacionPendiente(false);
          }
        },
        errorFunction: () => {
          // Error 300: Si el paciente no tiene snapshot (nunca ha tenido una consulta)
          // se consultan los datos del expediente directamente de las tablas
          // Por alguna razón no funciona con "setRequest" (al parecer no funcionan si están anidados)
          dispatch(setLoading(true));
          getPacienteDatosPersonales(idPaciente, idMedico, idConsultorio)
            .then((response) => response.json())
            .then((result) => {
              if (result.code === 200) {
                dispatch(setDatosPersonales({ ...result.data.datosPersonales, loaded: true }));
                dispatch(setInmunizaciones(result.data.inmunizaciones));
                if (result.data.datosUsuario) {
                  dispatch(setDatosExpedienteUsuario(result.data.datosUsuario));
                  dispatch(
                    setCambiosDatosPaciente({
                      perfil: {
                        datosPersonales: getCambiosDatosPersonales(
                          result.data.datosPersonales,
                          result.data.datosUsuario.perfil.datosPersonales,
                          t,
                        ),
                        datosUbicacion: [],
                        discapacidades: [],
                        infoEmergencia: [],
                        segurosPlanes: [],
                      },
                      antNoPatologicos: {
                        vivienda: [],
                        higiene: [],
                        alimentacion: [],
                        actividadFisica: [],
                        toxicomania: [],
                        ginecoObstetrico: [],
                      },
                    }),
                  );
                }
                if (result.data.usuariosPorValidar) {
                  setShowModalValidacionCorreo(true);
                } else {
                  setValidacionPendiente(false);
                }
              }
              dispatch(setLoading(false));
            })
            .catch(() => {
              dispatch(setLoading(false));
            });
        },
        showMsgError: false,
      }),
    );
  };

  useEffect(() => {
    // Esta acción se ejecuta al hacer clic en el botón de volver a la consulta, pero se valida aquí por si se accede a través de la URL
    if (!esPaciente && idPacienteConsulta > 0 && idPaciente !== idPacienteConsulta) {
      dispatch(setPacienteID(idPacienteConsulta));
      dispatch(getPaciente(idPacienteConsulta, idMedico, idOrganizacion, idConsultorio));
    }
  }, [esPaciente, idPaciente, idPacienteConsulta]);

  useEffect(() => {
    if (!validacionPendiente && enOrganizacion && (!tipoEmpleado || tipoEmpleado === 'externo')) {
      setShowModalTipoPaciente(true);
    }
  }, [validacionPendiente]);

  useEffect(() => {
    if (tipoEmpleado) {
      setShowModalTipoPaciente(false);
    }
  }, [tipoEmpleado]);

  useEffect(() => {
    if (idMedico) {
      if (esPaciente) {
        // Si el usuario es el paciente se consultan sus datos de usuario
        obtenerDatosUsuarioPaciente();
      } else if (
        idPaciente > 0 &&
        idConsultorio > 0 &&
        idConsulta > 0 &&
        !expediente.perfil.datosPersonales.loaded
      ) {
        // Se consultan los datos del expediente y los datos del usuario del paciente
        obtenerDatosExpedientePaciente();
      } else {
        setValidacionPendiente(false);
      }
    }
  }, [idMedico, idPaciente, idConsultorio, idConsulta]);

  // Si el paciente es nuevo, se selecciona el tipo de empleado al principio, pero se guarda en el
  // back luego de que se guarda el registro del paciente, es decir, cuando idPaciente cambia a
  // mayor a 0
  useEffect(() => {
    if (idPaciente > 0 && !tipoEmpleado && tipoPaciente) {
      guardarTipo();
    }
  }, [idPaciente]);

  return (
    <>
      <ValidarUsuarioCorreo
        open={showModalValidacionCorreo}
        email={emailPaciente}
        setEmail={setEmailPaciente}
        emailError={errorCorreoInvalido}
        setEmailError={setErrorCorreoInvalido}
        callBackAceptar={handleValidarUsuarioCorreo}
        callBackClose={() => {
          setShowModalValidacionCorreo(false);
          setValidacionPendiente(false);
        }}
      />
      <SeleccionarTipoPaciente
        open={showModalTipoPaciente}
        esExterno={tipoEmpleado === 'externo'}
        tipoPaciente={tipoPaciente}
        setTipoPaciente={setTipoPaciente}
        callBackAceptar={handleSeleccionarTipoPaciente}
        callBackClose={() => setShowModalTipoPaciente(false)}
      />
      <div className="w-full rounded-lg my-4">
        <StyledButtonGroup className="mb-4" variant="contained" color="primary" disableElevation>
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 7 && valor.idSeccion <= 11) && (
              <StyledFirstButton
                classes={rutaPrincipal === 'perfil' ? { root: classes.buttonSelected } : {}}
                onClick={() => dispatch(setRutaPrincipal('perfil'))}
                startIcon={
                  !esPaciente &&
                  idUsuario > 0 &&
                  (cambios.perfil.datosPersonales.some((c: string) => c.length > 0) ||
                    cambios.perfil.datosUbicacion.some((c: string) => c.length > 0) ||
                    cambios.perfil.discapacidades.some((c: string) => c.length > 0) ||
                    cambios.perfil.infoEmergencia.some((c: string) => c.length > 0) ||
                    cambios.perfil.segurosPlanes.some((c: string) => c.length > 0)) ? (
                      <Warning color="secondary" fontSize="small" />
                    ) : null
                }
              >
                {t('perfil')}
              </StyledFirstButton>
          )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 12 && valor.idSeccion <= 13) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={rutaPrincipal === 'mcpa' ? { root: classes.buttonSelected } : {}}
                onClick={() => dispatch(setRutaPrincipal('mcpa'))}
              >
                {t('mc/pa')}
              </StyledButton>
          )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 32) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={rutaPrincipal === 'antecedentes' ? { root: classes.buttonSelected } : {}}
                onClick={() => dispatch(setRutaPrincipal('antecedentes'))}
                startIcon={
                  !esPaciente &&
                  idUsuario > 0 &&
                  (usuario.antPatologicos.diagnosticosGenerales.contenidoTabla?.some(
                    (diagnostico: IDiagnosticoGeneral) =>
                      diagnostico.posee === 'si' && !diagnostico.validado,
                  ) ||
                    cambios.antNoPatologicos.vivienda.some((c: string) => c.length > 0) ||
                    cambios.antNoPatologicos.higiene.some((c: string) => c.length > 0) ||
                    cambios.antNoPatologicos.alimentacion.some((c: string) => c.length > 0) ||
                    cambios.antNoPatologicos.actividadFisica.some((c: string) => c.length > 0) ||
                    cambios.antNoPatologicos.toxicomania.some((c: string) => c.length > 0) ||
                    cambios.antNoPatologicos.ginecoObstetrico.some((c: string) => c.length > 0) ||
                    (sexo === 'M' &&
                      usuario.antNoPatologicos.enGestacion.loaded &&
                      expediente.antNoPatologicos.enGestacion !==
                        usuario.antNoPatologicos.enGestacion.enGestacion)) ? (
                          <Warning color="secondary" fontSize="small" />
                    ) : null
                }
              >
                {t('antecedentes')}
              </StyledButton>
          )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 33 && valor.idSeccion <= 45) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={
                  rutaPrincipal === 'aparatos-y-sistemas' ? { root: classes.buttonSelected } : {}
                }
                onClick={() => dispatch(setRutaPrincipal('aparatos-y-sistemas'))}
              >
                {t('aparatos_y_sistemas')}
              </StyledButton>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 46) && (
            <StyledButton
              disabled={idPaciente <= 0}
              classes={
                rutaPrincipal === 'exploracion-fisica' ? { root: classes.buttonSelected } : {}
              }
              onClick={() => dispatch(setRutaPrincipal('exploracion-fisica'))}
            >
              {t(esPaciente ? 'mediciones' : 'exploracion_fisica')}
            </StyledButton>
          )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 47 && valor.idSeccion <= 58) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={
                  rutaPrincipal === 'diagnostico-y-plan' ? { root: classes.buttonSelected } : {}
                }
                onClick={() => dispatch(setRutaPrincipal('diagnostico-y-plan'))}
              >
                {t(esPaciente ? 'medicina_preventiva' : 'diagnostico_y_plan')}
              </StyledButton>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 80) && (
            <StyledLastButton
              disabled={idPaciente <= 0}
              classes={rutaPrincipal === 'incapacidades' ? { root: classes.buttonSelected } : {}}
              onClick={() => dispatch(setRutaPrincipal('incapacidades'))}
            >
              Incapacidades
            </StyledLastButton>
          )}
        </StyledButtonGroup>
        {rutaPrincipal === 'perfil' && <PerfilPaciente />}
        {idPaciente > 0 && (
          <>
            {rutaPrincipal === 'mcpa' && <MCPA />}
            {rutaPrincipal === 'antecedentes' && <Antecedentes />}
            {rutaPrincipal === 'aparatos-y-sistemas' && <AparatosYSistemas />}
            {rutaPrincipal === 'exploracion-fisica' && <ExploracionFisica />}
            {rutaPrincipal === 'diagnostico-y-plan' && <DiagnosticoPlan />}
            {rutaPrincipal === 'incapacidades' && <Incapacidades />}
          </>
        )}
      </div>
    </>
  );
}

export default TabsPaciente;
