import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TablaDocumentosCargados from '@components/Archivos/TablaDocumentosCargados';
import { getFechaActualISO } from '@common/functions';
import { ICargaDocumentosLaboratorioProps } from './types';

function CargaDocumentosLaboratorio({
  archivos,
  idMedico,
  setObjSeleccionArchivo,
  verArchivo,
  eliminarArchivo,
  totalPaginasArchivos,
  paginaArchivos,
  handleChangePaginaArchivos,
}: ICargaDocumentosLaboratorioProps) {
  const idLista = 0;
  const { t } = useTranslation();
  const seleccionarArchivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setObjSeleccionArchivo({ archivo: event.target.files[0], fecha: getFechaActualISO() });
    } else {
      setObjSeleccionArchivo(null);
    }
  };

  return (
    <div className="border-solid border border-gray-400 rounded p-4">
      <div className="flex justify-between">
        <h2 className="text-gray-500 font-normal m-0 pr-4">{t('d-p-plan-lab-cargar-titulo')}</h2>
        <label htmlFor="btn-upload-laboratorio">
          <input
            id="btn-upload-laboratorio"
            name="btn-upload-laboratorio"
            style={{ display: 'none' }}
            type="file"
            accept=".csv, .odt, .ods, odp, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
            onChange={seleccionarArchivo}
          />
          <Button component="span" color="primary" startIcon={<CloudUploadIcon />}>
            {t('d-p-plan-lab-cargar-doc')}
          </Button>
        </label>
      </div>
      {archivos.length > 0 && (
        <>
          <h3 className="my-2 text-gray-600 text-center">{t('documentos_cargados')}</h3>
          <TablaDocumentosCargados
            idMedico={idMedico}
            idLista={idLista}
            archivos={archivos}
            eliminarArchivo={eliminarArchivo}
            verArchivo={verArchivo}
          />
          <div className="flex justify-center mt-4">
            <Pagination
              count={totalPaginasArchivos}
              page={paginaArchivos}
              onChange={handleChangePaginaArchivos}
              shape="rounded"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default CargaDocumentosLaboratorio;
