import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RawDraftContentState } from 'draft-js';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
// dejado a proposito es el boton de carga de documentos
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { constDia, constMes, constAnio } from '@constants/fechas';
import useStyles from '@common/styles';
import { ICatalogosEstandar, IFechaField } from '@common/types';
import { valFechaToSave, validarFecha, validarFechaEsAnterior } from '@common/functions';
import ComentariosComponente from '@components/Comentarios';
import {
  transfusionesActualizarTranfusion,
  transfusionesUsuarioPacienteActualizarTranfusion,
} from '@utils/editInfo';
import {
  transfusionesGuardarTransfusion,
  transfusionesUsuarioPacienteGuardarTransfusion,
} from '@utils/sendInfo';
import { RootState } from 'src/store';
import { setRequest } from '@actions/request/types';
import { setTransfusiones } from '@actions/paciente/actions';
import { IFormularioTransfusionesProps } from './types';

function FormularioTransfusiones({
  idPaciente,
  catDiagnostico,
  idBD,
  setIDBD,
  diagnosticoRelacionadoALaTransfusion,
  setDiagnosticoRelacionadoALaTransfusion,
  // no sabemos si se va a utilizar otro diagnostico, dejado comentado
  // al dia 18 de noviembre de 2021
  // otroDiagnosticoRelacionadoALaTransfusion,
  // setOtroDiagosticoRelacionadoALaTransfusion,
  motivoTransfusion,
  setMotivoTransfusion,
  fecha,
  setFecha,
  componente,
  setComponente,
  otroComponente,
  setOtroComponente,
  comentarios,
  setComentarios,
}: IFormularioTransfusionesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idMedico, idUsuario, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { transfusiones } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const [keyComentarios, setKeyComentarios] = useState<number>(1);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const cambiarKeyComentarios = () => {
    setKeyComentarios(Math.floor(Math.random() * 1000) + 1);
  };

  const limpiarFormulario = () => {
    setDiagnosticoRelacionadoALaTransfusion({ id: 0, label: '' });
    // setOtroDiagosticoRelacionadoALaTransfusion('');
    setMotivoTransfusion('');
    setFecha({ dia: '', mes: '', anio: '' });
    setComponente('');
    setOtroComponente('');
    setComentarios({
      entityMap: {},
      blocks: [],
    });
    cambiarKeyComentarios();
  };

  const handleChangeDiagnosticoRelacionadoALaTransfusion = (
    event: any,
    newValue: ICatalogosEstandar | null,
  ) => {
    if (newValue) {
      setDiagnosticoRelacionadoALaTransfusion(newValue || { id: 0, label: '' });
      // if (newValue.label !== 'OTRO') {
      //   setOtroDiagosticoRelacionadoALaTransfusion('');
      // }
    }
  };

  // const handleChangeOtroDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const val = event.target.value as string;
  //   setOtroDiagosticoRelacionadoALaTransfusion(val);
  // };

  const handleChangeMotivoTransfusion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMotivoTransfusion(val);
  };

  const handleChangeDia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setFecha({ ...fecha, dia: val });
  };

  const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setFecha({ ...fecha, mes: val });
  };

  const handleChangeAnio = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setFecha({ ...fecha, anio: val });
  };

  const handleChangeComponente = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setComponente(val);
    if (val !== 'otro') {
      setOtroComponente('');
    }
  };

  const handleChangeOtroComponente = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setOtroComponente(val);
  };

  const handleClickGuardarTransfusion = () => {
    let comentario = {
      entityMap: {},
      blocks: [],
    } as RawDraftContentState;
    if (
      comentarios.blocks.length &&
      !comentarios.blocks.every((row) => row.text === '' || /^\s*$/.test(row.text))
    ) {
      comentario = comentarios;
    }
    const dataToSend = {
      idBD,
      diagnosticoRelacionadoALaTransfusion,
      // otroDiagnosticoRelacionadoALaTransfusion,
      motivoTransfusion,
      fecha: valFechaToSave(fecha),
      componente,
      otroComponente,
      comentarios: comentario,
    };
    const funcTransfusionesGuardarTransfusion = esPaciente
      ? transfusionesUsuarioPacienteGuardarTransfusion({
        ...dataToSend,
        idUsuario,
      })
      : transfusionesGuardarTransfusion({
        ...dataToSend,
        idPaciente,
        idMedico,
        consulta: idConsulta,
        idConsultorio,
      });
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: funcTransfusionesGuardarTransfusion,
        successFunction: (result: any) => {
          dispatch(
            setTransfusiones(
              transfusiones.concat({
                idMedico,
                idConsulta,
                idBD: result.datos as number,
                diagnosticoRelacionadoALaTransfusion,
                // otroDiagnosticoRelacionadoALaTransfusion,
                motivoTransfusion,
                fecha,
                componente,
                otroComponente,
                comentarios: comentario,
                archivos: [],
              }),
            ),
          );
          limpiarFormulario();
          setIDBD(-1);
        },
      }),
    );
  };

  const handleClickActualizarTransfusion = () => {
    let comentario = {
      entityMap: {},
      blocks: [],
    } as RawDraftContentState;
    if (
      comentarios.blocks.length &&
      !comentarios.blocks.every((row) => row.text === '' || /^\s*$/.test(row.text))
    ) {
      comentario = comentarios;
    }
    const dataToSend = {
      idBD,
      diagnosticoRelacionadoALaTransfusion,
      // otroDiagnosticoRelacionadoALaTransfusion,
      motivoTransfusion,
      fecha: valFechaToSave(fecha),
      componente,
      otroComponente,
      comentarios: comentario,
    };
    const funcTransfusionesActualizarTranfusion = esPaciente
      ? transfusionesUsuarioPacienteActualizarTranfusion({
        ...dataToSend,
        idUsuario,
      })
      : transfusionesActualizarTranfusion({
        ...dataToSend,
        idPaciente,
        idMedico,
        consulta: idConsulta,
        idConsultorio,
      });
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: funcTransfusionesActualizarTranfusion,
        successFunction: () => {
          dispatch(
            setTransfusiones(
              transfusiones.map((transf) => {
                if (transf.idBD === idBD) {
                  return {
                    idMedico,
                    idConsulta,
                    idBD: transf.idBD as number,
                    diagnosticoRelacionadoALaTransfusion,
                    // otroDiagnosticoRelacionadoALaTransfusion,
                    motivoTransfusion,
                    fecha: valFechaToSave(fecha),
                    componente,
                    otroComponente,
                    comentarios: comentario,
                    archivos: transf.archivos,
                  };
                }
                return transf;
              }),
            ),
          );
          limpiarFormulario();
          setIDBD(-1);
        },
      }),
    );
  };

  useEffect(() => {
    if (fecha.dia !== '' && fecha.mes !== '' && fecha.anio !== '') {
      if (
        !validarFecha(fecha.anio, fecha.mes, fecha.dia) ||
        !validarFechaEsAnterior(fecha.anio, fecha.mes, fecha.dia)
      ) {
        setFechaField({
          error: true,
          helperText: t('fecha_invalida'),
        });
      } else {
        setFechaField({
          error: false,
          helperText: '',
        });
      }
    }
  }, [fecha]);

  const disableAgregarTransfusion = () =>
    motivoTransfusion === '' ||
    motivoTransfusion.length < 5 ||
    fechaField.error ||
    componente === '';

  return (
    <div className="border border-solid border-gray-300 rounded p-4">
      <div className="flex justify-between">
        <h3 className="mt-0 text-gray-600 font-medium">
          {t('diagnostico_relacionado_a_la_transfusion')}
        </h3>
        <span className="text-red-500">
          {t('_para_guardar_el_antecedente_deberas_llenar_al_menos_el_campo_de_motivo_transfusion')}
        </span>
      </div>
      <div>
        <Autocomplete
          options={catDiagnostico}
          getOptionLabel={(option) => option.label}
          noOptionsText={t('sin_resultados')}
          value={diagnosticoRelacionadoALaTransfusion}
          onChange={(e, nV) => handleChangeDiagnosticoRelacionadoALaTransfusion(e, nV)}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label={t('selecciona')} variant="outlined" />
          )}
        />
      </div>
      {/* {diagnosticoRelacionadoALaTransfusion.label === 'OTRO' && (
        <div className="my-4">
          <TextField
            variant="outlined"
            value={otroDiagnosticoRelacionadoALaTransfusion}
            onChange={handleChangeOtroDiagnostico}
            fullWidth
            label={t('especifica')}
            inputProps={{
              maxLength: 150,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
          />
        </div>
      )} */}
      <div className="grid grid-cols-3 gap-x-4">
        <h3 className="mt-4 mb-2 col-span-2 text-gray-600 font-medium">
          {t('motivo_de_la_transfusion')}
          *
        </h3>
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('fecha')}</h3>
      </div>
      <div className="grid grid-cols-3 gap-x-4">
        <div className="col-span-2">
          <TextField
            variant="outlined"
            fullWidth
            label={t('especifica')}
            inputProps={{
              maxLength: 150,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
            value={motivoTransfusion}
            onChange={handleChangeMotivoTransfusion}
          />
        </div>
        <div className="w-full grid grid-cols-3 gap-2">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-dia">{t('dia')}</InputLabel>
            <Select
              labelId="label-dia"
              id="select-dia"
              value={fecha.dia}
              onChange={handleChangeDia}
              label={t('dia')}
              error={fechaField.error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
            >
              {constDia.map((diaSingle) => (
                <MenuItem value={diaSingle.toString()} key={diaSingle}>
                  {diaSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-mes">{t('mes')}</InputLabel>
            <Select
              labelId="label-mes"
              id="select-mes"
              value={fecha.mes}
              onChange={handleChangeMes}
              label={t('mes')}
              error={fechaField.error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
            >
              {constMes.map((mesSingle, indx) => (
                <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                  {mesSingle.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              className={classes.absoluteBottom}
              style={{ textAlign: 'center', margin: '0', width: '100%' }}
            >
              {fechaField.helperText}
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label-año">{t('anio')}</InputLabel>
            <Select
              labelId="label-año"
              id="select-año"
              value={fecha.anio}
              onChange={handleChangeAnio}
              label={t('anio')}
              error={fechaField.error}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 375,
                  },
                },
              }}
            >
              {constAnio().map((anioSingle) => (
                <MenuItem key={anioSingle} value={anioSingle.toString()}>
                  {anioSingle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <h3 className="mt-4 mb-2 text-gray-600 font-medium">
        {t('componente')}
        *
      </h3>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="componente"
          name="componente"
          value={componente}
          onChange={handleChangeComponente}
          row
        >
          <FormControlLabel
            value="globulos_rojos"
            control={<Radio color="primary" />}
            label={t('globulos_rojos')}
          />
          <FormControlLabel
            value="plasma"
            control={<Radio color="primary" />}
            label={t('plasma')}
          />
          <FormControlLabel
            value="plaquetas"
            control={<Radio color="primary" />}
            label={t('plaquetas')}
          />
          <FormControlLabel
            value="leucocitos"
            control={<Radio color="primary" />}
            label={t('leucocitos')}
          />
          <FormControlLabel
            value="desconoce"
            control={<Radio color="primary" />}
            label={t('desconoce')}
          />
          <FormControlLabel value="otro" control={<Radio color="primary" />} label={t('otro')} />
        </RadioGroup>
      </FormControl>
      {componente === 'otro' && (
        <div className="my-4">
          <TextField
            variant="outlined"
            fullWidth
            label={t('especifica')}
            inputProps={{
              maxLength: 150,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
            onChange={handleChangeOtroComponente}
            value={otroComponente}
          />
        </div>
      )}
      <div className="my-4">
        <ComentariosComponente
          comentarios={comentarios}
          setComentarios={(data: RawDraftContentState) => setComentarios(data)}
          rows={4}
          maxLength={500}
          placeholder={t('comentarios')}
          lang="es"
          disabled={false}
          key={`${keyComentarios}-${idBD}`}
        />
      </div>
      <div className="flex justify-end">
        {/* <Button color="primary" size="small" startIcon={<CloudUploadIcon />}>
          {t('cargar_documento')}
        </Button> */}
        {idBD > 0 ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SyncIcon />}
            onClick={handleClickActualizarTransfusion}
          >
            {t('actualizar')}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleClickGuardarTransfusion}
            disabled={disableAgregarTransfusion()}
          >
            {t('guardar')}
          </Button>
        )}
      </div>
      {idBD > 0 ? (
        <p className="text-right">
          {t('para_registrar_los_cambios_debes_dar_clic_en_el_boton_actualizar')}
        </p>
      ) : (
        <p className="text-right">
          {t('debes_dar_click_en_el_boton')}
          &nbsp;
          <span className="font-bold">{`"${t('guardar')}"`}</span>
          &nbsp;
          {t('para_incluir_el_antecedente_en_la_lista')}
        </p>
      )}
    </div>
  );
}

export default FormularioTransfusiones;
