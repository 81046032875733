import { Menu, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { IMenuHospital } from './types';

/** Componete que regresa el menú del hospital */
function MenuHospital({
  anchorEl, handleClose, open, handleCambiarHospital,
}: IMenuHospital) {
  const {
    consultorios,
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  return (
    <Menu
      id="menu-consultorios"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      {consultorios.map((consul) => (
        <MenuItem
          key={consul.label}
          onClick={() => handleCambiarHospital(consul.id)}
          selected={idConsultorio === consul.id}
        >
          {consul.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

export default MenuHospital;
