import { useTranslation } from 'react-i18next';
import DetalleArchivo from '../DetalleArchivo';
import { IDocumentosCargadoListadoProps } from './types';

function DocumentosCargadosListado({
  idMedico,
  idLista,
  archivos,
  eliminarArchivo,
  verArchivo,
}: IDocumentosCargadoListadoProps) {
  const { t } = useTranslation();

  return (
    <>
      <h4 className="mt-4 mb-0 text-gray-600">{t('documentos_cargados')}</h4>
      {archivos.map((arch) => (
        <DetalleArchivo
          key={arch.idArchivo}
          idMedico={idMedico}
          idLista={idLista}
          archivo={arch}
          eliminarArchivo={eliminarArchivo}
          verArchivo={verArchivo}
        />
      ))}
    </>
  );
}

export default DocumentosCargadosListado;
