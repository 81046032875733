import { IRequest, RequestActionTypes, SET_REQUEST_COMPLETE } from '@actions/request/actions';

const initialState: IRequest = {
  type: null,
  multiple: false,
  requestFunction: undefined,
  requestFunctions: [],
  successFunction: undefined,
  successFunctions: [],
  errorFunction: undefined,
  errorFunctions: [],
  catchFunction: undefined,
  alwaysFunction: undefined,
  successMessage: undefined,
  errorMessage: undefined,
  toJson: true,
  loading: true,
  showMsgs: true,
  showMsgLoading: true,
  showMsgSuccess: true,
  showMsgError: true,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const RequestReducer = (state: IRequest = initialState, action: RequestActionTypes) => {
  switch (action.type) {
    case SET_REQUEST_COMPLETE:
      return action.payload;
    default:
      return state;
  }
};

export default RequestReducer;
