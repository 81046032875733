import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Button,
  RadioGroup,
  FormControl,
  Typography,
  Popover,
  MenuItem,
  Select,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import DateDayjsUtils from '@date-io/dayjs';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { setLoading } from '@actions/loading/actions';
import { RootState } from 'src/store';
import {
  getBusinessDoctorPacientesEdadSexoAnio,
  getBusinessDoctorPacientesDistribucionGeografica,
  getCatalogoAgem,
} from '@utils/getCatalogos';
import {
  generarXlsPacientesDia,
  generarXlsPacientesDiagnosticosSexo,
  generarXlsPacientesDistribucionGeografica,
  generarXlsPacientesEdadSexo,
} from '@utils/documents';
import obtenerDatosGeograficos from '@utils/geografia';
import { setSnackComplete } from '@actions/snackbar/types';
import { ICatalogosEstandar } from '@common/types';
import { ReactComponent as Excel2021Icon } from '@assets/icons/Excel2021.svg';
import getMoneda from '@constants/monedas';
import { setRequest } from '@actions/request/types';
import useStyles from '../styles';
import { IObjPacientesDia } from '../types';
import Tabla from '../Tabla';
import {
  ICatalogoAGEE,
  ICatalogoAGEM,
} from '../../TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { IInteligenciaProps } from './types';

ChartJS.register(ArcElement, Tooltip, Legend);
dayjs.locale('es');

const Inteligencia = (props: IInteligenciaProps) => {
  const {
    idConsultorio,
    setIdConsultorio,
    tipoPacientes,
    fechaSeleccionada,
    setFechaSeleccionada,
    fechaSeleccionadaMayor,
    setFechaSeleccionadaMayor,
    filtroEdadSexo,
    setFiltroEdadSexo,
    datoPeticionGeneral,
    setDatoPeticionGeneral,
    datosPacienteDia,
    setDatosPacienteDia,
    datosPacienteEdadSexo,
    setDatosPacienteEdadSexo,
    datosPacienteDiagnosticoSexo,
    setDatosPacienteDiagnosticoSexo,
    datosPacienteAccidentes,
    setDatosPacienteAccidentes,
    datosPacienteIncapacidades,
    setDatosPacienteIncapacidades,
    datosPacienteDistribucionGeografica,
    setDatosPacienteDistribucionGeografica,
    catAGEE,
    catAGEM,
    setCatAGEM,
    selAGEE,
    setSelAGEE,
    selAGEM,
    setSelAGEM,
    tablaAreasOportunidad,
    getObtenerDatosInteligencia,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idMedico, pais, enOrganizacion } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { consultorios } = useSelector((state: RootState) => state.Consultorios);
  const [txtDelFiltro, setTxtDelFiltro] = useState<string>(t('business_rango_tiempo_busqueda'));
  const [popoverEl, setPopoverEl] = React.useState(null);

  const pageSize = 5;
  const columnasAreasOportunidad = [
    { Header: t('business_diagnostico_habito'), accessor: 'titulo' },
    {
      Header: `${t('business_perdida_presentismo')} (${getMoneda(pais).simbolo}) / ${t('anio_')}`,
      accessor: 'perdidaPresentismo',
    },
    {
      Header: `${t('business_perdida_ausentismo')} (${getMoneda(pais).simbolo}) / ${t('anio_')}`,
      accessor: 'perdidaAusentismo',
    },
    {
      Header: `${t('business_perdida_total')} (${getMoneda(pais).simbolo}) / ${t('anio_')}`,
      accessor: 'perdidaTotal',
    },
  ];
  const columnasPacientesDia = [
    { Header: t('calendar-date'), accessor: 'fecha' },
    { Header: t('total'), accessor: 'total' },
    { Header: t('business_medico_nuevos'), accessor: 'nuevos' },
    { Header: t('business_medico_subsecuentes'), accessor: 'subsecuentes' },
    { Header: t('business_medico_registrados'), accessor: 'registrados' },
    { Header: t('business_medico_diferencia'), accessor: 'diferencia' },
    // { Header: t('business_medico_inasistentes'), accessor: 'inasistentes' },
    // { Header: t('business_medico_ingresos'), accessor: 'ingresos' },
    // { Header: t('business_medico_perdidas'), accessor: 'perdidas' },
  ];
  const columnasPacientesEdadSexo = [
    { Header: t('edad'), accessor: 'grupo' },
    { Header: t('business_medico_mujeres'), accessor: 'mujer' },
    { Header: t('business_medico_hombres'), accessor: 'hombre' },
    { Header: t('total'), accessor: 'total' },
  ];
  const columnasDiagnostico = [
    { Header: t('diagnostico'), accessor: 'diagnostico' },
    { Header: `${t('business_medico_porcentaje')} %`, accessor: 'porcentaje' },
    { Header: t('business_medico_mujeres'), accessor: 'mujeres' },
    { Header: t('business_medico_hombres'), accessor: 'hombres' },
    { Header: t('total'), accessor: 'total' },
  ];
  const columnasAccidentes = [
    { Header: t('fecha'), accessor: 'fecha' },
    { Header: t('trabajador_a'), accessor: 'nombreEmpleado' },
    { Header: t('numero_trabajador_a'), accessor: 'numeroEmpleado' },
    { Header: t('accidentes_causa'), accessor: 'causaAccidente' },
    { Header: t('accidentes_lesion_naturaleza'), accessor: 'naturalezaLesion' },
    { Header: t('accidentes_incapacidad'), accessor: 'incapacidad' },
    { Header: t('departamento'), accessor: 'departamento' },
    { Header: t('area'), accessor: 'area' },
    { Header: t('incapacidades_dias'), accessor: 'diasIncapacidad' },
    { Header: t('incapacidades_tipo_riesgo'), accessor: 'tipoRiesgo' },
  ];
  const columnasIncapacidades = [
    { Header: t('trabajador_a'), accessor: 'nombreEmpleado' },
    { Header: t('numero_trabajador_a'), accessor: 'numeroEmpleado' },
    { Header: t('fecha'), accessor: 'fecha' },
    { Header: t('incapacidades_tipo_incidencia'), accessor: 'tipoIncidencia' },
    { Header: t('incapacidades_folio_imss'), accessor: 'folioImss' },
    { Header: t('incapacidades_folio_interno'), accessor: 'folioInterno' },
    { Header: t('incapacidades_dias'), accessor: 'diasIncapacidad' },
    { Header: t('fecha_inicio'), accessor: 'fechaInicio' },
    { Header: t('incapacidades_ramo_seguro'), accessor: 'ramoSeguro' },
    { Header: t('incapacidades_tipo_riesgo'), accessor: 'tipoRiesgo' },
    { Header: t('incapacidades_secuela'), accessor: 'secuela' },
    { Header: t('incapacidades_control_incapacidad'), accessor: 'controlIncapacidad' },
    // { Header: t('departamento'), accessor: 'departamento' },
    // { Header: t('area'), accessor: 'area' },
  ];
  const columnasDistribucionGeografica = [
    {
      Header:
        idConsultorio === -1
          ? t('business_medico_estado_municipio')
          : t('business_medico_estado_colonia'),
      accessor: 'nombre',
    },
    { Header: `${t('business_medico_porcentaje')} %`, accessor: 'porcentaje' },
    { Header: t('business_medico_mujeres'), accessor: 'mujeres' },
    { Header: t('business_medico_hombres'), accessor: 'hombres' },
    { Header: t('total'), accessor: 'total' },
  ];
  const geo = obtenerDatosGeograficos(pais);
  const catalogoConsultorio = consultorios.concat([
    { id: -1, label: `${t('business_todos_consultorios')}` },
  ]);
  const handlePopoverOpen = (event: any) => {
    setPopoverEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverEl(null);
  };
  const open = Boolean(popoverEl);
  // call para traer toda la informacion de las peticiones
  const findAllData = () => {
    dispatch(setLoading(true));
    const periodoMaximo =
      dayjs(datoPeticionGeneral.end).diff(datoPeticionGeneral.start, 'day') > 31;
    setFechaSeleccionadaMayor(periodoMaximo);
    setDatosPacienteDia([]);
    setDatosPacienteEdadSexo([]);
    setDatosPacienteDiagnosticoSexo([]);
    setDatosPacienteAccidentes([]);
    setDatosPacienteIncapacidades([]);
    setDatosPacienteDistribucionGeografica([]);
    if (!periodoMaximo) {
      setTxtDelFiltro(t('business_rango_periodo_maximo'));
    }
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getObtenerDatosInteligencia(periodoMaximo),
        successFunction: (result: any) => {
          setDatosPacienteDia(
            result.datos.pacienteDia
              ? result.datos.pacienteDia.map((row: IObjPacientesDia) => ({
                ...row,
                fecha: dayjs(row.fecha).format('YYYY-MMM-DD'),
              }))
              : [],
          );
          setDatosPacienteEdadSexo(result.datos.pacienteEdadSexo || []);
          setDatosPacienteDiagnosticoSexo(result.datos.diagnosticosSexo);
          setDatosPacienteAccidentes(
            result.datos.accidentes.map((accidente: any) => ({
              ...accidente,
              fecha: accidente.fecha.endsWith('00')
                ? dayjs(accidente.fecha).format('YYYY-MMM')
                : dayjs(accidente.fecha).format('YYYY-MMM-DD'),
              causaAccidente: accidente.causaAccidenteNombre || accidente.causaAccidenteLibre,
              naturalezaLesion: accidente.naturalezaLesionNombre || accidente.naturalezaLesionLibre,
              incapacidad: t(`accidentes_incapacidad_${accidente.incapacidadNombre}`),
              tipoRiesgo: t(accidente.tipoRiesgo),
            })),
          );
          setDatosPacienteIncapacidades(
            result.datos.incapacidades.map((incapacidad: any) => ({
              ...incapacidad,
              folioImss: incapacidad.tipoIncapacidad === 'imss' ? incapacidad.folio : '-',
              folioInterno: incapacidad.tipoIncapacidad === 'interna' ? incapacidad.folio : '-',
              fecha: incapacidad.fecha.endsWith('00')
                ? dayjs(incapacidad.fecha).format('YYYY-MMM')
                : dayjs(incapacidad.fecha).format('YYYY-MMM-DD'),
              fechaInicio: incapacidad.fechaInicio.endsWith('00')
                ? dayjs(incapacidad.fechaInicio).format('YYYY-MMM')
                : dayjs(incapacidad.fechaInicio).format('YYYY-MMM-DD'),
            })),
          );
          setDatosPacienteDistribucionGeografica(result.datos.distribucionGeografica);
        },
      }),
    );
    setFechaSeleccionada(true);
  };
  // call para traer lo que se solicita en el filtro edad de la tabla sexo edad
  const findEdadSexo = (val: string) => {
    dispatch(setLoading(true));
    getBusinessDoctorPacientesEdadSexoAnio(
      idMedico,
      idConsultorio,
      tipoPacientes,
      dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
      dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
      val,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setDatosPacienteEdadSexo(result.datos);
        } else {
          setDatosPacienteEdadSexo([]);
        }
        dispatch(setLoading(false));
      });
  };
  // cambio de agee o agem
  const handleChangeAgee = (event: any, newValue: ICatalogoAGEE | null) => {
    dispatch(setLoading(true));
    setSelAGEE(newValue);
    setSelAGEM(null);
    if (newValue) {
      getCatalogoAgem(newValue.cve_agee).then((result: ICatalogoAGEM[]) => {
        setCatAGEM(result);
      });
    }
    getBusinessDoctorPacientesDistribucionGeografica(
      idMedico,
      idOrganizacion,
      idConsultorio,
      tipoPacientes,
      dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
      dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
      newValue ? newValue.cve_agee : '',
      '',
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setDatosPacienteDistribucionGeografica(result.datos);
        } else {
          setDatosPacienteDistribucionGeografica([]);
        }
        dispatch(setLoading(false));
      });
  };
  const handleChangeAgem = (event: any, newValue: ICatalogoAGEM | null) => {
    dispatch(setLoading(true));
    if (newValue) {
      setSelAGEM(newValue);
      getBusinessDoctorPacientesDistribucionGeografica(
        idMedico,
        idOrganizacion,
        idConsultorio,
        tipoPacientes,
        dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
        dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
        selAGEE?.cve_agee || '',
        newValue.cve_agem,
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setDatosPacienteDistribucionGeografica(result.datos);
          } else {
            setDatosPacienteDistribucionGeografica([]);
          }
          dispatch(setLoading(false));
        })
        .catch(() => dispatch(setLoading(false)));
    } else {
      setSelAGEM(null);
      getBusinessDoctorPacientesDistribucionGeografica(
        idMedico,
        idOrganizacion,
        idConsultorio,
        tipoPacientes,
        dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
        dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
        selAGEE?.cve_agee || '',
        '',
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setDatosPacienteDistribucionGeografica(result.datos);
          } else {
            setDatosPacienteDistribucionGeografica([]);
          }
          dispatch(setLoading(false));
        })
        .catch(() => dispatch(setLoading(false)));
    }
  };

  return (
    <>
      {/* esta es la parte de los filtros */}
      <div className="flex flex-row items-end justify-end">
        <div>
          <h4 className="text-gray-500 font-normal m-0 pb-2">{`${t('business_rango_tiempo')}:`}</h4>
          <div className="flex flex-row">
            <div className="col-span-2 m-0 mr-2">
              <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                <KeyboardDatePicker
                  value={datoPeticionGeneral.start}
                  onChange={(e: any) => {
                    if (e) {
                      const dato = { ...datoPeticionGeneral };
                      dato.start = new Date(e.toDate());
                      setDatoPeticionGeneral(dato);
                    }
                  }}
                  maxDate={datoPeticionGeneral.end}
                  format="DD/MM/YYYY"
                  label={t('calendar-desde')}
                  invalidDateMessage={t('calendar-error-fecha')}
                  keyboardIcon={<CalendarTodayRoundedIcon color="primary" />}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-span-2 m-0 mr-2">
              <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
                <KeyboardDatePicker
                  value={datoPeticionGeneral.end}
                  onChange={(e) => {
                    if (e && dayjs(e.toDate()).isSameOrAfter(datoPeticionGeneral.start)) {
                      const dato = { ...datoPeticionGeneral };
                      dato.end = new Date(e.toDate());
                      setDatoPeticionGeneral(dato);
                    } else if (e) {
                      dispatch(
                        setSnackComplete({
                          open: true,
                          severity: 'error',
                          mensaje: `${t('calendar-hasta-hora')}`,
                        }),
                      );
                    }
                  }}
                  minDate={datoPeticionGeneral.start}
                  maxDate={new Date()}
                  format="DD/MM/YYYY"
                  label={t('calendar-hasta')}
                  minDateMessage={t('calendar-hasta-error')}
                  invalidDateMessage={t('calendar-error-fecha')}
                  keyboardIcon={<CalendarTodayRoundedIcon color="primary" />}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        <div className="ml-2 mr-3 w-1/5">
          <FormControl variant="outlined" fullWidth>
            {consultorios.length === 1 ? (
              <div>
                <Typography
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  {consultorios[0].label.length > window.innerWidth / 45
                    ? `${consultorios[0].label.substring(0, window.innerWidth / 45)}...`
                    : consultorios[0].label}
                </Typography>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{ paper: classes.paper }}
                  open={open}
                  anchorEl={popoverEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography>{consultorios[0].label}</Typography>
                </Popover>
              </div>
            ) : (
              <Select
                value={idConsultorio}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setIdConsultorio(Number(event.target.value));
                }}
                displayEmpty
              >
                <MenuItem value={0} disabled>
                  <span className="text-gray-400 font-light">{t('selecciona')}</span>
                </MenuItem>
                {catalogoConsultorio.map((singleConsultorio: ICatalogosEstandar) => (
                  <MenuItem value={singleConsultorio.id} key={singleConsultorio.id}>
                    {singleConsultorio.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
        <div className="mb-1">
          <Button variant="contained" color="primary" onClick={() => findAllData()}>
            {t('buscar')}
          </Button>
        </div>
      </div>
      <div className="py-2" />
      {/* Esta es la parte de los datos */}
      {enOrganizacion && (
        <div>
          <h2 className="text-blue-800 font-normal m-0 pb-4">{t('business_areas_oportunidad')}</h2>
          <Tabla
            columns={columnasAreasOportunidad}
            firstColumnIsTitle
            data={tablaAreasOportunidad}
          />
        </div>
      )}
      <div className="py-2" />
      <div className="flex justify-between items-center">
        <div className="text-left">
          <h2 className="text-blue-800 font-normal m-0">{t('business_pacientes_dia')}</h2>
        </div>
        {datosPacienteDia.length > 0 && (
          <div className="flex flex-row">
            <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (datosPacienteDia.length === 0) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: t('business_exportar_excel_sin_datos'),
                    }),
                  );
                } else {
                  const sendData = {
                    idMedico,
                    idOrganizacion,
                    idConsultorio,
                    start: dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
                    end: dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
                    tipoPacientes,
                  };
                  generarXlsPacientesDia(sendData);
                }
              }}
              color="primary"
            >
              <Excel2021Icon />
            </IconButton>
          </div>
        )}
      </div>
      {!fechaSeleccionada || fechaSeleccionadaMayor ? (
        <p className="text-red-500">{txtDelFiltro}</p>
      ) : (
        <Tabla
          columns={columnasPacientesDia}
          firstColumnIsTitle
          data={datosPacienteDia}
          pageCount={datosPacienteDia.length / pageSize}
          pageSize={pageSize}
        />
      )}
      <div className="py-2" />
      <div className="flex justify-between items-center">
        <div className="text-left">
          <h2 className="text-blue-800 font-normal m-0">{t('business_pacientes_edad_sexo')}</h2>
        </div>
        {fechaSeleccionada && !fechaSeleccionadaMayor ? (
          <div className="flex flex-row">
            <RadioGroup
              row
              aria-label="filtro"
              name="filtro"
              value={filtroEdadSexo}
              onChange={(event) => {
                const val = event.target.value as string;
                setFiltroEdadSexo(val);
                findEdadSexo(val);
              }}
            >
              <FormControlLabel
                value="year"
                control={<Radio color="primary" />}
                label={t('anios')}
              />
              <FormControlLabel
                value="month"
                control={<Radio color="primary" />}
                label={t('meses')}
              />
              <FormControlLabel value="day" control={<Radio color="primary" />} label={t('dias')} />
            </RadioGroup>
            <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (datosPacienteEdadSexo.length === 0) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: t('business_exportar_excel_sin_datos'),
                    }),
                  );
                } else {
                  const sendData = {
                    idMedico,
                    idConsultorio,
                    start: dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
                    end: dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
                    tipo: filtroEdadSexo,
                    tipoPacientes,
                  };
                  generarXlsPacientesEdadSexo(sendData);
                }
              }}
              color="primary"
            >
              <Excel2021Icon />
            </IconButton>
          </div>
        ) : null}
      </div>
      {!fechaSeleccionada || fechaSeleccionadaMayor ? (
        <p className="text-red-500">{txtDelFiltro}</p>
      ) : (
        <Tabla
          columns={columnasPacientesEdadSexo}
          firstColumnIsTitle
          data={datosPacienteEdadSexo}
          pageCount={datosPacienteEdadSexo.length / pageSize}
          pageSize={pageSize}
        />
      )}
      <div className="py-2" />
      <div className="flex justify-between items-center">
        <div className="text-left">
          <h2 className="text-blue-800 font-normal m-0">{t('business_diagnosticos')}</h2>
        </div>
        {fechaSeleccionada && (
          <div className="flex flex-row">
            <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (datosPacienteDiagnosticoSexo.length === 0) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: t('business_exportar_excel_sin_datos'),
                    }),
                  );
                } else {
                  const sendData = {
                    idMedico,
                    idConsultorio,
                    start: dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
                    end: dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
                    tipoPacientes,
                  };
                  generarXlsPacientesDiagnosticosSexo(sendData);
                }
              }}
              color="primary"
            >
              <Excel2021Icon />
            </IconButton>
          </div>
        )}
      </div>
      {!fechaSeleccionada ? (
        <p className="text-red-500">{txtDelFiltro}</p>
      ) : (
        <Tabla
          columns={columnasDiagnostico}
          firstColumnIsTitle
          data={datosPacienteDiagnosticoSexo}
          pageSize={11}
        />
      )}
      <div className="py-2" />
      <div className="flex justify-between items-center">
        <div className="text-left">
          <h2 className="text-blue-800 font-normal m-0">{t('business_accidentes')}</h2>
        </div>
        {fechaSeleccionada && (
          <div className="flex flex-row">
            <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (datosPacienteAccidentes.length === 0) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: t('business_exportar_excel_sin_datos'),
                    }),
                  );
                }
              }}
              color="primary"
            >
              <Excel2021Icon />
            </IconButton>
          </div>
        )}
      </div>
      {!fechaSeleccionada ? (
        <p className="text-red-500">{txtDelFiltro}</p>
      ) : (
        <Tabla columns={columnasAccidentes} firstColumnIsTitle data={datosPacienteAccidentes} />
      )}
      <div className="py-2" />
      <div className="flex justify-between items-center">
        <div className="text-left">
          <h2 className="text-blue-800 font-normal m-0">{t('incapacidades')}</h2>
        </div>
        {fechaSeleccionada && (
          <div className="flex flex-row">
            <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (datosPacienteIncapacidades.length === 0) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: t('business_exportar_excel_sin_datos'),
                    }),
                  );
                }
              }}
              color="primary"
            >
              <Excel2021Icon />
            </IconButton>
          </div>
        )}
      </div>
      {!fechaSeleccionada ? (
        <p className="text-red-500">{txtDelFiltro}</p>
      ) : (
        <Tabla
          columns={columnasIncapacidades}
          firstColumnIsTitle
          data={datosPacienteIncapacidades}
        />
      )}
      <div className="py-2" />
      <div className="flex justify-between items-center py-2">
        <div className="text-left">
          <h2 className="text-blue-800 font-normal m-0">{t('business_distribucion_geografica')}</h2>
        </div>
        {geo.agee && fechaSeleccionada && (
          <div className="flex flex-row w-3/5 justify-end">
            {idConsultorio > -1 && (
              <>
                <div className="flex-1 m-0 pr-2">
                  <Autocomplete
                    options={catAGEE}
                    getOptionLabel={(option) => option.nom_agee}
                    fullWidth
                    noOptionsText={t('sin_resultados')}
                    value={selAGEE}
                    onChange={(e, nV) => handleChangeAgee(e, nV)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${t(`${geo.agee.label}`)}*`}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                {geo.agem && (
                  <div className="flex-1 m-0 pr-2">
                    <Autocomplete
                      disabled={!selAGEE}
                      options={catAGEM}
                      getOptionLabel={(option) => option.nom_agem}
                      fullWidth
                      noOptionsText={t('sin_resultados')}
                      value={selAGEM}
                      onChange={(e, nV) => handleChangeAgem(e, nV)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={`${t(`${geo.agem.label}`)}*`}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                )}
              </>
            )}
            <div className="flex flex-row">
              <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
              <IconButton
                aria-label="edit"
                onClick={() => {
                  if (datosPacienteDistribucionGeografica.length === 0) {
                    dispatch(
                      setSnackComplete({
                        open: true,
                        severity: 'error',
                        mensaje: t('business_exportar_excel_sin_datos'),
                      }),
                    );
                  } else {
                    const sendData = {
                      idMedico,
                      idOrganizacion,
                      idConsultorio,
                      start: dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
                      end: dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
                      agee: selAGEE?.cve_agee || '',
                      agem: selAGEM?.cve_agem || '',
                      tipoPacientes,
                    };
                    generarXlsPacientesDistribucionGeografica(sendData);
                  }
                }}
                color="primary"
              >
                <Excel2021Icon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
      {!fechaSeleccionada ? (
        <p className="text-red-500">{txtDelFiltro}</p>
      ) : (
        <Tabla
          columns={columnasDistribucionGeografica}
          firstColumnIsTitle
          data={datosPacienteDistribucionGeografica}
        />
      )}
    </>
  );
};

export default Inteligencia;
