import { useTranslation } from 'react-i18next';
import ViewComentarios from '@components/Comentarios/view';
import { formatoDeIsoAFechaHora, stringMes } from '@common/functions';
import { IDiagnosticoEnfermedadProps } from './types';

function DiagnosticoEnfermedad({ diagnostico }: IDiagnosticoEnfermedadProps) {
  const { t } = useTranslation();
  const {
    seleccionDiagnostico,
    diagnosticoCie10,
    diagnosticoMedico,
    certezaDelDiagnostico,
    estatusDelDiagnostico,
    edadAlDiagnostico,
    fechaRegistroDiagnostico,
    comentarios,
  } = diagnostico;

  const borderDiagnostico = () => {
    let border = 'rounded p-4 w-full';
    if (certezaDelDiagnostico === 'presuntivo' && estatusDelDiagnostico !== 'resuelto') {
      border = 'border-0 border-solid border-red-500 rounded border-l-8 p-4 w-full';
    } else if (
      (certezaDelDiagnostico === 'confirmado' && estatusDelDiagnostico !== 'resuelto') ||
      estatusDelDiagnostico === 'activo'
    ) {
      border = 'border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full';
    }
    return border;
  };

  const tituloDiagnostico = () => {
    switch (seleccionDiagnostico) {
      case 'cie10':
        return diagnosticoCie10.label;
      case 'medico':
        return diagnosticoMedico;
      default:
        return '';
    }
  };

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <div className={borderDiagnostico()}>
        <div className="flex justify-between">
          <h2 className="m-0 overflow-ellipsis overflow-hidden text-left">{tituloDiagnostico()}</h2>
          {certezaDelDiagnostico !== '' && (
            <h4
              className={`font-normal m-0${
                certezaDelDiagnostico === 'presuntivo' ? ' text-red-500' : ' text-blue-500'
              }`}
            >
              {t(certezaDelDiagnostico)}
            </h4>
          )}
        </div>
        <div className="flex">
          {edadAlDiagnostico.edad !== 0 && (
            <h4 className="font-normal text-gray-500 m-0 mr-4">
              {`${t('edad_al_diagnostico')}: ${edadAlDiagnostico.edad} ${t(
                edadAlDiagnostico.periodo,
              )}`}
            </h4>
          )}
          {fechaRegistroDiagnostico.anio && (
            <h4 className="font-normal text-gray-500 m-0">
              {`${t('fecha_de_registro_del_diagnostico')}: ${
                fechaRegistroDiagnostico.dia ? `${fechaRegistroDiagnostico.dia}-` : ''
              }${
                fechaRegistroDiagnostico.mes ? `${stringMes(fechaRegistroDiagnostico.mes, t)}-` : ''
              }${fechaRegistroDiagnostico.anio}.`}
            </h4>
          )}
        </div>
        <div className="max-h-44 overflow-y-auto text-left mt-2">
          {comentarios.length > 0 &&
            comentarios.map((com) => (
              <>
                <h4 className="font-normal text-gray-500 m-0">
                  {`${t('fecha_del_comentario')}: ${formatoDeIsoAFechaHora(com.fecha, t)}`}
                </h4>
                <ViewComentarios comentarios={com.comentario} />
              </>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DiagnosticoEnfermedad;
