import { useCallback, useRef } from 'react';
import {
  DndProvider, useDrag, useDrop, DropTargetMonitor,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { XYCoord } from 'dnd-core';
import ViewRespuestaBreve from '@containers/Builder/Scaffold/RespuestaBreve/view';
import ViewParrafo from '@containers/Builder/Scaffold/Parrafo/view';
import ViewOpcionMultiple from '@containers/Builder/Scaffold/OpcionMultiple/view';
import ViewCasillasVerificacion from '@containers/Builder/Scaffold/CasillasVerificacion/view';
import ViewListaDesplegable from '@containers/Builder/Scaffold/ListaDesplegable/view';
import ViewEscalaLineal from '@containers/Builder/Scaffold/EscalaLineal/view';
import ViewFecha from '@containers/Builder/Scaffold/Fecha/view';
import ViewHora from '@containers/Builder/Scaffold/Hora/view';
import ViewTabla from '@containers/Builder/Scaffold/Tabla/view';
import { IRenderComponentProps, IRenderProps, IDragItem } from './types';

const style = {
  cursor: 'move',
};

function Render({
  formato, constructorComponente, index, id, mueveComponente,
}: IRenderProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'Componente',
    hover(item: IDragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      mueveComponente(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'Componente', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging() as boolean,
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  switch (formato) {
    case 1:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewRespuestaBreve componenteRespuesta={constructorComponente} />
        </div>
      );
    case 2:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewParrafo componenteParrafo={constructorComponente} />
        </div>
      );
    case 3:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewOpcionMultiple componenteCasillasOpciones={constructorComponente} />
        </div>
      );
    case 4:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewCasillasVerificacion componenteCasillasOpciones={constructorComponente} />
        </div>
      );
    case 5:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewListaDesplegable componenteListaDesplegable={constructorComponente} />
        </div>
      );
    case 6:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewEscalaLineal componenteEscalaLineal={constructorComponente} />
        </div>
      );
    case 7:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewTabla componenteTabla={constructorComponente} />
        </div>
      );
    case 8:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewFecha componenteFecha={constructorComponente} />
        </div>
      );
    case 9:
      return (
        <div ref={ref} style={{ ...style, opacity }}>
          <ViewHora componenteHora={constructorComponente} />
        </div>
      );
    default:
      return null;
  }
}

function RenderComponent({ arrComps, setArrComps }: IRenderComponentProps) {
  // MEJORAR LOGICA DEL ALGORITMO***
  const mueveComponente = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      // const compMovido = arrComps[dragIndex];
      setArrComps((prevA) => {
        const arr = [...prevA];
        const [ordenado] = arr.splice(dragIndex, 1);
        arr.splice(hoverIndex, 0, ordenado);
        return arr;
      });
    },
    [arrComps],
  );

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        {arrComps.map((single, indx) => (
          <Render
            formato={single.formato}
            constructorComponente={single.constructorComponente}
            key={single.id}
            index={indx}
            id={single.id}
            mueveComponente={mueveComponente}
          />
        ))}
      </DndProvider>
    </div>
  );
}

export default RenderComponent;
