import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  TextField, InputAdornment, ButtonGroup, Button,
} from '@material-ui/core';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import MoodIcon from '@material-ui/icons/Mood';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { RootState } from 'src/store';
import { validacionSoloNumeros } from '@constants/validaciones';
import { IMenstruacionHabitualProps } from './types';

function MenstruacionHabitual(props: IMenstruacionHabitualProps) {
  const { hayCambios, ginecoObstetrico, setGinecoObstetrico } = props;
  const { t } = useTranslation();
  const { esPaciente } = useSelector((state: RootState) => state.Me);

  const handleChangeRitmoCada = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        menstruacionHabitual: {
          ...ginecoObstetrico.menstruacionHabitual,
          ritmoCada: val.toString(),
        },
      });
    }
  };

  const handleChangeRitmoDuracion = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        menstruacionHabitual: {
          ...ginecoObstetrico.menstruacionHabitual,
          ritmoDuracion: val.toString(),
        },
      });
    }
  };

  const handleChangeCantidad = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val === '' || validacionSoloNumeros(val)) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        menstruacionHabitual: {
          ...ginecoObstetrico.menstruacionHabitual,
          cantidad: val.toString(),
        },
      });
    }
  };

  const handleChangeDolor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      menstruacionHabitual: {
        ...ginecoObstetrico.menstruacionHabitual,
        dolor: event.currentTarget.textContent as string,
      },
    });
  };

  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      menstruacionHabitual: {
        ...ginecoObstetrico.menstruacionHabitual,
        comentarios: event.target.value as string,
      },
    });
  };

  return (
    <div className="border-solid border-0 border-b border-gray-600">
      <h2 className="font-normal text-blue-800 mt-6 mb-0">{t('menstruacion_habitual')}</h2>
      <div className="grid grid-cols-4 gap-x-4 mb-4">
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">
          {t(esPaciente ? 'frecuencia' : 'ritmo')}
        </h3>
        <h3 className="mt-4 mb-2 text-gray-600 font-medium">{t('cantidad')}</h3>
        <h3 className="mt-4 mb-2 col-span-2 text-gray-600 font-medium">{t('dolor(intensidad)')}</h3>
        <div className="w-100 flex items-center self-start">
          <TextField
            variant="outlined"
            label={t('cada')}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('(dias)')}</InputAdornment>,
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '3',
              },
            }}
            value={ginecoObstetrico.menstruacionHabitual.ritmoCada}
            onChange={handleChangeRitmoCada}
            disabled={hayCambios}
          />
          <span className="px-2">X</span>
          <TextField
            variant="outlined"
            label={t('duracion')}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('(dias)')}</InputAdornment>,
              inputProps: {
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                maxLength: '2',
              },
            }}
            value={ginecoObstetrico.menstruacionHabitual.ritmoDuracion}
            onChange={handleChangeRitmoDuracion}
            disabled={hayCambios}
          />
        </div>
        <TextField
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t(esPaciente ? 'toalla-o-tampones-dia' : 'aposito-dia')}
              </InputAdornment>
            ),
            inputProps: {
              autoComplete: 'off',
              'aria-autocomplete': 'none',
              maxLength: '2',
            },
          }}
          value={ginecoObstetrico.menstruacionHabitual.cantidad}
          onChange={handleChangeCantidad}
          disabled={hayCambios}
        />
        <div className="col-span-2 border-solid border border-gray-400 rounded pt-4">
          <div className="grid grid-cols-3 justify-items-center">
            <h3 className="m-0 uppercase">{t('leve')}</h3>
            <h3 className="m-0 uppercase">{t('moderada')}</h3>
            <h3 className="m-0 uppercase">{t('intensa')}</h3>
            <MoodIcon />
            <SentimentSatisfiedIcon />
            <MoodBadIcon />
          </div>
          <div className="px-2 my-2">
            <ButtonGroup size="small" aria-label="small outlined button group" fullWidth>
              {[...Array(10)].map((x, i) => (
                <Button
                  variant={
                    parseInt(ginecoObstetrico.menstruacionHabitual.dolor, 10) === i + 1
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  onClick={handleChangeDolor}
                  disabled={hayCambios}
                >
                  {i + 1}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div
            className="w-full h-2.5"
            style={{
              background:
                'linear-gradient(270deg, #D9E9F7 0%, #0170A5 12.5%, #495678 25%, #00A13A 37.5%, #F7DE60 50%, #E87E18 62.5%, #DA271D 75%, #8F2829 87.5%)',
              borderRadius: '3px',
              transform: 'rotate(180deg)',
            }}
          />
        </div>
      </div>
      {!esPaciente && (
        <div className="mb-4">
          <TextField
            variant="outlined"
            rows={4}
            multiline
            fullWidth
            label={t('comentarios')}
            inputProps={{
              maxLength: 500,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
            value={ginecoObstetrico.menstruacionHabitual.comentarios}
            onChange={handleChangeComentario}
            disabled={hayCambios}
          />
        </div>
      )}
    </div>
  );
}

export default MenstruacionHabitual;
