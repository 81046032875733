import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { RootState } from 'src/store';
import {
  getDataTablaGloborisk,
  getValueMedicinaPreventiva,
  // getValueMedicinaPreventivaUsuario,
  getRiesgoGloborisk,
  getRiesgoAAA,
} from '@utils/getCatalogos';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import ModalRecomendacion from '../ModalRecomendacion';
import PrevencionCardiovascularDiv from './PrevencionCardiovascular';
import AneurismaAortaAbdominal from './AneurismaAortaAbdominal';

require('dayjs/locale/es');

function PrevencionCardiovascular() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    idPaciente,
    edad: { edad },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { secciones } = useSelector((state: RootState) => state.Me);
  const [petInfo, setPetInfo] = useState<boolean>(false);
  const [diabetico, setDiabetico] = useState<number | null>(null);
  const [esDiabetico, setEsDiabetico] = useState<number | null>(null);
  const [presionArterial, setPresionArterial] = useState<number | null>(null);
  const [colesterol, setColesterol] = useState<number | null>(null);
  const [imc, setImc] = useState<number | null>(null);
  const [fechaColesterol, setFechaColesterol] = useState<string>('');
  const [sexo, setSexo] = useState<string>('');
  const [toxi, setToxi] = useState<string | null>(null);
  const [stepShow, setStepShow] = useState<number>(0);
  const [tableGloborisk, setTableGloborisk] = useState<any[]>([]);
  const [stepShowAAA, setStepShowAAA] = useState<string>('');
  const [riesgoValor, setRiesgoValor] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ref, setRef] = useState<any>({});

  const evalPresion = (val: number) => {
    let retId = 0;
    if (val >= 70 && val < 140) {
      retId = 1;
    } else if (val >= 140 && val < 160) {
      retId = 2;
    } else if (val >= 160 && val < 180) {
      retId = 3;
    } else if (val >= 180 && val <= 270) {
      retId = 4;
    }
    return retId;
  };

  const evalColesterol = (val: number) => {
    let retCol = 0;
    if (val >= 68 && val < 155) {
      retCol = 1;
    } else if (val >= 155 && val < 193) {
      retCol = 2;
    } else if (val >= 193 && val < 232) {
      retCol = 3;
    } else if (val >= 232 && val < 271) {
      retCol = 4;
    } else if (val >= 271 && val <= 772) {
      retCol = 5;
    }
    return retCol;
  };

  const evalEdad = (val: number) => {
    let retCol = 0;
    if (val >= 40 && val < 45) {
      retCol = 1;
    } else if (val >= 45 && val < 50) {
      retCol = 2;
    } else if (val >= 50 && val < 55) {
      retCol = 3;
    } else if (val >= 55 && val < 60) {
      retCol = 4;
    } else if (val >= 60 && val < 65) {
      retCol = 5;
    } else if (val >= 65 && val < 70) {
      retCol = 6;
    } else if (val >= 70 && val < 75) {
      retCol = 7;
    } else if (val >= 75 && val < 80) {
      retCol = 8;
    } else if (val >= 80 && val < 81) {
      retCol = 9;
    }
    return retCol;
  };

  useEffect(() => {
    if (idPaciente > 0 && !petInfo) {
      dispatch(setLoading(true));
      const fetchValueMedicinaPreventiva = getValueMedicinaPreventiva(
        idPaciente,
        idConsulta,
        idMedico,
        idConsultorio,
      );
      const fetchRiesgoAAA = getRiesgoAAA(idMedico, idPaciente, idConsultorio);
      Promise.all([fetchValueMedicinaPreventiva, fetchRiesgoAAA])
        .then((responses) => {
          Promise.all([responses[0].json(), responses[1].json()])
            .then((results) => {
              if (results[0].code === 200 && results[0].datos && results[0].datos.length > 0) {
                const valToVar = results[0].datos[0];
                const objToxicomania = JSON.parse(valToVar.toxicomania);
                if (
                  objToxicomania &&
                  objToxicomania.tabaco &&
                  objToxicomania.tabaco.consumoTabaco
                ) {
                  setToxi(objToxicomania.tabaco.consumoTabaco);
                }
                setSexo(valToVar.sexo);
                setPresionArterial(valToVar.presionArterial);
                setColesterol(valToVar.valorColesterol);
                setImc(valToVar.imc);
                setFechaColesterol(`${valToVar.anio}-${valToVar.mes}-${valToVar.dia}`);
                // const pDiabetico = valToVar.numeroDiabetes
                //   ? valToVar.numeroDiabetes
                //   : valToVar.negadoDiabetes;
                const pDiabetico =
                  valToVar.numeroDiabetes ||
                  valToVar.negadoDiabetes ||
                  valToVar.negadoGrupoDiabetes;
                setDiabetico(pDiabetico);
                if (valToVar.numeroDiabetes) {
                  setEsDiabetico(1);
                } else if (valToVar.negadoDiabetes || valToVar.negadoGrupoDiabetes) {
                  setEsDiabetico(0);
                }
                if (valToVar.numeroEnfermedades && valToVar.numeroEnfermedades > 0) {
                  setStepShow(1);
                } else if (
                  !valToVar.numeroEnfermedades &&
                  valToVar.edad &&
                  valToVar.presionArterial &&
                  valToVar.valorColesterol &&
                  valToVar.imc &&
                  (valToVar.edad < 40 ||
                    valToVar.edad > 80 ||
                    valToVar.presionArterial < 70 ||
                    valToVar.presionArterial > 270 ||
                    valToVar.valorColesterol < 68 ||
                    valToVar.valorColesterol > 772 ||
                    valToVar.imc > 80)
                ) {
                  setStepShow(2);
                } else if (
                  !objToxicomania ||
                  (objToxicomania &&
                    objToxicomania.tabaco &&
                    objToxicomania.tabaco.consumoTabaco &&
                    objToxicomania.tabaco.consumoTabaco.length === '') ||
                  !valToVar.edad ||
                  !valToVar.presionArterial ||
                  !valToVar.valorColesterol ||
                  !valToVar.imc ||
                  !pDiabetico
                ) {
                  setStepShow(3);
                }
              }
              if (results[1].code === 200 && results[1].datos && results[1].datos.length > 0) {
                setStepShowAAA(results[1].datos[0].toxicomania || 'ERROR');
              }
              setPetInfo(true);
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-get-error')} ${err.toString()}`,
                }),
              );
              dispatch(setLoading(false));
            });
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${err.toString()}`,
            }),
          );
          setPresionArterial(null);
          setColesterol(null);
          setImc(null);
          setStepShow(0);
          setFechaColesterol('');
          setDiabetico(null);
          setPetInfo(true);
          dispatch(setLoading(false));
        });
    }
  }, [idPaciente]);

  useEffect(() => {
    if (petInfo && stepShow === 0) {
      dispatch(setLoading(true));
      const idPA = presionArterial ? evalPresion(presionArterial) : 0;
      const idF = toxi === 'activo' ? 1 : 0;
      const idD = esDiabetico ? 1 : 0;
      const idC = colesterol ? evalColesterol(colesterol) : 0;
      const idS = sexo && sexo === 'M' ? 1 : 0;
      const idE = edad ? evalEdad(edad) : 0;
      const fetchDataTablaGloborisk = getDataTablaGloborisk(
        idS,
        idD,
        idF,
        idE,
        true,
        idMedico,
        idPaciente,
        idConsultorio,
      );
      const fetchRiesgoGloborisk = getRiesgoGloborisk(
        idS,
        idD,
        idF,
        idPA,
        idC,
        idE,
        idMedico,
        idPaciente,
        idConsultorio,
      );
      Promise.all([fetchDataTablaGloborisk, fetchRiesgoGloborisk])
        .then((responses) => {
          Promise.all([responses[0].json(), responses[1].json()]).then((results) => {
            if (results[0].code === 200 && results[0].datos && results[0].datos.length > 0) {
              setTableGloborisk(results[0].datos);
            } else {
              setTableGloborisk([]);
            }
            if (results[1].code === 200 && results[1].datos && results[1].datos.length > 0) {
              setRiesgoValor(results[1].datos[0]);
            } else {
              setRiesgoValor({});
            }
            dispatch(setLoading(false));
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-get-error')} ${err.toString()}`,
            }),
          );
        });
    }
  }, [petInfo, stepShow]);

  return (
    <div className="flex w-full flex-col">
      <ModalRecomendacion
        open={openModal}
        callBackClose={() => {
          setOpenModal(false);
          setRef({});
        }}
        referencia={ref}
      />
      {stepShow === 1 ? (
        <div className="border border-solid border-red-500 rounded-lg w-full pl-8">
          <h3 className="text-blue-500 font-normal">{t('p_e_cardio_evaluacion')}</h3>
          <h3 className="text-red-500 font-normal">{t('p_e_cardio_evaluacion_no_calculo')}</h3>
        </div>
      ) : null}
      {stepShow === 2 ? (
        <div className="border border-solid border-red-500 rounded-lg w-full pl-8">
          <h3 className="text-blue-500 font-normal">{t('p_e_cardio_evaluacion')}</h3>
          <h3 className="text-red-500 font-normal">{`${t('p_e_cardio_evaluacion_rangos')}:`}</h3>
          <ul>
            {(edad < 40 || edad > 80) && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_edad_error')}</span>
              </li>
            )}
            {presionArterial && (presionArterial < 70 || presionArterial > 270) && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_presion_error')}</span>
              </li>
            )}
            {colesterol && (colesterol < 68 || colesterol > 772) && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_colesterol_error')}</span>
              </li>
            )}
            {imc && imc > 80 && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_imc_error')}</span>
              </li>
            )}
          </ul>
        </div>
      ) : null}
      {stepShow === 3 ? (
        <div className="border border-solid border-red-500 rounded-lg w-full pl-8">
          <h3 className="text-blue-500 font-normal">{t('p_e_cardio_evaluacion')}</h3>
          <h3 className="text-red-500 font-normal">{`${t('p_e_cardio_evaluacion_falta')}:`}</h3>
          <ul>
            {presionArterial === null && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_presion')}</span>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 46) && (
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push('/ece/exploracion-fisica');
                    }}
                  >
                    {`(${t('ir_a_la_exploracion_fisica')})`}
                  </Button>
                )}
              </li>
            )}
            {imc === null && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_imc')}</span>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 46) && (
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push('/ece/exploracion-fisica');
                    }}
                  >
                    {`(${t('ir_a_la_exploracion_fisica')})`}
                  </Button>
                )}
              </li>
            )}
            {colesterol === null && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_colesterol')}</span>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 51) && (
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push('/ece/diagnostico-y-plan/laboratorio');
                    }}
                  >
                    {`(${t('ir_a_la_seccion_laboratorio')})`}
                  </Button>
                )}
              </li>
            )}
            {toxi === null && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_tabaco')}</span>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 28) && (
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push('/ece/antecedentes/personales-no-patologicos/toxicomania');
                    }}
                  >
                    {`(${t('ir_a_la_seccion_toxicomania')})`}
                  </Button>
                )}
              </li>
            )}
            {diabetico === null && (
              <li className="text-red-500">
                <span className="text-gray-500">{t('p_e_cardio_er_diabetes')}</span>
                {secciones.length > 0 &&
                  secciones.find((valor) => valor.idSeccion === 14 || valor.idSeccion === 15) && (
                    <Button
                      color="primary"
                      onClick={() => {
                        history.push('/ece/antecedentes/personales-patologicos/enfermedades');
                      }}
                    >
                      {`(${t('ir_a_la_antecedentes_patologicos')})`}
                    </Button>
                )}
              </li>
            )}
          </ul>
        </div>
      ) : null}
      {stepShow === 0 ? (
        <PrevencionCardiovascularDiv
          presion={riesgoValor.PRESION}
          colesterol={riesgoValor.COLESTEROL}
          valor={riesgoValor.VALOR}
          sexo={sexo}
          toxi={toxi || ''}
          esDiabetico={esDiabetico || 0}
          colesterolResultado={colesterol || 0}
          fechaColesterol={fechaColesterol}
          tableGloborisk={tableGloborisk}
          edad={edad}
        />
      ) : null}
      <div className="py-2" />
      {stepShowAAA !== '' && <AneurismaAortaAbdominal txtToxicomania={stepShowAAA} />}
    </div>
  );
}

export default PrevencionCardiovascular;
