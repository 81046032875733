import {
  SET_CONSULTA,
  SET_ID_PACIENTE_CONSULTA,
  RESET_CONSULTA,
  ConsultaActionTypes,
} from '@actions/consulta/types';
import { getIdConsulta, getPaciente } from '@utils/common';

const initialState = {
  idConsulta: getIdConsulta(),
  idPaciente: getPaciente(),
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function Consulta(state = initialState, action: ConsultaActionTypes) {
  switch (action.type) {
    case SET_CONSULTA:
      return {
        ...state,
        idConsulta: action.payload.idConsulta,
        idPaciente: action.payload.idPaciente,
      };
    case SET_ID_PACIENTE_CONSULTA:
      return {
        ...state,
        idPaciente: action.payload,
      };
    case RESET_CONSULTA:
      return {
        ...state,
        idConsulta: 0,
        idPaciente: 0,
      };
    default:
      return state;
  }
}

export default Consulta;
