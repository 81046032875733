import { IEdad } from '@common/types';

export const GET_PACIENTE = 'GET_PACIENTE';
export const SET_PACIENTE = 'SET_PACIENTE';
export const REMOVE_PACIENTE = 'REMOVE_PACIENTE';
export const PACIENTE_ERROR = 'PACIENTE_ERROR';
export const PACIENTE_NOMBRE = 'PACIENTE_NOMBRE';
export const PACIENTE_SEXO = 'PACIENTE_SEXO';
export const PACIENTE_DIA = 'PACIENTE_DIA';
export const PACIENTE_MES = 'PACIENTE_MES';
export const PACIENTE_ANIO = 'PACIENTE_ANIO';
export const PACIENTE_ID = 'PACIENTE_ID';
export const PACIENTE_ID_USUARIO = 'PACIENTE_ID_USUARIO';
export const PACIENTE_USUARIO = 'PACIENTE_USUARIO';
export const PACIENTE_EDAD = 'PACIENTE_EDAD';
export const PACIENTE_DOC_ID = 'PACIENTE_DOC_ID';
export const PACIENTE_SIN_DOC_ID = 'PACIENTE_SIN_DOC_ID';
export const PACIENTE_GESTACION = 'PACIENTE_GESTACION';
export const PACIENTE_TIPO_EMPLEADO = 'PACIENTE_TIPO_EMPLEADO';
export const PACIENTE_NUMERO_EMPLEADO = 'PACIENTE_NUMERO_EMPLEADO';
export const PACIENTE_ALERGIAS = 'PACIENTE_ALERGIAS';
export const PACIENTE_TELEFONOS = 'PACIENTE_TELEFONOS';
export const PACIENTE_SHOWCERVICOUTERINO = 'PACIENTE_SHOWCERVICOUTERINO';
export const PACIENTE_EXISTSESTUDIOS = 'PACIENTE_EXISTSESTUDIOS';

export interface IAlergias {
  grupo: number;
  nomVisual: string;
}

export interface IObjEstudios {
  existsEstudios: boolean;
  fecha: string;
}

interface IPaciente {
  nombre: string;
  sexo: string;
  dia: string;
  mes: string;
  anio: string;
  idPaciente: number;
  idUsuario: number;
  docId: string;
  sinDocId: boolean | null;
  usuario: string;
  edad: IEdad;
  gestacion: string;
  tipoEmpleado: 'directo' | 'externo' | 'candidato' | 'contratista' | 'otro' | null;
  numeroEmpleado: string;
  alergias: IAlergias[];
  telefonos: string[];
  showCervicoUterino: boolean;
  existsEstudios: IObjEstudios;
}

export interface IPacienteState {
  nombre: string;
  sexo: string;
  dia: string;
  mes: string;
  anio: string;
  idPaciente: number;
  idUsuario: number;
  docId: string;
  sinDocId: boolean | null;
  usuario: string;
  edad: IEdad;
  gestacion: string;
  tipoEmpleado: 'directo' | 'externo' | 'candidato' | 'contratista' | 'otro' | null;
  numeroEmpleado: string;
  alergias: IAlergias[];
  telefonos: string[];
  mensaje: string;
  error: boolean;
  showCervicoUterino: boolean;
  existsEstudios: IObjEstudios;
}

interface SetPacienteAction {
  type: typeof SET_PACIENTE;
  payload: IPaciente;
}

interface RemovePacienteAction {
  type: typeof REMOVE_PACIENTE;
}

interface PacienteErrorAction {
  type: typeof PACIENTE_ERROR;
  payload: string;
}

interface SetPacienteNombreAction {
  type: typeof PACIENTE_NOMBRE;
  payload: string;
}

interface SetPacienteSexoAction {
  type: typeof PACIENTE_SEXO;
  payload: string;
}

interface SetPacienteDiaAction {
  type: typeof PACIENTE_DIA;
  payload: string;
}

interface SetPacienteMesAction {
  type: typeof PACIENTE_MES;
  payload: string;
}

interface SetPacienteAnioAction {
  type: typeof PACIENTE_ANIO;
  payload: string;
}

interface SetPacienteUsuarioAction {
  type: typeof PACIENTE_USUARIO;
  payload: string;
}

interface SetPacienteIDAction {
  type: typeof PACIENTE_ID;
  payload: number;
}

interface SetPacienteIdUsuarioAction {
  type: typeof PACIENTE_ID_USUARIO;
  payload: number;
}

interface SetPacienteEdadAction {
  type: typeof PACIENTE_EDAD;
  payload: IEdad;
}

interface SetPacienteGestacionAction {
  type: typeof PACIENTE_GESTACION;
  payload: string;
}

interface SetPacienteTipoEmpleadoAction {
  type: typeof PACIENTE_TIPO_EMPLEADO;
  payload: 'directo' | 'externo' | 'candidato' | 'contratista' | 'otro' | null;
}

interface SetPacienteNumeroEmpleadoAction {
  type: typeof PACIENTE_NUMERO_EMPLEADO;
  payload: string;
}

interface SetPacienteAlergiasAction {
  type: typeof PACIENTE_ALERGIAS;
  payload: IAlergias[];
}

interface SetPacienteDocId {
  type: typeof PACIENTE_DOC_ID;
  payload: string;
}

interface SetPacienteSinDocId {
  type: typeof PACIENTE_SIN_DOC_ID;
  payload: boolean;
}

interface SetPacienteTelefonosAction {
  type: typeof PACIENTE_TELEFONOS;
  payload: string[];
}

interface SetPacienteShowCervicouterinoAction {
  type: typeof PACIENTE_SHOWCERVICOUTERINO;
  payload: boolean;
}

interface SetPacienteExistsEstudiosAction {
  type: typeof PACIENTE_EXISTSESTUDIOS;
  payload: IObjEstudios;
}

export type PacienteActionTypes =
  | SetPacienteAction
  | RemovePacienteAction
  | SetPacienteAnioAction
  | SetPacienteMesAction
  | SetPacienteDiaAction
  | SetPacienteNombreAction
  | SetPacienteSexoAction
  | SetPacienteUsuarioAction
  | SetPacienteIDAction
  | SetPacienteIdUsuarioAction
  | PacienteErrorAction
  | SetPacienteEdadAction
  | SetPacienteDocId
  | SetPacienteSinDocId
  | SetPacienteGestacionAction
  | SetPacienteTipoEmpleadoAction
  | SetPacienteNumeroEmpleadoAction
  | SetPacienteAlergiasAction
  | SetPacienteShowCervicouterinoAction
  | SetPacienteExistsEstudiosAction
  | SetPacienteTelefonosAction;
