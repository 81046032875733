import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, IconButton, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ICatStrEstandar } from '@common/types';
import DeleteDialog from '@components/DeleteDialog';
import { IProcedimientosProps } from './types';

function ProcedimientosComponente({
  arrayData,
  setArrayData,
  selArray,
  cie9,
  index,
  setSaveClick,
}: IProcedimientosProps) {
  const { t } = useTranslation();
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const eliminarProcedimiento = () => {
    const valArray = [...arrayData];
    if (valArray[selArray].procedimientosArray.length === 1) {
      valArray[selArray].procedimientosArray = [
        {
          idBd: -1,
          selector: 'cie9',
          cie9: { id: '', label: '' },
          txtLibre: '',
          actualizado: true,
        },
      ];
    } else {
      const proArray = valArray[selArray].procedimientosArray;
      proArray.splice(index, 1);
      valArray[selArray].procedimientosArray = proArray;
    }
    setArrayData(valArray);
    setSaveClick(true);
  };
  const handleChangeProcedimiento = (event: any, newValue: ICatStrEstandar | null) => {
    const valArray = [...arrayData];
    if (newValue) {
      valArray[selArray].procedimientosArray[index].cie9 = newValue;
      valArray[selArray].procedimientosArray[index].actualizado = true;
    } else {
      valArray[selArray].procedimientosArray[index].cie9 = { id: '', label: '' };
      valArray[selArray].procedimientosArray[index].actualizado = true;
    }
    setArrayData(valArray);
    setSaveClick(true);
  };
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 flex" key={index}>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('d-p-plan-pro-del-pro')}
        callBackAceptar={() => eliminarProcedimiento()}
        callBackClose={handleAlertClose}
      />
      <div className="w-full">
        <h3 className="font-normal m-0 text-gray-600 pb-3">{t('d-p-plan-pro-sol-nproc')}</h3>
        <div className="pb-3">
          <RadioGroup
            aria-label="realiza"
            name="realiza"
            value={arrayData[selArray].procedimientosArray[index].selector}
            onChange={(e) => {
              const newVal = (e.target as HTMLInputElement).value;
              const valArray = [...arrayData];
              valArray[selArray].procedimientosArray[index].selector = newVal;
              valArray[selArray].procedimientosArray[index].cie9 = { id: '', label: '' };
              valArray[selArray].procedimientosArray[index].txtLibre = '';
              valArray[selArray].procedimientosArray[index].actualizado = true;
              setArrayData(valArray);
              setSaveClick(true);
            }}
            row
          >
            <FormControlLabel value="cie9" control={<Radio color="primary" />} label={t('cie-9')} />
            <FormControlLabel
              value="txtLibre"
              control={<Radio color="primary" />}
              label={t('texto-libre')}
            />
          </RadioGroup>
        </div>
        <div>
          {arrayData[selArray].procedimientosArray[index].selector === 'cie9' ? (
            <Autocomplete
              id="cie9"
              options={cie9}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('sin_resultados')}
              value={arrayData[selArray].procedimientosArray[index].cie9}
              onChange={(e, nV) => handleChangeProcedimiento(e, nV)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t('selecciona')} variant="outlined" />
              )}
            />
          ) : (
            <TextField
              id="especifica"
              label={t('especifica')}
              value={arrayData[selArray].procedimientosArray[index].txtLibre}
              onChange={(event) => {
                const valArray = [...arrayData];
                valArray[selArray].procedimientosArray[index].txtLibre = event.target.value;
                valArray[selArray].procedimientosArray[index].actualizado = true;
                setArrayData(valArray);
                setSaveClick(true);
              }}
              variant="outlined"
              fullWidth
            />
          )}
        </div>
      </div>
      <div className="justify-end self-center">
        <IconButton aria-label="delete" onClick={() => setDeleteAlert(true)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ProcedimientosComponente;
