import { ICatalogosEstandar } from '@common/types';

export interface ITipoActividad {
  idTipo: ICatalogosEstandar;
  especifica: string;
  frecuencia: string;
  duracion: string;
}

export interface IActividadFisica {
  loaded: boolean;
  realiza: string;
  tipo: ITipoActividad[];
  comentarios: string;
}

export const actividadFisicaInitial: IActividadFisica = {
  loaded: false,
  realiza: '',
  tipo: [
    {
      idTipo: { id: 0, label: '' },
      especifica: '',
      frecuencia: '',
      duracion: '',
    },
  ],
  comentarios: '',
};
