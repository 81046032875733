import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select, TextField, FormControl, InputLabel, MenuItem,
} from '@material-ui/core';
import { capitalize } from '@common/functions';
import { IConstructorHoraProps } from './types';

function BuilderHora({ variable, setVariable }: IConstructorHoraProps) {
  const { t } = useTranslation();
  /** States del functional component */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        descripcion: val,
      },
      constructor: {
        ...preCons.constructor,
        descripcion: val,
      },
    }));
  };
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-2 gap-4 items-center">
      <div>
        <div className="flex flex-wrap mb-3.5 items-end">
          <h3 className="w-max mr-4 mb-2 mt-0 text-gray-600 font-medium">
            {capitalize(variable.datosComponente.titulo)}
          </h3>
          <div className="w-1/2 flex-wrap">
            <h3 className="mt-0 mb-2 text-gray-600 font-medium">
              {t('incluye_una_descripcion_opcional')}
            </h3>
            <TextField
              fullWidth
              value={variable.datosComponente.descripcion}
              onChange={handleChangeDescripcion}
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              multiline
              rows={1}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mb-3">
          <TextField label={t('hora')} variant="outlined" fullWidth disabled value="00:00" />
          <FormControl variant="outlined" fullWidth disabled>
            <InputLabel>{t('horario')}</InputLabel>
            <Select label={t('horario')} value={10}>
              <MenuItem value={10}>a.m.</MenuItem>
              <MenuItem value={20}>p.m.</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TextField
        label={t('comentarios')}
        variant="outlined"
        rows={6}
        multiline
        fullWidth
        disabled
      />
    </div>
  );
}

export default BuilderHora;
