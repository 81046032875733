import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from '@material-ui/core';
import { getToken, getRecomendacionReferencia } from '@utils/common';
import { IPrevencionAuditivaProps } from './types';
import ModalRecomendacion from '../../ModalRecomendacion';

dayjs.locale('es');

function PrevencionAuditiva({ nivel, fechaEstudio }: IPrevencionAuditivaProps) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ref, setRef] = useState<any>({});

  const revRangoNivelTxt = () => {
    if (nivel >= 85) {
      return t('pre_rie_auditivo_aud_ver_85_sup');
    }
    return t('pre_rie_auditivo_aud_ver_80_85');
  };

  return (
    <div className="flex-1">
      <ModalRecomendacion
        open={openModal}
        callBackClose={() => {
          setOpenModal(false);
          setRef({});
        }}
        referencia={ref}
      />
      <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
        <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
          <h3 className="m-0 text-left text-blue-500 font-normal">{t('pre_rie_auditivo')}</h3>
          <div className="flex pt-2">
            <h4 className="font-normal m-0 text-gray-500 pr-4">
              {`${t('pre_rie_nivel')}: ${nivel} dB(A)`}
            </h4>
            <h4 className="font-normal m-0 text-gray-500 pr-4">
              {`${t('pre_rie_auditivo_fec_ult_aud')}: ${dayjs(`${fechaEstudio}`, 'YYYY M D').format(
                'DD-MMM-YYYY',
              )}`}
            </h4>
          </div>
          <h4 className="font-normal m-0 text-gray-900 pt-2">
            <p>
              <Trans i18nKey="pre_rie_auditivo_nom">
                <span>index0</span>
                <a
                  href="https://www.dof.gob.mx/nota_detalle.php?codigo=734536&fecha=17/04/2002"
                  target="_blank"
                  rel="noreferrer"
                >
                  index1
                </a>
                <span>index2</span>
              </Trans>
            </p>
          </h4>
          <h4 className="font-normal m-0 text-gray-500 py-2">{revRangoNivelTxt()}</h4>
        </div>
        <div className="min-w-max self-end">
          <Button
            color="primary"
            onClick={() => {
              const token = getToken();
              fetch(getRecomendacionReferencia('8'), {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
              })
                .then((response) => response.json())
                .then((result) => {
                  if (result.code === 200 && result.datos && result.datos.length > 0) {
                    setRef(result.datos[0]);
                    setOpenModal(true);
                  }
                })
                .catch(() => {
                  setRef({});
                });
            }}
          >
            {t('ver_recomendacion')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PrevencionAuditiva;
