import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { capitalize } from '@common/functions';
import { IConstructorEscalaLinealProps, IObjValues } from './types';

function BuilderEscalaLineal({ variable, setVariable, paso }: IConstructorEscalaLinealProps) {
  const { t } = useTranslation();
  /** States del functional component */
  const [value, setValue] = React.useState<number | number[]>(0);
  const [marks, setMarks] = useState<IObjValues[]>([]);
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  /** Logica */
  /** Hooks */
  /** Efecto al cargar la pagina */
  useEffect(() => {
    if (
      variable.constructor.escalaLineal!.valor.final -
        variable.constructor.escalaLineal!.valor.inicial <=
      30
    ) {
      const arrayMarks = [];
      for (
        let step = variable.constructor.escalaLineal!.valor.inicial;
        step <= variable.constructor.escalaLineal!.valor.final;
        step += 1
      ) {
        arrayMarks.push({ value: step, label: step.toString() });
      }
      setMarks(arrayMarks);
    }
  }, [variable.constructor.escalaLineal!.valor]);
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers de la snackbar */
  /** Handlers del componente */
  const handleChangeValInicial = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = parseInt(e.target.value as string, 10) || 0;
    if (
      val < variable.constructor.escalaLineal!.valor.final &&
      variable.constructor.escalaLineal!.valor.final - val <= 100
    ) {
      setVariable((preCons) => ({
        ...preCons,
        constructor: {
          ...preCons.constructor,
          escalaLineal: {
            ...preCons.constructor.escalaLineal!,
            valor: {
              ...preCons.constructor.escalaLineal!.valor,
              inicial: val,
            },
          },
        },
        valores: {
          ...preCons.valores,
          escalaLineal: {
            ...preCons.valores.escalaLineal!,
            valor: {
              ...preCons.valores.escalaLineal!.valor,
              inicial: val,
            },
          },
        },
      }));
      setValue(val);
    }
  };

  const handleChangeValFinal = (e: React.ChangeEvent<{ value: unknown }>) => {
    let val = parseInt(e.target.value as string, 10);
    val = Number.isNaN(val) ? 1 : val;
    if (
      val > variable.constructor.escalaLineal!.valor.inicial &&
      val - variable.constructor.escalaLineal!.valor.inicial <= 100
    ) {
      setVariable((preCons) => ({
        ...preCons,
        constructor: {
          ...preCons.constructor,
          escalaLineal: {
            ...preCons.constructor.escalaLineal!,
            valor: {
              ...preCons.constructor.escalaLineal!.valor,
              final: val,
            },
          },
        },
        valores: {
          ...preCons.valores,
          escalaLineal: {
            ...preCons.valores.escalaLineal!,
            valor: {
              ...preCons.valores.escalaLineal!.valor,
              final: val,
            },
          },
        },
      }));
    }
  };

  const handleChangeDescripcion = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        descripcion: val,
      },
      constructor: {
        ...preCons.constructor,
        descripcion: val,
      },
    }));
  };

  const handleChangeEI = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      constructor: {
        ...preCons.constructor,
        escalaLineal: {
          ...preCons.constructor.escalaLineal!,
          escala: {
            ...preCons.constructor.escalaLineal!.escala,
            inicial: val,
          },
        },
      },
      valores: {
        ...preCons.valores,
        escalaLineal: {
          ...preCons.valores!.escalaLineal!,
          escala: {
            ...preCons.valores.escalaLineal!.escala,
            inicial: val,
          },
        },
      },
    }));
  };

  const handleChangeEF = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setVariable((preCons) => ({
      ...preCons,
      constructor: {
        ...preCons.constructor,
        escalaLineal: {
          ...preCons.constructor.escalaLineal!,
          escala: {
            ...preCons.constructor.escalaLineal!.escala,
            final: val,
          },
        },
      },
      valores: {
        ...preCons.valores,
        escalaLineal: {
          ...preCons.valores!.escalaLineal!,
          escala: {
            ...preCons.valores.escalaLineal!.escala,
            final: val,
          },
        },
      },
    }));
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue);
  };

  const valueText = (val: number) => val.toString();
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** handler del evento submit */
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-1 gap-4">
      {paso >= 2 ? null : (
        <>
          <div className="font-bold">{t('instrucciones')}</div>
          {paso < 1 ? <div>{t('e-l-escala')}</div> : <div>{t('e-l-etiquetas')}</div>}
          <div className="border-solid border-0 border-b border-gray-600" />
        </>
      )}
      <div>
        <h3 className="mt-4 mb-2 w-full text-center text-gray-600 font-medium">
          {capitalize(variable.datosComponente.titulo)}
        </h3>
        <div className="grid grid-cols-8 gap-2 mb-3.5 items-end">
          <div />
          <div>
            {paso === 0 && (
              <>
                <h4 className="my-2">{t('valor_inicial')}</h4>
                <TextField
                  fullWidth
                  value={variable.constructor.escalaLineal!.valor.inicial.toString()}
                  onChange={handleChangeValInicial}
                  type="number"
                  onKeyPress={(e) => {
                    if (e.key === '.') {
                      e.preventDefault();
                    }
                  }}
                />
              </>
            )}
          </div>
          <div />
          <div className="col-span-2">
            <h4 className="my-2">{t('incluye_una_descripcion_opcional')}</h4>
            <TextField
              fullWidth
              value={variable.datosComponente.descripcion}
              onChange={handleChangeDescripcion}
              inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
              multiline
              rows={1}
            />
          </div>
          <div />
          <div>
            {paso === 0 && (
              <>
                <h4 className="my-2">{t('valor_final')}</h4>
                <TextField
                  fullWidth
                  value={variable.constructor.escalaLineal!.valor.final.toString()}
                  onChange={handleChangeValFinal}
                  type="number"
                  onKeyPress={(e) => {
                    if (e.key === '.') {
                      e.preventDefault();
                    }
                  }}
                />
              </>
            )}
          </div>
          <div />
        </div>
      </div>
      <div />
      <div className="grid grid-cols-8 gap-2 mb-3.5 items-end">
        <div />
        <div className="col-span-6">
          <Slider
            value={value}
            getAriaValueText={valueText}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            step={1}
            onChange={handleChange}
            min={variable.constructor.escalaLineal!.valor.inicial}
            max={variable.constructor.escalaLineal!.valor.final}
            marks={
              variable.constructor.escalaLineal!.valor.final -
                variable.constructor.escalaLineal!.valor.inicial <=
              30
                ? marks
                : true
            }
            disabled
          />
        </div>
        <div />
      </div>
      {paso >= 1 && (
        <div>
          <div className="grid grid-cols-12 gap-2 mb-3.5 items-end">
            <div />
            <div className="col-span-2">
              {paso === 1 ? (
                <>
                  <h4 className="my-2">{t('e-l-escalaInicial')}</h4>
                  <TextField
                    fullWidth
                    value={variable.constructor.escalaLineal!.escala.inicial}
                    onChange={handleChangeEI}
                    inputProps={{ maxLength: 20, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                  />
                </>
              ) : (
                <h4 className="col-span-2">{variable.constructor.escalaLineal!.escala.inicial}</h4>
              )}
            </div>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div className="text-right col-span-2">
              {paso === 1 ? (
                <>
                  <h4 className="my-2">{t('e-l-escalaFinal')}</h4>
                  <TextField
                    fullWidth
                    value={variable.constructor.escalaLineal!.escala.final}
                    onChange={handleChangeEF}
                    inputProps={{
                      maxLength: 20,
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      className: 'text-right',
                    }}
                  />
                </>
              ) : (
                <h4 className="col-span-2">{variable.constructor.escalaLineal!.escala.final}</h4>
              )}
            </div>
            <div />
          </div>
        </div>
      )}
      {paso === 2 && (
        <TextField
          label={t('comentarios')}
          variant="outlined"
          rows={6}
          multiline
          fullWidth
          disabled
        />
      )}
    </div>
  );
}

export default BuilderEscalaLineal;
