import { ICatalogosEstandar } from '@common/types';

interface IServiciosBasicos {
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
}

export interface ITipoMascota {
  idAnimal: ICatalogosEstandar;
  especifica: string;
  cantidad: string;
}

export interface IVivienda {
  loaded: boolean;
  serviciosBasicos: IServiciosBasicos;
  numeroHabitaciones: number | null;
  numeroHabitantes: number | null;
  indiceHac: string;
  animales: string;
  tipo: ITipoMascota[];
  comentarios: string;
  trayecto_trabajo: 'Si' | 'No' | '';
  riesgo_inundacion: 'Si' | 'No' | '';
  zona_deslaves: 'Si' | 'No' | '';
}

export const viviendaInitial: IVivienda = {
  loaded: false,
  serviciosBasicos: {
    check1: false,
    check2: false,
    check3: false,
    check4: false,
  },
  numeroHabitaciones: null,
  numeroHabitantes: null,
  indiceHac: '',
  animales: '',
  tipo: [],
  comentarios: '',
  trayecto_trabajo: '',
  riesgo_inundacion: '',
  zona_deslaves: '',
};
