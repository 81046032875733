import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button, TextField, Checkbox, IconButton, Link,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { RootState } from 'src/store';
import { removePaciente } from '@utils/commonStore';
import useStyles from '../styles';
import { IIniciarConsultaProps } from './types';

function IniciarConsulta({
  docId,
  setDocId,
  evalData,
  error,
  sinDocId,
  setSinDocId,
  setPasoModal,
  setModalConsultaAbierta,
  setShowNoMedipraxi,
  busquedaPaciente,
  idPaciente,
  idConsulta,
  idMedico,
}: IIniciarConsultaProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { secciones, pais } = useSelector((state: RootState) => state.Me);
  const [verIniciar, setVerIniciar] = useState<boolean>(false);
  removePaciente();
  return (
    <div
      className={`container mx-auto shadow-lg border border-solid border-gray-200 ${
        verIniciar ? 'bg-white' : 'bg-blue-200'
      } rounded-lg`}
    >
      <div className={verIniciar ? 'p-4 rounded-lg relative' : 'p-2 rounded-lg'}>
        {verIniciar ? (
          <>
            <div className="grid grid-cols-3 gap-4 items-center">
              <TextField
                placeholder={t(`ingrese_doc_id_${pais}`)}
                value={docId}
                error={error}
                FormHelperTextProps={{ className: classes.absoluteBottom }}
                helperText={error ? t(`doc_id_incorrecto_${pais}`) : ''}
                onChange={(event) => {
                  evalData(event);
                  // setDocId(event.target.value.toUpperCase());
                }}
                InputProps={{
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '18',
                  },
                }}
                disabled={sinDocId}
                variant="outlined"
                fullWidth
              />
              <div>
                <Checkbox
                  checked={sinDocId}
                  onChange={() => {
                    setDocId('');
                    setSinDocId((prev) => !prev);
                  }}
                  name="sin-doc-id"
                  color="primary"
                />
                <span>{t(`no_tiene_usuario_${pais}`)}</span>
              </div>
              {sinDocId ? (
                <div>
                  <Button
                    onClick={() => {
                      if (idPaciente && idPaciente > 0 && idConsulta && idMedico) {
                        setPasoModal(-2);
                        setModalConsultaAbierta(true);
                      } else {
                        setShowNoMedipraxi((pas) => !pas);
                      }
                    }}
                    color="primary"
                    variant="contained"
                    startIcon={<ErrorIcon />}
                  >
                    {t(`iniciar_consulta_no_doc_id_${pais}`)}
                  </Button>
                </div>
              ) : (
                <div />
              )}
            </div>
            {pais === 'mx' && (
              <div className="flex py-4">
                <div className="flex flex-wrap content-center pr-2">
                  <ErrorIcon color="primary" />
                </div>
                <div className="flex flex-col">
                  <span>
                    <Trans i18nKey="no_conoce_curp">
                      index0
                      <strong title="index1">index1</strong>
                      index2
                    </Trans>
                  </span>
                  <span>
                    <Link href="https://www.gob.mx/curp/" target="_blank" rel="noreferrer">
                      https://www.gob.mx/curp/
                    </Link>
                  </span>
                </div>
              </div>
            )}
            <div className="pt-4">
              <Button
                disabled={sinDocId || error || docId.length === 0}
                onClick={() => {
                  if (idPaciente && idPaciente > 0 && idConsulta && idMedico) {
                    setPasoModal(-3);
                    setModalConsultaAbierta(true);
                  } else {
                    busquedaPaciente();
                  }
                }}
                color="primary"
                variant="contained"
              >
                {t('iniciar_consulta')}
              </Button>
            </div>
            <div className="absolute top-0 right-0">
              <IconButton
                aria-label="cerrar"
                color="primary"
                onClick={() => {
                  setSinDocId(false);
                  setDocId('');
                  setVerIniciar((prev) => !prev);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </>
        ) : (
          <div>
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 66) && (
              <Button
                color="primary"
                size="small"
                onClick={() => setVerIniciar((prev) => !prev)}
                startIcon={<AddIcon />}
              >
                {t('pacientes-iniciar')}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default IniciarConsulta;
