import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, XIcon } from 'lucide-react';
import { validarFecha, validarFechaEsAnterior } from '@common/functions';
import { DateInputProps } from './type';
import { IFechaObj } from '@common/types';

export const DateInput: FC<DateInputProps> = ({
  containerClass = '',
  label: externalLabel,
  isRequired,
  name,
  setValue,
  value,
  validacion,
  helperText,
  disabled = false,
  yearsMore = 0,
}) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const [openDay, setOpenDay] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const label = externalLabel === undefined ? t(name) : externalLabel;
  const days = [...Array(31)].map((x, i) => ({ value: String(i + 1), label: String(i + 1) }));
  const months = [...Array(12)].map((x, i) => ({ value: String(i + 1), label: t(`mes${i + 1}`) }));
  const years = [...Array(101)].map((x, i) => ({
    value: String(currentDate.getFullYear() - i + yearsMore),
    label: String(currentDate.getFullYear() - i + yearsMore),
  }));

  useEffect(() => {
    if (value?.dia && value?.mes && value?.anio) {
      if (
        !validarFecha(value.anio, value.mes, value.dia)
        || (validacion && (
          validacion  === 'esAnterior'
          && !validarFechaEsAnterior(value.anio, value.mes, value.dia)
        ))
      ) {
        setErrorMessage(t('fecha_invalida'));
      }
    }
  }, [value?.anio, value?.mes, value?.dia]);

  const handleDateChange = (type: 'day' | 'month' | 'year', newValue: string) => {
    const defaultDate: IFechaObj = { dia: '', mes: '', anio: '' };
    switch (type) {
      case 'day':
        setValue({ name, value: { ...value || defaultDate, dia: newValue || '' } });
        setOpenDay(false);
        break;
      case 'month':
        setValue({ name, value: { ...value || defaultDate, mes: newValue || '' } });
        setOpenMonth(false);
        break;
      case 'year':
        setValue({ name, value: { ...value || defaultDate, anio: newValue || '' } });
        setOpenYear(false);
        break;
      default:
        break;
    }
  };

  const CustomSelect = ({
    value: val,
    onChange,
    options,
    placeholder,
    isOpen,
    setIsOpen,
    isDisabled,
  }: {
    value: string;
    onChange: (value: string) => void;
    options: string[] | typeof months;
    placeholder: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isDisabled: boolean;
  }) => {
    const [wasFocused, setWasFocused] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (!dropdownRef.current?.contains(event.target as Node)) {
          setIsOpen(false);  
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [wasFocused]);

    useEffect(() => {
      const handleHover = (event: MouseEvent) => {
        setIsHover(!!dropdownRef.current?.contains(event.target as Node));
      };
      document.addEventListener('mouseover', handleHover);
      return () => document.removeEventListener('mouseover', handleHover);
    }, [isHover]);

    const handleToggleDropdown = () => {
      if (!disabled) {
        if (!wasFocused) {
          setWasFocused(true);
        }
        setIsOpen(!isOpen);
      }
    };

    return (
      <div className="w-full relative" ref={dropdownRef}>
        <div className="absolute -top-3 left-1 bg-white px-1">
          <span className="text-gray-500">{placeholder}</span>
        </div>
        <div
          className={`w-full h-[42px] p-2 border rounded-md flex justify-between items-center 
            ${isDisabled
            ? 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed'
            : 'bg-white border-gray-300 cursor-pointer'
            }`}
        >
          <div
            className="w-full"
            onClick={handleToggleDropdown}
          >
            <span
              className={!val?.length  ? 'text-gray-400' : ''}
            >
              {(options as { value: string; label: string }[])
                .find((option) => option.value === val)?.label || placeholder}
            </span>
          </div>
          <div className="flex flex-row">
            {val?.length && isHover && !disabled
              ? <XIcon
                size={18}
                onClick={() => {
                  if (!isDisabled) {
                    onChange('');
                  }
                }}
                className="hover:bg-gray-100 rounded-full"
              /> : null
            }
            <ChevronDown
              size={20}
              className={`transition-transform duration-200 ${
                isOpen ? 'rotate-180' : ''}
              hover:bg-gray-100 rounded-full`}
              onClick={handleToggleDropdown}
            />
          </div>
        </div>

        {isOpen && !isDisabled && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
            {(options as { value: string; label: string }[]).map((option) => (
              <div
                key={Number(option.value)}
                className={`p-2 cursor-pointer hover:bg-gray-100 
                  ${
                    option.value.toString() === val.toString()
                      ? 'bg-blue-50 text-blue-600'
                      : ''
                  }`}
                onClick={() => onChange(option.value.toString() || '')}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`w-full h-auto px-4 ${containerClass}`}>
      <div className="w-full mb-6">
        {label && (
          <label className="block font-medium text-base text-gray-700 mb-4">
            {label}
            {isRequired && <span className="text-red-500 ml-1">*</span>}
          </label>
        )}
        <div className="flex gap-2">
          <div className="w-24">
            <CustomSelect
              value={value?.dia || ''}
              onChange={(newValue) => handleDateChange('day', newValue)}
              options={days}
              placeholder={t('dia')}
              isDisabled={disabled}
              isOpen={openDay}
              setIsOpen={setOpenDay}
            />
          </div>
          <div className="w-44">
            <CustomSelect
              value={value?.mes || ''}
              onChange={(newValue) => handleDateChange('month', newValue)}
              options={months}
              placeholder={t('mes')}
              isDisabled={disabled}
              isOpen={openMonth}
              setIsOpen={setOpenMonth}
            />
          </div>
          <div className="w-28">
            <CustomSelect
              value={value?.anio || ''}
              onChange={(newValue) => handleDateChange('year', newValue)}
              options={years}
              placeholder={t('anio')}
              isDisabled={disabled}
              isOpen={openYear}
              setIsOpen={setOpenYear}
            />
          </div>
        </div>
        <div className="grid grid-cols-3">
          <div/>
          {(errorMessage || helperText) && (
            <h5 className={`mt-1 ml-2 ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
              {errorMessage || helperText}
            </h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateInput;
