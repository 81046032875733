export interface ICafeina {
  consumoCafeina: '' | 'niega' | 'activo';
  tazaDeCafe: string;
  lataDeBebidaEnergetica: string;
  cafeinaAlDia: string;
  alarmaConsumoExcesivo: boolean;
  comentarios: string;
}

export interface ITabaco {
  consumoTabaco: '' | 'niega' | 'activo' | 'ex-fumador';
  edadInicio: string;
  edadTermino: string;
  cigarrillosDia: string;
  ipa: string;
  alarmaTabaquismo: '' | 'leve' | 'moderado' | 'intenso';
  comentarios: string;
}

export interface ITipoAlcohol {
  label: string;
  grUnidad: number;
}

export interface IArrTipoAlcohol {
  arrTabla: string[];
  tipoAlcohol: ITipoAlcohol[];
  cantidadAlcohol: string[];
}

export interface IAlcohol {
  consumoAlcohol: '' | 'niega' | 'activo' | 'ex-bebedor';
  edadInicio: string;
  edadTermino: string;
  frecuencia: string;
  arrTipoAlcohol: IArrTipoAlcohol;
  totalTragos: string;
  totalAproxAlcohol: string;
  alarmaConsumoIntensivo: boolean;
  alarmaPosibleTrastorno: boolean;
  comentarios: string;
  consumeEnMenosDeDosHoras: '' | 'si' | 'no';
}

export interface IArrTipoDrogas {
  arrTabla: string[];
  tipoDroga: string[];
  especifica: string[];
  cantidadDroga: string[];
  edadInicio: string[];
  edadTermino: string[];
  frecuencia: string[];
  estatus: string[];
}

export interface IOtrasDrogas {
  consumoOtrasDrogas: '' | 'si' | 'no';
  arrTipoDrogas: IArrTipoDrogas;
  comentarios: string;
}

export interface IToxicomania {
  loaded: boolean;
  cafeina: ICafeina;
  tabaco: ITabaco;
  alcohol: IAlcohol;
  otrasDrogas: IOtrasDrogas;
}

export const toxicomaniaInitial: IToxicomania = {
  loaded: false,
  cafeina: {
    consumoCafeina: '',
    tazaDeCafe: '',
    lataDeBebidaEnergetica: '',
    cafeinaAlDia: '',
    alarmaConsumoExcesivo: false,
    comentarios: '',
  },
  tabaco: {
    consumoTabaco: '',
    edadInicio: '',
    edadTermino: '',
    cigarrillosDia: '',
    ipa: '',
    alarmaTabaquismo: '',
    comentarios: '',
  },
  alcohol: {
    consumoAlcohol: '',
    edadInicio: '',
    edadTermino: '',
    frecuencia: '',
    arrTipoAlcohol: {
      arrTabla: ['I'],
      tipoAlcohol: [{ label: '', grUnidad: 0 }],
      cantidadAlcohol: [''],
    },
    totalTragos: '',
    totalAproxAlcohol: '',
    alarmaConsumoIntensivo: false,
    alarmaPosibleTrastorno: false,
    comentarios: '',
    consumeEnMenosDeDosHoras: '',
  },
  otrasDrogas: {
    consumoOtrasDrogas: '',
    arrTipoDrogas: {
      arrTabla: ['I'],
      tipoDroga: [''],
      especifica: [''],
      cantidadDroga: [''],
      edadInicio: [''],
      edadTermino: [''],
      frecuencia: [''],
      estatus: ['activo'],
    },
    comentarios: '',
  },
};
