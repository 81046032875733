import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useTable } from 'react-table';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { capitalize } from '@common/functions';
import { IBuilderTablaProps, IBuilderTablaViewProps } from './types';
import useStyles from './styles';

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackCheckbox = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(React.memo(TextField));

const reviewCheck = (objDatos: any, titulo: string, idColumn: string) => {
  let valRet = false;
  const ubicacionArray = objDatos.findIndex((dat: any) => Object.keys(dat)[0] === titulo);
  if (
    ubicacionArray > -1 &&
    objDatos[ubicacionArray][titulo].columnas.find((dato: string) => dato === idColumn)
  ) {
    valRet = true;
  }
  return valRet;
};

const TableView = ({
  columns,
  data,
  varOpciones,
  datos,
  tabla,
  id,
  setVariable,
}: IBuilderTablaViewProps) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps, headerGroups, rows, prepareRow,
  } = useTable({
    columns,
    data,
  });
  const classes = useStyles();
  const { t } = useTranslation();

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()} className={classes.header}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell, inx) => {
                const rowIndex = parseInt(cell.row.id, 10) || 0;
                const tituloCell = cell.row.values.titulo;
                let field = '';
                if (cell.column && cell.column.id === 'titulo') {
                  field = 'titulo';
                } else if (cell.column && cell.column.id === 'comentarios') {
                  field = 'comentarios';
                }
                if (field === 'titulo') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                }
                if (field === 'comentarios') {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                    >
                      <BlackTextField
                        placeholder={t('comentarios')}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={1}
                        InputProps={{
                          className: classes.backgroundWhite,
                          inputProps: { maxLength: 150, autoComplete: 'off' },
                        }}
                        onChange={(e) => {
                          const valGen = [...datos];
                          if (valGen.length > 0) {
                            const locVal = valGen.findIndex(
                              (dat) => Object.keys(dat)[0] === tituloCell,
                            );
                            if (locVal > -1) {
                              valGen[locVal][tituloCell].comentarios = e.target.value as string;
                            } else {
                              valGen.push({
                                [tituloCell]: {
                                  columnas: [],
                                  comentarios: e.target.value as string,
                                },
                              });
                            }
                          } else {
                            valGen.push({
                              [tituloCell]: {
                                columnas: [],
                                comentarios: e.target.value as string,
                              },
                            });
                          }
                          const valVal = [...tabla];
                          const valExistente = valVal.findIndex(
                            (objJson) =>
                              objJson.tituloFila === tituloCell &&
                              objJson.tituloColumna === 'comentarios',
                          );
                          if (valExistente > -1) {
                            valVal[valExistente].comentarios = e.target.value;
                          } else {
                            valVal.push({
                              tituloFila: tituloCell,
                              tituloColumna: 'comentarios',
                              comentarios: e.target.value as string,
                            });
                          }
                          setVariable((preMcpa) => ({
                            ...preMcpa,
                            componentes: preMcpa.componentes.map((preComp) => {
                              if (preComp.id === id) {
                                return {
                                  ...preComp,
                                  constructorComponente: {
                                    ...preComp.constructorComponente,
                                    constructor: {
                                      ...preComp.constructorComponente.constructor,
                                      tabla: {
                                        ...preComp.constructorComponente.constructor.tabla!,
                                        datos: valGen,
                                      },
                                    },
                                    valores: {
                                      ...preComp.constructorComponente.valores,
                                      tabla: valVal,
                                    },
                                  },
                                };
                              }
                              return preComp;
                            }),
                          }));
                        }}
                        value={
                          datos &&
                          datos.findIndex((dat: any) => Object.keys(dat)[0] === tituloCell) > -1
                            ? datos[
                              datos.findIndex((dat: any) => Object.keys(dat)[0] === tituloCell)
                            ][tituloCell].columnas.find((dato: string) => dato === 'comentarios')
                            : ''
                        }
                        name={`txt-${cell.column.id}-${inx}`}
                        style={{ textAlign: 'center', width: '100%' }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    {...cell.getCellProps()}
                    className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                  >
                    {varOpciones ? (
                      <BlackCheckbox
                        style={{ textAlign: 'center', width: '100%' }}
                        onChange={(e) => {
                          const valGen = [...datos];
                          if (valGen.length > 0) {
                            const locVal = valGen.findIndex(
                              (dat) => Object.keys(dat)[0] === tituloCell,
                            );
                            if (locVal > -1 && e.target.checked) {
                              const filDatos = [...valGen[locVal][tituloCell].columnas];
                              if (!filDatos.find((dat) => dat === cell.column.id)) {
                                filDatos.push(cell.column.id);
                              }
                              valGen[locVal][tituloCell].columnas = filDatos;
                            } else if (locVal > -1 && !e.target.checked) {
                              const filDatos = [...valGen[locVal][tituloCell].columnas];
                              filDatos.splice(
                                filDatos.find((dat) => dat === cell.column.id),
                                1,
                              );
                              valGen[locVal][tituloCell].columnas = filDatos;
                            } else {
                              valGen.push({
                                [tituloCell]: {
                                  columnas: [cell.column.id],
                                  comentarios: '',
                                },
                              });
                            }
                          } else {
                            valGen.push({
                              [tituloCell]: {
                                columnas: [cell.column.id],
                                comentarios: '',
                              },
                            });
                          }
                          const valVal = [...tabla];
                          const valExistente = valVal.findIndex(
                            (objJson) =>
                              objJson.tituloFila === tituloCell &&
                              objJson.tituloColumna === cell.column.Header!,
                          );
                          if (valExistente > -1 && !e.target.checked) {
                            valVal.splice(valExistente, 1);
                          } else {
                            valVal.push({
                              tituloFila: tituloCell,
                              tituloColumna: cell.column.Header!,
                              comentarios: '',
                            });
                          }
                          setVariable((preMcpa) => ({
                            ...preMcpa,
                            componentes: preMcpa.componentes.map((preComp) => {
                              if (preComp.id === id) {
                                return {
                                  ...preComp,
                                  constructorComponente: {
                                    ...preComp.constructorComponente,
                                    constructor: {
                                      ...preComp.constructorComponente.constructor,
                                      tabla: {
                                        ...preComp.constructorComponente.constructor.tabla!,
                                        datos: valGen,
                                      },
                                    },
                                    valores: {
                                      ...preComp.constructorComponente.valores,
                                      tabla: valVal,
                                    },
                                  },
                                };
                              }
                              return preComp;
                            }),
                          }));
                        }}
                        checked={reviewCheck(datos, tituloCell, cell.column.id)}
                      />
                    ) : (
                      <BlackRadio
                        style={{ textAlign: 'center', width: '100%' }}
                        onChange={(e) => {
                          const valGen = [...datos];
                          if (valGen.length > 0) {
                            const locVal = valGen.findIndex(
                              (dat) => Object.keys(dat)[0] === tituloCell,
                            );
                            if (locVal > -1 && e.target.checked) {
                              valGen[locVal][tituloCell].columnas = [cell.column.id];
                            } else {
                              valGen.push({
                                [tituloCell]: {
                                  columnas: [cell.column.id],
                                  comentarios: '',
                                },
                              });
                            }
                          } else {
                            valGen.push({
                              [tituloCell]: {
                                columnas: [cell.column.id],
                                comentarios: '',
                              },
                            });
                          }
                          const valVal = [...tabla];
                          const valExistente = valVal.findIndex(
                            (objJson) => objJson.tituloFila === tituloCell,
                          );
                          if (valExistente > -1) {
                            valVal.splice(valExistente, 1);
                            valVal.push({
                              tituloFila: tituloCell,
                              tituloColumna: cell.column.Header!,
                              comentarios: '',
                            });
                          } else {
                            valVal.push({
                              tituloFila: tituloCell,
                              tituloColumna: cell.column.Header!,
                              comentarios: '',
                            });
                          }
                          setVariable((preMcpa) => ({
                            ...preMcpa,
                            componentes: preMcpa.componentes.map((preComp) => {
                              if (preComp.id === id) {
                                return {
                                  ...preComp,
                                  constructorComponente: {
                                    ...preComp.constructorComponente,
                                    constructor: {
                                      ...preComp.constructorComponente.constructor,
                                      tabla: {
                                        ...preComp.constructorComponente.constructor.tabla!,
                                        datos: valGen,
                                      },
                                    },
                                    valores: {
                                      ...preComp.constructorComponente.valores,
                                      tabla: valVal,
                                    },
                                  },
                                };
                              }
                              return preComp;
                            }),
                          }));
                        }}
                        checked={reviewCheck(datos, tituloCell, cell.column.id)}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
};

const Tabla = ({ componenteTabla, setMcpa, id }: IBuilderTablaProps) => {
  const { titulo } = componenteTabla.datosComponente;
  const { descripcion } = componenteTabla.datosComponente;
  const { columnas } = componenteTabla.constructor.tabla!;
  const { renglones } = componenteTabla.constructor.tabla!;
  const { datos } = componenteTabla.constructor.tabla!;
  const { tabla } = componenteTabla.valores;
  const varOpciones = componenteTabla.constructor.tabla!.unaVarOpc;
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="flex flex-wrap mb-2">
        <h3 className="mr-4 mt-0 mb-0 text-gray-600 font-medium">{capitalize(titulo)}</h3>
        <div className="text-gray-500">{descripcion ? `*${descripcion}` : ''}</div>
      </div>
      <CssBaseline />
      <TableView
        columns={columnas}
        data={renglones}
        varOpciones={varOpciones}
        datos={datos}
        tabla={tabla}
        id={id}
        setVariable={setMcpa}
      />
    </div>
  );
};

export default React.memo(Tabla);
