import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { capitalize } from '@common/functions';
import { IViewParrafoProps } from './types';

function Parrafo({ componenteParrafo }: IViewParrafoProps) {
  const { t } = useTranslation();
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-1">
      <div className="flex flex-wrap items-end mb-2">
        <h3 className="mr-4 mt-0 mb-0 text-gray-600 font-medium">
          {capitalize(componenteParrafo.datosComponente.titulo)}
        </h3>
        <div className="text-gray-500">
          {componenteParrafo.datosComponente.descripcion
            ? `* ${componenteParrafo.datosComponente.descripcion}`
            : ''}
        </div>
      </div>
      <TextField
        variant="outlined"
        rows={5}
        multiline
        fullWidth
        disabled
        placeholder={t('parrafo')}
        inputProps={{ autoComplete: 'off', maxLength: 1000 }}
        value={componenteParrafo.constructor.comentarios}
      />
    </div>
  );
}

export default Parrafo;
