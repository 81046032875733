import { ICatalogosEstandar, IFechaObj } from '@common/types';

export interface ISegurosPlanes {
  loaded: boolean;
  noTieneAseguradora: boolean;
  aseguradora: ICatalogosEstandar;
  cualAseguradora: string;
  numPoliza: string;
  vigencia: IFechaObj;
  sumaAsegurada: string;
  comentarios: string;
}

export const segurosPlanesInitial: ISegurosPlanes = {
  loaded: false,
  noTieneAseguradora: false,
  aseguradora: { id: 0, label: '' },
  cualAseguradora: '',
  numPoliza: '',
  vigencia: { dia: '', mes: '', anio: '' },
  sumaAsegurada: '',
  comentarios: '',
};
