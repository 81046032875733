import { IRenderMCPAProps, IRenderProps } from './types';
import RespuestaBreve from '../../../../Builder/Scaffold/RespuestaBreve';
import Parrafo from '../../../../Builder/Scaffold/Parrafo';
import OpcionMultiple from '../../../../Builder/Scaffold/OpcionMultiple';
import CasillasVerificacion from '../../../../Builder/Scaffold/CasillasVerificacion';
import ListaDesplegable from '../../../../Builder/Scaffold/ListaDesplegable';
import EscalaLineal from '../../../../Builder/Scaffold/EscalaLineal';
import Tabla from '../../../../Builder/Scaffold/Tabla';
import Fecha from '../../../../Builder/Scaffold/Fecha';
import Hora from '../../../../Builder/Scaffold/Hora';

function Render({ componente, formato, setMcpa }: IRenderProps) {
  switch (formato) {
    case 1:
      return (
        <RespuestaBreve
          componenteRespuesta={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 2:
      return (
        <Parrafo
          componenteParrafo={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 3:
      return (
        <OpcionMultiple
          componenteCasillasOpciones={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 4:
      return (
        <CasillasVerificacion
          componenteCasillasOpciones={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 5:
      return (
        <ListaDesplegable
          componenteListaDesplegable={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 6:
      return (
        <EscalaLineal
          componenteEscalaLineal={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 7:
      return (
        <Tabla
          componenteTabla={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 8:
      return (
        <Fecha
          componenteFecha={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    case 9:
      return (
        <Hora
          componenteHora={componente.constructorComponente}
          key={componente.titulo}
          id={componente.id}
          setMcpa={setMcpa}
        />
      );
    default:
      return null;
  }
}

function RenderMCPA({ componentes, setMcpa }: IRenderMCPAProps) {
  return (
    <>
      {componentes.map((comp) => (
        <Render key={comp.titulo} componente={comp} formato={comp.formato} setMcpa={setMcpa} />
      ))}
    </>
  );
}

export default RenderMCPA;
