import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RawDraftContentState } from 'draft-js';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
// dejado a proposito es el boton de carga de documentos
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { constDia, constMes, constAnio } from '@constants/fechas';
import useStyles from '@common/styles';
import { ICatalogosEstandar, IFechaField } from '@common/types';
import { valFechaToSave, validarFecha, validarFechaEsAnterior } from '@common/functions';
import ComentariosComponente from '@components/Comentarios';
import { hospitalizacionesActualizarHospitalizacion } from '@utils/editInfo';
import { hospitalizacionesGuardarHospitalizacion } from '@utils/sendInfo';
import { RootState } from 'src/store';
import { setRequest } from '@actions/request/types';
import { setHospitalizaciones } from '@actions/paciente/actions';
import { IFormHospitalizacionProps } from './types';

function FormHospitalizacion({
  idPaciente,
  catDuracion,
  idBD,
  setIDBD,
  diagnosticoRelacionadoALaHospitalizacion,
  setDiagnosticoRelacionadoALaHospitalizacion,
  motivoHospitalizacion,
  setMotivoHospitalizacion,
  duracion,
  setDuracion,
  fecha,
  setFecha,
  comentarios,
  setComentarios,
}: IFormHospitalizacionProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { catDiagnostico, hospitalizaciones } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const [keyComentarios, setKeyComentarios] = useState<number>(1);
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const cambiarKeyComentarios = () => {
    setKeyComentarios(Math.floor(Math.random() * 1000) + 1);
  };

  const limpiarFormulario = () => {
    setDiagnosticoRelacionadoALaHospitalizacion({ id: 0, label: '' });
    setMotivoHospitalizacion('');
    setDuracion({ id: 0, label: '' });
    setFecha({ dia: '', mes: '', anio: '' });
    setComentarios({
      entityMap: {},
      blocks: [],
    });
    cambiarKeyComentarios();
  };

  const handleChangeDiagnosticoRelacionadoALaHospitalizacion = (
    event: any,
    newValue: ICatalogosEstandar | null,
  ) => {
    if (newValue) {
      setDiagnosticoRelacionadoALaHospitalizacion(newValue || { id: 0, label: '' });
    }
  };

  const handleChangeMotivoHospitalizacion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setMotivoHospitalizacion(val);
  };

  const handleChangeDuracion = (event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue) {
      setDuracion(newValue || { id: 0, label: '' });
    }
  };

  const handleChangeDia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setFecha({ ...fecha, dia: val });
  };

  const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setFecha({ ...fecha, mes: val });
  };

  const handleChangeAnio = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setFecha({ ...fecha, anio: val });
  };

  const getObjHospitalizacion = (comentario: RawDraftContentState) => ({
    idPaciente,
    idBD,
    diagnosticoRelacionadoALaHospitalizacion,
    motivoHospitalizacion,
    duracion,
    fecha: valFechaToSave(fecha),
    comentarios: comentario,
    idMedico,
    consulta: idConsulta,
    idConsultorio,
  });

  const handleClickGuardarHospitalizacion = () => {
    let comentario = {
      entityMap: {},
      blocks: [],
    } as RawDraftContentState;
    if (
      comentarios.blocks.length &&
      !comentarios.blocks.every((row) => row.text === '' || /^\s*$/.test(row.text))
    ) {
      comentario = comentarios;
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: hospitalizacionesGuardarHospitalizacion(getObjHospitalizacion(comentario)),
        successFunction: (result: any) => {
          setHospitalizaciones(
            hospitalizaciones.concat({
              idBD: result.datos,
              diagnosticoRelacionadoALaHospitalizacion,
              motivoHospitalizacion,
              duracion,
              fecha,
              comentarios: comentario,
            }),
          );
          limpiarFormulario();
          setIDBD(-1);
        },
      }),
    );
  };

  const handleClickActualizarHospitalizacion = () => {
    let comentario = {
      entityMap: {},
      blocks: [],
    } as RawDraftContentState;
    if (
      comentarios.blocks.length &&
      !comentarios.blocks.every((row) => row.text === '' || /^\s*$/.test(row.text))
    ) {
      comentario = comentarios;
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: hospitalizacionesActualizarHospitalizacion(
          getObjHospitalizacion(comentario),
        ),
        successFunction: () => {
          setHospitalizaciones(
            hospitalizaciones.map((hosp) => {
              if (hosp.idBD === idBD) {
                return {
                  idBD: hosp.idBD,
                  diagnosticoRelacionadoALaHospitalizacion,
                  motivoHospitalizacion,
                  duracion,
                  fecha: valFechaToSave(fecha),
                  comentarios: comentario,
                };
              }
              return hosp;
            }),
          );
          limpiarFormulario();
          setIDBD(-1);
        },
      }),
    );
  };

  useEffect(() => {
    if (fecha.dia !== '' && fecha.mes !== '' && fecha.anio !== '') {
      if (
        !validarFecha(fecha.anio, fecha.mes, fecha.dia) ||
        !validarFechaEsAnterior(fecha.anio, fecha.mes, fecha.dia)
      ) {
        setFechaField({
          error: true,
          helperText: t('fecha_invalida'),
        });
      } else {
        setFechaField({
          error: false,
          helperText: '',
        });
      }
    }
  }, [fecha]);

  const disableAgregarHospitalizacion = () =>
    motivoHospitalizacion === '' || motivoHospitalizacion.length < 5 || fechaField.error;

  return (
    <div className="border border-solid border-gray-300 rounded p-4">
      <div className="mb-4">
        <div className="grid grid-cols-2">
          <h3 className="mt-0 mb-3 text-gray-600 font-medium">
            {`${t('antecedentes-pp-hos-diagnostico')}:`}
          </h3>
          <div className="flex justify-end">
            <h4 className="mt-0 mb-2 font-normal text-red-600">
              <Trans i18nKey="antecedentes-pp-hos-atxt">
                index0
                <strong title="index1">index1</strong>
              </Trans>
            </h4>
          </div>
        </div>
        <Autocomplete
          options={catDiagnostico}
          getOptionLabel={(option) => option.label}
          noOptionsText={t('sin_resultados')}
          value={diagnosticoRelacionadoALaHospitalizacion}
          onChange={(e, nV) => handleChangeDiagnosticoRelacionadoALaHospitalizacion(e, nV)}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
      </div>
      <div className="mb-4">
        <h3 className="mt-0 mb-3 text-gray-600 font-medium">
          {`${t('antecedentes-pp-hos-motivo')}*`}
        </h3>
        <TextField
          variant="outlined"
          value={motivoHospitalizacion}
          placeholder={t('especifica')}
          fullWidth
          InputProps={{
            inputProps: {
              autoComplete: 'off',
              'aria-autocomplete': 'none',
              maxLength: 150,
            },
          }}
          onChange={handleChangeMotivoHospitalizacion}
        />
      </div>
      <div className="grid grid-cols-12 mb-4 gab-4">
        <div className="col-span-2">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">
            {t('antecedentes-pp-hos-duracion')}
          </h3>
          <Autocomplete
            options={catDuracion}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={duracion}
            onChange={(e, nV) => handleChangeDuracion(e, nV)}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
        </div>
        <div />
        <div className="col-span-4">
          <h3 className="mt-0 mb-2 text-gray-600 font-medium">{t('antecedentes-pp-hos-fecha')}</h3>
          <div className="w-full grid grid-cols-3 gap-2">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-dia">{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={fecha.dia}
                onChange={handleChangeDia}
                label={t('dia')}
                error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle.toString()} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-mes">{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={fecha.mes}
                onChange={handleChangeMes}
                label={t('mes')}
                error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {mesSingle.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                className={classes.absoluteBottom}
                style={{ textAlign: 'center', margin: '0', width: '100%' }}
              >
                {fechaField.helperText}
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label-año">{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={fecha.anio}
                onChange={handleChangeAnio}
                label={t('anio')}
                error={fechaField.error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constAnio().map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="my-4">
        <ComentariosComponente
          comentarios={comentarios}
          setComentarios={(data: RawDraftContentState) => setComentarios(data)}
          rows={4}
          maxLength={500}
          placeholder={t('comentarios')}
          lang="es"
          disabled={false}
          key={`${keyComentarios}-${idBD}`}
        />
      </div>
      <div className="flex justify-end mt-2 mb-3">
        {/*
          // dejado a proposito es el boton de carga de documentos
          <Button
            color="primary"
            size="small"
            className={classes.noTextTranform}
            startIcon={<CloudUploadIcon />}
          >
            {t('cargar_documento')}
          </Button> */}
        {idBD > 0 ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SyncIcon />}
            onClick={handleClickActualizarHospitalizacion}
          >
            {t('actualizar')}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleClickGuardarHospitalizacion}
            disabled={disableAgregarHospitalizacion()}
          >
            {t('guardar')}
          </Button>
        )}
      </div>
      <div className="flex justify-end">
        <h4 className="mt-0 mb-2 font-normal">
          <Trans i18nKey="antecedentes-pp-hos-gtxt">
            index0
            <strong title="index1">index1</strong>
            index2
          </Trans>
        </h4>
      </div>
    </div>
  );
}

export default FormHospitalizacion;
