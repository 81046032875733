export interface IDiscapacidadSimple {
  tipoId: string;
  gradoId: string;
  origenId: string;
}

export const discapacidadSimpleInitial = {
  tipoId: '',
  gradoId: '',
  origenId: '',
};

export interface IDiscapacidad {
  tipoId: string;
  tipo: string;
  gradoId: string;
  grado: string;
  origenId: string;
  origen: string;
}

export const discapacidadInitial = {
  tipoId: '',
  tipo: '',
  gradoId: '',
  grado: '',
  origenId: '',
  origen: '',
};

export interface IDiscapacidades {
  loaded: boolean;
  arrayDiscapacidades: IDiscapacidad[];
  sinDiscapacidad: boolean;
  certificado: '' | 'si' | 'no';
  comentarios: string;
}

export const discapacidadesInitial: IDiscapacidades = {
  loaded: false,
  arrayDiscapacidades: [discapacidadInitial],
  sinDiscapacidad: false,
  certificado: '',
  comentarios: '',
};
