import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField, IconButton, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ICatLaboratorio } from '@common/types';
import DeleteDialog from '@components/DeleteDialog';
import { setLaboratorioSolicitud } from '@actions/paciente/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { IProcedimientosProps } from './types';
import { ILaboratorioSolicitado, ILaboratorioSolicitud } from '../types';

function LaboratoriosComponente({
  setSaveClick,
  laboratorio,
  selArray,
  catLaboratorios,
  index,
}: IProcedimientosProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { laboratorioSolicitud } = useSelector(
    (state: RootState) => state.Paciente.expediente.plan,
  );
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  const eliminarProcedimiento = () => {
    dispatch(
      setLaboratorioSolicitud(
        laboratorioSolicitud.map((sD: ILaboratorioSolicitud, indx: number) => {
          if (indx === selArray) {
            let newLA = sD.laboratoriosArray.filter(
              (_la: ILaboratorioSolicitado, indi: number) => indi !== index,
            );
            if (newLA.length === 0) {
              newLA = [
                {
                  idBd: -1,
                  selector: 'catalogo',
                  catLaboratorios: {
                    id: 0,
                    idCat: 0,
                    categoria: '',
                    label: '',
                  },
                  txtLibre: '',
                  actualizado: true,
                },
              ];
            }
            return {
              ...sD,
              laboratoriosArray: newLA,
            };
          }
          return sD;
        }),
      ),
    );
    setSaveClick(true);
  };
  const handleChangeProcedimiento = (event: any, newValue: ICatLaboratorio | null) => {
    dispatch(
      setLaboratorioSolicitud(
        laboratorioSolicitud.map((sD: ILaboratorioSolicitud, indx: number) => {
          if (indx === selArray) {
            return {
              ...sD,
              laboratoriosArray: sD.laboratoriosArray.map(
                (lab: ILaboratorioSolicitado, jind: number) => {
                  if (jind === index) {
                    return {
                      ...lab,
                      catLaboratorios: {
                        id: newValue?.id || 0,
                        idCat: newValue?.idCat || 0,
                        categoria: newValue?.categoria || '',
                        label: newValue?.label || '',
                      },
                      actualizado: true,
                    };
                  }
                  return lab;
                },
              ),
            };
          }
          return sD;
        }),
      ),
    );
    setSaveClick(true);
  };
  const handleChangeTextoProcedimiento = (event: React.ChangeEvent<HTMLInputElement>) => {
    const texto = event.target.value;
    dispatch(
      setLaboratorioSolicitud(
        laboratorioSolicitud.map((sD: ILaboratorioSolicitud, indx: number) => {
          if (indx === selArray) {
            return {
              ...sD,
              laboratoriosArray: sD.laboratoriosArray.map(
                (lab: ILaboratorioSolicitado, jind: number) => {
                  if (jind === index) {
                    return {
                      ...lab,
                      txtLibre: texto,
                      actualizado: true,
                    };
                  }
                  return lab;
                },
              ),
            };
          }
          return sD;
        }),
      ),
    );
    setSaveClick(true);
  };
  const handleChangeNombreLaboratorio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    dispatch(
      setLaboratorioSolicitud(
        laboratorioSolicitud.map((sD: ILaboratorioSolicitud, indx: number) => {
          if (indx === selArray) {
            return {
              ...sD,
              laboratoriosArray: sD.laboratoriosArray.map(
                (lab: ILaboratorioSolicitado, jind: number) => {
                  if (jind === index) {
                    return {
                      ...lab,
                      catLaboratorios: {
                        id: 0,
                        idCat: 0,
                        categoria: '',
                        label: '',
                      },
                      txtLibre: '',
                      actualizado: true,
                      selector: newVal,
                    };
                  }
                  return lab;
                },
              ),
            };
          }
          return sD;
        }),
      ),
    );
    setSaveClick(true);
  };
  return (
    <div className="border-solid border border-gray-400 rounded p-4 flex" key={index}>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('d-p-plan-lab-del')}
        callBackAceptar={eliminarProcedimiento}
        callBackClose={handleAlertClose}
      />
      <div className="w-full">
        <div className="pb-2 flex items-center">
          <h3 className="m-0 mr-2 text-gray-600">{`${t('d-p-plan-lab-nom')}*`}</h3>
          <RadioGroup
            aria-label="realiza"
            name="realiza"
            value={laboratorio.selector}
            onChange={handleChangeNombreLaboratorio}
            row
          >
            <FormControlLabel
              value="catalogo"
              control={<Radio color="primary" />}
              label={t('d-p-plan-lab-cat')}
            />
            <FormControlLabel
              value="txtLibre"
              control={<Radio color="primary" />}
              label={t('texto-libre')}
            />
          </RadioGroup>
        </div>
        <div>
          {laboratorio.selector === 'catalogo' ? (
            <Autocomplete
              options={catLaboratorios}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('sin_resultados')}
              value={laboratorio.catLaboratorios}
              onChange={handleChangeProcedimiento}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          ) : (
            <TextField
              id="especifica"
              placeholder={t('especifica')}
              value={laboratorio.txtLibre}
              onChange={handleChangeTextoProcedimiento}
              variant="outlined"
              fullWidth
            />
          )}
        </div>
        <h4 className="m-0 mt-2 font-medium text-gray-300">{`*${t('campos_obligatorios')}`}</h4>
      </div>
      <div className="justify-end self-center">
        <IconButton aria-label="delete" onClick={() => setDeleteAlert(true)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default LaboratoriosComponente;
