import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import {
  RadioGroup, FormControlLabel, Radio, Button, TextField,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { setEnfNegadas, setEnfReferidas, setGrupoEnfNegada } from '@actions/paciente/actions';
import { RootState } from 'src/store';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { ICatalogosCie10, IFechaField } from '@common/types';
import {
  edadCalculada,
  esMismoDiaFechaISO,
  getFechaActualISO,
  hasKey,
  iEdadMenorOIgual,
  verificarIEdadValida,
} from '@common/functions';
import ComentariosComponente from '@components/Comentarios';
import { getCatCie10Query } from '@utils/getCatalogos';
import { validacionSoloNumeros } from '@constants/validaciones';
import { numeroEnfermedadGrupo } from '@common/enfermedades';
import {
  enfermedadesGuardarGrupoEnfermedadesNegadas,
  enfermedadesGuardarReferida,
} from '@utils/sendInfo';
import { enfermedadesEliminarNegada } from '@utils/deleteInfo';
import { enfermedadesEditReferida } from '@utils/editInfo';
import { setRequest } from '@actions/request/types';
import AlertaEnfermedad from '../../../../../EnfermedadesDialogs/AlertaEnfermedad';
import { IEnfermedadesFormularioProps } from './types';
import { IFechaComentarios, enfermedadesFormInitial } from '../types';

function EnfermedadesFormulario({ enfermedad, setEnfermedad }: IEnfermedadesFormularioProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idConsulta: consulta } = useSelector((state: RootState) => state.Consulta);
  const {
    idPaciente,
    sexo: perfilSexo,
    edad: { edad, periodo },
    dia,
    mes,
    anio,
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { enfReferidas, enfNegadas, grupoEnfNegadas } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const edadPaciente = edadCalculada(anio, mes, dia);

  const [catCIE10, setCatCIE10] = useState<ICatalogosCie10[]>([]);
  const [openAlertEnfermedad, setOpenAlertEnfermedad] = useState(false);
  const [indiceRepetido, setIndiceRepetido] = useState(-1);
  const [openCIE10, setOpenCIE10] = useState<boolean>(false);
  const [loadingCIE10, setLoadingCIE10] = useState<boolean>(false);
  const [inputCIE10, setInputCIE10] = useState<string>('');
  const [edadField, setEdadField] = useState<IFechaField>({ error: false, helperText: '' });

  const handleChangeCIE = (event: any, newValue: ICatalogosCie10 | null, reason: string) => {
    setEnfermedad({
      ...enfermedad,
      diagnosticoCie10:
        reason !== 'clear' && newValue ? newValue : { id: '', label: '', grupoEnfermedades: 0 },
    });
    setOpenCIE10(reason === 'clear');
  };

  const handleChangeSeleccionDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnfermedad({
      ...enfermedad,
      seleccionDiagnostico: event.target.value as 'cie10' | 'medico',
    });
  };

  const handleChangeDiagnosticoMedico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnfermedad({
      ...enfermedad,
      diagnosticoMedico: event.target.value as string,
    });
  };

  const handleChangeTipoDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnfermedad({
      ...enfermedad,
      tipoDiagnostico: event.target.value as 'cronico' | 'agudo' | '',
    });
  };

  const handleChangeEstatusDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnfermedad({
      ...enfermedad,
      estatusDelDiagnostico: event.target.value as 'activo' | 'resuelto' | '',
    });
  };

  const handleChangeEdad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    const num = parseInt(val, 10) || 0;
    if (val === '' || (validacionSoloNumeros(val) && num < 121)) {
      setEnfermedad({
        ...enfermedad,
        edadAlDiagnostico: {
          ...enfermedad.edadAlDiagnostico,
          edad: num,
        },
      });
      if (enfermedad.edadAlDiagnostico.claveEdad && num > 0) {
        if (!verificarIEdadValida({ ...enfermedad.edadAlDiagnostico, edad: num })) {
          setEdadField({
            error: true,
            helperText: 'formato_i_edad_invalido',
          });
          return;
        }
        if (!iEdadMenorOIgual({ ...enfermedad.edadAlDiagnostico, edad: num }, edadPaciente)) {
          setEdadField({
            error: true,
            helperText: 'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
          });
          return;
        }
      }
    }
    if (num === 0) {
      setEdadField({
        error: true,
        helperText: 'debes_introducir_un_valor_y_seleccionar_una_opcion',
      });
    } else {
      setEdadField({
        error: false,
        helperText: '',
      });
    }
  };

  const handleBlurEdad = () => {
    if (enfermedad.edadAlDiagnostico.edad && enfermedad.edadAlDiagnostico.periodo === '') {
      setEdadField({
        error: true,
        helperText: 'debes_introducir_un_valor_y_seleccionar_una_opcion',
      });
    } else {
      setEdadField({
        error: false,
        helperText: '',
      });
    }
  };

  const handleChangePeriodoEdad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as '' | 'anios' | 'meses' | 'dias';
    let claveEdad = 0;
    switch (val) {
      case 'anios':
        claveEdad = 5;
        break;
      case 'meses':
        claveEdad = 4;
        break;
      case 'dias':
        claveEdad = 3;
        break;
      default:
        claveEdad = 0;
        break;
    }
    setEnfermedad({
      ...enfermedad,
      edadAlDiagnostico: {
        ...enfermedad.edadAlDiagnostico,
        periodo: val,
        claveEdad,
      },
    });
    if (enfermedad.edadAlDiagnostico.edad) {
      if (!verificarIEdadValida({ ...enfermedad.edadAlDiagnostico, periodo: val, claveEdad })) {
        setEnfermedad({
          ...enfermedad,
          edadAlDiagnostico: {
            ...enfermedad.edadAlDiagnostico,
            periodo: '',
            claveEdad: 0,
          },
        });
        setEdadField({
          error: true,
          helperText: 'formato_i_edad_invalido',
        });
        return;
      }
    }
    if (enfermedad.edadAlDiagnostico.edad && val) {
      if (
        !iEdadMenorOIgual(
          { ...enfermedad.edadAlDiagnostico, periodo: val, claveEdad },
          edadPaciente,
        )
      ) {
        setEdadField({
          error: true,
          helperText: 'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
        });
        return;
      }
      setEdadField({
        error: false,
        helperText: '',
      });
      return;
    }
    setEdadField({
      error: true,
      helperText: 'debes_introducir_un_valor_y_seleccionar_una_opcion',
    });
  };

  const setComentarios = (data: RawDraftContentState) => {
    setEnfermedad({
      ...enfermedad,
      comentario: data,
    });
  };

  const handleClickAgregarDiagnostico = () => {
    let newComs = [] as IFechaComentarios[];
    if (
      enfermedad.comentario.blocks.length &&
      !enfermedad.comentario.blocks.every((row: any) => row.text === '' || /^\s*$/.test(row.text))
    ) {
      newComs = [{ fecha: getFechaActualISO(), comentario: enfermedad.comentario }];
    }
    // creamos el objeto de diagnostico
    const objDiagnostico = {
      idBD: enfermedad.idBD,
      seleccionDiagnostico: enfermedad.seleccionDiagnostico,
      diagnosticoCie10: enfermedad.diagnosticoCie10,
      diagnosticoMedico: enfermedad.diagnosticoMedico,
      tipoDiagnostico: enfermedad.tipoDiagnostico,
      certezaDelDiagnostico: enfermedad.certezaDelDiagnostico,
      estatusDelDiagnostico: enfermedad.estatusDelDiagnostico,
      edadAlDiagnostico: enfermedad.edadAlDiagnostico,
      fechaRegistroDiagnostico: enfermedad.fechaRegistroDiagnostico,
      comentarios: newComs,
      idConsulta: consulta,
    };
    const objEnviar = {
      idMedico,
      idPaciente,
      idConsultorio,
      consulta,
      enfermedadReferida: objDiagnostico,
    };
    const LGEN: any = { ...grupoEnfNegadas };
    // si el diagnostico es cie 10 entonces tenemos que hacer varias verificaciones
    if (enfermedad.seleccionDiagnostico === 'cie10') {
      // tenemos que verificar que esa misma enfermedad no esté activa
      // solamente si esta es referida
      // si esta activa tenemos que mostrar el modal con las opciones de que hacer con ella
      if (objDiagnostico.estatusDelDiagnostico === 'activo') {
        const found = enfReferidas.findIndex(
          (enf) =>
            enf.diagnosticoCie10.id === objDiagnostico.diagnosticoCie10.id &&
            enf.estatusDelDiagnostico === 'activo',
        );
        if (found >= 0) {
          setIndiceRepetido(found);
          setOpenAlertEnfermedad(true);
          return;
        }
      }
      // tenemos que obtener el catalog_key padre de la enfermedad introducida
      // para buscarla en la lista de enfermedades negadas, y si resulta que existe
      // en la lista de enfermedades negadas, procederemos a eliminarla
      const idPadre = enfermedad.diagnosticoCie10.id.substr(0, 3);
      const findIndxEN = enfNegadas.findIndex((len) => len.diagnosticoCie10.id === idPadre);
      if (findIndxEN >= 0) {
        const idABorrar = enfNegadas[findIndxEN].idBD;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: enfermedadesEliminarNegada({
              idMedico,
              idPaciente,
              idConsultorio,
              idBD: idABorrar,
              consulta,
            }),
            successFunction: () => {
              dispatch(
                setEnfNegadas(enfNegadas.filter((enfN) => enfN.diagnosticoCie10.id !== idPadre)),
              );
            },
          }),
        );
      }
      // ahora buscamos el nombre del grupo, ya que si esta referida la enfermedad
      // eso significa que no podemos negarla del grupo, ya que existe
      // asi que buscamos su grupo y su selected y disabled
      const grupoEnf = numeroEnfermedadGrupo[enfermedad.diagnosticoCie10.grupoEnfermedades];
      const keySelected = `${grupoEnf}Selected`;
      const keyDisabled = `${grupoEnf}Disabled`;
      // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
      // y tenemos que deshabilitarlo
      if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
        LGEN[keySelected] = false;
        LGEN[keyDisabled] = true;
      }
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesGuardarReferida(objEnviar),
        successFunction: (result: any) => {
          dispatch(
            setEnfReferidas([{ ...objDiagnostico, idBD: result.data.idBD }, ...enfReferidas]),
          );
          setEnfermedad(enfermedadesFormInitial);
          // al darnos un resultado positivo mandamos a guardar el grupo de enfNegadas
          dispatch(setLoading(true));
          enfermedadesGuardarGrupoEnfermedadesNegadas({
            idMedico,
            idPaciente,
            idConsultorio,
            consulta,
            listaGrupoEnfermedadesNegadas: LGEN,
          })
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'success',
                    mensaje: t('message-success'),
                  }),
                );
              } else {
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'error',
                    mensaje: `${t('message-error-warning')}: ${result2.msg}`,
                  }),
                );
              }
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-warning')}: ${err}`,
                }),
              );
              dispatch(setLoading(false));
            });
          // cambiarKeyComentarios();
        },
      }),
    );
  };

  const verificarDisableAgregarDiagnostico = () => {
    let diagnosticoBool;
    switch (enfermedad.seleccionDiagnostico) {
      case 'cie10':
        diagnosticoBool = enfermedad.diagnosticoCie10.id === '0';
        break;
      case 'medico':
        diagnosticoBool = !enfermedad.diagnosticoMedico;
        break;
      default:
        diagnosticoBool = true;
        break;
    }
    return (
      diagnosticoBool
      || !enfermedad.estatusDelDiagnostico
      || edadField.error
    );
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertEnfermedad(false);
  };

  const handleClickActualizarDiagnostico = () => {
    // creamos nuestra variable de comentarios
    let comentarios = [];
    // encotramos el indice del diagnostico al que queremos actualizar
    const findIndex = enfReferidas.findIndex((e) => e.idBD === enfermedad.idBD);
    // si los comentarios tienen informacion agregamos otro comentario al diagnostico
    if (
      enfermedad.comentario.blocks.length &&
      !enfermedad.comentario.blocks.every((row: any) => row.text === '' || /^\s*$/.test(row.text))
    ) {
      // si existen comentarios previos que coincidan con la misma fecha, se reemplazara
      if (
        enfReferidas[findIndex].comentarios.length > 0 &&
        // eslint-disable-next-line max-len
        esMismoDiaFechaISO(enfReferidas[findIndex].comentarios[0].fecha, getFechaActualISO())
      ) {
        comentarios = [
          { fecha: getFechaActualISO(), comentario: enfermedad.comentario },
          ...enfReferidas[findIndex].comentarios.slice(1),
        ];
      } else {
        comentarios = [
          { fecha: getFechaActualISO(), comentario: enfermedad.comentario },
          ...enfReferidas[findIndex].comentarios,
        ];
      }
    } else {
      // si los comentarios no tienen informacion los dejamos como esta
      comentarios = [...enfReferidas[findIndex].comentarios];
    }
    const objDiagnostico = {
      idBD: enfermedad.idBD,
      seleccionDiagnostico: enfermedad.seleccionDiagnostico,
      diagnosticoCie10: enfermedad.diagnosticoCie10.id
        ? enfermedad.diagnosticoCie10
        : { id: '', label: '', grupoEnfermedades: 0 },
      diagnosticoMedico: enfermedad.diagnosticoMedico,
      tipoDiagnostico: enfermedad.tipoDiagnostico,
      certezaDelDiagnostico: enfermedad.certezaDelDiagnostico,
      estatusDelDiagnostico: enfermedad.estatusDelDiagnostico,
      edadAlDiagnostico: enfermedad.edadAlDiagnostico,
      fechaRegistroDiagnostico: enfermedad.fechaRegistroDiagnostico,
      comentarios,
      idConsulta: enfReferidas[findIndex].idConsulta,
    };
    const objEnviar = {
      idMedico,
      idPaciente,
      idConsultorio,
      consulta,
      enfermedadReferida: objDiagnostico,
    };
    const LGEN: any = { ...grupoEnfNegadas };
    // si el diagnostico es cie 10 entonces tenemos que hacer varias verificaciones
    if (enfermedad.seleccionDiagnostico === 'cie10') {
      // tenemos que verificar que esa misma enfermedad no esté activa
      // solamente si esta es referida
      // si esta activa tenemos que mostrar el modal con las opciones de que hacer con ella
      if (objDiagnostico.estatusDelDiagnostico === 'activo') {
        const found = enfReferidas.findIndex(
          (enf) =>
            enf.diagnosticoCie10.id === objDiagnostico.diagnosticoCie10.id &&
            enf.estatusDelDiagnostico === 'activo' &&
            enf.idBD !== objDiagnostico.idBD,
        );
        if (found >= 0) {
          setIndiceRepetido(found);
          setOpenAlertEnfermedad(true);
          return;
        }
      }
      // tenemos que obtener el catalog_key padre de la enfermedad introducida
      // para buscarla en la lista de enfermedades negadas, y si resulta que existe
      // en la lista de enfermedades negadas, procederemos a eliminarla
      const idPadre = enfermedad.diagnosticoCie10.id.substr(0, 3);
      const findIndxEN = enfNegadas.findIndex((len) => len.diagnosticoCie10.id === idPadre);
      if (findIndxEN >= 0) {
        const idABorrar = enfNegadas[findIndxEN].idBD;
        dispatch(
          setRequest({
            type: 'delete',
            requestFunction: enfermedadesEliminarNegada({
              idMedico,
              idPaciente,
              idConsultorio,
              idBD: idABorrar,
              consulta,
            }),
            successFunction: () => {
              const LEN = enfNegadas.filter((enfN) => enfN.diagnosticoCie10.id !== idPadre);
              dispatch(setEnfNegadas(LEN));
            },
          }),
        );
      }
      // AQUI DEBERIAMOS VOLVER A RECREAR LA LISTA CON ALGUN ALGORITMO
      /* FALTA ALGORITMO */
      const grupoEnf = numeroEnfermedadGrupo[enfermedad.diagnosticoCie10.grupoEnfermedades];
      const keySelected = `${grupoEnf}Selected`;
      const keyDisabled = `${grupoEnf}Disabled`;
      // cuando lo encontremos tenemos que deseleccionalo si llegara a existir
      // y tenemos que deshabilitarlo
      if (hasKey(grupoEnfNegadas, keySelected) && hasKey(grupoEnfNegadas, keyDisabled)) {
        LGEN[keySelected] = false;
        LGEN[keyDisabled] = true;
      }
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: enfermedadesEditReferida(objEnviar),
        successFunction: () => {
          const LE = [...enfReferidas];
          LE[findIndex] = { ...objDiagnostico };
          dispatch(setEnfReferidas(LE));
          setEnfermedad(enfermedadesFormInitial);
          dispatch(setLoading(true));
          enfermedadesGuardarGrupoEnfermedadesNegadas({
            idMedico,
            idPaciente,
            idConsultorio,
            consulta,
            listaGrupoEnfermedadesNegadas: LGEN,
          })
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                // si el resultado es positivo se actualiza el grupo de enfermedades negadas
                dispatch(setGrupoEnfNegada(LGEN));
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'success',
                    mensaje: t('message-success'),
                  }),
                );
              } else {
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'error',
                    mensaje: `${t('message-error-warning')}: ${result2.msg}`,
                  }),
                );
              }
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-warning')}: ${err}`,
                }),
              );
              dispatch(setLoading(false));
            });
        },
      }),
    );
  };

  useEffect(() => {
    if (!openCIE10) {
      setCatCIE10([]);
    }
  }, [openCIE10]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (inputCIE10.length > 2) {
      let sexo = '';
      if (perfilSexo === 'H') {
        sexo = 'HOMBRE';
      } else if (perfilSexo === 'M') {
        sexo = 'MUJER';
      }
      setLoadingCIE10(true);
      getCatCie10Query(inputCIE10, sexo, periodo, edad, signal)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setCatCIE10(result.datos);
          } else {
            setCatCIE10([]);
          }
          setLoadingCIE10(false);
        })
        .catch(() => {
          setCatCIE10([]);
          setLoadingCIE10(false);
        });
    } else {
      setCatCIE10([]);
      setLoadingCIE10(false);
    }
    return () => abortController.abort();
  }, [inputCIE10]);

  return (
    <div id="formulario-diagnostico-enfermedad">
      <AlertaEnfermedad
        open={openAlertEnfermedad}
        callBackClose={handleAlertClose}
        indiceRepetido={indiceRepetido}
        objForm={enfermedad}
        estaEnDiagnostico={false}
      />
      <div className="border border-solid rounded border-gray-500 p-4 mt-2">
        <div className="flex items-center">
          <h3 className="m-0 mr-4 text-gray-500 font-medium">{`${t('diagnostico')}*`}</h3>
          <RadioGroup
            row
            name="diagnostico"
            value={enfermedad.seleccionDiagnostico}
            onChange={handleChangeSeleccionDiagnostico}
          >
            <FormControlLabel
              value="cie10"
              control={<Radio color="primary" />}
              label={t('CIE-10')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="medico"
              control={<Radio color="primary" />}
              label={t('texto_libre')}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div className="my-4">
          {enfermedad.seleccionDiagnostico === 'cie10' && (
            <Autocomplete
              open={openCIE10}
              onOpen={() => {
                setOpenCIE10(true);
              }}
              onClose={() => {
                setOpenCIE10(false);
              }}
              getOptionLabel={(option) => option.label}
              options={catCIE10}
              loading={loadingCIE10}
              loadingText="Buscando..."
              noOptionsText={
                inputCIE10.length <= 2 ? t('introduce-tres-caracteres') : t('sin_resultados')
              }
              value={enfermedad.diagnosticoCie10}
              onChange={(e, nV, r) => handleChangeCIE(e, nV, r)}
              onInputChange={(e, newInputValue) => {
                if (newInputValue.length < 3) {
                  setCatCIE10([]);
                }
                setInputCIE10(newInputValue);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('selecciona')}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingCIE10 ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
          {enfermedad.seleccionDiagnostico === 'medico' && (
            <TextField
              variant="outlined"
              fullWidth
              value={enfermedad.diagnosticoMedico}
              onChange={handleChangeDiagnosticoMedico}
              inputProps={{ autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: 255 }}
            />
          )}
        </div>
        <div className="flex my-4">
          <div className="mr-4">
            <h3 className="m-0 mr-4 text-gray-500 font-medium mb-6">
              {`${t('estatus_del_diagnostico')}*`}
            </h3>
            <RadioGroup
              row
              name="diagnostico"
              value={enfermedad.estatusDelDiagnostico}
              onChange={handleChangeEstatusDiagnostico}
            >
              <FormControlLabel
                value="activo"
                control={<Radio color="primary" />}
                label={t('activo')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="resuelto"
                control={<Radio color="primary" />}
                label={t('resuelto')}
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
          <div className="mr-4">
            <h3 className="m-0 mr-4 text-gray-500 font-medium mb-6">
              {t('tipo_de_diagnostico')}
            </h3>
            <RadioGroup
              row
              name="tipoDiagnostico"
              value={enfermedad.tipoDiagnostico}
              onChange={handleChangeTipoDiagnostico}
            >
              <FormControlLabel
                value="cronico"
                control={<Radio color="primary" />}
                label={t('cronico')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="agudo"
                control={<Radio color="primary" />}
                label={t('agudo')}
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
          <div>
            <h3 className="m-0 mr-4 text-gray-500 font-medium mb-2">
              {t('edad_al_momento_del_diagnostico')}
            </h3>
            <div className="flex">
              <div className="mr-4 my-2 w-16">
                <TextField
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: '3',
                    },
                  }}
                  value={
                    enfermedad.edadAlDiagnostico.edad
                      ? enfermedad.edadAlDiagnostico.edad.toString()
                      : ''
                  }
                  onChange={handleChangeEdad}
                  onBlur={handleBlurEdad}
                  fullWidth
                />
              </div>
              <RadioGroup
                row
                name="periodo_edad"
                value={enfermedad.edadAlDiagnostico.periodo}
                onChange={handleChangePeriodoEdad}
              >
                <FormControlLabel
                  value="anios"
                  control={<Radio color="primary" />}
                  label={t('anio(s)')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="meses"
                  control={<Radio color="primary" />}
                  label={t('mes(es)')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="dias"
                  control={<Radio color="primary" />}
                  label={t('dias')}
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            {edadField.error && <p className="m-0 text-red-500">{t(edadField.helperText)}</p>}
          </div>
        </div>
        <ComentariosComponente
          comentarios={enfermedad.comentario}
          setComentarios={setComentarios}
          rows={4}
          maxLength={400}
          placeholder={t('comentarios')}
          lang="es"
          disabled={false}
          key={`${enfermedad.diagnosticoCie10.id}-${enfermedad.diagnosticoMedico}`}
        />
        <div className="flex justify-between items-center text-gray-500">
          <span>
            *
            {t('campos_obligatorios')}
          </span>
          <div>
            {!(enfermedad.idBD > 0) ? (
              <Button
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={verificarDisableAgregarDiagnostico()}
                onClick={handleClickAgregarDiagnostico}
              >
                {t('guardar_diagnostico')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<SyncIcon />}
                disabled={verificarDisableAgregarDiagnostico()}
                onClick={handleClickActualizarDiagnostico}
              >
                {t('actualizar')}
              </Button>
            )}
          </div>
        </div>
        <div className="text-right text-gray-500 mt-2">
          {`${t('para_guardar_el_diagnostico_debes_dar_clic_en_el_boton')} `}
          <span className="font-bold">{`“${t('guardar_diagnostico')}”`}</span>
        </div>
      </div>
    </div>
  );
}

export default EnfermedadesFormulario;
